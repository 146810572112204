import React, { Component } from 'react'
import { notification } from 'antd'
import { compose } from 'redux'
import SendConsumerModal from './SendConsumerModal'
import { connectResource } from '../../../common/utils/resource'


function openNotification({ title, message, duration }) {
  const args = {
    message: title,
    description: message,
    duration: duration || 2,
  }
  notification.open(args)
}


class SendConsumerModalContainer extends Component {
  state = {
    selectedConsumers: [],
  }

  onSubmit = () => {
    const {
      selectedConsumers,
    } = this.state
    const users = []

    selectedConsumers.map((consumer) => {
      users.push(consumer.id)
    })
    const data = {
      consumers: users,
      order_id: this.props.orderData.id,
      submission_version: this.props.version,
    }
    this.props.sendReport.create(data)
      .then(({ failed }) => {
        let args = {
          title: 'Success',
          message: 'Successfully sent emails.',
          duration: 2,
        }
        if (failed && failed.length) {
          args = {
            title: 'Error',
            message: `Error sending to: ${(failed.join(', '))}`,
            duration: 5,
          }
        }
        openNotification(args)
        this.props.onHide()
        this.props.refreshOrder()
      })
  }

  handleChange = (selectedConsumers) => this.setState({ selectedConsumers })

  handleRadio = ({ target: { value, name } }) => this.setState({ [name]: value })

  render() {
    const { ...props } = this.props
    const {
      selectedConsumers,
    } = this.state

    let consumers = []

    if (props.orderData.schedule && props.orderData.schedule.consumers && props.orderData.schedule.consumers.length) {
      consumers = props.orderData.schedule.consumers.filter(c => !c.hidden)
    }

    return <SendConsumerModal
      {...props}
      selectedConsumers={selectedConsumers}
      handleChange={this.handleChange}
      handleRadio={this.handleRadio}
      consumers={consumers}
      onSubmit={this.onSubmit}
    />
  }
}

export default compose(
  connectResource({
    namespace: 'sendReport',
    endpoint: 'order/send-report',
    prefetch: false,
    apiVersion: 2,
  }),

)(SendConsumerModalContainer)
