import React, { Component } from 'react'
import { connect } from 'react-redux'
import { connectResource } from '../utils/resource'
import { compose } from 'redux'

// import {
//   startSocket,
//   disconnectSocket,
// } from '../socket'

import BasicLayout from './BasicLayout'
import { updateHamburgerMenu } from '../socket/actions'

class BasicLayoutContainer extends Component {
  state = {
    sidebarHidden: this.props.updateHamburgerMenu === 'closed',
    sidebarHiddenMobile: false,
    minimize: true,
    dropdown: {},
  }

  // TODO in the future we will either reintroduce socket functionality or do a more thorough cleanup to remove socket-related code and dependencies.
  // commenting out socket functionality since it is broken
  // componentDidMount() {
  //   const {
  //     user,
  //     dispatch,
  //   } = this.props

  //   if (user.data) {
  //     // don't start the socket. our sockets have been broken for years.
  //     // startSocket(user.data.id, dispatch)
  //   }
  // }

  // componentWillUnmount() {
  //   // if we never start the socket, we don't need to disconnect it.
  //   // disconnectSocket()
  // }

  toggleSidebar = () => {
    this.setState({
      sidebarHidden: !this.state.sidebarHidden,
    }, () => {
      this.props.dispatch(updateHamburgerMenu(this.state.sidebarHidden))
    })
  }

  toggleSidebarMobile = () => {
    this.setState({
      sidebarHiddenMobile: !this.state.sidebarHiddenMobile,
    })
  }

  toggleMinimizeSidebar = () => {
    this.setState({
      minimize: !this.state.minimize,
    })
  }

  toggleDropdown = key => {
    const dropdown = { ...this.state.dropdown }

    dropdown[key] = dropdown[key] ? !dropdown[key] : true

    for (const otherKey in dropdown) {
      if (String(otherKey) !== String(key)) {
        dropdown[otherKey] = false
      }
    }
    this.setState({ dropdown })
  }

  getClass = () => {
    const sidebarHidden = this.state.sidebarHidden ? 'sidebar-hidden' : ''
    const sidebarHiddenMobile = this.state.sidebarHiddenMobile ? 'sidebar-mobile-show' : ''
    const minimizeSidebar = this.state.minimize ? 'brand-minimized sidebar-minimized' : ''
    return `app ${sidebarHidden} ${sidebarHiddenMobile} ${minimizeSidebar}`
  }

  render() {
    return (
      <BasicLayout
        {...this.props}
        {...this.state}
        toggleSidebar={this.toggleSidebar}
        toggleSidebarMobile={this.toggleSidebarMobile}
        toggleMinimizeSidebar={this.toggleMinimizeSidebar}
        toggleDropdown={this.toggleDropdown}
        getClass={this.getClass}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    updateHamburgerMenu: state.app.updateHamburgerMenu,
  }
}

export default compose(
  connectResource(
    { namespace: 'user', endpoint: 'users', prefetch: false }
  ),
  connect(null, (dispatch) => ({ dispatch })),
  connect(mapStateToProps)
)(BasicLayoutContainer)
