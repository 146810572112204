// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalBody_459392 > div:last-child {\n  margin-bottom: 0;\n}\n\n.modalBody_459392 .ant-tag {\n  margin-right: 0;\n}\n\n.cardContent_20e04d {\n  display: flex;\n  flex-direction: column;\n}\n\n.cardContent_20e04d > div {\n  margin-bottom: 0.25rem;\n}\n\n.cardContent_20e04d .data-points-label {\n  color: inherit;\n}\n\n.tagContainer_f3e343 {\n  display: flex;\n}\n\n.logCardItemLabel_7bed39 {\n  font-weight: 700;\n  margin-right: 8px;\n}\n\n.tagArrow_843a89 {\n  margin: 0 8px;\n}\n", "",{"version":3,"sources":["webpack://src/app/pages/orders/SubmissionsTab/ReggoraReview/ReviewLog/ReviewLogModal.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".modalBody > div:last-child {\n  margin-bottom: 0;\n}\n\n.modalBody :global(.ant-tag) {\n  margin-right: 0;\n}\n\n.cardContent {\n  display: flex;\n  flex-direction: column;\n}\n\n.cardContent > div {\n  margin-bottom: 0.25rem;\n}\n\n.cardContent :global(.data-points-label) {\n  color: inherit;\n}\n\n.tagContainer {\n  display: flex;\n}\n\n.logCardItemLabel {\n  font-weight: 700;\n  margin-right: 8px;\n}\n\n.tagArrow {\n  margin: 0 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "modalBody_459392",
	"cardContent": "cardContent_20e04d",
	"tagContainer": "tagContainer_f3e343",
	"logCardItemLabel": "logCardItemLabel_7bed39",
	"tagArrow": "tagArrow_843a89"
};
export default ___CSS_LOADER_EXPORT___;
