// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".overlay_e25d74 {\n  position: absolute;\n  top: 0%;\n  left: 0%;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(255, 255, 255, 0.5);\n  transition: background-color 0.3s ease-in-out,\n    backdrop-filter 0.3s ease-in-out;\n  display: none;\n  z-index: 10;\n}\n\n.overlayVisible_70c093 {\n  display: flex;\n  background-color: rgba(255, 255, 255, 0.5);\n  backdrop-filter: blur(3px);\n}\n", "",{"version":3,"sources":["webpack://src/app/common/layout/components/OverlayLoader/OverlayLoader.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,WAAW;EACX,YAAY;EACZ,0CAA0C;EAC1C;oCACkC;EAClC,aAAa;EACb,WAAW;AACb;;AAEA;EACE,aAAa;EACb,0CAA0C;EAC1C,0BAA0B;AAC5B","sourcesContent":[".overlay {\n  position: absolute;\n  top: 0%;\n  left: 0%;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(255, 255, 255, 0.5);\n  transition: background-color 0.3s ease-in-out,\n    backdrop-filter 0.3s ease-in-out;\n  display: none;\n  z-index: 10;\n}\n\n.overlayVisible {\n  display: flex;\n  background-color: rgba(255, 255, 255, 0.5);\n  backdrop-filter: blur(3px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": "overlay_e25d74",
	"overlayVisible": "overlayVisible_70c093"
};
export default ___CSS_LOADER_EXPORT___;
