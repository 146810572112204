// keys are state two letter codes, values are readable state names
export const STATES = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  //    'AS': 'American Samoa',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'DC': 'District Of Columbia',
  //    'FM': 'Federated States Of Micronesia',
  'FL': 'Florida',
  'GA': 'Georgia',
  //    'GU': 'Guam',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  // 'MH': 'Marshall Islands',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  // 'MP': 'Northern Mariana Islands',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  // 'PW': 'Palau',
  'PA': 'Pennsylvania',
  'PR': 'Puerto Rico',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VI': 'Virgin Islands',
  'VT': 'Vermont',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming'
}

export const stateShortToFIPS = {
  'AK': '02',
  'AL': '01',
  'AR': '05',
  'AS': '60',
  'AZ': '04',
  'CA': '06',
  'CO': '08',
  'CT': '09',
  'DC': '11',
  'DE': '10',
  'FL': '12',
  'GA': '13',
  'GU': '66',
  'HI': '15',
  'IA': '19',
  'ID': '16',
  'IL': '17',
  'IN': '18',
  'KS': '20',
  'KY': '21',
  'LA': '22',
  'MA': '25',
  'MD': '24',
  'ME': '23',
  'MI': '26',
  'MN': '27',
  'MO': '29',
  'MS': '28',
  'MT': '30',
  'NC': '37',
  'ND': '38',
  'NE': '31',
  'NH': '33',
  'NJ': '34',
  'NM': '35',
  'NV': '32',
  'NY': '36',
  'OH': '39',
  'OK': '40',
  'OR': '41',
  'PA': '42',
  'PR': '72',
  'RI': '44',
  'SC': '45',
  'SD': '46',
  'TN': '47',
  'TX': '48',
  'UT': '49',
  'VA': '51',
  'VI': '78',
  'VT': '50',
  'WA': '53',
  'WI': '55',
  'WV': '54',
  'WY': '56'
}

// keys are FIPS state codes, values are arrays of zip codes found in that state
export const zipByState = {
  '12': [
    '32003',
    '32008',
    '32009',
    '32011',
    '32024',
    '32025',
    '32026',
    '32033',
    '32034',
    '32038',
    '32040',
    '32043',
    '32044',
    '32046',
    '32052',
    '32053',
    '32054',
    '32055',
    '32058',
    '32059',
    '32060',
    '32061',
    '32062',
    '32063',
    '32064',
    '32065',
    '32066',
    '32068',
    '32071',
    '32072',
    '32073',
    '32079',
    '32080',
    '32081',
    '32082',
    '32083',
    '32084',
    '32086',
    '32087',
    '32091',
    '32092',
    '32094',
    '32095',
    '32096',
    '32097',
    '32102',
    '32110',
    '32112',
    '32113',
    '32114',
    '32117',
    '32118',
    '32119',
    '32124',
    '32127',
    '32128',
    '32129',
    '32130',
    '32131',
    '32132',
    '32133',
    '32134',
    '32136',
    '32137',
    '32139',
    '32140',
    '32141',
    '32145',
    '32147',
    '32148',
    '32157',
    '32159',
    '32162',
    '32163',
    '32164',
    '32168',
    '32169',
    '32174',
    '32176',
    '32177',
    '32179',
    '32180',
    '32181',
    '32187',
    '32189',
    '32190',
    '32193',
    '32195',
    '32202',
    '32204',
    '32205',
    '32206',
    '32207',
    '32208',
    '32209',
    '32210',
    '32211',
    '32212',
    '32216',
    '32217',
    '32218',
    '32219',
    '32220',
    '32221',
    '32222',
    '32223',
    '32224',
    '32225',
    '32226',
    '32227',
    '32228',
    '32233',
    '32234',
    '32244',
    '32246',
    '32250',
    '32254',
    '32256',
    '32257',
    '32258',
    '32259',
    '32266',
    '32277',
    '32301',
    '32303',
    '32304',
    '32305',
    '32308',
    '32309',
    '32310',
    '32311',
    '32312',
    '32317',
    '32320',
    '32321',
    '32322',
    '32323',
    '32324',
    '32327',
    '32328',
    '32330',
    '32331',
    '32332',
    '32333',
    '32334',
    '32336',
    '32340',
    '32343',
    '32344',
    '32346',
    '32347',
    '32348',
    '32350',
    '32351',
    '32352',
    '32355',
    '32356',
    '32358',
    '32359',
    '32361',
    '32399',
    '32401',
    '32403',
    '32404',
    '32405',
    '32407',
    '32408',
    '32409',
    '32410',
    '32413',
    '32420',
    '32421',
    '32423',
    '32424',
    '32425',
    '32426',
    '32427',
    '32428',
    '32430',
    '32431',
    '32432',
    '32433',
    '32435',
    '32437',
    '32438',
    '32439',
    '32440',
    '32442',
    '32443',
    '32444',
    '32445',
    '32446',
    '32447',
    '32448',
    '32449',
    '32455',
    '32456',
    '32459',
    '32460',
    '32461',
    '32462',
    '32463',
    '32464',
    '32465',
    '32466',
    '32501',
    '32502',
    '32503',
    '32504',
    '32505',
    '32506',
    '32507',
    '32508',
    '32509',
    '32511',
    '32514',
    '32526',
    '32530',
    '32531',
    '32533',
    '32534',
    '32535',
    '32536',
    '32539',
    '32541',
    '32542',
    '32544',
    '32547',
    '32548',
    '32550',
    '32561',
    '32563',
    '32564',
    '32565',
    '32566',
    '32567',
    '32568',
    '32569',
    '32570',
    '32571',
    '32577',
    '32578',
    '32579',
    '32580',
    '32583',
    '32601',
    '32603',
    '32605',
    '32606',
    '32607',
    '32608',
    '32609',
    '32612',
    '32615',
    '32616',
    '32617',
    '32618',
    '32619',
    '32621',
    '32622',
    '32625',
    '32626',
    '32628',
    '32631',
    '32639',
    '32640',
    '32641',
    '32643',
    '32648',
    '32653',
    '32656',
    '32658',
    '32664',
    '32666',
    '32667',
    '32668',
    '32669',
    '32680',
    '32681',
    '32683',
    '32686',
    '32692',
    '32693',
    '32694',
    '32696',
    '32697',
    '32701',
    '32702',
    '32703',
    '32707',
    '32708',
    '32709',
    '32712',
    '32713',
    '32714',
    '32720',
    '32724',
    '32725',
    '32726',
    '32730',
    '32732',
    '32735',
    '32736',
    '32738',
    '32744',
    '32746',
    '32750',
    '32751',
    '32754',
    '32757',
    '32759',
    '32763',
    '32764',
    '32765',
    '32766',
    '32767',
    '32771',
    '32773',
    '32776',
    '32778',
    '32779',
    '32780',
    '32784',
    '32789',
    '32792',
    '32796',
    '32798',
    '32801',
    '32803',
    '32804',
    '32805',
    '32806',
    '32807',
    '32808',
    '32809',
    '32810',
    '32811',
    '32812',
    '32814',
    '32817',
    '32818',
    '32819',
    '32820',
    '32821',
    '32822',
    '32824',
    '32825',
    '32826',
    '32827',
    '32828',
    '32829',
    '32830',
    '32831',
    '32832',
    '32833',
    '32835',
    '32836',
    '32837',
    '32839',
    '32901',
    '32903',
    '32904',
    '32905',
    '32907',
    '32908',
    '32909',
    '32920',
    '32922',
    '32925',
    '32926',
    '32927',
    '32931',
    '32934',
    '32935',
    '32937',
    '32940',
    '32948',
    '32949',
    '32950',
    '32951',
    '32952',
    '32953',
    '32955',
    '32958',
    '32960',
    '32962',
    '32963',
    '32966',
    '32967',
    '32968',
    '32970',
    '32976',
    '33001',
    '33004',
    '33009',
    '33010',
    '33012',
    '33013',
    '33014',
    '33015',
    '33016',
    '33018',
    '33019',
    '33020',
    '33021',
    '33023',
    '33024',
    '33025',
    '33026',
    '33027',
    '33028',
    '33029',
    '33030',
    '33031',
    '33032',
    '33033',
    '33034',
    '33035',
    '33036',
    '33037',
    '33039',
    '33040',
    '33042',
    '33043',
    '33050',
    '33051',
    '33054',
    '33055',
    '33056',
    '33060',
    '33062',
    '33063',
    '33064',
    '33065',
    '33066',
    '33067',
    '33068',
    '33069',
    '33070',
    '33071',
    '33073',
    '33076',
    '33101',
    '33109',
    '33122',
    '33125',
    '33126',
    '33127',
    '33128',
    '33129',
    '33130',
    '33131',
    '33132',
    '33133',
    '33134',
    '33135',
    '33136',
    '33137',
    '33138',
    '33139',
    '33140',
    '33141',
    '33142',
    '33143',
    '33144',
    '33145',
    '33146',
    '33147',
    '33149',
    '33150',
    '33154',
    '33155',
    '33156',
    '33157',
    '33158',
    '33160',
    '33161',
    '33162',
    '33165',
    '33166',
    '33167',
    '33168',
    '33169',
    '33170',
    '33172',
    '33173',
    '33174',
    '33175',
    '33176',
    '33177',
    '33178',
    '33179',
    '33180',
    '33181',
    '33182',
    '33183',
    '33184',
    '33185',
    '33186',
    '33187',
    '33189',
    '33190',
    '33193',
    '33194',
    '33196',
    '33301',
    '33304',
    '33305',
    '33306',
    '33308',
    '33309',
    '33311',
    '33312',
    '33313',
    '33314',
    '33315',
    '33316',
    '33317',
    '33319',
    '33321',
    '33322',
    '33323',
    '33324',
    '33325',
    '33326',
    '33327',
    '33328',
    '33330',
    '33331',
    '33332',
    '33334',
    '33351',
    '33401',
    '33403',
    '33404',
    '33405',
    '33406',
    '33407',
    '33408',
    '33409',
    '33410',
    '33411',
    '33412',
    '33413',
    '33414',
    '33415',
    '33417',
    '33418',
    '33426',
    '33428',
    '33430',
    '33431',
    '33432',
    '33433',
    '33434',
    '33435',
    '33436',
    '33437',
    '33438',
    '33440',
    '33441',
    '33442',
    '33444',
    '33445',
    '33446',
    '33449',
    '33455',
    '33458',
    '33460',
    '33461',
    '33462',
    '33463',
    '33467',
    '33469',
    '33470',
    '33471',
    '33472',
    '33473',
    '33476',
    '33477',
    '33478',
    '33480',
    '33483',
    '33484',
    '33486',
    '33487',
    '33493',
    '33496',
    '33498',
    '33503',
    '33510',
    '33511',
    '33513',
    '33514',
    '33521',
    '33523',
    '33525',
    '33527',
    '33534',
    '33538',
    '33540',
    '33541',
    '33542',
    '33543',
    '33544',
    '33545',
    '33547',
    '33548',
    '33549',
    '33556',
    '33558',
    '33559',
    '33563',
    '33565',
    '33566',
    '33567',
    '33569',
    '33570',
    '33572',
    '33573',
    '33576',
    '33578',
    '33579',
    '33584',
    '33585',
    '33592',
    '33594',
    '33596',
    '33597',
    '33598',
    '33602',
    '33603',
    '33604',
    '33605',
    '33606',
    '33607',
    '33609',
    '33610',
    '33611',
    '33612',
    '33613',
    '33614',
    '33615',
    '33616',
    '33617',
    '33618',
    '33619',
    '33620',
    '33621',
    '33624',
    '33625',
    '33626',
    '33629',
    '33634',
    '33635',
    '33637',
    '33647',
    '33701',
    '33702',
    '33703',
    '33704',
    '33705',
    '33706',
    '33707',
    '33708',
    '33709',
    '33710',
    '33711',
    '33712',
    '33713',
    '33714',
    '33715',
    '33716',
    '33744',
    '33755',
    '33756',
    '33759',
    '33760',
    '33761',
    '33762',
    '33763',
    '33764',
    '33765',
    '33767',
    '33770',
    '33771',
    '33772',
    '33773',
    '33774',
    '33776',
    '33777',
    '33778',
    '33781',
    '33782',
    '33785',
    '33786',
    '33801',
    '33803',
    '33805',
    '33809',
    '33810',
    '33811',
    '33812',
    '33813',
    '33815',
    '33823',
    '33825',
    '33827',
    '33830',
    '33834',
    '33837',
    '33838',
    '33839',
    '33841',
    '33843',
    '33844',
    '33847',
    '33848',
    '33849',
    '33850',
    '33851',
    '33852',
    '33853',
    '33854',
    '33855',
    '33856',
    '33857',
    '33859',
    '33860',
    '33865',
    '33867',
    '33868',
    '33870',
    '33872',
    '33873',
    '33875',
    '33876',
    '33877',
    '33880',
    '33881',
    '33884',
    '33890',
    '33896',
    '33897',
    '33898',
    '33901',
    '33903',
    '33904',
    '33905',
    '33907',
    '33908',
    '33909',
    '33912',
    '33913',
    '33914',
    '33916',
    '33917',
    '33919',
    '33920',
    '33921',
    '33922',
    '33924',
    '33928',
    '33930',
    '33931',
    '33935',
    '33936',
    '33944',
    '33945',
    '33946',
    '33947',
    '33948',
    '33950',
    '33952',
    '33953',
    '33954',
    '33955',
    '33956',
    '33957',
    '33960',
    '33965',
    '33966',
    '33967',
    '33971',
    '33972',
    '33973',
    '33974',
    '33976',
    '33980',
    '33981',
    '33982',
    '33983',
    '33990',
    '33991',
    '33993',
    '34101',
    '34102',
    '34103',
    '34104',
    '34105',
    '34108',
    '34109',
    '34110',
    '34112',
    '34113',
    '34114',
    '34116',
    '34117',
    '34119',
    '34120',
    '34134',
    '34135',
    '34137',
    '34138',
    '34139',
    '34140',
    '34141',
    '34142',
    '34145',
    '34201',
    '34202',
    '34203',
    '34205',
    '34207',
    '34208',
    '34209',
    '34210',
    '34211',
    '34212',
    '34215',
    '34216',
    '34217',
    '34219',
    '34221',
    '34222',
    '34223',
    '34224',
    '34228',
    '34229',
    '34231',
    '34232',
    '34233',
    '34234',
    '34235',
    '34236',
    '34237',
    '34238',
    '34239',
    '34240',
    '34241',
    '34242',
    '34243',
    '34251',
    '34266',
    '34268',
    '34269',
    '34275',
    '34285',
    '34286',
    '34287',
    '34288',
    '34289',
    '34291',
    '34292',
    '34293',
    '34420',
    '34428',
    '34429',
    '34431',
    '34432',
    '34433',
    '34434',
    '34436',
    '34442',
    '34445',
    '34446',
    '34448',
    '34449',
    '34450',
    '34452',
    '34453',
    '34461',
    '34465',
    '34470',
    '34471',
    '34472',
    '34473',
    '34474',
    '34475',
    '34476',
    '34479',
    '34480',
    '34481',
    '34482',
    '34484',
    '34488',
    '34491',
    '34498',
    '34601',
    '34602',
    '34604',
    '34606',
    '34607',
    '34608',
    '34609',
    '34610',
    '34613',
    '34614',
    '34637',
    '34638',
    '34639',
    '34652',
    '34653',
    '34654',
    '34655',
    '34661',
    '34667',
    '34668',
    '34669',
    '34677',
    '34679',
    '34681',
    '34683',
    '34684',
    '34685',
    '34688',
    '34689',
    '34690',
    '34691',
    '34695',
    '34698',
    '34705',
    '34711',
    '34714',
    '34715',
    '34731',
    '34734',
    '34736',
    '34737',
    '34739',
    '34741',
    '34743',
    '34744',
    '34746',
    '34747',
    '34748',
    '34753',
    '34756',
    '34758',
    '34759',
    '34760',
    '34761',
    '34762',
    '34769',
    '34771',
    '34772',
    '34773',
    '34785',
    '34786',
    '34787',
    '34788',
    '34797',
    '34945',
    '34946',
    '34947',
    '34949',
    '34950',
    '34951',
    '34952',
    '34953',
    '34956',
    '34957',
    '34972',
    '34974',
    '34981',
    '34982',
    '34983',
    '34984',
    '34986',
    '34987',
    '34990',
    '34994',
    '34996',
    '34997'
  ],
  '46': [
    '51001',
    '51023',
    '56144',
    '56164',
    '56219',
    '57001',
    '57002',
    '57003',
    '57004',
    '57005',
    '57006',
    '57010',
    '57012',
    '57013',
    '57014',
    '57015',
    '57016',
    '57017',
    '57018',
    '57020',
    '57021',
    '57022',
    '57024',
    '57025',
    '57026',
    '57027',
    '57028',
    '57029',
    '57030',
    '57031',
    '57032',
    '57033',
    '57034',
    '57035',
    '57036',
    '57037',
    '57038',
    '57039',
    '57040',
    '57041',
    '57042',
    '57043',
    '57045',
    '57046',
    '57047',
    '57048',
    '57049',
    '57050',
    '57051',
    '57052',
    '57053',
    '57054',
    '57055',
    '57057',
    '57058',
    '57059',
    '57061',
    '57062',
    '57063',
    '57064',
    '57065',
    '57066',
    '57067',
    '57068',
    '57069',
    '57070',
    '57071',
    '57072',
    '57073',
    '57075',
    '57076',
    '57077',
    '57078',
    '57103',
    '57104',
    '57105',
    '57106',
    '57107',
    '57108',
    '57110',
    '57117',
    '57197',
    '57201',
    '57212',
    '57213',
    '57214',
    '57216',
    '57217',
    '57218',
    '57219',
    '57220',
    '57221',
    '57223',
    '57224',
    '57225',
    '57226',
    '57227',
    '57231',
    '57232',
    '57233',
    '57234',
    '57235',
    '57236',
    '57237',
    '57238',
    '57239',
    '57241',
    '57242',
    '57243',
    '57245',
    '57246',
    '57247',
    '57248',
    '57249',
    '57251',
    '57252',
    '57255',
    '57256',
    '57257',
    '57258',
    '57259',
    '57260',
    '57261',
    '57262',
    '57263',
    '57264',
    '57265',
    '57266',
    '57268',
    '57269',
    '57270',
    '57271',
    '57272',
    '57273',
    '57274',
    '57276',
    '57278',
    '57279',
    '57301',
    '57311',
    '57312',
    '57313',
    '57314',
    '57315',
    '57317',
    '57319',
    '57321',
    '57322',
    '57323',
    '57324',
    '57325',
    '57328',
    '57329',
    '57330',
    '57331',
    '57332',
    '57334',
    '57335',
    '57337',
    '57339',
    '57340',
    '57341',
    '57342',
    '57344',
    '57345',
    '57346',
    '57348',
    '57349',
    '57350',
    '57353',
    '57355',
    '57356',
    '57358',
    '57359',
    '57361',
    '57362',
    '57363',
    '57364',
    '57365',
    '57366',
    '57367',
    '57368',
    '57369',
    '57370',
    '57371',
    '57373',
    '57374',
    '57375',
    '57376',
    '57379',
    '57380',
    '57381',
    '57382',
    '57383',
    '57384',
    '57385',
    '57386',
    '57401',
    '57420',
    '57421',
    '57422',
    '57424',
    '57426',
    '57427',
    '57428',
    '57429',
    '57430',
    '57432',
    '57433',
    '57434',
    '57435',
    '57436',
    '57437',
    '57438',
    '57439',
    '57440',
    '57441',
    '57442',
    '57445',
    '57446',
    '57448',
    '57449',
    '57450',
    '57451',
    '57452',
    '57454',
    '57455',
    '57456',
    '57457',
    '57460',
    '57461',
    '57465',
    '57466',
    '57467',
    '57468',
    '57469',
    '57470',
    '57471',
    '57472',
    '57473',
    '57474',
    '57475',
    '57476',
    '57477',
    '57479',
    '57481',
    '57501',
    '57520',
    '57521',
    '57522',
    '57523',
    '57528',
    '57529',
    '57531',
    '57532',
    '57533',
    '57534',
    '57536',
    '57537',
    '57538',
    '57540',
    '57541',
    '57543',
    '57544',
    '57547',
    '57548',
    '57551',
    '57552',
    '57553',
    '57555',
    '57559',
    '57560',
    '57562',
    '57563',
    '57564',
    '57566',
    '57567',
    '57568',
    '57569',
    '57570',
    '57571',
    '57572',
    '57574',
    '57576',
    '57577',
    '57579',
    '57580',
    '57584',
    '57585',
    '57601',
    '57620',
    '57621',
    '57622',
    '57623',
    '57625',
    '57626',
    '57630',
    '57631',
    '57632',
    '57633',
    '57634',
    '57636',
    '57638',
    '57639',
    '57640',
    '57641',
    '57642',
    '57644',
    '57645',
    '57646',
    '57648',
    '57649',
    '57650',
    '57651',
    '57652',
    '57656',
    '57657',
    '57658',
    '57660',
    '57661',
    '57701',
    '57702',
    '57703',
    '57706',
    '57714',
    '57716',
    '57717',
    '57718',
    '57719',
    '57720',
    '57722',
    '57724',
    '57725',
    '57730',
    '57732',
    '57735',
    '57738',
    '57741',
    '57744',
    '57745',
    '57747',
    '57748',
    '57750',
    '57751',
    '57752',
    '57754',
    '57755',
    '57756',
    '57758',
    '57759',
    '57760',
    '57761',
    '57762',
    '57763',
    '57764',
    '57766',
    '57767',
    '57769',
    '57770',
    '57772',
    '57773',
    '57775',
    '57776',
    '57779',
    '57780',
    '57782',
    '57783',
    '57785',
    '57787',
    '57788',
    '57790',
    '57791',
    '57792',
    '57793',
    '57794',
    '57799',
    '58439',
    '58623',
    '58649',
    '58653',
    '68719',
    '69201',
    '69212',
    '69216',
    '82701'
  ],
  '06': [
    '89010',
    '89019',
    '89060',
    '89061',
    '89439',
    '90001',
    '90002',
    '90003',
    '90004',
    '90005',
    '90006',
    '90007',
    '90008',
    '90010',
    '90011',
    '90012',
    '90013',
    '90014',
    '90015',
    '90016',
    '90017',
    '90018',
    '90019',
    '90020',
    '90021',
    '90022',
    '90023',
    '90024',
    '90025',
    '90026',
    '90027',
    '90028',
    '90029',
    '90031',
    '90032',
    '90033',
    '90034',
    '90035',
    '90036',
    '90037',
    '90038',
    '90039',
    '90040',
    '90041',
    '90042',
    '90043',
    '90044',
    '90045',
    '90046',
    '90047',
    '90048',
    '90049',
    '90056',
    '90057',
    '90058',
    '90059',
    '90061',
    '90062',
    '90063',
    '90064',
    '90065',
    '90066',
    '90067',
    '90068',
    '90069',
    '90071',
    '90073',
    '90077',
    '90079',
    '90089',
    '90090',
    '90094',
    '90095',
    '90201',
    '90210',
    '90211',
    '90212',
    '90220',
    '90221',
    '90222',
    '90230',
    '90232',
    '90240',
    '90241',
    '90242',
    '90245',
    '90247',
    '90248',
    '90249',
    '90250',
    '90254',
    '90255',
    '90260',
    '90262',
    '90263',
    '90265',
    '90266',
    '90270',
    '90272',
    '90274',
    '90275',
    '90277',
    '90278',
    '90280',
    '90290',
    '90291',
    '90292',
    '90293',
    '90301',
    '90302',
    '90303',
    '90304',
    '90305',
    '90401',
    '90402',
    '90403',
    '90404',
    '90405',
    '90501',
    '90502',
    '90503',
    '90504',
    '90505',
    '90506',
    '90601',
    '90602',
    '90603',
    '90604',
    '90605',
    '90606',
    '90620',
    '90621',
    '90623',
    '90630',
    '90631',
    '90638',
    '90640',
    '90650',
    '90660',
    '90670',
    '90680',
    '90701',
    '90703',
    '90704',
    '90706',
    '90710',
    '90712',
    '90713',
    '90715',
    '90716',
    '90717',
    '90720',
    '90723',
    '90731',
    '90732',
    '90740',
    '90742',
    '90743',
    '90744',
    '90745',
    '90746',
    '90747',
    '90755',
    '90802',
    '90803',
    '90804',
    '90805',
    '90806',
    '90807',
    '90808',
    '90810',
    '90813',
    '90814',
    '90815',
    '90822',
    '90831',
    '91001',
    '91006',
    '91007',
    '91008',
    '91010',
    '91011',
    '91016',
    '91020',
    '91024',
    '91030',
    '91040',
    '91042',
    '91046',
    '91101',
    '91103',
    '91104',
    '91105',
    '91106',
    '91107',
    '91108',
    '91201',
    '91202',
    '91203',
    '91204',
    '91205',
    '91206',
    '91207',
    '91208',
    '91210',
    '91214',
    '91301',
    '91302',
    '91303',
    '91304',
    '91306',
    '91307',
    '91311',
    '91316',
    '91320',
    '91321',
    '91324',
    '91325',
    '91326',
    '91330',
    '91331',
    '91335',
    '91340',
    '91342',
    '91343',
    '91344',
    '91345',
    '91350',
    '91351',
    '91352',
    '91354',
    '91355',
    '91356',
    '91360',
    '91361',
    '91362',
    '91364',
    '91367',
    '91371',
    '91377',
    '91381',
    '91384',
    '91387',
    '91390',
    '91401',
    '91402',
    '91403',
    '91405',
    '91406',
    '91411',
    '91423',
    '91436',
    '91501',
    '91502',
    '91504',
    '91505',
    '91506',
    '91601',
    '91602',
    '91604',
    '91605',
    '91606',
    '91607',
    '91608',
    '91701',
    '91702',
    '91706',
    '91708',
    '91709',
    '91710',
    '91711',
    '91722',
    '91723',
    '91724',
    '91730',
    '91731',
    '91732',
    '91733',
    '91737',
    '91739',
    '91740',
    '91741',
    '91744',
    '91745',
    '91746',
    '91748',
    '91750',
    '91752',
    '91754',
    '91755',
    '91759',
    '91761',
    '91762',
    '91763',
    '91764',
    '91765',
    '91766',
    '91767',
    '91768',
    '91770',
    '91773',
    '91775',
    '91776',
    '91780',
    '91784',
    '91786',
    '91789',
    '91790',
    '91791',
    '91792',
    '91801',
    '91803',
    '91901',
    '91902',
    '91905',
    '91906',
    '91910',
    '91911',
    '91913',
    '91914',
    '91915',
    '91916',
    '91917',
    '91931',
    '91932',
    '91934',
    '91935',
    '91941',
    '91942',
    '91945',
    '91948',
    '91950',
    '91962',
    '91963',
    '91977',
    '91978',
    '91980',
    '92003',
    '92004',
    '92007',
    '92008',
    '92009',
    '92010',
    '92011',
    '92014',
    '92019',
    '92020',
    '92021',
    '92024',
    '92025',
    '92026',
    '92027',
    '92028',
    '92029',
    '92036',
    '92037',
    '92040',
    '92054',
    '92055',
    '92056',
    '92057',
    '92058',
    '92059',
    '92060',
    '92061',
    '92064',
    '92065',
    '92066',
    '92067',
    '92069',
    '92070',
    '92071',
    '92075',
    '92078',
    '92081',
    '92082',
    '92083',
    '92084',
    '92086',
    '92091',
    '92101',
    '92102',
    '92103',
    '92104',
    '92105',
    '92106',
    '92107',
    '92108',
    '92109',
    '92110',
    '92111',
    '92113',
    '92114',
    '92115',
    '92116',
    '92117',
    '92118',
    '92119',
    '92120',
    '92121',
    '92122',
    '92123',
    '92124',
    '92126',
    '92127',
    '92128',
    '92129',
    '92130',
    '92131',
    '92132',
    '92134',
    '92135',
    '92139',
    '92140',
    '92145',
    '92147',
    '92154',
    '92155',
    '92173',
    '92201',
    '92203',
    '92210',
    '92211',
    '92220',
    '92222',
    '92223',
    '92225',
    '92227',
    '92230',
    '92231',
    '92233',
    '92234',
    '92236',
    '92239',
    '92240',
    '92241',
    '92242',
    '92243',
    '92249',
    '92250',
    '92251',
    '92252',
    '92253',
    '92254',
    '92256',
    '92257',
    '92258',
    '92259',
    '92260',
    '92262',
    '92264',
    '92266',
    '92267',
    '92268',
    '92270',
    '92273',
    '92274',
    '92275',
    '92276',
    '92277',
    '92278',
    '92280',
    '92281',
    '92282',
    '92283',
    '92284',
    '92285',
    '92301',
    '92304',
    '92305',
    '92307',
    '92308',
    '92309',
    '92310',
    '92311',
    '92313',
    '92314',
    '92315',
    '92316',
    '92320',
    '92321',
    '92322',
    '92324',
    '92325',
    '92327',
    '92328',
    '92332',
    '92333',
    '92335',
    '92336',
    '92337',
    '92338',
    '92339',
    '92341',
    '92342',
    '92344',
    '92345',
    '92346',
    '92347',
    '92352',
    '92354',
    '92356',
    '92358',
    '92359',
    '92363',
    '92364',
    '92365',
    '92368',
    '92371',
    '92372',
    '92373',
    '92374',
    '92376',
    '92377',
    '92378',
    '92382',
    '92384',
    '92385',
    '92386',
    '92389',
    '92391',
    '92392',
    '92394',
    '92395',
    '92397',
    '92398',
    '92399',
    '92401',
    '92404',
    '92405',
    '92407',
    '92408',
    '92410',
    '92411',
    '92501',
    '92503',
    '92504',
    '92505',
    '92506',
    '92507',
    '92508',
    '92509',
    '92518',
    '92530',
    '92532',
    '92536',
    '92539',
    '92543',
    '92544',
    '92545',
    '92548',
    '92549',
    '92551',
    '92553',
    '92555',
    '92557',
    '92561',
    '92562',
    '92563',
    '92567',
    '92570',
    '92571',
    '92582',
    '92583',
    '92584',
    '92585',
    '92586',
    '92587',
    '92590',
    '92591',
    '92592',
    '92595',
    '92596',
    '92602',
    '92603',
    '92604',
    '92606',
    '92610',
    '92612',
    '92614',
    '92617',
    '92618',
    '92620',
    '92624',
    '92625',
    '92626',
    '92627',
    '92629',
    '92630',
    '92637',
    '92646',
    '92647',
    '92648',
    '92649',
    '92651',
    '92653',
    '92655',
    '92656',
    '92657',
    '92660',
    '92661',
    '92662',
    '92663',
    '92672',
    '92673',
    '92675',
    '92676',
    '92677',
    '92678',
    '92679',
    '92683',
    '92688',
    '92691',
    '92692',
    '92694',
    '92701',
    '92703',
    '92704',
    '92705',
    '92706',
    '92707',
    '92708',
    '92780',
    '92782',
    '92801',
    '92802',
    '92804',
    '92805',
    '92806',
    '92807',
    '92808',
    '92821',
    '92823',
    '92831',
    '92832',
    '92833',
    '92835',
    '92840',
    '92841',
    '92843',
    '92844',
    '92845',
    '92860',
    '92861',
    '92865',
    '92866',
    '92867',
    '92868',
    '92869',
    '92870',
    '92879',
    '92880',
    '92881',
    '92882',
    '92883',
    '92886',
    '92887',
    '93001',
    '93003',
    '93004',
    '93010',
    '93012',
    '93013',
    '93015',
    '93021',
    '93022',
    '93023',
    '93030',
    '93033',
    '93035',
    '93036',
    '93040',
    '93041',
    '93042',
    '93043',
    '93060',
    '93063',
    '93064',
    '93065',
    '93066',
    '93067',
    '93101',
    '93103',
    '93105',
    '93108',
    '93109',
    '93110',
    '93111',
    '93117',
    '93201',
    '93202',
    '93203',
    '93204',
    '93205',
    '93206',
    '93207',
    '93208',
    '93210',
    '93212',
    '93215',
    '93218',
    '93219',
    '93220',
    '93221',
    '93222',
    '93223',
    '93224',
    '93225',
    '93226',
    '93230',
    '93234',
    '93235',
    '93238',
    '93239',
    '93240',
    '93241',
    '93242',
    '93243',
    '93244',
    '93245',
    '93247',
    '93249',
    '93250',
    '93251',
    '93252',
    '93254',
    '93255',
    '93256',
    '93257',
    '93258',
    '93260',
    '93261',
    '93262',
    '93263',
    '93265',
    '93266',
    '93267',
    '93268',
    '93270',
    '93271',
    '93272',
    '93274',
    '93276',
    '93277',
    '93280',
    '93283',
    '93285',
    '93286',
    '93287',
    '93291',
    '93292',
    '93301',
    '93304',
    '93305',
    '93306',
    '93307',
    '93308',
    '93309',
    '93311',
    '93312',
    '93313',
    '93314',
    '93401',
    '93402',
    '93405',
    '93410',
    '93420',
    '93422',
    '93424',
    '93426',
    '93427',
    '93428',
    '93429',
    '93430',
    '93432',
    '93433',
    '93434',
    '93436',
    '93437',
    '93440',
    '93441',
    '93442',
    '93444',
    '93445',
    '93446',
    '93449',
    '93450',
    '93451',
    '93452',
    '93453',
    '93454',
    '93455',
    '93458',
    '93460',
    '93461',
    '93463',
    '93465',
    '93501',
    '93505',
    '93510',
    '93512',
    '93513',
    '93514',
    '93516',
    '93517',
    '93518',
    '93519',
    '93522',
    '93523',
    '93524',
    '93526',
    '93527',
    '93528',
    '93529',
    '93530',
    '93531',
    '93532',
    '93534',
    '93535',
    '93536',
    '93541',
    '93543',
    '93544',
    '93545',
    '93546',
    '93549',
    '93550',
    '93551',
    '93552',
    '93553',
    '93554',
    '93555',
    '93558',
    '93560',
    '93561',
    '93562',
    '93563',
    '93591',
    '93592',
    '93601',
    '93602',
    '93603',
    '93604',
    '93605',
    '93606',
    '93608',
    '93609',
    '93610',
    '93611',
    '93612',
    '93614',
    '93615',
    '93616',
    '93618',
    '93619',
    '93620',
    '93621',
    '93622',
    '93623',
    '93624',
    '93625',
    '93626',
    '93627',
    '93628',
    '93630',
    '93631',
    '93633',
    '93634',
    '93635',
    '93636',
    '93637',
    '93638',
    '93640',
    '93641',
    '93643',
    '93644',
    '93645',
    '93646',
    '93647',
    '93648',
    '93650',
    '93651',
    '93652',
    '93653',
    '93654',
    '93656',
    '93657',
    '93660',
    '93662',
    '93664',
    '93665',
    '93666',
    '93667',
    '93668',
    '93669',
    '93673',
    '93675',
    '93701',
    '93702',
    '93703',
    '93704',
    '93705',
    '93706',
    '93710',
    '93711',
    '93720',
    '93721',
    '93722',
    '93723',
    '93725',
    '93726',
    '93727',
    '93728',
    '93730',
    '93901',
    '93905',
    '93906',
    '93907',
    '93908',
    '93920',
    '93921',
    '93923',
    '93924',
    '93925',
    '93926',
    '93927',
    '93928',
    '93930',
    '93932',
    '93933',
    '93940',
    '93943',
    '93950',
    '93953',
    '93954',
    '93955',
    '93960',
    '93962',
    '94002',
    '94005',
    '94010',
    '94014',
    '94015',
    '94019',
    '94020',
    '94021',
    '94022',
    '94024',
    '94025',
    '94027',
    '94028',
    '94030',
    '94037',
    '94038',
    '94040',
    '94041',
    '94043',
    '94044',
    '94060',
    '94061',
    '94062',
    '94063',
    '94065',
    '94066',
    '94070',
    '94074',
    '94080',
    '94085',
    '94086',
    '94087',
    '94089',
    '94102',
    '94103',
    '94104',
    '94105',
    '94107',
    '94108',
    '94109',
    '94110',
    '94111',
    '94112',
    '94114',
    '94115',
    '94116',
    '94117',
    '94118',
    '94121',
    '94122',
    '94123',
    '94124',
    '94127',
    '94128',
    '94129',
    '94130',
    '94131',
    '94132',
    '94133',
    '94134',
    '94158',
    '94301',
    '94303',
    '94304',
    '94305',
    '94306',
    '94401',
    '94402',
    '94403',
    '94404',
    '94501',
    '94502',
    '94503',
    '94505',
    '94506',
    '94507',
    '94508',
    '94509',
    '94510',
    '94511',
    '94512',
    '94513',
    '94514',
    '94515',
    '94516',
    '94517',
    '94518',
    '94519',
    '94520',
    '94521',
    '94523',
    '94525',
    '94526',
    '94528',
    '94530',
    '94531',
    '94533',
    '94534',
    '94535',
    '94536',
    '94538',
    '94539',
    '94541',
    '94542',
    '94544',
    '94545',
    '94546',
    '94547',
    '94548',
    '94549',
    '94550',
    '94551',
    '94552',
    '94553',
    '94555',
    '94556',
    '94558',
    '94559',
    '94560',
    '94561',
    '94563',
    '94564',
    '94565',
    '94566',
    '94567',
    '94568',
    '94569',
    '94571',
    '94572',
    '94573',
    '94574',
    '94575',
    '94576',
    '94577',
    '94578',
    '94579',
    '94580',
    '94582',
    '94583',
    '94585',
    '94586',
    '94587',
    '94588',
    '94589',
    '94590',
    '94591',
    '94592',
    '94595',
    '94596',
    '94597',
    '94598',
    '94599',
    '94601',
    '94602',
    '94603',
    '94605',
    '94606',
    '94607',
    '94608',
    '94609',
    '94610',
    '94611',
    '94612',
    '94613',
    '94618',
    '94619',
    '94621',
    '94702',
    '94703',
    '94704',
    '94705',
    '94706',
    '94707',
    '94708',
    '94709',
    '94710',
    '94720',
    '94801',
    '94803',
    '94804',
    '94805',
    '94806',
    '94850',
    '94901',
    '94903',
    '94904',
    '94920',
    '94922',
    '94923',
    '94924',
    '94925',
    '94928',
    '94929',
    '94930',
    '94931',
    '94933',
    '94937',
    '94938',
    '94939',
    '94940',
    '94941',
    '94945',
    '94946',
    '94947',
    '94949',
    '94950',
    '94951',
    '94952',
    '94954',
    '94956',
    '94957',
    '94960',
    '94963',
    '94964',
    '94965',
    '94970',
    '94971',
    '94972',
    '94973',
    '95002',
    '95003',
    '95004',
    '95005',
    '95006',
    '95007',
    '95008',
    '95010',
    '95012',
    '95013',
    '95014',
    '95017',
    '95018',
    '95019',
    '95020',
    '95023',
    '95030',
    '95032',
    '95033',
    '95035',
    '95037',
    '95039',
    '95041',
    '95043',
    '95045',
    '95046',
    '95050',
    '95051',
    '95053',
    '95054',
    '95060',
    '95062',
    '95064',
    '95065',
    '95066',
    '95070',
    '95073',
    '95075',
    '95076',
    '95110',
    '95111',
    '95112',
    '95113',
    '95116',
    '95117',
    '95118',
    '95119',
    '95120',
    '95121',
    '95122',
    '95123',
    '95124',
    '95125',
    '95126',
    '95127',
    '95128',
    '95129',
    '95130',
    '95131',
    '95132',
    '95133',
    '95134',
    '95135',
    '95136',
    '95138',
    '95139',
    '95140',
    '95148',
    '95202',
    '95203',
    '95204',
    '95205',
    '95206',
    '95207',
    '95209',
    '95210',
    '95211',
    '95212',
    '95215',
    '95219',
    '95220',
    '95222',
    '95223',
    '95224',
    '95225',
    '95226',
    '95227',
    '95228',
    '95230',
    '95231',
    '95232',
    '95233',
    '95234',
    '95236',
    '95237',
    '95240',
    '95242',
    '95245',
    '95246',
    '95247',
    '95248',
    '95249',
    '95250',
    '95251',
    '95252',
    '95254',
    '95255',
    '95257',
    '95258',
    '95301',
    '95303',
    '95304',
    '95305',
    '95306',
    '95307',
    '95310',
    '95311',
    '95312',
    '95313',
    '95314',
    '95315',
    '95316',
    '95317',
    '95318',
    '95319',
    '95320',
    '95321',
    '95322',
    '95323',
    '95324',
    '95325',
    '95326',
    '95327',
    '95328',
    '95329',
    '95330',
    '95333',
    '95334',
    '95335',
    '95336',
    '95337',
    '95338',
    '95340',
    '95341',
    '95345',
    '95346',
    '95348',
    '95350',
    '95351',
    '95354',
    '95355',
    '95356',
    '95357',
    '95358',
    '95360',
    '95361',
    '95363',
    '95364',
    '95365',
    '95366',
    '95367',
    '95368',
    '95369',
    '95370',
    '95372',
    '95374',
    '95375',
    '95376',
    '95377',
    '95379',
    '95380',
    '95382',
    '95383',
    '95385',
    '95386',
    '95387',
    '95388',
    '95389',
    '95391',
    '95401',
    '95403',
    '95404',
    '95405',
    '95407',
    '95409',
    '95410',
    '95412',
    '95415',
    '95419',
    '95417',
    '95420',
    '95421',
    '95422',
    '95423',
    '95425',
    '95426',
    '95427',
    '95428',
    '95429',
    '95430',
    '95431',
    '95432',
    '95435',
    '95436',
    '95437',
    '95439',
    '95441',
    '95442',
    '95443',
    '95444',
    '95445',
    '95446',
    '95448',
    '95449',
    '95450',
    '95451',
    '95452',
    '95453',
    '95454',
    '95456',
    '95457',
    '95458',
    '95459',
    '95460',
    '95461',
    '95462',
    '95463',
    '95464',
    '95465',
    '95466',
    '95467',
    '95468',
    '95469',
    '95470',
    '95471',
    '95472',
    '95476',
    '95482',
    '95485',
    '95486',
    '95488',
    '95490',
    '95492',
    '95493',
    '95494',
    '95497',
    '95501',
    '95503',
    '95511',
    '95514',
    '95519',
    '95521',
    '95524',
    '95525',
    '95526',
    '95527',
    '95528',
    '95531',
    '95536',
    '95537',
    '95540',
    '95542',
    '95543',
    '95545',
    '95546',
    '95547',
    '95548',
    '95549',
    '95550',
    '95551',
    '95552',
    '95553',
    '95554',
    '95555',
    '95556',
    '95558',
    '95559',
    '95560',
    '95562',
    '95563',
    '95564',
    '95565',
    '95567',
    '95568',
    '95569',
    '95570',
    '95571',
    '95573',
    '95585',
    '95587',
    '95589',
    '95595',
    '95601',
    '95602',
    '95603',
    '95604',
    '95605',
    '95606',
    '95607',
    '95608',
    '95610',
    '95612',
    '95614',
    '95615',
    '95616',
    '95618',
    '95619',
    '95620',
    '95621',
    '95623',
    '95624',
    '95625',
    '95626',
    '95627',
    '95628',
    '95629',
    '95630',
    '95631',
    '95632',
    '95633',
    '95634',
    '95635',
    '95636',
    '95637',
    '95638',
    '95639',
    '95640',
    '95641',
    '95642',
    '95645',
    '95646',
    '95648',
    '95650',
    '95651',
    '95652',
    '95653',
    '95655',
    '95658',
    '95659',
    '95660',
    '95661',
    '95662',
    '95663',
    '95664',
    '95665',
    '95666',
    '95667',
    '95668',
    '95669',
    '95670',
    '95672',
    '95673',
    '95674',
    '95675',
    '95677',
    '95678',
    '95679',
    '95680',
    '95681',
    '95682',
    '95683',
    '95684',
    '95685',
    '95686',
    '95687',
    '95688',
    '95689',
    '95690',
    '95691',
    '95692',
    '95693',
    '95694',
    '95695',
    '95697',
    '95698',
    '95699',
    '95701',
    '95703',
    '95709',
    '95713',
    '95714',
    '95715',
    '95717',
    '95720',
    '95721',
    '95722',
    '95724',
    '95726',
    '95728',
    '95735',
    '95736',
    '95742',
    '95746',
    '95747',
    '95757',
    '95758',
    '95762',
    '95765',
    '95776',
    '95811',
    '95814',
    '95815',
    '95816',
    '95817',
    '95818',
    '95819',
    '95820',
    '95821',
    '95822',
    '95823',
    '95824',
    '95825',
    '95826',
    '95827',
    '95828',
    '95829',
    '95830',
    '95831',
    '95832',
    '95833',
    '95834',
    '95835',
    '95837',
    '95838',
    '95841',
    '95842',
    '95843',
    '95864',
    '95901',
    '95903',
    '95910',
    '95912',
    '95914',
    '95915',
    '95916',
    '95917',
    '95918',
    '95919',
    '95920',
    '95922',
    '95923',
    '95925',
    '95926',
    '95928',
    '95930',
    '95932',
    '95934',
    '95935',
    '95936',
    '95937',
    '95938',
    '95939',
    '95941',
    '95942',
    '95943',
    '95944',
    '95945',
    '95946',
    '95947',
    '95948',
    '95949',
    '95950',
    '95951',
    '95953',
    '95954',
    '95955',
    '95956',
    '95957',
    '95959',
    '95960',
    '95961',
    '95962',
    '95963',
    '95965',
    '95966',
    '95968',
    '95969',
    '95970',
    '95971',
    '95973',
    '95974',
    '95975',
    '95977',
    '95978',
    '95979',
    '95981',
    '95982',
    '95983',
    '95984',
    '95986',
    '95987',
    '95988',
    '95991',
    '95993',
    '96001',
    '96002',
    '96003',
    '96006',
    '96007',
    '96008',
    '96009',
    '96010',
    '96011',
    '96013',
    '96014',
    '96015',
    '96016',
    '96017',
    '96019',
    '96020',
    '96021',
    '96022',
    '96023',
    '96024',
    '96025',
    '96027',
    '96028',
    '96029',
    '96031',
    '96032',
    '96033',
    '96034',
    '96035',
    '96037',
    '96038',
    '96039',
    '96040',
    '96041',
    '96044',
    '96046',
    '96047',
    '96048',
    '96049',
    '96050',
    '96051',
    '96052',
    '96054',
    '96055',
    '96056',
    '96057',
    '96058',
    '96059',
    '96061',
    '96062',
    '96063',
    '96064',
    '96065',
    '96067',
    '96068',
    '96069',
    '96071',
    '96073',
    '96074',
    '96075',
    '96076',
    '96080',
    '96084',
    '96085',
    '96086',
    '96087',
    '96088',
    '96090',
    '96091',
    '96092',
    '96093',
    '96094',
    '96096',
    '96097',
    '96101',
    '96103',
    '96104',
    '96105',
    '96106',
    '96107',
    '96108',
    '96109',
    '96110',
    '96111',
    '96112',
    '96113',
    '96114',
    '96115',
    '96116',
    '96117',
    '96118',
    '96119',
    '96120',
    '96121',
    '96122',
    '96123',
    '96124',
    '96125',
    '96126',
    '96128',
    '96129',
    '96130',
    '96132',
    '96133',
    '96134',
    '96135',
    '96136',
    '96137',
    '96140',
    '96141',
    '96142',
    '96143',
    '96145',
    '96146',
    '96148',
    '96150',
    '96155',
    '96161',
    '97635',
  ],
  '25': [
    '01001',
    '01002',
    '01003',
    '01005',
    '01007',
    '01008',
    '01009',
    '01010',
    '01011',
    '01012',
    '01013',
    '01020',
    '01022',
    '01026',
    '01027',
    '01028',
    '01029',
    '01030',
    '01031',
    '01032',
    '01033',
    '01034',
    '01035',
    '01036',
    '01037',
    '01038',
    '01039',
    '01040',
    '01050',
    '01053',
    '01054',
    '01056',
    '01057',
    '01060',
    '01062',
    '01063',
    '01066',
    '01068',
    '01069',
    '01070',
    '01071',
    '01072',
    '01073',
    '01074',
    '01075',
    '01077',
    '01079',
    '01080',
    '01081',
    '01082',
    '01083',
    '01084',
    '01085',
    '01086',
    '01088',
    '01089',
    '01092',
    '01093',
    '01094',
    '01095',
    '01096',
    '01097',
    '01098',
    '01103',
    '01104',
    '01105',
    '01106',
    '01107',
    '01108',
    '01109',
    '01118',
    '01119',
    '01128',
    '01129',
    '01151',
    '01199',
    '01201',
    '01220',
    '01222',
    '01223',
    '01224',
    '01225',
    '01226',
    '01229',
    '01230',
    '01235',
    '01236',
    '01237',
    '01238',
    '01240',
    '01242',
    '01243',
    '01244',
    '01245',
    '01247',
    '01253',
    '01254',
    '01255',
    '01256',
    '01257',
    '01258',
    '01259',
    '01260',
    '01262',
    '01264',
    '01266',
    '01267',
    '01270',
    '01301',
    '01330',
    '01331',
    '01337',
    '01338',
    '01339',
    '01340',
    '01341',
    '01342',
    '01343',
    '01344',
    '01346',
    '01347',
    '01349',
    '01350',
    '01351',
    '01354',
    '01355',
    '01360',
    '01364',
    '01366',
    '01367',
    '01368',
    '01370',
    '01373',
    '01375',
    '01376',
    '01378',
    '01379',
    '01420',
    '01430',
    '01431',
    '01432',
    '01434',
    '01436',
    '01438',
    '01440',
    '01450',
    '01451',
    '01452',
    '01453',
    '01460',
    '01462',
    '01463',
    '01464',
    '01467',
    '01468',
    '01469',
    '01473',
    '01474',
    '01475',
    '01501',
    '01503',
    '01504',
    '01505',
    '01506',
    '01507',
    '01510',
    '01515',
    '01516',
    '01518',
    '01519',
    '01520',
    '01521',
    '01522',
    '01523',
    '01524',
    '01525',
    '01527',
    '01529',
    '01531',
    '01532',
    '01534',
    '01535',
    '01536',
    '01537',
    '01540',
    '01541',
    '01542',
    '01543',
    '01545',
    '01550',
    '01560',
    '01561',
    '01562',
    '01564',
    '01566',
    '01568',
    '01569',
    '01570',
    '01571',
    '01581',
    '01583',
    '01585',
    '01588',
    '01590',
    '01602',
    '01603',
    '01604',
    '01605',
    '01606',
    '01607',
    '01608',
    '01609',
    '01610',
    '01611',
    '01612',
    '01701',
    '01702',
    '01718',
    '01719',
    '01720',
    '01721',
    '01730',
    '01731',
    '01740',
    '01741',
    '01742',
    '01745',
    '01746',
    '01747',
    '01748',
    '01749',
    '01752',
    '01754',
    '01756',
    '01757',
    '01760',
    '01770',
    '01772',
    '01773',
    '01775',
    '01776',
    '01778',
    '01801',
    '01803',
    '01810',
    '01821',
    '01824',
    '01826',
    '01827',
    '01830',
    '01832',
    '01833',
    '01834',
    '01835',
    '01840',
    '01841',
    '01843',
    '01844',
    '01845',
    '01850',
    '01851',
    '01852',
    '01854',
    '01860',
    '01862',
    '01863',
    '01864',
    '01867',
    '01876',
    '01879',
    '01880',
    '01886',
    '01887',
    '01890',
    '01901',
    '01902',
    '01904',
    '01905',
    '01906',
    '01907',
    '01908',
    '01913',
    '01915',
    '01921',
    '01922',
    '01923',
    '01929',
    '01930',
    '01937',
    '01938',
    '01940',
    '01944',
    '01945',
    '01949',
    '01950',
    '01951',
    '01952',
    '01960',
    '01966',
    '01969',
    '01970',
    '01982',
    '01983',
    '01984',
    '01985',
    '02019',
    '02021',
    '02025',
    '02026',
    '02030',
    '02032',
    '02035',
    '02038',
    '02043',
    '02045',
    '02047',
    '02048',
    '02050',
    '02052',
    '02053',
    '02054',
    '02056',
    '02061',
    '02062',
    '02066',
    '02067',
    '02071',
    '02072',
    '02081',
    '02090',
    '02093',
    '02108',
    '02109',
    '02110',
    '02111',
    '02113',
    '02114',
    '02115',
    '02116',
    '02118',
    '02119',
    '02120',
    '02121',
    '02122',
    '02124',
    '02125',
    '02126',
    '02127',
    '02128',
    '02129',
    '02130',
    '02131',
    '02132',
    '02134',
    '02135',
    '02136',
    '02138',
    '02139',
    '02140',
    '02141',
    '02142',
    '02143',
    '02144',
    '02145',
    '02148',
    '02149',
    '02150',
    '02151',
    '02152',
    '02155',
    '02163',
    '02169',
    '02170',
    '02171',
    '02176',
    '02180',
    '02184',
    '02186',
    '02188',
    '02189',
    '02190',
    '02191',
    '02199',
    '02203',
    '02210',
    '02215',
    '02301',
    '02302',
    '02322',
    '02324',
    '02330',
    '02332',
    '02333',
    '02338',
    '02339',
    '02341',
    '02343',
    '02346',
    '02347',
    '02351',
    '02356',
    '02357',
    '02359',
    '02360',
    '02364',
    '02366',
    '02367',
    '02368',
    '02370',
    '02375',
    '02379',
    '02382',
    '02420',
    '02421',
    '02445',
    '02446',
    '02451',
    '02452',
    '02453',
    '02457',
    '02458',
    '02459',
    '02460',
    '02461',
    '02462',
    '02464',
    '02465',
    '02466',
    '02467',
    '02468',
    '02472',
    '02474',
    '02476',
    '02478',
    '02481',
    '02482',
    '02492',
    '02493',
    '02494',
    '02532',
    '02534',
    '02535',
    '02536',
    '02537',
    '02538',
    '02539',
    '02540',
    '02542',
    '02543',
    '02553',
    '02554',
    '02556',
    '02557',
    '02558',
    '02559',
    '02561',
    '02562',
    '02563',
    '02564',
    '02568',
    '02571',
    '02575',
    '02576',
    '02584',
    '02601',
    '02630',
    '02631',
    '02632',
    '02633',
    '02635',
    '02637',
    '02638',
    '02639',
    '02641',
    '02642',
    '02643',
    '02644',
    '02645',
    '02646',
    '02647',
    '02648',
    '02649',
    '02650',
    '02651',
    '02652',
    '02653',
    '02655',
    '02657',
    '02659',
    '02660',
    '02663',
    '02664',
    '02666',
    '02667',
    '02668',
    '02669',
    '02670',
    '02671',
    '02672',
    '02673',
    '02675',
    '02702',
    '02703',
    '02713',
    '02715',
    '02717',
    '02718',
    '02719',
    '02720',
    '02721',
    '02723',
    '02724',
    '02725',
    '02726',
    '02738',
    '02739',
    '02740',
    '02743',
    '02744',
    '02745',
    '02746',
    '02747',
    '02748',
    '02760',
    '02762',
    '02763',
    '02764',
    '02766',
    '02767',
    '02769',
    '02770',
    '02771',
    '02777',
    '02779',
    '02780',
    '02790',
    '02791',
    '02861'
  ],
  '72': [
    '00601',
    '00602',
    '00603',
    '00606',
    '00610',
    '00612',
    '00616',
    '00617',
    '00622',
    '00623',
    '00624',
    '00627',
    '00631',
    '00637',
    '00638',
    '00641',
    '00646',
    '00647',
    '00650',
    '00652',
    '00653',
    '00656',
    '00659',
    '00660',
    '00662',
    '00664',
    '00667',
    '00669',
    '00670',
    '00674',
    '00676',
    '00677',
    '00678',
    '00680',
    '00682',
    '00683',
    '00685',
    '00687',
    '00688',
    '00690',
    '00692',
    '00693',
    '00694',
    '00698',
    '00703',
    '00704',
    '00705',
    '00707',
    '00714',
    '00715',
    '00716',
    '00717',
    '00718',
    '00719',
    '00720',
    '00723',
    '00725',
    '00727',
    '00728',
    '00729',
    '00730',
    '00731',
    '00735',
    '00736',
    '00738',
    '00739',
    '00740',
    '00741',
    '00745',
    '00751',
    '00754',
    '00757',
    '00765',
    '00766',
    '00767',
    '00769',
    '00771',
    '00772',
    '00773',
    '00775',
    '00777',
    '00778',
    '00780',
    '00782',
    '00783',
    '00784',
    '00786',
    '00791',
    '00794',
    '00795',
    '00901',
    '00906',
    '00907',
    '00909',
    '00911',
    '00912',
    '00913',
    '00915',
    '00917',
    '00918',
    '00920',
    '00921',
    '00923',
    '00924',
    '00925',
    '00926',
    '00927',
    '00934',
    '00936',
    '00949',
    '00950',
    '00951',
    '00952',
    '00953',
    '00956',
    '00957',
    '00959',
    '00960',
    '00961',
    '00962',
    '00965',
    '00966',
    '00968',
    '00969',
    '00971',
    '00976',
    '00979',
    '00982',
    '00983',
    '00985',
    '00987'
  ],
  '51': [
    '20105',
    '20106',
    '20109',
    '20110',
    '20111',
    '20112',
    '20115',
    '20117',
    '20118',
    '20119',
    '20120',
    '20121',
    '20124',
    '20129',
    '20130',
    '20132',
    '20135',
    '20136',
    '20137',
    '20139',
    '20141',
    '20143',
    '20144',
    '20147',
    '20148',
    '20151',
    '20152',
    '20155',
    '20158',
    '20164',
    '20165',
    '20166',
    '20169',
    '20170',
    '20171',
    '20175',
    '20176',
    '20180',
    '20181',
    '20184',
    '20186',
    '20187',
    '20190',
    '20191',
    '20194',
    '20197',
    '20198',
    '22003',
    '22015',
    '22025',
    '22026',
    '22027',
    '22030',
    '22031',
    '22032',
    '22033',
    '22035',
    '22039',
    '22041',
    '22042',
    '22043',
    '22044',
    '22046',
    '22060',
    '22066',
    '22079',
    '22101',
    '22102',
    '22124',
    '22125',
    '22134',
    '22150',
    '22151',
    '22152',
    '22153',
    '22172',
    '22180',
    '22181',
    '22182',
    '22185',
    '22191',
    '22192',
    '22193',
    '22201',
    '22202',
    '22203',
    '22204',
    '22205',
    '22206',
    '22207',
    '22209',
    '22211',
    '22213',
    '22214',
    '22301',
    '22302',
    '22303',
    '22304',
    '22305',
    '22306',
    '22307',
    '22308',
    '22309',
    '22310',
    '22311',
    '22312',
    '22314',
    '22315',
    '22401',
    '22405',
    '22406',
    '22407',
    '22408',
    '22427',
    '22432',
    '22433',
    '22435',
    '22436',
    '22437',
    '22438',
    '22443',
    '22448',
    '22454',
    '22460',
    '22469',
    '22473',
    '22476',
    '22480',
    '22482',
    '22485',
    '22488',
    '22503',
    '22504',
    '22508',
    '22509',
    '22511',
    '22514',
    '22520',
    '22529',
    '22530',
    '22534',
    '22535',
    '22538',
    '22539',
    '22542',
    '22546',
    '22548',
    '22551',
    '22553',
    '22554',
    '22556',
    '22560',
    '22567',
    '22572',
    '22576',
    '22578',
    '22579',
    '22580',
    '22601',
    '22602',
    '22603',
    '22610',
    '22611',
    '22620',
    '22623',
    '22624',
    '22625',
    '22627',
    '22630',
    '22637',
    '22639',
    '22640',
    '22641',
    '22642',
    '22643',
    '22644',
    '22645',
    '22646',
    '22650',
    '22652',
    '22654',
    '22655',
    '22656',
    '22657',
    '22660',
    '22663',
    '22664',
    '22701',
    '22709',
    '22711',
    '22712',
    '22713',
    '22714',
    '22715',
    '22716',
    '22718',
    '22719',
    '22720',
    '22722',
    '22723',
    '22724',
    '22726',
    '22727',
    '22728',
    '22729',
    '22730',
    '22731',
    '22732',
    '22733',
    '22734',
    '22735',
    '22736',
    '22737',
    '22738',
    '22740',
    '22741',
    '22742',
    '22743',
    '22747',
    '22749',
    '22801',
    '22802',
    '22807',
    '22810',
    '22811',
    '22812',
    '22815',
    '22820',
    '22821',
    '22824',
    '22827',
    '22830',
    '22831',
    '22832',
    '22834',
    '22835',
    '22840',
    '22841',
    '22842',
    '22843',
    '22844',
    '22845',
    '22846',
    '22847',
    '22849',
    '22850',
    '22851',
    '22853',
    '22901',
    '22902',
    '22903',
    '22904',
    '22911',
    '22920',
    '22922',
    '22923',
    '22931',
    '22932',
    '22935',
    '22936',
    '22937',
    '22938',
    '22939',
    '22940',
    '22942',
    '22943',
    '22946',
    '22947',
    '22948',
    '22949',
    '22952',
    '22958',
    '22959',
    '22960',
    '22963',
    '22964',
    '22967',
    '22968',
    '22969',
    '22971',
    '22972',
    '22973',
    '22974',
    '22976',
    '22980',
    '22989',
    '23002',
    '23004',
    '23005',
    '23009',
    '23011',
    '23015',
    '23021',
    '23022',
    '23023',
    '23024',
    '23025',
    '23027',
    '23030',
    '23032',
    '23035',
    '23038',
    '23039',
    '23040',
    '23043',
    '23045',
    '23047',
    '23050',
    '23055',
    '23056',
    '23059',
    '23060',
    '23061',
    '23062',
    '23063',
    '23064',
    '23065',
    '23066',
    '23068',
    '23069',
    '23070',
    '23071',
    '23072',
    '23075',
    '23076',
    '23079',
    '23083',
    '23084',
    '23085',
    '23086',
    '23089',
    '23091',
    '23092',
    '23093',
    '23102',
    '23103',
    '23106',
    '23108',
    '23109',
    '23110',
    '23111',
    '23112',
    '23113',
    '23114',
    '23115',
    '23116',
    '23117',
    '23119',
    '23120',
    '23123',
    '23124',
    '23125',
    '23126',
    '23128',
    '23129',
    '23130',
    '23138',
    '23139',
    '23140',
    '23141',
    '23146',
    '23148',
    '23149',
    '23150',
    '23153',
    '23156',
    '23160',
    '23161',
    '23163',
    '23168',
    '23169',
    '23173',
    '23175',
    '23176',
    '23177',
    '23180',
    '23181',
    '23185',
    '23187',
    '23188',
    '23192',
    '23219',
    '23220',
    '23221',
    '23222',
    '23223',
    '23224',
    '23225',
    '23226',
    '23227',
    '23228',
    '23229',
    '23230',
    '23231',
    '23233',
    '23234',
    '23235',
    '23236',
    '23237',
    '23238',
    '23250',
    '23294',
    '23301',
    '23302',
    '23303',
    '23304',
    '23306',
    '23307',
    '23308',
    '23310',
    '23313',
    '23314',
    '23315',
    '23316',
    '23320',
    '23321',
    '23322',
    '23323',
    '23324',
    '23325',
    '23336',
    '23337',
    '23347',
    '23350',
    '23354',
    '23356',
    '23357',
    '23358',
    '23359',
    '23389',
    '23395',
    '23398',
    '23401',
    '23405',
    '23407',
    '23408',
    '23409',
    '23410',
    '23413',
    '23414',
    '23415',
    '23416',
    '23417',
    '23418',
    '23420',
    '23421',
    '23422',
    '23423',
    '23426',
    '23427',
    '23430',
    '23432',
    '23433',
    '23434',
    '23435',
    '23436',
    '23437',
    '23438',
    '23440',
    '23441',
    '23442',
    '23451',
    '23452',
    '23453',
    '23454',
    '23455',
    '23456',
    '23457',
    '23459',
    '23460',
    '23461',
    '23462',
    '23464',
    '23480',
    '23486',
    '23487',
    '23488',
    '23502',
    '23503',
    '23504',
    '23505',
    '23507',
    '23508',
    '23509',
    '23510',
    '23511',
    '23513',
    '23517',
    '23518',
    '23523',
    '23551',
    '23601',
    '23602',
    '23603',
    '23604',
    '23605',
    '23606',
    '23607',
    '23608',
    '23651',
    '23661',
    '23662',
    '23663',
    '23664',
    '23665',
    '23666',
    '23669',
    '23690',
    '23691',
    '23692',
    '23693',
    '23696',
    '23701',
    '23702',
    '23703',
    '23704',
    '23707',
    '23708',
    '23709',
    '23801',
    '23803',
    '23805',
    '23806',
    '23821',
    '23824',
    '23827',
    '23828',
    '23829',
    '23830',
    '23831',
    '23832',
    '23833',
    '23834',
    '23836',
    '23837',
    '23838',
    '23839',
    '23840',
    '23841',
    '23842',
    '23843',
    '23844',
    '23845',
    '23846',
    '23847',
    '23850',
    '23851',
    '23856',
    '23857',
    '23860',
    '23866',
    '23867',
    '23868',
    '23872',
    '23874',
    '23875',
    '23876',
    '23878',
    '23879',
    '23881',
    '23882',
    '23883',
    '23884',
    '23885',
    '23887',
    '23888',
    '23889',
    '23890',
    '23891',
    '23893',
    '23894',
    '23897',
    '23898',
    '23899',
    '23901',
    '23909',
    '23915',
    '23917',
    '23919',
    '23920',
    '23921',
    '23922',
    '23923',
    '23924',
    '23927',
    '23930',
    '23934',
    '23936',
    '23937',
    '23938',
    '23942',
    '23943',
    '23944',
    '23947',
    '23950',
    '23952',
    '23954',
    '23958',
    '23959',
    '23960',
    '23962',
    '23963',
    '23964',
    '23966',
    '23967',
    '23968',
    '23970',
    '23974',
    '23976',
    '24011',
    '24012',
    '24013',
    '24014',
    '24015',
    '24016',
    '24017',
    '24018',
    '24019',
    '24020',
    '24053',
    '24054',
    '24055',
    '24058',
    '24059',
    '24060',
    '24064',
    '24065',
    '24066',
    '24067',
    '24069',
    '24070',
    '24072',
    '24073',
    '24076',
    '24077',
    '24078',
    '24079',
    '24082',
    '24083',
    '24084',
    '24085',
    '24086',
    '24087',
    '24088',
    '24089',
    '24090',
    '24091',
    '24092',
    '24093',
    '24095',
    '24101',
    '24102',
    '24104',
    '24105',
    '24112',
    '24120',
    '24121',
    '24122',
    '24124',
    '24127',
    '24128',
    '24130',
    '24131',
    '24132',
    '24133',
    '24134',
    '24136',
    '24137',
    '24138',
    '24139',
    '24141',
    '24142',
    '24147',
    '24148',
    '24149',
    '24150',
    '24151',
    '24153',
    '24161',
    '24162',
    '24165',
    '24167',
    '24168',
    '24171',
    '24174',
    '24175',
    '24176',
    '24179',
    '24184',
    '24185',
    '24201',
    '24202',
    '24210',
    '24211',
    '24216',
    '24217',
    '24219',
    '24220',
    '24221',
    '24224',
    '24225',
    '24226',
    '24228',
    '24230',
    '24236',
    '24237',
    '24239',
    '24243',
    '24244',
    '24245',
    '24246',
    '24248',
    '24250',
    '24251',
    '24256',
    '24258',
    '24260',
    '24263',
    '24265',
    '24266',
    '24269',
    '24270',
    '24271',
    '24272',
    '24273',
    '24277',
    '24279',
    '24280',
    '24281',
    '24282',
    '24283',
    '24290',
    '24292',
    '24293',
    '24301',
    '24311',
    '24312',
    '24313',
    '24314',
    '24315',
    '24316',
    '24317',
    '24318',
    '24319',
    '24322',
    '24323',
    '24324',
    '24325',
    '24326',
    '24328',
    '24330',
    '24333',
    '24340',
    '24343',
    '24347',
    '24348',
    '24350',
    '24351',
    '24352',
    '24354',
    '24360',
    '24361',
    '24363',
    '24366',
    '24368',
    '24370',
    '24374',
    '24375',
    '24377',
    '24378',
    '24380',
    '24381',
    '24382',
    '24401',
    '24411',
    '24412',
    '24413',
    '24415',
    '24416',
    '24421',
    '24422',
    '24426',
    '24430',
    '24431',
    '24432',
    '24433',
    '24435',
    '24437',
    '24439',
    '24440',
    '24441',
    '24442',
    '24445',
    '24448',
    '24450',
    '24457',
    '24458',
    '24459',
    '24460',
    '24464',
    '24465',
    '24467',
    '24471',
    '24472',
    '24473',
    '24474',
    '24476',
    '24477',
    '24479',
    '24482',
    '24483',
    '24484',
    '24485',
    '24486',
    '24487',
    '24501',
    '24502',
    '24503',
    '24504',
    '24517',
    '24520',
    '24521',
    '24522',
    '24523',
    '24526',
    '24527',
    '24528',
    '24529',
    '24530',
    '24531',
    '24534',
    '24536',
    '24538',
    '24539',
    '24540',
    '24541',
    '24549',
    '24550',
    '24551',
    '24553',
    '24554',
    '24555',
    '24556',
    '24557',
    '24558',
    '24562',
    '24563',
    '24565',
    '24566',
    '24569',
    '24570',
    '24571',
    '24572',
    '24574',
    '24577',
    '24578',
    '24579',
    '24580',
    '24581',
    '24586',
    '24588',
    '24589',
    '24590',
    '24592',
    '24593',
    '24594',
    '24595',
    '24597',
    '24598',
    '24599',
    '24601',
    '24602',
    '24603',
    '24604',
    '24605',
    '24606',
    '24607',
    '24609',
    '24612',
    '24613',
    '24614',
    '24620',
    '24622',
    '24628',
    '24630',
    '24631',
    '24634',
    '24635',
    '24637',
    '24639',
    '24641',
    '24646',
    '24649',
    '24651',
    '24656',
    '24657'
  ],
  '39': [
    '43001',
    '43002',
    '43003',
    '43004',
    '43005',
    '43006',
    '43008',
    '43009',
    '43010',
    '43011',
    '43013',
    '43014',
    '43015',
    '43016',
    '43017',
    '43019',
    '43021',
    '43022',
    '43023',
    '43025',
    '43026',
    '43028',
    '43029',
    '43030',
    '43031',
    '43032',
    '43033',
    '43035',
    '43036',
    '43037',
    '43040',
    '43044',
    '43045',
    '43046',
    '43050',
    '43054',
    '43055',
    '43056',
    '43060',
    '43061',
    '43062',
    '43064',
    '43065',
    '43066',
    '43067',
    '43068',
    '43070',
    '43071',
    '43072',
    '43074',
    '43076',
    '43077',
    '43078',
    '43080',
    '43081',
    '43082',
    '43084',
    '43085',
    '43101',
    '43102',
    '43103',
    '43105',
    '43106',
    '43107',
    '43109',
    '43110',
    '43111',
    '43112',
    '43113',
    '43115',
    '43116',
    '43117',
    '43119',
    '43123',
    '43125',
    '43126',
    '43127',
    '43128',
    '43130',
    '43135',
    '43136',
    '43137',
    '43138',
    '43140',
    '43142',
    '43143',
    '43144',
    '43145',
    '43146',
    '43147',
    '43148',
    '43149',
    '43150',
    '43151',
    '43152',
    '43153',
    '43154',
    '43155',
    '43156',
    '43157',
    '43158',
    '43160',
    '43162',
    '43164',
    '43201',
    '43202',
    '43203',
    '43204',
    '43205',
    '43206',
    '43207',
    '43209',
    '43210',
    '43211',
    '43212',
    '43213',
    '43214',
    '43215',
    '43217',
    '43219',
    '43220',
    '43221',
    '43222',
    '43223',
    '43224',
    '43227',
    '43228',
    '43229',
    '43230',
    '43231',
    '43232',
    '43235',
    '43240',
    '43302',
    '43310',
    '43311',
    '43314',
    '43315',
    '43316',
    '43317',
    '43318',
    '43319',
    '43320',
    '43321',
    '43322',
    '43323',
    '43324',
    '43326',
    '43330',
    '43331',
    '43332',
    '43333',
    '43334',
    '43336',
    '43337',
    '43338',
    '43340',
    '43341',
    '43342',
    '43343',
    '43344',
    '43345',
    '43347',
    '43348',
    '43351',
    '43356',
    '43357',
    '43358',
    '43359',
    '43360',
    '43402',
    '43403',
    '43406',
    '43407',
    '43408',
    '43410',
    '43412',
    '43413',
    '43416',
    '43420',
    '43430',
    '43431',
    '43432',
    '43433',
    '43434',
    '43435',
    '43437',
    '43438',
    '43439',
    '43440',
    '43442',
    '43443',
    '43445',
    '43446',
    '43447',
    '43449',
    '43450',
    '43451',
    '43452',
    '43456',
    '43457',
    '43458',
    '43460',
    '43462',
    '43463',
    '43464',
    '43465',
    '43466',
    '43467',
    '43468',
    '43469',
    '43501',
    '43502',
    '43504',
    '43505',
    '43506',
    '43511',
    '43512',
    '43515',
    '43516',
    '43517',
    '43518',
    '43519',
    '43521',
    '43522',
    '43523',
    '43524',
    '43525',
    '43526',
    '43527',
    '43528',
    '43529',
    '43531',
    '43532',
    '43533',
    '43534',
    '43535',
    '43536',
    '43537',
    '43540',
    '43541',
    '43542',
    '43543',
    '43545',
    '43547',
    '43548',
    '43549',
    '43551',
    '43553',
    '43554',
    '43555',
    '43556',
    '43557',
    '43558',
    '43560',
    '43565',
    '43566',
    '43567',
    '43569',
    '43570',
    '43571',
    '43604',
    '43605',
    '43606',
    '43607',
    '43608',
    '43609',
    '43610',
    '43611',
    '43612',
    '43613',
    '43614',
    '43615',
    '43616',
    '43617',
    '43619',
    '43620',
    '43623',
    '43701',
    '43711',
    '43713',
    '43716',
    '43717',
    '43718',
    '43719',
    '43720',
    '43721',
    '43722',
    '43723',
    '43724',
    '43725',
    '43727',
    '43728',
    '43730',
    '43731',
    '43732',
    '43733',
    '43734',
    '43735',
    '43736',
    '43738',
    '43739',
    '43740',
    '43746',
    '43747',
    '43748',
    '43749',
    '43750',
    '43754',
    '43755',
    '43756',
    '43758',
    '43759',
    '43760',
    '43761',
    '43762',
    '43764',
    '43766',
    '43767',
    '43768',
    '43771',
    '43772',
    '43773',
    '43777',
    '43778',
    '43779',
    '43780',
    '43782',
    '43783',
    '43786',
    '43787',
    '43788',
    '43793',
    '43802',
    '43804',
    '43805',
    '43811',
    '43812',
    '43821',
    '43822',
    '43824',
    '43830',
    '43832',
    '43836',
    '43837',
    '43840',
    '43842',
    '43843',
    '43844',
    '43845',
    '43901',
    '43902',
    '43903',
    '43905',
    '43906',
    '43907',
    '43908',
    '43910',
    '43912',
    '43913',
    '43914',
    '43915',
    '43917',
    '43920',
    '43925',
    '43926',
    '43927',
    '43928',
    '43930',
    '43931',
    '43932',
    '43933',
    '43934',
    '43935',
    '43938',
    '43939',
    '43940',
    '43942',
    '43943',
    '43944',
    '43945',
    '43946',
    '43947',
    '43948',
    '43950',
    '43951',
    '43952',
    '43953',
    '43961',
    '43962',
    '43963',
    '43964',
    '43967',
    '43968',
    '43970',
    '43971',
    '43972',
    '43973',
    '43974',
    '43976',
    '43977',
    '43983',
    '43985',
    '43986',
    '43988',
    '44001',
    '44003',
    '44004',
    '44010',
    '44011',
    '44012',
    '44017',
    '44021',
    '44022',
    '44023',
    '44024',
    '44026',
    '44028',
    '44030',
    '44032',
    '44035',
    '44039',
    '44040',
    '44041',
    '44044',
    '44045',
    '44046',
    '44047',
    '44048',
    '44049',
    '44050',
    '44052',
    '44053',
    '44054',
    '44055',
    '44056',
    '44057',
    '44060',
    '44062',
    '44064',
    '44065',
    '44067',
    '44070',
    '44072',
    '44074',
    '44076',
    '44077',
    '44080',
    '44081',
    '44082',
    '44084',
    '44085',
    '44086',
    '44087',
    '44089',
    '44090',
    '44092',
    '44093',
    '44094',
    '44095',
    '44099',
    '44101',
    '44102',
    '44103',
    '44104',
    '44105',
    '44106',
    '44107',
    '44108',
    '44109',
    '44110',
    '44111',
    '44112',
    '44113',
    '44114',
    '44115',
    '44116',
    '44117',
    '44118',
    '44119',
    '44120',
    '44121',
    '44122',
    '44123',
    '44124',
    '44125',
    '44126',
    '44127',
    '44128',
    '44129',
    '44130',
    '44131',
    '44132',
    '44133',
    '44134',
    '44135',
    '44136',
    '44137',
    '44138',
    '44139',
    '44140',
    '44141',
    '44142',
    '44143',
    '44144',
    '44145',
    '44146',
    '44147',
    '44149',
    '44201',
    '44202',
    '44203',
    '44212',
    '44214',
    '44215',
    '44216',
    '44217',
    '44221',
    '44223',
    '44224',
    '44230',
    '44231',
    '44233',
    '44234',
    '44235',
    '44236',
    '44240',
    '44241',
    '44243',
    '44250',
    '44251',
    '44253',
    '44254',
    '44255',
    '44256',
    '44260',
    '44262',
    '44264',
    '44265',
    '44266',
    '44270',
    '44272',
    '44273',
    '44274',
    '44275',
    '44276',
    '44278',
    '44280',
    '44281',
    '44285',
    '44286',
    '44287',
    '44288',
    '44301',
    '44302',
    '44303',
    '44304',
    '44305',
    '44306',
    '44307',
    '44308',
    '44310',
    '44311',
    '44312',
    '44313',
    '44314',
    '44319',
    '44320',
    '44321',
    '44333',
    '44401',
    '44402',
    '44403',
    '44404',
    '44405',
    '44406',
    '44408',
    '44410',
    '44411',
    '44412',
    '44413',
    '44417',
    '44418',
    '44420',
    '44423',
    '44425',
    '44427',
    '44428',
    '44429',
    '44430',
    '44431',
    '44432',
    '44436',
    '44437',
    '44438',
    '44439',
    '44440',
    '44441',
    '44442',
    '44443',
    '44444',
    '44445',
    '44446',
    '44449',
    '44450',
    '44451',
    '44452',
    '44454',
    '44455',
    '44460',
    '44470',
    '44471',
    '44473',
    '44481',
    '44483',
    '44484',
    '44485',
    '44490',
    '44491',
    '44493',
    '44502',
    '44503',
    '44504',
    '44505',
    '44506',
    '44507',
    '44509',
    '44510',
    '44511',
    '44512',
    '44514',
    '44515',
    '44601',
    '44606',
    '44607',
    '44608',
    '44609',
    '44610',
    '44611',
    '44612',
    '44613',
    '44614',
    '44615',
    '44618',
    '44620',
    '44621',
    '44622',
    '44624',
    '44625',
    '44626',
    '44627',
    '44628',
    '44629',
    '44632',
    '44633',
    '44634',
    '44637',
    '44638',
    '44640',
    '44641',
    '44643',
    '44644',
    '44645',
    '44646',
    '44647',
    '44651',
    '44652',
    '44653',
    '44654',
    '44656',
    '44657',
    '44659',
    '44661',
    '44662',
    '44663',
    '44666',
    '44667',
    '44669',
    '44670',
    '44671',
    '44672',
    '44675',
    '44676',
    '44677',
    '44678',
    '44680',
    '44681',
    '44682',
    '44683',
    '44685',
    '44687',
    '44688',
    '44689',
    '44690',
    '44691',
    '44693',
    '44695',
    '44697',
    '44699',
    '44702',
    '44703',
    '44704',
    '44705',
    '44706',
    '44707',
    '44708',
    '44709',
    '44710',
    '44714',
    '44718',
    '44720',
    '44721',
    '44730',
    '44802',
    '44804',
    '44805',
    '44807',
    '44809',
    '44811',
    '44813',
    '44814',
    '44815',
    '44816',
    '44817',
    '44818',
    '44820',
    '44822',
    '44824',
    '44825',
    '44826',
    '44827',
    '44828',
    '44830',
    '44833',
    '44836',
    '44837',
    '44838',
    '44839',
    '44840',
    '44841',
    '44842',
    '44843',
    '44844',
    '44846',
    '44847',
    '44849',
    '44850',
    '44851',
    '44853',
    '44854',
    '44855',
    '44856',
    '44857',
    '44859',
    '44861',
    '44864',
    '44865',
    '44866',
    '44867',
    '44870',
    '44874',
    '44875',
    '44878',
    '44880',
    '44881',
    '44882',
    '44883',
    '44887',
    '44889',
    '44890',
    '44901',
    '44902',
    '44903',
    '44904',
    '44905',
    '44906',
    '44907',
    '45001',
    '45002',
    '45003',
    '45005',
    '45011',
    '45013',
    '45014',
    '45015',
    '45030',
    '45032',
    '45033',
    '45034',
    '45036',
    '45039',
    '45040',
    '45041',
    '45042',
    '45044',
    '45050',
    '45051',
    '45052',
    '45053',
    '45054',
    '45056',
    '45062',
    '45064',
    '45065',
    '45066',
    '45067',
    '45068',
    '45069',
    '45070',
    '45101',
    '45102',
    '45103',
    '45106',
    '45107',
    '45111',
    '45112',
    '45113',
    '45115',
    '45118',
    '45120',
    '45121',
    '45122',
    '45123',
    '45130',
    '45131',
    '45132',
    '45133',
    '45135',
    '45140',
    '45142',
    '45144',
    '45145',
    '45146',
    '45147',
    '45148',
    '45150',
    '45152',
    '45153',
    '45154',
    '45155',
    '45156',
    '45157',
    '45158',
    '45159',
    '45160',
    '45162',
    '45164',
    '45166',
    '45167',
    '45168',
    '45169',
    '45171',
    '45172',
    '45174',
    '45176',
    '45177',
    '45202',
    '45203',
    '45204',
    '45205',
    '45206',
    '45207',
    '45208',
    '45209',
    '45211',
    '45212',
    '45213',
    '45214',
    '45215',
    '45216',
    '45217',
    '45218',
    '45219',
    '45220',
    '45223',
    '45224',
    '45225',
    '45226',
    '45227',
    '45229',
    '45230',
    '45231',
    '45232',
    '45233',
    '45236',
    '45237',
    '45238',
    '45239',
    '45240',
    '45241',
    '45242',
    '45243',
    '45244',
    '45245',
    '45246',
    '45247',
    '45248',
    '45249',
    '45251',
    '45252',
    '45255',
    '45301',
    '45302',
    '45303',
    '45304',
    '45305',
    '45306',
    '45307',
    '45308',
    '45309',
    '45310',
    '45311',
    '45312',
    '45314',
    '45315',
    '45316',
    '45317',
    '45318',
    '45319',
    '45320',
    '45321',
    '45322',
    '45323',
    '45324',
    '45325',
    '45326',
    '45327',
    '45328',
    '45330',
    '45331',
    '45332',
    '45333',
    '45334',
    '45335',
    '45336',
    '45337',
    '45338',
    '45339',
    '45340',
    '45341',
    '45342',
    '45344',
    '45345',
    '45346',
    '45347',
    '45348',
    '45349',
    '45350',
    '45351',
    '45352',
    '45353',
    '45354',
    '45356',
    '45358',
    '45359',
    '45360',
    '45361',
    '45362',
    '45363',
    '45365',
    '45368',
    '45369',
    '45370',
    '45371',
    '45372',
    '45373',
    '45377',
    '45378',
    '45380',
    '45381',
    '45382',
    '45383',
    '45384',
    '45385',
    '45387',
    '45388',
    '45389',
    '45390',
    '45402',
    '45403',
    '45404',
    '45405',
    '45406',
    '45409',
    '45410',
    '45414',
    '45415',
    '45416',
    '45417',
    '45418',
    '45419',
    '45420',
    '45424',
    '45426',
    '45428',
    '45429',
    '45430',
    '45431',
    '45432',
    '45433',
    '45434',
    '45439',
    '45440',
    '45449',
    '45458',
    '45459',
    '45502',
    '45503',
    '45504',
    '45505',
    '45506',
    '45601',
    '45612',
    '45613',
    '45614',
    '45616',
    '45617',
    '45618',
    '45619',
    '45620',
    '45621',
    '45622',
    '45623',
    '45624',
    '45628',
    '45629',
    '45630',
    '45631',
    '45634',
    '45636',
    '45638',
    '45640',
    '45642',
    '45644',
    '45645',
    '45646',
    '45647',
    '45648',
    '45650',
    '45651',
    '45652',
    '45653',
    '45654',
    '45656',
    '45657',
    '45658',
    '45659',
    '45660',
    '45661',
    '45662',
    '45663',
    '45669',
    '45671',
    '45672',
    '45673',
    '45674',
    '45678',
    '45679',
    '45680',
    '45681',
    '45682',
    '45684',
    '45685',
    '45686',
    '45688',
    '45690',
    '45692',
    '45693',
    '45694',
    '45695',
    '45696',
    '45697',
    '45698',
    '45701',
    '45710',
    '45711',
    '45714',
    '45715',
    '45716',
    '45719',
    '45721',
    '45723',
    '45724',
    '45727',
    '45729',
    '45732',
    '45734',
    '45735',
    '45740',
    '45741',
    '45742',
    '45743',
    '45744',
    '45745',
    '45746',
    '45750',
    '45760',
    '45761',
    '45764',
    '45766',
    '45767',
    '45768',
    '45769',
    '45770',
    '45771',
    '45772',
    '45773',
    '45775',
    '45776',
    '45778',
    '45779',
    '45780',
    '45782',
    '45784',
    '45786',
    '45787',
    '45788',
    '45789',
    '45801',
    '45804',
    '45805',
    '45806',
    '45807',
    '45808',
    '45809',
    '45810',
    '45812',
    '45813',
    '45814',
    '45816',
    '45817',
    '45819',
    '45820',
    '45821',
    '45822',
    '45826',
    '45827',
    '45828',
    '45830',
    '45831',
    '45832',
    '45833',
    '45835',
    '45836',
    '45838',
    '45840',
    '45841',
    '45843',
    '45844',
    '45845',
    '45846',
    '45849',
    '45850',
    '45851',
    '45853',
    '45854',
    '45855',
    '45856',
    '45858',
    '45859',
    '45860',
    '45861',
    '45862',
    '45863',
    '45864',
    '45865',
    '45866',
    '45867',
    '45868',
    '45869',
    '45870',
    '45871',
    '45872',
    '45873',
    '45874',
    '45875',
    '45876',
    '45877',
    '45879',
    '45880',
    '45881',
    '45882',
    '45883',
    '45884',
    '45885',
    '45886',
    '45887',
    '45888',
    '45889',
    '45890',
    '45891',
    '45894',
    '45895',
    '45896',
    '45897',
    '45898',
    '45899'
  ],
  '11': [
    '20001',
    '20002',
    '20003',
    '20004',
    '20005',
    '20006',
    '20007',
    '20008',
    '20009',
    '20010',
    '20011',
    '20012',
    '20015',
    '20016',
    '20017',
    '20018',
    '20019',
    '20020',
    '20024',
    '20032',
    '20036',
    '20037',
    '20045',
    '20052',
    '20053',
    '20057',
    '20064',
    '20202',
    '20204',
    '20228',
    '20230',
    '20240',
    '20245',
    '20260',
    '20307',
    '20317',
    '20319',
    '20373',
    '20390',
    '20405',
    '20418',
    '20427',
    '20506',
    '20510',
    '20520',
    '20535',
    '20540',
    '20551',
    '20553',
    '20560',
    '20565',
    '20566',
    '20593'
  ],
  '26': [
    '48001',
    '48002',
    '48003',
    '48005',
    '48006',
    '48009',
    '48014',
    '48015',
    '48017',
    '48021',
    '48022',
    '48023',
    '48025',
    '48026',
    '48027',
    '48028',
    '48030',
    '48032',
    '48033',
    '48034',
    '48035',
    '48036',
    '48038',
    '48039',
    '48040',
    '48041',
    '48042',
    '48043',
    '48044',
    '48045',
    '48047',
    '48048',
    '48049',
    '48050',
    '48051',
    '48054',
    '48059',
    '48060',
    '48062',
    '48063',
    '48064',
    '48065',
    '48066',
    '48067',
    '48069',
    '48070',
    '48071',
    '48072',
    '48073',
    '48074',
    '48075',
    '48076',
    '48079',
    '48080',
    '48081',
    '48082',
    '48083',
    '48084',
    '48085',
    '48088',
    '48089',
    '48091',
    '48092',
    '48093',
    '48094',
    '48095',
    '48096',
    '48097',
    '48098',
    '48101',
    '48103',
    '48104',
    '48105',
    '48108',
    '48109',
    '48111',
    '48114',
    '48116',
    '48117',
    '48118',
    '48120',
    '48122',
    '48124',
    '48125',
    '48126',
    '48127',
    '48128',
    '48130',
    '48131',
    '48133',
    '48134',
    '48135',
    '48137',
    '48138',
    '48139',
    '48140',
    '48141',
    '48143',
    '48144',
    '48145',
    '48146',
    '48150',
    '48152',
    '48154',
    '48157',
    '48158',
    '48159',
    '48160',
    '48161',
    '48162',
    '48164',
    '48165',
    '48166',
    '48167',
    '48168',
    '48169',
    '48170',
    '48173',
    '48174',
    '48176',
    '48177',
    '48178',
    '48179',
    '48180',
    '48182',
    '48183',
    '48184',
    '48185',
    '48186',
    '48187',
    '48188',
    '48189',
    '48190',
    '48191',
    '48192',
    '48193',
    '48195',
    '48197',
    '48198',
    '48201',
    '48202',
    '48203',
    '48204',
    '48205',
    '48206',
    '48207',
    '48208',
    '48209',
    '48210',
    '48211',
    '48212',
    '48213',
    '48214',
    '48215',
    '48216',
    '48217',
    '48218',
    '48219',
    '48220',
    '48221',
    '48223',
    '48224',
    '48225',
    '48226',
    '48227',
    '48228',
    '48229',
    '48230',
    '48233',
    '48234',
    '48235',
    '48236',
    '48237',
    '48238',
    '48239',
    '48240',
    '48242',
    '48243',
    '48301',
    '48302',
    '48304',
    '48306',
    '48307',
    '48309',
    '48310',
    '48312',
    '48313',
    '48314',
    '48315',
    '48316',
    '48317',
    '48320',
    '48322',
    '48323',
    '48324',
    '48326',
    '48327',
    '48328',
    '48329',
    '48331',
    '48334',
    '48335',
    '48336',
    '48340',
    '48341',
    '48342',
    '48346',
    '48348',
    '48350',
    '48353',
    '48356',
    '48357',
    '48359',
    '48360',
    '48362',
    '48363',
    '48367',
    '48370',
    '48371',
    '48374',
    '48375',
    '48377',
    '48380',
    '48381',
    '48382',
    '48383',
    '48386',
    '48390',
    '48393',
    '48397',
    '48401',
    '48411',
    '48412',
    '48413',
    '48414',
    '48415',
    '48416',
    '48417',
    '48418',
    '48419',
    '48420',
    '48421',
    '48422',
    '48423',
    '48426',
    '48427',
    '48428',
    '48429',
    '48430',
    '48432',
    '48433',
    '48434',
    '48435',
    '48436',
    '48437',
    '48438',
    '48439',
    '48440',
    '48441',
    '48442',
    '48444',
    '48445',
    '48446',
    '48449',
    '48450',
    '48451',
    '48453',
    '48454',
    '48455',
    '48456',
    '48457',
    '48458',
    '48460',
    '48461',
    '48462',
    '48463',
    '48464',
    '48465',
    '48466',
    '48467',
    '48468',
    '48469',
    '48470',
    '48471',
    '48472',
    '48473',
    '48475',
    '48476',
    '48502',
    '48503',
    '48504',
    '48505',
    '48506',
    '48507',
    '48509',
    '48519',
    '48529',
    '48532',
    '48551',
    '48553',
    '48554',
    '48601',
    '48602',
    '48603',
    '48604',
    '48607',
    '48609',
    '48610',
    '48611',
    '48612',
    '48613',
    '48614',
    '48615',
    '48616',
    '48617',
    '48618',
    '48619',
    '48621',
    '48622',
    '48623',
    '48624',
    '48625',
    '48626',
    '48627',
    '48628',
    '48629',
    '48630',
    '48631',
    '48632',
    '48633',
    '48634',
    '48635',
    '48636',
    '48637',
    '48638',
    '48640',
    '48642',
    '48647',
    '48649',
    '48650',
    '48651',
    '48652',
    '48653',
    '48654',
    '48655',
    '48656',
    '48657',
    '48658',
    '48659',
    '48661',
    '48662',
    '48667',
    '48701',
    '48703',
    '48705',
    '48706',
    '48708',
    '48710',
    '48720',
    '48721',
    '48722',
    '48723',
    '48724',
    '48725',
    '48726',
    '48727',
    '48728',
    '48729',
    '48730',
    '48731',
    '48732',
    '48733',
    '48734',
    '48735',
    '48737',
    '48738',
    '48739',
    '48740',
    '48741',
    '48742',
    '48743',
    '48744',
    '48745',
    '48746',
    '48747',
    '48748',
    '48749',
    '48750',
    '48754',
    '48755',
    '48756',
    '48757',
    '48759',
    '48760',
    '48761',
    '48762',
    '48763',
    '48765',
    '48766',
    '48767',
    '48768',
    '48770',
    '48801',
    '48806',
    '48807',
    '48808',
    '48809',
    '48811',
    '48813',
    '48815',
    '48816',
    '48817',
    '48818',
    '48819',
    '48820',
    '48821',
    '48822',
    '48823',
    '48825',
    '48827',
    '48829',
    '48831',
    '48832',
    '48834',
    '48835',
    '48836',
    '48837',
    '48838',
    '48840',
    '48841',
    '48842',
    '48843',
    '48845',
    '48846',
    '48847',
    '48848',
    '48849',
    '48850',
    '48851',
    '48852',
    '48853',
    '48854',
    '48855',
    '48856',
    '48857',
    '48858',
    '48860',
    '48861',
    '48864',
    '48865',
    '48866',
    '48867',
    '48870',
    '48871',
    '48872',
    '48873',
    '48874',
    '48875',
    '48876',
    '48877',
    '48878',
    '48879',
    '48880',
    '48881',
    '48883',
    '48884',
    '48885',
    '48886',
    '48888',
    '48889',
    '48890',
    '48891',
    '48892',
    '48893',
    '48894',
    '48895',
    '48896',
    '48897',
    '48906',
    '48910',
    '48911',
    '48912',
    '48915',
    '48917',
    '48921',
    '48933',
    '49001',
    '49002',
    '49004',
    '49006',
    '49007',
    '49008',
    '49009',
    '49010',
    '49011',
    '49012',
    '49013',
    '49014',
    '49015',
    '49017',
    '49021',
    '49022',
    '49024',
    '49026',
    '49027',
    '49028',
    '49029',
    '49030',
    '49031',
    '49032',
    '49033',
    '49034',
    '49036',
    '49037',
    '49038',
    '49040',
    '49042',
    '49043',
    '49045',
    '49046',
    '49047',
    '49048',
    '49050',
    '49051',
    '49052',
    '49053',
    '49055',
    '49056',
    '49057',
    '49058',
    '49060',
    '49061',
    '49064',
    '49065',
    '49066',
    '49067',
    '49068',
    '49070',
    '49071',
    '49072',
    '49073',
    '49074',
    '49075',
    '49076',
    '49078',
    '49079',
    '49080',
    '49082',
    '49083',
    '49084',
    '49085',
    '49087',
    '49088',
    '49089',
    '49090',
    '49091',
    '49092',
    '49093',
    '49094',
    '49095',
    '49096',
    '49097',
    '49098',
    '49099',
    '49101',
    '49102',
    '49103',
    '49104',
    '49106',
    '49107',
    '49111',
    '49112',
    '49113',
    '49115',
    '49116',
    '49117',
    '49119',
    '49120',
    '49125',
    '49126',
    '49127',
    '49128',
    '49129',
    '49130',
    '49201',
    '49202',
    '49203',
    '49220',
    '49221',
    '49224',
    '49227',
    '49228',
    '49229',
    '49230',
    '49232',
    '49233',
    '49234',
    '49235',
    '49236',
    '49237',
    '49238',
    '49240',
    '49241',
    '49242',
    '49245',
    '49246',
    '49247',
    '49248',
    '49249',
    '49250',
    '49251',
    '49252',
    '49253',
    '49254',
    '49255',
    '49256',
    '49259',
    '49261',
    '49262',
    '49263',
    '49264',
    '49265',
    '49266',
    '49267',
    '49268',
    '49269',
    '49270',
    '49271',
    '49272',
    '49274',
    '49276',
    '49277',
    '49279',
    '49282',
    '49283',
    '49284',
    '49285',
    '49286',
    '49287',
    '49288',
    '49289',
    '49301',
    '49302',
    '49303',
    '49304',
    '49305',
    '49306',
    '49307',
    '49309',
    '49310',
    '49312',
    '49315',
    '49316',
    '49318',
    '49319',
    '49320',
    '49321',
    '49322',
    '49323',
    '49325',
    '49326',
    '49327',
    '49328',
    '49329',
    '49330',
    '49331',
    '49332',
    '49333',
    '49335',
    '49336',
    '49337',
    '49338',
    '49339',
    '49340',
    '49341',
    '49342',
    '49343',
    '49344',
    '49345',
    '49346',
    '49347',
    '49348',
    '49349',
    '49401',
    '49402',
    '49403',
    '49404',
    '49405',
    '49406',
    '49408',
    '49410',
    '49411',
    '49412',
    '49415',
    '49417',
    '49418',
    '49419',
    '49420',
    '49421',
    '49423',
    '49424',
    '49425',
    '49426',
    '49428',
    '49431',
    '49434',
    '49435',
    '49436',
    '49437',
    '49440',
    '49441',
    '49442',
    '49444',
    '49445',
    '49446',
    '49448',
    '49449',
    '49450',
    '49451',
    '49452',
    '49453',
    '49454',
    '49455',
    '49456',
    '49457',
    '49458',
    '49459',
    '49460',
    '49461',
    '49464',
    '49503',
    '49504',
    '49505',
    '49506',
    '49507',
    '49508',
    '49509',
    '49512',
    '49519',
    '49525',
    '49534',
    '49544',
    '49546',
    '49548',
    '49601',
    '49611',
    '49612',
    '49613',
    '49614',
    '49615',
    '49616',
    '49617',
    '49618',
    '49619',
    '49620',
    '49621',
    '49622',
    '49623',
    '49625',
    '49626',
    '49627',
    '49628',
    '49629',
    '49630',
    '49631',
    '49632',
    '49633',
    '49634',
    '49635',
    '49636',
    '49637',
    '49638',
    '49639',
    '49640',
    '49642',
    '49643',
    '49644',
    '49645',
    '49646',
    '49648',
    '49649',
    '49650',
    '49651',
    '49653',
    '49654',
    '49655',
    '49656',
    '49657',
    '49659',
    '49660',
    '49663',
    '49664',
    '49665',
    '49666',
    '49667',
    '49668',
    '49670',
    '49674',
    '49675',
    '49676',
    '49677',
    '49679',
    '49680',
    '49682',
    '49683',
    '49684',
    '49686',
    '49688',
    '49689',
    '49690',
    '49701',
    '49705',
    '49706',
    '49707',
    '49709',
    '49710',
    '49712',
    '49713',
    '49715',
    '49716',
    '49717',
    '49718',
    '49719',
    '49720',
    '49721',
    '49722',
    '49724',
    '49725',
    '49726',
    '49727',
    '49728',
    '49729',
    '49730',
    '49733',
    '49735',
    '49736',
    '49738',
    '49740',
    '49743',
    '49744',
    '49745',
    '49746',
    '49747',
    '49748',
    '49749',
    '49751',
    '49752',
    '49753',
    '49755',
    '49756',
    '49757',
    '49759',
    '49760',
    '49762',
    '49764',
    '49765',
    '49766',
    '49768',
    '49769',
    '49770',
    '49774',
    '49775',
    '49776',
    '49777',
    '49779',
    '49780',
    '49781',
    '49782',
    '49783',
    '49788',
    '49791',
    '49793',
    '49795',
    '49796',
    '49799',
    '49801',
    '49802',
    '49805',
    '49806',
    '49807',
    '49808',
    '49812',
    '49814',
    '49815',
    '49816',
    '49817',
    '49818',
    '49819',
    '49820',
    '49821',
    '49822',
    '49825',
    '49826',
    '49827',
    '49829',
    '49831',
    '49833',
    '49834',
    '49835',
    '49836',
    '49837',
    '49838',
    '49839',
    '49840',
    '49841',
    '49847',
    '49848',
    '49849',
    '49852',
    '49853',
    '49854',
    '49855',
    '49858',
    '49861',
    '49862',
    '49863',
    '49864',
    '49866',
    '49868',
    '49870',
    '49871',
    '49872',
    '49873',
    '49874',
    '49876',
    '49877',
    '49878',
    '49879',
    '49880',
    '49881',
    '49883',
    '49884',
    '49885',
    '49886',
    '49887',
    '49891',
    '49892',
    '49893',
    '49894',
    '49895',
    '49896',
    '49901',
    '49902',
    '49903',
    '49905',
    '49908',
    '49910',
    '49911',
    '49912',
    '49913',
    '49915',
    '49916',
    '49917',
    '49918',
    '49919',
    '49920',
    '49921',
    '49922',
    '49925',
    '49927',
    '49929',
    '49930',
    '49931',
    '49934',
    '49935',
    '49938',
    '49942',
    '49945',
    '49946',
    '49947',
    '49948',
    '49950',
    '49952',
    '49953',
    '49955',
    '49958',
    '49959',
    '49960',
    '49961',
    '49962',
    '49963',
    '49965',
    '49967',
    '49968',
    '49969',
    '49970',
    '49971',
    '54540',
    '54554'
  ],
  '27': [
    '51360',
    '55001',
    '55003',
    '55005',
    '55006',
    '55007',
    '55008',
    '55009',
    '55011',
    '55012',
    '55013',
    '55014',
    '55016',
    '55017',
    '55018',
    '55019',
    '55020',
    '55021',
    '55024',
    '55025',
    '55026',
    '55027',
    '55029',
    '55030',
    '55031',
    '55032',
    '55033',
    '55036',
    '55037',
    '55038',
    '55040',
    '55041',
    '55042',
    '55043',
    '55044',
    '55045',
    '55046',
    '55047',
    '55049',
    '55051',
    '55052',
    '55053',
    '55054',
    '55055',
    '55056',
    '55057',
    '55060',
    '55063',
    '55065',
    '55066',
    '55068',
    '55069',
    '55070',
    '55071',
    '55072',
    '55073',
    '55074',
    '55075',
    '55076',
    '55077',
    '55079',
    '55080',
    '55082',
    '55084',
    '55085',
    '55087',
    '55088',
    '55089',
    '55090',
    '55092',
    '55101',
    '55102',
    '55103',
    '55104',
    '55105',
    '55106',
    '55107',
    '55108',
    '55109',
    '55110',
    '55111',
    '55112',
    '55113',
    '55114',
    '55115',
    '55116',
    '55117',
    '55118',
    '55119',
    '55120',
    '55121',
    '55122',
    '55123',
    '55124',
    '55125',
    '55126',
    '55127',
    '55128',
    '55129',
    '55130',
    '55150',
    '55155',
    '55301',
    '55302',
    '55303',
    '55304',
    '55305',
    '55306',
    '55307',
    '55308',
    '55309',
    '55310',
    '55311',
    '55312',
    '55313',
    '55314',
    '55315',
    '55316',
    '55317',
    '55318',
    '55319',
    '55320',
    '55321',
    '55322',
    '55324',
    '55325',
    '55327',
    '55328',
    '55329',
    '55330',
    '55331',
    '55332',
    '55333',
    '55334',
    '55335',
    '55336',
    '55337',
    '55338',
    '55339',
    '55340',
    '55341',
    '55342',
    '55343',
    '55344',
    '55345',
    '55346',
    '55347',
    '55349',
    '55350',
    '55352',
    '55353',
    '55354',
    '55355',
    '55356',
    '55357',
    '55358',
    '55359',
    '55360',
    '55362',
    '55363',
    '55364',
    '55366',
    '55367',
    '55368',
    '55369',
    '55370',
    '55371',
    '55372',
    '55373',
    '55374',
    '55375',
    '55376',
    '55378',
    '55379',
    '55381',
    '55382',
    '55384',
    '55385',
    '55386',
    '55387',
    '55388',
    '55389',
    '55390',
    '55391',
    '55395',
    '55396',
    '55397',
    '55398',
    '55401',
    '55402',
    '55403',
    '55404',
    '55405',
    '55406',
    '55407',
    '55408',
    '55409',
    '55410',
    '55411',
    '55412',
    '55413',
    '55414',
    '55415',
    '55416',
    '55417',
    '55418',
    '55419',
    '55420',
    '55421',
    '55422',
    '55423',
    '55424',
    '55425',
    '55426',
    '55427',
    '55428',
    '55429',
    '55430',
    '55431',
    '55432',
    '55433',
    '55434',
    '55435',
    '55436',
    '55437',
    '55438',
    '55439',
    '55441',
    '55442',
    '55443',
    '55444',
    '55445',
    '55446',
    '55447',
    '55448',
    '55449',
    '55450',
    '55454',
    '55455',
    '55601',
    '55602',
    '55603',
    '55604',
    '55605',
    '55606',
    '55607',
    '55609',
    '55612',
    '55613',
    '55614',
    '55615',
    '55616',
    '55702',
    '55703',
    '55704',
    '55705',
    '55706',
    '55707',
    '55708',
    '55709',
    '55710',
    '55711',
    '55712',
    '55713',
    '55716',
    '55717',
    '55718',
    '55719',
    '55720',
    '55721',
    '55722',
    '55723',
    '55724',
    '55725',
    '55726',
    '55731',
    '55732',
    '55733',
    '55734',
    '55735',
    '55736',
    '55738',
    '55741',
    '55742',
    '55744',
    '55746',
    '55748',
    '55749',
    '55750',
    '55751',
    '55752',
    '55753',
    '55756',
    '55757',
    '55758',
    '55760',
    '55763',
    '55764',
    '55765',
    '55766',
    '55767',
    '55768',
    '55769',
    '55771',
    '55772',
    '55775',
    '55779',
    '55780',
    '55781',
    '55782',
    '55783',
    '55784',
    '55785',
    '55786',
    '55787',
    '55790',
    '55792',
    '55793',
    '55795',
    '55797',
    '55798',
    '55802',
    '55803',
    '55804',
    '55805',
    '55806',
    '55807',
    '55808',
    '55810',
    '55811',
    '55812',
    '55814',
    '55901',
    '55902',
    '55904',
    '55905',
    '55906',
    '55909',
    '55910',
    '55912',
    '55917',
    '55918',
    '55919',
    '55920',
    '55921',
    '55922',
    '55923',
    '55924',
    '55925',
    '55926',
    '55927',
    '55929',
    '55931',
    '55932',
    '55933',
    '55934',
    '55935',
    '55936',
    '55939',
    '55940',
    '55941',
    '55943',
    '55944',
    '55945',
    '55946',
    '55947',
    '55949',
    '55950',
    '55951',
    '55952',
    '55953',
    '55954',
    '55955',
    '55956',
    '55957',
    '55959',
    '55960',
    '55961',
    '55962',
    '55963',
    '55964',
    '55965',
    '55967',
    '55968',
    '55969',
    '55970',
    '55971',
    '55972',
    '55973',
    '55974',
    '55975',
    '55976',
    '55977',
    '55979',
    '55981',
    '55982',
    '55983',
    '55985',
    '55987',
    '55990',
    '55991',
    '55992',
    '56001',
    '56003',
    '56007',
    '56009',
    '56010',
    '56011',
    '56013',
    '56014',
    '56016',
    '56017',
    '56019',
    '56020',
    '56021',
    '56022',
    '56023',
    '56024',
    '56025',
    '56026',
    '56027',
    '56028',
    '56029',
    '56031',
    '56032',
    '56033',
    '56034',
    '56035',
    '56036',
    '56037',
    '56039',
    '56041',
    '56042',
    '56043',
    '56044',
    '56045',
    '56046',
    '56047',
    '56048',
    '56050',
    '56051',
    '56052',
    '56054',
    '56055',
    '56056',
    '56057',
    '56058',
    '56060',
    '56062',
    '56063',
    '56065',
    '56068',
    '56069',
    '56071',
    '56072',
    '56073',
    '56074',
    '56075',
    '56078',
    '56080',
    '56081',
    '56082',
    '56083',
    '56085',
    '56087',
    '56088',
    '56089',
    '56090',
    '56091',
    '56093',
    '56096',
    '56097',
    '56098',
    '56101',
    '56110',
    '56111',
    '56113',
    '56114',
    '56115',
    '56116',
    '56117',
    '56118',
    '56119',
    '56120',
    '56121',
    '56122',
    '56123',
    '56125',
    '56127',
    '56128',
    '56129',
    '56131',
    '56132',
    '56134',
    '56136',
    '56137',
    '56138',
    '56139',
    '56140',
    '56141',
    '56142',
    '56143',
    '56144',
    '56145',
    '56146',
    '56147',
    '56149',
    '56150',
    '56151',
    '56152',
    '56153',
    '56155',
    '56156',
    '56157',
    '56158',
    '56159',
    '56160',
    '56161',
    '56162',
    '56164',
    '56165',
    '56166',
    '56167',
    '56168',
    '56169',
    '56170',
    '56171',
    '56172',
    '56173',
    '56174',
    '56175',
    '56176',
    '56177',
    '56178',
    '56180',
    '56181',
    '56183',
    '56185',
    '56186',
    '56187',
    '56201',
    '56207',
    '56208',
    '56209',
    '56210',
    '56211',
    '56212',
    '56214',
    '56215',
    '56216',
    '56218',
    '56219',
    '56220',
    '56221',
    '56222',
    '56223',
    '56224',
    '56225',
    '56226',
    '56227',
    '56228',
    '56229',
    '56230',
    '56231',
    '56232',
    '56235',
    '56236',
    '56237',
    '56239',
    '56240',
    '56241',
    '56243',
    '56244',
    '56245',
    '56248',
    '56249',
    '56251',
    '56252',
    '56253',
    '56255',
    '56256',
    '56257',
    '56258',
    '56260',
    '56262',
    '56263',
    '56264',
    '56265',
    '56266',
    '56267',
    '56270',
    '56271',
    '56273',
    '56274',
    '56276',
    '56277',
    '56278',
    '56279',
    '56280',
    '56281',
    '56282',
    '56283',
    '56284',
    '56285',
    '56287',
    '56288',
    '56289',
    '56291',
    '56292',
    '56293',
    '56294',
    '56295',
    '56296',
    '56297',
    '56301',
    '56303',
    '56304',
    '56307',
    '56308',
    '56309',
    '56310',
    '56311',
    '56312',
    '56313',
    '56314',
    '56315',
    '56316',
    '56318',
    '56319',
    '56320',
    '56321',
    '56323',
    '56324',
    '56325',
    '56326',
    '56327',
    '56328',
    '56329',
    '56330',
    '56331',
    '56332',
    '56334',
    '56335',
    '56336',
    '56338',
    '56339',
    '56340',
    '56342',
    '56343',
    '56345',
    '56347',
    '56349',
    '56350',
    '56352',
    '56353',
    '56354',
    '56355',
    '56356',
    '56357',
    '56358',
    '56359',
    '56360',
    '56361',
    '56362',
    '56363',
    '56364',
    '56367',
    '56368',
    '56369',
    '56371',
    '56373',
    '56374',
    '56375',
    '56376',
    '56377',
    '56378',
    '56379',
    '56381',
    '56382',
    '56384',
    '56385',
    '56386',
    '56387',
    '56389',
    '56401',
    '56425',
    '56431',
    '56433',
    '56434',
    '56435',
    '56436',
    '56437',
    '56438',
    '56440',
    '56441',
    '56442',
    '56443',
    '56444',
    '56446',
    '56447',
    '56448',
    '56449',
    '56450',
    '56452',
    '56453',
    '56455',
    '56456',
    '56458',
    '56461',
    '56464',
    '56465',
    '56466',
    '56467',
    '56468',
    '56469',
    '56470',
    '56472',
    '56473',
    '56474',
    '56475',
    '56477',
    '56479',
    '56481',
    '56482',
    '56484',
    '56501',
    '56510',
    '56511',
    '56514',
    '56515',
    '56516',
    '56517',
    '56518',
    '56519',
    '56520',
    '56521',
    '56522',
    '56523',
    '56524',
    '56525',
    '56527',
    '56528',
    '56529',
    '56531',
    '56533',
    '56534',
    '56535',
    '56536',
    '56537',
    '56540',
    '56541',
    '56542',
    '56543',
    '56544',
    '56545',
    '56546',
    '56547',
    '56548',
    '56549',
    '56550',
    '56551',
    '56552',
    '56553',
    '56554',
    '56556',
    '56557',
    '56560',
    '56565',
    '56566',
    '56567',
    '56568',
    '56569',
    '56570',
    '56571',
    '56572',
    '56573',
    '56574',
    '56575',
    '56576',
    '56577',
    '56578',
    '56579',
    '56580',
    '56581',
    '56583',
    '56584',
    '56585',
    '56586',
    '56587',
    '56588',
    '56589',
    '56590',
    '56591',
    '56592',
    '56593',
    '56594',
    '56601',
    '56621',
    '56623',
    '56626',
    '56627',
    '56628',
    '56629',
    '56630',
    '56633',
    '56634',
    '56636',
    '56637',
    '56639',
    '56641',
    '56644',
    '56646',
    '56647',
    '56649',
    '56650',
    '56651',
    '56652',
    '56653',
    '56654',
    '56655',
    '56657',
    '56658',
    '56659',
    '56660',
    '56661',
    '56662',
    '56663',
    '56666',
    '56667',
    '56668',
    '56669',
    '56670',
    '56671',
    '56672',
    '56673',
    '56676',
    '56678',
    '56680',
    '56681',
    '56683',
    '56684',
    '56685',
    '56686',
    '56687',
    '56688',
    '56701',
    '56710',
    '56711',
    '56713',
    '56714',
    '56715',
    '56716',
    '56720',
    '56721',
    '56722',
    '56723',
    '56724',
    '56725',
    '56726',
    '56727',
    '56728',
    '56729',
    '56731',
    '56732',
    '56733',
    '56734',
    '56735',
    '56736',
    '56737',
    '56738',
    '56741',
    '56742',
    '56744',
    '56748',
    '56750',
    '56751',
    '56754',
    '56755',
    '56756',
    '56757',
    '56758',
    '56759',
    '56760',
    '56761',
    '56762',
    '56763',
    '57026',
    '57030',
    '57068',
    '58225'
  ],
  '31': [
    '51557',
    '57078',
    '66541',
    '68001',
    '68002',
    '68003',
    '68004',
    '68005',
    '68007',
    '68008',
    '68010',
    '68014',
    '68015',
    '68016',
    '68017',
    '68018',
    '68019',
    '68020',
    '68022',
    '68023',
    '68025',
    '68028',
    '68029',
    '68030',
    '68031',
    '68033',
    '68034',
    '68036',
    '68037',
    '68038',
    '68039',
    '68040',
    '68041',
    '68042',
    '68044',
    '68045',
    '68046',
    '68047',
    '68048',
    '68050',
    '68055',
    '68057',
    '68058',
    '68059',
    '68061',
    '68062',
    '68063',
    '68064',
    '68065',
    '68066',
    '68067',
    '68068',
    '68069',
    '68070',
    '68071',
    '68072',
    '68073',
    '68102',
    '68104',
    '68105',
    '68106',
    '68107',
    '68108',
    '68110',
    '68111',
    '68112',
    '68113',
    '68114',
    '68116',
    '68117',
    '68118',
    '68122',
    '68123',
    '68124',
    '68127',
    '68128',
    '68130',
    '68131',
    '68132',
    '68133',
    '68134',
    '68135',
    '68136',
    '68137',
    '68138',
    '68142',
    '68144',
    '68147',
    '68152',
    '68154',
    '68157',
    '68164',
    '68178',
    '68301',
    '68303',
    '68304',
    '68305',
    '68307',
    '68309',
    '68310',
    '68313',
    '68314',
    '68315',
    '68316',
    '68317',
    '68318',
    '68319',
    '68320',
    '68321',
    '68322',
    '68323',
    '68324',
    '68325',
    '68326',
    '68327',
    '68328',
    '68329',
    '68331',
    '68332',
    '68333',
    '68335',
    '68336',
    '68337',
    '68338',
    '68339',
    '68340',
    '68341',
    '68342',
    '68343',
    '68344',
    '68345',
    '68346',
    '68347',
    '68348',
    '68349',
    '68350',
    '68351',
    '68352',
    '68354',
    '68355',
    '68357',
    '68358',
    '68359',
    '68360',
    '68361',
    '68362',
    '68364',
    '68365',
    '68366',
    '68367',
    '68368',
    '68370',
    '68371',
    '68372',
    '68375',
    '68376',
    '68377',
    '68378',
    '68379',
    '68380',
    '68381',
    '68382',
    '68401',
    '68402',
    '68403',
    '68404',
    '68405',
    '68406',
    '68407',
    '68409',
    '68410',
    '68413',
    '68414',
    '68415',
    '68416',
    '68417',
    '68418',
    '68419',
    '68420',
    '68421',
    '68422',
    '68423',
    '68424',
    '68428',
    '68430',
    '68431',
    '68433',
    '68434',
    '68436',
    '68437',
    '68438',
    '68439',
    '68440',
    '68441',
    '68442',
    '68443',
    '68444',
    '68445',
    '68446',
    '68447',
    '68448',
    '68450',
    '68452',
    '68453',
    '68454',
    '68455',
    '68456',
    '68457',
    '68458',
    '68460',
    '68461',
    '68462',
    '68463',
    '68464',
    '68465',
    '68466',
    '68467',
    '68502',
    '68503',
    '68504',
    '68505',
    '68506',
    '68507',
    '68508',
    '68510',
    '68512',
    '68514',
    '68516',
    '68517',
    '68520',
    '68521',
    '68522',
    '68523',
    '68524',
    '68526',
    '68527',
    '68528',
    '68531',
    '68532',
    '68601',
    '68620',
    '68621',
    '68622',
    '68623',
    '68624',
    '68626',
    '68627',
    '68628',
    '68629',
    '68631',
    '68632',
    '68633',
    '68634',
    '68635',
    '68636',
    '68637',
    '68638',
    '68640',
    '68641',
    '68642',
    '68643',
    '68644',
    '68647',
    '68648',
    '68649',
    '68651',
    '68652',
    '68653',
    '68654',
    '68655',
    '68658',
    '68659',
    '68660',
    '68661',
    '68662',
    '68663',
    '68664',
    '68665',
    '68666',
    '68667',
    '68669',
    '68701',
    '68710',
    '68711',
    '68713',
    '68714',
    '68715',
    '68716',
    '68717',
    '68718',
    '68719',
    '68720',
    '68722',
    '68723',
    '68724',
    '68725',
    '68726',
    '68727',
    '68728',
    '68729',
    '68730',
    '68731',
    '68732',
    '68733',
    '68734',
    '68735',
    '68736',
    '68739',
    '68740',
    '68741',
    '68742',
    '68743',
    '68745',
    '68746',
    '68747',
    '68748',
    '68749',
    '68751',
    '68752',
    '68753',
    '68755',
    '68756',
    '68757',
    '68758',
    '68759',
    '68760',
    '68761',
    '68763',
    '68764',
    '68765',
    '68766',
    '68767',
    '68768',
    '68769',
    '68770',
    '68771',
    '68773',
    '68774',
    '68776',
    '68777',
    '68778',
    '68779',
    '68780',
    '68781',
    '68783',
    '68784',
    '68785',
    '68786',
    '68787',
    '68788',
    '68789',
    '68790',
    '68791',
    '68792',
    '68801',
    '68803',
    '68810',
    '68812',
    '68813',
    '68814',
    '68815',
    '68816',
    '68817',
    '68818',
    '68820',
    '68821',
    '68822',
    '68823',
    '68824',
    '68825',
    '68826',
    '68827',
    '68828',
    '68831',
    '68832',
    '68833',
    '68834',
    '68835',
    '68836',
    '68837',
    '68838',
    '68840',
    '68841',
    '68842',
    '68843',
    '68844',
    '68845',
    '68846',
    '68847',
    '68849',
    '68850',
    '68852',
    '68853',
    '68854',
    '68855',
    '68856',
    '68858',
    '68859',
    '68860',
    '68861',
    '68862',
    '68863',
    '68864',
    '68865',
    '68866',
    '68869',
    '68870',
    '68871',
    '68872',
    '68873',
    '68874',
    '68875',
    '68876',
    '68878',
    '68879',
    '68881',
    '68882',
    '68883',
    '68901',
    '68920',
    '68922',
    '68923',
    '68924',
    '68925',
    '68926',
    '68927',
    '68928',
    '68929',
    '68930',
    '68932',
    '68933',
    '68934',
    '68935',
    '68936',
    '68937',
    '68938',
    '68939',
    '68940',
    '68941',
    '68942',
    '68943',
    '68944',
    '68945',
    '68946',
    '68947',
    '68948',
    '68949',
    '68950',
    '68952',
    '68954',
    '68955',
    '68956',
    '68957',
    '68958',
    '68959',
    '68960',
    '68961',
    '68964',
    '68966',
    '68967',
    '68969',
    '68970',
    '68971',
    '68972',
    '68973',
    '68974',
    '68975',
    '68976',
    '68977',
    '68978',
    '68979',
    '68980',
    '68981',
    '68982',
    '69001',
    '69020',
    '69021',
    '69022',
    '69023',
    '69024',
    '69025',
    '69026',
    '69027',
    '69028',
    '69029',
    '69030',
    '69032',
    '69033',
    '69034',
    '69036',
    '69037',
    '69038',
    '69039',
    '69040',
    '69041',
    '69042',
    '69043',
    '69044',
    '69045',
    '69046',
    '69101',
    '69120',
    '69121',
    '69122',
    '69123',
    '69125',
    '69127',
    '69128',
    '69129',
    '69130',
    '69131',
    '69132',
    '69133',
    '69134',
    '69135',
    '69138',
    '69140',
    '69141',
    '69142',
    '69143',
    '69144',
    '69145',
    '69146',
    '69147',
    '69148',
    '69149',
    '69150',
    '69151',
    '69152',
    '69153',
    '69154',
    '69155',
    '69156',
    '69157',
    '69161',
    '69162',
    '69163',
    '69165',
    '69166',
    '69167',
    '69168',
    '69169',
    '69170',
    '69171',
    '69201',
    '69210',
    '69211',
    '69212',
    '69214',
    '69216',
    '69217',
    '69218',
    '69219',
    '69220',
    '69221',
    '69301',
    '69331',
    '69333',
    '69334',
    '69335',
    '69336',
    '69337',
    '69339',
    '69340',
    '69341',
    '69343',
    '69345',
    '69346',
    '69347',
    '69348',
    '69350',
    '69351',
    '69352',
    '69353',
    '69354',
    '69355',
    '69356',
    '69357',
    '69358',
    '69360',
    '69361',
    '69365',
    '69366',
    '69367',
    '80758',
    '82082'
  ],
  '19': [
    '50001',
    '50002',
    '50003',
    '50005',
    '50006',
    '50007',
    '50008',
    '50009',
    '50010',
    '50011',
    '50012',
    '50014',
    '50020',
    '50021',
    '50022',
    '50023',
    '50025',
    '50026',
    '50027',
    '50028',
    '50029',
    '50032',
    '50033',
    '50034',
    '50035',
    '50036',
    '50038',
    '50039',
    '50041',
    '50042',
    '50044',
    '50046',
    '50047',
    '50048',
    '50049',
    '50050',
    '50051',
    '50052',
    '50054',
    '50055',
    '50056',
    '50057',
    '50058',
    '50060',
    '50061',
    '50062',
    '50063',
    '50064',
    '50065',
    '50066',
    '50067',
    '50068',
    '50069',
    '50070',
    '50071',
    '50072',
    '50073',
    '50074',
    '50075',
    '50076',
    '50078',
    '50101',
    '50102',
    '50103',
    '50104',
    '50105',
    '50106',
    '50107',
    '50108',
    '50109',
    '50111',
    '50112',
    '50115',
    '50116',
    '50117',
    '50118',
    '50119',
    '50120',
    '50122',
    '50123',
    '50124',
    '50125',
    '50126',
    '50127',
    '50128',
    '50129',
    '50130',
    '50131',
    '50132',
    '50133',
    '50134',
    '50135',
    '50136',
    '50138',
    '50139',
    '50140',
    '50141',
    '50142',
    '50143',
    '50144',
    '50146',
    '50147',
    '50148',
    '50149',
    '50150',
    '50151',
    '50153',
    '50154',
    '50155',
    '50156',
    '50157',
    '50158',
    '50160',
    '50161',
    '50162',
    '50163',
    '50164',
    '50165',
    '50166',
    '50167',
    '50168',
    '50169',
    '50170',
    '50171',
    '50173',
    '50174',
    '50201',
    '50206',
    '50207',
    '50208',
    '50210',
    '50211',
    '50212',
    '50213',
    '50214',
    '50216',
    '50217',
    '50218',
    '50219',
    '50220',
    '50222',
    '50223',
    '50225',
    '50226',
    '50227',
    '50228',
    '50229',
    '50230',
    '50231',
    '50232',
    '50233',
    '50234',
    '50235',
    '50236',
    '50237',
    '50238',
    '50239',
    '50240',
    '50242',
    '50243',
    '50244',
    '50246',
    '50247',
    '50248',
    '50249',
    '50250',
    '50251',
    '50252',
    '50254',
    '50255',
    '50256',
    '50257',
    '50258',
    '50261',
    '50262',
    '50263',
    '50264',
    '50265',
    '50266',
    '50268',
    '50271',
    '50272',
    '50273',
    '50274',
    '50275',
    '50276',
    '50277',
    '50278',
    '50309',
    '50310',
    '50311',
    '50312',
    '50313',
    '50314',
    '50315',
    '50316',
    '50317',
    '50319',
    '50320',
    '50321',
    '50322',
    '50323',
    '50324',
    '50325',
    '50327',
    '50401',
    '50420',
    '50421',
    '50423',
    '50424',
    '50426',
    '50428',
    '50430',
    '50431',
    '50432',
    '50433',
    '50434',
    '50435',
    '50436',
    '50438',
    '50439',
    '50440',
    '50441',
    '50444',
    '50446',
    '50447',
    '50448',
    '50449',
    '50450',
    '50451',
    '50452',
    '50453',
    '50454',
    '50455',
    '50456',
    '50457',
    '50458',
    '50459',
    '50460',
    '50461',
    '50464',
    '50465',
    '50466',
    '50467',
    '50468',
    '50469',
    '50470',
    '50471',
    '50472',
    '50473',
    '50475',
    '50476',
    '50477',
    '50478',
    '50479',
    '50480',
    '50482',
    '50483',
    '50484',
    '50501',
    '50510',
    '50511',
    '50514',
    '50515',
    '50516',
    '50517',
    '50518',
    '50519',
    '50520',
    '50521',
    '50522',
    '50523',
    '50524',
    '50525',
    '50527',
    '50528',
    '50529',
    '50530',
    '50531',
    '50532',
    '50533',
    '50535',
    '50536',
    '50538',
    '50539',
    '50540',
    '50541',
    '50542',
    '50543',
    '50544',
    '50545',
    '50546',
    '50548',
    '50551',
    '50554',
    '50556',
    '50557',
    '50558',
    '50559',
    '50560',
    '50561',
    '50562',
    '50563',
    '50565',
    '50566',
    '50567',
    '50568',
    '50569',
    '50570',
    '50571',
    '50573',
    '50574',
    '50575',
    '50576',
    '50577',
    '50578',
    '50579',
    '50581',
    '50582',
    '50583',
    '50585',
    '50586',
    '50588',
    '50590',
    '50591',
    '50593',
    '50594',
    '50595',
    '50597',
    '50598',
    '50599',
    '50601',
    '50602',
    '50603',
    '50604',
    '50605',
    '50606',
    '50607',
    '50609',
    '50611',
    '50612',
    '50613',
    '50616',
    '50619',
    '50620',
    '50621',
    '50622',
    '50624',
    '50625',
    '50626',
    '50627',
    '50628',
    '50629',
    '50630',
    '50632',
    '50633',
    '50634',
    '50635',
    '50636',
    '50638',
    '50641',
    '50642',
    '50643',
    '50644',
    '50645',
    '50647',
    '50648',
    '50650',
    '50651',
    '50652',
    '50653',
    '50654',
    '50655',
    '50658',
    '50659',
    '50660',
    '50662',
    '50664',
    '50665',
    '50666',
    '50667',
    '50668',
    '50669',
    '50670',
    '50671',
    '50672',
    '50673',
    '50674',
    '50675',
    '50676',
    '50677',
    '50680',
    '50681',
    '50682',
    '50701',
    '50702',
    '50703',
    '50707',
    '50801',
    '50830',
    '50833',
    '50835',
    '50836',
    '50837',
    '50839',
    '50840',
    '50841',
    '50842',
    '50843',
    '50845',
    '50846',
    '50847',
    '50848',
    '50849',
    '50851',
    '50853',
    '50854',
    '50857',
    '50858',
    '50859',
    '50860',
    '50861',
    '50862',
    '50863',
    '50864',
    '51001',
    '51002',
    '51003',
    '51004',
    '51005',
    '51006',
    '51007',
    '51008',
    '51009',
    '51010',
    '51011',
    '51012',
    '51014',
    '51016',
    '51018',
    '51019',
    '51020',
    '51022',
    '51023',
    '51024',
    '51025',
    '51026',
    '51027',
    '51028',
    '51029',
    '51030',
    '51031',
    '51033',
    '51034',
    '51035',
    '51036',
    '51037',
    '51038',
    '51039',
    '51040',
    '51041',
    '51044',
    '51046',
    '51047',
    '51048',
    '51049',
    '51050',
    '51051',
    '51052',
    '51053',
    '51054',
    '51055',
    '51056',
    '51058',
    '51060',
    '51061',
    '51062',
    '51063',
    '51101',
    '51103',
    '51104',
    '51105',
    '51106',
    '51108',
    '51109',
    '51111',
    '51201',
    '51230',
    '51231',
    '51232',
    '51234',
    '51235',
    '51237',
    '51238',
    '51239',
    '51240',
    '51241',
    '51242',
    '51243',
    '51244',
    '51245',
    '51246',
    '51247',
    '51248',
    '51249',
    '51250',
    '51301',
    '51331',
    '51333',
    '51334',
    '51338',
    '51341',
    '51342',
    '51343',
    '51345',
    '51346',
    '51347',
    '51350',
    '51351',
    '51354',
    '51355',
    '51357',
    '51358',
    '51360',
    '51363',
    '51364',
    '51365',
    '51366',
    '51401',
    '51430',
    '51431',
    '51433',
    '51436',
    '51439',
    '51440',
    '51441',
    '51442',
    '51443',
    '51444',
    '51445',
    '51446',
    '51447',
    '51448',
    '51449',
    '51450',
    '51451',
    '51453',
    '51454',
    '51455',
    '51458',
    '51459',
    '51461',
    '51462',
    '51463',
    '51465',
    '51466',
    '51467',
    '51501',
    '51503',
    '51510',
    '51520',
    '51521',
    '51523',
    '51525',
    '51526',
    '51527',
    '51528',
    '51529',
    '51530',
    '51531',
    '51532',
    '51533',
    '51534',
    '51535',
    '51536',
    '51537',
    '51540',
    '51541',
    '51542',
    '51543',
    '51544',
    '51545',
    '51546',
    '51548',
    '51549',
    '51550',
    '51551',
    '51552',
    '51553',
    '51554',
    '51555',
    '51556',
    '51557',
    '51558',
    '51559',
    '51560',
    '51561',
    '51562',
    '51563',
    '51564',
    '51565',
    '51566',
    '51570',
    '51571',
    '51572',
    '51573',
    '51575',
    '51576',
    '51577',
    '51578',
    '51579',
    '51601',
    '51630',
    '51631',
    '51632',
    '51636',
    '51637',
    '51638',
    '51639',
    '51640',
    '51645',
    '51646',
    '51647',
    '51648',
    '51649',
    '51650',
    '51652',
    '51653',
    '51654',
    '51656',
    '52001',
    '52002',
    '52003',
    '52030',
    '52031',
    '52032',
    '52033',
    '52035',
    '52037',
    '52038',
    '52039',
    '52040',
    '52041',
    '52042',
    '52043',
    '52044',
    '52045',
    '52046',
    '52047',
    '52048',
    '52049',
    '52050',
    '52052',
    '52053',
    '52054',
    '52057',
    '52060',
    '52064',
    '52065',
    '52066',
    '52068',
    '52069',
    '52070',
    '52072',
    '52073',
    '52074',
    '52076',
    '52077',
    '52078',
    '52079',
    '52101',
    '52132',
    '52133',
    '52134',
    '52135',
    '52136',
    '52140',
    '52141',
    '52142',
    '52144',
    '52146',
    '52147',
    '52151',
    '52154',
    '52155',
    '52156',
    '52157',
    '52158',
    '52159',
    '52160',
    '52161',
    '52162',
    '52163',
    '52164',
    '52165',
    '52166',
    '52168',
    '52169',
    '52170',
    '52171',
    '52172',
    '52175',
    '52201',
    '52202',
    '52203',
    '52205',
    '52206',
    '52207',
    '52208',
    '52209',
    '52210',
    '52211',
    '52212',
    '52213',
    '52214',
    '52215',
    '52216',
    '52217',
    '52218',
    '52219',
    '52220',
    '52221',
    '52222',
    '52223',
    '52224',
    '52225',
    '52227',
    '52228',
    '52229',
    '52231',
    '52232',
    '52233',
    '52235',
    '52236',
    '52237',
    '52240',
    '52241',
    '52242',
    '52245',
    '52246',
    '52247',
    '52248',
    '52249',
    '52251',
    '52253',
    '52254',
    '52255',
    '52257',
    '52301',
    '52302',
    '52305',
    '52306',
    '52307',
    '52308',
    '52309',
    '52310',
    '52312',
    '52313',
    '52314',
    '52315',
    '52316',
    '52317',
    '52318',
    '52320',
    '52321',
    '52322',
    '52323',
    '52324',
    '52325',
    '52326',
    '52327',
    '52328',
    '52329',
    '52330',
    '52332',
    '52333',
    '52334',
    '52335',
    '52336',
    '52337',
    '52338',
    '52339',
    '52340',
    '52341',
    '52342',
    '52345',
    '52346',
    '52347',
    '52348',
    '52349',
    '52351',
    '52352',
    '52353',
    '52354',
    '52355',
    '52356',
    '52358',
    '52359',
    '52361',
    '52362',
    '52401',
    '52402',
    '52403',
    '52404',
    '52405',
    '52411',
    '52501',
    '52530',
    '52531',
    '52533',
    '52534',
    '52535',
    '52536',
    '52537',
    '52540',
    '52542',
    '52543',
    '52544',
    '52548',
    '52549',
    '52550',
    '52551',
    '52552',
    '52553',
    '52554',
    '52555',
    '52556',
    '52557',
    '52560',
    '52561',
    '52563',
    '52565',
    '52566',
    '52567',
    '52569',
    '52570',
    '52571',
    '52572',
    '52573',
    '52574',
    '52576',
    '52577',
    '52580',
    '52581',
    '52583',
    '52584',
    '52585',
    '52586',
    '52588',
    '52590',
    '52591',
    '52593',
    '52594',
    '52595',
    '52601',
    '52619',
    '52620',
    '52621',
    '52623',
    '52624',
    '52625',
    '52626',
    '52627',
    '52630',
    '52632',
    '52635',
    '52637',
    '52638',
    '52639',
    '52640',
    '52641',
    '52644',
    '52645',
    '52646',
    '52647',
    '52649',
    '52650',
    '52651',
    '52653',
    '52654',
    '52655',
    '52656',
    '52657',
    '52658',
    '52659',
    '52660',
    '52701',
    '52720',
    '52721',
    '52722',
    '52726',
    '52727',
    '52728',
    '52729',
    '52730',
    '52731',
    '52732',
    '52737',
    '52738',
    '52739',
    '52742',
    '52745',
    '52746',
    '52747',
    '52748',
    '52749',
    '52750',
    '52751',
    '52752',
    '52753',
    '52754',
    '52755',
    '52756',
    '52757',
    '52758',
    '52760',
    '52761',
    '52765',
    '52766',
    '52767',
    '52768',
    '52769',
    '52772',
    '52773',
    '52774',
    '52776',
    '52777',
    '52778',
    '52801',
    '52802',
    '52803',
    '52804',
    '52806',
    '52807',
    '56027'
  ],
  '34': [
    '07001',
    '07002',
    '07003',
    '07004',
    '07005',
    '07006',
    '07008',
    '07009',
    '07010',
    '07011',
    '07012',
    '07013',
    '07014',
    '07016',
    '07017',
    '07018',
    '07020',
    '07021',
    '07022',
    '07023',
    '07024',
    '07026',
    '07027',
    '07028',
    '07029',
    '07030',
    '07031',
    '07032',
    '07033',
    '07034',
    '07035',
    '07036',
    '07039',
    '07040',
    '07041',
    '07042',
    '07043',
    '07044',
    '07045',
    '07046',
    '07047',
    '07050',
    '07052',
    '07054',
    '07055',
    '07057',
    '07058',
    '07059',
    '07060',
    '07062',
    '07063',
    '07064',
    '07065',
    '07066',
    '07067',
    '07068',
    '07069',
    '07070',
    '07071',
    '07072',
    '07073',
    '07074',
    '07075',
    '07076',
    '07077',
    '07078',
    '07079',
    '07080',
    '07081',
    '07082',
    '07083',
    '07086',
    '07087',
    '07088',
    '07090',
    '07092',
    '07093',
    '07094',
    '07095',
    '07102',
    '07103',
    '07104',
    '07105',
    '07106',
    '07107',
    '07108',
    '07109',
    '07110',
    '07111',
    '07112',
    '07114',
    '07201',
    '07202',
    '07203',
    '07204',
    '07205',
    '07206',
    '07208',
    '07302',
    '07304',
    '07305',
    '07306',
    '07307',
    '07310',
    '07311',
    '07401',
    '07403',
    '07405',
    '07407',
    '07410',
    '07416',
    '07417',
    '07418',
    '07419',
    '07420',
    '07421',
    '07422',
    '07423',
    '07424',
    '07430',
    '07432',
    '07435',
    '07436',
    '07438',
    '07439',
    '07440',
    '07442',
    '07444',
    '07446',
    '07450',
    '07452',
    '07456',
    '07457',
    '07458',
    '07460',
    '07461',
    '07462',
    '07463',
    '07465',
    '07470',
    '07480',
    '07481',
    '07495',
    '07501',
    '07502',
    '07503',
    '07504',
    '07505',
    '07506',
    '07508',
    '07512',
    '07513',
    '07514',
    '07522',
    '07524',
    '07601',
    '07603',
    '07604',
    '07605',
    '07606',
    '07607',
    '07608',
    '07620',
    '07621',
    '07624',
    '07626',
    '07627',
    '07628',
    '07630',
    '07631',
    '07632',
    '07640',
    '07641',
    '07642',
    '07643',
    '07644',
    '07645',
    '07646',
    '07647',
    '07648',
    '07649',
    '07650',
    '07652',
    '07656',
    '07657',
    '07660',
    '07661',
    '07662',
    '07663',
    '07666',
    '07670',
    '07675',
    '07676',
    '07677',
    '07701',
    '07702',
    '07703',
    '07704',
    '07711',
    '07712',
    '07716',
    '07717',
    '07718',
    '07719',
    '07720',
    '07721',
    '07722',
    '07723',
    '07724',
    '07726',
    '07727',
    '07728',
    '07730',
    '07731',
    '07732',
    '07733',
    '07734',
    '07735',
    '07737',
    '07738',
    '07739',
    '07740',
    '07746',
    '07747',
    '07748',
    '07750',
    '07751',
    '07753',
    '07755',
    '07756',
    '07757',
    '07758',
    '07760',
    '07762',
    '07764',
    '07801',
    '07803',
    '07820',
    '07821',
    '07822',
    '07823',
    '07825',
    '07826',
    '07827',
    '07828',
    '07830',
    '07832',
    '07833',
    '07834',
    '07836',
    '07838',
    '07840',
    '07842',
    '07843',
    '07846',
    '07847',
    '07848',
    '07849',
    '07850',
    '07851',
    '07852',
    '07853',
    '07856',
    '07857',
    '07860',
    '07863',
    '07865',
    '07866',
    '07869',
    '07870',
    '07871',
    '07874',
    '07876',
    '07878',
    '07880',
    '07881',
    '07882',
    '07885',
    '07901',
    '07920',
    '07921',
    '07922',
    '07924',
    '07926',
    '07927',
    '07928',
    '07930',
    '07931',
    '07932',
    '07933',
    '07934',
    '07935',
    '07936',
    '07939',
    '07940',
    '07945',
    '07946',
    '07950',
    '07960',
    '07961',
    '07970',
    '07974',
    '07976',
    '07977',
    '07979',
    '07980',
    '07981',
    '08001',
    '08002',
    '08003',
    '08004',
    '08005',
    '08006',
    '08007',
    '08008',
    '08009',
    '08010',
    '08011',
    '08012',
    '08014',
    '08015',
    '08016',
    '08019',
    '08020',
    '08021',
    '08022',
    '08023',
    '08026',
    '08027',
    '08028',
    '08029',
    '08030',
    '08031',
    '08033',
    '08034',
    '08035',
    '08036',
    '08037',
    '08038',
    '08039',
    '08041',
    '08042',
    '08043',
    '08045',
    '08046',
    '08048',
    '08049',
    '08050',
    '08051',
    '08052',
    '08053',
    '08054',
    '08055',
    '08056',
    '08057',
    '08059',
    '08060',
    '08061',
    '08062',
    '08063',
    '08064',
    '08065',
    '08066',
    '08067',
    '08068',
    '08069',
    '08070',
    '08071',
    '08072',
    '08073',
    '08074',
    '08075',
    '08077',
    '08078',
    '08079',
    '08080',
    '08081',
    '08083',
    '08084',
    '08085',
    '08086',
    '08087',
    '08088',
    '08089',
    '08090',
    '08091',
    '08092',
    '08093',
    '08094',
    '08095',
    '08096',
    '08097',
    '08098',
    '08102',
    '08103',
    '08104',
    '08105',
    '08106',
    '08107',
    '08108',
    '08109',
    '08110',
    '08201',
    '08202',
    '08203',
    '08204',
    '08205',
    '08210',
    '08212',
    '08215',
    '08217',
    '08221',
    '08223',
    '08224',
    '08225',
    '08226',
    '08230',
    '08232',
    '08234',
    '08240',
    '08241',
    '08242',
    '08243',
    '08244',
    '08246',
    '08247',
    '08248',
    '08251',
    '08260',
    '08270',
    '08302',
    '08310',
    '08311',
    '08312',
    '08314',
    '08316',
    '08317',
    '08318',
    '08319',
    '08320',
    '08321',
    '08322',
    '08323',
    '08324',
    '08326',
    '08327',
    '08328',
    '08329',
    '08330',
    '08332',
    '08340',
    '08341',
    '08343',
    '08344',
    '08345',
    '08346',
    '08348',
    '08349',
    '08350',
    '08352',
    '08353',
    '08360',
    '08361',
    '08401',
    '08402',
    '08403',
    '08406',
    '08501',
    '08502',
    '08505',
    '08510',
    '08511',
    '08512',
    '08514',
    '08515',
    '08518',
    '08520',
    '08525',
    '08527',
    '08528',
    '08530',
    '08533',
    '08534',
    '08535',
    '08536',
    '08540',
    '08542',
    '08550',
    '08551',
    '08553',
    '08554',
    '08555',
    '08558',
    '08559',
    '08560',
    '08561',
    '08562',
    '08608',
    '08609',
    '08610',
    '08611',
    '08618',
    '08619',
    '08620',
    '08628',
    '08629',
    '08638',
    '08640',
    '08641',
    '08648',
    '08690',
    '08691',
    '08701',
    '08720',
    '08721',
    '08722',
    '08723',
    '08724',
    '08730',
    '08731',
    '08732',
    '08733',
    '08734',
    '08735',
    '08736',
    '08738',
    '08740',
    '08741',
    '08742',
    '08750',
    '08751',
    '08752',
    '08753',
    '08755',
    '08757',
    '08758',
    '08759',
    '08801',
    '08802',
    '08804',
    '08805',
    '08807',
    '08808',
    '08809',
    '08810',
    '08812',
    '08816',
    '08817',
    '08820',
    '08821',
    '08822',
    '08823',
    '08824',
    '08825',
    '08826',
    '08827',
    '08828',
    '08829',
    '08830',
    '08831',
    '08832',
    '08833',
    '08835',
    '08836',
    '08837',
    '08840',
    '08844',
    '08846',
    '08848',
    '08850',
    '08852',
    '08853',
    '08854',
    '08857',
    '08858',
    '08859',
    '08861',
    '08863',
    '08865',
    '08867',
    '08869',
    '08872',
    '08873',
    '08876',
    '08879',
    '08880',
    '08882',
    '08884',
    '08886',
    '08887',
    '08889',
    '08890',
    '08901',
    '08902',
    '08904'
  ],
  '09': [
    '06001',
    '06002',
    '06010',
    '06013',
    '06016',
    '06018',
    '06019',
    '06020',
    '06021',
    '06022',
    '06023',
    '06024',
    '06026',
    '06027',
    '06029',
    '06031',
    '06032',
    '06033',
    '06035',
    '06037',
    '06039',
    '06040',
    '06042',
    '06043',
    '06051',
    '06052',
    '06053',
    '06057',
    '06058',
    '06059',
    '06060',
    '06061',
    '06062',
    '06063',
    '06065',
    '06066',
    '06067',
    '06068',
    '06069',
    '06070',
    '06071',
    '06073',
    '06074',
    '06076',
    '06078',
    '06081',
    '06082',
    '06084',
    '06085',
    '06088',
    '06089',
    '06090',
    '06091',
    '06092',
    '06093',
    '06095',
    '06096',
    '06098',
    '06103',
    '06105',
    '06106',
    '06107',
    '06108',
    '06109',
    '06110',
    '06111',
    '06112',
    '06114',
    '06117',
    '06118',
    '06119',
    '06120',
    '06160',
    '06226',
    '06231',
    '06232',
    '06234',
    '06235',
    '06237',
    '06238',
    '06239',
    '06241',
    '06242',
    '06243',
    '06247',
    '06248',
    '06249',
    '06250',
    '06254',
    '06255',
    '06256',
    '06259',
    '06260',
    '06262',
    '06263',
    '06264',
    '06266',
    '06268',
    '06269',
    '06277',
    '06278',
    '06279',
    '06280',
    '06281',
    '06282',
    '06320',
    '06330',
    '06331',
    '06332',
    '06333',
    '06334',
    '06335',
    '06336',
    '06339',
    '06340',
    '06350',
    '06351',
    '06353',
    '06354',
    '06355',
    '06357',
    '06359',
    '06360',
    '06365',
    '06370',
    '06371',
    '06373',
    '06374',
    '06375',
    '06376',
    '06377',
    '06378',
    '06379',
    '06380',
    '06382',
    '06384',
    '06385',
    '06387',
    '06389',
    '06401',
    '06403',
    '06405',
    '06409',
    '06410',
    '06412',
    '06413',
    '06414',
    '06415',
    '06416',
    '06417',
    '06418',
    '06419',
    '06420',
    '06422',
    '06423',
    '06424',
    '06426',
    '06437',
    '06438',
    '06441',
    '06442',
    '06443',
    '06444',
    '06447',
    '06450',
    '06451',
    '06455',
    '06456',
    '06457',
    '06460',
    '06461',
    '06467',
    '06468',
    '06469',
    '06470',
    '06471',
    '06472',
    '06473',
    '06475',
    '06477',
    '06478',
    '06479',
    '06480',
    '06481',
    '06482',
    '06483',
    '06484',
    '06488',
    '06489',
    '06492',
    '06498',
    '06510',
    '06511',
    '06512',
    '06513',
    '06514',
    '06515',
    '06516',
    '06517',
    '06518',
    '06519',
    '06524',
    '06525',
    '06604',
    '06605',
    '06606',
    '06607',
    '06608',
    '06610',
    '06611',
    '06612',
    '06614',
    '06615',
    '06702',
    '06704',
    '06705',
    '06706',
    '06708',
    '06710',
    '06712',
    '06716',
    '06750',
    '06751',
    '06752',
    '06754',
    '06755',
    '06756',
    '06757',
    '06758',
    '06759',
    '06762',
    '06763',
    '06770',
    '06776',
    '06777',
    '06778',
    '06779',
    '06782',
    '06783',
    '06784',
    '06785',
    '06786',
    '06787',
    '06790',
    '06791',
    '06793',
    '06794',
    '06795',
    '06796',
    '06798',
    '06801',
    '06804',
    '06807',
    '06810',
    '06811',
    '06812',
    '06820',
    '06824',
    '06825',
    '06830',
    '06831',
    '06840',
    '06850',
    '06851',
    '06853',
    '06854',
    '06855',
    '06856',
    '06870',
    '06877',
    '06878',
    '06880',
    '06883',
    '06890',
    '06896',
    '06897',
    '06901',
    '06902',
    '06903',
    '06905',
    '06906',
    '06907'
  ],
  '54': [
    '20135',
    '24604',
    '24701',
    '24712',
    '24714',
    '24715',
    '24716',
    '24719',
    '24724',
    '24726',
    '24729',
    '24731',
    '24733',
    '24736',
    '24737',
    '24738',
    '24740',
    '24747',
    '24801',
    '24808',
    '24811',
    '24813',
    '24815',
    '24816',
    '24817',
    '24818',
    '24822',
    '24823',
    '24826',
    '24827',
    '24828',
    '24830',
    '24831',
    '24834',
    '24836',
    '24839',
    '24843',
    '24844',
    '24845',
    '24846',
    '24847',
    '24848',
    '24849',
    '24850',
    '24851',
    '24853',
    '24854',
    '24857',
    '24860',
    '24861',
    '24862',
    '24866',
    '24867',
    '24868',
    '24869',
    '24870',
    '24871',
    '24872',
    '24873',
    '24874',
    '24878',
    '24879',
    '24880',
    '24881',
    '24882',
    '24884',
    '24887',
    '24888',
    '24892',
    '24894',
    '24898',
    '24901',
    '24910',
    '24915',
    '24916',
    '24918',
    '24920',
    '24924',
    '24925',
    '24927',
    '24931',
    '24934',
    '24935',
    '24938',
    '24941',
    '24944',
    '24945',
    '24946',
    '24951',
    '24954',
    '24957',
    '24962',
    '24963',
    '24966',
    '24970',
    '24974',
    '24976',
    '24977',
    '24981',
    '24983',
    '24984',
    '24986',
    '24991',
    '25002',
    '25003',
    '25005',
    '25007',
    '25008',
    '25009',
    '25011',
    '25015',
    '25019',
    '25021',
    '25022',
    '25024',
    '25025',
    '25028',
    '25030',
    '25031',
    '25033',
    '25035',
    '25036',
    '25039',
    '25040',
    '25043',
    '25044',
    '25045',
    '25047',
    '25048',
    '25049',
    '25051',
    '25053',
    '25054',
    '25057',
    '25059',
    '25060',
    '25061',
    '25062',
    '25063',
    '25064',
    '25067',
    '25070',
    '25071',
    '25075',
    '25076',
    '25081',
    '25082',
    '25083',
    '25085',
    '25086',
    '25088',
    '25090',
    '25093',
    '25102',
    '25103',
    '25106',
    '25107',
    '25108',
    '25109',
    '25110',
    '25111',
    '25112',
    '25113',
    '25114',
    '25115',
    '25118',
    '25119',
    '25121',
    '25123',
    '25124',
    '25125',
    '25126',
    '25130',
    '25132',
    '25133',
    '25134',
    '25136',
    '25139',
    '25140',
    '25141',
    '25142',
    '25143',
    '25148',
    '25149',
    '25152',
    '25154',
    '25156',
    '25159',
    '25160',
    '25161',
    '25162',
    '25164',
    '25165',
    '25168',
    '25169',
    '25173',
    '25174',
    '25177',
    '25180',
    '25181',
    '25183',
    '25185',
    '25186',
    '25187',
    '25193',
    '25201',
    '25202',
    '25203',
    '25204',
    '25205',
    '25206',
    '25208',
    '25209',
    '25211',
    '25213',
    '25214',
    '25234',
    '25235',
    '25239',
    '25241',
    '25243',
    '25244',
    '25245',
    '25247',
    '25248',
    '25251',
    '25252',
    '25253',
    '25259',
    '25260',
    '25261',
    '25262',
    '25264',
    '25265',
    '25266',
    '25267',
    '25268',
    '25270',
    '25271',
    '25275',
    '25276',
    '25285',
    '25286',
    '25287',
    '25301',
    '25302',
    '25303',
    '25304',
    '25305',
    '25306',
    '25309',
    '25311',
    '25312',
    '25313',
    '25314',
    '25315',
    '25320',
    '25401',
    '25403',
    '25404',
    '25405',
    '25411',
    '25413',
    '25414',
    '25419',
    '25420',
    '25422',
    '25425',
    '25427',
    '25428',
    '25430',
    '25431',
    '25432',
    '25434',
    '25437',
    '25438',
    '25442',
    '25443',
    '25444',
    '25446',
    '25501',
    '25502',
    '25503',
    '25504',
    '25505',
    '25506',
    '25507',
    '25508',
    '25510',
    '25511',
    '25512',
    '25514',
    '25515',
    '25517',
    '25520',
    '25521',
    '25523',
    '25524',
    '25526',
    '25529',
    '25530',
    '25534',
    '25535',
    '25537',
    '25540',
    '25541',
    '25545',
    '25547',
    '25550',
    '25555',
    '25557',
    '25559',
    '25560',
    '25564',
    '25565',
    '25567',
    '25570',
    '25571',
    '25573',
    '25601',
    '25606',
    '25607',
    '25608',
    '25611',
    '25617',
    '25621',
    '25624',
    '25625',
    '25628',
    '25630',
    '25632',
    '25634',
    '25635',
    '25637',
    '25638',
    '25639',
    '25644',
    '25646',
    '25647',
    '25649',
    '25650',
    '25651',
    '25652',
    '25653',
    '25654',
    '25661',
    '25666',
    '25669',
    '25670',
    '25671',
    '25672',
    '25674',
    '25676',
    '25678',
    '25688',
    '25690',
    '25692',
    '25696',
    '25699',
    '25701',
    '25702',
    '25703',
    '25704',
    '25705',
    '25801',
    '25810',
    '25811',
    '25812',
    '25813',
    '25817',
    '25818',
    '25820',
    '25823',
    '25825',
    '25826',
    '25827',
    '25831',
    '25832',
    '25836',
    '25837',
    '25839',
    '25840',
    '25841',
    '25843',
    '25844',
    '25845',
    '25846',
    '25848',
    '25849',
    '25853',
    '25854',
    '25855',
    '25857',
    '25862',
    '25864',
    '25865',
    '25868',
    '25870',
    '25871',
    '25873',
    '25875',
    '25876',
    '25878',
    '25879',
    '25880',
    '25882',
    '25901',
    '25902',
    '25904',
    '25906',
    '25907',
    '25908',
    '25911',
    '25913',
    '25915',
    '25916',
    '25917',
    '25918',
    '25920',
    '25921',
    '25922',
    '25928',
    '25932',
    '25936',
    '25938',
    '25942',
    '25951',
    '25958',
    '25962',
    '25969',
    '25971',
    '25972',
    '25976',
    '25977',
    '25978',
    '25979',
    '25981',
    '25984',
    '25985',
    '25989',
    '26003',
    '26030',
    '26031',
    '26032',
    '26033',
    '26034',
    '26035',
    '26036',
    '26037',
    '26038',
    '26039',
    '26040',
    '26041',
    '26047',
    '26050',
    '26055',
    '26056',
    '26059',
    '26060',
    '26062',
    '26070',
    '26074',
    '26075',
    '26101',
    '26104',
    '26105',
    '26133',
    '26134',
    '26136',
    '26137',
    '26138',
    '26141',
    '26142',
    '26143',
    '26146',
    '26147',
    '26148',
    '26149',
    '26150',
    '26151',
    '26152',
    '26155',
    '26159',
    '26160',
    '26161',
    '26164',
    '26167',
    '26169',
    '26170',
    '26175',
    '26178',
    '26180',
    '26181',
    '26184',
    '26187',
    '26201',
    '26202',
    '26203',
    '26205',
    '26206',
    '26208',
    '26209',
    '26215',
    '26217',
    '26218',
    '26222',
    '26224',
    '26228',
    '26230',
    '26234',
    '26236',
    '26237',
    '26238',
    '26241',
    '26250',
    '26253',
    '26254',
    '26257',
    '26259',
    '26260',
    '26261',
    '26263',
    '26264',
    '26266',
    '26267',
    '26268',
    '26269',
    '26270',
    '26271',
    '26273',
    '26275',
    '26276',
    '26278',
    '26280',
    '26282',
    '26283',
    '26285',
    '26287',
    '26288',
    '26291',
    '26292',
    '26293',
    '26294',
    '26296',
    '26298',
    '26301',
    '26320',
    '26321',
    '26323',
    '26325',
    '26327',
    '26330',
    '26335',
    '26337',
    '26338',
    '26339',
    '26342',
    '26343',
    '26346',
    '26347',
    '26348',
    '26349',
    '26351',
    '26354',
    '26361',
    '26362',
    '26366',
    '26369',
    '26372',
    '26374',
    '26376',
    '26377',
    '26378',
    '26384',
    '26385',
    '26386',
    '26404',
    '26405',
    '26408',
    '26410',
    '26411',
    '26412',
    '26415',
    '26416',
    '26419',
    '26421',
    '26422',
    '26424',
    '26425',
    '26426',
    '26430',
    '26431',
    '26435',
    '26436',
    '26437',
    '26438',
    '26440',
    '26443',
    '26444',
    '26447',
    '26448',
    '26451',
    '26452',
    '26456',
    '26501',
    '26505',
    '26508',
    '26519',
    '26520',
    '26521',
    '26525',
    '26534',
    '26537',
    '26541',
    '26542',
    '26543',
    '26547',
    '26554',
    '26559',
    '26560',
    '26562',
    '26563',
    '26568',
    '26570',
    '26571',
    '26572',
    '26574',
    '26575',
    '26576',
    '26581',
    '26582',
    '26585',
    '26586',
    '26587',
    '26588',
    '26590',
    '26591',
    '26601',
    '26610',
    '26611',
    '26615',
    '26617',
    '26619',
    '26621',
    '26623',
    '26624',
    '26627',
    '26629',
    '26631',
    '26636',
    '26638',
    '26651',
    '26656',
    '26660',
    '26662',
    '26676',
    '26678',
    '26679',
    '26680',
    '26681',
    '26684',
    '26690',
    '26691',
    '26704',
    '26705',
    '26707',
    '26710',
    '26711',
    '26714',
    '26716',
    '26717',
    '26719',
    '26720',
    '26722',
    '26726',
    '26731',
    '26739',
    '26743',
    '26750',
    '26753',
    '26755',
    '26757',
    '26761',
    '26763',
    '26764',
    '26767',
    '26801',
    '26802',
    '26804',
    '26807',
    '26808',
    '26810',
    '26812',
    '26814',
    '26815',
    '26817',
    '26818',
    '26823',
    '26833',
    '26836',
    '26845',
    '26847',
    '26851',
    '26852',
    '26855',
    '26865',
    '26866',
    '26884'
  ],
  '32': [
    '84034',
    '89001',
    '89002',
    '89003',
    '89004',
    '89005',
    '89007',
    '89008',
    '89010',
    '89011',
    '89012',
    '89013',
    '89014',
    '89015',
    '89017',
    '89018',
    '89019',
    '89020',
    '89021',
    '89022',
    '89025',
    '89026',
    '89027',
    '89029',
    '89030',
    '89031',
    '89032',
    '89039',
    '89040',
    '89042',
    '89043',
    '89044',
    '89045',
    '89046',
    '89047',
    '89048',
    '89049',
    '89052',
    '89054',
    '89060',
    '89061',
    '89074',
    '89081',
    '89084',
    '89085',
    '89086',
    '89101',
    '89102',
    '89103',
    '89104',
    '89106',
    '89107',
    '89108',
    '89109',
    '89110',
    '89113',
    '89115',
    '89117',
    '89118',
    '89119',
    '89120',
    '89121',
    '89122',
    '89123',
    '89124',
    '89128',
    '89129',
    '89130',
    '89131',
    '89134',
    '89135',
    '89138',
    '89139',
    '89141',
    '89142',
    '89143',
    '89144',
    '89145',
    '89146',
    '89147',
    '89148',
    '89149',
    '89156',
    '89161',
    '89166',
    '89169',
    '89178',
    '89179',
    '89183',
    '89191',
    '89301',
    '89310',
    '89311',
    '89314',
    '89316',
    '89317',
    '89318',
    '89319',
    '89402',
    '89403',
    '89404',
    '89405',
    '89406',
    '89408',
    '89409',
    '89410',
    '89411',
    '89412',
    '89413',
    '89414',
    '89415',
    '89418',
    '89419',
    '89420',
    '89421',
    '89422',
    '89423',
    '89424',
    '89425',
    '89426',
    '89427',
    '89428',
    '89429',
    '89430',
    '89431',
    '89433',
    '89434',
    '89436',
    '89438',
    '89439',
    '89440',
    '89441',
    '89442',
    '89444',
    '89445',
    '89446',
    '89447',
    '89448',
    '89449',
    '89450',
    '89451',
    '89460',
    '89501',
    '89502',
    '89503',
    '89506',
    '89508',
    '89509',
    '89510',
    '89511',
    '89512',
    '89519',
    '89521',
    '89523',
    '89701',
    '89702',
    '89703',
    '89704',
    '89705',
    '89706',
    '89801',
    '89815',
    '89820',
    '89821',
    '89822',
    '89823',
    '89825',
    '89826',
    '89828',
    '89830',
    '89831',
    '89832',
    '89833',
    '89834',
    '89835',
    '89883'
  ],
  '21': [
    '38079',
    '40003',
    '40004',
    '40006',
    '40007',
    '40008',
    '40009',
    '40010',
    '40011',
    '40012',
    '40013',
    '40014',
    '40019',
    '40020',
    '40022',
    '40023',
    '40025',
    '40026',
    '40031',
    '40033',
    '40036',
    '40037',
    '40040',
    '40041',
    '40045',
    '40046',
    '40047',
    '40048',
    '40049',
    '40050',
    '40051',
    '40052',
    '40055',
    '40056',
    '40057',
    '40058',
    '40059',
    '40060',
    '40062',
    '40063',
    '40065',
    '40067',
    '40068',
    '40069',
    '40070',
    '40071',
    '40075',
    '40076',
    '40077',
    '40078',
    '40104',
    '40107',
    '40108',
    '40109',
    '40110',
    '40111',
    '40115',
    '40117',
    '40118',
    '40119',
    '40121',
    '40140',
    '40142',
    '40143',
    '40144',
    '40145',
    '40146',
    '40150',
    '40152',
    '40155',
    '40157',
    '40160',
    '40161',
    '40162',
    '40165',
    '40170',
    '40171',
    '40175',
    '40176',
    '40177',
    '40178',
    '40202',
    '40203',
    '40204',
    '40205',
    '40206',
    '40207',
    '40208',
    '40209',
    '40210',
    '40211',
    '40212',
    '40213',
    '40214',
    '40215',
    '40216',
    '40217',
    '40218',
    '40219',
    '40220',
    '40222',
    '40223',
    '40228',
    '40229',
    '40231',
    '40241',
    '40242',
    '40243',
    '40245',
    '40258',
    '40272',
    '40280',
    '40291',
    '40299',
    '40310',
    '40311',
    '40312',
    '40313',
    '40316',
    '40322',
    '40324',
    '40328',
    '40330',
    '40334',
    '40336',
    '40337',
    '40339',
    '40342',
    '40346',
    '40347',
    '40348',
    '40350',
    '40351',
    '40353',
    '40356',
    '40358',
    '40359',
    '40360',
    '40361',
    '40363',
    '40370',
    '40371',
    '40372',
    '40374',
    '40376',
    '40379',
    '40380',
    '40383',
    '40385',
    '40387',
    '40390',
    '40391',
    '40402',
    '40403',
    '40404',
    '40409',
    '40419',
    '40422',
    '40434',
    '40437',
    '40440',
    '40442',
    '40444',
    '40445',
    '40447',
    '40448',
    '40456',
    '40460',
    '40461',
    '40464',
    '40468',
    '40472',
    '40475',
    '40481',
    '40484',
    '40486',
    '40489',
    '40502',
    '40503',
    '40504',
    '40505',
    '40506',
    '40507',
    '40508',
    '40509',
    '40510',
    '40511',
    '40513',
    '40514',
    '40515',
    '40516',
    '40517',
    '40601',
    '40604',
    '40701',
    '40729',
    '40734',
    '40737',
    '40740',
    '40741',
    '40743',
    '40744',
    '40759',
    '40763',
    '40769',
    '40771',
    '40801',
    '40806',
    '40807',
    '40808',
    '40810',
    '40813',
    '40815',
    '40816',
    '40818',
    '40819',
    '40820',
    '40823',
    '40824',
    '40826',
    '40827',
    '40828',
    '40829',
    '40830',
    '40831',
    '40840',
    '40843',
    '40844',
    '40845',
    '40847',
    '40849',
    '40854',
    '40855',
    '40856',
    '40858',
    '40862',
    '40863',
    '40865',
    '40868',
    '40870',
    '40873',
    '40874',
    '40902',
    '40903',
    '40906',
    '40913',
    '40914',
    '40915',
    '40921',
    '40923',
    '40927',
    '40935',
    '40939',
    '40940',
    '40941',
    '40943',
    '40946',
    '40949',
    '40953',
    '40958',
    '40962',
    '40964',
    '40965',
    '40972',
    '40977',
    '40979',
    '40982',
    '40983',
    '40988',
    '40995',
    '40997',
    '41001',
    '41002',
    '41003',
    '41004',
    '41005',
    '41006',
    '41007',
    '41008',
    '41010',
    '41011',
    '41014',
    '41015',
    '41016',
    '41017',
    '41018',
    '41030',
    '41031',
    '41033',
    '41034',
    '41035',
    '41039',
    '41040',
    '41041',
    '41042',
    '41043',
    '41044',
    '41045',
    '41046',
    '41048',
    '41049',
    '41051',
    '41052',
    '41055',
    '41056',
    '41059',
    '41062',
    '41063',
    '41064',
    '41071',
    '41073',
    '41074',
    '41075',
    '41076',
    '41080',
    '41083',
    '41085',
    '41086',
    '41091',
    '41092',
    '41093',
    '41094',
    '41095',
    '41097',
    '41098',
    '41099',
    '41101',
    '41102',
    '41121',
    '41124',
    '41129',
    '41132',
    '41135',
    '41139',
    '41141',
    '41142',
    '41143',
    '41144',
    '41146',
    '41149',
    '41159',
    '41164',
    '41166',
    '41168',
    '41169',
    '41171',
    '41174',
    '41175',
    '41179',
    '41180',
    '41183',
    '41189',
    '41201',
    '41203',
    '41204',
    '41214',
    '41216',
    '41219',
    '41222',
    '41224',
    '41226',
    '41230',
    '41231',
    '41232',
    '41234',
    '41238',
    '41240',
    '41250',
    '41254',
    '41255',
    '41256',
    '41257',
    '41260',
    '41262',
    '41263',
    '41264',
    '41265',
    '41267',
    '41268',
    '41271',
    '41274',
    '41301',
    '41311',
    '41314',
    '41317',
    '41332',
    '41339',
    '41348',
    '41352',
    '41360',
    '41365',
    '41366',
    '41367',
    '41385',
    '41390',
    '41397',
    '41408',
    '41421',
    '41425',
    '41464',
    '41465',
    '41472',
    '41501',
    '41503',
    '41512',
    '41513',
    '41514',
    '41517',
    '41519',
    '41522',
    '41524',
    '41526',
    '41527',
    '41528',
    '41531',
    '41534',
    '41535',
    '41537',
    '41538',
    '41539',
    '41540',
    '41543',
    '41544',
    '41547',
    '41548',
    '41553',
    '41554',
    '41555',
    '41557',
    '41558',
    '41559',
    '41560',
    '41562',
    '41563',
    '41564',
    '41566',
    '41567',
    '41568',
    '41571',
    '41572',
    '41601',
    '41602',
    '41603',
    '41604',
    '41605',
    '41606',
    '41607',
    '41612',
    '41615',
    '41616',
    '41619',
    '41621',
    '41622',
    '41630',
    '41631',
    '41632',
    '41635',
    '41636',
    '41640',
    '41642',
    '41643',
    '41645',
    '41647',
    '41649',
    '41650',
    '41653',
    '41655',
    '41659',
    '41660',
    '41663',
    '41666',
    '41667',
    '41669',
    '41701',
    '41712',
    '41713',
    '41714',
    '41719',
    '41721',
    '41722',
    '41723',
    '41725',
    '41727',
    '41729',
    '41731',
    '41735',
    '41739',
    '41740',
    '41745',
    '41746',
    '41749',
    '41751',
    '41754',
    '41759',
    '41760',
    '41762',
    '41763',
    '41764',
    '41766',
    '41772',
    '41773',
    '41774',
    '41775',
    '41776',
    '41777',
    '41804',
    '41810',
    '41812',
    '41815',
    '41817',
    '41819',
    '41821',
    '41822',
    '41824',
    '41825',
    '41826',
    '41828',
    '41831',
    '41832',
    '41833',
    '41834',
    '41835',
    '41836',
    '41837',
    '41838',
    '41839',
    '41840',
    '41843',
    '41844',
    '41845',
    '41847',
    '41848',
    '41849',
    '41855',
    '41858',
    '41859',
    '41861',
    '41862',
    '42001',
    '42003',
    '42020',
    '42021',
    '42022',
    '42023',
    '42024',
    '42025',
    '42027',
    '42028',
    '42029',
    '42031',
    '42032',
    '42035',
    '42036',
    '42037',
    '42038',
    '42039',
    '42040',
    '42041',
    '42044',
    '42045',
    '42047',
    '42048',
    '42049',
    '42050',
    '42051',
    '42053',
    '42054',
    '42055',
    '42056',
    '42058',
    '42060',
    '42061',
    '42064',
    '42066',
    '42069',
    '42071',
    '42076',
    '42078',
    '42079',
    '42081',
    '42082',
    '42083',
    '42084',
    '42085',
    '42086',
    '42087',
    '42088',
    '42101',
    '42102',
    '42103',
    '42104',
    '42120',
    '42122',
    '42123',
    '42124',
    '42127',
    '42129',
    '42130',
    '42133',
    '42134',
    '42140',
    '42141',
    '42151',
    '42153',
    '42154',
    '42156',
    '42157',
    '42159',
    '42160',
    '42163',
    '42164',
    '42166',
    '42167',
    '42170',
    '42171',
    '42202',
    '42204',
    '42206',
    '42207',
    '42210',
    '42211',
    '42214',
    '42215',
    '42217',
    '42220',
    '42223',
    '42232',
    '42234',
    '42236',
    '42240',
    '42254',
    '42256',
    '42259',
    '42261',
    '42262',
    '42265',
    '42266',
    '42273',
    '42274',
    '42275',
    '42276',
    '42280',
    '42285',
    '42286',
    '42301',
    '42303',
    '42320',
    '42321',
    '42322',
    '42323',
    '42324',
    '42325',
    '42326',
    '42327',
    '42328',
    '42330',
    '42332',
    '42333',
    '42337',
    '42338',
    '42339',
    '42343',
    '42344',
    '42345',
    '42347',
    '42348',
    '42349',
    '42350',
    '42351',
    '42352',
    '42354',
    '42355',
    '42356',
    '42361',
    '42366',
    '42367',
    '42368',
    '42369',
    '42370',
    '42371',
    '42372',
    '42374',
    '42376',
    '42378',
    '42404',
    '42406',
    '42408',
    '42409',
    '42410',
    '42411',
    '42413',
    '42420',
    '42431',
    '42436',
    '42437',
    '42440',
    '42441',
    '42442',
    '42445',
    '42450',
    '42451',
    '42452',
    '42453',
    '42455',
    '42456',
    '42458',
    '42459',
    '42461',
    '42462',
    '42463',
    '42464',
    '42501',
    '42503',
    '42516',
    '42518',
    '42519',
    '42528',
    '42533',
    '42539',
    '42541',
    '42544',
    '42553',
    '42565',
    '42566',
    '42567',
    '42602',
    '42603',
    '42629',
    '42631',
    '42633',
    '42634',
    '42635',
    '42638',
    '42642',
    '42647',
    '42649',
    '42653',
    '42701',
    '42712',
    '42713',
    '42715',
    '42716',
    '42717',
    '42718',
    '42721',
    '42722',
    '42724',
    '42726',
    '42728',
    '42729',
    '42731',
    '42732',
    '42733',
    '42740',
    '42741',
    '42743',
    '42746',
    '42748',
    '42749',
    '42753',
    '42754',
    '42757',
    '42758',
    '42762',
    '42764',
    '42765',
    '42776',
    '42782',
    '42784',
    '42788'
  ],
  '04': [
    '84531',
    '84536',
    '85003',
    '85004',
    '85006',
    '85007',
    '85008',
    '85009',
    '85012',
    '85013',
    '85014',
    '85015',
    '85016',
    '85017',
    '85018',
    '85019',
    '85020',
    '85021',
    '85022',
    '85023',
    '85024',
    '85027',
    '85028',
    '85029',
    '85031',
    '85032',
    '85033',
    '85034',
    '85035',
    '85037',
    '85040',
    '85041',
    '85042',
    '85043',
    '85044',
    '85045',
    '85048',
    '85050',
    '85051',
    '85053',
    '85054',
    '85083',
    '85085',
    '85086',
    '85087',
    '85118',
    '85119',
    '85120',
    '85121',
    '85122',
    '85123',
    '85128',
    '85131',
    '85132',
    '85135',
    '85137',
    '85138',
    '85139',
    '85140',
    '85141',
    '85142',
    '85143',
    '85145',
    '85147',
    '85172',
    '85173',
    '85192',
    '85193',
    '85194',
    '85201',
    '85202',
    '85203',
    '85204',
    '85205',
    '85206',
    '85207',
    '85208',
    '85209',
    '85210',
    '85212',
    '85213',
    '85215',
    '85224',
    '85225',
    '85226',
    '85233',
    '85234',
    '85248',
    '85249',
    '85250',
    '85251',
    '85253',
    '85254',
    '85255',
    '85256',
    '85257',
    '85258',
    '85259',
    '85260',
    '85262',
    '85263',
    '85264',
    '85266',
    '85268',
    '85281',
    '85282',
    '85283',
    '85284',
    '85286',
    '85295',
    '85296',
    '85297',
    '85298',
    '85301',
    '85302',
    '85303',
    '85304',
    '85305',
    '85306',
    '85307',
    '85308',
    '85309',
    '85310',
    '85320',
    '85321',
    '85322',
    '85323',
    '85324',
    '85325',
    '85326',
    '85328',
    '85331',
    '85332',
    '85333',
    '85334',
    '85335',
    '85336',
    '85337',
    '85338',
    '85339',
    '85340',
    '85341',
    '85342',
    '85343',
    '85344',
    '85345',
    '85346',
    '85347',
    '85348',
    '85349',
    '85350',
    '85351',
    '85352',
    '85353',
    '85354',
    '85355',
    '85356',
    '85357',
    '85360',
    '85361',
    '85362',
    '85363',
    '85364',
    '85365',
    '85367',
    '85371',
    '85373',
    '85374',
    '85375',
    '85377',
    '85379',
    '85381',
    '85382',
    '85383',
    '85387',
    '85388',
    '85390',
    '85392',
    '85395',
    '85396',
    '85501',
    '85530',
    '85531',
    '85533',
    '85534',
    '85535',
    '85536',
    '85539',
    '85540',
    '85541',
    '85542',
    '85543',
    '85544',
    '85545',
    '85546',
    '85550',
    '85551',
    '85552',
    '85553',
    '85554',
    '85601',
    '85602',
    '85603',
    '85605',
    '85606',
    '85607',
    '85608',
    '85609',
    '85610',
    '85611',
    '85613',
    '85614',
    '85615',
    '85616',
    '85617',
    '85618',
    '85619',
    '85620',
    '85621',
    '85622',
    '85623',
    '85624',
    '85625',
    '85626',
    '85627',
    '85629',
    '85630',
    '85631',
    '85632',
    '85633',
    '85634',
    '85635',
    '85637',
    '85638',
    '85640',
    '85641',
    '85643',
    '85645',
    '85646',
    '85648',
    '85650',
    '85653',
    '85654',
    '85658',
    '85701',
    '85704',
    '85705',
    '85706',
    '85707',
    '85708',
    '85710',
    '85711',
    '85712',
    '85713',
    '85714',
    '85715',
    '85716',
    '85718',
    '85719',
    '85723',
    '85724',
    '85726',
    '85730',
    '85735',
    '85736',
    '85737',
    '85739',
    '85741',
    '85742',
    '85743',
    '85745',
    '85746',
    '85747',
    '85748',
    '85749',
    '85750',
    '85755',
    '85756',
    '85757',
    '85901',
    '85911',
    '85912',
    '85920',
    '85922',
    '85923',
    '85924',
    '85925',
    '85926',
    '85927',
    '85928',
    '85929',
    '85930',
    '85931',
    '85932',
    '85933',
    '85934',
    '85935',
    '85936',
    '85937',
    '85938',
    '85939',
    '85940',
    '85941',
    '85942',
    '86001',
    '86003',
    '86004',
    '86005',
    '86011',
    '86015',
    '86016',
    '86017',
    '86018',
    '86020',
    '86021',
    '86022',
    '86023',
    '86024',
    '86025',
    '86028',
    '86029',
    '86030',
    '86031',
    '86032',
    '86033',
    '86034',
    '86035',
    '86036',
    '86038',
    '86039',
    '86040',
    '86042',
    '86043',
    '86044',
    '86045',
    '86046',
    '86047',
    '86052',
    '86053',
    '86054',
    '86301',
    '86303',
    '86305',
    '86313',
    '86314',
    '86315',
    '86320',
    '86321',
    '86322',
    '86323',
    '86324',
    '86325',
    '86326',
    '86327',
    '86329',
    '86331',
    '86332',
    '86333',
    '86334',
    '86335',
    '86336',
    '86337',
    '86338',
    '86343',
    '86351',
    '86401',
    '86403',
    '86404',
    '86406',
    '86409',
    '86411',
    '86413',
    '86426',
    '86429',
    '86431',
    '86432',
    '86433',
    '86434',
    '86435',
    '86436',
    '86437',
    '86438',
    '86440',
    '86441',
    '86442',
    '86443',
    '86444',
    '86445',
    '86502',
    '86503',
    '86504',
    '86505',
    '86506',
    '86507',
    '86508',
    '86510',
    '86511',
    '86512',
    '86514',
    '86515',
    '86520',
    '86535',
    '86538',
    '86540',
    '86544',
    '86545',
    '86547',
    '86556',
    '87328'
  ],
  '55': [
    '53001',
    '53002',
    '53003',
    '53004',
    '53005',
    '53006',
    '53007',
    '53010',
    '53011',
    '53012',
    '53013',
    '53014',
    '53015',
    '53016',
    '53017',
    '53018',
    '53019',
    '53020',
    '53021',
    '53022',
    '53023',
    '53024',
    '53027',
    '53029',
    '53031',
    '53032',
    '53033',
    '53034',
    '53035',
    '53036',
    '53037',
    '53038',
    '53039',
    '53040',
    '53042',
    '53044',
    '53045',
    '53046',
    '53047',
    '53048',
    '53049',
    '53050',
    '53051',
    '53057',
    '53058',
    '53059',
    '53061',
    '53063',
    '53065',
    '53066',
    '53069',
    '53070',
    '53072',
    '53073',
    '53074',
    '53075',
    '53076',
    '53078',
    '53079',
    '53080',
    '53081',
    '53083',
    '53085',
    '53086',
    '53088',
    '53089',
    '53090',
    '53091',
    '53092',
    '53093',
    '53094',
    '53095',
    '53097',
    '53098',
    '53103',
    '53104',
    '53105',
    '53108',
    '53110',
    '53114',
    '53115',
    '53118',
    '53119',
    '53120',
    '53121',
    '53122',
    '53125',
    '53126',
    '53128',
    '53129',
    '53130',
    '53132',
    '53137',
    '53139',
    '53140',
    '53142',
    '53143',
    '53144',
    '53146',
    '53147',
    '53149',
    '53150',
    '53151',
    '53153',
    '53154',
    '53156',
    '53158',
    '53167',
    '53168',
    '53170',
    '53172',
    '53177',
    '53178',
    '53179',
    '53181',
    '53182',
    '53183',
    '53184',
    '53185',
    '53186',
    '53188',
    '53189',
    '53190',
    '53191',
    '53192',
    '53195',
    '53202',
    '53203',
    '53204',
    '53205',
    '53206',
    '53207',
    '53208',
    '53209',
    '53210',
    '53211',
    '53212',
    '53213',
    '53214',
    '53215',
    '53216',
    '53217',
    '53218',
    '53219',
    '53220',
    '53221',
    '53222',
    '53223',
    '53224',
    '53225',
    '53226',
    '53227',
    '53228',
    '53233',
    '53235',
    '53295',
    '53402',
    '53403',
    '53404',
    '53405',
    '53406',
    '53501',
    '53502',
    '53503',
    '53504',
    '53505',
    '53506',
    '53507',
    '53508',
    '53510',
    '53511',
    '53515',
    '53516',
    '53517',
    '53518',
    '53520',
    '53521',
    '53522',
    '53523',
    '53525',
    '53526',
    '53527',
    '53528',
    '53529',
    '53530',
    '53531',
    '53532',
    '53533',
    '53534',
    '53536',
    '53537',
    '53538',
    '53540',
    '53541',
    '53543',
    '53544',
    '53545',
    '53546',
    '53548',
    '53549',
    '53550',
    '53551',
    '53553',
    '53554',
    '53555',
    '53556',
    '53557',
    '53558',
    '53559',
    '53560',
    '53561',
    '53562',
    '53563',
    '53565',
    '53566',
    '53569',
    '53570',
    '53571',
    '53572',
    '53573',
    '53574',
    '53575',
    '53576',
    '53577',
    '53578',
    '53579',
    '53580',
    '53581',
    '53582',
    '53583',
    '53585',
    '53586',
    '53587',
    '53588',
    '53589',
    '53590',
    '53593',
    '53594',
    '53597',
    '53598',
    '53599',
    '53702',
    '53703',
    '53704',
    '53705',
    '53706',
    '53711',
    '53713',
    '53714',
    '53715',
    '53716',
    '53717',
    '53718',
    '53719',
    '53726',
    '53792',
    '53801',
    '53802',
    '53803',
    '53804',
    '53805',
    '53806',
    '53807',
    '53808',
    '53809',
    '53810',
    '53811',
    '53813',
    '53816',
    '53817',
    '53818',
    '53820',
    '53821',
    '53825',
    '53826',
    '53827',
    '53901',
    '53910',
    '53911',
    '53913',
    '53916',
    '53919',
    '53920',
    '53922',
    '53923',
    '53924',
    '53925',
    '53926',
    '53928',
    '53929',
    '53930',
    '53931',
    '53932',
    '53933',
    '53934',
    '53935',
    '53936',
    '53937',
    '53939',
    '53941',
    '53943',
    '53944',
    '53946',
    '53947',
    '53948',
    '53949',
    '53950',
    '53951',
    '53952',
    '53953',
    '53954',
    '53955',
    '53956',
    '53959',
    '53960',
    '53961',
    '53963',
    '53964',
    '53965',
    '53968',
    '53969',
    '54001',
    '54002',
    '54003',
    '54004',
    '54005',
    '54006',
    '54007',
    '54009',
    '54010',
    '54011',
    '54013',
    '54014',
    '54015',
    '54016',
    '54017',
    '54020',
    '54021',
    '54022',
    '54023',
    '54024',
    '54025',
    '54026',
    '54027',
    '54028',
    '54082',
    '54101',
    '54102',
    '54103',
    '54104',
    '54106',
    '54107',
    '54110',
    '54111',
    '54112',
    '54113',
    '54114',
    '54115',
    '54119',
    '54120',
    '54121',
    '54123',
    '54124',
    '54125',
    '54126',
    '54127',
    '54128',
    '54129',
    '54130',
    '54135',
    '54136',
    '54137',
    '54138',
    '54139',
    '54140',
    '54141',
    '54143',
    '54149',
    '54150',
    '54151',
    '54153',
    '54154',
    '54155',
    '54156',
    '54157',
    '54159',
    '54160',
    '54161',
    '54162',
    '54165',
    '54166',
    '54169',
    '54170',
    '54171',
    '54173',
    '54174',
    '54175',
    '54177',
    '54180',
    '54201',
    '54202',
    '54204',
    '54205',
    '54207',
    '54208',
    '54209',
    '54210',
    '54211',
    '54212',
    '54213',
    '54214',
    '54216',
    '54217',
    '54220',
    '54227',
    '54228',
    '54229',
    '54230',
    '54232',
    '54234',
    '54235',
    '54241',
    '54245',
    '54246',
    '54247',
    '54301',
    '54302',
    '54303',
    '54304',
    '54307',
    '54311',
    '54313',
    '54401',
    '54403',
    '54405',
    '54406',
    '54407',
    '54408',
    '54409',
    '54410',
    '54411',
    '54412',
    '54413',
    '54414',
    '54416',
    '54417',
    '54418',
    '54420',
    '54421',
    '54422',
    '54423',
    '54424',
    '54425',
    '54426',
    '54427',
    '54428',
    '54430',
    '54433',
    '54435',
    '54436',
    '54437',
    '54440',
    '54441',
    '54442',
    '54443',
    '54446',
    '54447',
    '54448',
    '54449',
    '54450',
    '54451',
    '54452',
    '54454',
    '54455',
    '54456',
    '54457',
    '54458',
    '54459',
    '54460',
    '54462',
    '54463',
    '54465',
    '54466',
    '54467',
    '54469',
    '54470',
    '54471',
    '54473',
    '54474',
    '54475',
    '54476',
    '54479',
    '54480',
    '54481',
    '54482',
    '54484',
    '54485',
    '54486',
    '54487',
    '54488',
    '54489',
    '54490',
    '54491',
    '54493',
    '54494',
    '54495',
    '54498',
    '54499',
    '54501',
    '54511',
    '54512',
    '54513',
    '54514',
    '54515',
    '54517',
    '54519',
    '54520',
    '54521',
    '54524',
    '54525',
    '54526',
    '54527',
    '54529',
    '54530',
    '54531',
    '54534',
    '54536',
    '54537',
    '54538',
    '54539',
    '54540',
    '54541',
    '54542',
    '54545',
    '54546',
    '54547',
    '54548',
    '54550',
    '54552',
    '54554',
    '54555',
    '54556',
    '54557',
    '54558',
    '54559',
    '54560',
    '54561',
    '54562',
    '54563',
    '54564',
    '54565',
    '54566',
    '54568',
    '54601',
    '54603',
    '54610',
    '54611',
    '54612',
    '54613',
    '54614',
    '54615',
    '54616',
    '54618',
    '54619',
    '54621',
    '54622',
    '54623',
    '54624',
    '54625',
    '54626',
    '54627',
    '54628',
    '54629',
    '54630',
    '54631',
    '54632',
    '54634',
    '54635',
    '54636',
    '54637',
    '54638',
    '54639',
    '54641',
    '54642',
    '54643',
    '54644',
    '54645',
    '54646',
    '54648',
    '54650',
    '54651',
    '54652',
    '54653',
    '54654',
    '54655',
    '54656',
    '54657',
    '54658',
    '54659',
    '54660',
    '54661',
    '54664',
    '54665',
    '54666',
    '54667',
    '54669',
    '54670',
    '54701',
    '54703',
    '54720',
    '54721',
    '54722',
    '54723',
    '54724',
    '54725',
    '54726',
    '54727',
    '54728',
    '54729',
    '54730',
    '54731',
    '54732',
    '54733',
    '54734',
    '54736',
    '54737',
    '54738',
    '54739',
    '54740',
    '54741',
    '54742',
    '54745',
    '54746',
    '54747',
    '54748',
    '54749',
    '54750',
    '54751',
    '54754',
    '54755',
    '54756',
    '54757',
    '54758',
    '54759',
    '54760',
    '54761',
    '54762',
    '54763',
    '54765',
    '54766',
    '54767',
    '54768',
    '54769',
    '54770',
    '54771',
    '54772',
    '54773',
    '54801',
    '54805',
    '54806',
    '54810',
    '54812',
    '54813',
    '54814',
    '54817',
    '54819',
    '54820',
    '54821',
    '54822',
    '54824',
    '54826',
    '54827',
    '54828',
    '54829',
    '54830',
    '54832',
    '54835',
    '54836',
    '54837',
    '54838',
    '54839',
    '54840',
    '54841',
    '54842',
    '54843',
    '54844',
    '54845',
    '54846',
    '54847',
    '54848',
    '54849',
    '54850',
    '54853',
    '54854',
    '54855',
    '54856',
    '54857',
    '54858',
    '54859',
    '54861',
    '54862',
    '54864',
    '54865',
    '54867',
    '54868',
    '54870',
    '54871',
    '54872',
    '54873',
    '54874',
    '54875',
    '54876',
    '54880',
    '54888',
    '54889',
    '54891',
    '54893',
    '54895',
    '54896',
    '54901',
    '54902',
    '54904',
    '54909',
    '54911',
    '54913',
    '54914',
    '54915',
    '54921',
    '54922',
    '54923',
    '54927',
    '54928',
    '54929',
    '54930',
    '54931',
    '54932',
    '54933',
    '54934',
    '54935',
    '54937',
    '54940',
    '54941',
    '54942',
    '54943',
    '54944',
    '54945',
    '54946',
    '54947',
    '54948',
    '54949',
    '54950',
    '54952',
    '54956',
    '54960',
    '54961',
    '54962',
    '54963',
    '54964',
    '54965',
    '54966',
    '54967',
    '54968',
    '54970',
    '54971',
    '54974',
    '54977',
    '54979',
    '54980',
    '54981',
    '54982',
    '54983',
    '54984',
    '54985',
    '54986'
  ],
  '38': [
    '56744',
    '57638',
    '57641',
    '57642',
    '57645',
    '57648',
    '57660',
    '58001',
    '58002',
    '58004',
    '58005',
    '58006',
    '58007',
    '58008',
    '58009',
    '58011',
    '58012',
    '58013',
    '58015',
    '58016',
    '58017',
    '58018',
    '58021',
    '58027',
    '58029',
    '58030',
    '58031',
    '58032',
    '58033',
    '58035',
    '58036',
    '58038',
    '58040',
    '58041',
    '58042',
    '58043',
    '58045',
    '58046',
    '58047',
    '58048',
    '58049',
    '58051',
    '58052',
    '58053',
    '58054',
    '58056',
    '58057',
    '58058',
    '58059',
    '58060',
    '58061',
    '58062',
    '58063',
    '58064',
    '58065',
    '58067',
    '58068',
    '58069',
    '58071',
    '58072',
    '58075',
    '58076',
    '58077',
    '58078',
    '58079',
    '58081',
    '58102',
    '58103',
    '58104',
    '58105',
    '58201',
    '58202',
    '58203',
    '58204',
    '58205',
    '58210',
    '58212',
    '58214',
    '58216',
    '58218',
    '58219',
    '58220',
    '58222',
    '58223',
    '58224',
    '58225',
    '58227',
    '58228',
    '58229',
    '58230',
    '58231',
    '58233',
    '58235',
    '58237',
    '58238',
    '58239',
    '58240',
    '58241',
    '58243',
    '58244',
    '58249',
    '58250',
    '58251',
    '58254',
    '58256',
    '58257',
    '58258',
    '58259',
    '58260',
    '58261',
    '58262',
    '58265',
    '58266',
    '58267',
    '58269',
    '58270',
    '58271',
    '58272',
    '58273',
    '58274',
    '58275',
    '58276',
    '58277',
    '58278',
    '58281',
    '58282',
    '58301',
    '58311',
    '58316',
    '58317',
    '58318',
    '58321',
    '58323',
    '58324',
    '58325',
    '58327',
    '58329',
    '58330',
    '58331',
    '58332',
    '58335',
    '58338',
    '58339',
    '58341',
    '58343',
    '58344',
    '58345',
    '58346',
    '58348',
    '58351',
    '58352',
    '58353',
    '58355',
    '58356',
    '58357',
    '58361',
    '58362',
    '58363',
    '58365',
    '58366',
    '58367',
    '58368',
    '58369',
    '58370',
    '58372',
    '58374',
    '58377',
    '58379',
    '58380',
    '58381',
    '58382',
    '58384',
    '58385',
    '58386',
    '58401',
    '58402',
    '58405',
    '58413',
    '58415',
    '58416',
    '58418',
    '58420',
    '58421',
    '58422',
    '58423',
    '58424',
    '58425',
    '58426',
    '58428',
    '58429',
    '58430',
    '58431',
    '58433',
    '58436',
    '58438',
    '58439',
    '58440',
    '58441',
    '58442',
    '58443',
    '58444',
    '58445',
    '58448',
    '58451',
    '58452',
    '58454',
    '58455',
    '58456',
    '58458',
    '58460',
    '58461',
    '58463',
    '58464',
    '58466',
    '58467',
    '58472',
    '58474',
    '58475',
    '58476',
    '58477',
    '58478',
    '58479',
    '58480',
    '58481',
    '58482',
    '58483',
    '58484',
    '58486',
    '58487',
    '58488',
    '58490',
    '58492',
    '58494',
    '58495',
    '58496',
    '58497',
    '58501',
    '58503',
    '58504',
    '58505',
    '58520',
    '58521',
    '58523',
    '58524',
    '58528',
    '58529',
    '58530',
    '58531',
    '58532',
    '58533',
    '58535',
    '58538',
    '58540',
    '58541',
    '58542',
    '58544',
    '58545',
    '58549',
    '58552',
    '58554',
    '58558',
    '58559',
    '58560',
    '58561',
    '58562',
    '58563',
    '58564',
    '58565',
    '58566',
    '58568',
    '58569',
    '58570',
    '58571',
    '58572',
    '58573',
    '58575',
    '58576',
    '58577',
    '58579',
    '58580',
    '58581',
    '58601',
    '58620',
    '58621',
    '58622',
    '58623',
    '58625',
    '58626',
    '58627',
    '58630',
    '58631',
    '58632',
    '58634',
    '58636',
    '58638',
    '58639',
    '58640',
    '58641',
    '58642',
    '58643',
    '58645',
    '58646',
    '58647',
    '58649',
    '58650',
    '58651',
    '58652',
    '58653',
    '58654',
    '58655',
    '58656',
    '58701',
    '58702',
    '58703',
    '58704',
    '58705',
    '58707',
    '58710',
    '58711',
    '58712',
    '58713',
    '58716',
    '58718',
    '58721',
    '58722',
    '58723',
    '58725',
    '58727',
    '58730',
    '58731',
    '58733',
    '58734',
    '58735',
    '58736',
    '58737',
    '58740',
    '58741',
    '58744',
    '58746',
    '58748',
    '58750',
    '58752',
    '58755',
    '58756',
    '58757',
    '58758',
    '58759',
    '58760',
    '58761',
    '58762',
    '58763',
    '58765',
    '58768',
    '58769',
    '58770',
    '58771',
    '58772',
    '58773',
    '58775',
    '58776',
    '58778',
    '58779',
    '58781',
    '58782',
    '58783',
    '58784',
    '58785',
    '58787',
    '58788',
    '58789',
    '58790',
    '58792',
    '58793',
    '58794',
    '58795',
    '58801',
    '58830',
    '58831',
    '58833',
    '58835',
    '58838',
    '58843',
    '58844',
    '58845',
    '58847',
    '58849',
    '58852',
    '58853',
    '58854',
    '58856',
    '59221',
    '59270',
    '59275'
  ],
  '48': [
    '73949',
    '75001',
    '75002',
    '75006',
    '75007',
    '75009',
    '75010',
    '75013',
    '75019',
    '75020',
    '75021',
    '75022',
    '75023',
    '75024',
    '75025',
    '75028',
    '75032',
    '75034',
    '75035',
    '75038',
    '75039',
    '75040',
    '75041',
    '75042',
    '75043',
    '75044',
    '75048',
    '75050',
    '75051',
    '75052',
    '75054',
    '75056',
    '75057',
    '75058',
    '75060',
    '75061',
    '75062',
    '75063',
    '75065',
    '75067',
    '75068',
    '75069',
    '75070',
    '75071',
    '75074',
    '75075',
    '75076',
    '75077',
    '75078',
    '75080',
    '75081',
    '75082',
    '75087',
    '75088',
    '75089',
    '75090',
    '75092',
    '75093',
    '75094',
    '75098',
    '75101',
    '75102',
    '75103',
    '75104',
    '75105',
    '75109',
    '75110',
    '75114',
    '75115',
    '75116',
    '75117',
    '75119',
    '75124',
    '75125',
    '75126',
    '75127',
    '75132',
    '75134',
    '75135',
    '75137',
    '75140',
    '75141',
    '75142',
    '75143',
    '75144',
    '75146',
    '75147',
    '75148',
    '75149',
    '75150',
    '75152',
    '75153',
    '75154',
    '75155',
    '75156',
    '75157',
    '75158',
    '75159',
    '75160',
    '75161',
    '75163',
    '75164',
    '75165',
    '75166',
    '75167',
    '75169',
    '75172',
    '75173',
    '75180',
    '75181',
    '75182',
    '75189',
    '75201',
    '75202',
    '75203',
    '75204',
    '75205',
    '75206',
    '75207',
    '75208',
    '75209',
    '75210',
    '75211',
    '75212',
    '75214',
    '75215',
    '75216',
    '75217',
    '75218',
    '75219',
    '75220',
    '75223',
    '75224',
    '75225',
    '75226',
    '75227',
    '75228',
    '75229',
    '75230',
    '75231',
    '75232',
    '75233',
    '75234',
    '75235',
    '75236',
    '75237',
    '75238',
    '75240',
    '75241',
    '75243',
    '75244',
    '75246',
    '75247',
    '75248',
    '75249',
    '75251',
    '75252',
    '75253',
    '75254',
    '75270',
    '75287',
    '75390',
    '75401',
    '75402',
    '75407',
    '75409',
    '75410',
    '75411',
    '75412',
    '75413',
    '75414',
    '75415',
    '75416',
    '75417',
    '75418',
    '75420',
    '75421',
    '75422',
    '75423',
    '75424',
    '75426',
    '75428',
    '75431',
    '75432',
    '75433',
    '75435',
    '75436',
    '75437',
    '75438',
    '75439',
    '75440',
    '75441',
    '75442',
    '75446',
    '75447',
    '75448',
    '75449',
    '75450',
    '75451',
    '75452',
    '75453',
    '75454',
    '75455',
    '75457',
    '75459',
    '75460',
    '75462',
    '75468',
    '75469',
    '75470',
    '75471',
    '75472',
    '75473',
    '75474',
    '75475',
    '75476',
    '75477',
    '75478',
    '75479',
    '75480',
    '75481',
    '75482',
    '75486',
    '75487',
    '75488',
    '75489',
    '75490',
    '75491',
    '75492',
    '75493',
    '75494',
    '75495',
    '75496',
    '75497',
    '75501',
    '75503',
    '75550',
    '75551',
    '75554',
    '75555',
    '75556',
    '75558',
    '75559',
    '75560',
    '75561',
    '75562',
    '75563',
    '75565',
    '75566',
    '75567',
    '75568',
    '75569',
    '75570',
    '75571',
    '75572',
    '75573',
    '75574',
    '75601',
    '75602',
    '75603',
    '75604',
    '75605',
    '75630',
    '75631',
    '75633',
    '75638',
    '75639',
    '75640',
    '75641',
    '75642',
    '75643',
    '75644',
    '75645',
    '75647',
    '75650',
    '75651',
    '75652',
    '75654',
    '75656',
    '75657',
    '75661',
    '75662',
    '75667',
    '75668',
    '75669',
    '75670',
    '75672',
    '75681',
    '75682',
    '75683',
    '75684',
    '75686',
    '75691',
    '75692',
    '75693',
    '75701',
    '75702',
    '75703',
    '75704',
    '75705',
    '75706',
    '75707',
    '75708',
    '75709',
    '75750',
    '75751',
    '75752',
    '75754',
    '75755',
    '75756',
    '75757',
    '75758',
    '75759',
    '75760',
    '75762',
    '75763',
    '75764',
    '75765',
    '75766',
    '75770',
    '75771',
    '75773',
    '75778',
    '75779',
    '75780',
    '75783',
    '75784',
    '75785',
    '75788',
    '75789',
    '75790',
    '75791',
    '75792',
    '75801',
    '75803',
    '75831',
    '75832',
    '75833',
    '75835',
    '75838',
    '75839',
    '75840',
    '75844',
    '75845',
    '75846',
    '75847',
    '75848',
    '75849',
    '75850',
    '75851',
    '75852',
    '75853',
    '75855',
    '75856',
    '75858',
    '75859',
    '75860',
    '75861',
    '75862',
    '75901',
    '75904',
    '75925',
    '75926',
    '75928',
    '75929',
    '75930',
    '75931',
    '75932',
    '75933',
    '75934',
    '75935',
    '75936',
    '75937',
    '75938',
    '75939',
    '75941',
    '75942',
    '75943',
    '75944',
    '75946',
    '75948',
    '75949',
    '75951',
    '75954',
    '75956',
    '75959',
    '75960',
    '75961',
    '75962',
    '75964',
    '75965',
    '75966',
    '75968',
    '75969',
    '75972',
    '75973',
    '75974',
    '75975',
    '75976',
    '75977',
    '75978',
    '75979',
    '75980',
    '76001',
    '76002',
    '76006',
    '76008',
    '76009',
    '76010',
    '76011',
    '76012',
    '76013',
    '76014',
    '76015',
    '76016',
    '76017',
    '76018',
    '76020',
    '76021',
    '76022',
    '76023',
    '76028',
    '76031',
    '76033',
    '76034',
    '76035',
    '76036',
    '76039',
    '76040',
    '76041',
    '76043',
    '76044',
    '76048',
    '76049',
    '76050',
    '76051',
    '76052',
    '76053',
    '76054',
    '76055',
    '76058',
    '76059',
    '76060',
    '76061',
    '76063',
    '76064',
    '76065',
    '76066',
    '76067',
    '76070',
    '76071',
    '76073',
    '76077',
    '76078',
    '76082',
    '76084',
    '76085',
    '76086',
    '76087',
    '76088',
    '76092',
    '76093',
    '76102',
    '76103',
    '76104',
    '76105',
    '76106',
    '76107',
    '76108',
    '76109',
    '76110',
    '76111',
    '76112',
    '76114',
    '76115',
    '76116',
    '76117',
    '76118',
    '76119',
    '76120',
    '76123',
    '76126',
    '76127',
    '76129',
    '76131',
    '76132',
    '76133',
    '76134',
    '76135',
    '76137',
    '76140',
    '76148',
    '76155',
    '76164',
    '76177',
    '76179',
    '76180',
    '76182',
    '76201',
    '76205',
    '76207',
    '76208',
    '76209',
    '76210',
    '76225',
    '76226',
    '76227',
    '76228',
    '76230',
    '76233',
    '76234',
    '76238',
    '76239',
    '76240',
    '76244',
    '76245',
    '76247',
    '76248',
    '76249',
    '76250',
    '76251',
    '76252',
    '76253',
    '76255',
    '76258',
    '76259',
    '76261',
    '76262',
    '76263',
    '76264',
    '76265',
    '76266',
    '76268',
    '76270',
    '76271',
    '76272',
    '76273',
    '76301',
    '76302',
    '76305',
    '76306',
    '76308',
    '76309',
    '76310',
    '76311',
    '76351',
    '76354',
    '76357',
    '76360',
    '76363',
    '76364',
    '76365',
    '76366',
    '76367',
    '76370',
    '76371',
    '76372',
    '76373',
    '76374',
    '76377',
    '76379',
    '76380',
    '76384',
    '76388',
    '76389',
    '76401',
    '76402',
    '76424',
    '76426',
    '76427',
    '76429',
    '76430',
    '76431',
    '76432',
    '76433',
    '76435',
    '76436',
    '76437',
    '76442',
    '76443',
    '76444',
    '76445',
    '76446',
    '76448',
    '76449',
    '76450',
    '76452',
    '76453',
    '76454',
    '76455',
    '76457',
    '76458',
    '76459',
    '76460',
    '76462',
    '76463',
    '76464',
    '76466',
    '76469',
    '76470',
    '76471',
    '76472',
    '76474',
    '76475',
    '76476',
    '76481',
    '76483',
    '76484',
    '76486',
    '76487',
    '76490',
    '76491',
    '76501',
    '76502',
    '76504',
    '76508',
    '76511',
    '76513',
    '76518',
    '76519',
    '76520',
    '76522',
    '76523',
    '76524',
    '76525',
    '76527',
    '76528',
    '76530',
    '76531',
    '76534',
    '76537',
    '76538',
    '76539',
    '76541',
    '76542',
    '76543',
    '76544',
    '76548',
    '76549',
    '76550',
    '76554',
    '76556',
    '76557',
    '76559',
    '76561',
    '76565',
    '76566',
    '76567',
    '76569',
    '76570',
    '76571',
    '76573',
    '76574',
    '76577',
    '76578',
    '76579',
    '76596',
    '76597',
    '76598',
    '76599',
    '76621',
    '76622',
    '76623',
    '76624',
    '76626',
    '76627',
    '76628',
    '76629',
    '76630',
    '76631',
    '76632',
    '76633',
    '76634',
    '76635',
    '76636',
    '76637',
    '76638',
    '76639',
    '76640',
    '76641',
    '76642',
    '76643',
    '76645',
    '76648',
    '76649',
    '76650',
    '76651',
    '76652',
    '76653',
    '76654',
    '76655',
    '76656',
    '76657',
    '76660',
    '76661',
    '76664',
    '76665',
    '76666',
    '76667',
    '76670',
    '76671',
    '76673',
    '76676',
    '76678',
    '76679',
    '76680',
    '76681',
    '76682',
    '76685',
    '76686',
    '76687',
    '76689',
    '76690',
    '76691',
    '76692',
    '76693',
    '76701',
    '76704',
    '76705',
    '76706',
    '76707',
    '76708',
    '76710',
    '76711',
    '76712',
    '76798',
    '76801',
    '76802',
    '76820',
    '76821',
    '76823',
    '76824',
    '76825',
    '76827',
    '76828',
    '76831',
    '76832',
    '76834',
    '76836',
    '76837',
    '76841',
    '76842',
    '76844',
    '76845',
    '76848',
    '76849',
    '76852',
    '76853',
    '76854',
    '76856',
    '76857',
    '76858',
    '76859',
    '76861',
    '76862',
    '76864',
    '76865',
    '76866',
    '76869',
    '76870',
    '76871',
    '76872',
    '76873',
    '76874',
    '76875',
    '76877',
    '76878',
    '76882',
    '76884',
    '76885',
    '76887',
    '76888',
    '76890',
    '76901',
    '76903',
    '76904',
    '76905',
    '76908',
    '76930',
    '76932',
    '76933',
    '76934',
    '76935',
    '76936',
    '76937',
    '76939',
    '76940',
    '76941',
    '76943',
    '76945',
    '76949',
    '76950',
    '76951',
    '76953',
    '76955',
    '76957',
    '76958',
    '77002',
    '77003',
    '77004',
    '77005',
    '77006',
    '77007',
    '77008',
    '77009',
    '77010',
    '77011',
    '77012',
    '77013',
    '77014',
    '77015',
    '77016',
    '77017',
    '77018',
    '77019',
    '77020',
    '77021',
    '77022',
    '77023',
    '77024',
    '77025',
    '77026',
    '77027',
    '77028',
    '77029',
    '77030',
    '77031',
    '77032',
    '77033',
    '77034',
    '77035',
    '77036',
    '77037',
    '77038',
    '77039',
    '77040',
    '77041',
    '77042',
    '77043',
    '77044',
    '77045',
    '77046',
    '77047',
    '77048',
    '77049',
    '77050',
    '77051',
    '77053',
    '77054',
    '77055',
    '77056',
    '77057',
    '77058',
    '77059',
    '77060',
    '77061',
    '77062',
    '77063',
    '77064',
    '77065',
    '77066',
    '77067',
    '77068',
    '77069',
    '77070',
    '77071',
    '77072',
    '77073',
    '77074',
    '77075',
    '77076',
    '77077',
    '77078',
    '77079',
    '77080',
    '77081',
    '77082',
    '77083',
    '77084',
    '77085',
    '77086',
    '77087',
    '77088',
    '77089',
    '77090',
    '77091',
    '77092',
    '77093',
    '77094',
    '77095',
    '77096',
    '77098',
    '77099',
    '77201',
    '77301',
    '77302',
    '77303',
    '77304',
    '77306',
    '77316',
    '77318',
    '77320',
    '77326',
    '77327',
    '77328',
    '77331',
    '77334',
    '77335',
    '77336',
    '77338',
    '77339',
    '77340',
    '77342',
    '77345',
    '77346',
    '77350',
    '77351',
    '77354',
    '77355',
    '77356',
    '77357',
    '77358',
    '77359',
    '77360',
    '77362',
    '77363',
    '77364',
    '77365',
    '77367',
    '77368',
    '77369',
    '77371',
    '77372',
    '77373',
    '77374',
    '77375',
    '77376',
    '77377',
    '77378',
    '77379',
    '77380',
    '77381',
    '77382',
    '77384',
    '77385',
    '77386',
    '77388',
    '77389',
    '77396',
    '77401',
    '77406',
    '77407',
    '77412',
    '77414',
    '77415',
    '77417',
    '77418',
    '77419',
    '77420',
    '77422',
    '77423',
    '77426',
    '77428',
    '77429',
    '77430',
    '77432',
    '77433',
    '77434',
    '77435',
    '77436',
    '77437',
    '77440',
    '77441',
    '77442',
    '77443',
    '77444',
    '77445',
    '77446',
    '77447',
    '77448',
    '77449',
    '77450',
    '77451',
    '77453',
    '77454',
    '77455',
    '77456',
    '77457',
    '77458',
    '77459',
    '77460',
    '77461',
    '77464',
    '77465',
    '77466',
    '77467',
    '77468',
    '77469',
    '77470',
    '77471',
    '77473',
    '77474',
    '77475',
    '77476',
    '77477',
    '77478',
    '77479',
    '77480',
    '77481',
    '77482',
    '77483',
    '77484',
    '77485',
    '77486',
    '77488',
    '77489',
    '77493',
    '77494',
    '77498',
    '77502',
    '77503',
    '77504',
    '77505',
    '77506',
    '77507',
    '77510',
    '77511',
    '77514',
    '77515',
    '77517',
    '77518',
    '77519',
    '77520',
    '77521',
    '77523',
    '77530',
    '77531',
    '77532',
    '77533',
    '77534',
    '77535',
    '77536',
    '77538',
    '77539',
    '77541',
    '77545',
    '77546',
    '77547',
    '77550',
    '77551',
    '77554',
    '77560',
    '77561',
    '77562',
    '77563',
    '77564',
    '77565',
    '77566',
    '77568',
    '77571',
    '77573',
    '77575',
    '77577',
    '77578',
    '77580',
    '77581',
    '77583',
    '77584',
    '77585',
    '77586',
    '77587',
    '77590',
    '77591',
    '77597',
    '77598',
    '77611',
    '77612',
    '77613',
    '77614',
    '77615',
    '77616',
    '77617',
    '77619',
    '77622',
    '77623',
    '77624',
    '77625',
    '77627',
    '77629',
    '77630',
    '77632',
    '77640',
    '77642',
    '77650',
    '77651',
    '77655',
    '77656',
    '77657',
    '77659',
    '77660',
    '77661',
    '77662',
    '77663',
    '77664',
    '77665',
    '77701',
    '77702',
    '77703',
    '77705',
    '77706',
    '77707',
    '77708',
    '77713',
    '77801',
    '77802',
    '77803',
    '77807',
    '77808',
    '77830',
    '77831',
    '77833',
    '77835',
    '77836',
    '77837',
    '77840',
    '77845',
    '77853',
    '77855',
    '77856',
    '77857',
    '77859',
    '77861',
    '77864',
    '77865',
    '77867',
    '77868',
    '77871',
    '77872',
    '77873',
    '77876',
    '77878',
    '77879',
    '77880',
    '77901',
    '77904',
    '77905',
    '77950',
    '77951',
    '77954',
    '77957',
    '77960',
    '77961',
    '77962',
    '77963',
    '77964',
    '77968',
    '77969',
    '77970',
    '77971',
    '77973',
    '77974',
    '77975',
    '77976',
    '77977',
    '77978',
    '77979',
    '77982',
    '77983',
    '77984',
    '77987',
    '77988',
    '77990',
    '77991',
    '77993',
    '77994',
    '77995',
    '78001',
    '78002',
    '78003',
    '78004',
    '78005',
    '78006',
    '78007',
    '78008',
    '78009',
    '78010',
    '78011',
    '78012',
    '78013',
    '78014',
    '78015',
    '78016',
    '78017',
    '78019',
    '78021',
    '78022',
    '78023',
    '78024',
    '78025',
    '78026',
    '78027',
    '78028',
    '78029',
    '78039',
    '78040',
    '78041',
    '78043',
    '78044',
    '78045',
    '78046',
    '78050',
    '78052',
    '78055',
    '78056',
    '78057',
    '78058',
    '78059',
    '78060',
    '78061',
    '78063',
    '78064',
    '78065',
    '78066',
    '78067',
    '78069',
    '78070',
    '78071',
    '78072',
    '78073',
    '78075',
    '78076',
    '78101',
    '78102',
    '78104',
    '78107',
    '78108',
    '78109',
    '78112',
    '78113',
    '78114',
    '78116',
    '78117',
    '78118',
    '78119',
    '78121',
    '78122',
    '78123',
    '78124',
    '78125',
    '78130',
    '78132',
    '78133',
    '78140',
    '78141',
    '78142',
    '78143',
    '78144',
    '78145',
    '78146',
    '78147',
    '78148',
    '78150',
    '78151',
    '78152',
    '78154',
    '78155',
    '78159',
    '78160',
    '78161',
    '78162',
    '78163',
    '78164',
    '78201',
    '78202',
    '78203',
    '78204',
    '78205',
    '78207',
    '78208',
    '78209',
    '78210',
    '78211',
    '78212',
    '78213',
    '78214',
    '78215',
    '78216',
    '78217',
    '78218',
    '78219',
    '78220',
    '78221',
    '78222',
    '78223',
    '78224',
    '78225',
    '78226',
    '78227',
    '78228',
    '78229',
    '78230',
    '78231',
    '78232',
    '78233',
    '78234',
    '78235',
    '78236',
    '78237',
    '78238',
    '78239',
    '78240',
    '78242',
    '78243',
    '78244',
    '78245',
    '78247',
    '78248',
    '78249',
    '78250',
    '78251',
    '78252',
    '78253',
    '78254',
    '78255',
    '78256',
    '78257',
    '78258',
    '78259',
    '78260',
    '78261',
    '78263',
    '78264',
    '78266',
    '78330',
    '78332',
    '78335',
    '78336',
    '78338',
    '78339',
    '78340',
    '78341',
    '78342',
    '78343',
    '78344',
    '78349',
    '78351',
    '78352',
    '78353',
    '78355',
    '78357',
    '78358',
    '78359',
    '78361',
    '78362',
    '78363',
    '78368',
    '78369',
    '78370',
    '78371',
    '78372',
    '78373',
    '78374',
    '78375',
    '78376',
    '78377',
    '78379',
    '78380',
    '78382',
    '78383',
    '78384',
    '78385',
    '78387',
    '78389',
    '78390',
    '78391',
    '78393',
    '78401',
    '78402',
    '78404',
    '78405',
    '78406',
    '78407',
    '78408',
    '78409',
    '78410',
    '78411',
    '78412',
    '78413',
    '78414',
    '78415',
    '78416',
    '78417',
    '78418',
    '78419',
    '78501',
    '78503',
    '78504',
    '78516',
    '78520',
    '78521',
    '78526',
    '78535',
    '78536',
    '78537',
    '78538',
    '78539',
    '78541',
    '78542',
    '78543',
    '78545',
    '78548',
    '78549',
    '78550',
    '78552',
    '78557',
    '78558',
    '78559',
    '78560',
    '78561',
    '78562',
    '78563',
    '78564',
    '78565',
    '78566',
    '78567',
    '78569',
    '78570',
    '78572',
    '78573',
    '78574',
    '78575',
    '78576',
    '78577',
    '78578',
    '78579',
    '78580',
    '78582',
    '78583',
    '78584',
    '78585',
    '78586',
    '78588',
    '78589',
    '78590',
    '78591',
    '78592',
    '78593',
    '78594',
    '78595',
    '78596',
    '78597',
    '78598',
    '78599',
    '78602',
    '78605',
    '78606',
    '78607',
    '78608',
    '78609',
    '78610',
    '78611',
    '78612',
    '78613',
    '78614',
    '78615',
    '78616',
    '78617',
    '78618',
    '78619',
    '78620',
    '78621',
    '78622',
    '78623',
    '78624',
    '78626',
    '78628',
    '78629',
    '78631',
    '78632',
    '78633',
    '78634',
    '78635',
    '78636',
    '78638',
    '78639',
    '78640',
    '78641',
    '78642',
    '78643',
    '78644',
    '78645',
    '78648',
    '78650',
    '78652',
    '78653',
    '78654',
    '78655',
    '78656',
    '78657',
    '78659',
    '78660',
    '78661',
    '78662',
    '78663',
    '78664',
    '78665',
    '78666',
    '78669',
    '78670',
    '78671',
    '78672',
    '78675',
    '78676',
    '78677',
    '78681',
    '78701',
    '78702',
    '78703',
    '78704',
    '78705',
    '78712',
    '78717',
    '78719',
    '78721',
    '78722',
    '78723',
    '78724',
    '78725',
    '78726',
    '78727',
    '78728',
    '78729',
    '78730',
    '78731',
    '78732',
    '78733',
    '78734',
    '78735',
    '78736',
    '78737',
    '78738',
    '78739',
    '78741',
    '78742',
    '78744',
    '78745',
    '78746',
    '78747',
    '78748',
    '78749',
    '78750',
    '78751',
    '78752',
    '78753',
    '78754',
    '78756',
    '78757',
    '78758',
    '78759',
    '78801',
    '78802',
    '78827',
    '78828',
    '78829',
    '78830',
    '78832',
    '78833',
    '78834',
    '78836',
    '78837',
    '78838',
    '78839',
    '78840',
    '78843',
    '78850',
    '78851',
    '78852',
    '78860',
    '78861',
    '78870',
    '78871',
    '78872',
    '78873',
    '78877',
    '78879',
    '78880',
    '78881',
    '78883',
    '78884',
    '78885',
    '78886',
    '78931',
    '78932',
    '78933',
    '78934',
    '78935',
    '78938',
    '78940',
    '78941',
    '78942',
    '78943',
    '78944',
    '78945',
    '78946',
    '78947',
    '78948',
    '78949',
    '78950',
    '78951',
    '78953',
    '78954',
    '78956',
    '78957',
    '78959',
    '78962',
    '78963',
    '79001',
    '79003',
    '79005',
    '79007',
    '79009',
    '79010',
    '79011',
    '79012',
    '79013',
    '79014',
    '79015',
    '79016',
    '79018',
    '79019',
    '79021',
    '79022',
    '79024',
    '79025',
    '79027',
    '79029',
    '79031',
    '79032',
    '79033',
    '79034',
    '79035',
    '79036',
    '79039',
    '79040',
    '79041',
    '79042',
    '79043',
    '79044',
    '79045',
    '79046',
    '79051',
    '79052',
    '79053',
    '79054',
    '79056',
    '79057',
    '79058',
    '79059',
    '79061',
    '79062',
    '79063',
    '79064',
    '79065',
    '79068',
    '79070',
    '79072',
    '79078',
    '79079',
    '79080',
    '79081',
    '79082',
    '79083',
    '79084',
    '79085',
    '79086',
    '79087',
    '79088',
    '79091',
    '79092',
    '79093',
    '79094',
    '79095',
    '79096',
    '79097',
    '79098',
    '79101',
    '79102',
    '79103',
    '79104',
    '79105',
    '79106',
    '79107',
    '79108',
    '79109',
    '79110',
    '79111',
    '79118',
    '79119',
    '79121',
    '79124',
    '79201',
    '79220',
    '79225',
    '79226',
    '79227',
    '79229',
    '79230',
    '79231',
    '79233',
    '79234',
    '79235',
    '79236',
    '79237',
    '79239',
    '79240',
    '79241',
    '79243',
    '79244',
    '79245',
    '79247',
    '79248',
    '79250',
    '79251',
    '79252',
    '79255',
    '79256',
    '79257',
    '79258',
    '79259',
    '79261',
    '79311',
    '79312',
    '79313',
    '79314',
    '79316',
    '79322',
    '79323',
    '79324',
    '79325',
    '79326',
    '79329',
    '79330',
    '79331',
    '79336',
    '79339',
    '79342',
    '79343',
    '79344',
    '79345',
    '79346',
    '79347',
    '79350',
    '79351',
    '79353',
    '79355',
    '79356',
    '79357',
    '79358',
    '79359',
    '79360',
    '79363',
    '79364',
    '79366',
    '79367',
    '79369',
    '79370',
    '79371',
    '79372',
    '79373',
    '79376',
    '79377',
    '79378',
    '79379',
    '79380',
    '79381',
    '79382',
    '79401',
    '79403',
    '79404',
    '79406',
    '79407',
    '79410',
    '79411',
    '79412',
    '79413',
    '79414',
    '79415',
    '79416',
    '79423',
    '79424',
    '79501',
    '79502',
    '79503',
    '79504',
    '79505',
    '79506',
    '79508',
    '79510',
    '79511',
    '79512',
    '79517',
    '79518',
    '79519',
    '79520',
    '79521',
    '79525',
    '79526',
    '79527',
    '79528',
    '79529',
    '79530',
    '79532',
    '79533',
    '79534',
    '79535',
    '79536',
    '79537',
    '79538',
    '79539',
    '79540',
    '79541',
    '79543',
    '79544',
    '79545',
    '79546',
    '79547',
    '79548',
    '79549',
    '79553',
    '79556',
    '79560',
    '79561',
    '79562',
    '79563',
    '79565',
    '79566',
    '79567',
    '79601',
    '79602',
    '79603',
    '79605',
    '79606',
    '79607',
    '79699',
    '79701',
    '79703',
    '79705',
    '79706',
    '79707',
    '79713',
    '79714',
    '79718',
    '79719',
    '79720',
    '79730',
    '79731',
    '79733',
    '79734',
    '79735',
    '79738',
    '79739',
    '79741',
    '79742',
    '79743',
    '79744',
    '79745',
    '79748',
    '79749',
    '79752',
    '79754',
    '79755',
    '79756',
    '79758',
    '79759',
    '79761',
    '79762',
    '79763',
    '79764',
    '79765',
    '79766',
    '79770',
    '79772',
    '79777',
    '79778',
    '79780',
    '79781',
    '79782',
    '79783',
    '79785',
    '79788',
    '79789',
    '79821',
    '79830',
    '79831',
    '79834',
    '79835',
    '79836',
    '79837',
    '79838',
    '79839',
    '79842',
    '79843',
    '79845',
    '79846',
    '79847',
    '79848',
    '79849',
    '79851',
    '79852',
    '79853',
    '79854',
    '79855',
    '79901',
    '79902',
    '79903',
    '79904',
    '79905',
    '79906',
    '79907',
    '79908',
    '79911',
    '79912',
    '79915',
    '79916',
    '79920',
    '79922',
    '79924',
    '79925',
    '79927',
    '79928',
    '79930',
    '79932',
    '79934',
    '79935',
    '79936',
    '79938',
    '79942',
    '88063',
    '88220',
    '88430'
  ],
  '24': [
    '20601',
    '20602',
    '20603',
    '20606',
    '20607',
    '20608',
    '20609',
    '20611',
    '20612',
    '20613',
    '20615',
    '20616',
    '20617',
    '20618',
    '20619',
    '20620',
    '20621',
    '20622',
    '20623',
    '20624',
    '20625',
    '20626',
    '20628',
    '20629',
    '20630',
    '20632',
    '20634',
    '20636',
    '20637',
    '20639',
    '20640',
    '20645',
    '20646',
    '20650',
    '20653',
    '20657',
    '20658',
    '20659',
    '20660',
    '20662',
    '20664',
    '20667',
    '20670',
    '20674',
    '20675',
    '20676',
    '20677',
    '20678',
    '20680',
    '20684',
    '20685',
    '20686',
    '20687',
    '20688',
    '20689',
    '20690',
    '20692',
    '20693',
    '20695',
    '20701',
    '20705',
    '20706',
    '20707',
    '20708',
    '20710',
    '20711',
    '20712',
    '20714',
    '20715',
    '20716',
    '20720',
    '20721',
    '20722',
    '20723',
    '20724',
    '20732',
    '20733',
    '20735',
    '20736',
    '20737',
    '20740',
    '20742',
    '20743',
    '20744',
    '20745',
    '20746',
    '20747',
    '20748',
    '20751',
    '20754',
    '20755',
    '20758',
    '20759',
    '20762',
    '20763',
    '20764',
    '20765',
    '20769',
    '20770',
    '20772',
    '20774',
    '20776',
    '20777',
    '20778',
    '20779',
    '20781',
    '20782',
    '20783',
    '20784',
    '20785',
    '20794',
    '20812',
    '20814',
    '20815',
    '20816',
    '20817',
    '20818',
    '20832',
    '20833',
    '20837',
    '20838',
    '20839',
    '20841',
    '20842',
    '20850',
    '20851',
    '20852',
    '20853',
    '20854',
    '20855',
    '20860',
    '20861',
    '20862',
    '20866',
    '20868',
    '20871',
    '20872',
    '20874',
    '20876',
    '20877',
    '20878',
    '20879',
    '20880',
    '20882',
    '20886',
    '20895',
    '20896',
    '20899',
    '20901',
    '20902',
    '20903',
    '20904',
    '20905',
    '20906',
    '20910',
    '20912',
    '21001',
    '21005',
    '21009',
    '21010',
    '21012',
    '21013',
    '21014',
    '21015',
    '21017',
    '21028',
    '21029',
    '21030',
    '21031',
    '21032',
    '21034',
    '21035',
    '21036',
    '21037',
    '21040',
    '21042',
    '21043',
    '21044',
    '21045',
    '21046',
    '21047',
    '21048',
    '21050',
    '21051',
    '21052',
    '21053',
    '21054',
    '21056',
    '21057',
    '21060',
    '21061',
    '21071',
    '21074',
    '21075',
    '21076',
    '21077',
    '21078',
    '21082',
    '21084',
    '21085',
    '21087',
    '21090',
    '21093',
    '21102',
    '21104',
    '21105',
    '21108',
    '21111',
    '21113',
    '21114',
    '21117',
    '21120',
    '21122',
    '21128',
    '21130',
    '21131',
    '21132',
    '21133',
    '21136',
    '21140',
    '21144',
    '21146',
    '21152',
    '21153',
    '21154',
    '21155',
    '21156',
    '21157',
    '21158',
    '21160',
    '21161',
    '21162',
    '21163',
    '21201',
    '21202',
    '21204',
    '21205',
    '21206',
    '21207',
    '21208',
    '21209',
    '21210',
    '21211',
    '21212',
    '21213',
    '21214',
    '21215',
    '21216',
    '21217',
    '21218',
    '21219',
    '21220',
    '21221',
    '21222',
    '21223',
    '21224',
    '21225',
    '21226',
    '21227',
    '21228',
    '21229',
    '21230',
    '21231',
    '21234',
    '21236',
    '21237',
    '21239',
    '21240',
    '21244',
    '21250',
    '21251',
    '21252',
    '21286',
    '21401',
    '21402',
    '21403',
    '21405',
    '21409',
    '21502',
    '21520',
    '21521',
    '21522',
    '21523',
    '21524',
    '21529',
    '21530',
    '21531',
    '21532',
    '21536',
    '21538',
    '21539',
    '21540',
    '21541',
    '21542',
    '21543',
    '21545',
    '21550',
    '21555',
    '21557',
    '21561',
    '21562',
    '21601',
    '21607',
    '21610',
    '21612',
    '21613',
    '21617',
    '21619',
    '21620',
    '21622',
    '21623',
    '21624',
    '21625',
    '21626',
    '21627',
    '21628',
    '21629',
    '21631',
    '21632',
    '21634',
    '21635',
    '21636',
    '21638',
    '21639',
    '21640',
    '21641',
    '21643',
    '21644',
    '21645',
    '21647',
    '21648',
    '21649',
    '21650',
    '21651',
    '21652',
    '21653',
    '21654',
    '21655',
    '21657',
    '21658',
    '21659',
    '21660',
    '21661',
    '21662',
    '21663',
    '21664',
    '21665',
    '21666',
    '21667',
    '21668',
    '21669',
    '21671',
    '21672',
    '21673',
    '21675',
    '21676',
    '21677',
    '21678',
    '21679',
    '21701',
    '21702',
    '21703',
    '21704',
    '21705',
    '21710',
    '21711',
    '21713',
    '21714',
    '21716',
    '21717',
    '21718',
    '21719',
    '21722',
    '21723',
    '21727',
    '21733',
    '21734',
    '21737',
    '21738',
    '21740',
    '21742',
    '21746',
    '21750',
    '21754',
    '21755',
    '21756',
    '21757',
    '21758',
    '21762',
    '21766',
    '21767',
    '21769',
    '21770',
    '21771',
    '21773',
    '21774',
    '21776',
    '21777',
    '21778',
    '21779',
    '21780',
    '21781',
    '21782',
    '21783',
    '21784',
    '21787',
    '21788',
    '21790',
    '21791',
    '21793',
    '21794',
    '21795',
    '21797',
    '21798',
    '21801',
    '21802',
    '21804',
    '21810',
    '21811',
    '21813',
    '21814',
    '21817',
    '21821',
    '21822',
    '21824',
    '21826',
    '21829',
    '21830',
    '21835',
    '21837',
    '21838',
    '21840',
    '21841',
    '21842',
    '21849',
    '21850',
    '21851',
    '21853',
    '21856',
    '21861',
    '21862',
    '21863',
    '21864',
    '21865',
    '21866',
    '21867',
    '21869',
    '21871',
    '21872',
    '21874',
    '21875',
    '21890',
    '21901',
    '21902',
    '21903',
    '21904',
    '21911',
    '21912',
    '21913',
    '21914',
    '21915',
    '21916',
    '21917',
    '21918',
    '21919',
    '21920',
    '21921',
    '21930'
  ],
  '05': [
    '38769',
    '65729',
    '65733',
    '65761',
    '71601',
    '71602',
    '71603',
    '71630',
    '71631',
    '71635',
    '71638',
    '71639',
    '71640',
    '71642',
    '71643',
    '71644',
    '71646',
    '71647',
    '71651',
    '71652',
    '71653',
    '71654',
    '71655',
    '71658',
    '71659',
    '71660',
    '71661',
    '71662',
    '71663',
    '71665',
    '71666',
    '71667',
    '71670',
    '71671',
    '71674',
    '71675',
    '71676',
    '71677',
    '71701',
    '71711',
    '71720',
    '71722',
    '71724',
    '71725',
    '71726',
    '71730',
    '71740',
    '71742',
    '71743',
    '71744',
    '71745',
    '71747',
    '71749',
    '71751',
    '71752',
    '71753',
    '71758',
    '71759',
    '71762',
    '71763',
    '71764',
    '71765',
    '71766',
    '71770',
    '71772',
    '71801',
    '71820',
    '71822',
    '71823',
    '71825',
    '71826',
    '71827',
    '71832',
    '71833',
    '71834',
    '71835',
    '71836',
    '71837',
    '71838',
    '71839',
    '71841',
    '71842',
    '71845',
    '71846',
    '71847',
    '71851',
    '71852',
    '71853',
    '71854',
    '71855',
    '71857',
    '71858',
    '71859',
    '71860',
    '71861',
    '71862',
    '71865',
    '71866',
    '71901',
    '71909',
    '71913',
    '71921',
    '71922',
    '71923',
    '71929',
    '71933',
    '71935',
    '71937',
    '71940',
    '71941',
    '71943',
    '71944',
    '71945',
    '71949',
    '71950',
    '71952',
    '71953',
    '71956',
    '71957',
    '71958',
    '71959',
    '71960',
    '71961',
    '71962',
    '71964',
    '71965',
    '71968',
    '71969',
    '71970',
    '71971',
    '71972',
    '71973',
    '71998',
    '71999',
    '72001',
    '72002',
    '72003',
    '72004',
    '72005',
    '72006',
    '72007',
    '72010',
    '72011',
    '72012',
    '72013',
    '72014',
    '72015',
    '72016',
    '72017',
    '72019',
    '72020',
    '72021',
    '72022',
    '72023',
    '72024',
    '72025',
    '72026',
    '72027',
    '72029',
    '72030',
    '72031',
    '72032',
    '72034',
    '72035',
    '72036',
    '72037',
    '72038',
    '72039',
    '72040',
    '72041',
    '72042',
    '72044',
    '72045',
    '72046',
    '72047',
    '72048',
    '72051',
    '72055',
    '72057',
    '72058',
    '72059',
    '72060',
    '72061',
    '72063',
    '72064',
    '72065',
    '72067',
    '72068',
    '72069',
    '72070',
    '72072',
    '72073',
    '72074',
    '72076',
    '72079',
    '72080',
    '72081',
    '72082',
    '72083',
    '72084',
    '72085',
    '72086',
    '72087',
    '72088',
    '72099',
    '72101',
    '72102',
    '72103',
    '72104',
    '72106',
    '72107',
    '72108',
    '72110',
    '72111',
    '72112',
    '72113',
    '72114',
    '72116',
    '72117',
    '72118',
    '72119',
    '72120',
    '72121',
    '72122',
    '72123',
    '72125',
    '72126',
    '72127',
    '72128',
    '72129',
    '72130',
    '72131',
    '72132',
    '72134',
    '72135',
    '72136',
    '72137',
    '72139',
    '72140',
    '72141',
    '72142',
    '72143',
    '72149',
    '72150',
    '72152',
    '72153',
    '72156',
    '72157',
    '72160',
    '72165',
    '72166',
    '72167',
    '72168',
    '72169',
    '72170',
    '72173',
    '72175',
    '72176',
    '72179',
    '72181',
    '72201',
    '72202',
    '72204',
    '72205',
    '72206',
    '72207',
    '72209',
    '72210',
    '72211',
    '72212',
    '72223',
    '72227',
    '72301',
    '72311',
    '72315',
    '72320',
    '72321',
    '72322',
    '72324',
    '72325',
    '72326',
    '72327',
    '72328',
    '72329',
    '72330',
    '72331',
    '72332',
    '72333',
    '72335',
    '72338',
    '72339',
    '72340',
    '72341',
    '72342',
    '72346',
    '72347',
    '72348',
    '72350',
    '72351',
    '72353',
    '72354',
    '72355',
    '72358',
    '72359',
    '72360',
    '72364',
    '72365',
    '72366',
    '72367',
    '72368',
    '72370',
    '72372',
    '72373',
    '72374',
    '72376',
    '72377',
    '72379',
    '72383',
    '72384',
    '72386',
    '72389',
    '72390',
    '72392',
    '72394',
    '72395',
    '72396',
    '72401',
    '72404',
    '72410',
    '72411',
    '72412',
    '72413',
    '72414',
    '72415',
    '72416',
    '72417',
    '72419',
    '72421',
    '72422',
    '72424',
    '72425',
    '72426',
    '72427',
    '72428',
    '72429',
    '72430',
    '72431',
    '72432',
    '72433',
    '72434',
    '72435',
    '72436',
    '72437',
    '72438',
    '72440',
    '72441',
    '72442',
    '72443',
    '72444',
    '72445',
    '72447',
    '72449',
    '72450',
    '72453',
    '72454',
    '72455',
    '72456',
    '72457',
    '72458',
    '72459',
    '72460',
    '72461',
    '72462',
    '72464',
    '72466',
    '72467',
    '72469',
    '72470',
    '72471',
    '72472',
    '72473',
    '72476',
    '72478',
    '72479',
    '72482',
    '72501',
    '72512',
    '72513',
    '72515',
    '72517',
    '72519',
    '72520',
    '72521',
    '72522',
    '72523',
    '72524',
    '72526',
    '72527',
    '72528',
    '72529',
    '72530',
    '72531',
    '72532',
    '72533',
    '72534',
    '72536',
    '72537',
    '72538',
    '72539',
    '72540',
    '72542',
    '72543',
    '72544',
    '72546',
    '72550',
    '72553',
    '72554',
    '72555',
    '72556',
    '72560',
    '72561',
    '72562',
    '72564',
    '72565',
    '72566',
    '72567',
    '72568',
    '72569',
    '72571',
    '72572',
    '72573',
    '72576',
    '72577',
    '72578',
    '72579',
    '72581',
    '72583',
    '72584',
    '72585',
    '72587',
    '72601',
    '72611',
    '72616',
    '72617',
    '72619',
    '72623',
    '72624',
    '72626',
    '72628',
    '72629',
    '72630',
    '72631',
    '72632',
    '72633',
    '72634',
    '72635',
    '72636',
    '72638',
    '72639',
    '72640',
    '72641',
    '72642',
    '72644',
    '72645',
    '72648',
    '72650',
    '72651',
    '72653',
    '72655',
    '72658',
    '72660',
    '72661',
    '72662',
    '72663',
    '72666',
    '72668',
    '72669',
    '72670',
    '72672',
    '72675',
    '72677',
    '72679',
    '72680',
    '72682',
    '72683',
    '72685',
    '72686',
    '72687',
    '72701',
    '72703',
    '72704',
    '72712',
    '72714',
    '72715',
    '72717',
    '72718',
    '72719',
    '72721',
    '72722',
    '72727',
    '72729',
    '72730',
    '72732',
    '72734',
    '72736',
    '72738',
    '72739',
    '72740',
    '72742',
    '72744',
    '72745',
    '72747',
    '72749',
    '72751',
    '72752',
    '72753',
    '72756',
    '72758',
    '72760',
    '72761',
    '72762',
    '72764',
    '72768',
    '72769',
    '72773',
    '72774',
    '72776',
    '72801',
    '72802',
    '72821',
    '72823',
    '72824',
    '72826',
    '72827',
    '72828',
    '72830',
    '72832',
    '72833',
    '72834',
    '72835',
    '72837',
    '72838',
    '72839',
    '72840',
    '72841',
    '72842',
    '72843',
    '72845',
    '72846',
    '72847',
    '72851',
    '72852',
    '72853',
    '72854',
    '72855',
    '72856',
    '72857',
    '72858',
    '72860',
    '72863',
    '72865',
    '72901',
    '72903',
    '72904',
    '72908',
    '72916',
    '72921',
    '72923',
    '72926',
    '72927',
    '72928',
    '72930',
    '72932',
    '72933',
    '72934',
    '72935',
    '72936',
    '72937',
    '72938',
    '72940',
    '72941',
    '72943',
    '72944',
    '72945',
    '72946',
    '72947',
    '72948',
    '72949',
    '72950',
    '72951',
    '72952',
    '72955',
    '72956',
    '72958',
    '72959',
    '75556'
  ],
  '47': [
    '37010',
    '37012',
    '37013',
    '37014',
    '37015',
    '37016',
    '37018',
    '37019',
    '37020',
    '37022',
    '37023',
    '37025',
    '37026',
    '37027',
    '37028',
    '37029',
    '37030',
    '37031',
    '37032',
    '37033',
    '37034',
    '37035',
    '37036',
    '37037',
    '37040',
    '37042',
    '37043',
    '37046',
    '37047',
    '37048',
    '37049',
    '37050',
    '37051',
    '37052',
    '37055',
    '37057',
    '37058',
    '37059',
    '37060',
    '37061',
    '37062',
    '37064',
    '37066',
    '37067',
    '37069',
    '37072',
    '37073',
    '37074',
    '37075',
    '37076',
    '37078',
    '37079',
    '37080',
    '37082',
    '37083',
    '37085',
    '37086',
    '37087',
    '37090',
    '37091',
    '37095',
    '37096',
    '37097',
    '37098',
    '37101',
    '37110',
    '37115',
    '37118',
    '37122',
    '37127',
    '37128',
    '37129',
    '37130',
    '37132',
    '37134',
    '37135',
    '37137',
    '37138',
    '37140',
    '37141',
    '37142',
    '37143',
    '37144',
    '37145',
    '37146',
    '37148',
    '37149',
    '37150',
    '37151',
    '37153',
    '37160',
    '37165',
    '37166',
    '37167',
    '37171',
    '37172',
    '37174',
    '37175',
    '37178',
    '37179',
    '37180',
    '37181',
    '37183',
    '37184',
    '37185',
    '37186',
    '37187',
    '37188',
    '37189',
    '37190',
    '37191',
    '37201',
    '37203',
    '37204',
    '37205',
    '37206',
    '37207',
    '37208',
    '37209',
    '37210',
    '37211',
    '37212',
    '37213',
    '37214',
    '37215',
    '37216',
    '37217',
    '37218',
    '37219',
    '37220',
    '37221',
    '37228',
    '37240',
    '37243',
    '37246',
    '37301',
    '37302',
    '37303',
    '37305',
    '37306',
    '37307',
    '37308',
    '37309',
    '37310',
    '37311',
    '37312',
    '37313',
    '37315',
    '37317',
    '37318',
    '37321',
    '37322',
    '37323',
    '37324',
    '37325',
    '37326',
    '37327',
    '37328',
    '37329',
    '37330',
    '37331',
    '37332',
    '37333',
    '37334',
    '37335',
    '37336',
    '37337',
    '37338',
    '37339',
    '37340',
    '37341',
    '37342',
    '37343',
    '37345',
    '37347',
    '37348',
    '37350',
    '37351',
    '37352',
    '37353',
    '37354',
    '37355',
    '37356',
    '37357',
    '37359',
    '37360',
    '37361',
    '37362',
    '37363',
    '37365',
    '37366',
    '37367',
    '37369',
    '37370',
    '37373',
    '37374',
    '37375',
    '37376',
    '37377',
    '37379',
    '37380',
    '37381',
    '37385',
    '37387',
    '37388',
    '37391',
    '37394',
    '37396',
    '37397',
    '37398',
    '37402',
    '37403',
    '37404',
    '37405',
    '37406',
    '37407',
    '37408',
    '37409',
    '37410',
    '37411',
    '37412',
    '37415',
    '37416',
    '37419',
    '37421',
    '37601',
    '37604',
    '37614',
    '37615',
    '37616',
    '37617',
    '37618',
    '37620',
    '37640',
    '37641',
    '37642',
    '37643',
    '37645',
    '37650',
    '37656',
    '37657',
    '37658',
    '37659',
    '37660',
    '37663',
    '37664',
    '37665',
    '37680',
    '37681',
    '37682',
    '37683',
    '37686',
    '37687',
    '37688',
    '37690',
    '37691',
    '37692',
    '37694',
    '37701',
    '37705',
    '37708',
    '37709',
    '37710',
    '37711',
    '37713',
    '37714',
    '37715',
    '37716',
    '37719',
    '37721',
    '37722',
    '37723',
    '37724',
    '37725',
    '37726',
    '37727',
    '37729',
    '37730',
    '37731',
    '37732',
    '37733',
    '37737',
    '37738',
    '37742',
    '37743',
    '37745',
    '37748',
    '37752',
    '37753',
    '37754',
    '37755',
    '37756',
    '37757',
    '37760',
    '37762',
    '37763',
    '37764',
    '37765',
    '37766',
    '37769',
    '37770',
    '37771',
    '37772',
    '37774',
    '37777',
    '37779',
    '37801',
    '37803',
    '37804',
    '37806',
    '37807',
    '37809',
    '37810',
    '37811',
    '37813',
    '37814',
    '37818',
    '37819',
    '37820',
    '37821',
    '37825',
    '37826',
    '37828',
    '37829',
    '37830',
    '37840',
    '37841',
    '37843',
    '37845',
    '37846',
    '37847',
    '37848',
    '37849',
    '37851',
    '37852',
    '37853',
    '37854',
    '37857',
    '37860',
    '37861',
    '37862',
    '37863',
    '37865',
    '37866',
    '37869',
    '37870',
    '37871',
    '37872',
    '37873',
    '37874',
    '37876',
    '37877',
    '37878',
    '37879',
    '37880',
    '37881',
    '37882',
    '37885',
    '37886',
    '37887',
    '37888',
    '37890',
    '37891',
    '37892',
    '37902',
    '37909',
    '37912',
    '37914',
    '37915',
    '37916',
    '37917',
    '37918',
    '37919',
    '37920',
    '37921',
    '37922',
    '37923',
    '37924',
    '37931',
    '37932',
    '37934',
    '37938',
    '38001',
    '38002',
    '38004',
    '38006',
    '38007',
    '38008',
    '38011',
    '38012',
    '38015',
    '38016',
    '38017',
    '38018',
    '38019',
    '38021',
    '38023',
    '38024',
    '38028',
    '38029',
    '38030',
    '38034',
    '38036',
    '38037',
    '38039',
    '38040',
    '38041',
    '38042',
    '38044',
    '38046',
    '38047',
    '38049',
    '38050',
    '38052',
    '38053',
    '38054',
    '38057',
    '38058',
    '38059',
    '38060',
    '38061',
    '38063',
    '38066',
    '38067',
    '38068',
    '38069',
    '38070',
    '38075',
    '38076',
    '38077',
    '38079',
    '38080',
    '38103',
    '38104',
    '38105',
    '38106',
    '38107',
    '38108',
    '38109',
    '38111',
    '38112',
    '38114',
    '38115',
    '38116',
    '38117',
    '38118',
    '38119',
    '38120',
    '38122',
    '38125',
    '38126',
    '38127',
    '38128',
    '38131',
    '38132',
    '38133',
    '38134',
    '38135',
    '38138',
    '38139',
    '38141',
    '38152',
    '38201',
    '38220',
    '38221',
    '38222',
    '38224',
    '38225',
    '38226',
    '38229',
    '38230',
    '38231',
    '38232',
    '38233',
    '38235',
    '38236',
    '38237',
    '38240',
    '38241',
    '38242',
    '38251',
    '38253',
    '38254',
    '38255',
    '38256',
    '38257',
    '38258',
    '38259',
    '38260',
    '38261',
    '38301',
    '38305',
    '38310',
    '38311',
    '38313',
    '38315',
    '38316',
    '38317',
    '38318',
    '38320',
    '38321',
    '38326',
    '38327',
    '38328',
    '38329',
    '38330',
    '38332',
    '38333',
    '38334',
    '38337',
    '38339',
    '38340',
    '38341',
    '38342',
    '38343',
    '38344',
    '38345',
    '38347',
    '38348',
    '38351',
    '38352',
    '38355',
    '38356',
    '38357',
    '38358',
    '38359',
    '38361',
    '38362',
    '38363',
    '38365',
    '38366',
    '38367',
    '38368',
    '38369',
    '38370',
    '38371',
    '38372',
    '38374',
    '38375',
    '38376',
    '38379',
    '38380',
    '38381',
    '38382',
    '38387',
    '38388',
    '38390',
    '38391',
    '38392',
    '38401',
    '38425',
    '38449',
    '38450',
    '38451',
    '38452',
    '38453',
    '38454',
    '38455',
    '38456',
    '38457',
    '38459',
    '38460',
    '38461',
    '38462',
    '38463',
    '38464',
    '38468',
    '38469',
    '38471',
    '38472',
    '38473',
    '38474',
    '38475',
    '38476',
    '38477',
    '38478',
    '38481',
    '38482',
    '38483',
    '38485',
    '38486',
    '38487',
    '38488',
    '38501',
    '38504',
    '38505',
    '38506',
    '38541',
    '38542',
    '38543',
    '38544',
    '38545',
    '38547',
    '38548',
    '38549',
    '38551',
    '38552',
    '38553',
    '38554',
    '38555',
    '38556',
    '38558',
    '38559',
    '38560',
    '38562',
    '38563',
    '38564',
    '38565',
    '38567',
    '38568',
    '38569',
    '38570',
    '38571',
    '38572',
    '38573',
    '38574',
    '38575',
    '38577',
    '38578',
    '38579',
    '38580',
    '38581',
    '38582',
    '38583',
    '38585',
    '38587',
    '38588',
    '38589',
    '42223',
    '42602',
    '72338'
  ],
  '45': [
    '29001',
    '29003',
    '29006',
    '29009',
    '29010',
    '29014',
    '29015',
    '29016',
    '29018',
    '29020',
    '29030',
    '29031',
    '29032',
    '29033',
    '29036',
    '29037',
    '29038',
    '29039',
    '29040',
    '29042',
    '29044',
    '29045',
    '29046',
    '29047',
    '29048',
    '29051',
    '29052',
    '29053',
    '29054',
    '29055',
    '29056',
    '29058',
    '29059',
    '29061',
    '29062',
    '29063',
    '29065',
    '29067',
    '29069',
    '29070',
    '29072',
    '29073',
    '29074',
    '29075',
    '29078',
    '29079',
    '29080',
    '29081',
    '29082',
    '29101',
    '29102',
    '29104',
    '29105',
    '29107',
    '29108',
    '29111',
    '29112',
    '29113',
    '29114',
    '29115',
    '29117',
    '29118',
    '29122',
    '29123',
    '29125',
    '29126',
    '29127',
    '29128',
    '29129',
    '29130',
    '29133',
    '29135',
    '29137',
    '29138',
    '29142',
    '29145',
    '29146',
    '29147',
    '29148',
    '29150',
    '29152',
    '29153',
    '29154',
    '29160',
    '29161',
    '29162',
    '29163',
    '29164',
    '29166',
    '29168',
    '29169',
    '29170',
    '29172',
    '29175',
    '29178',
    '29180',
    '29201',
    '29202',
    '29203',
    '29204',
    '29205',
    '29206',
    '29207',
    '29208',
    '29209',
    '29210',
    '29212',
    '29223',
    '29225',
    '29229',
    '29301',
    '29302',
    '29303',
    '29306',
    '29307',
    '29316',
    '29320',
    '29321',
    '29322',
    '29323',
    '29324',
    '29325',
    '29329',
    '29330',
    '29331',
    '29332',
    '29333',
    '29334',
    '29335',
    '29338',
    '29340',
    '29341',
    '29346',
    '29349',
    '29351',
    '29353',
    '29355',
    '29356',
    '29360',
    '29364',
    '29365',
    '29368',
    '29369',
    '29370',
    '29372',
    '29373',
    '29374',
    '29375',
    '29376',
    '29377',
    '29378',
    '29379',
    '29384',
    '29385',
    '29388',
    '29401',
    '29403',
    '29404',
    '29405',
    '29406',
    '29407',
    '29409',
    '29410',
    '29412',
    '29414',
    '29418',
    '29420',
    '29423',
    '29424',
    '29426',
    '29429',
    '29431',
    '29432',
    '29434',
    '29435',
    '29436',
    '29437',
    '29438',
    '29439',
    '29440',
    '29445',
    '29446',
    '29448',
    '29449',
    '29450',
    '29451',
    '29452',
    '29453',
    '29455',
    '29456',
    '29458',
    '29461',
    '29464',
    '29466',
    '29468',
    '29469',
    '29470',
    '29471',
    '29472',
    '29474',
    '29475',
    '29477',
    '29479',
    '29481',
    '29482',
    '29483',
    '29485',
    '29487',
    '29488',
    '29492',
    '29493',
    '29501',
    '29505',
    '29506',
    '29510',
    '29511',
    '29512',
    '29516',
    '29518',
    '29519',
    '29520',
    '29525',
    '29526',
    '29527',
    '29530',
    '29532',
    '29536',
    '29540',
    '29541',
    '29543',
    '29544',
    '29545',
    '29546',
    '29547',
    '29550',
    '29554',
    '29555',
    '29556',
    '29560',
    '29563',
    '29564',
    '29565',
    '29566',
    '29567',
    '29568',
    '29569',
    '29570',
    '29571',
    '29572',
    '29574',
    '29575',
    '29576',
    '29577',
    '29579',
    '29580',
    '29581',
    '29582',
    '29583',
    '29584',
    '29585',
    '29588',
    '29590',
    '29591',
    '29592',
    '29593',
    '29594',
    '29596',
    '29601',
    '29605',
    '29607',
    '29609',
    '29611',
    '29613',
    '29614',
    '29615',
    '29617',
    '29620',
    '29621',
    '29624',
    '29625',
    '29626',
    '29627',
    '29628',
    '29630',
    '29631',
    '29634',
    '29635',
    '29638',
    '29639',
    '29640',
    '29642',
    '29643',
    '29644',
    '29645',
    '29646',
    '29649',
    '29650',
    '29651',
    '29653',
    '29654',
    '29655',
    '29657',
    '29658',
    '29659',
    '29661',
    '29662',
    '29664',
    '29665',
    '29666',
    '29667',
    '29669',
    '29670',
    '29671',
    '29672',
    '29673',
    '29676',
    '29678',
    '29680',
    '29681',
    '29682',
    '29683',
    '29684',
    '29685',
    '29686',
    '29687',
    '29689',
    '29690',
    '29691',
    '29692',
    '29693',
    '29696',
    '29697',
    '29702',
    '29704',
    '29706',
    '29707',
    '29708',
    '29709',
    '29710',
    '29712',
    '29714',
    '29715',
    '29717',
    '29718',
    '29720',
    '29724',
    '29726',
    '29727',
    '29728',
    '29729',
    '29730',
    '29732',
    '29733',
    '29741',
    '29742',
    '29743',
    '29745',
    '29801',
    '29803',
    '29805',
    '29809',
    '29810',
    '29812',
    '29816',
    '29817',
    '29819',
    '29821',
    '29824',
    '29826',
    '29827',
    '29828',
    '29829',
    '29831',
    '29832',
    '29834',
    '29835',
    '29836',
    '29838',
    '29840',
    '29841',
    '29842',
    '29843',
    '29844',
    '29845',
    '29847',
    '29848',
    '29849',
    '29850',
    '29851',
    '29853',
    '29856',
    '29860',
    '29899',
    '29902',
    '29904',
    '29905',
    '29906',
    '29907',
    '29909',
    '29910',
    '29911',
    '29912',
    '29915',
    '29916',
    '29918',
    '29920',
    '29921',
    '29922',
    '29923',
    '29924',
    '29926',
    '29927',
    '29928',
    '29929',
    '29932',
    '29934',
    '29935',
    '29936',
    '29939',
    '29940',
    '29941',
    '29943',
    '29944',
    '29945'
  ],
  '41': [
    '89421',
    '97001',
    '97002',
    '97004',
    '97005',
    '97006',
    '97007',
    '97008',
    '97009',
    '97011',
    '97013',
    '97014',
    '97015',
    '97016',
    '97017',
    '97018',
    '97019',
    '97020',
    '97021',
    '97022',
    '97023',
    '97024',
    '97026',
    '97027',
    '97028',
    '97029',
    '97030',
    '97031',
    '97032',
    '97033',
    '97034',
    '97035',
    '97037',
    '97038',
    '97039',
    '97040',
    '97041',
    '97042',
    '97045',
    '97048',
    '97049',
    '97050',
    '97051',
    '97053',
    '97054',
    '97055',
    '97056',
    '97057',
    '97058',
    '97060',
    '97062',
    '97063',
    '97064',
    '97065',
    '97067',
    '97068',
    '97070',
    '97071',
    '97080',
    '97086',
    '97089',
    '97101',
    '97102',
    '97103',
    '97106',
    '97107',
    '97108',
    '97109',
    '97110',
    '97111',
    '97112',
    '97113',
    '97114',
    '97115',
    '97116',
    '97117',
    '97118',
    '97119',
    '97121',
    '97122',
    '97123',
    '97124',
    '97125',
    '97127',
    '97128',
    '97130',
    '97131',
    '97132',
    '97133',
    '97134',
    '97135',
    '97136',
    '97137',
    '97138',
    '97140',
    '97141',
    '97144',
    '97145',
    '97146',
    '97147',
    '97148',
    '97149',
    '97201',
    '97202',
    '97203',
    '97204',
    '97205',
    '97206',
    '97208',
    '97209',
    '97210',
    '97211',
    '97212',
    '97213',
    '97214',
    '97215',
    '97216',
    '97217',
    '97218',
    '97219',
    '97220',
    '97221',
    '97222',
    '97223',
    '97224',
    '97225',
    '97227',
    '97229',
    '97230',
    '97231',
    '97232',
    '97233',
    '97236',
    '97239',
    '97266',
    '97267',
    '97301',
    '97302',
    '97303',
    '97304',
    '97305',
    '97306',
    '97317',
    '97321',
    '97322',
    '97324',
    '97325',
    '97326',
    '97327',
    '97329',
    '97330',
    '97331',
    '97333',
    '97338',
    '97341',
    '97342',
    '97343',
    '97344',
    '97345',
    '97346',
    '97347',
    '97348',
    '97350',
    '97351',
    '97352',
    '97355',
    '97357',
    '97358',
    '97360',
    '97361',
    '97362',
    '97364',
    '97365',
    '97366',
    '97367',
    '97368',
    '97369',
    '97370',
    '97371',
    '97373',
    '97374',
    '97375',
    '97376',
    '97377',
    '97378',
    '97380',
    '97381',
    '97383',
    '97384',
    '97385',
    '97386',
    '97388',
    '97389',
    '97390',
    '97391',
    '97392',
    '97394',
    '97396',
    '97401',
    '97402',
    '97403',
    '97404',
    '97405',
    '97406',
    '97408',
    '97410',
    '97411',
    '97412',
    '97413',
    '97414',
    '97415',
    '97416',
    '97417',
    '97419',
    '97420',
    '97423',
    '97424',
    '97426',
    '97429',
    '97430',
    '97431',
    '97434',
    '97435',
    '97436',
    '97437',
    '97438',
    '97439',
    '97441',
    '97442',
    '97443',
    '97444',
    '97446',
    '97447',
    '97448',
    '97449',
    '97450',
    '97451',
    '97452',
    '97453',
    '97454',
    '97455',
    '97456',
    '97457',
    '97458',
    '97459',
    '97461',
    '97462',
    '97463',
    '97465',
    '97466',
    '97467',
    '97469',
    '97470',
    '97471',
    '97473',
    '97476',
    '97477',
    '97478',
    '97479',
    '97480',
    '97481',
    '97484',
    '97486',
    '97487',
    '97488',
    '97489',
    '97490',
    '97492',
    '97493',
    '97494',
    '97495',
    '97496',
    '97497',
    '97498',
    '97499',
    '97501',
    '97502',
    '97503',
    '97504',
    '97520',
    '97522',
    '97523',
    '97524',
    '97525',
    '97526',
    '97527',
    '97530',
    '97531',
    '97532',
    '97534',
    '97535',
    '97536',
    '97537',
    '97538',
    '97539',
    '97540',
    '97541',
    '97543',
    '97544',
    '97601',
    '97603',
    '97604',
    '97620',
    '97621',
    '97622',
    '97623',
    '97624',
    '97625',
    '97626',
    '97627',
    '97630',
    '97632',
    '97633',
    '97634',
    '97635',
    '97636',
    '97637',
    '97638',
    '97639',
    '97640',
    '97641',
    '97701',
    '97702',
    '97703',
    '97707',
    '97710',
    '97711',
    '97712',
    '97720',
    '97721',
    '97722',
    '97730',
    '97731',
    '97732',
    '97733',
    '97734',
    '97735',
    '97736',
    '97737',
    '97738',
    '97739',
    '97741',
    '97750',
    '97751',
    '97752',
    '97753',
    '97754',
    '97756',
    '97758',
    '97759',
    '97760',
    '97761',
    '97801',
    '97810',
    '97812',
    '97813',
    '97814',
    '97817',
    '97818',
    '97819',
    '97820',
    '97823',
    '97824',
    '97825',
    '97826',
    '97827',
    '97828',
    '97830',
    '97833',
    '97834',
    '97835',
    '97836',
    '97837',
    '97838',
    '97839',
    '97840',
    '97841',
    '97842',
    '97843',
    '97844',
    '97845',
    '97846',
    '97848',
    '97850',
    '97856',
    '97857',
    '97859',
    '97862',
    '97864',
    '97865',
    '97867',
    '97868',
    '97869',
    '97870',
    '97873',
    '97874',
    '97875',
    '97876',
    '97877',
    '97880',
    '97882',
    '97883',
    '97884',
    '97885',
    '97886',
    '97901',
    '97903',
    '97904',
    '97905',
    '97906',
    '97907',
    '97908',
    '97909',
    '97910',
    '97911',
    '97913',
    '97914',
    '97918',
    '97920',
    '99362'
  ],
  '40': [
    '67950',
    '73002',
    '73003',
    '73004',
    '73005',
    '73006',
    '73007',
    '73008',
    '73009',
    '73010',
    '73011',
    '73012',
    '73013',
    '73014',
    '73015',
    '73016',
    '73017',
    '73018',
    '73019',
    '73020',
    '73021',
    '73024',
    '73025',
    '73026',
    '73027',
    '73028',
    '73029',
    '73030',
    '73032',
    '73033',
    '73034',
    '73036',
    '73038',
    '73040',
    '73041',
    '73042',
    '73043',
    '73044',
    '73045',
    '73047',
    '73048',
    '73049',
    '73050',
    '73051',
    '73052',
    '73053',
    '73054',
    '73055',
    '73056',
    '73057',
    '73058',
    '73059',
    '73061',
    '73062',
    '73063',
    '73064',
    '73065',
    '73066',
    '73067',
    '73068',
    '73069',
    '73071',
    '73072',
    '73073',
    '73074',
    '73075',
    '73077',
    '73078',
    '73079',
    '73080',
    '73082',
    '73084',
    '73086',
    '73089',
    '73090',
    '73092',
    '73093',
    '73095',
    '73096',
    '73097',
    '73098',
    '73099',
    '73102',
    '73103',
    '73104',
    '73105',
    '73106',
    '73107',
    '73108',
    '73109',
    '73110',
    '73111',
    '73112',
    '73114',
    '73115',
    '73116',
    '73117',
    '73118',
    '73119',
    '73120',
    '73121',
    '73122',
    '73127',
    '73128',
    '73129',
    '73130',
    '73131',
    '73132',
    '73134',
    '73135',
    '73139',
    '73141',
    '73142',
    '73145',
    '73149',
    '73150',
    '73151',
    '73159',
    '73160',
    '73162',
    '73165',
    '73169',
    '73170',
    '73173',
    '73179',
    '73401',
    '73425',
    '73430',
    '73432',
    '73433',
    '73434',
    '73437',
    '73438',
    '73439',
    '73440',
    '73441',
    '73442',
    '73443',
    '73444',
    '73446',
    '73447',
    '73448',
    '73449',
    '73450',
    '73453',
    '73455',
    '73456',
    '73458',
    '73459',
    '73460',
    '73461',
    '73463',
    '73481',
    '73487',
    '73491',
    '73501',
    '73503',
    '73505',
    '73507',
    '73520',
    '73521',
    '73526',
    '73527',
    '73528',
    '73529',
    '73530',
    '73531',
    '73532',
    '73533',
    '73537',
    '73538',
    '73539',
    '73540',
    '73541',
    '73542',
    '73543',
    '73544',
    '73546',
    '73547',
    '73548',
    '73549',
    '73550',
    '73551',
    '73552',
    '73553',
    '73554',
    '73555',
    '73556',
    '73557',
    '73559',
    '73560',
    '73562',
    '73564',
    '73565',
    '73566',
    '73567',
    '73568',
    '73569',
    '73570',
    '73571',
    '73572',
    '73573',
    '73601',
    '73620',
    '73622',
    '73624',
    '73625',
    '73626',
    '73627',
    '73628',
    '73632',
    '73638',
    '73639',
    '73641',
    '73642',
    '73644',
    '73645',
    '73646',
    '73647',
    '73650',
    '73651',
    '73654',
    '73655',
    '73658',
    '73659',
    '73660',
    '73661',
    '73662',
    '73663',
    '73664',
    '73666',
    '73667',
    '73669',
    '73673',
    '73701',
    '73703',
    '73705',
    '73716',
    '73717',
    '73718',
    '73719',
    '73720',
    '73722',
    '73724',
    '73726',
    '73727',
    '73728',
    '73729',
    '73730',
    '73731',
    '73733',
    '73734',
    '73735',
    '73736',
    '73737',
    '73738',
    '73739',
    '73741',
    '73742',
    '73743',
    '73744',
    '73746',
    '73747',
    '73749',
    '73750',
    '73753',
    '73754',
    '73755',
    '73756',
    '73757',
    '73758',
    '73759',
    '73760',
    '73761',
    '73762',
    '73763',
    '73764',
    '73766',
    '73768',
    '73771',
    '73772',
    '73773',
    '73801',
    '73832',
    '73834',
    '73835',
    '73838',
    '73840',
    '73841',
    '73842',
    '73843',
    '73844',
    '73848',
    '73851',
    '73852',
    '73853',
    '73855',
    '73857',
    '73858',
    '73859',
    '73860',
    '73901',
    '73931',
    '73932',
    '73933',
    '73937',
    '73938',
    '73939',
    '73942',
    '73944',
    '73945',
    '73946',
    '73947',
    '73949',
    '73950',
    '73951',
    '74001',
    '74002',
    '74003',
    '74006',
    '74008',
    '74010',
    '74011',
    '74012',
    '74014',
    '74015',
    '74016',
    '74017',
    '74019',
    '74020',
    '74021',
    '74022',
    '74023',
    '74026',
    '74027',
    '74028',
    '74029',
    '74030',
    '74032',
    '74033',
    '74034',
    '74035',
    '74036',
    '74037',
    '74038',
    '74039',
    '74041',
    '74042',
    '74044',
    '74045',
    '74046',
    '74047',
    '74048',
    '74050',
    '74051',
    '74052',
    '74053',
    '74054',
    '74055',
    '74056',
    '74058',
    '74059',
    '74060',
    '74061',
    '74062',
    '74063',
    '74066',
    '74068',
    '74070',
    '74071',
    '74072',
    '74073',
    '74074',
    '74075',
    '74078',
    '74079',
    '74080',
    '74081',
    '74082',
    '74083',
    '74084',
    '74085',
    '74103',
    '74104',
    '74105',
    '74106',
    '74107',
    '74108',
    '74110',
    '74112',
    '74114',
    '74115',
    '74116',
    '74117',
    '74119',
    '74120',
    '74126',
    '74127',
    '74128',
    '74129',
    '74130',
    '74131',
    '74132',
    '74133',
    '74134',
    '74135',
    '74136',
    '74137',
    '74145',
    '74146',
    '74301',
    '74330',
    '74331',
    '74332',
    '74333',
    '74337',
    '74338',
    '74339',
    '74340',
    '74342',
    '74343',
    '74344',
    '74346',
    '74347',
    '74349',
    '74350',
    '74352',
    '74354',
    '74358',
    '74359',
    '74360',
    '74361',
    '74363',
    '74364',
    '74365',
    '74366',
    '74367',
    '74368',
    '74369',
    '74370',
    '74401',
    '74403',
    '74421',
    '74422',
    '74423',
    '74425',
    '74426',
    '74427',
    '74428',
    '74429',
    '74430',
    '74431',
    '74432',
    '74434',
    '74435',
    '74436',
    '74437',
    '74438',
    '74441',
    '74442',
    '74445',
    '74446',
    '74447',
    '74450',
    '74451',
    '74452',
    '74454',
    '74455',
    '74456',
    '74457',
    '74458',
    '74459',
    '74460',
    '74462',
    '74463',
    '74464',
    '74467',
    '74468',
    '74469',
    '74470',
    '74471',
    '74472',
    '74477',
    '74501',
    '74521',
    '74522',
    '74523',
    '74525',
    '74528',
    '74530',
    '74531',
    '74533',
    '74534',
    '74535',
    '74536',
    '74538',
    '74540',
    '74543',
    '74546',
    '74547',
    '74549',
    '74552',
    '74553',
    '74554',
    '74555',
    '74556',
    '74557',
    '74558',
    '74560',
    '74561',
    '74562',
    '74563',
    '74565',
    '74567',
    '74569',
    '74570',
    '74571',
    '74572',
    '74574',
    '74576',
    '74577',
    '74578',
    '74601',
    '74604',
    '74630',
    '74631',
    '74632',
    '74633',
    '74636',
    '74637',
    '74640',
    '74641',
    '74643',
    '74644',
    '74646',
    '74647',
    '74650',
    '74651',
    '74652',
    '74653',
    '74701',
    '74720',
    '74722',
    '74723',
    '74724',
    '74726',
    '74727',
    '74728',
    '74729',
    '74730',
    '74731',
    '74733',
    '74734',
    '74735',
    '74736',
    '74738',
    '74740',
    '74741',
    '74743',
    '74745',
    '74747',
    '74748',
    '74750',
    '74753',
    '74754',
    '74755',
    '74756',
    '74759',
    '74760',
    '74761',
    '74764',
    '74766',
    '74801',
    '74804',
    '74820',
    '74824',
    '74825',
    '74826',
    '74827',
    '74829',
    '74830',
    '74831',
    '74832',
    '74833',
    '74834',
    '74836',
    '74837',
    '74839',
    '74840',
    '74842',
    '74843',
    '74844',
    '74845',
    '74848',
    '74849',
    '74850',
    '74851',
    '74852',
    '74854',
    '74855',
    '74856',
    '74857',
    '74859',
    '74860',
    '74864',
    '74865',
    '74867',
    '74868',
    '74869',
    '74871',
    '74872',
    '74873',
    '74875',
    '74878',
    '74880',
    '74881',
    '74883',
    '74884',
    '74901',
    '74902',
    '74930',
    '74931',
    '74932',
    '74935',
    '74936',
    '74937',
    '74939',
    '74940',
    '74941',
    '74942',
    '74943',
    '74944',
    '74945',
    '74946',
    '74948',
    '74949',
    '74951',
    '74953',
    '74954',
    '74955',
    '74956',
    '74957',
    '74959',
    '74960',
    '74962',
    '74963',
    '74964',
    '74965',
    '74966'
  ],
  '18': [
    '45053',
    '46001',
    '46011',
    '46012',
    '46013',
    '46016',
    '46017',
    '46030',
    '46031',
    '46032',
    '46033',
    '46034',
    '46035',
    '46036',
    '46037',
    '46038',
    '46039',
    '46040',
    '46041',
    '46044',
    '46045',
    '46047',
    '46048',
    '46049',
    '46050',
    '46051',
    '46052',
    '46055',
    '46056',
    '46057',
    '46058',
    '46060',
    '46062',
    '46063',
    '46064',
    '46065',
    '46068',
    '46069',
    '46070',
    '46071',
    '46072',
    '46074',
    '46075',
    '46076',
    '46077',
    '46103',
    '46104',
    '46105',
    '46106',
    '46107',
    '46110',
    '46111',
    '46112',
    '46113',
    '46115',
    '46117',
    '46118',
    '46120',
    '46121',
    '46122',
    '46123',
    '46124',
    '46125',
    '46126',
    '46127',
    '46128',
    '46130',
    '46131',
    '46133',
    '46135',
    '46140',
    '46142',
    '46143',
    '46144',
    '46146',
    '46147',
    '46148',
    '46149',
    '46150',
    '46151',
    '46155',
    '46156',
    '46157',
    '46158',
    '46160',
    '46161',
    '46162',
    '46163',
    '46164',
    '46165',
    '46166',
    '46167',
    '46168',
    '46171',
    '46172',
    '46173',
    '46175',
    '46176',
    '46180',
    '46181',
    '46182',
    '46183',
    '46184',
    '46186',
    '46201',
    '46202',
    '46203',
    '46204',
    '46205',
    '46208',
    '46214',
    '46216',
    '46217',
    '46218',
    '46219',
    '46220',
    '46221',
    '46222',
    '46224',
    '46225',
    '46226',
    '46227',
    '46228',
    '46229',
    '46231',
    '46234',
    '46235',
    '46236',
    '46237',
    '46239',
    '46240',
    '46241',
    '46250',
    '46254',
    '46256',
    '46259',
    '46260',
    '46268',
    '46278',
    '46280',
    '46290',
    '46301',
    '46303',
    '46304',
    '46307',
    '46310',
    '46311',
    '46312',
    '46319',
    '46320',
    '46321',
    '46322',
    '46323',
    '46324',
    '46327',
    '46340',
    '46341',
    '46342',
    '46345',
    '46346',
    '46347',
    '46348',
    '46349',
    '46350',
    '46356',
    '46360',
    '46365',
    '46366',
    '46368',
    '46371',
    '46373',
    '46374',
    '46375',
    '46376',
    '46377',
    '46379',
    '46381',
    '46382',
    '46383',
    '46385',
    '46390',
    '46391',
    '46392',
    '46393',
    '46394',
    '46402',
    '46403',
    '46404',
    '46405',
    '46406',
    '46407',
    '46408',
    '46409',
    '46410',
    '46501',
    '46502',
    '46504',
    '46506',
    '46507',
    '46508',
    '46510',
    '46511',
    '46514',
    '46516',
    '46517',
    '46524',
    '46526',
    '46528',
    '46530',
    '46531',
    '46532',
    '46534',
    '46536',
    '46537',
    '46538',
    '46539',
    '46540',
    '46542',
    '46543',
    '46544',
    '46545',
    '46550',
    '46552',
    '46553',
    '46554',
    '46555',
    '46556',
    '46561',
    '46562',
    '46563',
    '46565',
    '46567',
    '46570',
    '46571',
    '46573',
    '46574',
    '46580',
    '46582',
    '46590',
    '46595',
    '46601',
    '46613',
    '46614',
    '46615',
    '46616',
    '46617',
    '46619',
    '46628',
    '46635',
    '46637',
    '46701',
    '46702',
    '46703',
    '46704',
    '46705',
    '46706',
    '46710',
    '46711',
    '46714',
    '46721',
    '46723',
    '46725',
    '46730',
    '46731',
    '46732',
    '46733',
    '46737',
    '46738',
    '46740',
    '46741',
    '46742',
    '46743',
    '46745',
    '46746',
    '46747',
    '46748',
    '46750',
    '46755',
    '46759',
    '46760',
    '46761',
    '46763',
    '46764',
    '46765',
    '46766',
    '46767',
    '46770',
    '46771',
    '46772',
    '46773',
    '46774',
    '46776',
    '46777',
    '46779',
    '46781',
    '46783',
    '46784',
    '46785',
    '46786',
    '46787',
    '46788',
    '46791',
    '46792',
    '46793',
    '46794',
    '46795',
    '46797',
    '46798',
    '46799',
    '46802',
    '46803',
    '46804',
    '46805',
    '46806',
    '46807',
    '46808',
    '46809',
    '46814',
    '46815',
    '46816',
    '46818',
    '46819',
    '46825',
    '46835',
    '46845',
    '46901',
    '46902',
    '46910',
    '46911',
    '46913',
    '46914',
    '46915',
    '46917',
    '46919',
    '46920',
    '46922',
    '46923',
    '46926',
    '46928',
    '46929',
    '46930',
    '46931',
    '46932',
    '46933',
    '46936',
    '46938',
    '46939',
    '46940',
    '46941',
    '46943',
    '46946',
    '46947',
    '46950',
    '46951',
    '46952',
    '46953',
    '46957',
    '46958',
    '46959',
    '46960',
    '46961',
    '46962',
    '46967',
    '46968',
    '46970',
    '46974',
    '46975',
    '46978',
    '46979',
    '46982',
    '46984',
    '46985',
    '46986',
    '46987',
    '46988',
    '46989',
    '46990',
    '46991',
    '46992',
    '46994',
    '46996',
    '46998',
    '47001',
    '47003',
    '47006',
    '47010',
    '47011',
    '47012',
    '47016',
    '47017',
    '47018',
    '47020',
    '47022',
    '47023',
    '47024',
    '47025',
    '47030',
    '47031',
    '47032',
    '47034',
    '47035',
    '47036',
    '47037',
    '47038',
    '47040',
    '47041',
    '47042',
    '47043',
    '47060',
    '47102',
    '47104',
    '47106',
    '47108',
    '47110',
    '47111',
    '47112',
    '47114',
    '47115',
    '47116',
    '47117',
    '47118',
    '47119',
    '47120',
    '47122',
    '47123',
    '47124',
    '47125',
    '47126',
    '47129',
    '47130',
    '47135',
    '47136',
    '47137',
    '47138',
    '47140',
    '47141',
    '47142',
    '47143',
    '47145',
    '47147',
    '47150',
    '47160',
    '47161',
    '47162',
    '47163',
    '47164',
    '47165',
    '47166',
    '47167',
    '47170',
    '47172',
    '47175',
    '47177',
    '47201',
    '47203',
    '47220',
    '47223',
    '47224',
    '47226',
    '47227',
    '47229',
    '47230',
    '47231',
    '47232',
    '47234',
    '47235',
    '47240',
    '47243',
    '47244',
    '47246',
    '47247',
    '47250',
    '47260',
    '47263',
    '47264',
    '47265',
    '47270',
    '47272',
    '47273',
    '47274',
    '47280',
    '47281',
    '47282',
    '47283',
    '47302',
    '47303',
    '47304',
    '47305',
    '47306',
    '47320',
    '47324',
    '47325',
    '47326',
    '47327',
    '47330',
    '47331',
    '47334',
    '47335',
    '47336',
    '47337',
    '47338',
    '47339',
    '47340',
    '47341',
    '47342',
    '47344',
    '47345',
    '47346',
    '47348',
    '47351',
    '47352',
    '47353',
    '47354',
    '47355',
    '47356',
    '47357',
    '47358',
    '47359',
    '47360',
    '47361',
    '47362',
    '47367',
    '47368',
    '47369',
    '47371',
    '47373',
    '47374',
    '47380',
    '47381',
    '47382',
    '47383',
    '47384',
    '47385',
    '47386',
    '47387',
    '47388',
    '47390',
    '47393',
    '47394',
    '47396',
    '47401',
    '47403',
    '47404',
    '47405',
    '47406',
    '47408',
    '47420',
    '47421',
    '47424',
    '47427',
    '47429',
    '47431',
    '47432',
    '47433',
    '47434',
    '47436',
    '47437',
    '47438',
    '47441',
    '47443',
    '47446',
    '47448',
    '47449',
    '47451',
    '47452',
    '47453',
    '47454',
    '47455',
    '47456',
    '47457',
    '47458',
    '47459',
    '47460',
    '47462',
    '47464',
    '47465',
    '47467',
    '47468',
    '47469',
    '47470',
    '47471',
    '47501',
    '47512',
    '47513',
    '47514',
    '47515',
    '47516',
    '47519',
    '47520',
    '47521',
    '47522',
    '47523',
    '47524',
    '47525',
    '47527',
    '47528',
    '47529',
    '47531',
    '47532',
    '47535',
    '47536',
    '47537',
    '47541',
    '47542',
    '47546',
    '47550',
    '47551',
    '47552',
    '47553',
    '47557',
    '47558',
    '47561',
    '47562',
    '47564',
    '47567',
    '47568',
    '47574',
    '47575',
    '47576',
    '47577',
    '47578',
    '47579',
    '47580',
    '47581',
    '47584',
    '47585',
    '47586',
    '47588',
    '47590',
    '47591',
    '47596',
    '47597',
    '47598',
    '47601',
    '47610',
    '47611',
    '47612',
    '47613',
    '47615',
    '47616',
    '47619',
    '47620',
    '47630',
    '47631',
    '47633',
    '47634',
    '47635',
    '47637',
    '47638',
    '47639',
    '47640',
    '47648',
    '47649',
    '47654',
    '47660',
    '47665',
    '47666',
    '47670',
    '47683',
    '47708',
    '47710',
    '47711',
    '47712',
    '47713',
    '47714',
    '47715',
    '47720',
    '47725',
    '47802',
    '47803',
    '47804',
    '47805',
    '47807',
    '47809',
    '47832',
    '47833',
    '47834',
    '47836',
    '47837',
    '47838',
    '47840',
    '47841',
    '47842',
    '47846',
    '47847',
    '47848',
    '47849',
    '47850',
    '47853',
    '47854',
    '47855',
    '47857',
    '47858',
    '47859',
    '47860',
    '47861',
    '47862',
    '47863',
    '47865',
    '47866',
    '47868',
    '47869',
    '47871',
    '47872',
    '47874',
    '47876',
    '47879',
    '47880',
    '47881',
    '47882',
    '47884',
    '47885',
    '47901',
    '47904',
    '47905',
    '47906',
    '47907',
    '47909',
    '47916',
    '47917',
    '47918',
    '47920',
    '47921',
    '47922',
    '47923',
    '47924',
    '47925',
    '47926',
    '47928',
    '47929',
    '47930',
    '47932',
    '47933',
    '47940',
    '47941',
    '47942',
    '47943',
    '47944',
    '47946',
    '47948',
    '47949',
    '47950',
    '47951',
    '47952',
    '47954',
    '47955',
    '47957',
    '47958',
    '47959',
    '47960',
    '47963',
    '47964',
    '47965',
    '47966',
    '47967',
    '47968',
    '47969',
    '47970',
    '47971',
    '47974',
    '47975',
    '47977',
    '47978',
    '47980',
    '47981',
    '47982',
    '47983',
    '47987',
    '47989',
    '47990',
    '47991',
    '47992',
    '47993',
    '47994',
    '47995',
    '47997'
  ],
  '33': [
    '03031',
    '03032',
    '03033',
    '03034',
    '03036',
    '03037',
    '03038',
    '03042',
    '03043',
    '03044',
    '03045',
    '03046',
    '03047',
    '03048',
    '03049',
    '03051',
    '03052',
    '03053',
    '03054',
    '03055',
    '03057',
    '03060',
    '03062',
    '03063',
    '03064',
    '03070',
    '03071',
    '03076',
    '03077',
    '03079',
    '03082',
    '03084',
    '03086',
    '03087',
    '03101',
    '03102',
    '03103',
    '03104',
    '03106',
    '03109',
    '03110',
    '03215',
    '03216',
    '03217',
    '03218',
    '03220',
    '03221',
    '03222',
    '03223',
    '03224',
    '03225',
    '03226',
    '03227',
    '03229',
    '03230',
    '03231',
    '03233',
    '03234',
    '03235',
    '03237',
    '03238',
    '03240',
    '03241',
    '03242',
    '03243',
    '03244',
    '03245',
    '03246',
    '03249',
    '03251',
    '03253',
    '03254',
    '03255',
    '03256',
    '03257',
    '03258',
    '03259',
    '03260',
    '03261',
    '03262',
    '03263',
    '03264',
    '03266',
    '03268',
    '03269',
    '03273',
    '03275',
    '03276',
    '03278',
    '03279',
    '03280',
    '03281',
    '03282',
    '03284',
    '03285',
    '03287',
    '03290',
    '03291',
    '03293',
    '03301',
    '03303',
    '03304',
    '03307',
    '03431',
    '03440',
    '03441',
    '03442',
    '03443',
    '03444',
    '03445',
    '03446',
    '03447',
    '03448',
    '03449',
    '03450',
    '03451',
    '03452',
    '03455',
    '03456',
    '03457',
    '03458',
    '03461',
    '03462',
    '03464',
    '03465',
    '03466',
    '03467',
    '03470',
    '03561',
    '03570',
    '03574',
    '03575',
    '03576',
    '03579',
    '03580',
    '03581',
    '03582',
    '03583',
    '03584',
    '03585',
    '03586',
    '03588',
    '03590',
    '03592',
    '03593',
    '03595',
    '03597',
    '03598',
    '03601',
    '03602',
    '03603',
    '03604',
    '03605',
    '03607',
    '03608',
    '03609',
    '03740',
    '03741',
    '03743',
    '03745',
    '03746',
    '03748',
    '03750',
    '03751',
    '03752',
    '03753',
    '03754',
    '03755',
    '03765',
    '03766',
    '03768',
    '03770',
    '03771',
    '03773',
    '03774',
    '03777',
    '03779',
    '03780',
    '03781',
    '03782',
    '03784',
    '03785',
    '03801',
    '03809',
    '03810',
    '03811',
    '03812',
    '03813',
    '03814',
    '03816',
    '03817',
    '03818',
    '03819',
    '03820',
    '03823',
    '03824',
    '03825',
    '03826',
    '03827',
    '03830',
    '03832',
    '03833',
    '03835',
    '03836',
    '03837',
    '03838',
    '03839',
    '03840',
    '03841',
    '03842',
    '03844',
    '03845',
    '03846',
    '03847',
    '03848',
    '03849',
    '03850',
    '03851',
    '03852',
    '03853',
    '03854',
    '03855',
    '03856',
    '03857',
    '03858',
    '03860',
    '03861',
    '03862',
    '03864',
    '03865',
    '03867',
    '03868',
    '03869',
    '03870',
    '03871',
    '03872',
    '03873',
    '03874',
    '03875',
    '03878',
    '03882',
    '03883',
    '03884',
    '03885',
    '03886',
    '03887',
    '03890',
    '03894'
  ],
  '02': [
    '99501',
    '99502',
    '99503',
    '99504',
    '99505',
    '99506',
    '99507',
    '99508',
    '99510',
    '99513',
    '99515',
    '99516',
    '99517',
    '99518',
    '99519',
    '99540',
    '99546',
    '99547',
    '99548',
    '99549',
    '99550',
    '99551',
    '99552',
    '99553',
    '99554',
    '99555',
    '99556',
    '99557',
    '99558',
    '99559',
    '99561',
    '99563',
    '99564',
    '99565',
    '99566',
    '99567',
    '99568',
    '99569',
    '99571',
    '99572',
    '99573',
    '99574',
    '99575',
    '99576',
    '99577',
    '99578',
    '99579',
    '99580',
    '99581',
    '99583',
    '99585',
    '99586',
    '99587',
    '99588',
    '99589',
    '99590',
    '99591',
    '99602',
    '99603',
    '99604',
    '99605',
    '99606',
    '99607',
    '99609',
    '99610',
    '99611',
    '99612',
    '99613',
    '99614',
    '99615',
    '99620',
    '99621',
    '99622',
    '99624',
    '99625',
    '99626',
    '99627',
    '99628',
    '99630',
    '99631',
    '99632',
    '99633',
    '99634',
    '99636',
    '99637',
    '99638',
    '99639',
    '99640',
    '99641',
    '99643',
    '99644',
    '99645',
    '99647',
    '99648',
    '99649',
    '99650',
    '99651',
    '99652',
    '99653',
    '99654',
    '99655',
    '99656',
    '99657',
    '99658',
    '99659',
    '99660',
    '99661',
    '99662',
    '99663',
    '99664',
    '99665',
    '99666',
    '99667',
    '99668',
    '99669',
    '99670',
    '99671',
    '99672',
    '99674',
    '99676',
    '99677',
    '99678',
    '99679',
    '99680',
    '99681',
    '99682',
    '99683',
    '99684',
    '99685',
    '99686',
    '99688',
    '99689',
    '99690',
    '99691',
    '99692',
    '99693',
    '99694',
    '99695',
    '99701',
    '99702',
    '99703',
    '99704',
    '99705',
    '99706',
    '99709',
    '99712',
    '99714',
    '99720',
    '99721',
    '99722',
    '99723',
    '99724',
    '99726',
    '99727',
    '99729',
    '99730',
    '99732',
    '99733',
    '99734',
    '99736',
    '99737',
    '99738',
    '99739',
    '99740',
    '99741',
    '99742',
    '99743',
    '99744',
    '99745',
    '99746',
    '99747',
    '99748',
    '99749',
    '99750',
    '99751',
    '99752',
    '99753',
    '99754',
    '99755',
    '99756',
    '99757',
    '99758',
    '99759',
    '99760',
    '99761',
    '99762',
    '99763',
    '99764',
    '99765',
    '99766',
    '99767',
    '99768',
    '99769',
    '99770',
    '99771',
    '99772',
    '99773',
    '99774',
    '99775',
    '99776',
    '99777',
    '99778',
    '99780',
    '99781',
    '99782',
    '99783',
    '99784',
    '99785',
    '99786',
    '99788',
    '99789',
    '99790',
    '99791',
    '99801',
    '99820',
    '99824',
    '99825',
    '99826',
    '99827',
    '99829',
    '99830',
    '99832',
    '99833',
    '99835',
    '99836',
    '99840',
    '99841',
    '99901',
    '99903',
    '99918',
    '99919',
    '99921',
    '99922',
    '99923',
    '99925',
    '99926',
    '99927',
    '99929'
  ],
  '42': [
    '15001',
    '15003',
    '15004',
    '15005',
    '15006',
    '15007',
    '15009',
    '15010',
    '15012',
    '15014',
    '15015',
    '15017',
    '15018',
    '15019',
    '15020',
    '15021',
    '15022',
    '15024',
    '15025',
    '15026',
    '15027',
    '15028',
    '15030',
    '15031',
    '15033',
    '15034',
    '15035',
    '15037',
    '15038',
    '15042',
    '15043',
    '15044',
    '15045',
    '15046',
    '15047',
    '15049',
    '15050',
    '15051',
    '15052',
    '15053',
    '15054',
    '15055',
    '15056',
    '15057',
    '15059',
    '15060',
    '15061',
    '15062',
    '15063',
    '15064',
    '15065',
    '15066',
    '15067',
    '15068',
    '15071',
    '15072',
    '15074',
    '15075',
    '15076',
    '15077',
    '15078',
    '15081',
    '15082',
    '15083',
    '15084',
    '15085',
    '15086',
    '15087',
    '15088',
    '15089',
    '15090',
    '15101',
    '15102',
    '15104',
    '15106',
    '15108',
    '15110',
    '15112',
    '15116',
    '15120',
    '15122',
    '15126',
    '15129',
    '15131',
    '15132',
    '15133',
    '15135',
    '15136',
    '15137',
    '15139',
    '15140',
    '15142',
    '15143',
    '15144',
    '15145',
    '15146',
    '15147',
    '15148',
    '15201',
    '15202',
    '15203',
    '15204',
    '15205',
    '15206',
    '15207',
    '15208',
    '15209',
    '15210',
    '15211',
    '15212',
    '15213',
    '15214',
    '15215',
    '15216',
    '15217',
    '15218',
    '15219',
    '15220',
    '15221',
    '15222',
    '15223',
    '15224',
    '15225',
    '15226',
    '15227',
    '15228',
    '15229',
    '15232',
    '15233',
    '15234',
    '15235',
    '15236',
    '15237',
    '15238',
    '15239',
    '15241',
    '15243',
    '15260',
    '15290',
    '15301',
    '15310',
    '15311',
    '15312',
    '15313',
    '15314',
    '15315',
    '15316',
    '15317',
    '15320',
    '15321',
    '15322',
    '15323',
    '15324',
    '15325',
    '15327',
    '15329',
    '15330',
    '15331',
    '15332',
    '15333',
    '15334',
    '15337',
    '15338',
    '15340',
    '15341',
    '15342',
    '15344',
    '15345',
    '15346',
    '15347',
    '15348',
    '15349',
    '15350',
    '15351',
    '15352',
    '15353',
    '15357',
    '15358',
    '15359',
    '15360',
    '15361',
    '15362',
    '15363',
    '15364',
    '15366',
    '15367',
    '15368',
    '15370',
    '15376',
    '15377',
    '15378',
    '15379',
    '15380',
    '15401',
    '15410',
    '15411',
    '15412',
    '15413',
    '15417',
    '15419',
    '15420',
    '15421',
    '15422',
    '15423',
    '15424',
    '15425',
    '15427',
    '15428',
    '15429',
    '15430',
    '15431',
    '15432',
    '15433',
    '15434',
    '15435',
    '15436',
    '15437',
    '15438',
    '15440',
    '15442',
    '15443',
    '15444',
    '15445',
    '15446',
    '15447',
    '15448',
    '15449',
    '15450',
    '15451',
    '15454',
    '15455',
    '15456',
    '15458',
    '15459',
    '15460',
    '15461',
    '15462',
    '15463',
    '15464',
    '15466',
    '15467',
    '15468',
    '15469',
    '15470',
    '15472',
    '15473',
    '15474',
    '15475',
    '15476',
    '15477',
    '15478',
    '15479',
    '15480',
    '15482',
    '15483',
    '15484',
    '15486',
    '15489',
    '15490',
    '15492',
    '15501',
    '15502',
    '15510',
    '15520',
    '15521',
    '15522',
    '15530',
    '15531',
    '15532',
    '15533',
    '15534',
    '15535',
    '15536',
    '15537',
    '15538',
    '15539',
    '15540',
    '15541',
    '15542',
    '15544',
    '15545',
    '15546',
    '15547',
    '15550',
    '15551',
    '15552',
    '15554',
    '15555',
    '15557',
    '15558',
    '15559',
    '15560',
    '15561',
    '15562',
    '15563',
    '15564',
    '15601',
    '15610',
    '15611',
    '15612',
    '15613',
    '15615',
    '15616',
    '15617',
    '15618',
    '15620',
    '15621',
    '15622',
    '15623',
    '15624',
    '15625',
    '15626',
    '15627',
    '15628',
    '15629',
    '15631',
    '15632',
    '15633',
    '15634',
    '15635',
    '15636',
    '15637',
    '15638',
    '15639',
    '15640',
    '15641',
    '15642',
    '15644',
    '15646',
    '15647',
    '15650',
    '15655',
    '15656',
    '15658',
    '15660',
    '15661',
    '15662',
    '15663',
    '15665',
    '15666',
    '15668',
    '15670',
    '15671',
    '15672',
    '15673',
    '15675',
    '15676',
    '15677',
    '15678',
    '15679',
    '15680',
    '15681',
    '15683',
    '15684',
    '15686',
    '15687',
    '15688',
    '15689',
    '15690',
    '15691',
    '15692',
    '15693',
    '15695',
    '15696',
    '15697',
    '15698',
    '15701',
    '15710',
    '15711',
    '15712',
    '15713',
    '15714',
    '15715',
    '15716',
    '15717',
    '15721',
    '15722',
    '15723',
    '15724',
    '15725',
    '15727',
    '15728',
    '15729',
    '15730',
    '15731',
    '15732',
    '15733',
    '15734',
    '15736',
    '15737',
    '15738',
    '15739',
    '15741',
    '15742',
    '15744',
    '15745',
    '15746',
    '15747',
    '15748',
    '15750',
    '15752',
    '15753',
    '15754',
    '15756',
    '15757',
    '15759',
    '15760',
    '15761',
    '15762',
    '15764',
    '15765',
    '15767',
    '15770',
    '15771',
    '15772',
    '15773',
    '15774',
    '15775',
    '15776',
    '15777',
    '15778',
    '15779',
    '15780',
    '15781',
    '15783',
    '15784',
    '15801',
    '15821',
    '15823',
    '15824',
    '15825',
    '15827',
    '15828',
    '15829',
    '15832',
    '15834',
    '15840',
    '15841',
    '15845',
    '15846',
    '15847',
    '15848',
    '15849',
    '15851',
    '15853',
    '15856',
    '15857',
    '15860',
    '15861',
    '15863',
    '15864',
    '15865',
    '15866',
    '15868',
    '15870',
    '15901',
    '15902',
    '15904',
    '15905',
    '15906',
    '15909',
    '15920',
    '15921',
    '15922',
    '15923',
    '15924',
    '15925',
    '15926',
    '15927',
    '15928',
    '15929',
    '15930',
    '15931',
    '15934',
    '15935',
    '15936',
    '15937',
    '15938',
    '15940',
    '15942',
    '15943',
    '15944',
    '15945',
    '15946',
    '15948',
    '15949',
    '15951',
    '15952',
    '15953',
    '15954',
    '15955',
    '15956',
    '15957',
    '15958',
    '15960',
    '15961',
    '15962',
    '15963',
    '16001',
    '16002',
    '16020',
    '16022',
    '16023',
    '16024',
    '16025',
    '16027',
    '16028',
    '16029',
    '16030',
    '16033',
    '16034',
    '16035',
    '16036',
    '16037',
    '16038',
    '16040',
    '16041',
    '16045',
    '16046',
    '16048',
    '16049',
    '16050',
    '16051',
    '16052',
    '16053',
    '16054',
    '16055',
    '16056',
    '16057',
    '16059',
    '16061',
    '16063',
    '16066',
    '16101',
    '16102',
    '16105',
    '16110',
    '16111',
    '16112',
    '16113',
    '16114',
    '16115',
    '16116',
    '16117',
    '16120',
    '16121',
    '16123',
    '16124',
    '16125',
    '16127',
    '16130',
    '16131',
    '16132',
    '16133',
    '16134',
    '16136',
    '16137',
    '16140',
    '16141',
    '16142',
    '16143',
    '16145',
    '16146',
    '16148',
    '16150',
    '16151',
    '16153',
    '16154',
    '16155',
    '16156',
    '16157',
    '16159',
    '16160',
    '16161',
    '16201',
    '16210',
    '16211',
    '16212',
    '16213',
    '16214',
    '16217',
    '16218',
    '16222',
    '16223',
    '16224',
    '16226',
    '16228',
    '16229',
    '16230',
    '16232',
    '16233',
    '16235',
    '16236',
    '16238',
    '16239',
    '16240',
    '16242',
    '16244',
    '16245',
    '16246',
    '16248',
    '16249',
    '16250',
    '16253',
    '16254',
    '16255',
    '16256',
    '16258',
    '16259',
    '16260',
    '16262',
    '16263',
    '16301',
    '16311',
    '16312',
    '16313',
    '16314',
    '16316',
    '16317',
    '16319',
    '16321',
    '16322',
    '16323',
    '16326',
    '16327',
    '16328',
    '16329',
    '16331',
    '16332',
    '16333',
    '16334',
    '16335',
    '16340',
    '16341',
    '16342',
    '16343',
    '16344',
    '16345',
    '16346',
    '16347',
    '16350',
    '16351',
    '16352',
    '16353',
    '16354',
    '16360',
    '16361',
    '16362',
    '16364',
    '16365',
    '16370',
    '16371',
    '16372',
    '16373',
    '16374',
    '16401',
    '16402',
    '16403',
    '16404',
    '16405',
    '16406',
    '16407',
    '16410',
    '16411',
    '16412',
    '16415',
    '16416',
    '16417',
    '16420',
    '16421',
    '16422',
    '16423',
    '16424',
    '16426',
    '16427',
    '16428',
    '16433',
    '16434',
    '16435',
    '16436',
    '16438',
    '16440',
    '16441',
    '16442',
    '16443',
    '16444',
    '16501',
    '16502',
    '16503',
    '16504',
    '16505',
    '16506',
    '16507',
    '16508',
    '16509',
    '16510',
    '16511',
    '16546',
    '16563',
    '16601',
    '16602',
    '16611',
    '16613',
    '16616',
    '16617',
    '16619',
    '16620',
    '16621',
    '16622',
    '16623',
    '16624',
    '16625',
    '16627',
    '16630',
    '16631',
    '16633',
    '16634',
    '16635',
    '16636',
    '16637',
    '16638',
    '16639',
    '16640',
    '16641',
    '16645',
    '16646',
    '16647',
    '16648',
    '16650',
    '16651',
    '16652',
    '16655',
    '16656',
    '16657',
    '16659',
    '16661',
    '16662',
    '16664',
    '16665',
    '16666',
    '16667',
    '16668',
    '16669',
    '16670',
    '16671',
    '16672',
    '16673',
    '16674',
    '16677',
    '16678',
    '16679',
    '16680',
    '16682',
    '16683',
    '16685',
    '16686',
    '16689',
    '16691',
    '16692',
    '16693',
    '16694',
    '16695',
    '16699',
    '16701',
    '16720',
    '16724',
    '16725',
    '16726',
    '16727',
    '16728',
    '16729',
    '16730',
    '16731',
    '16732',
    '16733',
    '16734',
    '16735',
    '16738',
    '16740',
    '16743',
    '16744',
    '16745',
    '16746',
    '16748',
    '16749',
    '16750',
    '16801',
    '16802',
    '16803',
    '16820',
    '16821',
    '16822',
    '16823',
    '16825',
    '16826',
    '16827',
    '16828',
    '16829',
    '16830',
    '16832',
    '16833',
    '16834',
    '16835',
    '16836',
    '16837',
    '16838',
    '16839',
    '16840',
    '16841',
    '16843',
    '16844',
    '16845',
    '16847',
    '16848',
    '16849',
    '16851',
    '16852',
    '16853',
    '16854',
    '16855',
    '16858',
    '16859',
    '16860',
    '16861',
    '16863',
    '16865',
    '16866',
    '16868',
    '16870',
    '16871',
    '16872',
    '16874',
    '16875',
    '16876',
    '16877',
    '16878',
    '16879',
    '16881',
    '16882',
    '16901',
    '16911',
    '16912',
    '16914',
    '16915',
    '16917',
    '16920',
    '16921',
    '16922',
    '16923',
    '16925',
    '16926',
    '16927',
    '16928',
    '16929',
    '16930',
    '16932',
    '16933',
    '16935',
    '16936',
    '16937',
    '16938',
    '16939',
    '16940',
    '16941',
    '16942',
    '16943',
    '16946',
    '16947',
    '16948',
    '16950',
    '17002',
    '17003',
    '17004',
    '17005',
    '17006',
    '17007',
    '17009',
    '17010',
    '17011',
    '17013',
    '17015',
    '17016',
    '17017',
    '17018',
    '17019',
    '17020',
    '17021',
    '17022',
    '17023',
    '17024',
    '17025',
    '17026',
    '17027',
    '17028',
    '17029',
    '17030',
    '17032',
    '17033',
    '17034',
    '17035',
    '17036',
    '17037',
    '17038',
    '17039',
    '17040',
    '17041',
    '17042',
    '17043',
    '17044',
    '17045',
    '17046',
    '17047',
    '17048',
    '17049',
    '17050',
    '17051',
    '17052',
    '17053',
    '17055',
    '17056',
    '17057',
    '17058',
    '17059',
    '17060',
    '17061',
    '17062',
    '17063',
    '17064',
    '17065',
    '17066',
    '17067',
    '17068',
    '17069',
    '17070',
    '17071',
    '17072',
    '17073',
    '17074',
    '17075',
    '17076',
    '17077',
    '17078',
    '17080',
    '17081',
    '17082',
    '17083',
    '17084',
    '17086',
    '17087',
    '17088',
    '17090',
    '17093',
    '17094',
    '17097',
    '17098',
    '17099',
    '17101',
    '17102',
    '17103',
    '17104',
    '17109',
    '17110',
    '17111',
    '17112',
    '17113',
    '17120',
    '17201',
    '17202',
    '17210',
    '17211',
    '17212',
    '17213',
    '17214',
    '17215',
    '17217',
    '17219',
    '17220',
    '17221',
    '17222',
    '17223',
    '17224',
    '17225',
    '17228',
    '17229',
    '17233',
    '17235',
    '17236',
    '17237',
    '17238',
    '17239',
    '17240',
    '17241',
    '17243',
    '17244',
    '17246',
    '17247',
    '17249',
    '17250',
    '17251',
    '17252',
    '17253',
    '17254',
    '17255',
    '17256',
    '17257',
    '17260',
    '17261',
    '17262',
    '17263',
    '17264',
    '17265',
    '17266',
    '17267',
    '17268',
    '17270',
    '17271',
    '17272',
    '17301',
    '17302',
    '17304',
    '17306',
    '17307',
    '17309',
    '17311',
    '17313',
    '17314',
    '17315',
    '17316',
    '17317',
    '17318',
    '17319',
    '17320',
    '17321',
    '17322',
    '17324',
    '17325',
    '17327',
    '17329',
    '17331',
    '17339',
    '17340',
    '17343',
    '17344',
    '17345',
    '17347',
    '17349',
    '17350',
    '17352',
    '17353',
    '17355',
    '17356',
    '17360',
    '17361',
    '17362',
    '17363',
    '17364',
    '17365',
    '17366',
    '17368',
    '17370',
    '17371',
    '17372',
    '17401',
    '17402',
    '17403',
    '17404',
    '17406',
    '17407',
    '17408',
    '17501',
    '17502',
    '17505',
    '17507',
    '17508',
    '17509',
    '17512',
    '17516',
    '17517',
    '17518',
    '17519',
    '17520',
    '17522',
    '17527',
    '17529',
    '17532',
    '17535',
    '17536',
    '17538',
    '17540',
    '17543',
    '17545',
    '17547',
    '17550',
    '17551',
    '17552',
    '17554',
    '17555',
    '17557',
    '17560',
    '17562',
    '17563',
    '17565',
    '17566',
    '17569',
    '17570',
    '17572',
    '17576',
    '17578',
    '17579',
    '17581',
    '17582',
    '17584',
    '17601',
    '17602',
    '17603',
    '17606',
    '17701',
    '17702',
    '17721',
    '17723',
    '17724',
    '17727',
    '17728',
    '17729',
    '17730',
    '17731',
    '17737',
    '17739',
    '17740',
    '17742',
    '17744',
    '17745',
    '17747',
    '17748',
    '17749',
    '17750',
    '17751',
    '17752',
    '17754',
    '17756',
    '17758',
    '17760',
    '17762',
    '17763',
    '17764',
    '17765',
    '17767',
    '17768',
    '17771',
    '17772',
    '17774',
    '17776',
    '17777',
    '17778',
    '17779',
    '17801',
    '17810',
    '17812',
    '17813',
    '17814',
    '17815',
    '17820',
    '17821',
    '17822',
    '17823',
    '17824',
    '17827',
    '17829',
    '17830',
    '17832',
    '17834',
    '17835',
    '17836',
    '17837',
    '17840',
    '17841',
    '17842',
    '17844',
    '17845',
    '17846',
    '17847',
    '17850',
    '17851',
    '17853',
    '17855',
    '17856',
    '17857',
    '17859',
    '17860',
    '17861',
    '17862',
    '17864',
    '17865',
    '17866',
    '17867',
    '17868',
    '17870',
    '17872',
    '17876',
    '17878',
    '17880',
    '17881',
    '17884',
    '17885',
    '17886',
    '17887',
    '17888',
    '17889',
    '17901',
    '17920',
    '17921',
    '17922',
    '17923',
    '17925',
    '17929',
    '17930',
    '17931',
    '17932',
    '17933',
    '17934',
    '17935',
    '17936',
    '17938',
    '17941',
    '17943',
    '17944',
    '17945',
    '17946',
    '17948',
    '17949',
    '17951',
    '17952',
    '17953',
    '17954',
    '17957',
    '17959',
    '17960',
    '17961',
    '17963',
    '17964',
    '17965',
    '17966',
    '17967',
    '17968',
    '17970',
    '17972',
    '17974',
    '17976',
    '17978',
    '17979',
    '17980',
    '17981',
    '17982',
    '17983',
    '17985',
    '18002',
    '18003',
    '18011',
    '18012',
    '18013',
    '18014',
    '18015',
    '18016',
    '18017',
    '18018',
    '18020',
    '18030',
    '18031',
    '18032',
    '18034',
    '18035',
    '18036',
    '18037',
    '18038',
    '18040',
    '18041',
    '18042',
    '18043',
    '18044',
    '18045',
    '18046',
    '18049',
    '18051',
    '18052',
    '18053',
    '18054',
    '18055',
    '18056',
    '18058',
    '18059',
    '18062',
    '18063',
    '18064',
    '18066',
    '18067',
    '18068',
    '18069',
    '18070',
    '18071',
    '18072',
    '18073',
    '18074',
    '18076',
    '18077',
    '18078',
    '18079',
    '18080',
    '18081',
    '18083',
    '18085',
    '18086',
    '18087',
    '18088',
    '18091',
    '18092',
    '18101',
    '18102',
    '18103',
    '18104',
    '18105',
    '18106',
    '18109',
    '18195',
    '18201',
    '18202',
    '18210',
    '18211',
    '18212',
    '18214',
    '18216',
    '18218',
    '18219',
    '18220',
    '18221',
    '18222',
    '18223',
    '18224',
    '18225',
    '18229',
    '18230',
    '18231',
    '18232',
    '18234',
    '18235',
    '18237',
    '18239',
    '18240',
    '18241',
    '18242',
    '18244',
    '18245',
    '18246',
    '18247',
    '18248',
    '18249',
    '18250',
    '18251',
    '18252',
    '18254',
    '18255',
    '18256',
    '18301',
    '18302',
    '18320',
    '18321',
    '18322',
    '18323',
    '18324',
    '18325',
    '18326',
    '18327',
    '18328',
    '18330',
    '18331',
    '18332',
    '18333',
    '18334',
    '18335',
    '18336',
    '18337',
    '18340',
    '18341',
    '18342',
    '18343',
    '18344',
    '18346',
    '18347',
    '18348',
    '18349',
    '18350',
    '18351',
    '18352',
    '18353',
    '18354',
    '18355',
    '18356',
    '18357',
    '18360',
    '18370',
    '18371',
    '18372',
    '18403',
    '18405',
    '18407',
    '18410',
    '18411',
    '18413',
    '18414',
    '18415',
    '18416',
    '18417',
    '18419',
    '18420',
    '18421',
    '18424',
    '18425',
    '18426',
    '18427',
    '18428',
    '18430',
    '18431',
    '18433',
    '18434',
    '18435',
    '18436',
    '18437',
    '18438',
    '18439',
    '18440',
    '18441',
    '18443',
    '18444',
    '18445',
    '18446',
    '18447',
    '18448',
    '18449',
    '18451',
    '18452',
    '18453',
    '18454',
    '18455',
    '18456',
    '18457',
    '18458',
    '18459',
    '18460',
    '18461',
    '18462',
    '18463',
    '18464',
    '18465',
    '18466',
    '18469',
    '18470',
    '18471',
    '18472',
    '18473',
    '18501',
    '18502',
    '18503',
    '18504',
    '18505',
    '18507',
    '18508',
    '18509',
    '18510',
    '18512',
    '18515',
    '18517',
    '18518',
    '18519',
    '18540',
    '18577',
    '18601',
    '18602',
    '18603',
    '18610',
    '18611',
    '18612',
    '18614',
    '18615',
    '18616',
    '18617',
    '18618',
    '18619',
    '18621',
    '18622',
    '18623',
    '18624',
    '18625',
    '18626',
    '18627',
    '18628',
    '18629',
    '18630',
    '18631',
    '18632',
    '18634',
    '18635',
    '18636',
    '18640',
    '18641',
    '18642',
    '18643',
    '18644',
    '18651',
    '18653',
    '18654',
    '18655',
    '18656',
    '18657',
    '18660',
    '18661',
    '18701',
    '18702',
    '18703',
    '18704',
    '18705',
    '18706',
    '18707',
    '18708',
    '18709',
    '18710',
    '18711',
    '18762',
    '18764',
    '18765',
    '18766',
    '18767',
    '18769',
    '18773',
    '18801',
    '18810',
    '18812',
    '18814',
    '18816',
    '18817',
    '18818',
    '18821',
    '18822',
    '18823',
    '18824',
    '18825',
    '18826',
    '18828',
    '18829',
    '18830',
    '18831',
    '18832',
    '18833',
    '18834',
    '18837',
    '18840',
    '18842',
    '18843',
    '18844',
    '18845',
    '18846',
    '18847',
    '18848',
    '18850',
    '18851',
    '18853',
    '18854',
    '18901',
    '18902',
    '18912',
    '18913',
    '18914',
    '18915',
    '18917',
    '18920',
    '18923',
    '18925',
    '18929',
    '18930',
    '18932',
    '18935',
    '18936',
    '18938',
    '18940',
    '18942',
    '18944',
    '18947',
    '18950',
    '18951',
    '18954',
    '18955',
    '18960',
    '18962',
    '18964',
    '18966',
    '18969',
    '18970',
    '18972',
    '18974',
    '18976',
    '18977',
    '18980',
    '19001',
    '19002',
    '19003',
    '19004',
    '19006',
    '19007',
    '19008',
    '19009',
    '19010',
    '19012',
    '19013',
    '19014',
    '19015',
    '19017',
    '19018',
    '19020',
    '19021',
    '19022',
    '19023',
    '19025',
    '19026',
    '19027',
    '19029',
    '19030',
    '19031',
    '19032',
    '19033',
    '19034',
    '19035',
    '19036',
    '19038',
    '19040',
    '19041',
    '19043',
    '19044',
    '19046',
    '19047',
    '19050',
    '19053',
    '19054',
    '19055',
    '19056',
    '19057',
    '19060',
    '19061',
    '19063',
    '19064',
    '19066',
    '19067',
    '19070',
    '19072',
    '19073',
    '19074',
    '19075',
    '19076',
    '19078',
    '19079',
    '19081',
    '19082',
    '19083',
    '19085',
    '19086',
    '19087',
    '19090',
    '19094',
    '19095',
    '19096',
    '19102',
    '19103',
    '19104',
    '19106',
    '19107',
    '19109',
    '19111',
    '19112',
    '19113',
    '19114',
    '19115',
    '19116',
    '19118',
    '19119',
    '19120',
    '19121',
    '19122',
    '19123',
    '19124',
    '19125',
    '19126',
    '19127',
    '19128',
    '19129',
    '19130',
    '19131',
    '19132',
    '19133',
    '19134',
    '19135',
    '19136',
    '19137',
    '19138',
    '19139',
    '19140',
    '19141',
    '19142',
    '19143',
    '19144',
    '19145',
    '19146',
    '19147',
    '19148',
    '19149',
    '19150',
    '19151',
    '19152',
    '19153',
    '19154',
    '19301',
    '19310',
    '19311',
    '19312',
    '19316',
    '19317',
    '19319',
    '19320',
    '19330',
    '19333',
    '19335',
    '19341',
    '19342',
    '19343',
    '19344',
    '19345',
    '19348',
    '19350',
    '19352',
    '19355',
    '19358',
    '19362',
    '19363',
    '19365',
    '19367',
    '19372',
    '19373',
    '19374',
    '19375',
    '19380',
    '19382',
    '19383',
    '19390',
    '19401',
    '19403',
    '19405',
    '19406',
    '19422',
    '19425',
    '19426',
    '19428',
    '19435',
    '19436',
    '19437',
    '19438',
    '19440',
    '19442',
    '19444',
    '19446',
    '19453',
    '19454',
    '19456',
    '19457',
    '19460',
    '19462',
    '19464',
    '19465',
    '19468',
    '19472',
    '19473',
    '19474',
    '19475',
    '19477',
    '19492',
    '19501',
    '19503',
    '19504',
    '19505',
    '19506',
    '19507',
    '19508',
    '19510',
    '19511',
    '19512',
    '19518',
    '19519',
    '19520',
    '19522',
    '19523',
    '19525',
    '19526',
    '19529',
    '19530',
    '19533',
    '19534',
    '19535',
    '19536',
    '19538',
    '19539',
    '19540',
    '19541',
    '19542',
    '19543',
    '19544',
    '19545',
    '19547',
    '19549',
    '19550',
    '19551',
    '19554',
    '19555',
    '19559',
    '19560',
    '19562',
    '19564',
    '19565',
    '19567',
    '19601',
    '19602',
    '19604',
    '19605',
    '19606',
    '19607',
    '19608',
    '19609',
    '19610',
    '19611'
  ],
  '20': [
    '66002',
    '66006',
    '66007',
    '66008',
    '66010',
    '66012',
    '66013',
    '66014',
    '66015',
    '66016',
    '66017',
    '66018',
    '66019',
    '66020',
    '66021',
    '66023',
    '66024',
    '66025',
    '66026',
    '66027',
    '66030',
    '66031',
    '66032',
    '66033',
    '66035',
    '66039',
    '66040',
    '66041',
    '66042',
    '66043',
    '66044',
    '66045',
    '66046',
    '66047',
    '66048',
    '66049',
    '66050',
    '66052',
    '66053',
    '66054',
    '66056',
    '66058',
    '66060',
    '66061',
    '66062',
    '66064',
    '66066',
    '66067',
    '66070',
    '66071',
    '66072',
    '66073',
    '66075',
    '66076',
    '66078',
    '66079',
    '66080',
    '66083',
    '66085',
    '66086',
    '66087',
    '66088',
    '66090',
    '66091',
    '66092',
    '66093',
    '66094',
    '66095',
    '66097',
    '66101',
    '66102',
    '66103',
    '66104',
    '66105',
    '66106',
    '66109',
    '66111',
    '66112',
    '66115',
    '66118',
    '66202',
    '66203',
    '66204',
    '66205',
    '66206',
    '66207',
    '66208',
    '66209',
    '66210',
    '66211',
    '66212',
    '66213',
    '66214',
    '66215',
    '66216',
    '66217',
    '66218',
    '66219',
    '66220',
    '66221',
    '66223',
    '66224',
    '66226',
    '66227',
    '66401',
    '66402',
    '66403',
    '66404',
    '66406',
    '66407',
    '66408',
    '66409',
    '66411',
    '66412',
    '66413',
    '66414',
    '66415',
    '66416',
    '66417',
    '66418',
    '66419',
    '66422',
    '66423',
    '66424',
    '66425',
    '66427',
    '66428',
    '66429',
    '66431',
    '66432',
    '66434',
    '66436',
    '66438',
    '66439',
    '66440',
    '66441',
    '66442',
    '66449',
    '66451',
    '66501',
    '66502',
    '66503',
    '66506',
    '66507',
    '66508',
    '66509',
    '66510',
    '66512',
    '66514',
    '66515',
    '66516',
    '66517',
    '66518',
    '66520',
    '66521',
    '66522',
    '66523',
    '66524',
    '66526',
    '66527',
    '66528',
    '66531',
    '66532',
    '66533',
    '66534',
    '66535',
    '66536',
    '66537',
    '66538',
    '66539',
    '66540',
    '66541',
    '66542',
    '66543',
    '66544',
    '66546',
    '66547',
    '66548',
    '66549',
    '66550',
    '66552',
    '66554',
    '66603',
    '66604',
    '66605',
    '66606',
    '66607',
    '66608',
    '66609',
    '66610',
    '66611',
    '66612',
    '66614',
    '66615',
    '66616',
    '66617',
    '66618',
    '66619',
    '66621',
    '66622',
    '66701',
    '66710',
    '66711',
    '66712',
    '66713',
    '66714',
    '66716',
    '66717',
    '66720',
    '66724',
    '66725',
    '66728',
    '66732',
    '66733',
    '66734',
    '66735',
    '66736',
    '66738',
    '66739',
    '66740',
    '66741',
    '66743',
    '66746',
    '66748',
    '66749',
    '66751',
    '66753',
    '66754',
    '66755',
    '66756',
    '66757',
    '66758',
    '66760',
    '66761',
    '66762',
    '66763',
    '66767',
    '66769',
    '66770',
    '66771',
    '66772',
    '66773',
    '66775',
    '66776',
    '66777',
    '66778',
    '66779',
    '66780',
    '66781',
    '66782',
    '66783',
    '66801',
    '66830',
    '66833',
    '66834',
    '66835',
    '66838',
    '66839',
    '66840',
    '66842',
    '66843',
    '66845',
    '66846',
    '66849',
    '66850',
    '66851',
    '66852',
    '66853',
    '66854',
    '66856',
    '66857',
    '66858',
    '66859',
    '66860',
    '66861',
    '66862',
    '66863',
    '66864',
    '66865',
    '66866',
    '66868',
    '66869',
    '66870',
    '66871',
    '66872',
    '66873',
    '66901',
    '66930',
    '66932',
    '66933',
    '66935',
    '66936',
    '66937',
    '66938',
    '66939',
    '66940',
    '66941',
    '66942',
    '66943',
    '66944',
    '66945',
    '66946',
    '66948',
    '66949',
    '66951',
    '66952',
    '66953',
    '66955',
    '66956',
    '66958',
    '66959',
    '66960',
    '66962',
    '66963',
    '66964',
    '66966',
    '66967',
    '66968',
    '66970',
    '67001',
    '67002',
    '67003',
    '67004',
    '67005',
    '67008',
    '67009',
    '67010',
    '67012',
    '67013',
    '67016',
    '67017',
    '67018',
    '67019',
    '67020',
    '67021',
    '67022',
    '67023',
    '67024',
    '67025',
    '67026',
    '67028',
    '67029',
    '67030',
    '67031',
    '67035',
    '67036',
    '67037',
    '67038',
    '67039',
    '67041',
    '67042',
    '67045',
    '67047',
    '67049',
    '67050',
    '67051',
    '67052',
    '67053',
    '67054',
    '67055',
    '67056',
    '67057',
    '67058',
    '67059',
    '67060',
    '67061',
    '67062',
    '67063',
    '67065',
    '67066',
    '67067',
    '67068',
    '67070',
    '67071',
    '67072',
    '67073',
    '67074',
    '67101',
    '67103',
    '67104',
    '67105',
    '67106',
    '67107',
    '67108',
    '67109',
    '67110',
    '67111',
    '67112',
    '67114',
    '67117',
    '67118',
    '67119',
    '67120',
    '67122',
    '67123',
    '67124',
    '67127',
    '67131',
    '67132',
    '67133',
    '67134',
    '67135',
    '67137',
    '67138',
    '67140',
    '67142',
    '67143',
    '67144',
    '67146',
    '67147',
    '67149',
    '67150',
    '67151',
    '67152',
    '67154',
    '67155',
    '67156',
    '67159',
    '67202',
    '67203',
    '67204',
    '67205',
    '67206',
    '67207',
    '67208',
    '67209',
    '67210',
    '67211',
    '67212',
    '67213',
    '67214',
    '67215',
    '67216',
    '67217',
    '67218',
    '67219',
    '67220',
    '67223',
    '67226',
    '67227',
    '67228',
    '67230',
    '67232',
    '67235',
    '67260',
    '67301',
    '67330',
    '67332',
    '67333',
    '67334',
    '67335',
    '67336',
    '67337',
    '67340',
    '67341',
    '67342',
    '67344',
    '67345',
    '67346',
    '67347',
    '67349',
    '67351',
    '67352',
    '67353',
    '67354',
    '67355',
    '67356',
    '67357',
    '67360',
    '67361',
    '67363',
    '67364',
    '67401',
    '67410',
    '67416',
    '67417',
    '67418',
    '67420',
    '67422',
    '67423',
    '67425',
    '67427',
    '67428',
    '67430',
    '67431',
    '67432',
    '67436',
    '67437',
    '67438',
    '67439',
    '67441',
    '67442',
    '67443',
    '67444',
    '67445',
    '67446',
    '67447',
    '67448',
    '67449',
    '67450',
    '67451',
    '67452',
    '67454',
    '67455',
    '67456',
    '67457',
    '67458',
    '67459',
    '67460',
    '67464',
    '67466',
    '67467',
    '67468',
    '67470',
    '67473',
    '67474',
    '67475',
    '67478',
    '67480',
    '67481',
    '67482',
    '67483',
    '67484',
    '67485',
    '67487',
    '67490',
    '67491',
    '67492',
    '67501',
    '67502',
    '67505',
    '67510',
    '67511',
    '67512',
    '67513',
    '67514',
    '67515',
    '67516',
    '67518',
    '67519',
    '67520',
    '67521',
    '67522',
    '67523',
    '67524',
    '67525',
    '67526',
    '67529',
    '67530',
    '67543',
    '67544',
    '67545',
    '67546',
    '67547',
    '67548',
    '67550',
    '67552',
    '67553',
    '67554',
    '67556',
    '67557',
    '67559',
    '67560',
    '67561',
    '67563',
    '67564',
    '67565',
    '67566',
    '67567',
    '67568',
    '67570',
    '67572',
    '67573',
    '67574',
    '67575',
    '67576',
    '67578',
    '67579',
    '67581',
    '67583',
    '67584',
    '67601',
    '67621',
    '67622',
    '67623',
    '67625',
    '67626',
    '67628',
    '67629',
    '67631',
    '67632',
    '67634',
    '67635',
    '67637',
    '67638',
    '67639',
    '67640',
    '67642',
    '67643',
    '67644',
    '67645',
    '67646',
    '67647',
    '67648',
    '67649',
    '67650',
    '67651',
    '67653',
    '67654',
    '67656',
    '67657',
    '67658',
    '67659',
    '67660',
    '67661',
    '67663',
    '67664',
    '67665',
    '67667',
    '67669',
    '67671',
    '67672',
    '67673',
    '67674',
    '67675',
    '67701',
    '67730',
    '67731',
    '67732',
    '67733',
    '67734',
    '67735',
    '67736',
    '67737',
    '67738',
    '67739',
    '67740',
    '67741',
    '67743',
    '67744',
    '67745',
    '67747',
    '67748',
    '67749',
    '67751',
    '67752',
    '67753',
    '67756',
    '67757',
    '67758',
    '67761',
    '67762',
    '67764',
    '67801',
    '67831',
    '67834',
    '67835',
    '67836',
    '67837',
    '67838',
    '67839',
    '67840',
    '67841',
    '67842',
    '67843',
    '67844',
    '67846',
    '67849',
    '67850',
    '67851',
    '67853',
    '67854',
    '67855',
    '67857',
    '67859',
    '67860',
    '67861',
    '67862',
    '67863',
    '67864',
    '67865',
    '67867',
    '67868',
    '67869',
    '67870',
    '67871',
    '67876',
    '67877',
    '67878',
    '67879',
    '67880',
    '67882',
    '67901',
    '67950',
    '67951',
    '67952',
    '67953',
    '67954',
    '68325',
    '68978'
  ],
  '28': [
    '38326',
    '38601',
    '38603',
    '38606',
    '38610',
    '38611',
    '38614',
    '38617',
    '38618',
    '38619',
    '38620',
    '38621',
    '38622',
    '38623',
    '38625',
    '38626',
    '38627',
    '38629',
    '38630',
    '38631',
    '38632',
    '38633',
    '38635',
    '38637',
    '38639',
    '38641',
    '38642',
    '38643',
    '38644',
    '38645',
    '38646',
    '38647',
    '38650',
    '38651',
    '38652',
    '38654',
    '38655',
    '38658',
    '38659',
    '38661',
    '38663',
    '38664',
    '38665',
    '38666',
    '38668',
    '38670',
    '38671',
    '38672',
    '38673',
    '38674',
    '38676',
    '38677',
    '38680',
    '38683',
    '38685',
    '38701',
    '38702',
    '38703',
    '38704',
    '38720',
    '38721',
    '38722',
    '38723',
    '38725',
    '38726',
    '38730',
    '38731',
    '38732',
    '38736',
    '38737',
    '38738',
    '38740',
    '38744',
    '38745',
    '38746',
    '38748',
    '38749',
    '38751',
    '38753',
    '38754',
    '38756',
    '38759',
    '38760',
    '38761',
    '38762',
    '38764',
    '38765',
    '38767',
    '38768',
    '38769',
    '38771',
    '38772',
    '38773',
    '38774',
    '38778',
    '38781',
    '38801',
    '38804',
    '38821',
    '38824',
    '38826',
    '38827',
    '38828',
    '38829',
    '38833',
    '38834',
    '38838',
    '38841',
    '38843',
    '38844',
    '38846',
    '38847',
    '38848',
    '38849',
    '38850',
    '38851',
    '38852',
    '38855',
    '38856',
    '38857',
    '38858',
    '38859',
    '38860',
    '38862',
    '38863',
    '38864',
    '38865',
    '38866',
    '38868',
    '38869',
    '38870',
    '38871',
    '38873',
    '38876',
    '38878',
    '38879',
    '38901',
    '38913',
    '38914',
    '38915',
    '38916',
    '38917',
    '38920',
    '38921',
    '38922',
    '38923',
    '38924',
    '38925',
    '38927',
    '38928',
    '38929',
    '38930',
    '38940',
    '38941',
    '38943',
    '38944',
    '38945',
    '38946',
    '38947',
    '38948',
    '38949',
    '38950',
    '38951',
    '38952',
    '38953',
    '38954',
    '38957',
    '38958',
    '38961',
    '38962',
    '38963',
    '38964',
    '38965',
    '38966',
    '38967',
    '39038',
    '39039',
    '39040',
    '39041',
    '39042',
    '39044',
    '39045',
    '39046',
    '39047',
    '39051',
    '39054',
    '39056',
    '39057',
    '39059',
    '39061',
    '39062',
    '39063',
    '39066',
    '39067',
    '39069',
    '39071',
    '39073',
    '39074',
    '39078',
    '39079',
    '39082',
    '39083',
    '39086',
    '39088',
    '39090',
    '39092',
    '39094',
    '39095',
    '39096',
    '39097',
    '39108',
    '39110',
    '39111',
    '39113',
    '39114',
    '39115',
    '39116',
    '39117',
    '39119',
    '39120',
    '39140',
    '39144',
    '39145',
    '39146',
    '39149',
    '39150',
    '39152',
    '39153',
    '39154',
    '39156',
    '39157',
    '39159',
    '39160',
    '39162',
    '39166',
    '39167',
    '39168',
    '39169',
    '39170',
    '39174',
    '39175',
    '39176',
    '39177',
    '39179',
    '39180',
    '39183',
    '39189',
    '39191',
    '39192',
    '39193',
    '39194',
    '39201',
    '39202',
    '39203',
    '39204',
    '39206',
    '39208',
    '39209',
    '39211',
    '39212',
    '39213',
    '39216',
    '39217',
    '39218',
    '39232',
    '39269',
    '39272',
    '39301',
    '39305',
    '39307',
    '39309',
    '39320',
    '39322',
    '39323',
    '39325',
    '39326',
    '39327',
    '39328',
    '39330',
    '39332',
    '39335',
    '39336',
    '39337',
    '39338',
    '39339',
    '39341',
    '39342',
    '39345',
    '39346',
    '39347',
    '39348',
    '39350',
    '39352',
    '39354',
    '39355',
    '39356',
    '39358',
    '39359',
    '39360',
    '39361',
    '39362',
    '39363',
    '39364',
    '39365',
    '39366',
    '39367',
    '39401',
    '39402',
    '39406',
    '39421',
    '39422',
    '39423',
    '39425',
    '39426',
    '39427',
    '39428',
    '39429',
    '39437',
    '39439',
    '39440',
    '39443',
    '39451',
    '39452',
    '39455',
    '39456',
    '39459',
    '39461',
    '39462',
    '39464',
    '39465',
    '39466',
    '39470',
    '39474',
    '39475',
    '39476',
    '39477',
    '39478',
    '39479',
    '39480',
    '39481',
    '39482',
    '39483',
    '39501',
    '39503',
    '39507',
    '39520',
    '39525',
    '39530',
    '39531',
    '39532',
    '39534',
    '39540',
    '39553',
    '39556',
    '39560',
    '39561',
    '39562',
    '39563',
    '39564',
    '39565',
    '39567',
    '39571',
    '39572',
    '39573',
    '39574',
    '39576',
    '39577',
    '39581',
    '39601',
    '39629',
    '39630',
    '39631',
    '39633',
    '39635',
    '39638',
    '39641',
    '39643',
    '39645',
    '39647',
    '39648',
    '39652',
    '39653',
    '39654',
    '39656',
    '39657',
    '39661',
    '39662',
    '39663',
    '39664',
    '39665',
    '39666',
    '39667',
    '39668',
    '39669',
    '39701',
    '39702',
    '39705',
    '39730',
    '39735',
    '39736',
    '39737',
    '39739',
    '39740',
    '39741',
    '39743',
    '39744',
    '39745',
    '39746',
    '39747',
    '39750',
    '39751',
    '39752',
    '39755',
    '39756',
    '39759',
    '39760',
    '39762',
    '39766',
    '39767',
    '39769',
    '39771',
    '39772',
    '39773',
    '39776'
  ],
  '13': [
    '30002',
    '30004',
    '30005',
    '30008',
    '30009',
    '30011',
    '30012',
    '30013',
    '30014',
    '30016',
    '30017',
    '30019',
    '30021',
    '30022',
    '30024',
    '30025',
    '30028',
    '30030',
    '30032',
    '30033',
    '30034',
    '30035',
    '30038',
    '30039',
    '30040',
    '30041',
    '30043',
    '30044',
    '30045',
    '30046',
    '30047',
    '30052',
    '30054',
    '30055',
    '30056',
    '30058',
    '30060',
    '30062',
    '30064',
    '30066',
    '30067',
    '30068',
    '30070',
    '30071',
    '30072',
    '30075',
    '30076',
    '30078',
    '30079',
    '30080',
    '30082',
    '30083',
    '30084',
    '30087',
    '30088',
    '30092',
    '30093',
    '30094',
    '30096',
    '30097',
    '30101',
    '30102',
    '30103',
    '30104',
    '30105',
    '30106',
    '30107',
    '30108',
    '30110',
    '30113',
    '30114',
    '30115',
    '30116',
    '30117',
    '30118',
    '30120',
    '30121',
    '30122',
    '30124',
    '30125',
    '30126',
    '30127',
    '30132',
    '30134',
    '30135',
    '30137',
    '30139',
    '30141',
    '30143',
    '30144',
    '30145',
    '30147',
    '30148',
    '30149',
    '30152',
    '30153',
    '30157',
    '30161',
    '30164',
    '30165',
    '30168',
    '30170',
    '30171',
    '30173',
    '30175',
    '30176',
    '30177',
    '30178',
    '30179',
    '30180',
    '30182',
    '30183',
    '30184',
    '30185',
    '30187',
    '30188',
    '30189',
    '30204',
    '30205',
    '30206',
    '30213',
    '30214',
    '30215',
    '30216',
    '30217',
    '30218',
    '30220',
    '30222',
    '30223',
    '30224',
    '30228',
    '30230',
    '30233',
    '30234',
    '30236',
    '30238',
    '30240',
    '30241',
    '30248',
    '30250',
    '30251',
    '30252',
    '30253',
    '30256',
    '30257',
    '30258',
    '30259',
    '30260',
    '30263',
    '30265',
    '30268',
    '30269',
    '30273',
    '30274',
    '30275',
    '30276',
    '30277',
    '30281',
    '30284',
    '30285',
    '30286',
    '30288',
    '30289',
    '30290',
    '30291',
    '30292',
    '30293',
    '30294',
    '30295',
    '30296',
    '30297',
    '30303',
    '30305',
    '30306',
    '30307',
    '30308',
    '30309',
    '30310',
    '30311',
    '30312',
    '30313',
    '30314',
    '30315',
    '30316',
    '30317',
    '30318',
    '30319',
    '30322',
    '30324',
    '30326',
    '30327',
    '30328',
    '30329',
    '30331',
    '30332',
    '30334',
    '30336',
    '30337',
    '30338',
    '30339',
    '30340',
    '30341',
    '30342',
    '30344',
    '30345',
    '30346',
    '30349',
    '30350',
    '30354',
    '30360',
    '30363',
    '30401',
    '30410',
    '30411',
    '30412',
    '30413',
    '30415',
    '30417',
    '30420',
    '30421',
    '30423',
    '30425',
    '30426',
    '30427',
    '30428',
    '30429',
    '30434',
    '30436',
    '30438',
    '30439',
    '30441',
    '30442',
    '30445',
    '30446',
    '30448',
    '30449',
    '30450',
    '30451',
    '30452',
    '30453',
    '30454',
    '30455',
    '30456',
    '30457',
    '30458',
    '30460',
    '30461',
    '30464',
    '30467',
    '30470',
    '30471',
    '30473',
    '30474',
    '30475',
    '30477',
    '30501',
    '30504',
    '30506',
    '30507',
    '30510',
    '30511',
    '30512',
    '30513',
    '30516',
    '30517',
    '30518',
    '30519',
    '30520',
    '30521',
    '30522',
    '30523',
    '30525',
    '30527',
    '30528',
    '30529',
    '30530',
    '30531',
    '30533',
    '30534',
    '30535',
    '30536',
    '30537',
    '30538',
    '30540',
    '30541',
    '30542',
    '30543',
    '30545',
    '30546',
    '30547',
    '30548',
    '30549',
    '30552',
    '30553',
    '30554',
    '30555',
    '30557',
    '30558',
    '30559',
    '30560',
    '30562',
    '30563',
    '30564',
    '30565',
    '30566',
    '30567',
    '30568',
    '30571',
    '30572',
    '30573',
    '30575',
    '30576',
    '30577',
    '30581',
    '30582',
    '30601',
    '30602',
    '30605',
    '30606',
    '30607',
    '30609',
    '30619',
    '30620',
    '30621',
    '30622',
    '30623',
    '30624',
    '30625',
    '30627',
    '30628',
    '30629',
    '30630',
    '30631',
    '30633',
    '30634',
    '30635',
    '30641',
    '30642',
    '30643',
    '30646',
    '30648',
    '30650',
    '30655',
    '30656',
    '30660',
    '30662',
    '30663',
    '30664',
    '30665',
    '30666',
    '30667',
    '30668',
    '30669',
    '30673',
    '30677',
    '30678',
    '30680',
    '30683',
    '30701',
    '30705',
    '30707',
    '30708',
    '30710',
    '30711',
    '30720',
    '30721',
    '30725',
    '30726',
    '30728',
    '30730',
    '30731',
    '30733',
    '30734',
    '30735',
    '30736',
    '30738',
    '30739',
    '30740',
    '30741',
    '30742',
    '30746',
    '30747',
    '30750',
    '30751',
    '30752',
    '30753',
    '30755',
    '30756',
    '30757',
    '30802',
    '30803',
    '30805',
    '30807',
    '30808',
    '30809',
    '30810',
    '30812',
    '30813',
    '30814',
    '30815',
    '30816',
    '30817',
    '30818',
    '30820',
    '30821',
    '30822',
    '30823',
    '30824',
    '30828',
    '30830',
    '30833',
    '30901',
    '30903',
    '30904',
    '30905',
    '30906',
    '30907',
    '30909',
    '30912',
    '31001',
    '31002',
    '31003',
    '31005',
    '31006',
    '31007',
    '31008',
    '31009',
    '31011',
    '31012',
    '31014',
    '31015',
    '31016',
    '31017',
    '31018',
    '31019',
    '31020',
    '31021',
    '31022',
    '31023',
    '31024',
    '31025',
    '31027',
    '31028',
    '31029',
    '31030',
    '31031',
    '31032',
    '31033',
    '31035',
    '31036',
    '31037',
    '31038',
    '31039',
    '31041',
    '31042',
    '31044',
    '31045',
    '31046',
    '31047',
    '31049',
    '31050',
    '31051',
    '31052',
    '31054',
    '31055',
    '31057',
    '31058',
    '31060',
    '31061',
    '31062',
    '31063',
    '31064',
    '31065',
    '31066',
    '31067',
    '31068',
    '31069',
    '31070',
    '31071',
    '31072',
    '31075',
    '31076',
    '31077',
    '31078',
    '31079',
    '31081',
    '31082',
    '31083',
    '31084',
    '31085',
    '31087',
    '31088',
    '31089',
    '31090',
    '31091',
    '31092',
    '31093',
    '31094',
    '31096',
    '31097',
    '31098',
    '31201',
    '31204',
    '31206',
    '31207',
    '31210',
    '31211',
    '31213',
    '31216',
    '31217',
    '31220',
    '31301',
    '31302',
    '31303',
    '31304',
    '31305',
    '31307',
    '31308',
    '31309',
    '31312',
    '31313',
    '31314',
    '31315',
    '31316',
    '31318',
    '31320',
    '31321',
    '31322',
    '31323',
    '31324',
    '31326',
    '31327',
    '31328',
    '31329',
    '31331',
    '31401',
    '31404',
    '31405',
    '31406',
    '31407',
    '31408',
    '31409',
    '31410',
    '31411',
    '31415',
    '31419',
    '31501',
    '31503',
    '31510',
    '31512',
    '31513',
    '31516',
    '31518',
    '31519',
    '31520',
    '31522',
    '31523',
    '31524',
    '31525',
    '31527',
    '31532',
    '31533',
    '31535',
    '31537',
    '31539',
    '31542',
    '31543',
    '31544',
    '31545',
    '31546',
    '31547',
    '31548',
    '31549',
    '31550',
    '31551',
    '31552',
    '31553',
    '31554',
    '31555',
    '31556',
    '31557',
    '31558',
    '31560',
    '31561',
    '31562',
    '31563',
    '31565',
    '31566',
    '31567',
    '31568',
    '31569',
    '31601',
    '31602',
    '31605',
    '31606',
    '31620',
    '31622',
    '31623',
    '31624',
    '31625',
    '31626',
    '31627',
    '31629',
    '31630',
    '31631',
    '31632',
    '31634',
    '31635',
    '31636',
    '31637',
    '31638',
    '31639',
    '31641',
    '31642',
    '31643',
    '31645',
    '31647',
    '31648',
    '31649',
    '31650',
    '31698',
    '31699',
    '31701',
    '31705',
    '31707',
    '31709',
    '31711',
    '31712',
    '31714',
    '31716',
    '31719',
    '31720',
    '31721',
    '31722',
    '31730',
    '31733',
    '31735',
    '31738',
    '31743',
    '31744',
    '31747',
    '31749',
    '31750',
    '31756',
    '31757',
    '31763',
    '31764',
    '31765',
    '31768',
    '31771',
    '31772',
    '31773',
    '31774',
    '31775',
    '31778',
    '31779',
    '31780',
    '31781',
    '31783',
    '31784',
    '31787',
    '31788',
    '31789',
    '31790',
    '31791',
    '31792',
    '31793',
    '31794',
    '31795',
    '31796',
    '31798',
    '31801',
    '31803',
    '31804',
    '31805',
    '31806',
    '31807',
    '31808',
    '31810',
    '31811',
    '31812',
    '31814',
    '31815',
    '31816',
    '31820',
    '31821',
    '31822',
    '31823',
    '31824',
    '31825',
    '31826',
    '31827',
    '31829',
    '31830',
    '31831',
    '31832',
    '31833',
    '31836',
    '31901',
    '31903',
    '31904',
    '31905',
    '31906',
    '31907',
    '31909',
    '39813',
    '39815',
    '39817',
    '39819',
    '39823',
    '39824',
    '39825',
    '39826',
    '39827',
    '39828',
    '39834',
    '39836',
    '39837',
    '39840',
    '39841',
    '39842',
    '39845',
    '39846',
    '39851',
    '39854',
    '39859',
    '39861',
    '39862',
    '39866',
    '39867',
    '39870',
    '39877',
    '39885',
    '39886',
    '39897'
  ],
  '35': [
    '79837',
    '81137',
    '86504',
    '86515',
    '87001',
    '87002',
    '87004',
    '87005',
    '87006',
    '87007',
    '87008',
    '87010',
    '87011',
    '87012',
    '87013',
    '87014',
    '87015',
    '87016',
    '87017',
    '87018',
    '87020',
    '87021',
    '87022',
    '87023',
    '87024',
    '87025',
    '87026',
    '87027',
    '87028',
    '87029',
    '87031',
    '87032',
    '87034',
    '87035',
    '87036',
    '87037',
    '87038',
    '87040',
    '87041',
    '87042',
    '87043',
    '87044',
    '87045',
    '87046',
    '87047',
    '87048',
    '87049',
    '87051',
    '87052',
    '87053',
    '87056',
    '87059',
    '87061',
    '87062',
    '87063',
    '87064',
    '87068',
    '87070',
    '87072',
    '87083',
    '87102',
    '87104',
    '87105',
    '87106',
    '87107',
    '87108',
    '87109',
    '87110',
    '87111',
    '87112',
    '87113',
    '87114',
    '87116',
    '87117',
    '87120',
    '87121',
    '87122',
    '87123',
    '87124',
    '87144',
    '87301',
    '87305',
    '87310',
    '87311',
    '87312',
    '87313',
    '87315',
    '87316',
    '87317',
    '87319',
    '87320',
    '87321',
    '87322',
    '87323',
    '87325',
    '87326',
    '87327',
    '87328',
    '87347',
    '87357',
    '87364',
    '87375',
    '87401',
    '87402',
    '87410',
    '87412',
    '87413',
    '87415',
    '87416',
    '87417',
    '87418',
    '87419',
    '87420',
    '87421',
    '87455',
    '87461',
    '87499',
    '87501',
    '87505',
    '87506',
    '87507',
    '87508',
    '87510',
    '87511',
    '87512',
    '87513',
    '87514',
    '87515',
    '87516',
    '87517',
    '87518',
    '87519',
    '87520',
    '87521',
    '87522',
    '87523',
    '87524',
    '87525',
    '87527',
    '87528',
    '87529',
    '87530',
    '87531',
    '87532',
    '87533',
    '87535',
    '87537',
    '87538',
    '87539',
    '87540',
    '87543',
    '87544',
    '87548',
    '87549',
    '87551',
    '87552',
    '87553',
    '87554',
    '87556',
    '87557',
    '87558',
    '87560',
    '87562',
    '87564',
    '87565',
    '87566',
    '87567',
    '87569',
    '87571',
    '87573',
    '87574',
    '87575',
    '87577',
    '87578',
    '87579',
    '87580',
    '87581',
    '87582',
    '87583',
    '87701',
    '87710',
    '87711',
    '87712',
    '87713',
    '87714',
    '87715',
    '87718',
    '87722',
    '87723',
    '87724',
    '87728',
    '87729',
    '87730',
    '87731',
    '87732',
    '87733',
    '87734',
    '87735',
    '87736',
    '87740',
    '87742',
    '87743',
    '87745',
    '87746',
    '87747',
    '87749',
    '87750',
    '87752',
    '87753',
    '87801',
    '87820',
    '87821',
    '87823',
    '87824',
    '87825',
    '87827',
    '87828',
    '87829',
    '87830',
    '87831',
    '87832',
    '87901',
    '87930',
    '87931',
    '87933',
    '87935',
    '87936',
    '87937',
    '87939',
    '87940',
    '87941',
    '87942',
    '87943',
    '88001',
    '88002',
    '88003',
    '88004',
    '88005',
    '88007',
    '88008',
    '88009',
    '88011',
    '88012',
    '88020',
    '88021',
    '88022',
    '88023',
    '88024',
    '88025',
    '88026',
    '88027',
    '88028',
    '88029',
    '88030',
    '88032',
    '88033',
    '88034',
    '88038',
    '88039',
    '88040',
    '88041',
    '88042',
    '88043',
    '88044',
    '88045',
    '88046',
    '88047',
    '88048',
    '88049',
    '88051',
    '88052',
    '88053',
    '88055',
    '88056',
    '88061',
    '88063',
    '88065',
    '88072',
    '88081',
    '88101',
    '88103',
    '88112',
    '88113',
    '88114',
    '88115',
    '88116',
    '88118',
    '88119',
    '88120',
    '88121',
    '88124',
    '88125',
    '88126',
    '88130',
    '88132',
    '88134',
    '88135',
    '88136',
    '88201',
    '88203',
    '88210',
    '88220',
    '88230',
    '88231',
    '88232',
    '88240',
    '88242',
    '88250',
    '88252',
    '88253',
    '88254',
    '88255',
    '88256',
    '88260',
    '88262',
    '88263',
    '88264',
    '88265',
    '88267',
    '88268',
    '88301',
    '88310',
    '88311',
    '88312',
    '88314',
    '88316',
    '88317',
    '88318',
    '88321',
    '88323',
    '88324',
    '88325',
    '88330',
    '88336',
    '88337',
    '88338',
    '88339',
    '88340',
    '88341',
    '88342',
    '88343',
    '88344',
    '88345',
    '88346',
    '88347',
    '88348',
    '88349',
    '88350',
    '88351',
    '88352',
    '88353',
    '88354',
    '88355',
    '88401',
    '88410',
    '88411',
    '88414',
    '88415',
    '88416',
    '88417',
    '88418',
    '88419',
    '88421',
    '88422',
    '88424',
    '88426',
    '88427',
    '88430',
    '88431',
    '88434',
    '88435',
    '88436'
  ],
  '10': [
    '19701',
    '19702',
    '19703',
    '19706',
    '19707',
    '19709',
    '19710',
    '19711',
    '19713',
    '19716',
    '19717',
    '19720',
    '19730',
    '19731',
    '19732',
    '19733',
    '19734',
    '19735',
    '19736',
    '19801',
    '19802',
    '19803',
    '19804',
    '19805',
    '19806',
    '19807',
    '19808',
    '19809',
    '19810',
    '19901',
    '19902',
    '19904',
    '19930',
    '19931',
    '19933',
    '19934',
    '19936',
    '19938',
    '19939',
    '19940',
    '19941',
    '19943',
    '19944',
    '19945',
    '19946',
    '19947',
    '19950',
    '19951',
    '19952',
    '19953',
    '19954',
    '19955',
    '19956',
    '19958',
    '19960',
    '19962',
    '19963',
    '19964',
    '19966',
    '19967',
    '19968',
    '19970',
    '19971',
    '19973',
    '19975',
    '19977',
    '19979',
    '21912'
  ],
  '37': [
    '27006',
    '27007',
    '27009',
    '27011',
    '27012',
    '27013',
    '27014',
    '27016',
    '27017',
    '27018',
    '27019',
    '27020',
    '27021',
    '27022',
    '27023',
    '27024',
    '27025',
    '27027',
    '27028',
    '27030',
    '27040',
    '27041',
    '27042',
    '27043',
    '27045',
    '27046',
    '27047',
    '27048',
    '27050',
    '27051',
    '27052',
    '27053',
    '27054',
    '27055',
    '27101',
    '27103',
    '27104',
    '27105',
    '27106',
    '27107',
    '27109',
    '27110',
    '27127',
    '27201',
    '27203',
    '27205',
    '27207',
    '27208',
    '27209',
    '27212',
    '27214',
    '27215',
    '27217',
    '27229',
    '27231',
    '27233',
    '27235',
    '27239',
    '27242',
    '27243',
    '27244',
    '27248',
    '27249',
    '27252',
    '27253',
    '27258',
    '27260',
    '27262',
    '27263',
    '27265',
    '27278',
    '27281',
    '27282',
    '27283',
    '27284',
    '27288',
    '27291',
    '27292',
    '27295',
    '27298',
    '27299',
    '27301',
    '27302',
    '27305',
    '27306',
    '27310',
    '27311',
    '27312',
    '27313',
    '27314',
    '27315',
    '27316',
    '27317',
    '27320',
    '27325',
    '27326',
    '27330',
    '27332',
    '27340',
    '27341',
    '27343',
    '27344',
    '27349',
    '27350',
    '27355',
    '27356',
    '27357',
    '27358',
    '27360',
    '27370',
    '27371',
    '27376',
    '27377',
    '27379',
    '27401',
    '27403',
    '27405',
    '27406',
    '27407',
    '27408',
    '27409',
    '27410',
    '27455',
    '27501',
    '27502',
    '27503',
    '27504',
    '27505',
    '27507',
    '27508',
    '27509',
    '27510',
    '27511',
    '27513',
    '27514',
    '27516',
    '27517',
    '27518',
    '27519',
    '27520',
    '27521',
    '27522',
    '27523',
    '27524',
    '27525',
    '27526',
    '27527',
    '27529',
    '27530',
    '27531',
    '27534',
    '27536',
    '27537',
    '27539',
    '27540',
    '27541',
    '27542',
    '27544',
    '27545',
    '27546',
    '27549',
    '27551',
    '27553',
    '27555',
    '27556',
    '27557',
    '27559',
    '27560',
    '27562',
    '27563',
    '27565',
    '27568',
    '27569',
    '27571',
    '27572',
    '27573',
    '27574',
    '27576',
    '27577',
    '27581',
    '27582',
    '27583',
    '27587',
    '27589',
    '27591',
    '27592',
    '27596',
    '27597',
    '27601',
    '27603',
    '27604',
    '27605',
    '27606',
    '27607',
    '27608',
    '27609',
    '27610',
    '27612',
    '27613',
    '27614',
    '27615',
    '27616',
    '27617',
    '27701',
    '27703',
    '27704',
    '27705',
    '27707',
    '27709',
    '27712',
    '27713',
    '27801',
    '27803',
    '27804',
    '27805',
    '27806',
    '27807',
    '27808',
    '27809',
    '27810',
    '27812',
    '27813',
    '27814',
    '27816',
    '27817',
    '27818',
    '27819',
    '27820',
    '27821',
    '27822',
    '27823',
    '27824',
    '27825',
    '27826',
    '27827',
    '27828',
    '27829',
    '27830',
    '27831',
    '27832',
    '27834',
    '27837',
    '27839',
    '27840',
    '27841',
    '27842',
    '27843',
    '27844',
    '27845',
    '27846',
    '27847',
    '27849',
    '27850',
    '27851',
    '27852',
    '27853',
    '27855',
    '27856',
    '27857',
    '27858',
    '27860',
    '27861',
    '27862',
    '27863',
    '27864',
    '27865',
    '27866',
    '27869',
    '27870',
    '27871',
    '27872',
    '27873',
    '27874',
    '27875',
    '27876',
    '27878',
    '27879',
    '27880',
    '27881',
    '27882',
    '27883',
    '27884',
    '27885',
    '27886',
    '27888',
    '27889',
    '27890',
    '27891',
    '27892',
    '27893',
    '27896',
    '27897',
    '27909',
    '27910',
    '27915',
    '27916',
    '27917',
    '27919',
    '27920',
    '27921',
    '27922',
    '27923',
    '27924',
    '27925',
    '27926',
    '27927',
    '27928',
    '27929',
    '27932',
    '27935',
    '27936',
    '27937',
    '27938',
    '27939',
    '27941',
    '27942',
    '27943',
    '27944',
    '27946',
    '27947',
    '27948',
    '27949',
    '27950',
    '27953',
    '27954',
    '27956',
    '27957',
    '27958',
    '27959',
    '27960',
    '27962',
    '27964',
    '27965',
    '27966',
    '27967',
    '27968',
    '27970',
    '27972',
    '27973',
    '27974',
    '27976',
    '27978',
    '27979',
    '27980',
    '27981',
    '27982',
    '27983',
    '27985',
    '27986',
    '28001',
    '28006',
    '28007',
    '28009',
    '28012',
    '28016',
    '28017',
    '28018',
    '28019',
    '28020',
    '28021',
    '28023',
    '28025',
    '28027',
    '28031',
    '28032',
    '28033',
    '28034',
    '28036',
    '28037',
    '28039',
    '28040',
    '28043',
    '28052',
    '28054',
    '28056',
    '28071',
    '28072',
    '28073',
    '28075',
    '28076',
    '28077',
    '28078',
    '28079',
    '28080',
    '28081',
    '28083',
    '28086',
    '28088',
    '28089',
    '28090',
    '28091',
    '28092',
    '28097',
    '28098',
    '28101',
    '28102',
    '28103',
    '28104',
    '28105',
    '28107',
    '28108',
    '28109',
    '28110',
    '28112',
    '28114',
    '28115',
    '28117',
    '28119',
    '28120',
    '28124',
    '28125',
    '28127',
    '28128',
    '28129',
    '28133',
    '28134',
    '28135',
    '28137',
    '28138',
    '28139',
    '28144',
    '28146',
    '28147',
    '28150',
    '28152',
    '28159',
    '28160',
    '28163',
    '28164',
    '28166',
    '28167',
    '28168',
    '28169',
    '28170',
    '28173',
    '28174',
    '28202',
    '28203',
    '28204',
    '28205',
    '28206',
    '28207',
    '28208',
    '28209',
    '28210',
    '28211',
    '28212',
    '28213',
    '28214',
    '28215',
    '28216',
    '28217',
    '28226',
    '28227',
    '28244',
    '28262',
    '28269',
    '28270',
    '28273',
    '28277',
    '28278',
    '28280',
    '28282',
    '28301',
    '28303',
    '28304',
    '28305',
    '28306',
    '28307',
    '28308',
    '28310',
    '28311',
    '28312',
    '28314',
    '28315',
    '28318',
    '28320',
    '28323',
    '28325',
    '28326',
    '28327',
    '28328',
    '28330',
    '28332',
    '28333',
    '28334',
    '28337',
    '28338',
    '28339',
    '28340',
    '28341',
    '28342',
    '28343',
    '28344',
    '28345',
    '28347',
    '28348',
    '28349',
    '28350',
    '28351',
    '28352',
    '28356',
    '28357',
    '28358',
    '28360',
    '28363',
    '28364',
    '28365',
    '28366',
    '28367',
    '28369',
    '28371',
    '28372',
    '28373',
    '28374',
    '28375',
    '28376',
    '28377',
    '28379',
    '28382',
    '28383',
    '28384',
    '28385',
    '28386',
    '28387',
    '28390',
    '28391',
    '28392',
    '28393',
    '28394',
    '28395',
    '28396',
    '28398',
    '28399',
    '28401',
    '28403',
    '28405',
    '28409',
    '28411',
    '28412',
    '28420',
    '28421',
    '28422',
    '28423',
    '28424',
    '28425',
    '28428',
    '28429',
    '28430',
    '28431',
    '28432',
    '28433',
    '28434',
    '28435',
    '28436',
    '28438',
    '28439',
    '28441',
    '28442',
    '28443',
    '28444',
    '28445',
    '28447',
    '28448',
    '28449',
    '28450',
    '28451',
    '28452',
    '28453',
    '28454',
    '28455',
    '28456',
    '28457',
    '28458',
    '28460',
    '28461',
    '28462',
    '28463',
    '28464',
    '28465',
    '28466',
    '28467',
    '28468',
    '28469',
    '28470',
    '28472',
    '28478',
    '28479',
    '28480',
    '28501',
    '28504',
    '28508',
    '28510',
    '28511',
    '28512',
    '28513',
    '28515',
    '28516',
    '28518',
    '28519',
    '28520',
    '28521',
    '28523',
    '28524',
    '28525',
    '28526',
    '28527',
    '28528',
    '28529',
    '28530',
    '28531',
    '28532',
    '28533',
    '28537',
    '28538',
    '28539',
    '28540',
    '28543',
    '28544',
    '28546',
    '28547',
    '28551',
    '28552',
    '28553',
    '28554',
    '28555',
    '28556',
    '28557',
    '28560',
    '28562',
    '28570',
    '28571',
    '28572',
    '28573',
    '28574',
    '28575',
    '28577',
    '28578',
    '28579',
    '28580',
    '28581',
    '28582',
    '28583',
    '28584',
    '28585',
    '28586',
    '28587',
    '28589',
    '28590',
    '28594',
    '28601',
    '28602',
    '28604',
    '28605',
    '28606',
    '28607',
    '28609',
    '28610',
    '28611',
    '28612',
    '28613',
    '28615',
    '28616',
    '28617',
    '28618',
    '28619',
    '28621',
    '28622',
    '28623',
    '28624',
    '28625',
    '28626',
    '28627',
    '28628',
    '28629',
    '28630',
    '28631',
    '28634',
    '28635',
    '28636',
    '28637',
    '28638',
    '28640',
    '28642',
    '28643',
    '28644',
    '28645',
    '28646',
    '28647',
    '28649',
    '28650',
    '28651',
    '28652',
    '28654',
    '28655',
    '28657',
    '28658',
    '28659',
    '28660',
    '28662',
    '28663',
    '28665',
    '28666',
    '28667',
    '28668',
    '28669',
    '28670',
    '28671',
    '28672',
    '28673',
    '28675',
    '28676',
    '28677',
    '28678',
    '28679',
    '28681',
    '28682',
    '28683',
    '28684',
    '28685',
    '28689',
    '28690',
    '28692',
    '28693',
    '28694',
    '28697',
    '28698',
    '28701',
    '28702',
    '28704',
    '28705',
    '28707',
    '28708',
    '28709',
    '28711',
    '28712',
    '28713',
    '28714',
    '28715',
    '28716',
    '28717',
    '28718',
    '28719',
    '28720',
    '28721',
    '28722',
    '28723',
    '28725',
    '28726',
    '28729',
    '28730',
    '28731',
    '28732',
    '28733',
    '28734',
    '28735',
    '28736',
    '28739',
    '28740',
    '28741',
    '28742',
    '28743',
    '28745',
    '28746',
    '28747',
    '28748',
    '28749',
    '28751',
    '28752',
    '28753',
    '28754',
    '28756',
    '28757',
    '28759',
    '28761',
    '28762',
    '28763',
    '28766',
    '28768',
    '28771',
    '28772',
    '28773',
    '28774',
    '28775',
    '28777',
    '28778',
    '28779',
    '28781',
    '28782',
    '28783',
    '28785',
    '28786',
    '28787',
    '28789',
    '28790',
    '28791',
    '28792',
    '28801',
    '28803',
    '28804',
    '28805',
    '28806',
    '28901',
    '28902',
    '28904',
    '28905',
    '28906',
    '28909'
  ],
  '53': [
    '98001',
    '98002',
    '98003',
    '98004',
    '98005',
    '98006',
    '98007',
    '98008',
    '98010',
    '98011',
    '98012',
    '98014',
    '98019',
    '98020',
    '98021',
    '98022',
    '98023',
    '98024',
    '98026',
    '98027',
    '98028',
    '98029',
    '98030',
    '98031',
    '98032',
    '98033',
    '98034',
    '98036',
    '98037',
    '98038',
    '98039',
    '98040',
    '98042',
    '98043',
    '98045',
    '98047',
    '98050',
    '98051',
    '98052',
    '98053',
    '98055',
    '98056',
    '98057',
    '98058',
    '98059',
    '98065',
    '98068',
    '98070',
    '98072',
    '98074',
    '98075',
    '98077',
    '98087',
    '98092',
    '98101',
    '98102',
    '98103',
    '98104',
    '98105',
    '98106',
    '98107',
    '98108',
    '98109',
    '98110',
    '98112',
    '98115',
    '98116',
    '98117',
    '98118',
    '98119',
    '98121',
    '98122',
    '98125',
    '98126',
    '98133',
    '98134',
    '98136',
    '98144',
    '98146',
    '98148',
    '98154',
    '98155',
    '98158',
    '98164',
    '98166',
    '98168',
    '98174',
    '98177',
    '98178',
    '98188',
    '98195',
    '98198',
    '98199',
    '98201',
    '98203',
    '98204',
    '98205',
    '98207',
    '98208',
    '98220',
    '98221',
    '98222',
    '98223',
    '98224',
    '98225',
    '98226',
    '98229',
    '98230',
    '98232',
    '98233',
    '98235',
    '98236',
    '98237',
    '98238',
    '98239',
    '98240',
    '98241',
    '98243',
    '98244',
    '98245',
    '98247',
    '98248',
    '98249',
    '98250',
    '98251',
    '98252',
    '98253',
    '98255',
    '98256',
    '98257',
    '98258',
    '98260',
    '98261',
    '98262',
    '98263',
    '98264',
    '98266',
    '98267',
    '98270',
    '98271',
    '98272',
    '98273',
    '98274',
    '98275',
    '98276',
    '98277',
    '98278',
    '98279',
    '98280',
    '98281',
    '98282',
    '98283',
    '98284',
    '98286',
    '98288',
    '98290',
    '98292',
    '98294',
    '98295',
    '98296',
    '98297',
    '98303',
    '98304',
    '98305',
    '98310',
    '98311',
    '98312',
    '98314',
    '98315',
    '98320',
    '98321',
    '98323',
    '98325',
    '98326',
    '98327',
    '98328',
    '98329',
    '98330',
    '98331',
    '98332',
    '98333',
    '98335',
    '98336',
    '98337',
    '98338',
    '98339',
    '98340',
    '98342',
    '98345',
    '98346',
    '98349',
    '98350',
    '98351',
    '98353',
    '98354',
    '98355',
    '98356',
    '98357',
    '98358',
    '98359',
    '98360',
    '98361',
    '98362',
    '98363',
    '98364',
    '98365',
    '98366',
    '98367',
    '98368',
    '98370',
    '98371',
    '98372',
    '98373',
    '98374',
    '98375',
    '98376',
    '98377',
    '98380',
    '98381',
    '98382',
    '98383',
    '98385',
    '98387',
    '98388',
    '98390',
    '98391',
    '98392',
    '98394',
    '98396',
    '98402',
    '98403',
    '98404',
    '98405',
    '98406',
    '98407',
    '98408',
    '98409',
    '98416',
    '98418',
    '98421',
    '98422',
    '98424',
    '98430',
    '98433',
    '98438',
    '98439',
    '98443',
    '98444',
    '98445',
    '98446',
    '98447',
    '98465',
    '98466',
    '98467',
    '98498',
    '98499',
    '98501',
    '98502',
    '98503',
    '98506',
    '98512',
    '98513',
    '98516',
    '98520',
    '98524',
    '98526',
    '98527',
    '98528',
    '98530',
    '98531',
    '98532',
    '98533',
    '98535',
    '98536',
    '98537',
    '98538',
    '98541',
    '98542',
    '98544',
    '98546',
    '98547',
    '98548',
    '98550',
    '98552',
    '98555',
    '98557',
    '98558',
    '98559',
    '98560',
    '98562',
    '98563',
    '98564',
    '98565',
    '98568',
    '98569',
    '98570',
    '98571',
    '98572',
    '98575',
    '98576',
    '98577',
    '98579',
    '98580',
    '98581',
    '98582',
    '98583',
    '98584',
    '98585',
    '98586',
    '98587',
    '98588',
    '98589',
    '98590',
    '98591',
    '98592',
    '98593',
    '98595',
    '98596',
    '98597',
    '98601',
    '98602',
    '98603',
    '98604',
    '98605',
    '98606',
    '98607',
    '98610',
    '98611',
    '98612',
    '98613',
    '98614',
    '98616',
    '98617',
    '98619',
    '98620',
    '98621',
    '98624',
    '98625',
    '98626',
    '98628',
    '98629',
    '98631',
    '98632',
    '98635',
    '98638',
    '98639',
    '98640',
    '98641',
    '98642',
    '98643',
    '98644',
    '98645',
    '98647',
    '98648',
    '98649',
    '98650',
    '98651',
    '98660',
    '98661',
    '98662',
    '98663',
    '98664',
    '98665',
    '98670',
    '98671',
    '98672',
    '98673',
    '98674',
    '98675',
    '98682',
    '98683',
    '98684',
    '98685',
    '98686',
    '98801',
    '98802',
    '98811',
    '98812',
    '98813',
    '98814',
    '98815',
    '98816',
    '98817',
    '98819',
    '98821',
    '98822',
    '98823',
    '98824',
    '98826',
    '98827',
    '98828',
    '98829',
    '98830',
    '98831',
    '98832',
    '98833',
    '98834',
    '98836',
    '98837',
    '98840',
    '98841',
    '98843',
    '98844',
    '98845',
    '98846',
    '98847',
    '98848',
    '98849',
    '98850',
    '98851',
    '98852',
    '98853',
    '98855',
    '98856',
    '98857',
    '98858',
    '98859',
    '98860',
    '98862',
    '98901',
    '98902',
    '98903',
    '98908',
    '98921',
    '98922',
    '98923',
    '98925',
    '98926',
    '98929',
    '98930',
    '98932',
    '98933',
    '98934',
    '98935',
    '98936',
    '98937',
    '98938',
    '98939',
    '98940',
    '98941',
    '98942',
    '98943',
    '98944',
    '98946',
    '98947',
    '98948',
    '98950',
    '98951',
    '98952',
    '98953',
    '99001',
    '99003',
    '99004',
    '99005',
    '99006',
    '99008',
    '99009',
    '99011',
    '99012',
    '99013',
    '99016',
    '99017',
    '99018',
    '99019',
    '99020',
    '99021',
    '99022',
    '99023',
    '99025',
    '99026',
    '99027',
    '99029',
    '99030',
    '99031',
    '99032',
    '99033',
    '99034',
    '99036',
    '99037',
    '99039',
    '99040',
    '99101',
    '99102',
    '99103',
    '99105',
    '99109',
    '99110',
    '99111',
    '99113',
    '99114',
    '99115',
    '99116',
    '99117',
    '99118',
    '99119',
    '99121',
    '99122',
    '99123',
    '99124',
    '99125',
    '99126',
    '99128',
    '99129',
    '99130',
    '99131',
    '99133',
    '99134',
    '99135',
    '99136',
    '99137',
    '99138',
    '99139',
    '99140',
    '99141',
    '99143',
    '99144',
    '99146',
    '99147',
    '99148',
    '99149',
    '99150',
    '99151',
    '99152',
    '99153',
    '99154',
    '99155',
    '99156',
    '99157',
    '99158',
    '99159',
    '99160',
    '99161',
    '99163',
    '99164',
    '99166',
    '99167',
    '99169',
    '99170',
    '99171',
    '99173',
    '99174',
    '99176',
    '99179',
    '99180',
    '99181',
    '99185',
    '99201',
    '99202',
    '99203',
    '99204',
    '99205',
    '99206',
    '99207',
    '99208',
    '99212',
    '99216',
    '99217',
    '99218',
    '99223',
    '99224',
    '99301',
    '99320',
    '99321',
    '99322',
    '99323',
    '99324',
    '99326',
    '99328',
    '99329',
    '99330',
    '99333',
    '99335',
    '99336',
    '99337',
    '99338',
    '99341',
    '99343',
    '99344',
    '99345',
    '99346',
    '99347',
    '99348',
    '99349',
    '99350',
    '99352',
    '99353',
    '99354',
    '99356',
    '99357',
    '99359',
    '99360',
    '99361',
    '99362',
    '99363',
    '99371',
    '99401',
    '99402',
    '99403'
  ],
  '15': [
    '96701',
    '96703',
    '96704',
    '96705',
    '96706',
    '96707',
    '96708',
    '96710',
    '96712',
    '96713',
    '96714',
    '96716',
    '96717',
    '96719',
    '96720',
    '96722',
    '96725',
    '96726',
    '96727',
    '96728',
    '96729',
    '96730',
    '96731',
    '96732',
    '96734',
    '96737',
    '96738',
    '96740',
    '96741',
    '96742',
    '96743',
    '96744',
    '96746',
    '96747',
    '96748',
    '96749',
    '96750',
    '96751',
    '96752',
    '96753',
    '96754',
    '96755',
    '96756',
    '96757',
    '96759',
    '96760',
    '96761',
    '96762',
    '96763',
    '96764',
    '96765',
    '96766',
    '96768',
    '96769',
    '96770',
    '96771',
    '96772',
    '96773',
    '96774',
    '96776',
    '96777',
    '96778',
    '96779',
    '96780',
    '96781',
    '96782',
    '96783',
    '96785',
    '96786',
    '96789',
    '96790',
    '96791',
    '96792',
    '96793',
    '96795',
    '96796',
    '96797',
    '96813',
    '96814',
    '96815',
    '96816',
    '96817',
    '96818',
    '96819',
    '96821',
    '96822',
    '96825',
    '96826',
    '96850',
    '96853',
    '96857',
    '96859',
    '96860',
    '96863'
  ],
  '23': [
    '03579',
    '03901',
    '03902',
    '03903',
    '03904',
    '03905',
    '03906',
    '03907',
    '03908',
    '03909',
    '03910',
    '03911',
    '04001',
    '04002',
    '04003',
    '04005',
    '04006',
    '04008',
    '04009',
    '04010',
    '04011',
    '04015',
    '04017',
    '04019',
    '04020',
    '04021',
    '04022',
    '04024',
    '04027',
    '04029',
    '04030',
    '04032',
    '04037',
    '04038',
    '04039',
    '04040',
    '04041',
    '04042',
    '04043',
    '04046',
    '04047',
    '04048',
    '04049',
    '04050',
    '04051',
    '04055',
    '04056',
    '04057',
    '04061',
    '04062',
    '04063',
    '04064',
    '04066',
    '04068',
    '04069',
    '04071',
    '04072',
    '04073',
    '04074',
    '04076',
    '04079',
    '04083',
    '04084',
    '04085',
    '04086',
    '04087',
    '04088',
    '04090',
    '04091',
    '04092',
    '04093',
    '04095',
    '04096',
    '04097',
    '04101',
    '04102',
    '04103',
    '04105',
    '04106',
    '04107',
    '04108',
    '04109',
    '04110',
    '04210',
    '04216',
    '04217',
    '04219',
    '04220',
    '04221',
    '04222',
    '04224',
    '04226',
    '04227',
    '04228',
    '04231',
    '04234',
    '04236',
    '04237',
    '04238',
    '04239',
    '04240',
    '04250',
    '04252',
    '04253',
    '04254',
    '04255',
    '04256',
    '04257',
    '04258',
    '04259',
    '04260',
    '04261',
    '04263',
    '04265',
    '04267',
    '04268',
    '04270',
    '04271',
    '04274',
    '04275',
    '04276',
    '04280',
    '04281',
    '04282',
    '04284',
    '04285',
    '04286',
    '04287',
    '04289',
    '04290',
    '04292',
    '04294',
    '04330',
    '04342',
    '04343',
    '04344',
    '04345',
    '04346',
    '04347',
    '04348',
    '04349',
    '04350',
    '04351',
    '04352',
    '04353',
    '04354',
    '04355',
    '04357',
    '04358',
    '04359',
    '04360',
    '04363',
    '04364',
    '04401',
    '04406',
    '04408',
    '04410',
    '04411',
    '04412',
    '04413',
    '04414',
    '04415',
    '04416',
    '04417',
    '04418',
    '04419',
    '04421',
    '04422',
    '04424',
    '04426',
    '04427',
    '04428',
    '04429',
    '04430',
    '04431',
    '04434',
    '04435',
    '04438',
    '04441',
    '04442',
    '04443',
    '04444',
    '04448',
    '04449',
    '04450',
    '04451',
    '04453',
    '04454',
    '04455',
    '04456',
    '04457',
    '04459',
    '04460',
    '04461',
    '04462',
    '04463',
    '04464',
    '04468',
    '04469',
    '04471',
    '04472',
    '04473',
    '04474',
    '04475',
    '04476',
    '04478',
    '04479',
    '04481',
    '04485',
    '04487',
    '04488',
    '04489',
    '04490',
    '04491',
    '04492',
    '04493',
    '04495',
    '04496',
    '04497',
    '04530',
    '04535',
    '04537',
    '04538',
    '04539',
    '04541',
    '04543',
    '04544',
    '04547',
    '04548',
    '04551',
    '04553',
    '04554',
    '04555',
    '04556',
    '04558',
    '04562',
    '04563',
    '04564',
    '04568',
    '04570',
    '04571',
    '04572',
    '04573',
    '04574',
    '04575',
    '04576',
    '04578',
    '04579',
    '04605',
    '04606',
    '04607',
    '04609',
    '04611',
    '04612',
    '04613',
    '04614',
    '04616',
    '04617',
    '04619',
    '04622',
    '04623',
    '04624',
    '04625',
    '04626',
    '04627',
    '04628',
    '04629',
    '04630',
    '04631',
    '04634',
    '04635',
    '04637',
    '04640',
    '04642',
    '04643',
    '04644',
    '04645',
    '04646',
    '04648',
    '04649',
    '04650',
    '04652',
    '04653',
    '04654',
    '04655',
    '04657',
    '04658',
    '04660',
    '04662',
    '04664',
    '04666',
    '04667',
    '04668',
    '04669',
    '04671',
    '04673',
    '04674',
    '04675',
    '04676',
    '04677',
    '04679',
    '04680',
    '04681',
    '04683',
    '04684',
    '04685',
    '04686',
    '04691',
    '04693',
    '04694',
    '04730',
    '04732',
    '04733',
    '04734',
    '04735',
    '04736',
    '04739',
    '04740',
    '04741',
    '04742',
    '04743',
    '04745',
    '04746',
    '04747',
    '04750',
    '04756',
    '04757',
    '04758',
    '04760',
    '04761',
    '04762',
    '04763',
    '04764',
    '04765',
    '04766',
    '04768',
    '04769',
    '04772',
    '04773',
    '04774',
    '04776',
    '04777',
    '04779',
    '04780',
    '04781',
    '04783',
    '04785',
    '04786',
    '04787',
    '04841',
    '04843',
    '04847',
    '04848',
    '04849',
    '04851',
    '04852',
    '04853',
    '04854',
    '04855',
    '04856',
    '04858',
    '04859',
    '04860',
    '04861',
    '04862',
    '04863',
    '04864',
    '04901',
    '04910',
    '04911',
    '04912',
    '04915',
    '04917',
    '04918',
    '04920',
    '04921',
    '04922',
    '04923',
    '04924',
    '04925',
    '04926',
    '04927',
    '04928',
    '04929',
    '04930',
    '04932',
    '04933',
    '04936',
    '04937',
    '04938',
    '04939',
    '04940',
    '04941',
    '04942',
    '04943',
    '04944',
    '04945',
    '04947',
    '04949',
    '04950',
    '04951',
    '04952',
    '04953',
    '04955',
    '04956',
    '04957',
    '04958',
    '04961',
    '04962',
    '04963',
    '04964',
    '04965',
    '04966',
    '04967',
    '04969',
    '04970',
    '04971',
    '04973',
    '04974',
    '04975',
    '04976',
    '04978',
    '04979',
    '04981',
    '04982',
    '04983',
    '04984',
    '04985',
    '04986',
    '04987',
    '04988',
    '04989',
    '04992'
  ],
  '49': [
    '81324',
    '82930',
    '83312',
    '83342',
    '84001',
    '84002',
    '84003',
    '84004',
    '84005',
    '84006',
    '84007',
    '84009',
    '84010',
    '84013',
    '84014',
    '84015',
    '84017',
    '84018',
    '84020',
    '84021',
    '84022',
    '84023',
    '84024',
    '84025',
    '84026',
    '84027',
    '84028',
    '84029',
    '84031',
    '84032',
    '84033',
    '84034',
    '84035',
    '84036',
    '84037',
    '84038',
    '84039',
    '84040',
    '84041',
    '84042',
    '84043',
    '84044',
    '84045',
    '84046',
    '84047',
    '84049',
    '84050',
    '84051',
    '84052',
    '84053',
    '84054',
    '84055',
    '84056',
    '84057',
    '84058',
    '84060',
    '84061',
    '84062',
    '84063',
    '84064',
    '84065',
    '84066',
    '84067',
    '84069',
    '84070',
    '84071',
    '84072',
    '84073',
    '84074',
    '84075',
    '84076',
    '84078',
    '84080',
    '84081',
    '84082',
    '84083',
    '84084',
    '84085',
    '84086',
    '84087',
    '84088',
    '84092',
    '84093',
    '84094',
    '84095',
    '84096',
    '84097',
    '84098',
    '84101',
    '84102',
    '84103',
    '84104',
    '84105',
    '84106',
    '84107',
    '84108',
    '84109',
    '84111',
    '84112',
    '84113',
    '84115',
    '84116',
    '84117',
    '84118',
    '84119',
    '84120',
    '84121',
    '84123',
    '84124',
    '84128',
    '84144',
    '84180',
    '84301',
    '84302',
    '84304',
    '84305',
    '84306',
    '84307',
    '84308',
    '84309',
    '84310',
    '84311',
    '84312',
    '84313',
    '84314',
    '84315',
    '84316',
    '84317',
    '84318',
    '84319',
    '84320',
    '84321',
    '84324',
    '84325',
    '84326',
    '84327',
    '84328',
    '84329',
    '84330',
    '84331',
    '84332',
    '84333',
    '84334',
    '84335',
    '84336',
    '84337',
    '84338',
    '84339',
    '84340',
    '84341',
    '84401',
    '84403',
    '84404',
    '84405',
    '84414',
    '84501',
    '84511',
    '84512',
    '84513',
    '84515',
    '84516',
    '84518',
    '84520',
    '84521',
    '84522',
    '84523',
    '84525',
    '84526',
    '84528',
    '84529',
    '84530',
    '84531',
    '84532',
    '84533',
    '84534',
    '84535',
    '84536',
    '84537',
    '84539',
    '84540',
    '84542',
    '84601',
    '84604',
    '84606',
    '84620',
    '84621',
    '84622',
    '84623',
    '84624',
    '84626',
    '84627',
    '84628',
    '84629',
    '84630',
    '84631',
    '84632',
    '84633',
    '84634',
    '84635',
    '84636',
    '84637',
    '84638',
    '84639',
    '84640',
    '84642',
    '84643',
    '84645',
    '84646',
    '84647',
    '84648',
    '84649',
    '84651',
    '84652',
    '84653',
    '84654',
    '84655',
    '84656',
    '84657',
    '84660',
    '84662',
    '84663',
    '84664',
    '84665',
    '84667',
    '84701',
    '84710',
    '84711',
    '84712',
    '84713',
    '84714',
    '84715',
    '84716',
    '84718',
    '84719',
    '84720',
    '84721',
    '84722',
    '84723',
    '84724',
    '84725',
    '84726',
    '84728',
    '84729',
    '84730',
    '84731',
    '84732',
    '84733',
    '84734',
    '84735',
    '84736',
    '84737',
    '84738',
    '84739',
    '84740',
    '84741',
    '84742',
    '84743',
    '84744',
    '84745',
    '84746',
    '84747',
    '84749',
    '84750',
    '84751',
    '84752',
    '84753',
    '84754',
    '84755',
    '84756',
    '84757',
    '84758',
    '84759',
    '84760',
    '84761',
    '84762',
    '84763',
    '84764',
    '84765',
    '84766',
    '84767',
    '84770',
    '84772',
    '84773',
    '84774',
    '84775',
    '84776',
    '84779',
    '84780',
    '84781',
    '84782',
    '84783',
    '84784',
    '84790',
    '86044',
    '86514'
  ],
  '16': [
    '59847',
    '83111',
    '83120',
    '83201',
    '83202',
    '83203',
    '83204',
    '83209',
    '83210',
    '83211',
    '83212',
    '83213',
    '83214',
    '83215',
    '83217',
    '83218',
    '83220',
    '83221',
    '83223',
    '83226',
    '83227',
    '83228',
    '83232',
    '83233',
    '83234',
    '83235',
    '83236',
    '83237',
    '83238',
    '83239',
    '83241',
    '83243',
    '83244',
    '83245',
    '83246',
    '83250',
    '83251',
    '83252',
    '83253',
    '83254',
    '83255',
    '83261',
    '83262',
    '83263',
    '83271',
    '83272',
    '83274',
    '83276',
    '83277',
    '83278',
    '83281',
    '83283',
    '83285',
    '83286',
    '83287',
    '83301',
    '83302',
    '83311',
    '83312',
    '83313',
    '83314',
    '83316',
    '83318',
    '83320',
    '83321',
    '83322',
    '83323',
    '83324',
    '83325',
    '83327',
    '83328',
    '83330',
    '83332',
    '83333',
    '83334',
    '83335',
    '83336',
    '83337',
    '83338',
    '83340',
    '83341',
    '83342',
    '83344',
    '83346',
    '83347',
    '83348',
    '83349',
    '83350',
    '83352',
    '83353',
    '83354',
    '83355',
    '83401',
    '83402',
    '83404',
    '83406',
    '83420',
    '83421',
    '83422',
    '83423',
    '83424',
    '83425',
    '83427',
    '83428',
    '83429',
    '83431',
    '83433',
    '83434',
    '83435',
    '83436',
    '83440',
    '83442',
    '83443',
    '83444',
    '83445',
    '83446',
    '83448',
    '83449',
    '83450',
    '83451',
    '83452',
    '83454',
    '83455',
    '83460',
    '83462',
    '83463',
    '83464',
    '83465',
    '83466',
    '83467',
    '83468',
    '83469',
    '83501',
    '83520',
    '83522',
    '83523',
    '83524',
    '83525',
    '83526',
    '83530',
    '83533',
    '83535',
    '83536',
    '83537',
    '83539',
    '83540',
    '83541',
    '83542',
    '83543',
    '83544',
    '83545',
    '83546',
    '83547',
    '83548',
    '83549',
    '83552',
    '83553',
    '83554',
    '83555',
    '83601',
    '83602',
    '83604',
    '83605',
    '83607',
    '83610',
    '83611',
    '83612',
    '83615',
    '83616',
    '83617',
    '83619',
    '83622',
    '83623',
    '83624',
    '83626',
    '83627',
    '83628',
    '83629',
    '83631',
    '83632',
    '83633',
    '83634',
    '83636',
    '83637',
    '83638',
    '83639',
    '83641',
    '83642',
    '83643',
    '83644',
    '83645',
    '83646',
    '83647',
    '83648',
    '83650',
    '83651',
    '83654',
    '83655',
    '83656',
    '83657',
    '83660',
    '83661',
    '83666',
    '83669',
    '83670',
    '83671',
    '83672',
    '83676',
    '83677',
    '83686',
    '83687',
    '83702',
    '83703',
    '83704',
    '83705',
    '83706',
    '83709',
    '83712',
    '83713',
    '83714',
    '83716',
    '83801',
    '83802',
    '83803',
    '83804',
    '83805',
    '83806',
    '83808',
    '83809',
    '83810',
    '83811',
    '83812',
    '83813',
    '83814',
    '83815',
    '83821',
    '83822',
    '83823',
    '83824',
    '83825',
    '83826',
    '83827',
    '83830',
    '83832',
    '83833',
    '83834',
    '83835',
    '83836',
    '83837',
    '83839',
    '83840',
    '83841',
    '83842',
    '83843',
    '83844',
    '83845',
    '83846',
    '83847',
    '83848',
    '83849',
    '83850',
    '83851',
    '83852',
    '83854',
    '83855',
    '83856',
    '83857',
    '83858',
    '83860',
    '83861',
    '83864',
    '83866',
    '83867',
    '83868',
    '83869',
    '83870',
    '83871',
    '83872',
    '83873',
    '83874',
    '83876',
    '89832',
    '97910',
    '97913',
    '99128'
  ],
  '22': [
    '70001',
    '70002',
    '70003',
    '70005',
    '70006',
    '70030',
    '70031',
    '70032',
    '70036',
    '70037',
    '70038',
    '70039',
    '70040',
    '70041',
    '70043',
    '70047',
    '70049',
    '70050',
    '70051',
    '70052',
    '70053',
    '70056',
    '70057',
    '70058',
    '70062',
    '70065',
    '70067',
    '70068',
    '70070',
    '70071',
    '70072',
    '70075',
    '70076',
    '70079',
    '70080',
    '70082',
    '70083',
    '70084',
    '70085',
    '70086',
    '70087',
    '70090',
    '70091',
    '70092',
    '70094',
    '70112',
    '70113',
    '70114',
    '70115',
    '70116',
    '70117',
    '70118',
    '70119',
    '70121',
    '70122',
    '70123',
    '70124',
    '70125',
    '70126',
    '70127',
    '70128',
    '70129',
    '70130',
    '70131',
    '70139',
    '70163',
    '70301',
    '70339',
    '70340',
    '70341',
    '70342',
    '70343',
    '70344',
    '70345',
    '70346',
    '70352',
    '70353',
    '70354',
    '70355',
    '70356',
    '70357',
    '70358',
    '70359',
    '70360',
    '70363',
    '70364',
    '70372',
    '70373',
    '70374',
    '70375',
    '70377',
    '70380',
    '70390',
    '70391',
    '70392',
    '70393',
    '70394',
    '70395',
    '70397',
    '70401',
    '70402',
    '70403',
    '70420',
    '70422',
    '70426',
    '70427',
    '70431',
    '70433',
    '70435',
    '70436',
    '70437',
    '70438',
    '70441',
    '70442',
    '70443',
    '70444',
    '70445',
    '70446',
    '70447',
    '70448',
    '70449',
    '70450',
    '70451',
    '70452',
    '70453',
    '70454',
    '70455',
    '70456',
    '70458',
    '70460',
    '70461',
    '70462',
    '70463',
    '70464',
    '70465',
    '70466',
    '70471',
    '70501',
    '70503',
    '70506',
    '70507',
    '70508',
    '70510',
    '70512',
    '70513',
    '70514',
    '70515',
    '70516',
    '70517',
    '70518',
    '70519',
    '70520',
    '70523',
    '70524',
    '70525',
    '70526',
    '70528',
    '70529',
    '70531',
    '70532',
    '70533',
    '70534',
    '70535',
    '70537',
    '70538',
    '70541',
    '70542',
    '70543',
    '70544',
    '70546',
    '70548',
    '70549',
    '70550',
    '70552',
    '70554',
    '70555',
    '70556',
    '70558',
    '70559',
    '70560',
    '70563',
    '70570',
    '70575',
    '70576',
    '70577',
    '70578',
    '70580',
    '70581',
    '70582',
    '70583',
    '70584',
    '70585',
    '70586',
    '70589',
    '70591',
    '70592',
    '70601',
    '70605',
    '70607',
    '70611',
    '70615',
    '70630',
    '70631',
    '70632',
    '70633',
    '70634',
    '70637',
    '70638',
    '70639',
    '70640',
    '70643',
    '70644',
    '70645',
    '70646',
    '70647',
    '70648',
    '70650',
    '70651',
    '70652',
    '70653',
    '70654',
    '70655',
    '70656',
    '70657',
    '70658',
    '70659',
    '70660',
    '70661',
    '70662',
    '70663',
    '70665',
    '70668',
    '70669',
    '70706',
    '70710',
    '70711',
    '70712',
    '70714',
    '70715',
    '70719',
    '70721',
    '70722',
    '70723',
    '70725',
    '70726',
    '70729',
    '70730',
    '70732',
    '70733',
    '70734',
    '70736',
    '70737',
    '70739',
    '70740',
    '70743',
    '70744',
    '70747',
    '70748',
    '70749',
    '70750',
    '70752',
    '70753',
    '70754',
    '70755',
    '70756',
    '70757',
    '70759',
    '70760',
    '70761',
    '70762',
    '70763',
    '70764',
    '70767',
    '70769',
    '70770',
    '70772',
    '70773',
    '70774',
    '70775',
    '70776',
    '70777',
    '70778',
    '70780',
    '70782',
    '70783',
    '70785',
    '70787',
    '70788',
    '70789',
    '70791',
    '70801',
    '70802',
    '70803',
    '70805',
    '70806',
    '70807',
    '70808',
    '70809',
    '70810',
    '70811',
    '70812',
    '70814',
    '70815',
    '70816',
    '70817',
    '70818',
    '70819',
    '70820',
    '70836',
    '71001',
    '71003',
    '71004',
    '71006',
    '71007',
    '71008',
    '71016',
    '71018',
    '71019',
    '71021',
    '71023',
    '71024',
    '71027',
    '71028',
    '71029',
    '71030',
    '71031',
    '71032',
    '71033',
    '71034',
    '71037',
    '71038',
    '71039',
    '71040',
    '71043',
    '71044',
    '71045',
    '71046',
    '71047',
    '71048',
    '71049',
    '71051',
    '71052',
    '71055',
    '71060',
    '71061',
    '71063',
    '71064',
    '71065',
    '71067',
    '71068',
    '71069',
    '71070',
    '71071',
    '71072',
    '71073',
    '71075',
    '71078',
    '71079',
    '71082',
    '71101',
    '71103',
    '71104',
    '71105',
    '71106',
    '71107',
    '71108',
    '71109',
    '71110',
    '71111',
    '71112',
    '71115',
    '71118',
    '71119',
    '71129',
    '71201',
    '71202',
    '71203',
    '71209',
    '71219',
    '71220',
    '71222',
    '71223',
    '71225',
    '71226',
    '71227',
    '71229',
    '71232',
    '71233',
    '71234',
    '71235',
    '71237',
    '71238',
    '71241',
    '71243',
    '71245',
    '71247',
    '71250',
    '71251',
    '71253',
    '71254',
    '71256',
    '71259',
    '71260',
    '71261',
    '71263',
    '71264',
    '71266',
    '71268',
    '71269',
    '71270',
    '71272',
    '71275',
    '71276',
    '71277',
    '71279',
    '71280',
    '71282',
    '71286',
    '71291',
    '71292',
    '71295',
    '71301',
    '71302',
    '71303',
    '71316',
    '71322',
    '71323',
    '71325',
    '71326',
    '71327',
    '71328',
    '71331',
    '71333',
    '71334',
    '71336',
    '71339',
    '71340',
    '71341',
    '71342',
    '71343',
    '71345',
    '71346',
    '71350',
    '71351',
    '71353',
    '71354',
    '71355',
    '71356',
    '71357',
    '71358',
    '71360',
    '71362',
    '71366',
    '71367',
    '71368',
    '71369',
    '71371',
    '71373',
    '71375',
    '71377',
    '71378',
    '71401',
    '71403',
    '71404',
    '71405',
    '71406',
    '71407',
    '71409',
    '71410',
    '71411',
    '71414',
    '71416',
    '71417',
    '71418',
    '71419',
    '71422',
    '71423',
    '71424',
    '71425',
    '71426',
    '71427',
    '71429',
    '71430',
    '71432',
    '71433',
    '71435',
    '71438',
    '71439',
    '71441',
    '71446',
    '71447',
    '71449',
    '71450',
    '71452',
    '71454',
    '71455',
    '71456',
    '71457',
    '71459',
    '71461',
    '71462',
    '71463',
    '71465',
    '71466',
    '71467',
    '71468',
    '71469',
    '71472',
    '71473',
    '71474',
    '71479',
    '71480',
    '71483',
    '71485',
    '71486',
    '71749'
  ],
  '08': [
    '80002',
    '80003',
    '80004',
    '80005',
    '80007',
    '80010',
    '80011',
    '80012',
    '80013',
    '80014',
    '80015',
    '80016',
    '80017',
    '80018',
    '80019',
    '80020',
    '80021',
    '80022',
    '80023',
    '80024',
    '80025',
    '80026',
    '80027',
    '80030',
    '80031',
    '80033',
    '80045',
    '80101',
    '80102',
    '80103',
    '80104',
    '80105',
    '80106',
    '80107',
    '80108',
    '80109',
    '80110',
    '80111',
    '80112',
    '80113',
    '80116',
    '80117',
    '80118',
    '80120',
    '80121',
    '80122',
    '80123',
    '80124',
    '80125',
    '80126',
    '80127',
    '80128',
    '80129',
    '80130',
    '80131',
    '80132',
    '80133',
    '80134',
    '80135',
    '80136',
    '80137',
    '80138',
    '80202',
    '80203',
    '80204',
    '80205',
    '80206',
    '80207',
    '80209',
    '80210',
    '80211',
    '80212',
    '80214',
    '80215',
    '80216',
    '80218',
    '80219',
    '80220',
    '80221',
    '80222',
    '80223',
    '80224',
    '80226',
    '80227',
    '80228',
    '80229',
    '80230',
    '80231',
    '80232',
    '80233',
    '80234',
    '80235',
    '80236',
    '80237',
    '80238',
    '80239',
    '80241',
    '80246',
    '80247',
    '80249',
    '80260',
    '80264',
    '80290',
    '80293',
    '80294',
    '80301',
    '80302',
    '80303',
    '80304',
    '80305',
    '80310',
    '80401',
    '80403',
    '80419',
    '80420',
    '80421',
    '80422',
    '80423',
    '80424',
    '80425',
    '80426',
    '80427',
    '80428',
    '80432',
    '80433',
    '80434',
    '80435',
    '80436',
    '80438',
    '80439',
    '80440',
    '80442',
    '80443',
    '80444',
    '80446',
    '80447',
    '80448',
    '80449',
    '80451',
    '80452',
    '80453',
    '80454',
    '80455',
    '80456',
    '80457',
    '80459',
    '80461',
    '80463',
    '80465',
    '80466',
    '80467',
    '80468',
    '80469',
    '80470',
    '80471',
    '80473',
    '80475',
    '80476',
    '80477',
    '80478',
    '80479',
    '80480',
    '80481',
    '80482',
    '80483',
    '80487',
    '80488',
    '80497',
    '80498',
    '80501',
    '80503',
    '80504',
    '80510',
    '80511',
    '80512',
    '80513',
    '80514',
    '80515',
    '80516',
    '80517',
    '80520',
    '80521',
    '80524',
    '80525',
    '80526',
    '80528',
    '80530',
    '80532',
    '80534',
    '80535',
    '80536',
    '80537',
    '80538',
    '80540',
    '80542',
    '80543',
    '80544',
    '80545',
    '80546',
    '80547',
    '80549',
    '80550',
    '80601',
    '80602',
    '80603',
    '80610',
    '80611',
    '80612',
    '80615',
    '80620',
    '80621',
    '80622',
    '80623',
    '80624',
    '80631',
    '80634',
    '80640',
    '80642',
    '80643',
    '80644',
    '80645',
    '80648',
    '80649',
    '80650',
    '80651',
    '80652',
    '80653',
    '80654',
    '80701',
    '80705',
    '80720',
    '80721',
    '80722',
    '80723',
    '80726',
    '80727',
    '80728',
    '80729',
    '80731',
    '80733',
    '80734',
    '80735',
    '80736',
    '80737',
    '80740',
    '80741',
    '80742',
    '80743',
    '80744',
    '80745',
    '80746',
    '80747',
    '80749',
    '80750',
    '80751',
    '80754',
    '80755',
    '80757',
    '80758',
    '80759',
    '80801',
    '80802',
    '80804',
    '80805',
    '80807',
    '80808',
    '80809',
    '80810',
    '80812',
    '80813',
    '80814',
    '80815',
    '80816',
    '80817',
    '80818',
    '80819',
    '80820',
    '80821',
    '80822',
    '80823',
    '80824',
    '80825',
    '80827',
    '80828',
    '80829',
    '80830',
    '80831',
    '80832',
    '80833',
    '80834',
    '80835',
    '80836',
    '80840',
    '80860',
    '80861',
    '80862',
    '80863',
    '80864',
    '80902',
    '80903',
    '80904',
    '80905',
    '80906',
    '80907',
    '80908',
    '80909',
    '80910',
    '80911',
    '80913',
    '80914',
    '80915',
    '80916',
    '80917',
    '80918',
    '80919',
    '80920',
    '80921',
    '80922',
    '80923',
    '80924',
    '80925',
    '80926',
    '80927',
    '80928',
    '80929',
    '80930',
    '80938',
    '80939',
    '80951',
    '81001',
    '81003',
    '81004',
    '81005',
    '81006',
    '81007',
    '81008',
    '81019',
    '81020',
    '81021',
    '81022',
    '81023',
    '81024',
    '81025',
    '81027',
    '81029',
    '81030',
    '81033',
    '81036',
    '81038',
    '81039',
    '81040',
    '81041',
    '81043',
    '81044',
    '81045',
    '81047',
    '81049',
    '81050',
    '81052',
    '81054',
    '81055',
    '81057',
    '81058',
    '81059',
    '81062',
    '81063',
    '81064',
    '81067',
    '81069',
    '81071',
    '81073',
    '81076',
    '81077',
    '81081',
    '81082',
    '81084',
    '81087',
    '81089',
    '81090',
    '81091',
    '81092',
    '81101',
    '81120',
    '81121',
    '81122',
    '81123',
    '81124',
    '81125',
    '81126',
    '81128',
    '81129',
    '81130',
    '81131',
    '81132',
    '81133',
    '81136',
    '81137',
    '81138',
    '81140',
    '81141',
    '81143',
    '81144',
    '81146',
    '81147',
    '81148',
    '81149',
    '81151',
    '81152',
    '81154',
    '81155',
    '81201',
    '81210',
    '81211',
    '81212',
    '81220',
    '81221',
    '81222',
    '81223',
    '81224',
    '81225',
    '81226',
    '81227',
    '81230',
    '81231',
    '81232',
    '81233',
    '81235',
    '81236',
    '81237',
    '81239',
    '81240',
    '81241',
    '81242',
    '81243',
    '81244',
    '81248',
    '81251',
    '81252',
    '81253',
    '81301',
    '81303',
    '81320',
    '81321',
    '81323',
    '81324',
    '81325',
    '81326',
    '81327',
    '81328',
    '81330',
    '81331',
    '81332',
    '81334',
    '81335',
    '81401',
    '81403',
    '81410',
    '81411',
    '81413',
    '81415',
    '81416',
    '81418',
    '81419',
    '81422',
    '81423',
    '81424',
    '81425',
    '81426',
    '81427',
    '81428',
    '81429',
    '81430',
    '81431',
    '81432',
    '81433',
    '81434',
    '81435',
    '81501',
    '81503',
    '81504',
    '81505',
    '81506',
    '81507',
    '81520',
    '81521',
    '81522',
    '81523',
    '81524',
    '81525',
    '81526',
    '81527',
    '81601',
    '81610',
    '81611',
    '81612',
    '81615',
    '81620',
    '81621',
    '81623',
    '81624',
    '81625',
    '81630',
    '81631',
    '81632',
    '81633',
    '81635',
    '81637',
    '81638',
    '81639',
    '81640',
    '81641',
    '81642',
    '81643',
    '81645',
    '81646',
    '81647',
    '81648',
    '81649',
    '81650',
    '81652',
    '81653',
    '81654',
    '81655',
    '81656',
    '81657',
    '82063'
  ],
  '36': [
    '06390',
    '10001',
    '10002',
    '10003',
    '10004',
    '10005',
    '10006',
    '10007',
    '10009',
    '10010',
    '10011',
    '10012',
    '10013',
    '10014',
    '10016',
    '10017',
    '10018',
    '10019',
    '10020',
    '10021',
    '10022',
    '10023',
    '10024',
    '10025',
    '10026',
    '10027',
    '10028',
    '10029',
    '10030',
    '10031',
    '10032',
    '10033',
    '10034',
    '10035',
    '10036',
    '10037',
    '10038',
    '10039',
    '10040',
    '10044',
    '10065',
    '10069',
    '10075',
    '10103',
    '10110',
    '10111',
    '10112',
    '10115',
    '10119',
    '10128',
    '10152',
    '10153',
    '10154',
    '10162',
    '10165',
    '10167',
    '10168',
    '10169',
    '10170',
    '10171',
    '10172',
    '10173',
    '10174',
    '10177',
    '10199',
    '10271',
    '10278',
    '10279',
    '10280',
    '10282',
    '10301',
    '10302',
    '10303',
    '10304',
    '10305',
    '10306',
    '10307',
    '10308',
    '10309',
    '10310',
    '10311',
    '10312',
    '10314',
    '10451',
    '10452',
    '10453',
    '10454',
    '10455',
    '10456',
    '10457',
    '10458',
    '10459',
    '10460',
    '10461',
    '10462',
    '10463',
    '10464',
    '10465',
    '10466',
    '10467',
    '10468',
    '10469',
    '10470',
    '10471',
    '10472',
    '10473',
    '10474',
    '10475',
    '10501',
    '10502',
    '10503',
    '10504',
    '10505',
    '10506',
    '10507',
    '10509',
    '10510',
    '10511',
    '10512',
    '10514',
    '10516',
    '10517',
    '10518',
    '10519',
    '10520',
    '10522',
    '10523',
    '10524',
    '10526',
    '10527',
    '10528',
    '10530',
    '10532',
    '10533',
    '10535',
    '10536',
    '10537',
    '10538',
    '10541',
    '10543',
    '10545',
    '10546',
    '10547',
    '10548',
    '10549',
    '10550',
    '10552',
    '10553',
    '10560',
    '10562',
    '10566',
    '10567',
    '10570',
    '10573',
    '10576',
    '10577',
    '10578',
    '10579',
    '10580',
    '10583',
    '10588',
    '10589',
    '10590',
    '10591',
    '10594',
    '10595',
    '10596',
    '10597',
    '10598',
    '10601',
    '10603',
    '10604',
    '10605',
    '10606',
    '10607',
    '10701',
    '10703',
    '10704',
    '10705',
    '10706',
    '10707',
    '10708',
    '10709',
    '10710',
    '10801',
    '10803',
    '10804',
    '10805',
    '10901',
    '10910',
    '10911',
    '10913',
    '10914',
    '10915',
    '10916',
    '10917',
    '10918',
    '10919',
    '10920',
    '10921',
    '10922',
    '10923',
    '10924',
    '10925',
    '10926',
    '10927',
    '10928',
    '10930',
    '10931',
    '10932',
    '10933',
    '10940',
    '10941',
    '10950',
    '10952',
    '10953',
    '10954',
    '10956',
    '10958',
    '10960',
    '10962',
    '10963',
    '10964',
    '10965',
    '10968',
    '10969',
    '10970',
    '10973',
    '10974',
    '10975',
    '10976',
    '10977',
    '10979',
    '10980',
    '10983',
    '10984',
    '10985',
    '10986',
    '10987',
    '10988',
    '10989',
    '10990',
    '10992',
    '10993',
    '10994',
    '10996',
    '10998',
    '11001',
    '11003',
    '11004',
    '11005',
    '11010',
    '11020',
    '11021',
    '11023',
    '11024',
    '11030',
    '11040',
    '11042',
    '11050',
    '11096',
    '11101',
    '11102',
    '11103',
    '11104',
    '11105',
    '11106',
    '11109',
    '11201',
    '11203',
    '11204',
    '11205',
    '11206',
    '11207',
    '11208',
    '11209',
    '11210',
    '11211',
    '11212',
    '11213',
    '11214',
    '11215',
    '11216',
    '11217',
    '11218',
    '11219',
    '11220',
    '11221',
    '11222',
    '11223',
    '11224',
    '11225',
    '11226',
    '11228',
    '11229',
    '11230',
    '11231',
    '11232',
    '11233',
    '11234',
    '11235',
    '11236',
    '11237',
    '11238',
    '11239',
    '11351',
    '11354',
    '11355',
    '11356',
    '11357',
    '11358',
    '11359',
    '11360',
    '11361',
    '11362',
    '11363',
    '11364',
    '11365',
    '11366',
    '11367',
    '11368',
    '11369',
    '11370',
    '11371',
    '11372',
    '11373',
    '11374',
    '11375',
    '11377',
    '11378',
    '11379',
    '11385',
    '11411',
    '11412',
    '11413',
    '11414',
    '11415',
    '11416',
    '11417',
    '11418',
    '11419',
    '11420',
    '11421',
    '11422',
    '11423',
    '11424',
    '11425',
    '11426',
    '11427',
    '11428',
    '11429',
    '11430',
    '11432',
    '11433',
    '11434',
    '11435',
    '11436',
    '11451',
    '11501',
    '11507',
    '11509',
    '11510',
    '11514',
    '11516',
    '11518',
    '11520',
    '11530',
    '11542',
    '11545',
    '11547',
    '11548',
    '11549',
    '11550',
    '11552',
    '11553',
    '11554',
    '11556',
    '11557',
    '11558',
    '11559',
    '11560',
    '11561',
    '11563',
    '11565',
    '11566',
    '11568',
    '11569',
    '11570',
    '11572',
    '11575',
    '11576',
    '11577',
    '11579',
    '11580',
    '11581',
    '11590',
    '11596',
    '11598',
    '11691',
    '11692',
    '11693',
    '11694',
    '11697',
    '11701',
    '11702',
    '11703',
    '11704',
    '11705',
    '11706',
    '11709',
    '11710',
    '11713',
    '11714',
    '11715',
    '11716',
    '11717',
    '11718',
    '11719',
    '11720',
    '11721',
    '11722',
    '11724',
    '11725',
    '11726',
    '11727',
    '11729',
    '11730',
    '11731',
    '11732',
    '11733',
    '11735',
    '11738',
    '11739',
    '11740',
    '11741',
    '11742',
    '11743',
    '11746',
    '11747',
    '11749',
    '11751',
    '11752',
    '11753',
    '11754',
    '11755',
    '11756',
    '11757',
    '11758',
    '11762',
    '11763',
    '11764',
    '11765',
    '11766',
    '11767',
    '11768',
    '11769',
    '11770',
    '11771',
    '11772',
    '11776',
    '11777',
    '11778',
    '11779',
    '11780',
    '11782',
    '11783',
    '11784',
    '11786',
    '11787',
    '11788',
    '11789',
    '11790',
    '11791',
    '11792',
    '11793',
    '11794',
    '11795',
    '11796',
    '11797',
    '11798',
    '11801',
    '11803',
    '11804',
    '11901',
    '11930',
    '11931',
    '11932',
    '11933',
    '11934',
    '11935',
    '11937',
    '11939',
    '11940',
    '11941',
    '11942',
    '11944',
    '11946',
    '11947',
    '11948',
    '11949',
    '11950',
    '11951',
    '11952',
    '11953',
    '11954',
    '11955',
    '11956',
    '11957',
    '11958',
    '11959',
    '11960',
    '11961',
    '11962',
    '11963',
    '11964',
    '11965',
    '11967',
    '11968',
    '11970',
    '11971',
    '11972',
    '11973',
    '11975',
    '11976',
    '11977',
    '11978',
    '11980',
    '12007',
    '12008',
    '12009',
    '12010',
    '12015',
    '12017',
    '12018',
    '12019',
    '12020',
    '12022',
    '12023',
    '12024',
    '12025',
    '12027',
    '12028',
    '12029',
    '12031',
    '12032',
    '12033',
    '12035',
    '12036',
    '12037',
    '12040',
    '12041',
    '12042',
    '12043',
    '12045',
    '12046',
    '12047',
    '12051',
    '12052',
    '12053',
    '12054',
    '12056',
    '12057',
    '12058',
    '12059',
    '12060',
    '12061',
    '12062',
    '12063',
    '12064',
    '12065',
    '12066',
    '12067',
    '12068',
    '12069',
    '12070',
    '12071',
    '12072',
    '12074',
    '12075',
    '12076',
    '12077',
    '12078',
    '12083',
    '12084',
    '12085',
    '12086',
    '12087',
    '12089',
    '12090',
    '12092',
    '12093',
    '12094',
    '12095',
    '12106',
    '12108',
    '12110',
    '12115',
    '12116',
    '12117',
    '12118',
    '12120',
    '12121',
    '12122',
    '12123',
    '12124',
    '12125',
    '12130',
    '12131',
    '12132',
    '12134',
    '12136',
    '12137',
    '12138',
    '12139',
    '12140',
    '12143',
    '12144',
    '12147',
    '12148',
    '12149',
    '12150',
    '12151',
    '12153',
    '12154',
    '12155',
    '12156',
    '12157',
    '12158',
    '12159',
    '12160',
    '12161',
    '12164',
    '12165',
    '12166',
    '12167',
    '12168',
    '12169',
    '12170',
    '12172',
    '12173',
    '12174',
    '12175',
    '12176',
    '12177',
    '12180',
    '12182',
    '12183',
    '12184',
    '12185',
    '12186',
    '12187',
    '12188',
    '12189',
    '12190',
    '12192',
    '12193',
    '12194',
    '12195',
    '12196',
    '12197',
    '12198',
    '12202',
    '12203',
    '12204',
    '12205',
    '12206',
    '12207',
    '12208',
    '12209',
    '12210',
    '12211',
    '12222',
    '12302',
    '12303',
    '12304',
    '12305',
    '12306',
    '12307',
    '12308',
    '12309',
    '12401',
    '12404',
    '12405',
    '12406',
    '12407',
    '12409',
    '12410',
    '12411',
    '12412',
    '12413',
    '12414',
    '12416',
    '12417',
    '12418',
    '12419',
    '12420',
    '12421',
    '12422',
    '12423',
    '12424',
    '12427',
    '12428',
    '12429',
    '12430',
    '12431',
    '12432',
    '12433',
    '12434',
    '12435',
    '12436',
    '12438',
    '12439',
    '12440',
    '12441',
    '12442',
    '12443',
    '12444',
    '12446',
    '12448',
    '12449',
    '12450',
    '12451',
    '12452',
    '12453',
    '12454',
    '12455',
    '12456',
    '12457',
    '12458',
    '12459',
    '12460',
    '12461',
    '12463',
    '12464',
    '12465',
    '12466',
    '12468',
    '12469',
    '12470',
    '12471',
    '12472',
    '12473',
    '12474',
    '12475',
    '12477',
    '12480',
    '12481',
    '12482',
    '12483',
    '12484',
    '12485',
    '12486',
    '12487',
    '12489',
    '12490',
    '12491',
    '12492',
    '12493',
    '12494',
    '12495',
    '12496',
    '12498',
    '12501',
    '12502',
    '12503',
    '12504',
    '12507',
    '12508',
    '12512',
    '12513',
    '12514',
    '12515',
    '12516',
    '12517',
    '12518',
    '12520',
    '12521',
    '12522',
    '12523',
    '12524',
    '12525',
    '12526',
    '12527',
    '12528',
    '12529',
    '12530',
    '12531',
    '12533',
    '12534',
    '12538',
    '12540',
    '12542',
    '12543',
    '12545',
    '12546',
    '12547',
    '12548',
    '12549',
    '12550',
    '12553',
    '12561',
    '12563',
    '12564',
    '12565',
    '12566',
    '12567',
    '12569',
    '12570',
    '12571',
    '12572',
    '12574',
    '12575',
    '12577',
    '12578',
    '12580',
    '12581',
    '12582',
    '12583',
    '12585',
    '12586',
    '12589',
    '12590',
    '12592',
    '12594',
    '12601',
    '12603',
    '12604',
    '12701',
    '12719',
    '12720',
    '12721',
    '12722',
    '12723',
    '12724',
    '12725',
    '12726',
    '12729',
    '12732',
    '12733',
    '12734',
    '12736',
    '12737',
    '12738',
    '12740',
    '12741',
    '12742',
    '12743',
    '12745',
    '12746',
    '12747',
    '12748',
    '12749',
    '12750',
    '12751',
    '12752',
    '12754',
    '12758',
    '12759',
    '12760',
    '12762',
    '12763',
    '12764',
    '12765',
    '12766',
    '12767',
    '12768',
    '12769',
    '12770',
    '12771',
    '12775',
    '12776',
    '12777',
    '12778',
    '12779',
    '12780',
    '12781',
    '12783',
    '12784',
    '12785',
    '12786',
    '12787',
    '12788',
    '12789',
    '12790',
    '12791',
    '12792',
    '12801',
    '12803',
    '12804',
    '12808',
    '12809',
    '12810',
    '12811',
    '12812',
    '12814',
    '12815',
    '12816',
    '12817',
    '12819',
    '12821',
    '12822',
    '12823',
    '12824',
    '12827',
    '12828',
    '12831',
    '12832',
    '12833',
    '12834',
    '12835',
    '12836',
    '12837',
    '12838',
    '12839',
    '12841',
    '12842',
    '12843',
    '12844',
    '12845',
    '12846',
    '12847',
    '12849',
    '12850',
    '12851',
    '12852',
    '12853',
    '12855',
    '12856',
    '12857',
    '12858',
    '12859',
    '12860',
    '12861',
    '12862',
    '12863',
    '12864',
    '12865',
    '12866',
    '12870',
    '12871',
    '12872',
    '12873',
    '12874',
    '12878',
    '12883',
    '12884',
    '12885',
    '12886',
    '12887',
    '12901',
    '12903',
    '12910',
    '12911',
    '12912',
    '12913',
    '12914',
    '12916',
    '12917',
    '12918',
    '12919',
    '12920',
    '12921',
    '12922',
    '12923',
    '12924',
    '12926',
    '12927',
    '12928',
    '12929',
    '12930',
    '12932',
    '12933',
    '12934',
    '12935',
    '12936',
    '12937',
    '12939',
    '12941',
    '12942',
    '12943',
    '12944',
    '12945',
    '12946',
    '12950',
    '12952',
    '12953',
    '12955',
    '12956',
    '12957',
    '12958',
    '12959',
    '12960',
    '12961',
    '12962',
    '12964',
    '12965',
    '12966',
    '12967',
    '12969',
    '12970',
    '12972',
    '12973',
    '12974',
    '12975',
    '12976',
    '12977',
    '12978',
    '12979',
    '12980',
    '12981',
    '12983',
    '12985',
    '12986',
    '12987',
    '12989',
    '12992',
    '12993',
    '12996',
    '12997',
    '12998',
    '13020',
    '13021',
    '13024',
    '13026',
    '13027',
    '13028',
    '13029',
    '13030',
    '13031',
    '13032',
    '13033',
    '13034',
    '13035',
    '13036',
    '13037',
    '13039',
    '13040',
    '13041',
    '13042',
    '13044',
    '13045',
    '13051',
    '13052',
    '13053',
    '13054',
    '13057',
    '13060',
    '13061',
    '13062',
    '13063',
    '13064',
    '13066',
    '13068',
    '13069',
    '13071',
    '13072',
    '13073',
    '13074',
    '13076',
    '13077',
    '13078',
    '13080',
    '13081',
    '13082',
    '13083',
    '13084',
    '13087',
    '13088',
    '13090',
    '13092',
    '13101',
    '13102',
    '13103',
    '13104',
    '13108',
    '13110',
    '13111',
    '13112',
    '13113',
    '13114',
    '13115',
    '13116',
    '13117',
    '13118',
    '13120',
    '13122',
    '13123',
    '13124',
    '13126',
    '13131',
    '13132',
    '13134',
    '13135',
    '13136',
    '13138',
    '13140',
    '13141',
    '13142',
    '13143',
    '13144',
    '13145',
    '13146',
    '13147',
    '13148',
    '13152',
    '13153',
    '13155',
    '13156',
    '13157',
    '13158',
    '13159',
    '13160',
    '13162',
    '13163',
    '13164',
    '13165',
    '13166',
    '13167',
    '13202',
    '13203',
    '13204',
    '13205',
    '13206',
    '13207',
    '13208',
    '13209',
    '13210',
    '13211',
    '13212',
    '13214',
    '13215',
    '13219',
    '13224',
    '13290',
    '13301',
    '13302',
    '13303',
    '13304',
    '13305',
    '13308',
    '13309',
    '13310',
    '13312',
    '13313',
    '13314',
    '13315',
    '13316',
    '13317',
    '13318',
    '13319',
    '13320',
    '13321',
    '13322',
    '13323',
    '13324',
    '13325',
    '13326',
    '13327',
    '13328',
    '13329',
    '13331',
    '13332',
    '13333',
    '13334',
    '13335',
    '13337',
    '13338',
    '13339',
    '13340',
    '13341',
    '13342',
    '13343',
    '13345',
    '13346',
    '13348',
    '13350',
    '13352',
    '13353',
    '13354',
    '13355',
    '13357',
    '13360',
    '13361',
    '13362',
    '13363',
    '13364',
    '13365',
    '13367',
    '13368',
    '13402',
    '13403',
    '13404',
    '13406',
    '13407',
    '13408',
    '13409',
    '13410',
    '13411',
    '13413',
    '13415',
    '13416',
    '13417',
    '13418',
    '13420',
    '13421',
    '13424',
    '13425',
    '13428',
    '13431',
    '13433',
    '13435',
    '13436',
    '13437',
    '13438',
    '13439',
    '13440',
    '13441',
    '13450',
    '13452',
    '13454',
    '13456',
    '13459',
    '13460',
    '13461',
    '13464',
    '13468',
    '13469',
    '13470',
    '13471',
    '13472',
    '13473',
    '13475',
    '13476',
    '13477',
    '13478',
    '13480',
    '13483',
    '13484',
    '13485',
    '13486',
    '13488',
    '13489',
    '13490',
    '13491',
    '13492',
    '13493',
    '13494',
    '13495',
    '13501',
    '13502',
    '13601',
    '13602',
    '13603',
    '13605',
    '13606',
    '13607',
    '13608',
    '13612',
    '13613',
    '13614',
    '13615',
    '13616',
    '13617',
    '13618',
    '13619',
    '13620',
    '13621',
    '13622',
    '13623',
    '13624',
    '13625',
    '13626',
    '13628',
    '13630',
    '13633',
    '13634',
    '13635',
    '13636',
    '13637',
    '13638',
    '13639',
    '13640',
    '13641',
    '13642',
    '13643',
    '13646',
    '13647',
    '13648',
    '13650',
    '13651',
    '13652',
    '13654',
    '13655',
    '13656',
    '13658',
    '13659',
    '13660',
    '13661',
    '13662',
    '13664',
    '13665',
    '13666',
    '13667',
    '13668',
    '13669',
    '13670',
    '13672',
    '13673',
    '13674',
    '13675',
    '13676',
    '13677',
    '13678',
    '13679',
    '13680',
    '13681',
    '13682',
    '13684',
    '13685',
    '13687',
    '13690',
    '13691',
    '13692',
    '13693',
    '13694',
    '13695',
    '13696',
    '13697',
    '13730',
    '13731',
    '13732',
    '13733',
    '13734',
    '13736',
    '13739',
    '13740',
    '13743',
    '13744',
    '13746',
    '13748',
    '13750',
    '13751',
    '13752',
    '13753',
    '13754',
    '13755',
    '13756',
    '13757',
    '13760',
    '13774',
    '13775',
    '13776',
    '13777',
    '13778',
    '13780',
    '13782',
    '13783',
    '13784',
    '13786',
    '13787',
    '13788',
    '13790',
    '13794',
    '13795',
    '13796',
    '13797',
    '13801',
    '13802',
    '13803',
    '13804',
    '13806',
    '13807',
    '13808',
    '13809',
    '13810',
    '13811',
    '13812',
    '13813',
    '13815',
    '13820',
    '13825',
    '13826',
    '13827',
    '13830',
    '13832',
    '13833',
    '13834',
    '13835',
    '13838',
    '13839',
    '13841',
    '13842',
    '13843',
    '13844',
    '13845',
    '13846',
    '13847',
    '13849',
    '13850',
    '13856',
    '13859',
    '13860',
    '13861',
    '13862',
    '13863',
    '13864',
    '13865',
    '13901',
    '13902',
    '13903',
    '13904',
    '13905',
    '14001',
    '14004',
    '14005',
    '14006',
    '14008',
    '14009',
    '14011',
    '14012',
    '14013',
    '14020',
    '14024',
    '14025',
    '14026',
    '14028',
    '14030',
    '14031',
    '14032',
    '14033',
    '14034',
    '14035',
    '14036',
    '14037',
    '14039',
    '14040',
    '14041',
    '14042',
    '14043',
    '14047',
    '14048',
    '14051',
    '14052',
    '14054',
    '14055',
    '14057',
    '14058',
    '14059',
    '14060',
    '14061',
    '14062',
    '14063',
    '14065',
    '14066',
    '14067',
    '14068',
    '14069',
    '14070',
    '14072',
    '14075',
    '14080',
    '14081',
    '14082',
    '14085',
    '14086',
    '14091',
    '14092',
    '14094',
    '14098',
    '14101',
    '14102',
    '14103',
    '14105',
    '14108',
    '14109',
    '14111',
    '14112',
    '14113',
    '14120',
    '14125',
    '14126',
    '14127',
    '14129',
    '14130',
    '14131',
    '14132',
    '14134',
    '14135',
    '14136',
    '14138',
    '14139',
    '14141',
    '14143',
    '14145',
    '14150',
    '14167',
    '14168',
    '14169',
    '14170',
    '14171',
    '14172',
    '14173',
    '14174',
    '14201',
    '14202',
    '14203',
    '14204',
    '14206',
    '14207',
    '14208',
    '14209',
    '14210',
    '14211',
    '14212',
    '14213',
    '14214',
    '14215',
    '14216',
    '14217',
    '14218',
    '14219',
    '14220',
    '14221',
    '14222',
    '14223',
    '14224',
    '14225',
    '14226',
    '14227',
    '14228',
    '14261',
    '14301',
    '14302',
    '14303',
    '14304',
    '14305',
    '14411',
    '14414',
    '14415',
    '14416',
    '14418',
    '14420',
    '14422',
    '14423',
    '14424',
    '14425',
    '14427',
    '14428',
    '14432',
    '14433',
    '14435',
    '14437',
    '14441',
    '14445',
    '14450',
    '14454',
    '14456',
    '14462',
    '14464',
    '14466',
    '14467',
    '14468',
    '14469',
    '14470',
    '14471',
    '14472',
    '14475',
    '14476',
    '14477',
    '14478',
    '14479',
    '14480',
    '14481',
    '14482',
    '14485',
    '14486',
    '14487',
    '14489',
    '14502',
    '14504',
    '14505',
    '14506',
    '14507',
    '14510',
    '14511',
    '14512',
    '14513',
    '14514',
    '14516',
    '14517',
    '14519',
    '14521',
    '14522',
    '14525',
    '14526',
    '14527',
    '14529',
    '14530',
    '14532',
    '14533',
    '14534',
    '14536',
    '14537',
    '14539',
    '14541',
    '14542',
    '14543',
    '14544',
    '14545',
    '14546',
    '14548',
    '14549',
    '14550',
    '14551',
    '14555',
    '14559',
    '14560',
    '14561',
    '14564',
    '14568',
    '14569',
    '14571',
    '14572',
    '14580',
    '14585',
    '14586',
    '14588',
    '14589',
    '14590',
    '14591',
    '14592',
    '14604',
    '14605',
    '14606',
    '14607',
    '14608',
    '14609',
    '14610',
    '14611',
    '14612',
    '14613',
    '14614',
    '14615',
    '14616',
    '14617',
    '14618',
    '14619',
    '14620',
    '14621',
    '14622',
    '14623',
    '14624',
    '14625',
    '14626',
    '14627',
    '14701',
    '14706',
    '14707',
    '14708',
    '14709',
    '14710',
    '14711',
    '14712',
    '14714',
    '14715',
    '14716',
    '14717',
    '14718',
    '14719',
    '14720',
    '14721',
    '14722',
    '14723',
    '14724',
    '14726',
    '14727',
    '14728',
    '14729',
    '14731',
    '14732',
    '14733',
    '14735',
    '14736',
    '14737',
    '14738',
    '14739',
    '14740',
    '14741',
    '14742',
    '14743',
    '14744',
    '14747',
    '14748',
    '14750',
    '14752',
    '14753',
    '14754',
    '14755',
    '14756',
    '14757',
    '14760',
    '14767',
    '14769',
    '14770',
    '14772',
    '14774',
    '14775',
    '14777',
    '14778',
    '14779',
    '14781',
    '14782',
    '14783',
    '14784',
    '14787',
    '14788',
    '14801',
    '14802',
    '14803',
    '14804',
    '14805',
    '14806',
    '14807',
    '14808',
    '14809',
    '14810',
    '14812',
    '14813',
    '14814',
    '14815',
    '14816',
    '14817',
    '14818',
    '14819',
    '14820',
    '14821',
    '14822',
    '14823',
    '14824',
    '14825',
    '14826',
    '14827',
    '14830',
    '14836',
    '14837',
    '14838',
    '14839',
    '14840',
    '14841',
    '14842',
    '14843',
    '14845',
    '14846',
    '14847',
    '14850',
    '14853',
    '14854',
    '14855',
    '14856',
    '14858',
    '14859',
    '14860',
    '14861',
    '14864',
    '14865',
    '14867',
    '14869',
    '14870',
    '14871',
    '14872',
    '14873',
    '14874',
    '14877',
    '14878',
    '14879',
    '14880',
    '14881',
    '14882',
    '14883',
    '14884',
    '14885',
    '14886',
    '14889',
    '14891',
    '14892',
    '14893',
    '14894',
    '14895',
    '14897',
    '14898',
    '14901',
    '14903',
    '14904',
    '14905'
  ],
  '30': [
    '57724',
    '59001',
    '59002',
    '59003',
    '59006',
    '59007',
    '59008',
    '59010',
    '59011',
    '59012',
    '59013',
    '59014',
    '59015',
    '59016',
    '59018',
    '59019',
    '59020',
    '59022',
    '59024',
    '59025',
    '59026',
    '59027',
    '59028',
    '59029',
    '59030',
    '59031',
    '59032',
    '59033',
    '59034',
    '59035',
    '59036',
    '59037',
    '59038',
    '59039',
    '59041',
    '59043',
    '59044',
    '59046',
    '59047',
    '59050',
    '59052',
    '59053',
    '59054',
    '59055',
    '59057',
    '59058',
    '59059',
    '59061',
    '59062',
    '59063',
    '59064',
    '59065',
    '59066',
    '59067',
    '59068',
    '59069',
    '59070',
    '59071',
    '59072',
    '59074',
    '59075',
    '59076',
    '59077',
    '59078',
    '59079',
    '59081',
    '59082',
    '59085',
    '59086',
    '59087',
    '59088',
    '59089',
    '59101',
    '59102',
    '59105',
    '59106',
    '59201',
    '59211',
    '59212',
    '59213',
    '59214',
    '59215',
    '59217',
    '59218',
    '59219',
    '59221',
    '59222',
    '59223',
    '59225',
    '59226',
    '59230',
    '59231',
    '59240',
    '59241',
    '59242',
    '59243',
    '59244',
    '59247',
    '59248',
    '59250',
    '59252',
    '59253',
    '59254',
    '59255',
    '59256',
    '59257',
    '59258',
    '59259',
    '59260',
    '59261',
    '59262',
    '59263',
    '59270',
    '59274',
    '59275',
    '59276',
    '59301',
    '59311',
    '59312',
    '59313',
    '59314',
    '59315',
    '59317',
    '59318',
    '59322',
    '59323',
    '59324',
    '59326',
    '59327',
    '59330',
    '59332',
    '59333',
    '59336',
    '59337',
    '59338',
    '59339',
    '59343',
    '59344',
    '59347',
    '59349',
    '59351',
    '59353',
    '59354',
    '59401',
    '59404',
    '59405',
    '59410',
    '59411',
    '59412',
    '59414',
    '59416',
    '59417',
    '59418',
    '59419',
    '59420',
    '59421',
    '59422',
    '59424',
    '59425',
    '59427',
    '59430',
    '59432',
    '59433',
    '59434',
    '59436',
    '59440',
    '59441',
    '59442',
    '59443',
    '59444',
    '59446',
    '59447',
    '59448',
    '59450',
    '59451',
    '59452',
    '59453',
    '59454',
    '59456',
    '59457',
    '59460',
    '59461',
    '59462',
    '59463',
    '59464',
    '59465',
    '59466',
    '59467',
    '59468',
    '59469',
    '59471',
    '59472',
    '59474',
    '59477',
    '59479',
    '59480',
    '59482',
    '59483',
    '59484',
    '59485',
    '59486',
    '59487',
    '59489',
    '59501',
    '59520',
    '59521',
    '59522',
    '59523',
    '59524',
    '59525',
    '59526',
    '59527',
    '59528',
    '59529',
    '59530',
    '59531',
    '59532',
    '59535',
    '59537',
    '59538',
    '59540',
    '59542',
    '59544',
    '59545',
    '59546',
    '59547',
    '59601',
    '59602',
    '59631',
    '59632',
    '59633',
    '59634',
    '59635',
    '59636',
    '59638',
    '59639',
    '59640',
    '59642',
    '59643',
    '59644',
    '59645',
    '59647',
    '59648',
    '59701',
    '59703',
    '59710',
    '59711',
    '59713',
    '59714',
    '59715',
    '59716',
    '59718',
    '59720',
    '59721',
    '59722',
    '59724',
    '59725',
    '59727',
    '59728',
    '59729',
    '59730',
    '59731',
    '59732',
    '59733',
    '59735',
    '59736',
    '59739',
    '59740',
    '59741',
    '59743',
    '59745',
    '59746',
    '59747',
    '59748',
    '59749',
    '59750',
    '59751',
    '59752',
    '59754',
    '59755',
    '59756',
    '59758',
    '59759',
    '59760',
    '59761',
    '59762',
    '59801',
    '59802',
    '59803',
    '59804',
    '59808',
    '59820',
    '59821',
    '59823',
    '59824',
    '59825',
    '59826',
    '59827',
    '59828',
    '59829',
    '59830',
    '59831',
    '59832',
    '59833',
    '59834',
    '59837',
    '59840',
    '59841',
    '59842',
    '59843',
    '59844',
    '59845',
    '59846',
    '59847',
    '59848',
    '59851',
    '59853',
    '59854',
    '59855',
    '59856',
    '59858',
    '59859',
    '59860',
    '59863',
    '59864',
    '59865',
    '59866',
    '59867',
    '59868',
    '59870',
    '59871',
    '59872',
    '59873',
    '59874',
    '59875',
    '59901',
    '59910',
    '59911',
    '59912',
    '59913',
    '59914',
    '59915',
    '59916',
    '59917',
    '59918',
    '59919',
    '59920',
    '59922',
    '59923',
    '59925',
    '59926',
    '59927',
    '59928',
    '59929',
    '59930',
    '59931',
    '59932',
    '59933',
    '59934',
    '59935',
    '59936',
    '59937',
    '82801'
  ],
  '50': [
    '05001',
    '05031',
    '05032',
    '05033',
    '05034',
    '05035',
    '05036',
    '05037',
    '05038',
    '05039',
    '05040',
    '05041',
    '05042',
    '05043',
    '05045',
    '05046',
    '05048',
    '05050',
    '05051',
    '05052',
    '05053',
    '05055',
    '05056',
    '05058',
    '05059',
    '05060',
    '05061',
    '05062',
    '05065',
    '05067',
    '05068',
    '05069',
    '05070',
    '05071',
    '05072',
    '05075',
    '05076',
    '05077',
    '05079',
    '05081',
    '05083',
    '05084',
    '05086',
    '05089',
    '05091',
    '05101',
    '05141',
    '05142',
    '05143',
    '05146',
    '05148',
    '05149',
    '05150',
    '05151',
    '05152',
    '05153',
    '05154',
    '05155',
    '05156',
    '05158',
    '05161',
    '05201',
    '05250',
    '05251',
    '05252',
    '05253',
    '05254',
    '05255',
    '05257',
    '05260',
    '05261',
    '05262',
    '05301',
    '05340',
    '05341',
    '05342',
    '05343',
    '05345',
    '05346',
    '05350',
    '05352',
    '05353',
    '05354',
    '05355',
    '05356',
    '05358',
    '05359',
    '05360',
    '05361',
    '05362',
    '05363',
    '05401',
    '05403',
    '05404',
    '05405',
    '05408',
    '05439',
    '05440',
    '05441',
    '05442',
    '05443',
    '05444',
    '05445',
    '05446',
    '05447',
    '05448',
    '05450',
    '05452',
    '05454',
    '05455',
    '05456',
    '05457',
    '05458',
    '05459',
    '05461',
    '05462',
    '05463',
    '05464',
    '05465',
    '05468',
    '05471',
    '05472',
    '05473',
    '05474',
    '05476',
    '05477',
    '05478',
    '05481',
    '05482',
    '05483',
    '05485',
    '05486',
    '05487',
    '05488',
    '05489',
    '05491',
    '05492',
    '05494',
    '05495',
    '05602',
    '05640',
    '05641',
    '05647',
    '05648',
    '05649',
    '05650',
    '05651',
    '05652',
    '05653',
    '05654',
    '05655',
    '05656',
    '05657',
    '05658',
    '05660',
    '05661',
    '05663',
    '05664',
    '05667',
    '05669',
    '05672',
    '05673',
    '05674',
    '05675',
    '05676',
    '05677',
    '05678',
    '05679',
    '05680',
    '05681',
    '05682',
    '05701',
    '05730',
    '05732',
    '05733',
    '05734',
    '05735',
    '05736',
    '05737',
    '05738',
    '05739',
    '05740',
    '05742',
    '05743',
    '05744',
    '05747',
    '05748',
    '05751',
    '05753',
    '05757',
    '05758',
    '05759',
    '05760',
    '05761',
    '05762',
    '05763',
    '05764',
    '05765',
    '05766',
    '05767',
    '05769',
    '05770',
    '05772',
    '05773',
    '05774',
    '05775',
    '05776',
    '05777',
    '05778',
    '05819',
    '05820',
    '05821',
    '05822',
    '05824',
    '05825',
    '05826',
    '05827',
    '05828',
    '05829',
    '05830',
    '05832',
    '05833',
    '05836',
    '05837',
    '05839',
    '05841',
    '05842',
    '05843',
    '05845',
    '05846',
    '05847',
    '05850',
    '05851',
    '05853',
    '05855',
    '05857',
    '05858',
    '05859',
    '05860',
    '05862',
    '05866',
    '05867',
    '05868',
    '05871',
    '05872',
    '05873',
    '05874',
    '05875',
    '05901',
    '05902',
    '05903',
    '05904',
    '05905',
    '05906',
    '05907'
  ],
  '29': [
    '51640',
    '52542',
    '52573',
    '52626',
    '63005',
    '63010',
    '63011',
    '63012',
    '63013',
    '63014',
    '63015',
    '63016',
    '63017',
    '63019',
    '63020',
    '63021',
    '63023',
    '63025',
    '63026',
    '63028',
    '63030',
    '63031',
    '63033',
    '63034',
    '63036',
    '63037',
    '63038',
    '63039',
    '63040',
    '63041',
    '63042',
    '63043',
    '63044',
    '63045',
    '63047',
    '63048',
    '63049',
    '63050',
    '63051',
    '63052',
    '63053',
    '63055',
    '63056',
    '63057',
    '63060',
    '63061',
    '63068',
    '63069',
    '63070',
    '63071',
    '63072',
    '63073',
    '63074',
    '63077',
    '63079',
    '63080',
    '63084',
    '63087',
    '63088',
    '63089',
    '63090',
    '63091',
    '63101',
    '63102',
    '63103',
    '63104',
    '63105',
    '63106',
    '63107',
    '63108',
    '63109',
    '63110',
    '63111',
    '63112',
    '63113',
    '63114',
    '63115',
    '63116',
    '63117',
    '63118',
    '63119',
    '63120',
    '63121',
    '63122',
    '63123',
    '63124',
    '63125',
    '63126',
    '63127',
    '63128',
    '63129',
    '63130',
    '63131',
    '63132',
    '63133',
    '63134',
    '63135',
    '63136',
    '63137',
    '63138',
    '63139',
    '63140',
    '63141',
    '63143',
    '63144',
    '63146',
    '63147',
    '63155',
    '63301',
    '63303',
    '63304',
    '63330',
    '63332',
    '63333',
    '63334',
    '63336',
    '63339',
    '63341',
    '63343',
    '63344',
    '63345',
    '63347',
    '63348',
    '63349',
    '63350',
    '63351',
    '63352',
    '63353',
    '63357',
    '63359',
    '63361',
    '63362',
    '63363',
    '63366',
    '63367',
    '63368',
    '63369',
    '63370',
    '63373',
    '63376',
    '63377',
    '63379',
    '63381',
    '63382',
    '63383',
    '63384',
    '63385',
    '63386',
    '63387',
    '63388',
    '63389',
    '63390',
    '63401',
    '63430',
    '63431',
    '63432',
    '63433',
    '63434',
    '63435',
    '63436',
    '63437',
    '63438',
    '63439',
    '63440',
    '63441',
    '63443',
    '63445',
    '63446',
    '63447',
    '63448',
    '63450',
    '63451',
    '63452',
    '63453',
    '63454',
    '63456',
    '63457',
    '63458',
    '63459',
    '63460',
    '63461',
    '63462',
    '63463',
    '63464',
    '63465',
    '63467',
    '63468',
    '63469',
    '63471',
    '63472',
    '63473',
    '63474',
    '63501',
    '63530',
    '63531',
    '63532',
    '63533',
    '63534',
    '63535',
    '63536',
    '63537',
    '63538',
    '63539',
    '63540',
    '63541',
    '63543',
    '63544',
    '63545',
    '63546',
    '63547',
    '63548',
    '63549',
    '63551',
    '63552',
    '63555',
    '63556',
    '63557',
    '63558',
    '63559',
    '63560',
    '63561',
    '63563',
    '63565',
    '63566',
    '63567',
    '63601',
    '63620',
    '63621',
    '63622',
    '63623',
    '63624',
    '63625',
    '63626',
    '63627',
    '63628',
    '63629',
    '63630',
    '63631',
    '63633',
    '63636',
    '63637',
    '63638',
    '63640',
    '63645',
    '63648',
    '63650',
    '63653',
    '63654',
    '63655',
    '63656',
    '63660',
    '63662',
    '63663',
    '63664',
    '63665',
    '63666',
    '63670',
    '63673',
    '63674',
    '63675',
    '63701',
    '63703',
    '63730',
    '63732',
    '63735',
    '63736',
    '63738',
    '63739',
    '63740',
    '63742',
    '63743',
    '63744',
    '63745',
    '63746',
    '63747',
    '63748',
    '63750',
    '63751',
    '63755',
    '63758',
    '63760',
    '63763',
    '63764',
    '63766',
    '63767',
    '63769',
    '63770',
    '63771',
    '63774',
    '63775',
    '63780',
    '63781',
    '63782',
    '63783',
    '63784',
    '63785',
    '63787',
    '63801',
    '63820',
    '63821',
    '63822',
    '63823',
    '63824',
    '63825',
    '63826',
    '63827',
    '63828',
    '63829',
    '63830',
    '63833',
    '63834',
    '63837',
    '63839',
    '63841',
    '63845',
    '63846',
    '63847',
    '63848',
    '63849',
    '63851',
    '63852',
    '63853',
    '63855',
    '63857',
    '63860',
    '63862',
    '63863',
    '63866',
    '63867',
    '63868',
    '63869',
    '63870',
    '63873',
    '63874',
    '63876',
    '63877',
    '63878',
    '63879',
    '63880',
    '63882',
    '63901',
    '63902',
    '63932',
    '63933',
    '63934',
    '63935',
    '63936',
    '63937',
    '63939',
    '63940',
    '63941',
    '63942',
    '63943',
    '63944',
    '63945',
    '63951',
    '63952',
    '63953',
    '63954',
    '63955',
    '63956',
    '63957',
    '63960',
    '63961',
    '63962',
    '63964',
    '63965',
    '63966',
    '63967',
    '64001',
    '64011',
    '64012',
    '64014',
    '64015',
    '64016',
    '64017',
    '64018',
    '64019',
    '64020',
    '64021',
    '64022',
    '64024',
    '64029',
    '64030',
    '64034',
    '64035',
    '64036',
    '64037',
    '64040',
    '64048',
    '64050',
    '64052',
    '64053',
    '64054',
    '64055',
    '64056',
    '64057',
    '64058',
    '64060',
    '64061',
    '64062',
    '64063',
    '64064',
    '64065',
    '64066',
    '64067',
    '64068',
    '64070',
    '64071',
    '64072',
    '64074',
    '64075',
    '64076',
    '64077',
    '64078',
    '64079',
    '64080',
    '64081',
    '64082',
    '64083',
    '64084',
    '64085',
    '64086',
    '64088',
    '64089',
    '64090',
    '64092',
    '64093',
    '64096',
    '64097',
    '64098',
    '64101',
    '64102',
    '64105',
    '64106',
    '64108',
    '64109',
    '64110',
    '64111',
    '64112',
    '64113',
    '64114',
    '64116',
    '64117',
    '64118',
    '64119',
    '64120',
    '64123',
    '64124',
    '64125',
    '64126',
    '64127',
    '64128',
    '64129',
    '64130',
    '64131',
    '64132',
    '64133',
    '64134',
    '64136',
    '64137',
    '64138',
    '64139',
    '64145',
    '64146',
    '64147',
    '64149',
    '64150',
    '64151',
    '64152',
    '64153',
    '64154',
    '64155',
    '64156',
    '64157',
    '64158',
    '64161',
    '64163',
    '64164',
    '64165',
    '64166',
    '64167',
    '64192',
    '64401',
    '64402',
    '64420',
    '64421',
    '64422',
    '64423',
    '64424',
    '64426',
    '64427',
    '64428',
    '64429',
    '64430',
    '64431',
    '64432',
    '64433',
    '64434',
    '64436',
    '64437',
    '64438',
    '64439',
    '64440',
    '64441',
    '64442',
    '64443',
    '64444',
    '64445',
    '64446',
    '64448',
    '64449',
    '64451',
    '64453',
    '64454',
    '64455',
    '64456',
    '64457',
    '64458',
    '64459',
    '64461',
    '64463',
    '64465',
    '64466',
    '64467',
    '64468',
    '64469',
    '64470',
    '64471',
    '64473',
    '64474',
    '64475',
    '64476',
    '64477',
    '64479',
    '64480',
    '64481',
    '64482',
    '64483',
    '64484',
    '64485',
    '64486',
    '64487',
    '64489',
    '64490',
    '64491',
    '64492',
    '64493',
    '64494',
    '64496',
    '64497',
    '64498',
    '64499',
    '64501',
    '64503',
    '64504',
    '64505',
    '64506',
    '64507',
    '64601',
    '64620',
    '64622',
    '64623',
    '64624',
    '64625',
    '64628',
    '64630',
    '64631',
    '64632',
    '64633',
    '64635',
    '64636',
    '64637',
    '64638',
    '64639',
    '64640',
    '64641',
    '64642',
    '64643',
    '64644',
    '64645',
    '64646',
    '64647',
    '64648',
    '64649',
    '64650',
    '64651',
    '64652',
    '64653',
    '64654',
    '64655',
    '64656',
    '64657',
    '64658',
    '64659',
    '64660',
    '64661',
    '64664',
    '64667',
    '64668',
    '64670',
    '64671',
    '64672',
    '64673',
    '64674',
    '64676',
    '64679',
    '64681',
    '64682',
    '64683',
    '64686',
    '64688',
    '64689',
    '64701',
    '64720',
    '64722',
    '64723',
    '64724',
    '64725',
    '64726',
    '64728',
    '64730',
    '64733',
    '64734',
    '64735',
    '64738',
    '64739',
    '64740',
    '64741',
    '64742',
    '64743',
    '64744',
    '64745',
    '64746',
    '64747',
    '64748',
    '64750',
    '64752',
    '64755',
    '64756',
    '64759',
    '64761',
    '64762',
    '64763',
    '64765',
    '64767',
    '64769',
    '64770',
    '64771',
    '64772',
    '64776',
    '64778',
    '64779',
    '64780',
    '64781',
    '64783',
    '64784',
    '64788',
    '64790',
    '64801',
    '64804',
    '64830',
    '64831',
    '64832',
    '64833',
    '64834',
    '64835',
    '64836',
    '64840',
    '64841',
    '64842',
    '64843',
    '64844',
    '64847',
    '64848',
    '64849',
    '64850',
    '64854',
    '64855',
    '64856',
    '64857',
    '64858',
    '64859',
    '64861',
    '64862',
    '64863',
    '64865',
    '64866',
    '64867',
    '64870',
    '64873',
    '64874',
    '65001',
    '65010',
    '65011',
    '65013',
    '65014',
    '65016',
    '65017',
    '65018',
    '65020',
    '65023',
    '65024',
    '65025',
    '65026',
    '65032',
    '65034',
    '65035',
    '65037',
    '65039',
    '65040',
    '65041',
    '65043',
    '65046',
    '65047',
    '65048',
    '65049',
    '65050',
    '65051',
    '65052',
    '65053',
    '65054',
    '65058',
    '65059',
    '65061',
    '65062',
    '65063',
    '65064',
    '65065',
    '65066',
    '65067',
    '65068',
    '65069',
    '65072',
    '65074',
    '65075',
    '65076',
    '65077',
    '65078',
    '65079',
    '65080',
    '65081',
    '65082',
    '65083',
    '65084',
    '65085',
    '65101',
    '65109',
    '65201',
    '65202',
    '65203',
    '65215',
    '65230',
    '65231',
    '65232',
    '65233',
    '65236',
    '65237',
    '65239',
    '65240',
    '65243',
    '65244',
    '65246',
    '65247',
    '65248',
    '65250',
    '65251',
    '65254',
    '65255',
    '65256',
    '65257',
    '65258',
    '65259',
    '65260',
    '65261',
    '65262',
    '65263',
    '65264',
    '65265',
    '65270',
    '65274',
    '65275',
    '65276',
    '65278',
    '65279',
    '65280',
    '65281',
    '65282',
    '65283',
    '65284',
    '65285',
    '65286',
    '65287',
    '65301',
    '65305',
    '65320',
    '65321',
    '65322',
    '65323',
    '65324',
    '65325',
    '65326',
    '65327',
    '65329',
    '65330',
    '65332',
    '65333',
    '65334',
    '65335',
    '65336',
    '65337',
    '65338',
    '65339',
    '65340',
    '65344',
    '65345',
    '65347',
    '65348',
    '65349',
    '65350',
    '65351',
    '65354',
    '65355',
    '65360',
    '65401',
    '65436',
    '65438',
    '65439',
    '65440',
    '65441',
    '65443',
    '65444',
    '65446',
    '65449',
    '65452',
    '65453',
    '65456',
    '65457',
    '65459',
    '65461',
    '65462',
    '65463',
    '65464',
    '65466',
    '65468',
    '65470',
    '65473',
    '65479',
    '65483',
    '65484',
    '65486',
    '65501',
    '65529',
    '65534',
    '65535',
    '65536',
    '65541',
    '65542',
    '65543',
    '65548',
    '65550',
    '65552',
    '65555',
    '65556',
    '65557',
    '65559',
    '65560',
    '65564',
    '65565',
    '65566',
    '65567',
    '65570',
    '65571',
    '65580',
    '65582',
    '65583',
    '65584',
    '65586',
    '65588',
    '65589',
    '65590',
    '65591',
    '65601',
    '65603',
    '65604',
    '65605',
    '65606',
    '65608',
    '65609',
    '65610',
    '65611',
    '65612',
    '65613',
    '65614',
    '65616',
    '65617',
    '65618',
    '65619',
    '65620',
    '65622',
    '65623',
    '65624',
    '65625',
    '65626',
    '65627',
    '65629',
    '65630',
    '65631',
    '65632',
    '65633',
    '65634',
    '65635',
    '65637',
    '65638',
    '65640',
    '65641',
    '65644',
    '65646',
    '65647',
    '65648',
    '65649',
    '65650',
    '65652',
    '65653',
    '65654',
    '65655',
    '65656',
    '65657',
    '65658',
    '65660',
    '65661',
    '65662',
    '65663',
    '65664',
    '65667',
    '65668',
    '65669',
    '65672',
    '65674',
    '65676',
    '65679',
    '65680',
    '65681',
    '65682',
    '65685',
    '65686',
    '65689',
    '65690',
    '65692',
    '65702',
    '65704',
    '65705',
    '65706',
    '65707',
    '65708',
    '65710',
    '65711',
    '65712',
    '65713',
    '65714',
    '65715',
    '65717',
    '65720',
    '65721',
    '65722',
    '65723',
    '65724',
    '65725',
    '65727',
    '65728',
    '65729',
    '65730',
    '65731',
    '65732',
    '65733',
    '65734',
    '65735',
    '65737',
    '65738',
    '65739',
    '65740',
    '65742',
    '65744',
    '65745',
    '65746',
    '65747',
    '65752',
    '65753',
    '65754',
    '65755',
    '65756',
    '65757',
    '65759',
    '65760',
    '65761',
    '65762',
    '65764',
    '65766',
    '65767',
    '65768',
    '65769',
    '65770',
    '65771',
    '65772',
    '65773',
    '65774',
    '65775',
    '65777',
    '65778',
    '65779',
    '65781',
    '65783',
    '65784',
    '65785',
    '65786',
    '65787',
    '65788',
    '65789',
    '65790',
    '65791',
    '65793',
    '65802',
    '65803',
    '65804',
    '65806',
    '65807',
    '65809',
    '65810',
    '72644'
  ],
  '17': [
    '60002',
    '60004',
    '60005',
    '60007',
    '60008',
    '60010',
    '60012',
    '60013',
    '60014',
    '60015',
    '60016',
    '60018',
    '60020',
    '60021',
    '60022',
    '60025',
    '60026',
    '60029',
    '60030',
    '60031',
    '60033',
    '60034',
    '60035',
    '60040',
    '60041',
    '60042',
    '60043',
    '60044',
    '60045',
    '60046',
    '60047',
    '60048',
    '60050',
    '60051',
    '60053',
    '60056',
    '60060',
    '60061',
    '60062',
    '60064',
    '60067',
    '60068',
    '60069',
    '60070',
    '60071',
    '60072',
    '60073',
    '60074',
    '60076',
    '60077',
    '60081',
    '60083',
    '60084',
    '60085',
    '60087',
    '60088',
    '60089',
    '60090',
    '60091',
    '60093',
    '60096',
    '60097',
    '60098',
    '60099',
    '60101',
    '60102',
    '60103',
    '60104',
    '60106',
    '60107',
    '60108',
    '60109',
    '60110',
    '60111',
    '60112',
    '60113',
    '60115',
    '60118',
    '60119',
    '60120',
    '60123',
    '60124',
    '60126',
    '60129',
    '60130',
    '60131',
    '60133',
    '60134',
    '60135',
    '60136',
    '60137',
    '60139',
    '60140',
    '60141',
    '60142',
    '60143',
    '60144',
    '60145',
    '60146',
    '60148',
    '60150',
    '60151',
    '60152',
    '60153',
    '60154',
    '60155',
    '60156',
    '60157',
    '60160',
    '60162',
    '60163',
    '60164',
    '60165',
    '60169',
    '60171',
    '60172',
    '60173',
    '60174',
    '60175',
    '60176',
    '60177',
    '60178',
    '60180',
    '60181',
    '60184',
    '60185',
    '60187',
    '60188',
    '60189',
    '60190',
    '60191',
    '60192',
    '60193',
    '60194',
    '60195',
    '60201',
    '60202',
    '60203',
    '60301',
    '60302',
    '60304',
    '60305',
    '60401',
    '60402',
    '60403',
    '60404',
    '60406',
    '60407',
    '60408',
    '60409',
    '60410',
    '60411',
    '60415',
    '60416',
    '60417',
    '60419',
    '60420',
    '60421',
    '60422',
    '60423',
    '60424',
    '60425',
    '60426',
    '60428',
    '60429',
    '60430',
    '60431',
    '60432',
    '60433',
    '60435',
    '60436',
    '60437',
    '60438',
    '60439',
    '60440',
    '60441',
    '60442',
    '60443',
    '60444',
    '60445',
    '60446',
    '60447',
    '60448',
    '60449',
    '60450',
    '60451',
    '60452',
    '60453',
    '60455',
    '60456',
    '60457',
    '60458',
    '60459',
    '60460',
    '60461',
    '60462',
    '60463',
    '60464',
    '60465',
    '60466',
    '60467',
    '60468',
    '60469',
    '60470',
    '60471',
    '60472',
    '60473',
    '60474',
    '60475',
    '60476',
    '60477',
    '60478',
    '60479',
    '60480',
    '60481',
    '60482',
    '60484',
    '60487',
    '60490',
    '60491',
    '60501',
    '60502',
    '60503',
    '60504',
    '60505',
    '60506',
    '60510',
    '60511',
    '60512',
    '60513',
    '60514',
    '60515',
    '60516',
    '60517',
    '60518',
    '60519',
    '60520',
    '60521',
    '60523',
    '60525',
    '60526',
    '60527',
    '60530',
    '60531',
    '60532',
    '60534',
    '60536',
    '60537',
    '60538',
    '60539',
    '60540',
    '60541',
    '60542',
    '60543',
    '60544',
    '60545',
    '60546',
    '60548',
    '60549',
    '60550',
    '60551',
    '60552',
    '60553',
    '60554',
    '60555',
    '60556',
    '60557',
    '60558',
    '60559',
    '60560',
    '60561',
    '60563',
    '60564',
    '60565',
    '60585',
    '60586',
    '60601',
    '60602',
    '60603',
    '60604',
    '60605',
    '60606',
    '60607',
    '60608',
    '60609',
    '60610',
    '60611',
    '60612',
    '60613',
    '60614',
    '60615',
    '60616',
    '60617',
    '60618',
    '60619',
    '60620',
    '60621',
    '60622',
    '60623',
    '60624',
    '60625',
    '60626',
    '60628',
    '60629',
    '60630',
    '60631',
    '60632',
    '60633',
    '60634',
    '60636',
    '60637',
    '60638',
    '60639',
    '60640',
    '60641',
    '60642',
    '60643',
    '60644',
    '60645',
    '60646',
    '60647',
    '60649',
    '60651',
    '60652',
    '60653',
    '60654',
    '60655',
    '60656',
    '60657',
    '60659',
    '60660',
    '60661',
    '60706',
    '60707',
    '60712',
    '60714',
    '60803',
    '60804',
    '60805',
    '60827',
    '60901',
    '60910',
    '60911',
    '60912',
    '60913',
    '60914',
    '60915',
    '60917',
    '60918',
    '60919',
    '60920',
    '60921',
    '60922',
    '60924',
    '60926',
    '60927',
    '60928',
    '60929',
    '60930',
    '60931',
    '60932',
    '60933',
    '60934',
    '60935',
    '60936',
    '60938',
    '60940',
    '60941',
    '60942',
    '60945',
    '60946',
    '60948',
    '60949',
    '60950',
    '60951',
    '60952',
    '60953',
    '60954',
    '60955',
    '60957',
    '60958',
    '60959',
    '60960',
    '60961',
    '60962',
    '60963',
    '60964',
    '60966',
    '60968',
    '60969',
    '60970',
    '60973',
    '60974',
    '61001',
    '61006',
    '61007',
    '61008',
    '61010',
    '61011',
    '61012',
    '61013',
    '61014',
    '61015',
    '61016',
    '61018',
    '61019',
    '61020',
    '61021',
    '61024',
    '61025',
    '61027',
    '61028',
    '61030',
    '61031',
    '61032',
    '61036',
    '61037',
    '61038',
    '61039',
    '61041',
    '61042',
    '61043',
    '61044',
    '61046',
    '61047',
    '61048',
    '61049',
    '61050',
    '61051',
    '61052',
    '61053',
    '61054',
    '61057',
    '61059',
    '61060',
    '61061',
    '61062',
    '61063',
    '61064',
    '61065',
    '61067',
    '61068',
    '61070',
    '61071',
    '61072',
    '61073',
    '61074',
    '61075',
    '61077',
    '61078',
    '61079',
    '61080',
    '61081',
    '61084',
    '61085',
    '61087',
    '61088',
    '61089',
    '61091',
    '61101',
    '61102',
    '61103',
    '61104',
    '61107',
    '61108',
    '61109',
    '61111',
    '61112',
    '61114',
    '61115',
    '61201',
    '61230',
    '61231',
    '61232',
    '61234',
    '61235',
    '61236',
    '61238',
    '61239',
    '61240',
    '61241',
    '61242',
    '61243',
    '61244',
    '61250',
    '61251',
    '61252',
    '61254',
    '61256',
    '61257',
    '61258',
    '61259',
    '61260',
    '61261',
    '61262',
    '61263',
    '61264',
    '61265',
    '61270',
    '61272',
    '61273',
    '61274',
    '61275',
    '61276',
    '61277',
    '61278',
    '61279',
    '61281',
    '61282',
    '61283',
    '61284',
    '61285',
    '61301',
    '61310',
    '61311',
    '61312',
    '61313',
    '61314',
    '61315',
    '61316',
    '61317',
    '61318',
    '61319',
    '61320',
    '61321',
    '61322',
    '61323',
    '61324',
    '61325',
    '61326',
    '61327',
    '61328',
    '61329',
    '61330',
    '61331',
    '61332',
    '61333',
    '61334',
    '61335',
    '61336',
    '61337',
    '61338',
    '61340',
    '61341',
    '61342',
    '61344',
    '61345',
    '61346',
    '61348',
    '61349',
    '61350',
    '61353',
    '61354',
    '61356',
    '61358',
    '61359',
    '61360',
    '61361',
    '61362',
    '61363',
    '61364',
    '61367',
    '61368',
    '61369',
    '61370',
    '61372',
    '61373',
    '61374',
    '61375',
    '61376',
    '61377',
    '61378',
    '61379',
    '61401',
    '61410',
    '61411',
    '61412',
    '61413',
    '61414',
    '61415',
    '61416',
    '61417',
    '61418',
    '61419',
    '61420',
    '61421',
    '61422',
    '61423',
    '61424',
    '61425',
    '61426',
    '61427',
    '61428',
    '61430',
    '61431',
    '61432',
    '61433',
    '61434',
    '61435',
    '61436',
    '61437',
    '61438',
    '61439',
    '61440',
    '61441',
    '61442',
    '61443',
    '61447',
    '61448',
    '61449',
    '61450',
    '61451',
    '61452',
    '61453',
    '61454',
    '61455',
    '61458',
    '61459',
    '61460',
    '61462',
    '61465',
    '61466',
    '61467',
    '61468',
    '61469',
    '61470',
    '61471',
    '61472',
    '61473',
    '61474',
    '61475',
    '61476',
    '61477',
    '61478',
    '61479',
    '61480',
    '61482',
    '61483',
    '61484',
    '61485',
    '61486',
    '61488',
    '61489',
    '61490',
    '61491',
    '61501',
    '61516',
    '61517',
    '61519',
    '61520',
    '61523',
    '61524',
    '61525',
    '61526',
    '61528',
    '61529',
    '61530',
    '61531',
    '61532',
    '61533',
    '61534',
    '61535',
    '61536',
    '61537',
    '61539',
    '61540',
    '61541',
    '61542',
    '61543',
    '61544',
    '61545',
    '61546',
    '61547',
    '61548',
    '61550',
    '61552',
    '61553',
    '61554',
    '61559',
    '61560',
    '61561',
    '61562',
    '61563',
    '61564',
    '61565',
    '61567',
    '61568',
    '61569',
    '61570',
    '61571',
    '61572',
    '61602',
    '61603',
    '61604',
    '61605',
    '61606',
    '61607',
    '61610',
    '61611',
    '61614',
    '61615',
    '61616',
    '61625',
    '61701',
    '61704',
    '61705',
    '61720',
    '61721',
    '61722',
    '61723',
    '61724',
    '61725',
    '61726',
    '61727',
    '61728',
    '61729',
    '61730',
    '61731',
    '61732',
    '61733',
    '61734',
    '61735',
    '61736',
    '61737',
    '61738',
    '61739',
    '61740',
    '61741',
    '61742',
    '61743',
    '61744',
    '61745',
    '61747',
    '61748',
    '61749',
    '61750',
    '61751',
    '61752',
    '61753',
    '61754',
    '61755',
    '61756',
    '61759',
    '61760',
    '61761',
    '61764',
    '61769',
    '61770',
    '61771',
    '61772',
    '61773',
    '61774',
    '61775',
    '61776',
    '61777',
    '61778',
    '61801',
    '61802',
    '61810',
    '61811',
    '61812',
    '61813',
    '61814',
    '61815',
    '61816',
    '61817',
    '61818',
    '61820',
    '61821',
    '61822',
    '61830',
    '61831',
    '61832',
    '61833',
    '61834',
    '61839',
    '61840',
    '61841',
    '61842',
    '61843',
    '61844',
    '61845',
    '61846',
    '61847',
    '61848',
    '61849',
    '61850',
    '61851',
    '61852',
    '61853',
    '61854',
    '61855',
    '61856',
    '61857',
    '61858',
    '61859',
    '61862',
    '61863',
    '61864',
    '61865',
    '61866',
    '61870',
    '61871',
    '61872',
    '61873',
    '61874',
    '61875',
    '61876',
    '61877',
    '61878',
    '61880',
    '61882',
    '61883',
    '61884',
    '61910',
    '61911',
    '61912',
    '61913',
    '61914',
    '61917',
    '61919',
    '61920',
    '61924',
    '61925',
    '61928',
    '61929',
    '61930',
    '61931',
    '61932',
    '61933',
    '61936',
    '61937',
    '61938',
    '61940',
    '61941',
    '61942',
    '61943',
    '61944',
    '61949',
    '61951',
    '61953',
    '61955',
    '61956',
    '61957',
    '62001',
    '62002',
    '62006',
    '62009',
    '62010',
    '62011',
    '62012',
    '62013',
    '62014',
    '62015',
    '62016',
    '62017',
    '62018',
    '62019',
    '62021',
    '62022',
    '62023',
    '62024',
    '62025',
    '62027',
    '62028',
    '62030',
    '62031',
    '62032',
    '62033',
    '62034',
    '62035',
    '62036',
    '62037',
    '62040',
    '62044',
    '62045',
    '62046',
    '62047',
    '62048',
    '62049',
    '62050',
    '62051',
    '62052',
    '62053',
    '62054',
    '62056',
    '62058',
    '62059',
    '62060',
    '62061',
    '62062',
    '62063',
    '62065',
    '62067',
    '62069',
    '62070',
    '62074',
    '62075',
    '62076',
    '62077',
    '62078',
    '62079',
    '62080',
    '62081',
    '62082',
    '62083',
    '62084',
    '62085',
    '62086',
    '62087',
    '62088',
    '62089',
    '62090',
    '62091',
    '62092',
    '62093',
    '62094',
    '62095',
    '62097',
    '62098',
    '62201',
    '62203',
    '62204',
    '62205',
    '62206',
    '62207',
    '62208',
    '62214',
    '62215',
    '62216',
    '62217',
    '62218',
    '62219',
    '62220',
    '62221',
    '62223',
    '62225',
    '62226',
    '62230',
    '62231',
    '62232',
    '62233',
    '62234',
    '62236',
    '62237',
    '62238',
    '62239',
    '62240',
    '62241',
    '62242',
    '62243',
    '62244',
    '62245',
    '62246',
    '62248',
    '62249',
    '62250',
    '62253',
    '62254',
    '62255',
    '62257',
    '62258',
    '62260',
    '62261',
    '62262',
    '62263',
    '62264',
    '62265',
    '62266',
    '62268',
    '62269',
    '62271',
    '62272',
    '62273',
    '62274',
    '62275',
    '62277',
    '62278',
    '62279',
    '62280',
    '62281',
    '62282',
    '62284',
    '62285',
    '62286',
    '62288',
    '62289',
    '62292',
    '62293',
    '62294',
    '62295',
    '62297',
    '62298',
    '62301',
    '62305',
    '62311',
    '62312',
    '62313',
    '62314',
    '62316',
    '62319',
    '62320',
    '62321',
    '62323',
    '62324',
    '62325',
    '62326',
    '62330',
    '62334',
    '62336',
    '62338',
    '62339',
    '62340',
    '62341',
    '62343',
    '62344',
    '62345',
    '62346',
    '62347',
    '62348',
    '62349',
    '62351',
    '62352',
    '62353',
    '62354',
    '62355',
    '62356',
    '62357',
    '62358',
    '62359',
    '62360',
    '62361',
    '62362',
    '62363',
    '62365',
    '62366',
    '62367',
    '62370',
    '62373',
    '62374',
    '62375',
    '62376',
    '62378',
    '62379',
    '62380',
    '62401',
    '62410',
    '62411',
    '62413',
    '62414',
    '62417',
    '62418',
    '62419',
    '62420',
    '62421',
    '62422',
    '62423',
    '62424',
    '62425',
    '62426',
    '62427',
    '62428',
    '62431',
    '62432',
    '62433',
    '62434',
    '62436',
    '62438',
    '62439',
    '62440',
    '62441',
    '62442',
    '62443',
    '62444',
    '62445',
    '62446',
    '62447',
    '62448',
    '62449',
    '62450',
    '62451',
    '62452',
    '62454',
    '62458',
    '62459',
    '62460',
    '62461',
    '62462',
    '62463',
    '62465',
    '62466',
    '62467',
    '62468',
    '62469',
    '62471',
    '62473',
    '62474',
    '62475',
    '62476',
    '62477',
    '62478',
    '62479',
    '62480',
    '62481',
    '62501',
    '62510',
    '62512',
    '62513',
    '62514',
    '62515',
    '62517',
    '62518',
    '62519',
    '62520',
    '62521',
    '62522',
    '62523',
    '62526',
    '62530',
    '62531',
    '62532',
    '62533',
    '62534',
    '62535',
    '62536',
    '62537',
    '62538',
    '62539',
    '62540',
    '62541',
    '62543',
    '62544',
    '62545',
    '62546',
    '62547',
    '62548',
    '62549',
    '62550',
    '62551',
    '62553',
    '62554',
    '62555',
    '62556',
    '62557',
    '62558',
    '62560',
    '62561',
    '62563',
    '62565',
    '62567',
    '62568',
    '62570',
    '62571',
    '62572',
    '62573',
    '62601',
    '62610',
    '62611',
    '62612',
    '62613',
    '62615',
    '62617',
    '62618',
    '62621',
    '62622',
    '62624',
    '62625',
    '62626',
    '62627',
    '62628',
    '62629',
    '62630',
    '62631',
    '62633',
    '62634',
    '62635',
    '62638',
    '62639',
    '62640',
    '62642',
    '62643',
    '62644',
    '62649',
    '62650',
    '62655',
    '62656',
    '62661',
    '62663',
    '62664',
    '62665',
    '62666',
    '62667',
    '62668',
    '62670',
    '62671',
    '62672',
    '62673',
    '62674',
    '62675',
    '62677',
    '62681',
    '62682',
    '62684',
    '62685',
    '62688',
    '62689',
    '62690',
    '62691',
    '62692',
    '62693',
    '62694',
    '62695',
    '62701',
    '62702',
    '62703',
    '62704',
    '62707',
    '62711',
    '62712',
    '62801',
    '62803',
    '62806',
    '62807',
    '62808',
    '62809',
    '62810',
    '62811',
    '62812',
    '62814',
    '62815',
    '62816',
    '62817',
    '62818',
    '62819',
    '62820',
    '62821',
    '62822',
    '62823',
    '62824',
    '62825',
    '62827',
    '62828',
    '62829',
    '62830',
    '62831',
    '62832',
    '62833',
    '62835',
    '62836',
    '62837',
    '62838',
    '62839',
    '62841',
    '62842',
    '62843',
    '62844',
    '62846',
    '62848',
    '62849',
    '62850',
    '62851',
    '62852',
    '62853',
    '62854',
    '62856',
    '62858',
    '62859',
    '62860',
    '62861',
    '62862',
    '62863',
    '62864',
    '62865',
    '62867',
    '62868',
    '62869',
    '62870',
    '62871',
    '62872',
    '62874',
    '62875',
    '62876',
    '62877',
    '62878',
    '62879',
    '62880',
    '62881',
    '62882',
    '62883',
    '62884',
    '62885',
    '62886',
    '62887',
    '62888',
    '62889',
    '62890',
    '62891',
    '62892',
    '62893',
    '62894',
    '62895',
    '62896',
    '62897',
    '62898',
    '62899',
    '62901',
    '62902',
    '62903',
    '62905',
    '62906',
    '62907',
    '62908',
    '62910',
    '62912',
    '62914',
    '62915',
    '62916',
    '62917',
    '62918',
    '62919',
    '62920',
    '62921',
    '62922',
    '62923',
    '62924',
    '62926',
    '62927',
    '62928',
    '62930',
    '62931',
    '62932',
    '62933',
    '62934',
    '62935',
    '62938',
    '62939',
    '62940',
    '62941',
    '62942',
    '62943',
    '62946',
    '62947',
    '62948',
    '62949',
    '62950',
    '62951',
    '62952',
    '62953',
    '62954',
    '62956',
    '62957',
    '62958',
    '62959',
    '62960',
    '62961',
    '62962',
    '62963',
    '62964',
    '62965',
    '62966',
    '62967',
    '62969',
    '62970',
    '62972',
    '62974',
    '62975',
    '62976',
    '62977',
    '62979',
    '62982',
    '62983',
    '62984',
    '62985',
    '62987',
    '62988',
    '62990',
    '62992',
    '62994',
    '62995',
    '62996',
    '62997',
    '62998',
    '62999',
    '63673'
  ],
  '01': [
    '30165',
    '31905',
    '35004',
    '35005',
    '35006',
    '35007',
    '35010',
    '35013',
    '35014',
    '35016',
    '35019',
    '35020',
    '35022',
    '35023',
    '35031',
    '35032',
    '35033',
    '35034',
    '35035',
    '35036',
    '35040',
    '35042',
    '35043',
    '35044',
    '35045',
    '35046',
    '35049',
    '35051',
    '35052',
    '35053',
    '35054',
    '35055',
    '35057',
    '35058',
    '35060',
    '35061',
    '35062',
    '35063',
    '35064',
    '35068',
    '35070',
    '35071',
    '35072',
    '35073',
    '35074',
    '35077',
    '35078',
    '35079',
    '35080',
    '35082',
    '35083',
    '35085',
    '35087',
    '35089',
    '35091',
    '35094',
    '35096',
    '35097',
    '35098',
    '35111',
    '35112',
    '35114',
    '35115',
    '35116',
    '35117',
    '35118',
    '35119',
    '35120',
    '35121',
    '35124',
    '35125',
    '35126',
    '35127',
    '35128',
    '35130',
    '35131',
    '35133',
    '35135',
    '35136',
    '35139',
    '35143',
    '35146',
    '35147',
    '35148',
    '35149',
    '35150',
    '35151',
    '35160',
    '35171',
    '35172',
    '35173',
    '35175',
    '35176',
    '35178',
    '35179',
    '35180',
    '35183',
    '35184',
    '35186',
    '35187',
    '35188',
    '35203',
    '35204',
    '35205',
    '35206',
    '35207',
    '35208',
    '35209',
    '35210',
    '35211',
    '35212',
    '35213',
    '35214',
    '35215',
    '35216',
    '35217',
    '35218',
    '35221',
    '35222',
    '35223',
    '35224',
    '35226',
    '35228',
    '35229',
    '35233',
    '35234',
    '35235',
    '35242',
    '35243',
    '35244',
    '35254',
    '35401',
    '35404',
    '35405',
    '35406',
    '35441',
    '35442',
    '35443',
    '35444',
    '35446',
    '35447',
    '35452',
    '35453',
    '35456',
    '35457',
    '35458',
    '35459',
    '35460',
    '35461',
    '35462',
    '35463',
    '35464',
    '35466',
    '35469',
    '35470',
    '35473',
    '35474',
    '35475',
    '35476',
    '35477',
    '35480',
    '35481',
    '35490',
    '35501',
    '35503',
    '35504',
    '35540',
    '35541',
    '35542',
    '35543',
    '35544',
    '35545',
    '35546',
    '35548',
    '35549',
    '35550',
    '35552',
    '35553',
    '35554',
    '35555',
    '35559',
    '35563',
    '35564',
    '35565',
    '35570',
    '35571',
    '35572',
    '35574',
    '35575',
    '35576',
    '35577',
    '35578',
    '35579',
    '35580',
    '35581',
    '35582',
    '35584',
    '35585',
    '35586',
    '35587',
    '35592',
    '35593',
    '35594',
    '35601',
    '35603',
    '35610',
    '35611',
    '35613',
    '35614',
    '35615',
    '35616',
    '35618',
    '35619',
    '35620',
    '35621',
    '35622',
    '35630',
    '35633',
    '35634',
    '35640',
    '35643',
    '35645',
    '35646',
    '35647',
    '35648',
    '35649',
    '35650',
    '35651',
    '35652',
    '35653',
    '35654',
    '35660',
    '35661',
    '35670',
    '35671',
    '35672',
    '35673',
    '35674',
    '35677',
    '35739',
    '35740',
    '35741',
    '35744',
    '35745',
    '35746',
    '35747',
    '35748',
    '35749',
    '35750',
    '35751',
    '35752',
    '35754',
    '35755',
    '35756',
    '35757',
    '35758',
    '35759',
    '35760',
    '35761',
    '35763',
    '35764',
    '35765',
    '35766',
    '35768',
    '35769',
    '35771',
    '35772',
    '35773',
    '35774',
    '35775',
    '35776',
    '35801',
    '35802',
    '35803',
    '35805',
    '35806',
    '35808',
    '35810',
    '35811',
    '35816',
    '35824',
    '35896',
    '35901',
    '35903',
    '35904',
    '35905',
    '35906',
    '35907',
    '35950',
    '35951',
    '35952',
    '35953',
    '35954',
    '35956',
    '35957',
    '35958',
    '35959',
    '35960',
    '35961',
    '35962',
    '35963',
    '35966',
    '35967',
    '35968',
    '35971',
    '35972',
    '35973',
    '35974',
    '35975',
    '35976',
    '35978',
    '35979',
    '35980',
    '35981',
    '35983',
    '35984',
    '35986',
    '35987',
    '35988',
    '35989',
    '35990',
    '36003',
    '36005',
    '36006',
    '36009',
    '36010',
    '36013',
    '36016',
    '36017',
    '36020',
    '36022',
    '36024',
    '36025',
    '36026',
    '36027',
    '36028',
    '36029',
    '36030',
    '36031',
    '36032',
    '36033',
    '36034',
    '36035',
    '36036',
    '36037',
    '36038',
    '36039',
    '36040',
    '36041',
    '36042',
    '36043',
    '36046',
    '36047',
    '36048',
    '36049',
    '36051',
    '36052',
    '36053',
    '36054',
    '36064',
    '36066',
    '36067',
    '36069',
    '36071',
    '36075',
    '36078',
    '36079',
    '36080',
    '36081',
    '36082',
    '36083',
    '36088',
    '36089',
    '36091',
    '36092',
    '36093',
    '36104',
    '36105',
    '36106',
    '36107',
    '36108',
    '36109',
    '36110',
    '36111',
    '36112',
    '36113',
    '36115',
    '36116',
    '36117',
    '36201',
    '36203',
    '36205',
    '36206',
    '36207',
    '36250',
    '36251',
    '36255',
    '36256',
    '36258',
    '36260',
    '36262',
    '36263',
    '36264',
    '36265',
    '36266',
    '36267',
    '36268',
    '36269',
    '36271',
    '36272',
    '36273',
    '36274',
    '36276',
    '36277',
    '36278',
    '36279',
    '36280',
    '36301',
    '36303',
    '36305',
    '36310',
    '36311',
    '36312',
    '36313',
    '36314',
    '36316',
    '36317',
    '36318',
    '36319',
    '36320',
    '36321',
    '36322',
    '36323',
    '36330',
    '36340',
    '36343',
    '36344',
    '36345',
    '36346',
    '36350',
    '36351',
    '36352',
    '36353',
    '36360',
    '36362',
    '36370',
    '36371',
    '36373',
    '36374',
    '36375',
    '36376',
    '36401',
    '36420',
    '36421',
    '36425',
    '36426',
    '36432',
    '36435',
    '36436',
    '36439',
    '36441',
    '36442',
    '36444',
    '36445',
    '36446',
    '36451',
    '36453',
    '36454',
    '36455',
    '36456',
    '36460',
    '36467',
    '36470',
    '36471',
    '36473',
    '36474',
    '36475',
    '36476',
    '36477',
    '36480',
    '36481',
    '36482',
    '36483',
    '36502',
    '36505',
    '36507',
    '36509',
    '36511',
    '36512',
    '36513',
    '36518',
    '36521',
    '36522',
    '36523',
    '36524',
    '36525',
    '36526',
    '36527',
    '36528',
    '36529',
    '36530',
    '36532',
    '36535',
    '36538',
    '36539',
    '36540',
    '36541',
    '36542',
    '36543',
    '36544',
    '36545',
    '36548',
    '36549',
    '36550',
    '36551',
    '36553',
    '36555',
    '36556',
    '36558',
    '36559',
    '36560',
    '36561',
    '36562',
    '36564',
    '36567',
    '36568',
    '36569',
    '36571',
    '36572',
    '36574',
    '36575',
    '36576',
    '36578',
    '36579',
    '36580',
    '36581',
    '36582',
    '36583',
    '36584',
    '36585',
    '36587',
    '36590',
    '36602',
    '36603',
    '36604',
    '36605',
    '36606',
    '36607',
    '36608',
    '36609',
    '36610',
    '36611',
    '36612',
    '36613',
    '36615',
    '36616',
    '36617',
    '36618',
    '36619',
    '36688',
    '36693',
    '36695',
    '36701',
    '36703',
    '36720',
    '36722',
    '36723',
    '36726',
    '36727',
    '36728',
    '36732',
    '36736',
    '36738',
    '36740',
    '36742',
    '36744',
    '36748',
    '36749',
    '36750',
    '36751',
    '36752',
    '36753',
    '36754',
    '36756',
    '36758',
    '36759',
    '36761',
    '36763',
    '36765',
    '36766',
    '36767',
    '36768',
    '36769',
    '36773',
    '36775',
    '36776',
    '36782',
    '36783',
    '36784',
    '36785',
    '36786',
    '36790',
    '36792',
    '36793',
    '36801',
    '36804',
    '36830',
    '36832',
    '36849',
    '36850',
    '36852',
    '36853',
    '36854',
    '36855',
    '36856',
    '36858',
    '36859',
    '36860',
    '36861',
    '36862',
    '36863',
    '36865',
    '36866',
    '36867',
    '36869',
    '36870',
    '36871',
    '36874',
    '36875',
    '36877',
    '36879',
    '36901',
    '36904',
    '36907',
    '36908',
    '36910',
    '36912',
    '36913',
    '36915',
    '36916',
    '36919',
    '36921',
    '36922',
    '36925',
    '38852'
  ],
  '44': [
    '02802',
    '02804',
    '02806',
    '02807',
    '02808',
    '02809',
    '02812',
    '02813',
    '02814',
    '02815',
    '02816',
    '02817',
    '02818',
    '02822',
    '02825',
    '02826',
    '02827',
    '02828',
    '02830',
    '02831',
    '02832',
    '02833',
    '02835',
    '02836',
    '02837',
    '02838',
    '02839',
    '02840',
    '02841',
    '02842',
    '02852',
    '02857',
    '02858',
    '02859',
    '02860',
    '02861',
    '02863',
    '02864',
    '02865',
    '02871',
    '02872',
    '02873',
    '02874',
    '02875',
    '02876',
    '02878',
    '02879',
    '02881',
    '02882',
    '02885',
    '02886',
    '02888',
    '02889',
    '02891',
    '02892',
    '02893',
    '02894',
    '02895',
    '02896',
    '02898',
    '02903',
    '02904',
    '02905',
    '02906',
    '02907',
    '02908',
    '02909',
    '02910',
    '02911',
    '02912',
    '02914',
    '02915',
    '02916',
    '02917',
    '02919',
    '02920',
    '02921'
  ],
  '56': [
    '57717',
    '82001',
    '82005',
    '82007',
    '82009',
    '82050',
    '82051',
    '82052',
    '82053',
    '82054',
    '82055',
    '82058',
    '82059',
    '82060',
    '82061',
    '82063',
    '82070',
    '82072',
    '82073',
    '82081',
    '82082',
    '82083',
    '82084',
    '82190',
    '82201',
    '82210',
    '82212',
    '82213',
    '82214',
    '82215',
    '82217',
    '82219',
    '82221',
    '82222',
    '82223',
    '82224',
    '82225',
    '82227',
    '82229',
    '82240',
    '82242',
    '82243',
    '82244',
    '82301',
    '82321',
    '82322',
    '82323',
    '82324',
    '82325',
    '82327',
    '82329',
    '82331',
    '82332',
    '82334',
    '82335',
    '82336',
    '82401',
    '82410',
    '82411',
    '82412',
    '82414',
    '82420',
    '82421',
    '82422',
    '82423',
    '82426',
    '82428',
    '82430',
    '82431',
    '82432',
    '82433',
    '82434',
    '82435',
    '82440',
    '82441',
    '82442',
    '82443',
    '82450',
    '82501',
    '82510',
    '82512',
    '82513',
    '82514',
    '82515',
    '82516',
    '82520',
    '82523',
    '82601',
    '82604',
    '82609',
    '82620',
    '82630',
    '82633',
    '82635',
    '82636',
    '82637',
    '82638',
    '82639',
    '82640',
    '82642',
    '82643',
    '82644',
    '82646',
    '82648',
    '82649',
    '82701',
    '82710',
    '82711',
    '82712',
    '82714',
    '82715',
    '82716',
    '82718',
    '82720',
    '82721',
    '82723',
    '82725',
    '82727',
    '82729',
    '82730',
    '82731',
    '82732',
    '82801',
    '82831',
    '82832',
    '82833',
    '82834',
    '82835',
    '82836',
    '82837',
    '82838',
    '82839',
    '82842',
    '82844',
    '82845',
    '82901',
    '82922',
    '82923',
    '82925',
    '82929',
    '82930',
    '82932',
    '82933',
    '82934',
    '82935',
    '82936',
    '82937',
    '82938',
    '82939',
    '82941',
    '82942',
    '82943',
    '82944',
    '82945',
    '83001',
    '83011',
    '83012',
    '83013',
    '83014',
    '83025',
    '83101',
    '83110',
    '83111',
    '83112',
    '83113',
    '83114',
    '83115',
    '83116',
    '83118',
    '83119',
    '83120',
    '83121',
    '83122',
    '83123',
    '83124',
    '83126',
    '83127',
    '83128',
    '83414'
  ],
  '78': [
    '00801',
    '00802',
    '00803',
    '00804',
    '00805',
    '00820',
    '00821',
    '00822',
    '00823',
    '00824',
    '00830',
    '00831',
    '00840',
    '00841',
    '00850',
    '00851'
  ]
}


// keys are state FIPS codes, values are dicts. In those dicts, the keys are county FIPS codes, and the values are lists of the zip codes found in each county.
export const zipByCountyByState = {
  '28': {
    '079': [
      '39051',
      '39090',
      '39094',
      '39160',
      '39189',
      '39350',
      '39365'
    ],
    '123': [
      '39057',
      '39074',
      '39092',
      '39094',
      '39117',
      '39145',
      '39152',
      '39189',
      '39359'
    ],
    '099': [
      '39051',
      '39325',
      '39337',
      '39346',
      '39350',
      '39354',
      '39365'
    ],
    '067': [
      '39168',
      '39401',
      '39437',
      '39439',
      '39440',
      '39443',
      '39459',
      '39464',
      '39465',
      '39476',
      '39477',
      '39479',
      '39480'
    ],
    '119': [
      '38606',
      '38621',
      '38622',
      '38623',
      '38643',
      '38645',
      '38646',
      '38670',
      '38964'
    ],
    '153': [
      '39322',
      '39360',
      '39362',
      '39367',
      '39439',
      '39443',
      '39476'
    ],
    '055': [
      '38704',
      '38744',
      '38745',
      '39113',
      '39159',
      '39177',
      '39183'
    ],
    '029': [
      '39059',
      '39078',
      '39083',
      '39086',
      '39144',
      '39170',
      '39175',
      '39191'
    ],
    '109': [
      '39426',
      '39455',
      '39466',
      '39470',
      '39573'
    ],
    '021': [
      '39086',
      '39096',
      '39144',
      '39150',
      '39175'
    ],
    '053': [
      '38753',
      '38754',
      '38924',
      '39038',
      '39097',
      '39115',
      '39166',
      '39194'
    ],
    '031': [
      '39119',
      '39168',
      '39401',
      '39402',
      '39428',
      '39474',
      '39479',
      '39482'
    ],
    '091': [
      '39421',
      '39429',
      '39455',
      '39478',
      '39482',
      '39483',
      '39643',
      '39667'
    ],
    '063': [
      '39069',
      '39096',
      '39120',
      '39144',
      '39653',
      '39668'
    ],
    '059': [
      '39452',
      '39532',
      '39553',
      '39562',
      '39563',
      '39564',
      '39565',
      '39567',
      '39573',
      '39581'
    ],
    '025': [
      '39741',
      '39750',
      '39751',
      '39755',
      '39756',
      '39773',
      '39776'
    ],
    '121': [
      '39042',
      '39044',
      '39047',
      '39073',
      '39094',
      '39114',
      '39117',
      '39145',
      '39167',
      '39193',
      '39208',
      '39218',
      '39232'
    ],
    '061': [
      '39330',
      '39332',
      '39336',
      '39338',
      '39345',
      '39347',
      '39348',
      '39356',
      '39366',
      '39422',
      '39439',
      '39443',
      '39480',
      '39481'
    ],
    '151': [
      '38701',
      '38703',
      '38722',
      '38723',
      '38731',
      '38744',
      '38748',
      '38756',
      '38760'
    ],
    '111': [
      '39423',
      '39425',
      '39456',
      '39462',
      '39464',
      '39465',
      '39476',
      '39577'
    ],
    '039': [
      '39452',
      '39573'
    ],
    '149': [
      '39156',
      '39180',
      '39183'
    ],
    '011': [
      '38614',
      '38702',
      '38703',
      '38720',
      '38725',
      '38726',
      '38730',
      '38732',
      '38740',
      '38746',
      '38759',
      '38762',
      '38764',
      '38769',
      '38772',
      '38773',
      '38774',
      '38781'
    ],
    '117': [
      '38824',
      '38829',
      '38833',
      '38856',
      '38859',
      '38865',
      '38873'
    ],
    '041': [
      '39362',
      '39451',
      '39452',
      '39456',
      '39461',
      '39476'
    ],
    '033': [
      '38611',
      '38618',
      '38632',
      '38637',
      '38641',
      '38651',
      '38654',
      '38671',
      '38672',
      '38680'
    ],
    '037': [
      '39601',
      '39630',
      '39647',
      '39653',
      '39661',
      '39664',
      '39668'
    ],
    '159': [
      '39108',
      '39339',
      '39341',
      '39346',
      '39350',
      '39354',
      '39769'
    ],
    '147': [
      '39478',
      '39483',
      '39641',
      '39643',
      '39667'
    ],
    '145': [
      '38610',
      '38625',
      '38627',
      '38633',
      '38650',
      '38652',
      '38824',
      '38828',
      '38841',
      '38849',
      '38871'
    ],
    '085': [
      '39191',
      '39601',
      '39629',
      '39641',
      '39647',
      '39662',
      '39664',
      '39665',
      '39666',
      '39668'
    ],
    '143': [
      '38626',
      '38664',
      '38670',
      '38676'
    ],
    '103': [
      '39341',
      '39354',
      '39358',
      '39361',
      '39739',
      '39743'
    ],
    '089': [
      '39045',
      '39046',
      '39051',
      '39071',
      '39079',
      '39110',
      '39146',
      '39157',
      '39174',
      '39209',
      '39213'
    ],
    '045': [
      '39426',
      '39466',
      '39470',
      '39520',
      '39525',
      '39556',
      '39571',
      '39572',
      '39573',
      '39576'
    ],
    '131': [
      '39455',
      '39561',
      '39573',
      '39574',
      '39577'
    ],
    '107': [
      '38606',
      '38619',
      '38620',
      '38621',
      '38622',
      '38658',
      '38665',
      '38666',
      '38927',
      '38965'
    ],
    '155': [
      '38916',
      '38925',
      '38929',
      '39737',
      '39744',
      '39750',
      '39751',
      '39752',
      '39767',
      '39771'
    ],
    '127': [
      '39044',
      '39062',
      '39073',
      '39082',
      '39111',
      '39114',
      '39119',
      '39140',
      '39149'
    ],
    '161': [
      '38922',
      '38927',
      '38948',
      '38953',
      '38961',
      '38965'
    ],
    '137': [
      '38618',
      '38619',
      '38635',
      '38665',
      '38668'
    ],
    '139': [
      '38603',
      '38610',
      '38625',
      '38629',
      '38663',
      '38674',
      '38683',
      '38824',
      '38829'
    ],
    '097': [
      '38925',
      '38967',
      '39176',
      '39745',
      '39747',
      '39767'
    ],
    '057': [
      '38804',
      '38824',
      '38843',
      '38847',
      '38849',
      '38855',
      '38856',
      '38858',
      '38870',
      '38876'
    ],
    '001': [
      '39120',
      '39661'
    ],
    '129': [
      '39042',
      '39074',
      '39092',
      '39111',
      '39114',
      '39116',
      '39117',
      '39119',
      '39152',
      '39153',
      '39168',
      '39336',
      '39338',
      '39422'
    ],
    '017': [
      '38850',
      '38851',
      '38860',
      '38868',
      '38878',
      '39751',
      '39756',
      '39776'
    ],
    '095': [
      '38821',
      '38844',
      '38848',
      '38858',
      '38860',
      '38868',
      '38870',
      '39730',
      '39740',
      '39746',
      '39756',
      '39773'
    ],
    '087': [
      '39701',
      '39702',
      '39705',
      '39736',
      '39740',
      '39743',
      '39759',
      '39766'
    ],
    '115': [
      '38801',
      '38826',
      '38828',
      '38841',
      '38850',
      '38863',
      '38864',
      '38868',
      '38869',
      '38871'
    ],
    '035': [
      '39401',
      '39402',
      '39406',
      '39425',
      '39455',
      '39465',
      '39475',
      '39577'
    ],
    '005': [
      '39631',
      '39633',
      '39638',
      '39645',
      '39652',
      '39653',
      '39657',
      '39664',
      '39666'
    ],
    '051': [
      '38924',
      '39038',
      '39063',
      '39079',
      '39095',
      '39146',
      '39169',
      '39192'
    ],
    '077': [
      '39140',
      '39191',
      '39641',
      '39654',
      '39656',
      '39662',
      '39663',
      '39665'
    ],
    '027': [
      '38614',
      '38617',
      '38626',
      '38630',
      '38631',
      '38639',
      '38644',
      '38645',
      '38720',
      '38767',
      '38963'
    ],
    '069': [
      '39320',
      '39325',
      '39326',
      '39328',
      '39335',
      '39350',
      '39352',
      '39354',
      '39358',
      '39361',
      '39365'
    ],
    '093': [
      '38611',
      '38618',
      '38635',
      '38642',
      '38654',
      '38659',
      '38661',
      '38685'
    ],
    '009': [
      '38603',
      '38610',
      '38629',
      '38633',
      '38635',
      '38642',
      '38647',
      '38650',
      '38659',
      '38663',
      '38683'
    ],
    '105': [
      '39743',
      '39750',
      '39755',
      '39759',
      '39760',
      '39762',
      '39769'
    ],
    '113': [
      '39635',
      '39641',
      '39648',
      '39652',
      '39657',
      '39662',
      '39666',
      '39667'
    ],
    '133': [
      '38732',
      '38736',
      '38737',
      '38738',
      '38749',
      '38751',
      '38753',
      '38754',
      '38759',
      '38761',
      '38762',
      '38768',
      '38771',
      '38773',
      '38778',
      '38963'
    ],
    '071': [
      '38601',
      '38606',
      '38619',
      '38627',
      '38655',
      '38673',
      '38677',
      '38685',
      '38863',
      '38871',
      '38949',
      '38965'
    ],
    '141': [
      '38326',
      '38827',
      '38833',
      '38838',
      '38846',
      '38847',
      '38852',
      '38873'
    ],
    '075': [
      '39301',
      '39305',
      '39307',
      '39309',
      '39320',
      '39323',
      '39325',
      '39326',
      '39330',
      '39335',
      '39342',
      '39364'
    ],
    '047': [
      '39501',
      '39503',
      '39507',
      '39530',
      '39531',
      '39532',
      '39534',
      '39540',
      '39560',
      '39565',
      '39571',
      '39573',
      '39574'
    ],
    '163': [
      '39039',
      '39040',
      '39088',
      '39095',
      '39146',
      '39162',
      '39179',
      '39194'
    ],
    '083': [
      '38736',
      '38761',
      '38924',
      '38930',
      '38941',
      '38944',
      '38945',
      '38946',
      '38952',
      '38954',
      '38958',
      '39038'
    ],
    '023': [
      '39301',
      '39330',
      '39347',
      '39355',
      '39360',
      '39363',
      '39366',
      '39367',
      '39439'
    ],
    '043': [
      '38901',
      '38914',
      '38922',
      '38925',
      '38929',
      '38940',
      '38953'
    ],
    '065': [
      '39119',
      '39140',
      '39421',
      '39427',
      '39429',
      '39474',
      '39482',
      '39656',
      '39663'
    ],
    '135': [
      '38643',
      '38737',
      '38901',
      '38920',
      '38921',
      '38927',
      '38928',
      '38940',
      '38948',
      '38950',
      '38953',
      '38957',
      '38961',
      '38962',
      '38963',
      '38964',
      '38966'
    ],
    '101': [
      '39057',
      '39092',
      '39323',
      '39325',
      '39327',
      '39330',
      '39332',
      '39336',
      '39337',
      '39345',
      '39365'
    ],
    '081': [
      '38801',
      '38804',
      '38824',
      '38826',
      '38828',
      '38843',
      '38849',
      '38857',
      '38858',
      '38860',
      '38862',
      '38866',
      '38868',
      '38879'
    ],
    '125': [
      '38721',
      '38748',
      '38765',
      '39054',
      '39061',
      '39088',
      '39159'
    ],
    '157': [
      '39631',
      '39633',
      '39638',
      '39669'
    ],
    '049': [
      '39041',
      '39056',
      '39059',
      '39066',
      '39071',
      '39154',
      '39170',
      '39174',
      '39175',
      '39201',
      '39202',
      '39203',
      '39204',
      '39206',
      '39209',
      '39211',
      '39212',
      '39213',
      '39216',
      '39217',
      '39269',
      '39272'
    ],
    '019': [
      '39108',
      '39735',
      '39744',
      '39745',
      '39750',
      '39752',
      '39767',
      '39769',
      '39772'
    ],
    '003': [
      '38683',
      '38833',
      '38834',
      '38846',
      '38865'
    ],
    '007': [
      '39051',
      '39067',
      '39079',
      '39090',
      '39108',
      '39160',
      '39176',
      '39192',
      '39745'
    ],
    '013': [
      '38850',
      '38864',
      '38878',
      '38913',
      '38914',
      '38915',
      '38916',
      '38929',
      '38951',
      '38965',
      '39744'
    ],
    '073': [
      '39401',
      '39402',
      '39429',
      '39455',
      '39475',
      '39482'
    ],
    '015': [
      '38901',
      '38917',
      '38923',
      '38924',
      '38930',
      '38940',
      '38943',
      '38947',
      '38954',
      '38967',
      '39095',
      '39176',
      '39192'
    ]
  },
  '47': {
    '167': [
      '38004',
      '38011',
      '38015',
      '38019',
      '38023',
      '38049',
      '38053',
      '38058',
      '38069',
      '72338'
    ],
    '079': [
      '38201',
      '38222',
      '38224',
      '38231',
      '38236',
      '38242',
      '38251',
      '38256'
    ],
    '131': [
      '38080',
      '38232',
      '38233',
      '38240',
      '38253',
      '38254',
      '38257',
      '38259',
      '38260',
      '38261'
    ],
    '009': [
      '37701',
      '37737',
      '37742',
      '37777',
      '37801',
      '37803',
      '37804',
      '37853',
      '37865',
      '37878',
      '37882',
      '37886',
      '37920'
    ],
    '119': [
      '37091',
      '37174',
      '38401',
      '38451',
      '38461',
      '38474',
      '38476',
      '38482',
      '38483',
      '38487'
    ],
    '153': [
      '37327',
      '37338',
      '37365',
      '37377',
      '37379',
      '37397'
    ],
    '055': [
      '37047',
      '37144',
      '38449',
      '38451',
      '38453',
      '38455',
      '38456',
      '38459',
      '38460',
      '38464',
      '38468',
      '38472',
      '38473',
      '38474',
      '38477',
      '38478',
      '38483'
    ],
    '109': [
      '38044',
      '38061',
      '38310',
      '38315',
      '38332',
      '38334',
      '38339',
      '38357',
      '38359',
      '38361',
      '38367',
      '38375',
      '38376',
      '38379'
    ],
    '053': [
      '38059',
      '38220',
      '38233',
      '38259',
      '38316',
      '38330',
      '38343',
      '38355',
      '38358',
      '38369',
      '38382'
    ],
    '063': [
      '37711',
      '37813',
      '37814',
      '37860',
      '37877',
      '37890',
      '37891'
    ],
    '171': [
      '37601',
      '37650',
      '37657',
      '37692'
    ],
    '177': [
      '37110',
      '37166',
      '37190',
      '37357',
      '37394',
      '38581'
    ],
    '059': [
      '37616',
      '37641',
      '37656',
      '37681',
      '37711',
      '37743',
      '37745',
      '37809',
      '37810',
      '37818'
    ],
    '137': [
      '38543',
      '38549',
      '38556',
      '38573',
      '38577',
      '42602'
    ],
    '111': [
      '37022',
      '37057',
      '37074',
      '37083',
      '37145',
      '37150',
      '37186'
    ],
    '121': [
      '37308',
      '37322',
      '37336',
      '37826',
      '37880'
    ],
    '117': [
      '37019',
      '37034',
      '37046',
      '37047',
      '37091',
      '37144',
      '38401',
      '38451',
      '38472'
    ],
    '035': [
      '37337',
      '37367',
      '37723',
      '37854',
      '38555',
      '38558',
      '38571',
      '38572',
      '38574',
      '38578',
      '38583'
    ],
    '033': [
      '38001',
      '38006',
      '38021',
      '38034',
      '38040',
      '38050',
      '38337',
      '38343'
    ],
    '037': [
      '37013',
      '37015',
      '37027',
      '37064',
      '37072',
      '37076',
      '37080',
      '37086',
      '37115',
      '37122',
      '37135',
      '37138',
      '37143',
      '37189',
      '37201',
      '37203',
      '37204',
      '37205',
      '37206',
      '37207',
      '37208',
      '37209',
      '37210',
      '37211',
      '37212',
      '37213',
      '37214',
      '37215',
      '37216',
      '37217',
      '37218',
      '37219',
      '37220',
      '37221',
      '37228',
      '37240',
      '37243',
      '37246'
    ],
    '159': [
      '37012',
      '37030',
      '37057',
      '37087',
      '37090',
      '37145',
      '37151',
      '37184',
      '38547',
      '38548',
      '38552',
      '38560',
      '38563',
      '38567',
      '38569'
    ],
    '147': [
      '37010',
      '37032',
      '37048',
      '37049',
      '37072',
      '37073',
      '37141',
      '37146',
      '37148',
      '37172',
      '37188'
    ],
    '145': [
      '37748',
      '37763',
      '37771',
      '37774',
      '37830',
      '37840',
      '37846',
      '37854',
      '37880'
    ],
    '085': [
      '37078',
      '37101',
      '37134',
      '37185'
    ],
    '163': [
      '37617',
      '37618',
      '37620',
      '37656',
      '37659',
      '37660',
      '37663',
      '37664',
      '37665',
      '37686',
      '37694',
      '37857'
    ],
    '179': [
      '37601',
      '37604',
      '37614',
      '37615',
      '37641',
      '37650',
      '37656',
      '37659',
      '37663',
      '37681',
      '37686',
      '37690',
      '37694'
    ],
    '057': [
      '37708',
      '37709',
      '37811',
      '37848',
      '37861',
      '37881',
      '37888'
    ],
    '103': [
      '37047',
      '37144',
      '37328',
      '37334',
      '37335',
      '37348',
      '37359',
      '38449',
      '38453',
      '38459',
      '38488'
    ],
    '095': [
      '38077',
      '38079',
      '38080'
    ],
    '051': [
      '37306',
      '37318',
      '37324',
      '37328',
      '37330',
      '37335',
      '37345',
      '37375',
      '37376',
      '37388',
      '37398'
    ],
    '067': [
      '37731',
      '37752',
      '37765',
      '37869',
      '37879',
      '37881'
    ],
    '173': [
      '37705',
      '37721',
      '37754',
      '37779',
      '37807',
      '37825',
      '37848',
      '37866',
      '37870',
      '37888'
    ],
    '133': [
      '38501',
      '38506',
      '38541',
      '38542',
      '38543',
      '38554',
      '38568',
      '38570',
      '38573',
      '38574',
      '38580',
      '38589'
    ],
    '071': [
      '38310',
      '38326',
      '38327',
      '38357',
      '38359',
      '38361',
      '38365',
      '38370',
      '38371',
      '38372',
      '38376',
      '38425',
      '38475'
    ],
    '141': [
      '38501',
      '38505',
      '38506',
      '38544',
      '38545',
      '38548',
      '38564',
      '38574',
      '38582',
      '38583'
    ],
    '047': [
      '38002',
      '38017',
      '38028',
      '38036',
      '38039',
      '38042',
      '38046',
      '38049',
      '38057',
      '38060',
      '38066',
      '38068',
      '38075',
      '38076'
    ],
    '023': [
      '38044',
      '38313',
      '38315',
      '38332',
      '38334',
      '38340',
      '38347',
      '38352',
      '38356',
      '38359',
      '38366',
      '38368'
    ],
    '043': [
      '37015',
      '37025',
      '37029',
      '37036',
      '37051',
      '37055',
      '37062',
      '37165',
      '37181',
      '37187'
    ],
    '065': [
      '37302',
      '37308',
      '37311',
      '37315',
      '37336',
      '37338',
      '37341',
      '37343',
      '37350',
      '37351',
      '37353',
      '37363',
      '37373',
      '37377',
      '37379',
      '37402',
      '37403',
      '37404',
      '37405',
      '37406',
      '37407',
      '37408',
      '37409',
      '37410',
      '37411',
      '37412',
      '37415',
      '37416',
      '37419',
      '37421'
    ],
    '013': [
      '37714',
      '37715',
      '37729',
      '37757',
      '37762',
      '37766',
      '37769',
      '37819',
      '37847',
      '37870'
    ],
    '003': [
      '37018',
      '37020',
      '37034',
      '37060',
      '37091',
      '37144',
      '37153',
      '37160',
      '37180',
      '37183',
      '37334',
      '37360',
      '37388'
    ],
    '123': [
      '37329',
      '37354',
      '37369',
      '37385',
      '37774',
      '37801',
      '37846',
      '37874',
      '37885'
    ],
    '099': [
      '38456',
      '38457',
      '38463',
      '38464',
      '38468',
      '38469',
      '38474',
      '38481',
      '38483',
      '38486'
    ],
    '183': [
      '38201',
      '38224',
      '38225',
      '38226',
      '38229',
      '38230',
      '38237',
      '38241',
      '38242',
      '38255',
      '38257'
    ],
    '029': [
      '37713',
      '37722',
      '37727',
      '37743',
      '37753',
      '37821',
      '37843'
    ],
    '021': [
      '37015',
      '37032',
      '37035',
      '37036',
      '37043',
      '37080',
      '37082',
      '37143',
      '37146',
      '37187'
    ],
    '181': [
      '37096',
      '38425',
      '38450',
      '38452',
      '38463',
      '38464',
      '38471',
      '38475',
      '38485',
      '38486'
    ],
    '031': [
      '37018',
      '37183',
      '37330',
      '37342',
      '37355',
      '37357',
      '37360',
      '37388'
    ],
    '091': [
      '37640',
      '37680',
      '37683',
      '37688',
      '37691'
    ],
    '139': [
      '37307',
      '37309',
      '37310',
      '37317',
      '37323',
      '37325',
      '37326',
      '37333',
      '37361',
      '37362',
      '37369',
      '37391'
    ],
    '025': [
      '37715',
      '37724',
      '37730',
      '37752',
      '37825',
      '37851',
      '37869',
      '37870',
      '37879'
    ],
    '169': [
      '37022',
      '37031',
      '37057',
      '37074',
      '37087'
    ],
    '151': [
      '37732',
      '37755',
      '37756',
      '37841',
      '37847',
      '37852',
      '37872',
      '37892',
      '38504'
    ],
    '039': [
      '38311',
      '38329',
      '38341',
      '38363',
      '38374',
      '38380'
    ],
    '149': [
      '37014',
      '37020',
      '37037',
      '37046',
      '37060',
      '37085',
      '37086',
      '37118',
      '37122',
      '37127',
      '37128',
      '37129',
      '37130',
      '37132',
      '37135',
      '37149',
      '37153',
      '37167'
    ],
    '011': [
      '37310',
      '37311',
      '37312',
      '37323',
      '37336',
      '37353',
      '37362'
    ],
    '041': [
      '37012',
      '37059',
      '37095',
      '37166',
      '38544',
      '38567',
      '38569',
      '38581',
      '38582',
      '38583'
    ],
    '027': [
      '37150',
      '38541',
      '38551',
      '38568',
      '38575',
      '38588'
    ],
    '135': [
      '37096',
      '37097',
      '38425'
    ],
    '143': [
      '37321',
      '37332',
      '37337',
      '37338',
      '37381'
    ],
    '175': [
      '37110',
      '37367',
      '38559',
      '38581',
      '38583',
      '38585'
    ],
    '185': [
      '38506',
      '38559',
      '38579',
      '38583',
      '38587'
    ],
    '089': [
      '37725',
      '37760',
      '37813',
      '37820',
      '37871',
      '37876',
      '37877',
      '37890'
    ],
    '045': [
      '38007',
      '38024',
      '38030',
      '38034',
      '38040',
      '38047',
      '38059',
      '38070',
      '38080',
      '38240',
      '38259'
    ],
    '107': [
      '37303',
      '37309',
      '37322',
      '37325',
      '37329',
      '37331',
      '37354',
      '37370',
      '37826',
      '37846',
      '37874'
    ],
    '155': [
      '37722',
      '37725',
      '37738',
      '37764',
      '37862',
      '37863',
      '37865',
      '37871',
      '37876'
    ],
    '127': [
      '37144',
      '37160',
      '37183',
      '37306',
      '37334',
      '37352',
      '37359',
      '37360',
      '37388',
      '37398'
    ],
    '161': [
      '37023',
      '37028',
      '37050',
      '37058',
      '37079',
      '37175',
      '37178'
    ],
    '081': [
      '37025',
      '37033',
      '37055',
      '37078',
      '37098',
      '37101',
      '37137',
      '37140',
      '38454',
      '38462',
      '38476',
      '38487'
    ],
    '097': [
      '38037',
      '38040',
      '38041',
      '38063'
    ],
    '061': [
      '37110',
      '37301',
      '37305',
      '37313',
      '37324',
      '37339',
      '37356',
      '37357',
      '37365',
      '37366',
      '37387'
    ],
    '001': [
      '37705',
      '37710',
      '37716',
      '37754',
      '37769',
      '37828',
      '37830',
      '37840',
      '37849',
      '37931'
    ],
    '129': [
      '37719',
      '37726',
      '37733',
      '37748',
      '37770',
      '37829',
      '37840',
      '37845',
      '37852',
      '37854',
      '37872',
      '37887'
    ],
    '017': [
      '38201',
      '38220',
      '38235',
      '38258',
      '38305',
      '38317',
      '38318',
      '38321',
      '38341',
      '38342',
      '38344',
      '38348',
      '38387',
      '38390'
    ],
    '087': [
      '37145',
      '38501',
      '38545',
      '38551',
      '38562',
      '38564',
      '38568',
      '38588'
    ],
    '115': [
      '37340',
      '37347',
      '37356',
      '37374',
      '37375',
      '37380',
      '37396',
      '37397',
      '37405',
      '37419'
    ],
    '005': [
      '38221',
      '38317',
      '38320',
      '38333',
      '38341',
      '38380'
    ],
    '189': [
      '37012',
      '37016',
      '37076',
      '37085',
      '37087',
      '37090',
      '37095',
      '37118',
      '37122',
      '37138',
      '37184'
    ],
    '077': [
      '38313',
      '38321',
      '38328',
      '38329',
      '38345',
      '38351',
      '38352',
      '38363',
      '38368',
      '38371',
      '38374',
      '38388',
      '38390'
    ],
    '157': [
      '38002',
      '38004',
      '38011',
      '38016',
      '38017',
      '38018',
      '38028',
      '38029',
      '38053',
      '38054',
      '38103',
      '38104',
      '38105',
      '38106',
      '38107',
      '38108',
      '38109',
      '38111',
      '38112',
      '38114',
      '38115',
      '38116',
      '38117',
      '38118',
      '38119',
      '38120',
      '38122',
      '38125',
      '38126',
      '38127',
      '38128',
      '38131',
      '38132',
      '38133',
      '38134',
      '38135',
      '38138',
      '38139',
      '38141',
      '38152'
    ],
    '069': [
      '38008',
      '38039',
      '38042',
      '38044',
      '38052',
      '38061',
      '38067',
      '38075',
      '38340',
      '38356',
      '38381',
      '38392'
    ],
    '093': [
      '37709',
      '37721',
      '37754',
      '37764',
      '37772',
      '37779',
      '37806',
      '37807',
      '37830',
      '37849',
      '37853',
      '37871',
      '37902',
      '37909',
      '37912',
      '37914',
      '37915',
      '37916',
      '37917',
      '37918',
      '37919',
      '37920',
      '37921',
      '37922',
      '37923',
      '37924',
      '37931',
      '37932',
      '37934',
      '37938'
    ],
    '105': [
      '37737',
      '37742',
      '37771',
      '37772',
      '37774',
      '37846',
      '37874',
      '37885'
    ],
    '113': [
      '38006',
      '38301',
      '38305',
      '38313',
      '38343',
      '38351',
      '38355',
      '38356',
      '38362',
      '38366',
      '38391',
      '38392'
    ],
    '187': [
      '37014',
      '37025',
      '37027',
      '37046',
      '37060',
      '37062',
      '37064',
      '37067',
      '37069',
      '37135',
      '37174',
      '37179',
      '37215',
      '37221',
      '38401',
      '38476'
    ],
    '075': [
      '38006',
      '38012',
      '38037',
      '38063',
      '38069',
      '38075'
    ],
    '007': [
      '37321',
      '37327',
      '37332',
      '37337',
      '37338',
      '37367',
      '37379',
      '37381',
      '38572'
    ],
    '165': [
      '37022',
      '37031',
      '37048',
      '37066',
      '37072',
      '37075',
      '37148',
      '37186',
      '37188'
    ],
    '101': [
      '38461',
      '38462',
      '38474',
      '38483',
      '38485'
    ],
    '125': [
      '37010',
      '37015',
      '37040',
      '37042',
      '37043',
      '37050',
      '37051',
      '37052',
      '37079',
      '37142',
      '37171',
      '37191',
      '42223'
    ],
    '049': [
      '37726',
      '38504',
      '38549',
      '38553',
      '38556',
      '38565',
      '38574',
      '38577',
      '38589'
    ],
    '019': [
      '37601',
      '37604',
      '37640',
      '37643',
      '37658',
      '37682',
      '37687',
      '37694'
    ],
    '083': [
      '37050',
      '37051',
      '37061',
      '37101',
      '37175',
      '37178',
      '37181',
      '37185'
    ],
    '073': [
      '37642',
      '37645',
      '37660',
      '37711',
      '37731',
      '37811',
      '37857',
      '37869',
      '37873',
      '37881',
      '37891'
    ],
    '015': [
      '37016',
      '37026',
      '37095',
      '37110',
      '37118',
      '37149',
      '37166',
      '37190',
      '37357'
    ]
  },
  '34': {
    '035': [
      '07059',
      '07060',
      '07062',
      '07063',
      '07069',
      '07920',
      '07921',
      '07924',
      '07931',
      '07934',
      '07939',
      '07977',
      '07979',
      '08502',
      '08528',
      '08540',
      '08553',
      '08558',
      '08805',
      '08807',
      '08812',
      '08821',
      '08823',
      '08835',
      '08836',
      '08844',
      '08853',
      '08869',
      '08873',
      '08876',
      '08880',
      '08890'
    ],
    '027': [
      '07005',
      '07034',
      '07035',
      '07045',
      '07046',
      '07054',
      '07058',
      '07082',
      '07405',
      '07435',
      '07438',
      '07440',
      '07444',
      '07457',
      '07460',
      '07801',
      '07803',
      '07828',
      '07830',
      '07834',
      '07836',
      '07840',
      '07842',
      '07847',
      '07849',
      '07850',
      '07852',
      '07853',
      '07856',
      '07857',
      '07865',
      '07866',
      '07869',
      '07870',
      '07876',
      '07878',
      '07885',
      '07920',
      '07926',
      '07927',
      '07928',
      '07930',
      '07931',
      '07932',
      '07933',
      '07935',
      '07936',
      '07940',
      '07945',
      '07946',
      '07950',
      '07960',
      '07961',
      '07970',
      '07976',
      '07980',
      '07981'
    ],
    '021': [
      '08501',
      '08512',
      '08520',
      '08525',
      '08530',
      '08534',
      '08540',
      '08542',
      '08550',
      '08558',
      '08560',
      '08561',
      '08608',
      '08609',
      '08610',
      '08611',
      '08618',
      '08619',
      '08620',
      '08628',
      '08629',
      '08638',
      '08648',
      '08690',
      '08691'
    ],
    '037': [
      '07416',
      '07418',
      '07419',
      '07421',
      '07422',
      '07439',
      '07460',
      '07461',
      '07462',
      '07821',
      '07822',
      '07826',
      '07827',
      '07843',
      '07848',
      '07849',
      '07851',
      '07860',
      '07871',
      '07874',
      '07881'
    ],
    '001': [
      '08037',
      '08094',
      '08201',
      '08203',
      '08205',
      '08215',
      '08217',
      '08221',
      '08225',
      '08232',
      '08234',
      '08240',
      '08241',
      '08244',
      '08270',
      '08310',
      '08317',
      '08319',
      '08326',
      '08330',
      '08340',
      '08341',
      '08344',
      '08346',
      '08350',
      '08360',
      '08401',
      '08402',
      '08403',
      '08406'
    ],
    '015': [
      '08012',
      '08014',
      '08020',
      '08027',
      '08028',
      '08039',
      '08051',
      '08056',
      '08061',
      '08062',
      '08063',
      '08066',
      '08071',
      '08074',
      '08080',
      '08081',
      '08085',
      '08086',
      '08090',
      '08093',
      '08094',
      '08096',
      '08097',
      '08312',
      '08322',
      '08328',
      '08343',
      '08344',
      '08360'
    ],
    '017': [
      '07002',
      '07029',
      '07030',
      '07032',
      '07047',
      '07086',
      '07087',
      '07093',
      '07094',
      '07302',
      '07304',
      '07305',
      '07306',
      '07307',
      '07310',
      '07311'
    ],
    '029': [
      '08005',
      '08006',
      '08008',
      '08050',
      '08087',
      '08092',
      '08514',
      '08527',
      '08533',
      '08701',
      '08721',
      '08722',
      '08723',
      '08724',
      '08731',
      '08732',
      '08733',
      '08734',
      '08735',
      '08738',
      '08740',
      '08741',
      '08742',
      '08751',
      '08752',
      '08753',
      '08755',
      '08757',
      '08758',
      '08759'
    ],
    '023': [
      '07001',
      '07008',
      '07064',
      '07067',
      '07077',
      '07080',
      '07095',
      '07721',
      '07735',
      '07747',
      '08512',
      '08528',
      '08536',
      '08540',
      '08810',
      '08812',
      '08816',
      '08817',
      '08820',
      '08824',
      '08828',
      '08830',
      '08831',
      '08832',
      '08837',
      '08840',
      '08846',
      '08850',
      '08852',
      '08854',
      '08857',
      '08859',
      '08861',
      '08863',
      '08872',
      '08879',
      '08882',
      '08884',
      '08901',
      '08902',
      '08904'
    ],
    '041': [
      '07820',
      '07821',
      '07823',
      '07825',
      '07832',
      '07833',
      '07838',
      '07840',
      '07846',
      '07860',
      '07863',
      '07865',
      '07874',
      '07880',
      '07882',
      '08802',
      '08804',
      '08808',
      '08827',
      '08865',
      '08886'
    ],
    '013': [
      '07003',
      '07004',
      '07006',
      '07009',
      '07017',
      '07018',
      '07021',
      '07028',
      '07039',
      '07040',
      '07041',
      '07042',
      '07043',
      '07044',
      '07050',
      '07052',
      '07068',
      '07078',
      '07079',
      '07083',
      '07102',
      '07103',
      '07104',
      '07105',
      '07106',
      '07107',
      '07108',
      '07109',
      '07110',
      '07111',
      '07112',
      '07114'
    ],
    '031': [
      '07011',
      '07012',
      '07013',
      '07014',
      '07043',
      '07055',
      '07110',
      '07403',
      '07420',
      '07421',
      '07424',
      '07435',
      '07438',
      '07442',
      '07456',
      '07460',
      '07465',
      '07470',
      '07480',
      '07501',
      '07502',
      '07503',
      '07504',
      '07505',
      '07506',
      '07508',
      '07512',
      '07513',
      '07514',
      '07522',
      '07524'
    ],
    '033': [
      '08001',
      '08023',
      '08038',
      '08067',
      '08069',
      '08070',
      '08072',
      '08079',
      '08085',
      '08098',
      '08302',
      '08318',
      '08343',
      '08344'
    ],
    '011': [
      '08302',
      '08311',
      '08314',
      '08316',
      '08318',
      '08320',
      '08321',
      '08323',
      '08324',
      '08327',
      '08329',
      '08332',
      '08340',
      '08344',
      '08345',
      '08348',
      '08349',
      '08352',
      '08353',
      '08360',
      '08361'
    ],
    '009': [
      '08202',
      '08204',
      '08210',
      '08212',
      '08223',
      '08226',
      '08230',
      '08242',
      '08243',
      '08246',
      '08247',
      '08248',
      '08251',
      '08260',
      '08270'
    ],
    '025': [
      '07701',
      '07702',
      '07703',
      '07704',
      '07711',
      '07712',
      '07716',
      '07717',
      '07718',
      '07719',
      '07720',
      '07721',
      '07722',
      '07723',
      '07724',
      '07726',
      '07727',
      '07728',
      '07730',
      '07731',
      '07732',
      '07733',
      '07734',
      '07735',
      '07737',
      '07738',
      '07739',
      '07740',
      '07746',
      '07747',
      '07748',
      '07750',
      '07751',
      '07753',
      '07755',
      '07756',
      '07757',
      '07758',
      '07760',
      '07762',
      '07764',
      '08501',
      '08510',
      '08514',
      '08535',
      '08555',
      '08691',
      '08720',
      '08724',
      '08730',
      '08736',
      '08750'
    ],
    '019': [
      '07830',
      '07865',
      '07882',
      '07979',
      '08525',
      '08530',
      '08551',
      '08559',
      '08801',
      '08802',
      '08804',
      '08809',
      '08822',
      '08825',
      '08826',
      '08827',
      '08829',
      '08833',
      '08848',
      '08853',
      '08858',
      '08867',
      '08876',
      '08887',
      '08889'
    ],
    '005': [
      '08010',
      '08011',
      '08015',
      '08016',
      '08019',
      '08022',
      '08036',
      '08041',
      '08042',
      '08046',
      '08048',
      '08052',
      '08053',
      '08054',
      '08055',
      '08057',
      '08060',
      '08064',
      '08065',
      '08068',
      '08073',
      '08075',
      '08077',
      '08087',
      '08088',
      '08215',
      '08224',
      '08501',
      '08505',
      '08511',
      '08515',
      '08518',
      '08554',
      '08562',
      '08610',
      '08620',
      '08640',
      '08641'
    ],
    '007': [
      '08002',
      '08003',
      '08004',
      '08007',
      '08009',
      '08012',
      '08021',
      '08026',
      '08029',
      '08030',
      '08031',
      '08033',
      '08034',
      '08035',
      '08037',
      '08043',
      '08045',
      '08049',
      '08059',
      '08078',
      '08081',
      '08083',
      '08084',
      '08089',
      '08091',
      '08095',
      '08102',
      '08103',
      '08104',
      '08105',
      '08106',
      '08107',
      '08108',
      '08109',
      '08110'
    ],
    '039': [
      '07016',
      '07023',
      '07027',
      '07033',
      '07036',
      '07060',
      '07062',
      '07063',
      '07065',
      '07066',
      '07076',
      '07081',
      '07083',
      '07088',
      '07090',
      '07092',
      '07201',
      '07202',
      '07203',
      '07204',
      '07205',
      '07206',
      '07208',
      '07901',
      '07922',
      '07974',
      '08812'
    ],
    '003': [
      '07010',
      '07020',
      '07022',
      '07024',
      '07026',
      '07031',
      '07057',
      '07070',
      '07071',
      '07072',
      '07073',
      '07074',
      '07075',
      '07401',
      '07407',
      '07410',
      '07417',
      '07423',
      '07430',
      '07432',
      '07436',
      '07446',
      '07450',
      '07452',
      '07458',
      '07463',
      '07481',
      '07495',
      '07601',
      '07603',
      '07604',
      '07605',
      '07606',
      '07607',
      '07608',
      '07620',
      '07621',
      '07624',
      '07626',
      '07627',
      '07628',
      '07630',
      '07631',
      '07632',
      '07640',
      '07641',
      '07642',
      '07643',
      '07644',
      '07645',
      '07646',
      '07647',
      '07648',
      '07649',
      '07650',
      '07652',
      '07656',
      '07657',
      '07660',
      '07661',
      '07662',
      '07663',
      '07666',
      '07670',
      '07675',
      '07676',
      '07677'
    ]
  },
  '19': {
    '167': [
      '51003',
      '51011',
      '51022',
      '51023',
      '51027',
      '51036',
      '51041',
      '51201',
      '51234',
      '51235',
      '51238',
      '51239',
      '51240',
      '51244',
      '51247',
      '51250'
    ],
    '079': [
      '50034',
      '50075',
      '50130',
      '50132',
      '50230',
      '50231',
      '50246',
      '50248',
      '50249',
      '50271',
      '50532',
      '50595',
      '50599'
    ],
    '131': [
      '50426',
      '50454',
      '50455',
      '50458',
      '50460',
      '50461',
      '50466',
      '50471',
      '50472',
      '50476',
      '50628'
    ],
    '193': [
      '51004',
      '51006',
      '51007',
      '51016',
      '51018',
      '51019',
      '51026',
      '51028',
      '51030',
      '51034',
      '51039',
      '51044',
      '51048',
      '51052',
      '51054',
      '51055',
      '51056',
      '51101',
      '51103',
      '51104',
      '51105',
      '51106',
      '51108',
      '51109',
      '51111'
    ],
    '119': [
      '51201',
      '51230',
      '51232',
      '51235',
      '51237',
      '51239',
      '51240',
      '51241',
      '51242',
      '51243',
      '51246',
      '51247'
    ],
    '153': [
      '50007',
      '50009',
      '50021',
      '50023',
      '50032',
      '50035',
      '50046',
      '50047',
      '50061',
      '50073',
      '50109',
      '50111',
      '50124',
      '50131',
      '50156',
      '50161',
      '50169',
      '50226',
      '50237',
      '50243',
      '50244',
      '50265',
      '50266',
      '50309',
      '50310',
      '50311',
      '50312',
      '50313',
      '50314',
      '50315',
      '50316',
      '50317',
      '50319',
      '50320',
      '50321',
      '50322',
      '50323',
      '50324',
      '50325',
      '50327'
    ],
    '055': [
      '50654',
      '52035',
      '52038',
      '52040',
      '52041',
      '52042',
      '52050',
      '52057',
      '52065',
      '52076',
      '52078',
      '52218',
      '52223',
      '52237',
      '52310',
      '52330'
    ],
    '109': [
      '50424',
      '50430',
      '50451',
      '50480',
      '50483',
      '50484',
      '50511',
      '50514',
      '50517',
      '50519',
      '50522',
      '50539',
      '50556',
      '50558',
      '50559',
      '50560',
      '50570',
      '50590',
      '50597',
      '50598',
      '56027'
    ],
    '053': [
      '50065',
      '50067',
      '50103',
      '50108',
      '50123',
      '50133',
      '50140',
      '50144',
      '50147',
      '50262',
      '50264'
    ],
    '063': [
      '50514',
      '50531',
      '50578',
      '51334',
      '51342',
      '51364',
      '51365'
    ],
    '171': [
      '50106',
      '50158',
      '50173',
      '50609',
      '50612',
      '50632',
      '50635',
      '50652',
      '50669',
      '50675',
      '52208',
      '52215',
      '52217',
      '52224',
      '52225',
      '52339',
      '52342',
      '52348'
    ],
    '177': [
      '52535',
      '52542',
      '52551',
      '52554',
      '52565',
      '52567',
      '52570',
      '52573',
      '52588',
      '52620',
      '52626',
      '52630',
      '52651'
    ],
    '059': [
      '51331',
      '51334',
      '51338',
      '51345',
      '51347',
      '51351',
      '51355',
      '51360',
      '51363',
      '51364'
    ],
    '143': [
      '51232',
      '51243',
      '51248',
      '51249',
      '51345',
      '51346',
      '51350',
      '51354'
    ],
    '111': [
      '52619',
      '52624',
      '52625',
      '52626',
      '52627',
      '52630',
      '52632',
      '52639',
      '52649',
      '52656',
      '52657',
      '52658'
    ],
    '117': [
      '50049',
      '50068',
      '50123',
      '50139',
      '50151',
      '50238',
      '50272'
    ],
    '035': [
      '51002',
      '51005',
      '51012',
      '51014',
      '51020',
      '51025',
      '51029',
      '51035',
      '51037',
      '51046',
      '51047',
      '51048',
      '51049',
      '51058',
      '51061'
    ],
    '033': [
      '50401',
      '50428',
      '50433',
      '50434',
      '50444',
      '50449',
      '50457',
      '50458',
      '50464',
      '50467',
      '50468',
      '50469',
      '50475',
      '50477',
      '50479',
      '50482'
    ],
    '037': [
      '50603',
      '50616',
      '50630',
      '50645',
      '50658',
      '50659',
      '50674',
      '52154',
      '52171'
    ],
    '159': [
      '50074',
      '50133',
      '50140',
      '50835',
      '50836',
      '50840',
      '50845',
      '50851',
      '50854',
      '50860',
      '50861',
      '50863'
    ],
    '147': [
      '50515',
      '50527',
      '50528',
      '50536',
      '50539',
      '50554',
      '50562',
      '50578',
      '50581',
      '50597',
      '50598',
      '51342',
      '51358'
    ],
    '145': [
      '50864',
      '51566',
      '51601',
      '51630',
      '51631',
      '51632',
      '51636',
      '51637',
      '51638',
      '51647',
      '51656'
    ],
    '085': [
      '51523',
      '51529',
      '51545',
      '51546',
      '51550',
      '51555',
      '51556',
      '51557',
      '51558',
      '51559',
      '51563',
      '51564',
      '51565',
      '51570',
      '51579'
    ],
    '191': [
      '52101',
      '52132',
      '52133',
      '52136',
      '52140',
      '52144',
      '52161',
      '52162',
      '52165',
      '52168',
      '52171',
      '52172'
    ],
    '163': [
      '52722',
      '52726',
      '52728',
      '52745',
      '52746',
      '52747',
      '52748',
      '52753',
      '52756',
      '52758',
      '52765',
      '52767',
      '52768',
      '52769',
      '52773',
      '52801',
      '52802',
      '52803',
      '52804',
      '52806',
      '52807'
    ],
    '121': [
      '50033',
      '50061',
      '50070',
      '50072',
      '50149',
      '50155',
      '50210',
      '50211',
      '50218',
      '50222',
      '50229',
      '50240',
      '50257',
      '50261',
      '50273'
    ],
    '057': [
      '52601',
      '52623',
      '52637',
      '52638',
      '52640',
      '52644',
      '52645',
      '52646',
      '52650',
      '52655',
      '52658',
      '52660'
    ],
    '103': [
      '52203',
      '52228',
      '52235',
      '52240',
      '52241',
      '52242',
      '52245',
      '52246',
      '52247',
      '52253',
      '52317',
      '52322',
      '52325',
      '52327',
      '52333',
      '52338',
      '52340',
      '52356',
      '52358',
      '52404',
      '52755'
    ],
    '095': [
      '50136',
      '52203',
      '52208',
      '52220',
      '52221',
      '52222',
      '52236',
      '52251',
      '52301',
      '52307',
      '52308',
      '52316',
      '52318',
      '52325',
      '52334',
      '52347',
      '52355',
      '52356',
      '52361'
    ],
    '051': [
      '52537',
      '52552',
      '52554',
      '52560',
      '52570',
      '52572',
      '52584',
      '52588',
      '52594'
    ],
    '009': [
      '50020',
      '50022',
      '50025',
      '50042',
      '50058',
      '50076',
      '50117',
      '51455',
      '51531',
      '51543'
    ],
    '173': [
      '50833',
      '50836',
      '50840',
      '50841',
      '50848',
      '50851',
      '50857',
      '50862',
      '50864',
      '51632',
      '51646'
    ],
    '133': [
      '51010',
      '51026',
      '51034',
      '51040',
      '51051',
      '51055',
      '51056',
      '51060',
      '51063',
      '51523',
      '51529',
      '51545',
      '51558',
      '51572'
    ],
    '071': [
      '51601',
      '51639',
      '51640',
      '51645',
      '51648',
      '51649',
      '51650',
      '51652',
      '51653',
      '51654'
    ],
    '141': [
      '51009',
      '51022',
      '51046',
      '51047',
      '51058',
      '51201',
      '51231',
      '51238',
      '51245',
      '51248',
      '51346'
    ],
    '047': [
      '51019',
      '51034',
      '51060',
      '51436',
      '51439',
      '51441',
      '51442',
      '51448',
      '51454',
      '51455',
      '51461',
      '51465',
      '51467',
      '51520',
      '51527',
      '51528',
      '51529'
    ],
    '197': [
      '50034',
      '50071',
      '50101',
      '50271',
      '50420',
      '50421',
      '50447',
      '50457',
      '50470',
      '50525',
      '50533',
      '50542',
      '50577',
      '50599'
    ],
    '031': [
      '52216',
      '52253',
      '52255',
      '52306',
      '52320',
      '52323',
      '52333',
      '52337',
      '52358',
      '52720',
      '52721',
      '52747',
      '52760',
      '52765',
      '52772',
      '52776',
      '52777',
      '52778'
    ],
    '023': [
      '50433',
      '50601',
      '50602',
      '50604',
      '50605',
      '50611',
      '50613',
      '50619',
      '50625',
      '50636',
      '50660',
      '50665',
      '50666',
      '50670'
    ],
    '043': [
      '50606',
      '52035',
      '52042',
      '52043',
      '52044',
      '52047',
      '52048',
      '52049',
      '52050',
      '52052',
      '52053',
      '52066',
      '52072',
      '52073',
      '52076',
      '52077',
      '52141',
      '52156',
      '52157',
      '52158',
      '52159',
      '52162'
    ],
    '065': [
      '50606',
      '50607',
      '50629',
      '50650',
      '50655',
      '50662',
      '50664',
      '50671',
      '50674',
      '50681',
      '52076',
      '52133',
      '52135',
      '52141',
      '52142',
      '52144',
      '52147',
      '52161',
      '52162',
      '52164',
      '52166',
      '52169',
      '52171',
      '52175'
    ],
    '013': [
      '50612',
      '50613',
      '50626',
      '50629',
      '50634',
      '50643',
      '50647',
      '50648',
      '50651',
      '50667',
      '50669',
      '50675',
      '50701',
      '50702',
      '50703',
      '50707'
    ],
    '003': [
      '50801',
      '50837',
      '50839',
      '50841',
      '50843',
      '50851',
      '50853',
      '50857',
      '50859',
      '50864'
    ],
    '123': [
      '50027',
      '50044',
      '50104',
      '50143',
      '50153',
      '50207',
      '50219',
      '50256',
      '50268',
      '52534',
      '52543',
      '52553',
      '52561',
      '52577',
      '52586',
      '52595'
    ],
    '099': [
      '50028',
      '50054',
      '50055',
      '50106',
      '50112',
      '50127',
      '50135',
      '50141',
      '50153',
      '50168',
      '50169',
      '50170',
      '50208',
      '50228',
      '50232',
      '50234',
      '50237',
      '50251'
    ],
    '183': [
      '52201',
      '52247',
      '52248',
      '52327',
      '52353',
      '52356',
      '52359',
      '52540',
      '52585',
      '52621',
      '52641',
      '52654'
    ],
    '029': [
      '50020',
      '50022',
      '50274',
      '50837',
      '50843',
      '50853',
      '50864',
      '51532',
      '51535',
      '51544',
      '51552'
    ],
    '021': [
      '50510',
      '50554',
      '50565',
      '50568',
      '50576',
      '50585',
      '50588',
      '51002',
      '51005',
      '51033',
      '51047',
      '51366'
    ],
    '181': [
      '50001',
      '50047',
      '50061',
      '50118',
      '50125',
      '50139',
      '50151',
      '50160',
      '50166',
      '50210',
      '50211',
      '50225',
      '50229',
      '50240',
      '50257',
      '50320'
    ],
    '195': [
      '50434',
      '50440',
      '50444',
      '50446',
      '50448',
      '50450',
      '50456',
      '50459',
      '50464',
      '50472'
    ],
    '091': [
      '50516',
      '50519',
      '50520',
      '50529',
      '50533',
      '50541',
      '50542',
      '50545',
      '50548',
      '50558',
      '50560',
      '50570',
      '50577',
      '50582',
      '50591'
    ],
    '139': [
      '52720',
      '52726',
      '52739',
      '52747',
      '52749',
      '52754',
      '52760',
      '52761',
      '52766',
      '52769',
      '52773',
      '52776',
      '52778'
    ],
    '025': [
      '50538',
      '50540',
      '50551',
      '50561',
      '50563',
      '50575',
      '50579',
      '50586',
      '51433',
      '51449',
      '51453'
    ],
    '169': [
      '50010',
      '50011',
      '50012',
      '50014',
      '50046',
      '50055',
      '50056',
      '50105',
      '50124',
      '50134',
      '50154',
      '50161',
      '50201',
      '50206',
      '50226',
      '50236',
      '50243',
      '50244',
      '50247',
      '50248',
      '50278'
    ],
    '151': [
      '50510',
      '50520',
      '50524',
      '50540',
      '50541',
      '50546',
      '50554',
      '50562',
      '50563',
      '50571',
      '50573',
      '50574',
      '50575',
      '50581',
      '50593'
    ],
    '039': [
      '50108',
      '50174',
      '50210',
      '50213',
      '50257',
      '50264',
      '50275'
    ],
    '149': [
      '51001',
      '51008',
      '51022',
      '51024',
      '51027',
      '51028',
      '51031',
      '51038',
      '51050',
      '51062',
      '51108',
      '51109'
    ],
    '011': [
      '50651',
      '52206',
      '52208',
      '52209',
      '52213',
      '52224',
      '52225',
      '52228',
      '52229',
      '52249',
      '52257',
      '52301',
      '52313',
      '52315',
      '52318',
      '52324',
      '52332',
      '52345',
      '52346',
      '52349',
      '52351',
      '52352',
      '52354'
    ],
    '041': [
      '50515',
      '50585',
      '51033',
      '51047',
      '51058',
      '51301',
      '51333',
      '51338',
      '51341',
      '51343',
      '51346',
      '51357',
      '51358',
      '51364',
      '51366'
    ],
    '027': [
      '50058',
      '51401',
      '51430',
      '51436',
      '51440',
      '51443',
      '51444',
      '51449',
      '51451',
      '51453',
      '51455',
      '51459',
      '51463',
      '51467'
    ],
    '179': [
      '52501',
      '52530',
      '52533',
      '52536',
      '52537',
      '52548',
      '52552',
      '52553',
      '52554',
      '52563',
      '52566'
    ],
    '135': [
      '50044',
      '50150',
      '52531',
      '52536',
      '52553',
      '52569',
      '52571'
    ],
    '137': [
      '50847',
      '50864',
      '51532',
      '51533',
      '51541',
      '51566',
      '51573',
      '51638'
    ],
    '175': [
      '50074',
      '50149',
      '50174',
      '50254',
      '50801',
      '50830',
      '50842',
      '50845',
      '50851',
      '50861'
    ],
    '185': [
      '50008',
      '50049',
      '50052',
      '50060',
      '50068',
      '50123',
      '50147',
      '50165',
      '50238',
      '52569',
      '52581',
      '52583',
      '52590'
    ],
    '089': [
      '50466',
      '50603',
      '50628',
      '52132',
      '52134',
      '52136',
      '52154',
      '52155',
      '52163'
    ],
    '045': [
      '52037',
      '52060',
      '52064',
      '52069',
      '52070',
      '52207',
      '52254',
      '52323',
      '52701',
      '52727',
      '52729',
      '52730',
      '52731',
      '52732',
      '52742',
      '52750',
      '52751',
      '52757',
      '52774',
      '52777'
    ],
    '107': [
      '50104',
      '50136',
      '50255',
      '50268',
      '52231',
      '52248',
      '52316',
      '52335',
      '52355',
      '52550',
      '52561',
      '52563',
      '52576',
      '52585',
      '52591'
    ],
    '155': [
      '51501',
      '51503',
      '51510',
      '51521',
      '51525',
      '51526',
      '51532',
      '51535',
      '51536',
      '51541',
      '51542',
      '51544',
      '51548',
      '51549',
      '51553',
      '51555',
      '51559',
      '51560',
      '51566',
      '51570',
      '51571',
      '51575',
      '51576',
      '51577'
    ],
    '127': [
      '50005',
      '50051',
      '50078',
      '50106',
      '50120',
      '50141',
      '50142',
      '50148',
      '50158',
      '50162',
      '50206',
      '50234',
      '50239',
      '50247',
      '50258',
      '50609',
      '50621',
      '50632',
      '50635'
    ],
    '161': [
      '50535',
      '50561',
      '50567',
      '50568',
      '50583',
      '51020',
      '51053',
      '51431',
      '51433',
      '51436',
      '51448',
      '51450',
      '51458',
      '51466'
    ],
    '081': [
      '50423',
      '50430',
      '50432',
      '50436',
      '50438',
      '50439',
      '50447',
      '50449',
      '50457',
      '50480',
      '50482',
      '50483',
      '50484'
    ],
    '097': [
      '52030',
      '52031',
      '52032',
      '52037',
      '52054',
      '52060',
      '52064',
      '52069',
      '52070',
      '52074',
      '52079',
      '52207',
      '52309',
      '52731'
    ],
    '125': [
      '50044',
      '50057',
      '50062',
      '50116',
      '50119',
      '50138',
      '50139',
      '50163',
      '50170',
      '50214',
      '50219',
      '50225',
      '50228',
      '50237',
      '50252',
      '50256'
    ],
    '001': [
      '50002',
      '50020',
      '50048',
      '50070',
      '50155',
      '50164',
      '50250',
      '50801',
      '50837',
      '50846',
      '50849',
      '50858',
      '50859'
    ],
    '129': [
      '51503',
      '51533',
      '51534',
      '51540',
      '51541',
      '51551',
      '51554',
      '51561',
      '51571',
      '51645',
      '51653'
    ],
    '017': [
      '50622',
      '50629',
      '50630',
      '50645',
      '50647',
      '50666',
      '50668',
      '50670',
      '50674',
      '50676',
      '50677'
    ],
    '115': [
      '52621',
      '52640',
      '52646',
      '52653',
      '52659',
      '52737',
      '52738',
      '52739',
      '52752',
      '52754',
      '52755',
      '52761'
    ],
    '087': [
      '52623',
      '52630',
      '52635',
      '52641',
      '52644',
      '52645',
      '52647',
      '52649',
      '52654',
      '52656',
      '52659'
    ],
    '067': [
      '50433',
      '50435',
      '50458',
      '50460',
      '50461',
      '50468',
      '50471',
      '50616',
      '50620',
      '50636',
      '50645',
      '50653',
      '50658'
    ],
    '005': [
      '52101',
      '52140',
      '52146',
      '52151',
      '52156',
      '52159',
      '52160',
      '52162',
      '52170',
      '52172'
    ],
    '189': [
      '50424',
      '50436',
      '50446',
      '50450',
      '50453',
      '50465',
      '50473',
      '50478',
      '50484'
    ],
    '077': [
      '50002',
      '50026',
      '50029',
      '50048',
      '50058',
      '50070',
      '50115',
      '50128',
      '50164',
      '50216',
      '50233',
      '50250',
      '50277'
    ],
    '157': [
      '50027',
      '50106',
      '50112',
      '50153',
      '50157',
      '50171',
      '50207',
      '50242',
      '52208',
      '52211',
      '52215',
      '52221',
      '52222',
      '52232',
      '52339',
      '52347'
    ],
    '069': [
      '50006',
      '50041',
      '50071',
      '50126',
      '50227',
      '50420',
      '50431',
      '50433',
      '50441',
      '50452',
      '50457',
      '50475',
      '50479',
      '50601',
      '50605',
      '50625',
      '50633'
    ],
    '093': [
      '51006',
      '51016',
      '51018',
      '51019',
      '51020',
      '51025',
      '51053',
      '51061',
      '51431',
      '51445',
      '51448'
    ],
    '105': [
      '52032',
      '52033',
      '52205',
      '52212',
      '52216',
      '52253',
      '52305',
      '52306',
      '52309',
      '52310',
      '52312',
      '52320',
      '52321',
      '52323',
      '52362'
    ],
    '113': [
      '52202',
      '52205',
      '52213',
      '52214',
      '52218',
      '52219',
      '52227',
      '52228',
      '52233',
      '52253',
      '52302',
      '52305',
      '52310',
      '52314',
      '52324',
      '52328',
      '52332',
      '52336',
      '52338',
      '52341',
      '52351',
      '52352',
      '52401',
      '52402',
      '52403',
      '52404',
      '52405',
      '52411'
    ],
    '187': [
      '50249',
      '50501',
      '50516',
      '50518',
      '50521',
      '50523',
      '50524',
      '50530',
      '50532',
      '50533',
      '50538',
      '50543',
      '50544',
      '50548',
      '50557',
      '50563',
      '50566',
      '50569',
      '50586',
      '50591',
      '50594'
    ],
    '075': [
      '50601',
      '50604',
      '50609',
      '50613',
      '50621',
      '50624',
      '50627',
      '50638',
      '50642',
      '50643',
      '50660',
      '50665',
      '50669',
      '50672',
      '50673',
      '50680'
    ],
    '007': [
      '52544',
      '52549',
      '52555',
      '52569',
      '52571',
      '52572',
      '52574',
      '52581',
      '52590',
      '52593',
      '52594'
    ],
    '165': [
      '50022',
      '51446',
      '51447',
      '51454',
      '51455',
      '51521',
      '51527',
      '51529',
      '51530',
      '51531',
      '51537',
      '51543',
      '51552',
      '51562',
      '51565',
      '51570',
      '51577',
      '51578'
    ],
    '101': [
      '52533',
      '52535',
      '52540',
      '52554',
      '52556',
      '52557',
      '52563',
      '52567',
      '52580',
      '52585',
      '52635',
      '52641',
      '52651'
    ],
    '061': [
      '52001',
      '52002',
      '52003',
      '52031',
      '52032',
      '52033',
      '52039',
      '52040',
      '52045',
      '52046',
      '52052',
      '52053',
      '52054',
      '52065',
      '52068',
      '52073',
      '52078',
      '52079',
      '52237'
    ],
    '049': [
      '50003',
      '50038',
      '50039',
      '50061',
      '50063',
      '50066',
      '50069',
      '50070',
      '50072',
      '50109',
      '50111',
      '50128',
      '50146',
      '50156',
      '50167',
      '50220',
      '50233',
      '50235',
      '50261',
      '50263',
      '50266',
      '50276',
      '50277',
      '50323',
      '50325'
    ],
    '019': [
      '50607',
      '50629',
      '50641',
      '50644',
      '50648',
      '50650',
      '50654',
      '50662',
      '50671',
      '50682',
      '52210',
      '52218',
      '52326',
      '52329',
      '52352'
    ],
    '083': [
      '50006',
      '50102',
      '50122',
      '50126',
      '50154',
      '50206',
      '50230',
      '50258',
      '50601',
      '50627',
      '50672'
    ],
    '073': [
      '50026',
      '50029',
      '50050',
      '50058',
      '50064',
      '50107',
      '50128',
      '50129',
      '50217',
      '50220',
      '50235',
      '51462'
    ],
    '015': [
      '50014',
      '50036',
      '50107',
      '50134',
      '50156',
      '50212',
      '50217',
      '50220',
      '50223',
      '50235',
      '50244',
      '50248',
      '50249',
      '50276',
      '50530'
    ]
  },
  '46': {
    '079': [
      '57016',
      '57022',
      '57042',
      '57050',
      '57054',
      '57057',
      '57071',
      '57075',
      '57076'
    ],
    '123': [
      '57528',
      '57529',
      '57534',
      '57541',
      '57580',
      '57584'
    ],
    '099': [
      '57003',
      '57005',
      '57018',
      '57020',
      '57022',
      '57030',
      '57033',
      '57035',
      '57041',
      '57048',
      '57055',
      '57068',
      '57103',
      '57104',
      '57105',
      '57106',
      '57107',
      '57108',
      '57110',
      '57117',
      '57197'
    ],
    '009': [
      '57059',
      '57062',
      '57063',
      '57066',
      '57315',
      '57376'
    ],
    '119': [
      '57442',
      '57501',
      '57520',
      '57536',
      '57564'
    ],
    '055': [
      '57552',
      '57553',
      '57567',
      '57775'
    ],
    '029': [
      '57201',
      '57235',
      '57238',
      '57242',
      '57243',
      '57245',
      '57263',
      '57272'
    ],
    '109': [
      '56219',
      '57216',
      '57224',
      '57227',
      '57252',
      '57255',
      '57256',
      '57257',
      '57260',
      '57262',
      '57266',
      '57270',
      '57273',
      '57279'
    ],
    '117': [
      '57532',
      '57537',
      '57552'
    ],
    '021': [
      '57437',
      '57452',
      '57631',
      '57632',
      '57646',
      '57648'
    ],
    '053': [
      '57317',
      '57335',
      '57523',
      '57529',
      '57533',
      '57538',
      '57571',
      '68719'
    ],
    '031': [
      '57601',
      '57621',
      '57633',
      '57634',
      '57638',
      '57639',
      '57641',
      '57642',
      '57644',
      '57645',
      '57656',
      '57657',
      '57658',
      '57660'
    ],
    '091': [
      '57232',
      '57247',
      '57262',
      '57270',
      '57421',
      '57430',
      '57432',
      '57454'
    ],
    '063': [
      '57649',
      '57650',
      '57651',
      '57717',
      '57720',
      '57724',
      '57755',
      '57760',
      '57776',
      '58623',
      '58649',
      '58653'
    ],
    '025': [
      '57217',
      '57221',
      '57225',
      '57236',
      '57243',
      '57258',
      '57271',
      '57272',
      '57274',
      '57278',
      '57322',
      '57434'
    ],
    '059': [
      '57362',
      '57371',
      '57373',
      '57381',
      '57467',
      '57469',
      '57470',
      '57476'
    ],
    '111': [
      '57314',
      '57359',
      '57363',
      '57385'
    ],
    '039': [
      '57213',
      '57218',
      '57223',
      '57226',
      '57234',
      '57237',
      '57238',
      '57259',
      '57265',
      '57268'
    ],
    '011': [
      '57002',
      '57006',
      '57026',
      '57061',
      '57071',
      '57212',
      '57213',
      '57220',
      '57268',
      '57276'
    ],
    '121': [
      '57555',
      '57563',
      '57566',
      '57570',
      '57572',
      '57580',
      '69201',
      '69212',
      '69216'
    ],
    '125': [
      '57014',
      '57015',
      '57021',
      '57029',
      '57036',
      '57037',
      '57039',
      '57043',
      '57047',
      '57053',
      '57064',
      '57070',
      '57073',
      '57319'
    ],
    '035': [
      '57301',
      '57331',
      '57334',
      '57359',
      '57363'
    ],
    '027': [
      '57004',
      '57010',
      '57014',
      '57031',
      '57037',
      '57069',
      '57072',
      '57073'
    ],
    '037': [
      '57219',
      '57235',
      '57239',
      '57261',
      '57273',
      '57274',
      '57422',
      '57434',
      '57454',
      '57468'
    ],
    '085': [
      '57365',
      '57532',
      '57533',
      '57544',
      '57548',
      '57568',
      '57569',
      '57576'
    ],
    '137': [
      '57622',
      '57623',
      '57625',
      '57626',
      '57633',
      '57644',
      '57748'
    ],
    '103': [
      '57701',
      '57702',
      '57703',
      '57706',
      '57718',
      '57719',
      '57725',
      '57730',
      '57744',
      '57745',
      '57751',
      '57761',
      '57767',
      '57775',
      '57780',
      '57790',
      '57791',
      '82701'
    ],
    '089': [
      '57437',
      '57448',
      '57456',
      '57457',
      '57481',
      '58439'
    ],
    '045': [
      '57428',
      '57435',
      '57448',
      '57451',
      '57456',
      '57460',
      '57466',
      '57471',
      '57475',
      '57481'
    ],
    '107': [
      '57442',
      '57450',
      '57455',
      '57473',
      '57475'
    ],
    '127': [
      '51001',
      '51023',
      '57001',
      '57004',
      '57010',
      '57025',
      '57034',
      '57038',
      '57049'
    ],
    '065': [
      '57501',
      '57522',
      '57536'
    ],
    '097': [
      '57076',
      '57321',
      '57323',
      '57337',
      '57340',
      '57349'
    ],
    '057': [
      '57212',
      '57221',
      '57223',
      '57226',
      '57234',
      '57238',
      '57241',
      '57242',
      '57248',
      '57271'
    ],
    '129': [
      '57420',
      '57428',
      '57450',
      '57452',
      '57472',
      '57601',
      '57631'
    ],
    '017': [
      '57325',
      '57339',
      '57341',
      '57355',
      '57370'
    ],
    '095': [
      '57521',
      '57560',
      '57566',
      '57579',
      '57580',
      '57585'
    ],
    '087': [
      '57012',
      '57035',
      '57043',
      '57047',
      '57048',
      '57053',
      '57058',
      '57319',
      '57321',
      '57332',
      '57374'
    ],
    '115': [
      '57258',
      '57322',
      '57348',
      '57424',
      '57429',
      '57434',
      '57436',
      '57440',
      '57460',
      '57461',
      '57465',
      '57469',
      '57476',
      '57477'
    ],
    '041': [
      '57442',
      '57601',
      '57625',
      '57630',
      '57633',
      '57636',
      '57652',
      '57656',
      '57657',
      '57661'
    ],
    '005': [
      '57312',
      '57322',
      '57324',
      '57348',
      '57350',
      '57353',
      '57379',
      '57381',
      '57382',
      '57384',
      '57386'
    ],
    '051': [
      '57216',
      '57227',
      '57246',
      '57251',
      '57252',
      '57256',
      '57259',
      '57263',
      '57264',
      '57265',
      '57266',
      '57269'
    ],
    '077': [
      '57051',
      '57212',
      '57214',
      '57231',
      '57233',
      '57248',
      '57249',
      '57353'
    ],
    '033': [
      '57722',
      '57730',
      '57735',
      '57738',
      '57744',
      '57747',
      '57751',
      '57773',
      '82701'
    ],
    '069': [
      '57345',
      '57346',
      '57540',
      '57564'
    ],
    '093': [
      '57626',
      '57701',
      '57702',
      '57706',
      '57718',
      '57719',
      '57741',
      '57748',
      '57758',
      '57761',
      '57767',
      '57769',
      '57785',
      '57787',
      '57788',
      '57791',
      '57792',
      '57793'
    ],
    '067': [
      '57029',
      '57043',
      '57045',
      '57052',
      '57059',
      '57311',
      '57319',
      '57330',
      '57331',
      '57332',
      '57334',
      '57366',
      '57376'
    ],
    '105': [
      '57620',
      '57626',
      '57638',
      '57640',
      '57644',
      '57649',
      '57758'
    ],
    '113': [
      '57716',
      '57722',
      '57744',
      '57752',
      '57756',
      '57764',
      '57770',
      '57772',
      '57794'
    ],
    '071': [
      '57521',
      '57543',
      '57547',
      '57552',
      '57567',
      '57577',
      '57750',
      '57752',
      '57775'
    ],
    '075': [
      '57531',
      '57552',
      '57559',
      '57562',
      '57576'
    ],
    '047': [
      '57722',
      '57735',
      '57747',
      '57763',
      '57766',
      '57782'
    ],
    '081': [
      '57717',
      '57732',
      '57754',
      '57759',
      '57779',
      '57783',
      '57785',
      '57793',
      '57799'
    ],
    '007': [
      '57551',
      '57560',
      '57574',
      '57577',
      '57714',
      '57716',
      '57752'
    ],
    '023': [
      '57313',
      '57329',
      '57330',
      '57342',
      '57356',
      '57361',
      '57367',
      '57369',
      '57380'
    ],
    '043': [
      '57313',
      '57328',
      '57330',
      '57331',
      '57344',
      '57364',
      '57366',
      '57369'
    ],
    '135': [
      '57031',
      '57037',
      '57040',
      '57045',
      '57046',
      '57063',
      '57067',
      '57070',
      '57072',
      '57078'
    ],
    '101': [
      '56144',
      '56164',
      '57006',
      '57017',
      '57022',
      '57024',
      '57026',
      '57028',
      '57065'
    ],
    '061': [
      '57301',
      '57311',
      '57321',
      '57332',
      '57334',
      '57340',
      '57374'
    ],
    '049': [
      '57424',
      '57435',
      '57438',
      '57460',
      '57465',
      '57466',
      '57467',
      '57469',
      '57470',
      '57473'
    ],
    '019': [
      '57717',
      '57760',
      '57762',
      '57783',
      '57788',
      '57793'
    ],
    '015': [
      '57325',
      '57355',
      '57369',
      '57370',
      '57383'
    ],
    '083': [
      '57001',
      '57004',
      '57013',
      '57014',
      '57027',
      '57032',
      '57034',
      '57039',
      '57064',
      '57077',
      '57106',
      '57108'
    ],
    '013': [
      '57401',
      '57426',
      '57427',
      '57432',
      '57433',
      '57434',
      '57439',
      '57441',
      '57445',
      '57446',
      '57449',
      '57451',
      '57460',
      '57474',
      '57479',
      '57481'
    ],
    '073': [
      '57312',
      '57355',
      '57358',
      '57382',
      '57385'
    ],
    '003': [
      '57359',
      '57368',
      '57375',
      '57382',
      '57383',
      '57385'
    ]
  },
  '54': {
    '079': [
      '25011',
      '25033',
      '25070',
      '25082',
      '25109',
      '25123',
      '25124',
      '25143',
      '25159',
      '25168',
      '25177',
      '25213',
      '25245',
      '25510',
      '25523',
      '25526',
      '25560'
    ],
    '099': [
      '25504',
      '25507',
      '25511',
      '25512',
      '25514',
      '25517',
      '25530',
      '25534',
      '25535',
      '25555',
      '25570',
      '25669',
      '25674',
      '25699',
      '25701',
      '25704'
    ],
    '067': [
      '25043',
      '25059',
      '25063',
      '25111',
      '25125',
      '25981',
      '26202',
      '26205',
      '26208',
      '26261',
      '26610',
      '26617',
      '26651',
      '26656',
      '26660',
      '26662',
      '26676',
      '26678',
      '26679',
      '26680',
      '26681',
      '26684',
      '26690',
      '26691'
    ],
    '055': [
      '24701',
      '24712',
      '24714',
      '24715',
      '24724',
      '24729',
      '24731',
      '24733',
      '24736',
      '24737',
      '24738',
      '24740',
      '24747',
      '25820',
      '25841',
      '25902',
      '25922',
      '25971',
      '25979'
    ],
    '029': [
      '26034',
      '26047',
      '26050',
      '26056',
      '26062'
    ],
    '109': [
      '24716',
      '24719',
      '24726',
      '24736',
      '24801',
      '24818',
      '24822',
      '24823',
      '24827',
      '24828',
      '24834',
      '24839',
      '24845',
      '24847',
      '24849',
      '24851',
      '24854',
      '24857',
      '24860',
      '24867',
      '24869',
      '24870',
      '24874',
      '24880',
      '24882',
      '24898',
      '25810',
      '25811',
      '25826',
      '25845',
      '25848',
      '25870',
      '25875',
      '25876',
      '25882',
      '25913',
      '25916',
      '25928'
    ],
    '021': [
      '25267',
      '26137',
      '26335',
      '26342',
      '26351',
      '26384',
      '26412',
      '26430',
      '26443',
      '26611',
      '26636',
      '26638'
    ],
    '053': [
      '25082',
      '25106',
      '25123',
      '25187',
      '25239',
      '25241',
      '25247',
      '25253',
      '25260',
      '25264',
      '25265',
      '25287',
      '25502',
      '25503',
      '25515',
      '25520',
      '25526',
      '25541',
      '25550'
    ],
    '031': [
      '26755',
      '26801',
      '26810',
      '26812',
      '26818',
      '26836',
      '26845',
      '26851',
      '26852'
    ],
    '091': [
      '26330',
      '26347',
      '26354',
      '26374',
      '26424',
      '26431',
      '26435',
      '26440',
      '26554'
    ],
    '063': [
      '24910',
      '24918',
      '24925',
      '24941',
      '24945',
      '24951',
      '24963',
      '24970',
      '24974',
      '24976',
      '24981',
      '24983',
      '24984'
    ],
    '025': [
      '24901',
      '24910',
      '24916',
      '24925',
      '24931',
      '24938',
      '24957',
      '24966',
      '24970',
      '24976',
      '24977',
      '24986',
      '24991',
      '25958',
      '25962',
      '25972',
      '25976',
      '25981',
      '25984',
      '26261',
      '26680'
    ],
    '059': [
      '24846',
      '24851',
      '25608',
      '25621',
      '25650',
      '25651',
      '25661',
      '25666',
      '25670',
      '25671',
      '25672',
      '25674',
      '25676',
      '25678',
      '25688',
      '25690',
      '25692',
      '25696'
    ],
    '039': [
      '25003',
      '25015',
      '25025',
      '25035',
      '25036',
      '25039',
      '25045',
      '25054',
      '25061',
      '25064',
      '25067',
      '25071',
      '25075',
      '25083',
      '25086',
      '25102',
      '25103',
      '25107',
      '25110',
      '25112',
      '25124',
      '25126',
      '25132',
      '25134',
      '25136',
      '25143',
      '25156',
      '25160',
      '25162',
      '25177',
      '25201',
      '25202',
      '25214',
      '25248',
      '25301',
      '25302',
      '25303',
      '25304',
      '25305',
      '25306',
      '25309',
      '25311',
      '25312',
      '25313',
      '25314',
      '25315',
      '25320'
    ],
    '011': [
      '25504',
      '25506',
      '25510',
      '25520',
      '25537',
      '25541',
      '25545',
      '25559',
      '25701',
      '25702',
      '25703',
      '25704',
      '25705'
    ],
    '035': [
      '25124',
      '25239',
      '25241',
      '25244',
      '25245',
      '25248',
      '25252',
      '25262',
      '25264',
      '25271',
      '25275',
      '26143',
      '26164'
    ],
    '027': [
      '25422',
      '25431',
      '25434',
      '25437',
      '25444',
      '26704',
      '26710',
      '26711',
      '26714',
      '26722',
      '26755',
      '26757',
      '26761',
      '26763',
      '26808',
      '26817',
      '26823',
      '26851',
      '26852',
      '26865'
    ],
    '037': [
      '20135',
      '25414',
      '25425',
      '25430',
      '25432',
      '25438',
      '25442',
      '25443',
      '25446'
    ],
    '085': [
      '26137',
      '26143',
      '26148',
      '26161',
      '26170',
      '26178',
      '26325',
      '26327',
      '26337',
      '26346',
      '26362',
      '26415',
      '26421',
      '26456'
    ],
    '017': [
      '26339',
      '26411',
      '26415',
      '26426',
      '26436',
      '26443',
      '26448',
      '26456'
    ],
    '089': [
      '24910',
      '24918',
      '24935',
      '24962',
      '24963',
      '24981',
      '25825',
      '25841',
      '25951',
      '25969',
      '25976',
      '25977',
      '25978',
      '25979',
      '25985'
    ],
    '045': [
      '25022',
      '25047',
      '25076',
      '25108',
      '25121',
      '25183',
      '25505',
      '25508',
      '25524',
      '25547',
      '25601',
      '25606',
      '25607',
      '25611',
      '25617',
      '25624',
      '25625',
      '25628',
      '25630',
      '25632',
      '25634',
      '25635',
      '25637',
      '25638',
      '25639',
      '25644',
      '25646',
      '25647',
      '25649',
      '25650',
      '25652',
      '25653',
      '25654'
    ],
    '107': [
      '26101',
      '26104',
      '26105',
      '26133',
      '26142',
      '26143',
      '26150',
      '26164',
      '26169',
      '26180',
      '26181',
      '26184',
      '26187'
    ],
    '101': [
      '26203',
      '26206',
      '26208',
      '26215',
      '26217',
      '26222',
      '26266',
      '26288',
      '26298',
      '26610',
      '26691'
    ],
    '097': [
      '26201',
      '26218',
      '26224',
      '26228',
      '26234',
      '26236',
      '26237',
      '26238',
      '26267',
      '26343'
    ],
    '057': [
      '26710',
      '26717',
      '26719',
      '26726',
      '26743',
      '26750',
      '26753',
      '26763',
      '26767'
    ],
    '001': [
      '26201',
      '26238',
      '26250',
      '26267',
      '26275',
      '26283',
      '26330',
      '26347',
      '26349',
      '26405',
      '26416',
      '26440'
    ],
    '103': [
      '26055',
      '26155',
      '26159',
      '26167',
      '26348',
      '26377',
      '26419',
      '26437',
      '26562',
      '26575',
      '26581',
      '26585'
    ],
    '087': [
      '25005',
      '25045',
      '25071',
      '25164',
      '25243',
      '25244',
      '25251',
      '25252',
      '25259',
      '25266',
      '25270',
      '25276',
      '25286'
    ],
    '095': [
      '26146',
      '26149',
      '26155',
      '26159',
      '26175',
      '26320',
      '26377',
      '26415',
      '26456'
    ],
    '041': [
      '26201',
      '26321',
      '26338',
      '26343',
      '26372',
      '26376',
      '26378',
      '26384',
      '26412',
      '26447',
      '26452'
    ],
    '005': [
      '25009',
      '25021',
      '25024',
      '25028',
      '25047',
      '25049',
      '25051',
      '25053',
      '25081',
      '25093',
      '25108',
      '25114',
      '25130',
      '25142',
      '25148',
      '25149',
      '25154',
      '25165',
      '25169',
      '25181',
      '25193',
      '25203',
      '25204',
      '25205',
      '25206',
      '25208',
      '25209',
      '25508',
      '25529',
      '25565'
    ],
    '051': [
      '26003',
      '26031',
      '26033',
      '26036',
      '26038',
      '26039',
      '26040',
      '26041',
      '26055',
      '26155'
    ],
    '077': [
      '26374',
      '26405',
      '26410',
      '26425',
      '26440',
      '26444',
      '26519',
      '26520',
      '26525',
      '26537',
      '26542',
      '26547',
      '26705',
      '26716',
      '26764'
    ],
    '033': [
      '26301',
      '26323',
      '26330',
      '26361',
      '26366',
      '26369',
      '26378',
      '26385',
      '26386',
      '26404',
      '26408',
      '26422',
      '26426',
      '26431',
      '26438',
      '26448',
      '26451',
      '26554',
      '26568',
      '26582',
      '26591'
    ],
    '069': [
      '26003',
      '26059',
      '26060',
      '26074'
    ],
    '093': [
      '26260',
      '26263',
      '26269',
      '26271',
      '26276',
      '26283',
      '26287',
      '26292'
    ],
    '009': [
      '26003',
      '26030',
      '26032',
      '26035',
      '26037',
      '26062',
      '26070',
      '26075'
    ],
    '105': [
      '25252',
      '26138',
      '26141',
      '26143',
      '26152',
      '26160',
      '26161',
      '26180'
    ],
    '071': [
      '26802',
      '26804',
      '26807',
      '26814',
      '26815',
      '26847',
      '26855',
      '26866',
      '26884'
    ],
    '075': [
      '24915',
      '24920',
      '24924',
      '24927',
      '24934',
      '24944',
      '24946',
      '24954',
      '24986',
      '26209',
      '26264',
      '26291'
    ],
    '047': [
      '24604',
      '24801',
      '24808',
      '24811',
      '24813',
      '24815',
      '24816',
      '24817',
      '24826',
      '24828',
      '24830',
      '24831',
      '24836',
      '24843',
      '24844',
      '24846',
      '24848',
      '24850',
      '24853',
      '24861',
      '24862',
      '24866',
      '24868',
      '24871',
      '24872',
      '24873',
      '24878',
      '24879',
      '24881',
      '24884',
      '24887',
      '24888',
      '24892',
      '24894'
    ],
    '081': [
      '25007',
      '25008',
      '25044',
      '25048',
      '25060',
      '25062',
      '25140',
      '25174',
      '25180',
      '25209',
      '25801',
      '25811',
      '25813',
      '25817',
      '25818',
      '25823',
      '25825',
      '25827',
      '25832',
      '25836',
      '25839',
      '25843',
      '25844',
      '25849',
      '25853',
      '25857',
      '25864',
      '25865',
      '25871',
      '25873',
      '25878',
      '25880',
      '25902',
      '25906',
      '25908',
      '25911',
      '25915',
      '25918',
      '25920',
      '25921',
      '25932',
      '25951',
      '25989'
    ],
    '083': [
      '26224',
      '26230',
      '26241',
      '26250',
      '26253',
      '26254',
      '26257',
      '26259',
      '26263',
      '26267',
      '26268',
      '26270',
      '26273',
      '26276',
      '26278',
      '26280',
      '26282',
      '26283',
      '26285',
      '26293',
      '26294',
      '26296'
    ],
    '023': [
      '26707',
      '26710',
      '26717',
      '26720',
      '26726',
      '26731',
      '26739',
      '26743',
      '26833',
      '26847',
      '26855'
    ],
    '043': [
      '25003',
      '25202',
      '25501',
      '25505',
      '25506',
      '25510',
      '25521',
      '25523',
      '25524',
      '25529',
      '25534',
      '25540',
      '25557',
      '25564',
      '25565',
      '25567',
      '25571',
      '25573'
    ],
    '065': [
      '25411',
      '25422',
      '25427',
      '25434'
    ],
    '061': [
      '26374',
      '26501',
      '26505',
      '26508',
      '26521',
      '26534',
      '26541',
      '26542',
      '26543',
      '26554',
      '26562',
      '26570',
      '26588',
      '26590'
    ],
    '049': [
      '26354',
      '26437',
      '26448',
      '26554',
      '26559',
      '26560',
      '26563',
      '26570',
      '26571',
      '26572',
      '26574',
      '26576',
      '26582',
      '26585',
      '26586',
      '26587',
      '26588',
      '26591'
    ],
    '019': [
      '25002',
      '25031',
      '25036',
      '25040',
      '25057',
      '25059',
      '25083',
      '25085',
      '25090',
      '25115',
      '25118',
      '25119',
      '25136',
      '25139',
      '25152',
      '25161',
      '25173',
      '25185',
      '25186',
      '25812',
      '25831',
      '25837',
      '25840',
      '25846',
      '25854',
      '25855',
      '25862',
      '25864',
      '25868',
      '25879',
      '25880',
      '25901',
      '25904',
      '25907',
      '25917',
      '25936',
      '25938',
      '25942',
      '25962',
      '25976',
      '26656',
      '26680',
      '26690'
    ],
    '015': [
      '25019',
      '25030',
      '25043',
      '25045',
      '25063',
      '25088',
      '25111',
      '25113',
      '25125',
      '25133',
      '25141',
      '25164',
      '25211',
      '25235',
      '25285',
      '26617'
    ],
    '007': [
      '25063',
      '26335',
      '26376',
      '26412',
      '26601',
      '26615',
      '26619',
      '26621',
      '26623',
      '26624',
      '26627',
      '26629',
      '26631',
      '26636'
    ],
    '013': [
      '25234',
      '25235',
      '25261',
      '25267',
      '25268',
      '26136',
      '26137',
      '26141',
      '26143',
      '26147',
      '26151',
      '26636',
      '26638'
    ],
    '073': [
      '26134',
      '26146',
      '26170',
      '26184',
      '26346'
    ],
    '003': [
      '25401',
      '25403',
      '25404',
      '25405',
      '25413',
      '25419',
      '25420',
      '25427',
      '25428',
      '25430',
      '25443'
    ]
  },
  '50': {
    '027': [
      '05001',
      '05031',
      '05032',
      '05034',
      '05035',
      '05037',
      '05048',
      '05052',
      '05053',
      '05055',
      '05056',
      '05059',
      '05060',
      '05062',
      '05065',
      '05067',
      '05068',
      '05071',
      '05084',
      '05089',
      '05091',
      '05142',
      '05143',
      '05148',
      '05149',
      '05150',
      '05151',
      '05153',
      '05156',
      '05161',
      '05767',
      '05772'
    ],
    '001': [
      '05443',
      '05456',
      '05461',
      '05472',
      '05473',
      '05487',
      '05491',
      '05669',
      '05733',
      '05734',
      '05740',
      '05747',
      '05748',
      '05753',
      '05760',
      '05766',
      '05769',
      '05770',
      '05778'
    ],
    '017': [
      '05033',
      '05036',
      '05038',
      '05039',
      '05040',
      '05041',
      '05043',
      '05045',
      '05051',
      '05058',
      '05060',
      '05061',
      '05068',
      '05069',
      '05070',
      '05072',
      '05075',
      '05076',
      '05077',
      '05079',
      '05081',
      '05083',
      '05086',
      '05641',
      '05649',
      '05654',
      '05663',
      '05669',
      '05675',
      '05679'
    ],
    '023': [
      '05060',
      '05602',
      '05640',
      '05641',
      '05647',
      '05648',
      '05649',
      '05650',
      '05651',
      '05654',
      '05658',
      '05660',
      '05663',
      '05664',
      '05667',
      '05669',
      '05673',
      '05674',
      '05676',
      '05677',
      '05678',
      '05681',
      '05682',
      '05843'
    ],
    '005': [
      '05042',
      '05046',
      '05050',
      '05069',
      '05819',
      '05821',
      '05824',
      '05828',
      '05832',
      '05836',
      '05837',
      '05842',
      '05843',
      '05850',
      '05851',
      '05862',
      '05866',
      '05867',
      '05871',
      '05873'
    ],
    '021': [
      '05253',
      '05701',
      '05730',
      '05732',
      '05733',
      '05735',
      '05736',
      '05737',
      '05738',
      '05739',
      '05742',
      '05743',
      '05744',
      '05751',
      '05757',
      '05758',
      '05759',
      '05760',
      '05761',
      '05762',
      '05763',
      '05764',
      '05765',
      '05767',
      '05773',
      '05774',
      '05775',
      '05777',
      '05778'
    ],
    '003': [
      '05148',
      '05152',
      '05155',
      '05201',
      '05250',
      '05251',
      '05252',
      '05253',
      '05254',
      '05255',
      '05257',
      '05260',
      '05261',
      '05262',
      '05340',
      '05350',
      '05352',
      '05363',
      '05776'
    ],
    '009': [
      '05824',
      '05837',
      '05846',
      '05858',
      '05901',
      '05902',
      '05903',
      '05904',
      '05905',
      '05906',
      '05907'
    ],
    '025': [
      '05101',
      '05141',
      '05143',
      '05146',
      '05148',
      '05154',
      '05155',
      '05158',
      '05301',
      '05341',
      '05342',
      '05343',
      '05345',
      '05346',
      '05353',
      '05354',
      '05355',
      '05356',
      '05358',
      '05359',
      '05360',
      '05361',
      '05362',
      '05363'
    ],
    '019': [
      '05471',
      '05820',
      '05822',
      '05825',
      '05826',
      '05827',
      '05829',
      '05830',
      '05833',
      '05839',
      '05841',
      '05842',
      '05845',
      '05847',
      '05853',
      '05855',
      '05857',
      '05859',
      '05860',
      '05868',
      '05872',
      '05874',
      '05875'
    ],
    '015': [
      '05442',
      '05444',
      '05464',
      '05492',
      '05652',
      '05653',
      '05655',
      '05656',
      '05657',
      '05661',
      '05672',
      '05680'
    ],
    '007': [
      '05401',
      '05403',
      '05404',
      '05405',
      '05408',
      '05439',
      '05445',
      '05446',
      '05452',
      '05461',
      '05462',
      '05465',
      '05468',
      '05477',
      '05482',
      '05487',
      '05489',
      '05494',
      '05495',
      '05676'
    ],
    '013': [
      '05440',
      '05458',
      '05463',
      '05474',
      '05486'
    ],
    '011': [
      '05441',
      '05444',
      '05447',
      '05448',
      '05450',
      '05454',
      '05455',
      '05457',
      '05459',
      '05464',
      '05468',
      '05471',
      '05476',
      '05478',
      '05481',
      '05483',
      '05485',
      '05488'
    ]
  },
  '55': {
    '079': [
      '53110',
      '53129',
      '53130',
      '53132',
      '53154',
      '53172',
      '53202',
      '53203',
      '53204',
      '53205',
      '53206',
      '53207',
      '53208',
      '53209',
      '53210',
      '53211',
      '53212',
      '53213',
      '53214',
      '53215',
      '53216',
      '53217',
      '53218',
      '53219',
      '53220',
      '53221',
      '53222',
      '53223',
      '53224',
      '53225',
      '53226',
      '53227',
      '53228',
      '53233',
      '53235',
      '53295'
    ],
    '123': [
      '53929',
      '53968',
      '54619',
      '54621',
      '54623',
      '54624',
      '54628',
      '54632',
      '54634',
      '54638',
      '54639',
      '54651',
      '54652',
      '54655',
      '54658',
      '54664',
      '54665',
      '54667'
    ],
    '131': [
      '53002',
      '53010',
      '53012',
      '53017',
      '53021',
      '53022',
      '53027',
      '53029',
      '53033',
      '53037',
      '53040',
      '53076',
      '53086',
      '53090',
      '53091',
      '53095'
    ],
    '067': [
      '54175',
      '54408',
      '54409',
      '54418',
      '54424',
      '54428',
      '54430',
      '54435',
      '54462',
      '54465',
      '54485',
      '54491'
    ],
    '119': [
      '54411',
      '54422',
      '54425',
      '54433',
      '54447',
      '54451',
      '54460',
      '54470',
      '54480',
      '54490',
      '54498',
      '54766',
      '54768',
      '54771'
    ],
    '055': [
      '53036',
      '53038',
      '53066',
      '53094',
      '53118',
      '53137',
      '53156',
      '53178',
      '53190',
      '53523',
      '53534',
      '53538',
      '53549',
      '53551',
      '53559',
      '53563',
      '53594'
    ],
    '029': [
      '54201',
      '54202',
      '54204',
      '54205',
      '54209',
      '54210',
      '54211',
      '54212',
      '54213',
      '54217',
      '54234',
      '54235',
      '54246'
    ],
    '109': [
      '54002',
      '54005',
      '54007',
      '54013',
      '54015',
      '54016',
      '54017',
      '54020',
      '54022',
      '54023',
      '54025',
      '54026',
      '54027',
      '54028',
      '54082',
      '54734',
      '54749',
      '54767'
    ],
    '021': [
      '53532',
      '53555',
      '53561',
      '53578',
      '53583',
      '53901',
      '53911',
      '53913',
      '53923',
      '53925',
      '53926',
      '53928',
      '53932',
      '53935',
      '53954',
      '53955',
      '53956',
      '53960',
      '53965',
      '53969'
    ],
    '053': [
      '54466',
      '54611',
      '54615',
      '54616',
      '54627',
      '54635',
      '54641',
      '54642',
      '54643',
      '54644',
      '54659',
      '54666',
      '54741',
      '54746',
      '54754',
      '54758'
    ],
    '031': [
      '54820',
      '54830',
      '54836',
      '54838',
      '54842',
      '54849',
      '54854',
      '54859',
      '54864',
      '54873',
      '54874',
      '54880'
    ],
    '091': [
      '54721',
      '54736',
      '54737',
      '54755',
      '54759',
      '54769'
    ],
    '063': [
      '54601',
      '54603',
      '54614',
      '54619',
      '54623',
      '54636',
      '54642',
      '54644',
      '54650',
      '54653',
      '54656',
      '54658',
      '54661',
      '54667',
      '54669'
    ],
    '139': [
      '54901',
      '54902',
      '54904',
      '54914',
      '54915',
      '54923',
      '54927',
      '54934',
      '54940',
      '54947',
      '54952',
      '54956',
      '54963',
      '54964',
      '54971',
      '54979',
      '54980',
      '54985',
      '54986'
    ],
    '025': [
      '53508',
      '53515',
      '53516',
      '53517',
      '53521',
      '53523',
      '53527',
      '53528',
      '53529',
      '53531',
      '53532',
      '53534',
      '53544',
      '53555',
      '53558',
      '53559',
      '53560',
      '53562',
      '53571',
      '53572',
      '53574',
      '53575',
      '53583',
      '53589',
      '53590',
      '53593',
      '53594',
      '53597',
      '53598',
      '53703',
      '53704',
      '53705',
      '53706',
      '53711',
      '53713',
      '53714',
      '53715',
      '53716',
      '53717',
      '53718',
      '53719',
      '53726',
      '53792',
      '53911',
      '53925'
    ],
    '059': [
      '53104',
      '53105',
      '53128',
      '53139',
      '53140',
      '53142',
      '53143',
      '53144',
      '53158',
      '53168',
      '53170',
      '53177',
      '53179',
      '53181',
      '53182',
      '53192',
      '53403'
    ],
    '111': [
      '53556',
      '53561',
      '53577',
      '53578',
      '53583',
      '53588',
      '53913',
      '53924',
      '53937',
      '53941',
      '53943',
      '53944',
      '53951',
      '53959',
      '53961',
      '53965',
      '53968'
    ],
    '039': [
      '53006',
      '53010',
      '53011',
      '53014',
      '53019',
      '53040',
      '53048',
      '53049',
      '53057',
      '53061',
      '53065',
      '53079',
      '53919',
      '53931',
      '53946',
      '53963',
      '54932',
      '54935',
      '54937',
      '54964',
      '54971',
      '54974',
      '54979'
    ],
    '011': [
      '54610',
      '54612',
      '54622',
      '54629',
      '54736',
      '54747',
      '54755',
      '54756'
    ],
    '121': [
      '54612',
      '54616',
      '54625',
      '54627',
      '54630',
      '54642',
      '54659',
      '54661',
      '54738',
      '54747',
      '54758',
      '54760',
      '54770',
      '54773'
    ],
    '117': [
      '53001',
      '53011',
      '53013',
      '53015',
      '53020',
      '53023',
      '53031',
      '53040',
      '53044',
      '53070',
      '53073',
      '53075',
      '53079',
      '53081',
      '53083',
      '53085',
      '53093'
    ],
    '041': [
      '54103',
      '54104',
      '54125',
      '54465',
      '54511',
      '54520',
      '54521',
      '54541',
      '54542',
      '54562',
      '54566'
    ],
    '033': [
      '54005',
      '54013',
      '54701',
      '54703',
      '54721',
      '54724',
      '54725',
      '54730',
      '54734',
      '54736',
      '54737',
      '54739',
      '54740',
      '54749',
      '54751',
      '54755',
      '54757',
      '54762',
      '54763',
      '54765',
      '54767',
      '54772'
    ],
    '037': [
      '54120',
      '54121',
      '54151',
      '54542'
    ],
    '125': [
      '54512',
      '54519',
      '54521',
      '54538',
      '54540',
      '54545',
      '54548',
      '54554',
      '54557',
      '54558',
      '54560',
      '54561',
      '54568'
    ],
    '085': [
      '54428',
      '54463',
      '54487',
      '54501',
      '54521',
      '54529',
      '54531',
      '54538',
      '54539',
      '54548',
      '54558',
      '54562',
      '54564',
      '54568'
    ],
    '137': [
      '53964',
      '54909',
      '54923',
      '54930',
      '54940',
      '54943',
      '54960',
      '54965',
      '54966',
      '54967',
      '54970',
      '54981',
      '54982',
      '54983',
      '54984',
      '54986'
    ],
    '017': [
      '54433',
      '54703',
      '54724',
      '54726',
      '54727',
      '54729',
      '54730',
      '54732',
      '54739',
      '54745',
      '54748',
      '54757',
      '54766',
      '54768'
    ],
    '089': [
      '53004',
      '53012',
      '53013',
      '53021',
      '53024',
      '53074',
      '53075',
      '53080',
      '53090',
      '53092',
      '53095',
      '53097',
      '53217'
    ],
    '045': [
      '53502',
      '53504',
      '53508',
      '53516',
      '53520',
      '53521',
      '53522',
      '53550',
      '53566',
      '53570',
      '53572',
      '53574'
    ],
    '107': [
      '54526',
      '54530',
      '54563',
      '54728',
      '54731',
      '54745',
      '54757',
      '54766',
      '54817',
      '54819',
      '54835',
      '54848',
      '54868',
      '54895',
      '54896'
    ],
    '127': [
      '53105',
      '53114',
      '53115',
      '53119',
      '53120',
      '53121',
      '53125',
      '53128',
      '53147',
      '53149',
      '53184',
      '53190',
      '53191',
      '53195',
      '53585'
    ],
    '065': [
      '53504',
      '53510',
      '53516',
      '53522',
      '53530',
      '53541',
      '53565',
      '53586',
      '53587',
      '53599',
      '53803',
      '53807',
      '53811',
      '53818'
    ],
    '099': [
      '54459',
      '54470',
      '54490',
      '54513',
      '54514',
      '54515',
      '54524',
      '54530',
      '54537',
      '54538',
      '54552',
      '54555',
      '54556'
    ],
    '097': [
      '54406',
      '54407',
      '54423',
      '54443',
      '54454',
      '54455',
      '54458',
      '54467',
      '54473',
      '54475',
      '54481',
      '54482',
      '54494',
      '54499',
      '54909',
      '54921',
      '54945',
      '54966',
      '54977',
      '54981',
      '54984'
    ],
    '057': [
      '53929',
      '53941',
      '53944',
      '53948',
      '53950',
      '53965',
      '53968',
      '54457',
      '54618',
      '54634',
      '54637',
      '54638',
      '54646',
      '54666'
    ],
    '001': [
      '53910',
      '53920',
      '53934',
      '53936',
      '53952',
      '53964',
      '53965',
      '54457',
      '54494',
      '54613',
      '54921',
      '54930',
      '54943',
      '54966'
    ],
    '129': [
      '54801',
      '54813',
      '54817',
      '54830',
      '54843',
      '54859',
      '54868',
      '54870',
      '54871',
      '54875',
      '54876',
      '54888'
    ],
    '103': [
      '53518',
      '53540',
      '53556',
      '53573',
      '53581',
      '53924',
      '53937',
      '54634',
      '54639',
      '54655',
      '54664'
    ],
    '115': [
      '54107',
      '54111',
      '54124',
      '54127',
      '54128',
      '54137',
      '54150',
      '54154',
      '54162',
      '54165',
      '54166',
      '54170',
      '54408',
      '54409',
      '54414',
      '54416',
      '54427',
      '54450',
      '54486',
      '54499',
      '54928',
      '54929',
      '54948',
      '54950'
    ],
    '087': [
      '54106',
      '54107',
      '54113',
      '54115',
      '54130',
      '54136',
      '54140',
      '54155',
      '54165',
      '54170',
      '54180',
      '54313',
      '54911',
      '54913',
      '54914',
      '54915',
      '54922',
      '54929',
      '54931',
      '54940',
      '54942',
      '54944',
      '54956',
      '54961'
    ],
    '095': [
      '54001',
      '54004',
      '54005',
      '54006',
      '54007',
      '54009',
      '54017',
      '54020',
      '54024',
      '54026',
      '54810',
      '54824',
      '54826',
      '54829',
      '54837',
      '54840',
      '54853',
      '54858',
      '54872',
      '54889'
    ],
    '035': [
      '54701',
      '54703',
      '54720',
      '54722',
      '54726',
      '54727',
      '54729',
      '54738',
      '54739',
      '54741',
      '54742',
      '54755',
      '54758',
      '54768',
      '54770'
    ],
    '005': [
      '54004',
      '54005',
      '54728',
      '54733',
      '54757',
      '54762',
      '54763',
      '54805',
      '54812',
      '54813',
      '54817',
      '54822',
      '54826',
      '54829',
      '54841',
      '54857',
      '54868',
      '54870',
      '54889'
    ],
    '051': [
      '54514',
      '54525',
      '54534',
      '54536',
      '54538',
      '54545',
      '54547',
      '54550',
      '54552',
      '54559',
      '54565'
    ],
    '077': [
      '53901',
      '53920',
      '53926',
      '53930',
      '53946',
      '53949',
      '53952',
      '53953',
      '53954',
      '53964',
      '54930',
      '54960',
      '54968',
      '54982'
    ],
    '027': [
      '53003',
      '53006',
      '53016',
      '53027',
      '53032',
      '53034',
      '53035',
      '53036',
      '53039',
      '53047',
      '53048',
      '53050',
      '53059',
      '53065',
      '53066',
      '53078',
      '53091',
      '53098',
      '53557',
      '53579',
      '53594',
      '53916',
      '53922',
      '53925',
      '53932',
      '53933',
      '53946',
      '53956',
      '53963'
    ],
    '069': [
      '54435',
      '54442',
      '54452',
      '54487',
      '54501',
      '54564'
    ],
    '093': [
      '54003',
      '54010',
      '54011',
      '54014',
      '54021',
      '54022',
      '54723',
      '54740',
      '54750',
      '54761',
      '54767',
      '54769'
    ],
    '009': [
      '54110',
      '54115',
      '54126',
      '54130',
      '54155',
      '54162',
      '54165',
      '54173',
      '54180',
      '54208',
      '54217',
      '54229',
      '54301',
      '54302',
      '54303',
      '54304',
      '54307',
      '54311',
      '54313'
    ],
    '105': [
      '53114',
      '53190',
      '53501',
      '53505',
      '53511',
      '53520',
      '53521',
      '53525',
      '53534',
      '53536',
      '53537',
      '53538',
      '53545',
      '53546',
      '53548',
      '53563',
      '53576',
      '53585',
      '53589'
    ],
    '113': [
      '54517',
      '54530',
      '54552',
      '54817',
      '54821',
      '54828',
      '54835',
      '54843',
      '54862',
      '54867',
      '54876',
      '54896'
    ],
    '133': [
      '53005',
      '53007',
      '53017',
      '53018',
      '53029',
      '53036',
      '53045',
      '53046',
      '53051',
      '53058',
      '53066',
      '53069',
      '53072',
      '53089',
      '53103',
      '53118',
      '53119',
      '53122',
      '53146',
      '53149',
      '53150',
      '53151',
      '53153',
      '53183',
      '53186',
      '53188',
      '53189',
      '53225',
      '53702'
    ],
    '071': [
      '53014',
      '53015',
      '53020',
      '53042',
      '53061',
      '53063',
      '54110',
      '54126',
      '54207',
      '54208',
      '54214',
      '54220',
      '54227',
      '54228',
      '54230',
      '54232',
      '54241',
      '54245',
      '54247'
    ],
    '141': [
      '54410',
      '54412',
      '54413',
      '54420',
      '54441',
      '54443',
      '54449',
      '54454',
      '54457',
      '54466',
      '54469',
      '54475',
      '54489',
      '54494',
      '54495'
    ],
    '075': [
      '54102',
      '54103',
      '54104',
      '54112',
      '54114',
      '54119',
      '54120',
      '54125',
      '54139',
      '54143',
      '54151',
      '54156',
      '54157',
      '54159',
      '54161',
      '54177'
    ],
    '047': [
      '53923',
      '53926',
      '53939',
      '53946',
      '53947',
      '53949',
      '53956',
      '54923',
      '54941',
      '54960',
      '54968',
      '54971'
    ],
    '007': [
      '54517',
      '54806',
      '54814',
      '54820',
      '54821',
      '54827',
      '54832',
      '54838',
      '54839',
      '54844',
      '54847',
      '54856',
      '54865',
      '54873',
      '54891'
    ],
    '023': [
      '53518',
      '53805',
      '53821',
      '53826',
      '54624',
      '54626',
      '54628',
      '54631',
      '54645',
      '54654',
      '54655',
      '54657'
    ],
    '043': [
      '53518',
      '53543',
      '53554',
      '53569',
      '53573',
      '53801',
      '53802',
      '53804',
      '53805',
      '53806',
      '53807',
      '53808',
      '53809',
      '53810',
      '53811',
      '53813',
      '53816',
      '53817',
      '53818',
      '53820',
      '53821',
      '53825',
      '53827'
    ],
    '078': [
      '54124',
      '54135',
      '54150',
      '54174'
    ],
    '135': [
      '54170',
      '54486',
      '54922',
      '54929',
      '54933',
      '54940',
      '54945',
      '54946',
      '54949',
      '54950',
      '54961',
      '54962',
      '54965',
      '54977',
      '54981',
      '54983'
    ],
    '101': [
      '53105',
      '53108',
      '53120',
      '53126',
      '53139',
      '53149',
      '53150',
      '53167',
      '53177',
      '53182',
      '53185',
      '53402',
      '53403',
      '53404',
      '53405',
      '53406'
    ],
    '081': [
      '53929',
      '54615',
      '54618',
      '54619',
      '54638',
      '54648',
      '54651',
      '54653',
      '54656',
      '54660',
      '54666',
      '54670'
    ],
    '061': [
      '54201',
      '54205',
      '54208',
      '54213',
      '54216',
      '54217'
    ],
    '049': [
      '53503',
      '53506',
      '53507',
      '53516',
      '53517',
      '53526',
      '53533',
      '53543',
      '53544',
      '53553',
      '53554',
      '53560',
      '53565',
      '53569',
      '53573',
      '53580',
      '53582',
      '53588',
      '53818'
    ],
    '019': [
      '54405',
      '54420',
      '54421',
      '54422',
      '54425',
      '54436',
      '54437',
      '54446',
      '54449',
      '54456',
      '54460',
      '54466',
      '54479',
      '54488',
      '54493',
      '54498',
      '54741',
      '54746',
      '54754',
      '54768',
      '54771'
    ],
    '003': [
      '54514',
      '54517',
      '54527',
      '54546',
      '54559',
      '54806',
      '54846',
      '54850',
      '54855',
      '54856',
      '54861'
    ],
    '083': [
      '54101',
      '54111',
      '54112',
      '54114',
      '54124',
      '54137',
      '54138',
      '54139',
      '54141',
      '54149',
      '54153',
      '54154',
      '54157',
      '54161',
      '54162',
      '54171',
      '54174',
      '54175',
      '54491'
    ],
    '013': [
      '54801',
      '54813',
      '54830',
      '54837',
      '54840',
      '54845',
      '54853',
      '54871',
      '54872',
      '54893'
    ],
    '073': [
      '54401',
      '54403',
      '54405',
      '54408',
      '54409',
      '54411',
      '54412',
      '54414',
      '54417',
      '54421',
      '54425',
      '54426',
      '54427',
      '54440',
      '54443',
      '54448',
      '54449',
      '54451',
      '54452',
      '54454',
      '54455',
      '54471',
      '54473',
      '54474',
      '54476',
      '54479',
      '54480',
      '54484',
      '54488',
      '54499'
    ],
    '015': [
      '53014',
      '53020',
      '53042',
      '53049',
      '53061',
      '53088',
      '54110',
      '54123',
      '54129',
      '54130',
      '54160',
      '54169',
      '54915',
      '54952'
    ]
  },
  '32': {
    '510': [
      '89701',
      '89702',
      '89703',
      '89705',
      '89706'
    ],
    '033': [
      '84034',
      '89301',
      '89311',
      '89316',
      '89317',
      '89318',
      '89319'
    ],
    '027': [
      '89405',
      '89418',
      '89419',
      '89445'
    ],
    '001': [
      '89406',
      '89408'
    ],
    '017': [
      '89001',
      '89008',
      '89017',
      '89042',
      '89043'
    ],
    '029': [
      '89403',
      '89434',
      '89440',
      '89442',
      '89521'
    ],
    '023': [
      '89001',
      '89003',
      '89020',
      '89022',
      '89045',
      '89048',
      '89049',
      '89060',
      '89061',
      '89124',
      '89310',
      '89314',
      '89316',
      '89317',
      '89409'
    ],
    '005': [
      '89410',
      '89411',
      '89413',
      '89423',
      '89444',
      '89448',
      '89449',
      '89460',
      '89705'
    ],
    '021': [
      '89010',
      '89409',
      '89415',
      '89420',
      '89422',
      '89427'
    ],
    '031': [
      '89402',
      '89405',
      '89412',
      '89424',
      '89431',
      '89433',
      '89434',
      '89436',
      '89439',
      '89441',
      '89442',
      '89450',
      '89451',
      '89501',
      '89502',
      '89503',
      '89506',
      '89508',
      '89509',
      '89510',
      '89511',
      '89512',
      '89519',
      '89521',
      '89523',
      '89704'
    ],
    '003': [
      '89002',
      '89004',
      '89005',
      '89007',
      '89011',
      '89012',
      '89014',
      '89015',
      '89018',
      '89019',
      '89021',
      '89025',
      '89026',
      '89027',
      '89029',
      '89030',
      '89031',
      '89032',
      '89039',
      '89040',
      '89044',
      '89046',
      '89052',
      '89054',
      '89074',
      '89081',
      '89084',
      '89085',
      '89086',
      '89101',
      '89102',
      '89103',
      '89104',
      '89106',
      '89107',
      '89108',
      '89109',
      '89110',
      '89113',
      '89115',
      '89117',
      '89118',
      '89119',
      '89120',
      '89121',
      '89122',
      '89123',
      '89124',
      '89128',
      '89129',
      '89130',
      '89131',
      '89134',
      '89135',
      '89138',
      '89139',
      '89141',
      '89142',
      '89143',
      '89144',
      '89145',
      '89146',
      '89147',
      '89148',
      '89149',
      '89156',
      '89161',
      '89166',
      '89169',
      '89178',
      '89179',
      '89183',
      '89191'
    ],
    '009': [
      '89010',
      '89013',
      '89047',
      '89049'
    ],
    '019': [
      '89403',
      '89406',
      '89408',
      '89428',
      '89429',
      '89430',
      '89444',
      '89447',
      '89706'
    ],
    '015': [
      '89310',
      '89820'
    ],
    '007': [
      '89414',
      '89801',
      '89815',
      '89822',
      '89823',
      '89825',
      '89826',
      '89828',
      '89830',
      '89831',
      '89832',
      '89833',
      '89834',
      '89835',
      '89883'
    ],
    '013': [
      '89404',
      '89412',
      '89414',
      '89421',
      '89425',
      '89426',
      '89438',
      '89445',
      '89446'
    ],
    '011': [
      '89316',
      '89820',
      '89821',
      '89822'
    ]
  },
  '05': {
    '079': [
      '71603',
      '71639',
      '71643',
      '71644',
      '71655',
      '71667'
    ],
    '123': [
      '72322',
      '72326',
      '72335',
      '72340',
      '72346',
      '72348',
      '72359',
      '72372',
      '72392',
      '72394',
      '72396'
    ],
    '099': [
      '71722',
      '71764',
      '71770',
      '71827',
      '71835',
      '71857',
      '71858',
      '71860'
    ],
    '009': [
      '65733',
      '72601',
      '72611',
      '72630',
      '72633',
      '72644',
      '72662',
      '72685',
      '72687'
    ],
    '119': [
      '72002',
      '72016',
      '72023',
      '72046',
      '72065',
      '72076',
      '72099',
      '72103',
      '72113',
      '72114',
      '72116',
      '72117',
      '72118',
      '72119',
      '72120',
      '72122',
      '72126',
      '72135',
      '72142',
      '72201',
      '72202',
      '72204',
      '72205',
      '72206',
      '72207',
      '72209',
      '72210',
      '72211',
      '72212',
      '72223',
      '72227'
    ],
    '055': [
      '72401',
      '72412',
      '72416',
      '72425',
      '72436',
      '72443',
      '72450',
      '72461'
    ],
    '029': [
      '72001',
      '72013',
      '72025',
      '72027',
      '72030',
      '72031',
      '72063',
      '72080',
      '72107',
      '72110',
      '72125',
      '72127',
      '72156',
      '72157',
      '72823'
    ],
    '109': [
      '71852',
      '71921',
      '71922',
      '71940',
      '71943',
      '71950',
      '71952',
      '71958',
      '71959',
      '71971'
    ],
    '021': [
      '72422',
      '72424',
      '72430',
      '72435',
      '72436',
      '72441',
      '72453',
      '72454',
      '72456',
      '72461',
      '72464',
      '72470'
    ],
    '053': [
      '71602',
      '71603',
      '72015',
      '72057',
      '72065',
      '72079',
      '72084',
      '72128',
      '72129',
      '72132',
      '72150',
      '72167'
    ],
    '031': [
      '72401',
      '72404',
      '72411',
      '72414',
      '72416',
      '72417',
      '72419',
      '72421',
      '72427',
      '72437',
      '72447',
      '72450',
      '72467',
      '72479'
    ],
    '091': [
      '71834',
      '71837',
      '71839',
      '71854',
      '75556'
    ],
    '063': [
      '72020',
      '72112',
      '72165',
      '72501',
      '72521',
      '72522',
      '72523',
      '72524',
      '72526',
      '72527',
      '72534',
      '72550',
      '72553',
      '72562',
      '72564',
      '72568',
      '72571',
      '72572',
      '72579'
    ],
    '025': [
      '71603',
      '71652',
      '71660',
      '71665',
      '71667',
      '71671'
    ],
    '059': [
      '71901',
      '71913',
      '71921',
      '71923',
      '71929',
      '71933',
      '71941',
      '71943',
      '71964',
      '72084',
      '72104',
      '72167'
    ],
    '143': [
      '72701',
      '72703',
      '72704',
      '72717',
      '72727',
      '72729',
      '72730',
      '72738',
      '72744',
      '72749',
      '72753',
      '72761',
      '72762',
      '72764',
      '72769',
      '72773',
      '72774',
      '72959'
    ],
    '111': [
      '72324',
      '72354',
      '72365',
      '72377',
      '72386',
      '72429',
      '72432',
      '72472',
      '72479'
    ],
    '039': [
      '71720',
      '71725',
      '71742',
      '71763',
      '71923',
      '72084'
    ],
    '149': [
      '72025',
      '72824',
      '72827',
      '72828',
      '72833',
      '72834',
      '72838',
      '72842',
      '72853',
      '72857',
      '72860',
      '72943'
    ],
    '011': [
      '71631',
      '71647',
      '71651',
      '71660',
      '71671',
      '71675'
    ],
    '121': [
      '72413',
      '72425',
      '72434',
      '72444',
      '72449',
      '72455',
      '72459',
      '72460',
      '72462',
      '72476',
      '72478'
    ],
    '117': [
      '72017',
      '72024',
      '72040',
      '72041',
      '72060',
      '72064',
      '72160',
      '72170',
      '72176'
    ],
    '041': [
      '38769',
      '71630',
      '71638',
      '71639',
      '71654',
      '71662',
      '71666',
      '71670',
      '71674',
      '72328',
      '72379'
    ],
    '027': [
      '71740',
      '71752',
      '71753',
      '71764',
      '71770',
      '71860',
      '71861'
    ],
    '037': [
      '72324',
      '72326',
      '72331',
      '72346',
      '72347',
      '72373',
      '72396'
    ],
    '147': [
      '72006',
      '72021',
      '72036',
      '72059',
      '72074',
      '72101',
      '72112',
      '72123',
      '72392'
    ],
    '145': [
      '72010',
      '72012',
      '72020',
      '72045',
      '72060',
      '72068',
      '72081',
      '72082',
      '72085',
      '72102',
      '72111',
      '72121',
      '72136',
      '72137',
      '72139',
      '72143',
      '72149',
      '72173'
    ],
    '085': [
      '72007',
      '72023',
      '72024',
      '72037',
      '72046',
      '72072',
      '72076',
      '72083',
      '72086',
      '72142',
      '72160',
      '72176'
    ],
    '137': [
      '72031',
      '72044',
      '72051',
      '72153',
      '72519',
      '72530',
      '72533',
      '72550',
      '72555',
      '72560',
      '72567',
      '72645',
      '72650',
      '72663',
      '72680'
    ],
    '103': [
      '71701',
      '71720',
      '71726',
      '71751',
      '71762',
      '71763',
      '71764'
    ],
    '089': [
      '65729',
      '65733',
      '65761',
      '72601',
      '72619',
      '72633',
      '72634',
      '72644',
      '72653',
      '72661',
      '72668',
      '72672',
      '72675',
      '72677',
      '72682',
      '72687'
    ],
    '045': [
      '72023',
      '72032',
      '72034',
      '72035',
      '72039',
      '72047',
      '72058',
      '72061',
      '72076',
      '72106',
      '72111',
      '72120',
      '72131',
      '72173',
      '72181'
    ],
    '131': [
      '72901',
      '72903',
      '72904',
      '72908',
      '72916',
      '72923',
      '72927',
      '72933',
      '72936',
      '72937',
      '72938',
      '72940',
      '72941',
      '72944',
      '72945'
    ],
    '107': [
      '72069',
      '72328',
      '72333',
      '72342',
      '72353',
      '72355',
      '72366',
      '72367',
      '72374',
      '72383',
      '72389',
      '72390'
    ],
    '127': [
      '71953',
      '72833',
      '72841',
      '72926',
      '72927',
      '72944',
      '72950',
      '72958'
    ],
    '083': [
      '72826',
      '72834',
      '72835',
      '72851',
      '72855',
      '72863',
      '72865',
      '72927',
      '72933',
      '72943',
      '72949',
      '72951'
    ],
    '139': [
      '71724',
      '71730',
      '71747',
      '71749',
      '71753',
      '71758',
      '71759',
      '71762',
      '71765'
    ],
    '097': [
      '71933',
      '71935',
      '71943',
      '71957',
      '71960',
      '71961',
      '71965',
      '71969',
      '71970'
    ],
    '057': [
      '71801',
      '71825',
      '71835',
      '71838',
      '71847',
      '71852',
      '71855',
      '71857',
      '71859',
      '71862'
    ],
    '001': [
      '72003',
      '72026',
      '72038',
      '72042',
      '72048',
      '72055',
      '72073',
      '72140',
      '72160',
      '72166'
    ],
    '129': [
      '72633',
      '72636',
      '72639',
      '72645',
      '72650',
      '72669',
      '72675',
      '72679',
      '72685',
      '72686'
    ],
    '017': [
      '71638',
      '71640',
      '71653',
      '71661',
      '71663',
      '71676'
    ],
    '115': [
      '72063',
      '72080',
      '72679',
      '72801',
      '72802',
      '72823',
      '72837',
      '72839',
      '72843',
      '72846',
      '72847',
      '72856',
      '72858'
    ],
    '087': [
      '72632',
      '72703',
      '72721',
      '72727',
      '72738',
      '72740',
      '72742',
      '72752',
      '72756',
      '72760',
      '72773',
      '72776'
    ],
    '095': [
      '72021',
      '72029',
      '72036',
      '72069',
      '72108',
      '72134',
      '72366',
      '72368',
      '72392'
    ],
    '035': [
      '72301',
      '72325',
      '72327',
      '72331',
      '72332',
      '72339',
      '72348',
      '72364',
      '72376',
      '72384',
      '72386'
    ],
    '005': [
      '72519',
      '72531',
      '72537',
      '72538',
      '72544',
      '72617',
      '72623',
      '72626',
      '72635',
      '72642',
      '72651',
      '72653',
      '72658'
    ],
    '051': [
      '71901',
      '71909',
      '71913',
      '71933',
      '71949',
      '71956',
      '71964',
      '71968',
      '72087'
    ],
    '077': [
      '72311',
      '72320',
      '72335',
      '72341',
      '72348',
      '72355',
      '72360',
      '72368',
      '72372'
    ],
    '033': [
      '72921',
      '72932',
      '72934',
      '72935',
      '72946',
      '72947',
      '72948',
      '72952',
      '72955',
      '72956',
      '72959'
    ],
    '069': [
      '71601',
      '71602',
      '71603',
      '71644',
      '71659',
      '72004',
      '72046',
      '72073',
      '72079',
      '72132',
      '72150',
      '72152',
      '72160',
      '72168',
      '72175'
    ],
    '093': [
      '72315',
      '72321',
      '72329',
      '72330',
      '72338',
      '72350',
      '72351',
      '72354',
      '72358',
      '72370',
      '72386',
      '72395',
      '72426',
      '72428',
      '72438',
      '72442'
    ],
    '067': [
      '72005',
      '72014',
      '72020',
      '72101',
      '72112',
      '72169',
      '72347',
      '72431',
      '72471',
      '72473',
      '72479'
    ],
    '105': [
      '72001',
      '72016',
      '72025',
      '72070',
      '72125',
      '72126',
      '72853',
      '72857'
    ],
    '113': [
      '71841',
      '71937',
      '71944',
      '71945',
      '71953',
      '71972',
      '71973'
    ],
    '133': [
      '71823',
      '71832',
      '71833',
      '71841',
      '71842',
      '71846'
    ],
    '071': [
      '72752',
      '72821',
      '72830',
      '72832',
      '72839',
      '72840',
      '72845',
      '72846',
      '72847',
      '72852',
      '72854',
      '72949'
    ],
    '141': [
      '72013',
      '72030',
      '72031',
      '72039',
      '72067',
      '72080',
      '72088',
      '72131',
      '72141',
      '72153',
      '72629',
      '72645'
    ],
    '075': [
      '72410',
      '72415',
      '72433',
      '72434',
      '72440',
      '72445',
      '72457',
      '72458',
      '72459',
      '72466',
      '72469',
      '72476',
      '72572'
    ],
    '047': [
      '72821',
      '72855',
      '72928',
      '72930',
      '72933',
      '72947',
      '72949',
      '72951',
      '72959'
    ],
    '061': [
      '71833',
      '71851',
      '71852',
      '71859',
      '71959',
      '71971',
      '71973'
    ],
    '023': [
      '72044',
      '72067',
      '72088',
      '72121',
      '72130',
      '72131',
      '72137',
      '72143',
      '72153',
      '72179',
      '72523',
      '72530',
      '72534',
      '72543',
      '72546',
      '72550',
      '72581'
    ],
    '043': [
      '71638',
      '71642',
      '71646',
      '71655',
      '71670',
      '71671',
      '71675',
      '71677'
    ],
    '065': [
      '72512',
      '72513',
      '72517',
      '72519',
      '72528',
      '72532',
      '72536',
      '72540',
      '72556',
      '72560',
      '72561',
      '72565',
      '72566',
      '72573',
      '72577',
      '72584',
      '72585',
      '72587'
    ],
    '135': [
      '72459',
      '72466',
      '72469',
      '72482',
      '72513',
      '72521',
      '72529',
      '72532',
      '72542',
      '72554',
      '72569',
      '72577',
      '72579'
    ],
    '101': [
      '72601',
      '72624',
      '72628',
      '72638',
      '72640',
      '72641',
      '72648',
      '72655',
      '72666',
      '72670',
      '72683',
      '72685',
      '72740',
      '72742',
      '72752',
      '72839',
      '72852',
      '72854',
      '72856'
    ],
    '081': [
      '71820',
      '71822',
      '71836',
      '71846',
      '71853',
      '71865',
      '71866'
    ],
    '125': [
      '71909',
      '72002',
      '72011',
      '72015',
      '72019',
      '72022',
      '72065',
      '72087',
      '72103',
      '72104',
      '72122',
      '72167',
      '72206',
      '72210'
    ],
    '049': [
      '72512',
      '72513',
      '72515',
      '72520',
      '72529',
      '72531',
      '72538',
      '72539',
      '72542',
      '72554',
      '72576',
      '72578',
      '72583'
    ],
    '019': [
      '71743',
      '71772',
      '71921',
      '71923',
      '71962',
      '71998',
      '71999'
    ],
    '015': [
      '72611',
      '72616',
      '72624',
      '72631',
      '72632',
      '72638',
      '72660',
      '72740'
    ],
    '007': [
      '72712',
      '72714',
      '72715',
      '72718',
      '72719',
      '72722',
      '72732',
      '72734',
      '72736',
      '72738',
      '72739',
      '72745',
      '72747',
      '72751',
      '72756',
      '72758',
      '72761',
      '72762',
      '72764',
      '72768'
    ],
    '013': [
      '71701',
      '71711',
      '71720',
      '71742',
      '71744',
      '71745',
      '71766'
    ],
    '073': [
      '71826',
      '71827',
      '71845',
      '71860',
      '71861'
    ],
    '003': [
      '71635',
      '71642',
      '71646',
      '71658',
      '71661',
      '71663',
      '71676'
    ]
  },
  '39': {
    '167': [
      '43787',
      '45711',
      '45714',
      '45715',
      '45721',
      '45723',
      '45724',
      '45729',
      '45742',
      '45744',
      '45745',
      '45746',
      '45750',
      '45767',
      '45768',
      '45773',
      '45784',
      '45786',
      '45787',
      '45788',
      '45789'
    ],
    '079': [
      '45601',
      '45613',
      '45621',
      '45634',
      '45640',
      '45656',
      '45672',
      '45685',
      '45692'
    ],
    '131': [
      '45133',
      '45601',
      '45612',
      '45613',
      '45624',
      '45642',
      '45646',
      '45648',
      '45660',
      '45661',
      '45671',
      '45690'
    ],
    '009': [
      '43728',
      '45701',
      '45710',
      '45711',
      '45716',
      '45719',
      '45723',
      '45732',
      '45735',
      '45740',
      '45742',
      '45761',
      '45764',
      '45766',
      '45776',
      '45778',
      '45780',
      '45782'
    ],
    '119': [
      '43701',
      '43720',
      '43727',
      '43732',
      '43734',
      '43735',
      '43738',
      '43740',
      '43746',
      '43760',
      '43762',
      '43767',
      '43771',
      '43777',
      '43802',
      '43812',
      '43821',
      '43822',
      '43830',
      '43842'
    ],
    '153': [
      '44056',
      '44067',
      '44087',
      '44141',
      '44202',
      '44203',
      '44216',
      '44221',
      '44223',
      '44224',
      '44236',
      '44240',
      '44250',
      '44256',
      '44260',
      '44262',
      '44264',
      '44278',
      '44281',
      '44286',
      '44301',
      '44302',
      '44303',
      '44304',
      '44305',
      '44306',
      '44307',
      '44308',
      '44310',
      '44311',
      '44312',
      '44313',
      '44314',
      '44319',
      '44320',
      '44321',
      '44333',
      '44614',
      '44685',
      '44720'
    ],
    '055': [
      '44021',
      '44022',
      '44023',
      '44024',
      '44026',
      '44046',
      '44057',
      '44060',
      '44062',
      '44064',
      '44065',
      '44072',
      '44080',
      '44086',
      '44099',
      '44202',
      '44231',
      '44234',
      '44491'
    ],
    '109': [
      '45304',
      '45308',
      '45312',
      '45317',
      '45318',
      '45322',
      '45326',
      '45337',
      '45339',
      '45344',
      '45356',
      '45359',
      '45361',
      '45371',
      '45373',
      '45383',
      '45424'
    ],
    '053': [
      '45614',
      '45620',
      '45623',
      '45631',
      '45656',
      '45658',
      '45674',
      '45678',
      '45685',
      '45686',
      '45688'
    ],
    '063': [
      '43316',
      '43516',
      '44802',
      '44804',
      '44817',
      '44830',
      '45810',
      '45814',
      '45816',
      '45817',
      '45840',
      '45841',
      '45843',
      '45856',
      '45858',
      '45867',
      '45868',
      '45872',
      '45877',
      '45881',
      '45889',
      '45890',
      '45897'
    ],
    '171': [
      '43501',
      '43502',
      '43505',
      '43506',
      '43517',
      '43518',
      '43521',
      '43531',
      '43543',
      '43554',
      '43557',
      '43570'
    ],
    '059': [
      '43722',
      '43723',
      '43725',
      '43732',
      '43733',
      '43736',
      '43749',
      '43750',
      '43755',
      '43762',
      '43768',
      '43772',
      '43773',
      '43778',
      '43780',
      '43832',
      '43837',
      '43973',
      '43983',
      '44699'
    ],
    '143': [
      '43406',
      '43407',
      '43410',
      '43416',
      '43420',
      '43430',
      '43431',
      '43435',
      '43442',
      '43449',
      '43457',
      '43464',
      '43469',
      '44811',
      '44824',
      '44836',
      '44841'
    ],
    '111': [
      '43716',
      '43747',
      '43754',
      '43773',
      '43786',
      '43788',
      '43793',
      '43914',
      '43915',
      '43931',
      '43942',
      '43946',
      '45734',
      '45745',
      '45767',
      '45789'
    ],
    '117': [
      '43003',
      '43011',
      '43019',
      '43050',
      '43314',
      '43315',
      '43317',
      '43320',
      '43321',
      '43334',
      '43338',
      '43356',
      '44813',
      '44833',
      '44903',
      '44904'
    ],
    '035': [
      '44017',
      '44022',
      '44040',
      '44070',
      '44101',
      '44102',
      '44103',
      '44104',
      '44105',
      '44106',
      '44107',
      '44108',
      '44109',
      '44110',
      '44111',
      '44112',
      '44113',
      '44114',
      '44115',
      '44116',
      '44117',
      '44118',
      '44119',
      '44120',
      '44121',
      '44122',
      '44123',
      '44124',
      '44125',
      '44126',
      '44127',
      '44128',
      '44129',
      '44130',
      '44131',
      '44132',
      '44133',
      '44134',
      '44135',
      '44136',
      '44137',
      '44138',
      '44139',
      '44140',
      '44141',
      '44142',
      '44143',
      '44144',
      '44145',
      '44146',
      '44147',
      '44149'
    ],
    '033': [
      '43302',
      '43314',
      '44818',
      '44820',
      '44825',
      '44827',
      '44833',
      '44849',
      '44854',
      '44856',
      '44865',
      '44875',
      '44881',
      '44882',
      '44887'
    ],
    '037': [
      '45303',
      '45304',
      '45308',
      '45309',
      '45310',
      '45318',
      '45328',
      '45331',
      '45332',
      '45337',
      '45346',
      '45347',
      '45348',
      '45350',
      '45351',
      '45352',
      '45358',
      '45362',
      '45380',
      '45382',
      '45388',
      '45390',
      '45846'
    ],
    '159': [
      '43015',
      '43016',
      '43017',
      '43029',
      '43036',
      '43040',
      '43045',
      '43060',
      '43061',
      '43064',
      '43067',
      '43077',
      '43084',
      '43302',
      '43319',
      '43340',
      '43342',
      '43344',
      '43358'
    ],
    '147': [
      '43316',
      '43410',
      '43457',
      '44802',
      '44807',
      '44809',
      '44811',
      '44815',
      '44818',
      '44828',
      '44830',
      '44836',
      '44841',
      '44844',
      '44853',
      '44861',
      '44867',
      '44882',
      '44883'
    ],
    '145': [
      '45613',
      '45616',
      '45629',
      '45630',
      '45636',
      '45638',
      '45648',
      '45652',
      '45653',
      '45657',
      '45660',
      '45661',
      '45662',
      '45663',
      '45671',
      '45682',
      '45684',
      '45694'
    ],
    '085': [
      '44024',
      '44041',
      '44045',
      '44057',
      '44060',
      '44077',
      '44081',
      '44086',
      '44092',
      '44094',
      '44095'
    ],
    '163': [
      '43135',
      '43138',
      '43152',
      '45622',
      '45634',
      '45647',
      '45651',
      '45654',
      '45672',
      '45686',
      '45695',
      '45698',
      '45710',
      '45766'
    ],
    '121': [
      '43711',
      '43717',
      '43724',
      '43732',
      '43772',
      '43773',
      '43778',
      '43779',
      '43780',
      '43788',
      '45715',
      '45727',
      '45744',
      '45745',
      '45746'
    ],
    '057': [
      '43153',
      '45068',
      '45169',
      '45301',
      '45305',
      '45307',
      '45314',
      '45316',
      '45324',
      '45335',
      '45368',
      '45370',
      '45384',
      '45385',
      '45387',
      '45424',
      '45430',
      '45431',
      '45432',
      '45433',
      '45434',
      '45440',
      '45458',
      '45459'
    ],
    '103': [
      '44136',
      '44203',
      '44212',
      '44214',
      '44215',
      '44217',
      '44230',
      '44233',
      '44235',
      '44251',
      '44253',
      '44254',
      '44256',
      '44270',
      '44273',
      '44274',
      '44275',
      '44280',
      '44281',
      '44287',
      '44321',
      '44333',
      '44880'
    ],
    '095': [
      '43412',
      '43434',
      '43445',
      '43504',
      '43522',
      '43528',
      '43532',
      '43537',
      '43542',
      '43547',
      '43558',
      '43560',
      '43566',
      '43571',
      '43604',
      '43605',
      '43606',
      '43607',
      '43608',
      '43609',
      '43610',
      '43611',
      '43612',
      '43613',
      '43614',
      '43615',
      '43616',
      '43617',
      '43620',
      '43623'
    ],
    '051': [
      '43502',
      '43515',
      '43521',
      '43532',
      '43533',
      '43540',
      '43553',
      '43557',
      '43558',
      '43567',
      '43570'
    ],
    '067': [
      '43901',
      '43907',
      '43910',
      '43950',
      '43973',
      '43974',
      '43976',
      '43977',
      '43983',
      '43986',
      '43988',
      '44621',
      '44683',
      '44693',
      '44695',
      '44699'
    ],
    '173': [
      '43402',
      '43403',
      '43406',
      '43413',
      '43430',
      '43437',
      '43443',
      '43447',
      '43450',
      '43451',
      '43457',
      '43460',
      '43462',
      '43463',
      '43465',
      '43466',
      '43467',
      '43511',
      '43516',
      '43522',
      '43525',
      '43529',
      '43541',
      '43551',
      '43565',
      '43569',
      '43605',
      '43619',
      '44817',
      '44830',
      '45872'
    ],
    '133': [
      '44201',
      '44202',
      '44224',
      '44231',
      '44234',
      '44236',
      '44240',
      '44241',
      '44243',
      '44255',
      '44260',
      '44265',
      '44266',
      '44272',
      '44278',
      '44285',
      '44288',
      '44312',
      '44411',
      '44412',
      '44429',
      '44444',
      '44449',
      '44491',
      '44632'
    ],
    '071': [
      '45118',
      '45123',
      '45132',
      '45133',
      '45135',
      '45142',
      '45154',
      '45155',
      '45159',
      '45171',
      '45172',
      '45612',
      '45660',
      '45679',
      '45697'
    ],
    '141': [
      '43101',
      '43115',
      '43135',
      '43160',
      '43164',
      '45123',
      '45601',
      '45612',
      '45617',
      '45628',
      '45644',
      '45647',
      '45673',
      '45681',
      '45690'
    ],
    '047': [
      '43106',
      '43128',
      '43142',
      '43143',
      '43145',
      '43153',
      '43160',
      '45123',
      '45135',
      '45169',
      '45335'
    ],
    '023': [
      '43010',
      '43044',
      '43078',
      '43140',
      '43153',
      '45314',
      '45316',
      '45319',
      '45323',
      '45324',
      '45341',
      '45344',
      '45349',
      '45368',
      '45369',
      '45372',
      '45387',
      '45502',
      '45503',
      '45504',
      '45505',
      '45506'
    ],
    '043': [
      '43438',
      '43464',
      '44089',
      '44811',
      '44814',
      '44816',
      '44824',
      '44826',
      '44839',
      '44846',
      '44847',
      '44857',
      '44870',
      '44889'
    ],
    '065': [
      '43310',
      '43326',
      '43331',
      '43332',
      '43340',
      '43345',
      '43347',
      '45810',
      '45812',
      '45835',
      '45836',
      '45843',
      '45850',
      '45859',
      '45896'
    ],
    '139': [
      '43019',
      '44805',
      '44813',
      '44822',
      '44827',
      '44833',
      '44837',
      '44843',
      '44864',
      '44865',
      '44875',
      '44878',
      '44901',
      '44902',
      '44903',
      '44904',
      '44905',
      '44906',
      '44907'
    ],
    '013': [
      '43713',
      '43716',
      '43718',
      '43719',
      '43747',
      '43759',
      '43901',
      '43902',
      '43905',
      '43906',
      '43912',
      '43917',
      '43927',
      '43928',
      '43933',
      '43934',
      '43935',
      '43940',
      '43942',
      '43943',
      '43947',
      '43950',
      '43951',
      '43967',
      '43971',
      '43972',
      '43977',
      '43983',
      '43985'
    ],
    '003': [
      '45801',
      '45804',
      '45805',
      '45806',
      '45807',
      '45808',
      '45809',
      '45810',
      '45817',
      '45820',
      '45830',
      '45833',
      '45844',
      '45850',
      '45854',
      '45877',
      '45887',
      '45896'
    ],
    '123': [
      '43408',
      '43412',
      '43416',
      '43430',
      '43432',
      '43433',
      '43439',
      '43440',
      '43445',
      '43446',
      '43447',
      '43449',
      '43452',
      '43456',
      '43458',
      '43468',
      '43469'
    ],
    '099': [
      '44401',
      '44405',
      '44406',
      '44408',
      '44412',
      '44425',
      '44429',
      '44436',
      '44437',
      '44440',
      '44442',
      '44443',
      '44444',
      '44449',
      '44451',
      '44452',
      '44454',
      '44460',
      '44471',
      '44481',
      '44490',
      '44502',
      '44503',
      '44504',
      '44505',
      '44506',
      '44507',
      '44509',
      '44510',
      '44511',
      '44512',
      '44514',
      '44515',
      '44601',
      '44609',
      '44672'
    ],
    '029': [
      '43920',
      '43930',
      '43945',
      '43962',
      '43968',
      '44408',
      '44413',
      '44423',
      '44427',
      '44431',
      '44432',
      '44441',
      '44443',
      '44445',
      '44454',
      '44455',
      '44460',
      '44490',
      '44493',
      '44601',
      '44609',
      '44625',
      '44634',
      '44657'
    ],
    '021': [
      '43009',
      '43044',
      '43045',
      '43060',
      '43070',
      '43072',
      '43078',
      '43084',
      '43318',
      '43343',
      '43357',
      '45312',
      '45317',
      '45344',
      '45365',
      '45389',
      '45502'
    ],
    '031': [
      '43006',
      '43749',
      '43804',
      '43805',
      '43811',
      '43812',
      '43821',
      '43822',
      '43824',
      '43832',
      '43836',
      '43840',
      '43843',
      '43844',
      '43845',
      '44637',
      '44654'
    ],
    '091': [
      '43060',
      '43310',
      '43311',
      '43318',
      '43319',
      '43324',
      '43331',
      '43333',
      '43336',
      '43343',
      '43345',
      '43347',
      '43348',
      '43357',
      '43358',
      '43360',
      '45334',
      '45895'
    ],
    '025': [
      '45102',
      '45103',
      '45106',
      '45107',
      '45112',
      '45118',
      '45120',
      '45121',
      '45122',
      '45130',
      '45140',
      '45145',
      '45147',
      '45150',
      '45153',
      '45156',
      '45157',
      '45158',
      '45160',
      '45162',
      '45176',
      '45244',
      '45245',
      '45255'
    ],
    '169': [
      '44214',
      '44217',
      '44230',
      '44270',
      '44273',
      '44276',
      '44287',
      '44606',
      '44611',
      '44618',
      '44624',
      '44627',
      '44638',
      '44645',
      '44659',
      '44662',
      '44666',
      '44667',
      '44676',
      '44677',
      '44691',
      '44840'
    ],
    '151': [
      '44201',
      '44216',
      '44260',
      '44601',
      '44608',
      '44612',
      '44613',
      '44614',
      '44618',
      '44626',
      '44632',
      '44634',
      '44640',
      '44641',
      '44643',
      '44646',
      '44647',
      '44652',
      '44657',
      '44662',
      '44666',
      '44669',
      '44670',
      '44685',
      '44688',
      '44689',
      '44702',
      '44703',
      '44704',
      '44705',
      '44706',
      '44707',
      '44708',
      '44709',
      '44710',
      '44714',
      '44718',
      '44720',
      '44721',
      '44730'
    ],
    '039': [
      '43506',
      '43512',
      '43517',
      '43519',
      '43526',
      '43527',
      '43536',
      '43545',
      '43548',
      '43549',
      '43556',
      '45813',
      '45821',
      '45831'
    ],
    '149': [
      '45302',
      '45306',
      '45317',
      '45333',
      '45334',
      '45336',
      '45340',
      '45353',
      '45356',
      '45360',
      '45363',
      '45365',
      '45845',
      '45865',
      '45869',
      '45871'
    ],
    '011': [
      '43331',
      '45306',
      '45334',
      '45806',
      '45819',
      '45845',
      '45850',
      '45862',
      '45865',
      '45869',
      '45870',
      '45871',
      '45884',
      '45885',
      '45887',
      '45888',
      '45895',
      '45896'
    ],
    '041': [
      '43003',
      '43011',
      '43015',
      '43016',
      '43017',
      '43021',
      '43031',
      '43032',
      '43035',
      '43040',
      '43061',
      '43065',
      '43066',
      '43074',
      '43082',
      '43240',
      '43334',
      '43342',
      '43344',
      '43356'
    ],
    '027': [
      '45068',
      '45107',
      '45113',
      '45135',
      '45142',
      '45146',
      '45148',
      '45159',
      '45164',
      '45166',
      '45169',
      '45177',
      '45335'
    ],
    '135': [
      '45003',
      '45042',
      '45064',
      '45070',
      '45304',
      '45309',
      '45311',
      '45320',
      '45321',
      '45327',
      '45330',
      '45338',
      '45347',
      '45378',
      '45381',
      '45382'
    ],
    '137': [
      '43548',
      '45817',
      '45827',
      '45830',
      '45831',
      '45833',
      '45844',
      '45849',
      '45853',
      '45856',
      '45864',
      '45875',
      '45876',
      '45877'
    ],
    '175': [
      '43316',
      '43323',
      '43330',
      '43332',
      '43337',
      '43351',
      '43359',
      '44844',
      '44849',
      '44882',
      '45843',
      '45867'
    ],
    '089': [
      '43001',
      '43008',
      '43011',
      '43013',
      '43023',
      '43025',
      '43030',
      '43031',
      '43033',
      '43046',
      '43055',
      '43056',
      '43062',
      '43068',
      '43071',
      '43074',
      '43076',
      '43080',
      '43147',
      '43721',
      '43739',
      '43740',
      '43746',
      '43760',
      '43822',
      '43830'
    ],
    '045': [
      '43046',
      '43062',
      '43068',
      '43076',
      '43102',
      '43105',
      '43107',
      '43110',
      '43112',
      '43113',
      '43130',
      '43136',
      '43147',
      '43148',
      '43150',
      '43154',
      '43155',
      '43157'
    ],
    '107': [
      '45310',
      '45822',
      '45826',
      '45828',
      '45846',
      '45860',
      '45862',
      '45866',
      '45869',
      '45882',
      '45883',
      '45885',
      '45887',
      '45898'
    ],
    '155': [
      '44062',
      '44076',
      '44402',
      '44403',
      '44404',
      '44410',
      '44417',
      '44418',
      '44420',
      '44425',
      '44428',
      '44430',
      '44437',
      '44438',
      '44439',
      '44440',
      '44444',
      '44446',
      '44450',
      '44470',
      '44473',
      '44481',
      '44483',
      '44484',
      '44485',
      '44491',
      '44504',
      '44505'
    ],
    '127': [
      '43076',
      '43107',
      '43138',
      '43148',
      '43150',
      '43730',
      '43731',
      '43739',
      '43748',
      '43760',
      '43761',
      '43764',
      '43766',
      '43777',
      '43782',
      '43783',
      '45732'
    ],
    '161': [
      '45832',
      '45833',
      '45838',
      '45844',
      '45849',
      '45863',
      '45874',
      '45882',
      '45886',
      '45887',
      '45891',
      '45894',
      '45898',
      '45899'
    ],
    '081': [
      '43901',
      '43903',
      '43907',
      '43908',
      '43910',
      '43913',
      '43917',
      '43925',
      '43926',
      '43930',
      '43932',
      '43938',
      '43939',
      '43943',
      '43944',
      '43945',
      '43948',
      '43952',
      '43953',
      '43961',
      '43963',
      '43964',
      '43970',
      '43971'
    ],
    '097': [
      '43026',
      '43029',
      '43044',
      '43064',
      '43119',
      '43140',
      '43143',
      '43146',
      '43151',
      '43153',
      '43162',
      '45369'
    ],
    '125': [
      '43512',
      '43526',
      '45813',
      '45821',
      '45827',
      '45832',
      '45849',
      '45851',
      '45855',
      '45861',
      '45873',
      '45879',
      '45880',
      '45886'
    ],
    '001': [
      '45144',
      '45616',
      '45618',
      '45650',
      '45657',
      '45660',
      '45679',
      '45684',
      '45693',
      '45697'
    ],
    '129': [
      '43102',
      '43103',
      '43110',
      '43113',
      '43115',
      '43116',
      '43117',
      '43125',
      '43135',
      '43137',
      '43143',
      '43145',
      '43146',
      '43154',
      '43156',
      '43164',
      '45644'
    ],
    '017': [
      '45003',
      '45011',
      '45013',
      '45014',
      '45015',
      '45042',
      '45044',
      '45050',
      '45053',
      '45056',
      '45062',
      '45064',
      '45067',
      '45069',
      '45241',
      '45246',
      '45327'
    ],
    '087': [
      '45619',
      '45623',
      '45638',
      '45645',
      '45656',
      '45659',
      '45669',
      '45678',
      '45680',
      '45682',
      '45688',
      '45696'
    ],
    '115': [
      '43724',
      '43728',
      '43731',
      '43756',
      '43758',
      '43787',
      '45711',
      '45715',
      '45732',
      '45786'
    ],
    '005': [
      '44235',
      '44287',
      '44638',
      '44691',
      '44805',
      '44837',
      '44838',
      '44840',
      '44842',
      '44843',
      '44851',
      '44859',
      '44864',
      '44866',
      '44874',
      '44878',
      '44880',
      '44903'
    ],
    '077': [
      '44807',
      '44811',
      '44826',
      '44837',
      '44846',
      '44847',
      '44850',
      '44851',
      '44855',
      '44857',
      '44865',
      '44878',
      '44889',
      '44890'
    ],
    '157': [
      '43749',
      '43804',
      '43824',
      '43832',
      '43837',
      '43840',
      '44608',
      '44612',
      '44621',
      '44622',
      '44624',
      '44626',
      '44629',
      '44643',
      '44653',
      '44656',
      '44663',
      '44671',
      '44675',
      '44678',
      '44680',
      '44681',
      '44682',
      '44683',
      '44697',
      '44699'
    ],
    '069': [
      '43502',
      '43511',
      '43512',
      '43516',
      '43522',
      '43523',
      '43524',
      '43527',
      '43532',
      '43534',
      '43535',
      '43545',
      '43548',
      '43555',
      '43557',
      '43567',
      '45856'
    ],
    '093': [
      '44001',
      '44011',
      '44012',
      '44028',
      '44035',
      '44039',
      '44044',
      '44049',
      '44050',
      '44052',
      '44053',
      '44054',
      '44055',
      '44074',
      '44089',
      '44090',
      '44253',
      '44256',
      '44275',
      '44280',
      '44851',
      '44859',
      '44880',
      '44889'
    ],
    '105': [
      '45620',
      '45686',
      '45710',
      '45723',
      '45741',
      '45743',
      '45760',
      '45769',
      '45770',
      '45771',
      '45772',
      '45775',
      '45776',
      '45779'
    ],
    '113': [
      '45005',
      '45066',
      '45309',
      '45315',
      '45322',
      '45325',
      '45327',
      '45342',
      '45344',
      '45345',
      '45354',
      '45371',
      '45377',
      '45381',
      '45402',
      '45403',
      '45404',
      '45405',
      '45406',
      '45409',
      '45410',
      '45414',
      '45415',
      '45416',
      '45417',
      '45418',
      '45419',
      '45420',
      '45424',
      '45426',
      '45428',
      '45429',
      '45431',
      '45432',
      '45433',
      '45439',
      '45440',
      '45449',
      '45458',
      '45459'
    ],
    '075': [
      '43006',
      '43804',
      '44610',
      '44611',
      '44624',
      '44627',
      '44628',
      '44633',
      '44637',
      '44638',
      '44654',
      '44661',
      '44676',
      '44681',
      '44687',
      '44689',
      '44690',
      '44842'
    ],
    '083': [
      '43005',
      '43006',
      '43011',
      '43014',
      '43019',
      '43022',
      '43028',
      '43037',
      '43050',
      '43080',
      '43822',
      '43843',
      '44628',
      '44813',
      '44822'
    ],
    '165': [
      '45005',
      '45032',
      '45034',
      '45036',
      '45039',
      '45040',
      '45042',
      '45044',
      '45050',
      '45054',
      '45065',
      '45066',
      '45068',
      '45107',
      '45113',
      '45122',
      '45140',
      '45152',
      '45162',
      '45241',
      '45249',
      '45342',
      '45458'
    ],
    '101': [
      '43302',
      '43314',
      '43315',
      '43322',
      '43332',
      '43337',
      '43341',
      '43342',
      '43344',
      '43356',
      '44833',
      '44849'
    ],
    '061': [
      '45001',
      '45002',
      '45013',
      '45030',
      '45033',
      '45041',
      '45051',
      '45052',
      '45111',
      '45140',
      '45147',
      '45150',
      '45174',
      '45202',
      '45203',
      '45204',
      '45205',
      '45206',
      '45207',
      '45208',
      '45209',
      '45211',
      '45212',
      '45213',
      '45214',
      '45215',
      '45216',
      '45217',
      '45218',
      '45219',
      '45220',
      '45223',
      '45224',
      '45225',
      '45226',
      '45227',
      '45229',
      '45230',
      '45231',
      '45232',
      '45233',
      '45236',
      '45237',
      '45238',
      '45239',
      '45240',
      '45241',
      '45242',
      '45243',
      '45244',
      '45246',
      '45247',
      '45248',
      '45249',
      '45251',
      '45252',
      '45255'
    ],
    '049': [
      '43002',
      '43004',
      '43016',
      '43017',
      '43026',
      '43054',
      '43064',
      '43065',
      '43068',
      '43081',
      '43085',
      '43109',
      '43110',
      '43119',
      '43123',
      '43125',
      '43126',
      '43137',
      '43140',
      '43146',
      '43147',
      '43201',
      '43202',
      '43203',
      '43204',
      '43205',
      '43206',
      '43207',
      '43209',
      '43210',
      '43211',
      '43212',
      '43213',
      '43214',
      '43215',
      '43217',
      '43219',
      '43220',
      '43221',
      '43222',
      '43223',
      '43224',
      '43227',
      '43228',
      '43229',
      '43230',
      '43231',
      '43232',
      '43235'
    ],
    '019': [
      '43903',
      '43908',
      '43945',
      '43986',
      '43988',
      '44427',
      '44607',
      '44615',
      '44620',
      '44621',
      '44625',
      '44643',
      '44644',
      '44651',
      '44656',
      '44657',
      '44675',
      '44688',
      '44695',
      '44730'
    ],
    '007': [
      '44003',
      '44004',
      '44010',
      '44030',
      '44032',
      '44041',
      '44047',
      '44048',
      '44057',
      '44062',
      '44064',
      '44076',
      '44082',
      '44084',
      '44085',
      '44086',
      '44093',
      '44099',
      '44428'
    ],
    '073': [
      '43102',
      '43107',
      '43111',
      '43127',
      '43135',
      '43138',
      '43144',
      '43149',
      '43152',
      '43155',
      '43158',
      '43766',
      '45622',
      '45654',
      '45764'
    ],
    '015': [
      '45101',
      '45106',
      '45107',
      '45115',
      '45118',
      '45120',
      '45121',
      '45130',
      '45131',
      '45133',
      '45142',
      '45144',
      '45148',
      '45154',
      '45167',
      '45168',
      '45171',
      '45176',
      '45697'
    ]
  },
  '33': {
    '003': [
      '03227',
      '03254',
      '03259',
      '03812',
      '03813',
      '03814',
      '03816',
      '03817',
      '03818',
      '03830',
      '03832',
      '03836',
      '03838',
      '03845',
      '03846',
      '03847',
      '03849',
      '03850',
      '03853',
      '03860',
      '03864',
      '03872',
      '03875',
      '03882',
      '03883',
      '03886',
      '03887',
      '03890',
      '03894'
    ],
    '009': [
      '03215',
      '03217',
      '03222',
      '03223',
      '03238',
      '03240',
      '03241',
      '03245',
      '03251',
      '03262',
      '03264',
      '03266',
      '03279',
      '03282',
      '03285',
      '03293',
      '03561',
      '03574',
      '03580',
      '03585',
      '03586',
      '03740',
      '03741',
      '03748',
      '03750',
      '03755',
      '03765',
      '03766',
      '03768',
      '03771',
      '03774',
      '03777',
      '03779',
      '03780',
      '03784',
      '03785'
    ],
    '001': [
      '03218',
      '03220',
      '03225',
      '03226',
      '03237',
      '03246',
      '03249',
      '03253',
      '03254',
      '03256',
      '03269',
      '03276',
      '03809',
      '03810',
      '03837'
    ],
    '017': [
      '03225',
      '03820',
      '03823',
      '03824',
      '03825',
      '03835',
      '03839',
      '03851',
      '03852',
      '03855',
      '03861',
      '03867',
      '03868',
      '03869',
      '03878',
      '03884',
      '03887'
    ],
    '019': [
      '03280',
      '03284',
      '03601',
      '03602',
      '03603',
      '03605',
      '03607',
      '03743',
      '03745',
      '03746',
      '03748',
      '03751',
      '03752',
      '03753',
      '03754',
      '03770',
      '03773',
      '03781',
      '03782'
    ],
    '011': [
      '03031',
      '03033',
      '03043',
      '03045',
      '03047',
      '03048',
      '03049',
      '03051',
      '03052',
      '03054',
      '03055',
      '03057',
      '03060',
      '03062',
      '03063',
      '03064',
      '03070',
      '03071',
      '03076',
      '03082',
      '03084',
      '03086',
      '03101',
      '03102',
      '03103',
      '03104',
      '03109',
      '03110',
      '03244',
      '03281',
      '03440',
      '03442',
      '03449',
      '03458'
    ],
    '007': [
      '03570',
      '03575',
      '03576',
      '03579',
      '03581',
      '03582',
      '03583',
      '03584',
      '03588',
      '03590',
      '03592',
      '03593',
      '03595',
      '03597',
      '03598'
    ],
    '013': [
      '03046',
      '03104',
      '03106',
      '03216',
      '03220',
      '03221',
      '03224',
      '03229',
      '03230',
      '03231',
      '03233',
      '03234',
      '03235',
      '03242',
      '03243',
      '03255',
      '03257',
      '03258',
      '03260',
      '03263',
      '03268',
      '03273',
      '03275',
      '03276',
      '03278',
      '03287',
      '03301',
      '03303',
      '03304',
      '03307'
    ],
    '005': [
      '03431',
      '03441',
      '03443',
      '03444',
      '03445',
      '03446',
      '03447',
      '03448',
      '03450',
      '03451',
      '03452',
      '03455',
      '03456',
      '03457',
      '03458',
      '03461',
      '03462',
      '03464',
      '03465',
      '03466',
      '03467',
      '03470',
      '03602',
      '03604',
      '03608',
      '03609'
    ],
    '015': [
      '03032',
      '03034',
      '03036',
      '03037',
      '03038',
      '03042',
      '03044',
      '03053',
      '03077',
      '03079',
      '03087',
      '03103',
      '03261',
      '03290',
      '03291',
      '03801',
      '03811',
      '03819',
      '03826',
      '03827',
      '03833',
      '03840',
      '03841',
      '03842',
      '03844',
      '03848',
      '03854',
      '03856',
      '03857',
      '03858',
      '03862',
      '03865',
      '03870',
      '03871',
      '03873',
      '03874',
      '03885'
    ]
  },
  '30': {
    '079': [
      '59326',
      '59330',
      '59349'
    ],
    '099': [
      '59410',
      '59419',
      '59422',
      '59425',
      '59433',
      '59436',
      '59443',
      '59467',
      '59468',
      '59487'
    ],
    '009': [
      '59001',
      '59007',
      '59008',
      '59013',
      '59014',
      '59019',
      '59026',
      '59029',
      '59041',
      '59044',
      '59068',
      '59070',
      '59071'
    ],
    '055': [
      '59201',
      '59214',
      '59215',
      '59223',
      '59274'
    ],
    '029': [
      '59845',
      '59901',
      '59911',
      '59912',
      '59913',
      '59916',
      '59919',
      '59920',
      '59922',
      '59925',
      '59926',
      '59927',
      '59928',
      '59932',
      '59936',
      '59937'
    ],
    '109': [
      '59353'
    ],
    '021': [
      '59215',
      '59259',
      '59262',
      '59315',
      '59326',
      '59330',
      '59339'
    ],
    '053': [
      '59917',
      '59918',
      '59923',
      '59930',
      '59933',
      '59934',
      '59935'
    ],
    '031': [
      '59086',
      '59714',
      '59715',
      '59716',
      '59718',
      '59730',
      '59741',
      '59752',
      '59758',
      '59760'
    ],
    '091': [
      '59211',
      '59219',
      '59242',
      '59247',
      '59252',
      '59254',
      '59256',
      '59257',
      '59258',
      '59275'
    ],
    '063': [
      '59801',
      '59802',
      '59803',
      '59804',
      '59808',
      '59820',
      '59821',
      '59823',
      '59825',
      '59826',
      '59833',
      '59834',
      '59846',
      '59847',
      '59851',
      '59868'
    ],
    '025': [
      '59313',
      '59336',
      '59344',
      '59354'
    ],
    '059': [
      '59053',
      '59086',
      '59642',
      '59645'
    ],
    '111': [
      '59002',
      '59006',
      '59015',
      '59024',
      '59037',
      '59041',
      '59044',
      '59057',
      '59064',
      '59079',
      '59088',
      '59101',
      '59102',
      '59105',
      '59106'
    ],
    '039': [
      '59711',
      '59825',
      '59832',
      '59837',
      '59858'
    ],
    '011': [
      '57724',
      '59311',
      '59324',
      '59332'
    ],
    '041': [
      '59501',
      '59521',
      '59525',
      '59528',
      '59530',
      '59531',
      '59532',
      '59540'
    ],
    '033': [
      '59058',
      '59077',
      '59301',
      '59312',
      '59318',
      '59322',
      '59337'
    ],
    '037': [
      '59046',
      '59074',
      '59078'
    ],
    '085': [
      '59201',
      '59212',
      '59213',
      '59218',
      '59226',
      '59255',
      '59258'
    ],
    '017': [
      '59301',
      '59336',
      '59338',
      '59347',
      '59349',
      '59351'
    ],
    '089': [
      '59821',
      '59831',
      '59844',
      '59845',
      '59848',
      '59853',
      '59856',
      '59859',
      '59873',
      '59874'
    ],
    '045': [
      '59418',
      '59424',
      '59430',
      '59447',
      '59452',
      '59453',
      '59462',
      '59463',
      '59464',
      '59469',
      '59479'
    ],
    '107': [
      '59036',
      '59053',
      '59078',
      '59085',
      '59453'
    ],
    '065': [
      '59015',
      '59046',
      '59054',
      '59059',
      '59072'
    ],
    '097': [
      '59011',
      '59033',
      '59052',
      '59055',
      '59069'
    ],
    '057': [
      '59710',
      '59716',
      '59720',
      '59721',
      '59725',
      '59729',
      '59732',
      '59735',
      '59740',
      '59745',
      '59747',
      '59749',
      '59751',
      '59754',
      '59755',
      '59759'
    ],
    '001': [
      '59724',
      '59725',
      '59727',
      '59732',
      '59736',
      '59739',
      '59743',
      '59746',
      '59761',
      '59762'
    ],
    '103': [
      '59010',
      '59038',
      '59076'
    ],
    '087': [
      '59003',
      '59012',
      '59039',
      '59043',
      '59054',
      '59301',
      '59323',
      '59327',
      '59333',
      '59347'
    ],
    '095': [
      '59001',
      '59015',
      '59019',
      '59028',
      '59057',
      '59061',
      '59063',
      '59067',
      '59069'
    ],
    '035': [
      '59411',
      '59417',
      '59427',
      '59434'
    ],
    '005': [
      '59523',
      '59524',
      '59526',
      '59527',
      '59529',
      '59535',
      '59542',
      '59547'
    ],
    '051': [
      '59444',
      '59456',
      '59461',
      '59522',
      '59531',
      '59545'
    ],
    '077': [
      '59713',
      '59722',
      '59728',
      '59731',
      '59733',
      '59843',
      '59854',
      '59868'
    ],
    '027': [
      '59032',
      '59418',
      '59424',
      '59430',
      '59441',
      '59451',
      '59453',
      '59457',
      '59464',
      '59471',
      '59489'
    ],
    '069': [
      '59032',
      '59072',
      '59087'
    ],
    '093': [
      '59701',
      '59703',
      '59711',
      '59727',
      '59743',
      '59748',
      '59750',
      '59759'
    ],
    '067': [
      '59018',
      '59020',
      '59027',
      '59030',
      '59047',
      '59052',
      '59065',
      '59081',
      '59082',
      '59086',
      '59715'
    ],
    '105': [
      '59201',
      '59223',
      '59225',
      '59230',
      '59231',
      '59240',
      '59241',
      '59244',
      '59248',
      '59250',
      '59260',
      '59261'
    ],
    '071': [
      '59261',
      '59524',
      '59537',
      '59538',
      '59544',
      '59546'
    ],
    '075': [
      '59003',
      '59062',
      '59301',
      '59314',
      '59317',
      '59343',
      '59351'
    ],
    '047': [
      '59821',
      '59824',
      '59845',
      '59855',
      '59860',
      '59863',
      '59864',
      '59865',
      '59910',
      '59911',
      '59914',
      '59915',
      '59922',
      '59929',
      '59931'
    ],
    '081': [
      '59827',
      '59828',
      '59829',
      '59833',
      '59840',
      '59841',
      '59870',
      '59871',
      '59875'
    ],
    '083': [
      '59213',
      '59217',
      '59218',
      '59221',
      '59243',
      '59255',
      '59259',
      '59262',
      '59270'
    ],
    '023': [
      '59711',
      '59722',
      '59756',
      '59762'
    ],
    '043': [
      '59601',
      '59631',
      '59632',
      '59634',
      '59635',
      '59638',
      '59701',
      '59721',
      '59752',
      '59759'
    ],
    '101': [
      '59444',
      '59454',
      '59456',
      '59466',
      '59474',
      '59482',
      '59484'
    ],
    '061': [
      '59820',
      '59830',
      '59842',
      '59866',
      '59867',
      '59872'
    ],
    '049': [
      '59410',
      '59421',
      '59601',
      '59602',
      '59633',
      '59635',
      '59636',
      '59639',
      '59640',
      '59648'
    ],
    '019': [
      '59222',
      '59253',
      '59260',
      '59263',
      '59276'
    ],
    '003': [
      '59010',
      '59016',
      '59022',
      '59024',
      '59025',
      '59031',
      '59034',
      '59035',
      '59038',
      '59043',
      '59050',
      '59064',
      '59066',
      '59075',
      '59089',
      '59101',
      '82801'
    ],
    '007': [
      '59635',
      '59643',
      '59644',
      '59647',
      '59752'
    ],
    '013': [
      '59401',
      '59404',
      '59405',
      '59412',
      '59414',
      '59421',
      '59440',
      '59443',
      '59463',
      '59465',
      '59468',
      '59472',
      '59477',
      '59480',
      '59483',
      '59485',
      '59487'
    ],
    '073': [
      '59416',
      '59425',
      '59432',
      '59448',
      '59456',
      '59486'
    ],
    '015': [
      '59404',
      '59416',
      '59420',
      '59440',
      '59442',
      '59446',
      '59450',
      '59460',
      '59520',
      '59521'
    ]
  },
  '04': {
    '027': [
      '85333',
      '85336',
      '85347',
      '85349',
      '85350',
      '85352',
      '85356',
      '85364',
      '85365',
      '85367'
    ],
    '001': [
      '84531',
      '85901',
      '85920',
      '85924',
      '85925',
      '85927',
      '85930',
      '85932',
      '85936',
      '85938',
      '85940',
      '85941',
      '86028',
      '86033',
      '86034',
      '86502',
      '86503',
      '86504',
      '86505',
      '86506',
      '86507',
      '86508',
      '86510',
      '86511',
      '86512',
      '86514',
      '86515',
      '86520',
      '86535',
      '86538',
      '86540',
      '86544',
      '86545',
      '86547',
      '86556',
      '87328'
    ],
    '017': [
      '84536',
      '85901',
      '85911',
      '85912',
      '85923',
      '85926',
      '85928',
      '85929',
      '85930',
      '85933',
      '85934',
      '85935',
      '85937',
      '85939',
      '85941',
      '85942',
      '86025',
      '86029',
      '86030',
      '86031',
      '86032',
      '86033',
      '86034',
      '86039',
      '86042',
      '86043',
      '86044',
      '86047',
      '86054',
      '86505',
      '86510',
      '86520'
    ],
    '023': [
      '85611',
      '85621',
      '85624',
      '85637',
      '85640',
      '85645',
      '85646',
      '85648'
    ],
    '005': [
      '85931',
      '86001',
      '86003',
      '86004',
      '86011',
      '86015',
      '86016',
      '86017',
      '86018',
      '86020',
      '86022',
      '86023',
      '86024',
      '86030',
      '86035',
      '86036',
      '86038',
      '86040',
      '86044',
      '86045',
      '86046',
      '86047',
      '86052',
      '86053',
      '86054',
      '86320',
      '86336',
      '86337',
      '86435'
    ],
    '021': [
      '85118',
      '85119',
      '85120',
      '85121',
      '85122',
      '85123',
      '85128',
      '85131',
      '85132',
      '85137',
      '85138',
      '85139',
      '85140',
      '85141',
      '85142',
      '85143',
      '85145',
      '85147',
      '85172',
      '85173',
      '85192',
      '85193',
      '85194',
      '85248',
      '85339',
      '85539',
      '85618',
      '85623',
      '85631',
      '85634',
      '85653',
      '85658',
      '85739'
    ],
    '012': [
      '85325',
      '85328',
      '85334',
      '85344',
      '85346',
      '85348',
      '85357',
      '85371'
    ],
    '003': [
      '85602',
      '85603',
      '85605',
      '85606',
      '85607',
      '85608',
      '85609',
      '85610',
      '85611',
      '85613',
      '85615',
      '85616',
      '85617',
      '85620',
      '85625',
      '85626',
      '85627',
      '85630',
      '85632',
      '85635',
      '85638',
      '85643',
      '85650'
    ],
    '009': [
      '85530',
      '85531',
      '85533',
      '85535',
      '85536',
      '85542',
      '85543',
      '85546',
      '85551',
      '85552',
      '85643'
    ],
    '025': [
      '85320',
      '85324',
      '85332',
      '85342',
      '85362',
      '85390',
      '86301',
      '86303',
      '86305',
      '86313',
      '86314',
      '86315',
      '86320',
      '86321',
      '86322',
      '86323',
      '86324',
      '86325',
      '86326',
      '86327',
      '86329',
      '86331',
      '86332',
      '86333',
      '86334',
      '86335',
      '86336',
      '86337',
      '86338',
      '86343',
      '86351',
      '86434'
    ],
    '019': [
      '85321',
      '85341',
      '85601',
      '85602',
      '85611',
      '85614',
      '85619',
      '85622',
      '85629',
      '85633',
      '85634',
      '85637',
      '85641',
      '85645',
      '85653',
      '85654',
      '85658',
      '85701',
      '85704',
      '85705',
      '85706',
      '85707',
      '85708',
      '85710',
      '85711',
      '85712',
      '85713',
      '85714',
      '85715',
      '85716',
      '85718',
      '85719',
      '85723',
      '85724',
      '85726',
      '85730',
      '85735',
      '85736',
      '85737',
      '85739',
      '85741',
      '85742',
      '85743',
      '85745',
      '85746',
      '85747',
      '85748',
      '85749',
      '85750',
      '85755',
      '85756',
      '85757'
    ],
    '011': [
      '85533',
      '85534',
      '85540',
      '85922'
    ],
    '007': [
      '85135',
      '85192',
      '85501',
      '85539',
      '85541',
      '85542',
      '85544',
      '85545',
      '85550',
      '85553',
      '85554',
      '85941'
    ],
    '013': [
      '85003',
      '85004',
      '85006',
      '85007',
      '85008',
      '85009',
      '85012',
      '85013',
      '85014',
      '85015',
      '85016',
      '85017',
      '85018',
      '85019',
      '85020',
      '85021',
      '85022',
      '85023',
      '85024',
      '85027',
      '85028',
      '85029',
      '85031',
      '85032',
      '85033',
      '85034',
      '85035',
      '85037',
      '85040',
      '85041',
      '85042',
      '85043',
      '85044',
      '85045',
      '85048',
      '85050',
      '85051',
      '85053',
      '85054',
      '85083',
      '85085',
      '85086',
      '85087',
      '85120',
      '85139',
      '85142',
      '85201',
      '85202',
      '85203',
      '85204',
      '85205',
      '85206',
      '85207',
      '85208',
      '85209',
      '85210',
      '85212',
      '85213',
      '85215',
      '85224',
      '85225',
      '85226',
      '85233',
      '85234',
      '85248',
      '85249',
      '85250',
      '85251',
      '85253',
      '85254',
      '85255',
      '85256',
      '85257',
      '85258',
      '85259',
      '85260',
      '85262',
      '85263',
      '85264',
      '85266',
      '85268',
      '85281',
      '85282',
      '85283',
      '85284',
      '85286',
      '85295',
      '85296',
      '85297',
      '85298',
      '85301',
      '85302',
      '85303',
      '85304',
      '85305',
      '85306',
      '85307',
      '85308',
      '85309',
      '85310',
      '85320',
      '85321',
      '85322',
      '85323',
      '85326',
      '85331',
      '85333',
      '85335',
      '85337',
      '85338',
      '85339',
      '85340',
      '85342',
      '85343',
      '85345',
      '85351',
      '85353',
      '85354',
      '85355',
      '85361',
      '85363',
      '85373',
      '85374',
      '85375',
      '85377',
      '85379',
      '85381',
      '85382',
      '85383',
      '85387',
      '85388',
      '85390',
      '85392',
      '85395',
      '85396',
      '85545'
    ],
    '015': [
      '85360',
      '86021',
      '86022',
      '86401',
      '86403',
      '86404',
      '86406',
      '86409',
      '86411',
      '86413',
      '86426',
      '86429',
      '86431',
      '86432',
      '86433',
      '86434',
      '86436',
      '86437',
      '86438',
      '86440',
      '86441',
      '86442',
      '86443',
      '86444',
      '86445'
    ]
  },
  '35': {
    '028': [
      '87544'
    ],
    '041': [
      '88113',
      '88115',
      '88116',
      '88118',
      '88124',
      '88125',
      '88126',
      '88130',
      '88132',
      '88135'
    ],
    '057': [
      '87015',
      '87016',
      '87032',
      '87035',
      '87036',
      '87061',
      '87063',
      '87070',
      '88301',
      '88318',
      '88321'
    ],
    '001': [
      '87008',
      '87015',
      '87022',
      '87026',
      '87047',
      '87048',
      '87059',
      '87102',
      '87104',
      '87105',
      '87106',
      '87107',
      '87108',
      '87109',
      '87110',
      '87111',
      '87112',
      '87113',
      '87114',
      '87116',
      '87117',
      '87120',
      '87121',
      '87122',
      '87123'
    ],
    '017': [
      '88022',
      '88023',
      '88025',
      '88026',
      '88028',
      '88034',
      '88038',
      '88040',
      '88041',
      '88043',
      '88045',
      '88049',
      '88051',
      '88053',
      '88055',
      '88061',
      '88065'
    ],
    '055': [
      '87512',
      '87513',
      '87514',
      '87517',
      '87519',
      '87521',
      '87524',
      '87525',
      '87529',
      '87531',
      '87543',
      '87549',
      '87553',
      '87556',
      '87557',
      '87558',
      '87564',
      '87571',
      '87577',
      '87579',
      '87580'
    ],
    '029': [
      '88029',
      '88030'
    ],
    '047': [
      '87056',
      '87535',
      '87538',
      '87552',
      '87560',
      '87562',
      '87565',
      '87569',
      '87573',
      '87583',
      '87701',
      '87711',
      '87731',
      '87742',
      '87745',
      '87746',
      '88401',
      '88416',
      '88421',
      '88431'
    ],
    '021': [
      '87730',
      '87733',
      '87743',
      '87746',
      '88410',
      '88415',
      '88426'
    ],
    '053': [
      '87006',
      '87011',
      '87028',
      '87036',
      '87062',
      '87801',
      '87823',
      '87825',
      '87828',
      '87831',
      '87832',
      '87943'
    ],
    '051': [
      '87901',
      '87930',
      '87931',
      '87933',
      '87935',
      '87936',
      '87939',
      '87942',
      '87943',
      '88042'
    ],
    '033': [
      '87712',
      '87713',
      '87715',
      '87722',
      '87723',
      '87732',
      '87734',
      '87735',
      '87736',
      '87742',
      '87745',
      '87750',
      '87752',
      '87753'
    ],
    '009': [
      '88101',
      '88103',
      '88112',
      '88120',
      '88124',
      '88135'
    ],
    '025': [
      '88114',
      '88231',
      '88240',
      '88242',
      '88252',
      '88260',
      '88262',
      '88264',
      '88265',
      '88267'
    ],
    '059': [
      '88410',
      '88414',
      '88415',
      '88418',
      '88419',
      '88422',
      '88424',
      '88436'
    ],
    '005': [
      '88116',
      '88201',
      '88203',
      '88230',
      '88232',
      '88250',
      '88253',
      '88339',
      '88344'
    ],
    '039': [
      '87012',
      '87013',
      '87017',
      '87029',
      '87046',
      '87064',
      '87412',
      '87419',
      '87510',
      '87511',
      '87515',
      '87516',
      '87518',
      '87520',
      '87521',
      '87522',
      '87523',
      '87527',
      '87528',
      '87530',
      '87531',
      '87532',
      '87533',
      '87537',
      '87539',
      '87548',
      '87549',
      '87551',
      '87554',
      '87566',
      '87575',
      '87577',
      '87578',
      '87581',
      '87582'
    ],
    '011': [
      '88119',
      '88134',
      '88136'
    ],
    '006': [
      '87005',
      '87007',
      '87014',
      '87020',
      '87021',
      '87026',
      '87034',
      '87038',
      '87040',
      '87045',
      '87049',
      '87051',
      '87315',
      '87321',
      '87323',
      '87327',
      '87357',
      '87829'
    ],
    '035': [
      '79837',
      '88081',
      '88310',
      '88311',
      '88314',
      '88317',
      '88325',
      '88330',
      '88337',
      '88339',
      '88340',
      '88342',
      '88344',
      '88347',
      '88349',
      '88350',
      '88352',
      '88354'
    ],
    '027': [
      '87011',
      '88301',
      '88312',
      '88316',
      '88318',
      '88323',
      '88324',
      '88336',
      '88338',
      '88341',
      '88343',
      '88345',
      '88346',
      '88348',
      '88351',
      '88355'
    ],
    '037': [
      '88120',
      '88121',
      '88124',
      '88401',
      '88411',
      '88426',
      '88427',
      '88430',
      '88434'
    ],
    '031': [
      '86504',
      '86515',
      '87013',
      '87045',
      '87301',
      '87305',
      '87310',
      '87311',
      '87312',
      '87313',
      '87316',
      '87317',
      '87319',
      '87320',
      '87321',
      '87322',
      '87323',
      '87325',
      '87326',
      '87327',
      '87328',
      '87347',
      '87375'
    ],
    '023': [
      '88009',
      '88020',
      '88040',
      '88045',
      '88056'
    ],
    '043': [
      '87001',
      '87004',
      '87013',
      '87014',
      '87018',
      '87024',
      '87025',
      '87027',
      '87041',
      '87043',
      '87044',
      '87046',
      '87047',
      '87048',
      '87052',
      '87053',
      '87072',
      '87083',
      '87124',
      '87144'
    ],
    '045': [
      '81137',
      '87013',
      '87037',
      '87313',
      '87325',
      '87328',
      '87364',
      '87401',
      '87402',
      '87410',
      '87412',
      '87413',
      '87415',
      '87416',
      '87417',
      '87418',
      '87419',
      '87420',
      '87421',
      '87455',
      '87461',
      '87499'
    ],
    '061': [
      '87002',
      '87006',
      '87023',
      '87026',
      '87031',
      '87042',
      '87068'
    ],
    '049': [
      '87010',
      '87015',
      '87035',
      '87047',
      '87056',
      '87501',
      '87505',
      '87506',
      '87507',
      '87508',
      '87522',
      '87532',
      '87535',
      '87540',
      '87567',
      '87573',
      '87574'
    ],
    '019': [
      '87711',
      '87724',
      '88321',
      '88353',
      '88417',
      '88431',
      '88435'
    ],
    '015': [
      '88210',
      '88220',
      '88250',
      '88253',
      '88254',
      '88255',
      '88256',
      '88263',
      '88268'
    ],
    '007': [
      '87710',
      '87714',
      '87718',
      '87728',
      '87729',
      '87734',
      '87740',
      '87747',
      '87749',
      '88419',
      '88422'
    ],
    '013': [
      '87936',
      '87937',
      '87940',
      '87941',
      '88001',
      '88002',
      '88003',
      '88004',
      '88005',
      '88007',
      '88008',
      '88011',
      '88012',
      '88021',
      '88024',
      '88027',
      '88032',
      '88033',
      '88044',
      '88046',
      '88047',
      '88048',
      '88052',
      '88063',
      '88072',
      '88081'
    ],
    '003': [
      '87820',
      '87821',
      '87824',
      '87825',
      '87827',
      '87829',
      '87830',
      '87943',
      '88039'
    ]
  },
  '40': {
    '079': [
      '74549',
      '74563',
      '74571',
      '74577',
      '74901',
      '74902',
      '74930',
      '74932',
      '74935',
      '74937',
      '74939',
      '74940',
      '74941',
      '74942',
      '74944',
      '74949',
      '74951',
      '74953',
      '74956',
      '74957',
      '74959',
      '74966'
    ],
    '123': [
      '74572',
      '74820',
      '74825',
      '74831',
      '74842',
      '74843',
      '74844',
      '74865',
      '74871',
      '74872'
    ],
    '099': [
      '73030',
      '73032',
      '73086',
      '73098',
      '73444',
      '74856',
      '74865'
    ],
    '009': [
      '73627',
      '73644',
      '73645',
      '73662',
      '73673'
    ],
    '119': [
      '73027',
      '73063',
      '73073',
      '74023',
      '74030',
      '74032',
      '74045',
      '74059',
      '74062',
      '74074',
      '74075',
      '74078',
      '74085',
      '74824'
    ],
    '153': [
      '73801',
      '73838',
      '73840',
      '73841',
      '73842',
      '73852',
      '73853',
      '73857',
      '73859'
    ],
    '055': [
      '73526',
      '73547',
      '73554',
      '73662',
      '73673'
    ],
    '029': [
      '74530',
      '74534',
      '74535',
      '74538',
      '74556',
      '74572',
      '74825',
      '74871'
    ],
    '109': [
      '73003',
      '73007',
      '73008',
      '73012',
      '73013',
      '73020',
      '73025',
      '73034',
      '73045',
      '73049',
      '73054',
      '73066',
      '73078',
      '73084',
      '73097',
      '73102',
      '73103',
      '73104',
      '73105',
      '73106',
      '73107',
      '73108',
      '73109',
      '73110',
      '73111',
      '73112',
      '73114',
      '73115',
      '73116',
      '73117',
      '73118',
      '73119',
      '73120',
      '73121',
      '73122',
      '73127',
      '73128',
      '73129',
      '73130',
      '73131',
      '73132',
      '73134',
      '73135',
      '73139',
      '73141',
      '73142',
      '73145',
      '73149',
      '73150',
      '73151',
      '73159',
      '73162',
      '73169',
      '73179',
      '74857'
    ],
    '021': [
      '74347',
      '74352',
      '74364',
      '74427',
      '74434',
      '74441',
      '74451',
      '74452',
      '74457',
      '74464',
      '74471',
      '74931',
      '74960',
      '74962'
    ],
    '053': [
      '73758',
      '73759',
      '73761',
      '73766',
      '73771',
      '74636',
      '74643',
      '74646'
    ],
    '031': [
      '73006',
      '73017',
      '73055',
      '73082',
      '73501',
      '73503',
      '73505',
      '73507',
      '73527',
      '73528',
      '73538',
      '73540',
      '73541',
      '73543',
      '73552',
      '73557',
      '73559',
      '73564',
      '73567',
      '73572'
    ],
    '091': [
      '74426',
      '74428',
      '74432',
      '74437',
      '74438',
      '74445',
      '74455',
      '74459',
      '74469',
      '74839',
      '74845'
    ],
    '063': [
      '74531',
      '74570',
      '74825',
      '74827',
      '74839',
      '74848',
      '74850',
      '74867',
      '74883',
      '74884'
    ],
    '139': [
      '67950',
      '73901',
      '73939',
      '73942',
      '73944',
      '73945',
      '73949',
      '73950',
      '73951'
    ],
    '025': [
      '73933',
      '73937',
      '73946',
      '73947',
      '73949'
    ],
    '059': [
      '73801',
      '73834',
      '73842',
      '73844',
      '73848',
      '73851',
      '73855'
    ],
    '061': [
      '74462',
      '74472',
      '74552',
      '74561',
      '74941',
      '74943',
      '74944'
    ],
    '151': [
      '73716',
      '73717',
      '73726',
      '73729',
      '73731',
      '73746',
      '73842',
      '73860'
    ],
    '111': [
      '74047',
      '74421',
      '74422',
      '74431',
      '74436',
      '74437',
      '74445',
      '74447',
      '74456',
      '74460',
      '74880'
    ],
    '039': [
      '73048',
      '73096',
      '73601',
      '73620',
      '73625',
      '73626',
      '73639',
      '73644',
      '73646',
      '73647',
      '73650',
      '73654',
      '73669'
    ],
    '149': [
      '73015',
      '73021',
      '73024',
      '73041',
      '73062',
      '73096',
      '73601',
      '73622',
      '73624',
      '73626',
      '73632',
      '73641',
      '73644',
      '73647',
      '73661',
      '73664'
    ],
    '011': [
      '73040',
      '73043',
      '73048',
      '73646',
      '73669',
      '73724',
      '73744',
      '73755',
      '73763',
      '73764',
      '73772'
    ],
    '121': [
      '74425',
      '74430',
      '74432',
      '74442',
      '74462',
      '74501',
      '74522',
      '74528',
      '74536',
      '74546',
      '74547',
      '74553',
      '74554',
      '74560',
      '74561',
      '74565',
      '74570',
      '74576'
    ],
    '117': [
      '73061',
      '74020',
      '74032',
      '74034',
      '74038',
      '74044',
      '74045',
      '74058',
      '74081',
      '74085',
      '74650',
      '74651'
    ],
    '035': [
      '74016',
      '74072',
      '74301',
      '74331',
      '74332',
      '74333',
      '74349',
      '74354',
      '74369'
    ],
    '027': [
      '73019',
      '73020',
      '73026',
      '73051',
      '73068',
      '73069',
      '73071',
      '73072',
      '73135',
      '73139',
      '73149',
      '73150',
      '73159',
      '73160',
      '73165',
      '73169',
      '73170',
      '73173',
      '74851',
      '74852',
      '74857',
      '74878'
    ],
    '037': [
      '74010',
      '74028',
      '74030',
      '74038',
      '74039',
      '74041',
      '74044',
      '74046',
      '74047',
      '74050',
      '74052',
      '74063',
      '74066',
      '74068',
      '74071',
      '74079',
      '74081',
      '74085',
      '74131',
      '74132'
    ],
    '147': [
      '74003',
      '74006',
      '74021',
      '74022',
      '74029',
      '74051',
      '74061',
      '74070',
      '74080',
      '74082',
      '74083'
    ],
    '145': [
      '74008',
      '74014',
      '74015',
      '74036',
      '74108',
      '74337',
      '74352',
      '74403',
      '74429',
      '74434',
      '74436',
      '74446',
      '74454',
      '74458',
      '74467',
      '74477'
    ],
    '085': [
      '73401',
      '73430',
      '73441',
      '73448',
      '73453',
      '73456',
      '73459',
      '73463'
    ],
    '137': [
      '73055',
      '73425',
      '73434',
      '73438',
      '73442',
      '73481',
      '73491',
      '73529',
      '73533',
      '73548'
    ],
    '103': [
      '73061',
      '73073',
      '73077',
      '73757',
      '74032',
      '74075',
      '74601',
      '74630',
      '74644',
      '74651',
      '74653'
    ],
    '089': [
      '74722',
      '74724',
      '74728',
      '74734',
      '74736',
      '74740',
      '74745',
      '74750',
      '74754',
      '74755',
      '74764',
      '74766',
      '74957',
      '74963'
    ],
    '045': [
      '73832',
      '73840',
      '73841',
      '73843',
      '73848',
      '73851',
      '73858',
      '73859'
    ],
    '131': [
      '74015',
      '74016',
      '74017',
      '74019',
      '74021',
      '74036',
      '74053',
      '74055',
      '74080',
      '74116',
      '74332'
    ],
    '107': [
      '74010',
      '74437',
      '74829',
      '74833',
      '74839',
      '74859',
      '74860',
      '74880',
      '74883'
    ],
    '127': [
      '74521',
      '74523',
      '74536',
      '74540',
      '74543',
      '74549',
      '74557',
      '74558',
      '74562',
      '74567',
      '74571',
      '74574',
      '74728',
      '74735',
      '74754',
      '74760'
    ],
    '081': [
      '73045',
      '73054',
      '74023',
      '74026',
      '74059',
      '74079',
      '74824',
      '74832',
      '74834',
      '74851',
      '74855',
      '74864',
      '74869',
      '74875',
      '74881'
    ],
    '097': [
      '74016',
      '74036',
      '74301',
      '74330',
      '74332',
      '74337',
      '74340',
      '74349',
      '74350',
      '74352',
      '74361',
      '74364',
      '74365',
      '74366',
      '74367',
      '74452'
    ],
    '057': [
      '73532',
      '73537',
      '73544',
      '73550',
      '73554',
      '73571'
    ],
    '001': [
      '74338',
      '74347',
      '74457',
      '74931',
      '74948',
      '74960',
      '74964',
      '74965'
    ],
    '129': [
      '73628',
      '73638',
      '73642',
      '73644',
      '73650',
      '73654',
      '73660',
      '73662',
      '73666'
    ],
    '017': [
      '73014',
      '73036',
      '73040',
      '73047',
      '73059',
      '73064',
      '73078',
      '73090',
      '73099',
      '73127',
      '73128',
      '73179',
      '73762'
    ],
    '115': [
      '74331',
      '74333',
      '74339',
      '74343',
      '74344',
      '74354',
      '74358',
      '74360',
      '74363',
      '74370'
    ],
    '087': [
      '73010',
      '73052',
      '73057',
      '73065',
      '73072',
      '73074',
      '73080',
      '73093',
      '73095',
      '74831',
      '74872'
    ],
    '095': [
      '73439',
      '73440',
      '73446'
    ],
    '041': [
      '74331',
      '74338',
      '74342',
      '74344',
      '74346',
      '74347',
      '74359',
      '74364',
      '74365',
      '74366',
      '74368',
      '74370',
      '74964'
    ],
    '005': [
      '73432',
      '73461',
      '74525',
      '74533',
      '74540',
      '74553',
      '74555',
      '74560',
      '74569',
      '74576',
      '74727',
      '74729',
      '74748'
    ],
    '051': [
      '73002',
      '73004',
      '73010',
      '73011',
      '73017',
      '73018',
      '73052',
      '73055',
      '73059',
      '73067',
      '73079',
      '73082',
      '73089',
      '73092'
    ],
    '077': [
      '74536',
      '74547',
      '74563',
      '74571',
      '74574',
      '74578',
      '74966'
    ],
    '033': [
      '73528',
      '73531',
      '73533',
      '73540',
      '73543',
      '73548',
      '73562',
      '73568',
      '73572'
    ],
    '069': [
      '73401',
      '73432',
      '73447',
      '73450',
      '73455',
      '73460',
      '73461',
      '74530',
      '74748',
      '74820',
      '74836',
      '74856',
      '74871'
    ],
    '093': [
      '73663',
      '73716',
      '73718',
      '73729',
      '73737',
      '73747',
      '73754',
      '73755',
      '73760',
      '73763',
      '73768',
      '73838',
      '73860'
    ],
    '067': [
      '73442',
      '73456',
      '73520',
      '73529',
      '73548',
      '73565',
      '73569',
      '73573'
    ],
    '105': [
      '74016',
      '74027',
      '74042',
      '74048',
      '74072',
      '74083',
      '74301'
    ],
    '113': [
      '74001',
      '74002',
      '74003',
      '74022',
      '74035',
      '74051',
      '74054',
      '74056',
      '74060',
      '74063',
      '74070',
      '74073',
      '74084',
      '74106',
      '74126',
      '74127',
      '74604',
      '74633',
      '74637',
      '74650',
      '74652'
    ],
    '133': [
      '74830',
      '74837',
      '74840',
      '74848',
      '74849',
      '74854',
      '74859',
      '74867',
      '74868',
      '74884'
    ],
    '071': [
      '74601',
      '74604',
      '74631',
      '74632',
      '74641',
      '74646',
      '74647',
      '74652',
      '74653'
    ],
    '141': [
      '73528',
      '73530',
      '73542',
      '73546',
      '73551',
      '73553',
      '73555',
      '73566',
      '73570'
    ],
    '075': [
      '73015',
      '73041',
      '73062',
      '73559',
      '73564',
      '73566',
      '73651',
      '73655'
    ],
    '047': [
      '73056',
      '73073',
      '73701',
      '73703',
      '73705',
      '73718',
      '73720',
      '73727',
      '73730',
      '73733',
      '73735',
      '73736',
      '73738',
      '73739',
      '73743',
      '73753',
      '73754',
      '73757',
      '73761',
      '73773',
      '74630',
      '74640'
    ],
    '007': [
      '73844',
      '73848',
      '73931',
      '73932',
      '73938',
      '73950'
    ],
    '023': [
      '74523',
      '74727',
      '74735',
      '74738',
      '74743',
      '74756',
      '74759',
      '74760',
      '74761',
      '74764'
    ],
    '043': [
      '73646',
      '73654',
      '73658',
      '73659',
      '73663',
      '73667',
      '73724',
      '73755',
      '73835',
      '73859'
    ],
    '143': [
      '74008',
      '74011',
      '74012',
      '74014',
      '74015',
      '74021',
      '74033',
      '74037',
      '74047',
      '74050',
      '74055',
      '74063',
      '74066',
      '74070',
      '74073',
      '74103',
      '74104',
      '74105',
      '74106',
      '74107',
      '74108',
      '74110',
      '74112',
      '74114',
      '74115',
      '74116',
      '74117',
      '74119',
      '74120',
      '74126',
      '74127',
      '74128',
      '74129',
      '74130',
      '74131',
      '74132',
      '74133',
      '74134',
      '74135',
      '74136',
      '74137',
      '74145',
      '74146'
    ],
    '135': [
      '74435',
      '74931',
      '74936',
      '74945',
      '74946',
      '74948',
      '74954',
      '74955',
      '74962'
    ],
    '101': [
      '74401',
      '74403',
      '74422',
      '74423',
      '74428',
      '74434',
      '74435',
      '74436',
      '74450',
      '74455',
      '74463',
      '74468',
      '74469',
      '74470'
    ],
    '065': [
      '73521',
      '73526',
      '73532',
      '73537',
      '73539',
      '73549',
      '73556',
      '73560'
    ],
    '125': [
      '73045',
      '74801',
      '74804',
      '74826',
      '74840',
      '74849',
      '74851',
      '74852',
      '74854',
      '74855',
      '74857',
      '74864',
      '74873',
      '74878'
    ],
    '049': [
      '73030',
      '73052',
      '73057',
      '73074',
      '73075',
      '73098',
      '73433',
      '73434',
      '73481',
      '74831',
      '74865',
      '74872'
    ],
    '019': [
      '73401',
      '73425',
      '73437',
      '73438',
      '73443',
      '73444',
      '73456',
      '73458',
      '73463',
      '73481',
      '73487'
    ],
    '015': [
      '73005',
      '73006',
      '73009',
      '73015',
      '73017',
      '73021',
      '73029',
      '73033',
      '73038',
      '73042',
      '73047',
      '73048',
      '73053',
      '73059',
      '73079',
      '73092'
    ],
    '083': [
      '73007',
      '73016',
      '73025',
      '73027',
      '73028',
      '73034',
      '73044',
      '73050',
      '73054',
      '73056',
      '73058',
      '73063',
      '73073',
      '74059',
      '74881'
    ],
    '013': [
      '73449',
      '74701',
      '74720',
      '74723',
      '74726',
      '74727',
      '74729',
      '74730',
      '74731',
      '74733',
      '74741',
      '74747',
      '74748',
      '74753'
    ],
    '073': [
      '73016',
      '73028',
      '73056',
      '73734',
      '73742',
      '73750',
      '73756',
      '73762',
      '73763',
      '73764'
    ],
    '003': [
      '73716',
      '73717',
      '73719',
      '73722',
      '73726',
      '73728',
      '73739',
      '73741',
      '73749',
      '73758'
    ]
  },
  '08': {
    '079': [
      '81130',
      '81147',
      '81154'
    ],
    '123': [
      '80504',
      '80513',
      '80514',
      '80516',
      '80520',
      '80524',
      '80530',
      '80534',
      '80537',
      '80542',
      '80543',
      '80546',
      '80549',
      '80550',
      '80603',
      '80610',
      '80611',
      '80612',
      '80615',
      '80620',
      '80621',
      '80622',
      '80623',
      '80624',
      '80631',
      '80634',
      '80642',
      '80643',
      '80644',
      '80645',
      '80648',
      '80649',
      '80650',
      '80651',
      '80652',
      '80654',
      '80729',
      '80742',
      '80754'
    ],
    '099': [
      '81041',
      '81043',
      '81047',
      '81052',
      '81084',
      '81092'
    ],
    '067': [
      '81122',
      '81137',
      '81301',
      '81303',
      '81326',
      '81328'
    ],
    '119': [
      '80813',
      '80814',
      '80816',
      '80819',
      '80827',
      '80860',
      '80863'
    ],
    '055': [
      '81040',
      '81055',
      '81069',
      '81089'
    ],
    '029': [
      '81410',
      '81413',
      '81415',
      '81416',
      '81418',
      '81419',
      '81428',
      '81434'
    ],
    '109': [
      '81125',
      '81131',
      '81132',
      '81136',
      '81143',
      '81149',
      '81155',
      '81230',
      '81248'
    ],
    '021': [
      '81101',
      '81120',
      '81124',
      '81129',
      '81140',
      '81141',
      '81148',
      '81151'
    ],
    '053': [
      '81130',
      '81147',
      '81235'
    ],
    '031': [
      '80012',
      '80014',
      '80110',
      '80111',
      '80123',
      '80202',
      '80203',
      '80204',
      '80205',
      '80206',
      '80207',
      '80209',
      '80210',
      '80211',
      '80212',
      '80214',
      '80216',
      '80218',
      '80219',
      '80220',
      '80221',
      '80222',
      '80223',
      '80224',
      '80226',
      '80227',
      '80230',
      '80231',
      '80232',
      '80235',
      '80236',
      '80237',
      '80238',
      '80239',
      '80246',
      '80247',
      '80249',
      '80264',
      '80290',
      '80293',
      '80294'
    ],
    '091': [
      '81403',
      '81427',
      '81432'
    ],
    '063': [
      '80805',
      '80807',
      '80815',
      '80834',
      '80836',
      '80861'
    ],
    '025': [
      '81033',
      '81039',
      '81058',
      '81062',
      '81063',
      '81076'
    ],
    '059': [
      '80002',
      '80003',
      '80004',
      '80005',
      '80007',
      '80020',
      '80021',
      '80031',
      '80033',
      '80123',
      '80127',
      '80128',
      '80135',
      '80212',
      '80214',
      '80215',
      '80226',
      '80227',
      '80228',
      '80232',
      '80235',
      '80401',
      '80403',
      '80419',
      '80421',
      '80425',
      '80433',
      '80439',
      '80453',
      '80454',
      '80457',
      '80465',
      '80470'
    ],
    '111': [
      '81301',
      '81433'
    ],
    '039': [
      '80101',
      '80102',
      '80105',
      '80106',
      '80107',
      '80117',
      '80138',
      '80808',
      '80828',
      '80830',
      '80831',
      '80832',
      '80833',
      '80835'
    ],
    '097': [
      '81611',
      '81612',
      '81615',
      '81621',
      '81623',
      '81642',
      '81654',
      '81656'
    ],
    '011': [
      '81038',
      '81044',
      '81050',
      '81052',
      '81054',
      '81057',
      '81092'
    ],
    '117': [
      '80424',
      '80435',
      '80443',
      '80497',
      '80498'
    ],
    '035': [
      '80016',
      '80104',
      '80106',
      '80108',
      '80109',
      '80112',
      '80116',
      '80118',
      '80120',
      '80124',
      '80125',
      '80126',
      '80129',
      '80130',
      '80131',
      '80133',
      '80134',
      '80135',
      '80138'
    ],
    '027': [
      '81069',
      '81252',
      '81253'
    ],
    '037': [
      '80423',
      '80426',
      '80463',
      '81620',
      '81621',
      '81623',
      '81631',
      '81632',
      '81637',
      '81645',
      '81649',
      '81655',
      '81657'
    ],
    '014': [
      '80020',
      '80021',
      '80023',
      '80027',
      '80234',
      '80516'
    ],
    '125': [
      '80727',
      '80731',
      '80734',
      '80735',
      '80755',
      '80758',
      '80759',
      '80807',
      '80822',
      '80824'
    ],
    '085': [
      '81220',
      '81401',
      '81403',
      '81411',
      '81415',
      '81416',
      '81422',
      '81423',
      '81424',
      '81425',
      '81429',
      '81431'
    ],
    '103': [
      '81641',
      '81648',
      '81650'
    ],
    '089': [
      '81030',
      '81039',
      '81050',
      '81058',
      '81059',
      '81067',
      '81077'
    ],
    '045': [
      '81601',
      '81623',
      '81630',
      '81635',
      '81637',
      '81647',
      '81650',
      '81652'
    ],
    '107': [
      '80426',
      '80428',
      '80463',
      '80467',
      '80469',
      '80477',
      '80479',
      '80483',
      '80487',
      '80488',
      '81625',
      '81639',
      '81653'
    ],
    '065': [
      '80461',
      '81251'
    ],
    '121': [
      '80720',
      '80723',
      '80733',
      '80740',
      '80741',
      '80743',
      '80757',
      '80759',
      '80801',
      '80812',
      '80815',
      '80818'
    ],
    '057': [
      '80434',
      '80473',
      '80480'
    ],
    '001': [
      '80002',
      '80003',
      '80010',
      '80011',
      '80019',
      '80020',
      '80022',
      '80023',
      '80024',
      '80030',
      '80031',
      '80045',
      '80102',
      '80103',
      '80105',
      '80136',
      '80137',
      '80212',
      '80216',
      '80221',
      '80229',
      '80233',
      '80234',
      '80239',
      '80241',
      '80260',
      '80601',
      '80602',
      '80603',
      '80640',
      '80642',
      '80643',
      '80654',
      '80701'
    ],
    '017': [
      '80802',
      '80810',
      '80821',
      '80825',
      '80862'
    ],
    '095': [
      '80721',
      '80731',
      '80734',
      '80746'
    ],
    '087': [
      '80649',
      '80653',
      '80654',
      '80701',
      '80705',
      '80723',
      '80733',
      '80741',
      '80750'
    ],
    '115': [
      '80731',
      '80737',
      '80744',
      '80749'
    ],
    '041': [
      '80106',
      '80132',
      '80133',
      '80808',
      '80809',
      '80817',
      '80819',
      '80829',
      '80831',
      '80832',
      '80833',
      '80840',
      '80864',
      '80902',
      '80903',
      '80904',
      '80905',
      '80906',
      '80907',
      '80908',
      '80909',
      '80910',
      '80911',
      '80913',
      '80914',
      '80915',
      '80916',
      '80917',
      '80918',
      '80919',
      '80920',
      '80921',
      '80922',
      '80923',
      '80924',
      '80925',
      '80926',
      '80927',
      '80928',
      '80929',
      '80930',
      '80938',
      '80939',
      '80951',
      '81008'
    ],
    '005': [
      '80010',
      '80011',
      '80012',
      '80013',
      '80014',
      '80015',
      '80016',
      '80017',
      '80018',
      '80102',
      '80103',
      '80105',
      '80110',
      '80111',
      '80112',
      '80113',
      '80120',
      '80121',
      '80122',
      '80123',
      '80128',
      '80129',
      '80136',
      '80137',
      '80220',
      '80222',
      '80224',
      '80231',
      '80236',
      '80237',
      '80246',
      '80247'
    ],
    '051': [
      '81210',
      '81220',
      '81224',
      '81225',
      '81230',
      '81231',
      '81237',
      '81239',
      '81241',
      '81243',
      '81248',
      '81434',
      '81623'
    ],
    '077': [
      '81501',
      '81503',
      '81504',
      '81505',
      '81506',
      '81507',
      '81520',
      '81521',
      '81522',
      '81523',
      '81524',
      '81525',
      '81526',
      '81527',
      '81624',
      '81630',
      '81643',
      '81646'
    ],
    '033': [
      '81320',
      '81323',
      '81324',
      '81325',
      '81332'
    ],
    '069': [
      '80503',
      '80504',
      '80511',
      '80512',
      '80513',
      '80515',
      '80517',
      '80521',
      '80524',
      '80525',
      '80526',
      '80528',
      '80532',
      '80534',
      '80535',
      '80536',
      '80537',
      '80538',
      '80540',
      '80545',
      '80547',
      '80549',
      '80550',
      '80612',
      '82063'
    ],
    '093': [
      '80420',
      '80421',
      '80432',
      '80440',
      '80448',
      '80449',
      '80456',
      '80470',
      '80475',
      '80816',
      '80820',
      '80827'
    ],
    '009': [
      '81029',
      '81054',
      '81064',
      '81073',
      '81084',
      '81087',
      '81090'
    ],
    '105': [
      '81125',
      '81132',
      '81144',
      '81154'
    ],
    '113': [
      '81325',
      '81423',
      '81426',
      '81430',
      '81431',
      '81432',
      '81435'
    ],
    '071': [
      '81020',
      '81024',
      '81027',
      '81049',
      '81059',
      '81064',
      '81081',
      '81082',
      '81091'
    ],
    '075': [
      '80722',
      '80726',
      '80728',
      '80731',
      '80736',
      '80741',
      '80745',
      '80747',
      '80751'
    ],
    '047': [
      '80403',
      '80422',
      '80427'
    ],
    '081': [
      '81610',
      '81625',
      '81633',
      '81638',
      '81640',
      '81641',
      '81653'
    ],
    '083': [
      '81321',
      '81323',
      '81327',
      '81328',
      '81330',
      '81331',
      '81334',
      '81335'
    ],
    '023': [
      '81123',
      '81126',
      '81133',
      '81138',
      '81151',
      '81152'
    ],
    '043': [
      '80926',
      '81201',
      '81212',
      '81221',
      '81222',
      '81223',
      '81226',
      '81232',
      '81233',
      '81240',
      '81244',
      '81253'
    ],
    '101': [
      '81001',
      '81003',
      '81004',
      '81005',
      '81006',
      '81007',
      '81008',
      '81019',
      '81022',
      '81023',
      '81025',
      '81039',
      '81069'
    ],
    '061': [
      '81021',
      '81036',
      '81045',
      '81071'
    ],
    '049': [
      '80423',
      '80442',
      '80446',
      '80447',
      '80451',
      '80459',
      '80468',
      '80478',
      '80482'
    ],
    '019': [
      '80436',
      '80438',
      '80439',
      '80444',
      '80452',
      '80476'
    ],
    '015': [
      '80449',
      '81201',
      '81211',
      '81227',
      '81236',
      '81242'
    ],
    '007': [
      '81121',
      '81122',
      '81128',
      '81137',
      '81147'
    ],
    '013': [
      '80020',
      '80025',
      '80026',
      '80027',
      '80301',
      '80302',
      '80303',
      '80304',
      '80305',
      '80310',
      '80403',
      '80422',
      '80455',
      '80466',
      '80471',
      '80481',
      '80501',
      '80503',
      '80504',
      '80510',
      '80516',
      '80540',
      '80544'
    ],
    '073': [
      '80804',
      '80818',
      '80821',
      '80823',
      '80828',
      '80832',
      '80833',
      '81063'
    ],
    '003': [
      '81101',
      '81125',
      '81136',
      '81144',
      '81146'
    ]
  },
  '36': {
    '079': [
      '10509',
      '10512',
      '10516',
      '10524',
      '10537',
      '10541',
      '10579',
      '10588',
      '12531',
      '12533',
      '12563',
      '12582'
    ],
    '123': [
      '14415',
      '14418',
      '14441',
      '14456',
      '14478',
      '14507',
      '14512',
      '14527',
      '14544',
      '14561',
      '14837',
      '14842',
      '14878'
    ],
    '099': [
      '13146',
      '13148',
      '13165',
      '14433',
      '14456',
      '14489',
      '14521',
      '14532',
      '14541',
      '14588',
      '14841',
      '14847',
      '14860',
      '14886'
    ],
    '009': [
      '14009',
      '14030',
      '14041',
      '14042',
      '14060',
      '14065',
      '14070',
      '14081',
      '14101',
      '14129',
      '14138',
      '14141',
      '14168',
      '14171',
      '14173',
      '14706',
      '14719',
      '14726',
      '14727',
      '14729',
      '14731',
      '14737',
      '14738',
      '14741',
      '14743',
      '14747',
      '14748',
      '14753',
      '14755',
      '14760',
      '14770',
      '14772',
      '14778',
      '14779',
      '14783',
      '14788'
    ],
    '119': [
      '10501',
      '10502',
      '10503',
      '10504',
      '10505',
      '10506',
      '10507',
      '10509',
      '10510',
      '10511',
      '10514',
      '10517',
      '10518',
      '10519',
      '10520',
      '10522',
      '10523',
      '10526',
      '10527',
      '10528',
      '10530',
      '10532',
      '10533',
      '10535',
      '10536',
      '10537',
      '10538',
      '10541',
      '10543',
      '10545',
      '10546',
      '10547',
      '10548',
      '10549',
      '10550',
      '10552',
      '10553',
      '10560',
      '10562',
      '10566',
      '10567',
      '10570',
      '10573',
      '10576',
      '10577',
      '10578',
      '10580',
      '10583',
      '10588',
      '10589',
      '10590',
      '10591',
      '10594',
      '10595',
      '10596',
      '10597',
      '10598',
      '10601',
      '10603',
      '10604',
      '10605',
      '10606',
      '10607',
      '10701',
      '10703',
      '10704',
      '10705',
      '10706',
      '10707',
      '10708',
      '10709',
      '10710',
      '10801',
      '10803',
      '10804',
      '10805'
    ],
    '055': [
      '14414',
      '14416',
      '14420',
      '14428',
      '14445',
      '14450',
      '14464',
      '14467',
      '14468',
      '14470',
      '14472',
      '14476',
      '14482',
      '14502',
      '14506',
      '14511',
      '14514',
      '14519',
      '14526',
      '14534',
      '14543',
      '14546',
      '14559',
      '14564',
      '14580',
      '14586',
      '14604',
      '14605',
      '14606',
      '14607',
      '14608',
      '14609',
      '14610',
      '14611',
      '14612',
      '14613',
      '14614',
      '14615',
      '14616',
      '14617',
      '14618',
      '14619',
      '14620',
      '14621',
      '14622',
      '14623',
      '14624',
      '14625',
      '14626',
      '14627'
    ],
    '029': [
      '14001',
      '14004',
      '14006',
      '14013',
      '14025',
      '14026',
      '14030',
      '14031',
      '14032',
      '14033',
      '14034',
      '14035',
      '14037',
      '14043',
      '14047',
      '14051',
      '14052',
      '14055',
      '14057',
      '14059',
      '14061',
      '14068',
      '14069',
      '14070',
      '14072',
      '14075',
      '14080',
      '14081',
      '14085',
      '14086',
      '14091',
      '14102',
      '14111',
      '14112',
      '14127',
      '14134',
      '14139',
      '14141',
      '14145',
      '14150',
      '14169',
      '14170',
      '14201',
      '14202',
      '14203',
      '14204',
      '14206',
      '14207',
      '14208',
      '14209',
      '14210',
      '14211',
      '14212',
      '14213',
      '14214',
      '14215',
      '14216',
      '14217',
      '14218',
      '14219',
      '14220',
      '14221',
      '14222',
      '14223',
      '14224',
      '14225',
      '14226',
      '14227',
      '14228',
      '14261'
    ],
    '109': [
      '13045',
      '13053',
      '13062',
      '13068',
      '13073',
      '13092',
      '13102',
      '13736',
      '13864',
      '14817',
      '14850',
      '14853',
      '14854',
      '14867',
      '14881',
      '14882',
      '14883',
      '14886'
    ],
    '021': [
      '12017',
      '12024',
      '12029',
      '12037',
      '12060',
      '12062',
      '12075',
      '12106',
      '12115',
      '12125',
      '12130',
      '12132',
      '12136',
      '12156',
      '12165',
      '12172',
      '12173',
      '12174',
      '12184',
      '12195',
      '12502',
      '12503',
      '12513',
      '12516',
      '12517',
      '12521',
      '12523',
      '12526',
      '12529',
      '12530',
      '12534',
      '12546',
      '12565',
      '12567',
      '12571',
      '12583'
    ],
    '053': [
      '13030',
      '13032',
      '13035',
      '13037',
      '13052',
      '13061',
      '13072',
      '13082',
      '13104',
      '13122',
      '13134',
      '13163',
      '13310',
      '13314',
      '13332',
      '13334',
      '13346',
      '13355',
      '13364',
      '13402',
      '13408',
      '13409',
      '13418',
      '13421',
      '13425',
      '13460',
      '13480',
      '13484',
      '13485',
      '13491'
    ],
    '031': [
      '12808',
      '12836',
      '12851',
      '12852',
      '12853',
      '12855',
      '12857',
      '12858',
      '12870',
      '12872',
      '12883',
      '12912',
      '12913',
      '12928',
      '12932',
      '12936',
      '12941',
      '12942',
      '12943',
      '12944',
      '12946',
      '12950',
      '12956',
      '12960',
      '12961',
      '12964',
      '12972',
      '12974',
      '12975',
      '12977',
      '12983',
      '12987',
      '12993',
      '12996',
      '12997',
      '12998'
    ],
    '091': [
      '12010',
      '12019',
      '12020',
      '12025',
      '12027',
      '12065',
      '12074',
      '12086',
      '12118',
      '12134',
      '12148',
      '12151',
      '12170',
      '12188',
      '12302',
      '12803',
      '12822',
      '12828',
      '12831',
      '12833',
      '12835',
      '12850',
      '12859',
      '12863',
      '12866',
      '12871',
      '12884'
    ],
    '063': [
      '14001',
      '14008',
      '14012',
      '14028',
      '14067',
      '14092',
      '14094',
      '14105',
      '14108',
      '14109',
      '14120',
      '14126',
      '14131',
      '14132',
      '14172',
      '14174',
      '14301',
      '14302',
      '14303',
      '14304',
      '14305'
    ],
    '025': [
      '12093',
      '12155',
      '12167',
      '12406',
      '12421',
      '12430',
      '12434',
      '12438',
      '12441',
      '12455',
      '12459',
      '12465',
      '12468',
      '12474',
      '12758',
      '12760',
      '12776',
      '13731',
      '13733',
      '13739',
      '13740',
      '13750',
      '13751',
      '13752',
      '13753',
      '13754',
      '13755',
      '13756',
      '13757',
      '13774',
      '13775',
      '13782',
      '13783',
      '13786',
      '13788',
      '13804',
      '13806',
      '13820',
      '13825',
      '13838',
      '13839',
      '13842',
      '13846',
      '13847',
      '13849',
      '13856',
      '13860'
    ],
    '059': [
      '11001',
      '11003',
      '11010',
      '11020',
      '11021',
      '11023',
      '11024',
      '11030',
      '11040',
      '11042',
      '11050',
      '11096',
      '11501',
      '11507',
      '11509',
      '11510',
      '11514',
      '11516',
      '11518',
      '11520',
      '11530',
      '11542',
      '11545',
      '11547',
      '11548',
      '11549',
      '11550',
      '11552',
      '11553',
      '11554',
      '11556',
      '11557',
      '11558',
      '11559',
      '11560',
      '11561',
      '11563',
      '11565',
      '11566',
      '11568',
      '11569',
      '11570',
      '11572',
      '11575',
      '11576',
      '11577',
      '11579',
      '11580',
      '11581',
      '11590',
      '11596',
      '11598',
      '11709',
      '11710',
      '11714',
      '11732',
      '11735',
      '11753',
      '11756',
      '11758',
      '11762',
      '11765',
      '11771',
      '11783',
      '11791',
      '11793',
      '11797',
      '11801',
      '11803',
      '11804'
    ],
    '111': [
      '12401',
      '12404',
      '12406',
      '12409',
      '12410',
      '12411',
      '12412',
      '12416',
      '12417',
      '12419',
      '12420',
      '12428',
      '12429',
      '12432',
      '12433',
      '12435',
      '12440',
      '12441',
      '12443',
      '12446',
      '12448',
      '12449',
      '12453',
      '12455',
      '12456',
      '12457',
      '12458',
      '12461',
      '12464',
      '12465',
      '12466',
      '12471',
      '12472',
      '12475',
      '12477',
      '12480',
      '12481',
      '12483',
      '12484',
      '12486',
      '12487',
      '12489',
      '12490',
      '12491',
      '12493',
      '12494',
      '12495',
      '12498',
      '12515',
      '12525',
      '12528',
      '12542',
      '12547',
      '12548',
      '12561',
      '12566',
      '12586',
      '12589',
      '12725',
      '12740',
      '12758'
    ],
    '039': [
      '12015',
      '12042',
      '12046',
      '12051',
      '12058',
      '12083',
      '12087',
      '12120',
      '12124',
      '12143',
      '12176',
      '12192',
      '12405',
      '12407',
      '12413',
      '12414',
      '12418',
      '12422',
      '12423',
      '12424',
      '12427',
      '12430',
      '12431',
      '12436',
      '12439',
      '12442',
      '12444',
      '12450',
      '12451',
      '12452',
      '12454',
      '12460',
      '12463',
      '12468',
      '12469',
      '12470',
      '12473',
      '12480',
      '12482',
      '12485',
      '12492',
      '12496'
    ],
    '097': [
      '14805',
      '14812',
      '14815',
      '14818',
      '14824',
      '14837',
      '14840',
      '14841',
      '14864',
      '14865',
      '14869',
      '14870',
      '14878',
      '14886',
      '14889',
      '14891'
    ],
    '011': [
      '13021',
      '13024',
      '13026',
      '13027',
      '13033',
      '13034',
      '13045',
      '13064',
      '13071',
      '13073',
      '13074',
      '13077',
      '13080',
      '13081',
      '13092',
      '13111',
      '13113',
      '13117',
      '13118',
      '13126',
      '13140',
      '13143',
      '13147',
      '13152',
      '13156',
      '13160',
      '13166'
    ],
    '117': [
      '13143',
      '13146',
      '13156',
      '14433',
      '14450',
      '14489',
      '14502',
      '14505',
      '14513',
      '14516',
      '14519',
      '14522',
      '14542',
      '14551',
      '14555',
      '14568',
      '14580',
      '14589',
      '14590'
    ],
    '035': [
      '12010',
      '12025',
      '12032',
      '12068',
      '12070',
      '12078',
      '12086',
      '12095',
      '12117',
      '12134',
      '13329',
      '13339',
      '13452',
      '13470'
    ],
    '033': [
      '12912',
      '12913',
      '12914',
      '12916',
      '12917',
      '12920',
      '12926',
      '12930',
      '12937',
      '12939',
      '12945',
      '12953',
      '12955',
      '12957',
      '12966',
      '12969',
      '12970',
      '12976',
      '12980',
      '12981',
      '12983',
      '12986',
      '12989',
      '13655'
    ],
    '037': [
      '14001',
      '14004',
      '14005',
      '14011',
      '14013',
      '14020',
      '14036',
      '14040',
      '14054',
      '14058',
      '14105',
      '14125',
      '14143',
      '14416',
      '14422',
      '14482',
      '14525',
      '14591'
    ],
    '085': [
      '10301',
      '10302',
      '10303',
      '10304',
      '10305',
      '10306',
      '10307',
      '10308',
      '10309',
      '10310',
      '10311',
      '10312',
      '10314'
    ],
    '017': [
      '13040',
      '13052',
      '13072',
      '13124',
      '13136',
      '13155',
      '13332',
      '13411',
      '13460',
      '13464',
      '13485',
      '13730',
      '13733',
      '13746',
      '13778',
      '13780',
      '13787',
      '13801',
      '13809',
      '13813',
      '13815',
      '13830',
      '13832',
      '13841',
      '13843',
      '13844'
    ],
    '089': [
      '12847',
      '12914',
      '12922',
      '12927',
      '12930',
      '12965',
      '12967',
      '12973',
      '12980',
      '12986',
      '13608',
      '13613',
      '13614',
      '13617',
      '13621',
      '13623',
      '13625',
      '13630',
      '13633',
      '13635',
      '13639',
      '13642',
      '13646',
      '13647',
      '13648',
      '13652',
      '13654',
      '13655',
      '13658',
      '13660',
      '13662',
      '13664',
      '13666',
      '13667',
      '13668',
      '13669',
      '13670',
      '13672',
      '13676',
      '13677',
      '13678',
      '13679',
      '13680',
      '13681',
      '13684',
      '13687',
      '13690',
      '13694',
      '13695',
      '13696',
      '13697'
    ],
    '045': [
      '13083',
      '13601',
      '13602',
      '13603',
      '13605',
      '13606',
      '13607',
      '13608',
      '13612',
      '13615',
      '13616',
      '13618',
      '13619',
      '13622',
      '13624',
      '13626',
      '13628',
      '13634',
      '13636',
      '13637',
      '13638',
      '13640',
      '13641',
      '13642',
      '13643',
      '13646',
      '13650',
      '13651',
      '13656',
      '13659',
      '13661',
      '13665',
      '13673',
      '13674',
      '13675',
      '13679',
      '13682',
      '13685',
      '13691',
      '13692',
      '13693'
    ],
    '107': [
      '13732',
      '13734',
      '13736',
      '13743',
      '13760',
      '13811',
      '13812',
      '13827',
      '13835',
      '13845',
      '13864',
      '14817',
      '14859',
      '14883',
      '14889',
      '14892'
    ],
    '081': [
      '11001',
      '11003',
      '11004',
      '11005',
      '11040',
      '11101',
      '11102',
      '11103',
      '11104',
      '11105',
      '11106',
      '11109',
      '11351',
      '11354',
      '11355',
      '11356',
      '11357',
      '11358',
      '11359',
      '11360',
      '11361',
      '11362',
      '11363',
      '11364',
      '11365',
      '11366',
      '11367',
      '11368',
      '11369',
      '11370',
      '11371',
      '11372',
      '11373',
      '11374',
      '11375',
      '11377',
      '11378',
      '11379',
      '11385',
      '11411',
      '11412',
      '11413',
      '11414',
      '11415',
      '11416',
      '11417',
      '11418',
      '11419',
      '11420',
      '11421',
      '11422',
      '11423',
      '11424',
      '11426',
      '11427',
      '11428',
      '11429',
      '11430',
      '11432',
      '11433',
      '11434',
      '11435',
      '11436',
      '11451',
      '11691',
      '11692',
      '11693',
      '11694',
      '11697'
    ],
    '121': [
      '14004',
      '14009',
      '14011',
      '14024',
      '14030',
      '14037',
      '14039',
      '14040',
      '14054',
      '14066',
      '14082',
      '14113',
      '14130',
      '14145',
      '14167',
      '14427',
      '14525',
      '14530',
      '14533',
      '14536',
      '14549',
      '14550',
      '14569',
      '14591',
      '14735'
    ],
    '057': [
      '12010',
      '12053',
      '12066',
      '12068',
      '12069',
      '12070',
      '12072',
      '12086',
      '12095',
      '12137',
      '12166',
      '12177',
      '13317',
      '13320',
      '13339',
      '13410',
      '13428',
      '13452',
      '13459'
    ],
    '001': [
      '12007',
      '12009',
      '12023',
      '12041',
      '12045',
      '12046',
      '12047',
      '12053',
      '12054',
      '12059',
      '12067',
      '12077',
      '12083',
      '12084',
      '12085',
      '12087',
      '12110',
      '12120',
      '12122',
      '12143',
      '12147',
      '12157',
      '12158',
      '12159',
      '12161',
      '12183',
      '12186',
      '12189',
      '12193',
      '12202',
      '12203',
      '12204',
      '12205',
      '12206',
      '12207',
      '12208',
      '12209',
      '12210',
      '12211',
      '12222',
      '12303',
      '12304',
      '12306',
      '12309',
      '12469'
    ],
    '103': [
      '06390',
      '11701',
      '11702',
      '11703',
      '11704',
      '11705',
      '11706',
      '11713',
      '11715',
      '11716',
      '11717',
      '11718',
      '11719',
      '11720',
      '11721',
      '11722',
      '11724',
      '11725',
      '11726',
      '11727',
      '11729',
      '11730',
      '11731',
      '11733',
      '11735',
      '11738',
      '11739',
      '11740',
      '11741',
      '11742',
      '11743',
      '11746',
      '11747',
      '11749',
      '11751',
      '11752',
      '11754',
      '11755',
      '11757',
      '11763',
      '11764',
      '11766',
      '11767',
      '11768',
      '11769',
      '11770',
      '11772',
      '11776',
      '11777',
      '11778',
      '11779',
      '11780',
      '11782',
      '11784',
      '11786',
      '11787',
      '11788',
      '11789',
      '11790',
      '11792',
      '11794',
      '11795',
      '11796',
      '11798',
      '11901',
      '11930',
      '11931',
      '11932',
      '11933',
      '11934',
      '11935',
      '11937',
      '11939',
      '11940',
      '11941',
      '11942',
      '11944',
      '11946',
      '11947',
      '11948',
      '11949',
      '11950',
      '11951',
      '11952',
      '11953',
      '11954',
      '11955',
      '11956',
      '11957',
      '11958',
      '11959',
      '11960',
      '11961',
      '11962',
      '11963',
      '11964',
      '11965',
      '11967',
      '11968',
      '11970',
      '11971',
      '11972',
      '11973',
      '11975',
      '11976',
      '11977',
      '11978',
      '11980'
    ],
    '095': [
      '12023',
      '12031',
      '12035',
      '12036',
      '12043',
      '12053',
      '12064',
      '12066',
      '12071',
      '12076',
      '12092',
      '12093',
      '12122',
      '12131',
      '12149',
      '12157',
      '12160',
      '12166',
      '12167',
      '12175',
      '12187',
      '12194',
      '12469',
      '13459'
    ],
    '087': [
      '10901',
      '10913',
      '10920',
      '10923',
      '10927',
      '10931',
      '10952',
      '10954',
      '10956',
      '10960',
      '10962',
      '10964',
      '10965',
      '10968',
      '10970',
      '10974',
      '10976',
      '10977',
      '10980',
      '10983',
      '10984',
      '10986',
      '10989',
      '10993',
      '10994'
    ],
    '115': [
      '12028',
      '12057',
      '12094',
      '12154',
      '12185',
      '12804',
      '12809',
      '12816',
      '12819',
      '12821',
      '12823',
      '12827',
      '12828',
      '12832',
      '12834',
      '12837',
      '12838',
      '12839',
      '12841',
      '12844',
      '12849',
      '12861',
      '12865',
      '12873',
      '12887'
    ],
    '041': [
      '12032',
      '12108',
      '12134',
      '12139',
      '12164',
      '12190',
      '12812',
      '12842',
      '12847',
      '12864',
      '13353',
      '13360',
      '13436'
    ],
    '005': [
      '10451',
      '10452',
      '10453',
      '10454',
      '10455',
      '10456',
      '10457',
      '10458',
      '10459',
      '10460',
      '10461',
      '10462',
      '10463',
      '10464',
      '10465',
      '10466',
      '10467',
      '10468',
      '10469',
      '10470',
      '10471',
      '10472',
      '10473',
      '10474',
      '10475',
      '11370'
    ],
    '051': [
      '14414',
      '14423',
      '14435',
      '14437',
      '14454',
      '14462',
      '14466',
      '14471',
      '14472',
      '14480',
      '14481',
      '14482',
      '14485',
      '14486',
      '14487',
      '14510',
      '14517',
      '14525',
      '14533',
      '14539',
      '14545',
      '14560',
      '14572',
      '14592',
      '14822',
      '14836',
      '14846'
    ],
    '077': [
      '12036',
      '12043',
      '12064',
      '12116',
      '12155',
      '12197',
      '13315',
      '13317',
      '13320',
      '13326',
      '13333',
      '13335',
      '13337',
      '13342',
      '13348',
      '13357',
      '13361',
      '13411',
      '13415',
      '13439',
      '13450',
      '13459',
      '13468',
      '13485',
      '13488',
      '13491',
      '13733',
      '13776',
      '13796',
      '13807',
      '13808',
      '13809',
      '13810',
      '13820',
      '13825',
      '13834',
      '13838',
      '13843',
      '13849',
      '13859',
      '13861'
    ],
    '027': [
      '12501',
      '12504',
      '12507',
      '12508',
      '12512',
      '12514',
      '12522',
      '12524',
      '12527',
      '12531',
      '12533',
      '12538',
      '12540',
      '12545',
      '12546',
      '12564',
      '12567',
      '12569',
      '12570',
      '12571',
      '12572',
      '12574',
      '12578',
      '12580',
      '12581',
      '12582',
      '12583',
      '12585',
      '12590',
      '12592',
      '12594',
      '12601',
      '12603',
      '12604'
    ],
    '069': [
      '14424',
      '14425',
      '14432',
      '14456',
      '14466',
      '14469',
      '14471',
      '14472',
      '14475',
      '14485',
      '14487',
      '14489',
      '14504',
      '14512',
      '14513',
      '14522',
      '14532',
      '14534',
      '14537',
      '14544',
      '14548',
      '14560',
      '14561',
      '14564',
      '14585'
    ],
    '093': [
      '12008',
      '12010',
      '12019',
      '12027',
      '12053',
      '12056',
      '12066',
      '12137',
      '12150',
      '12157',
      '12302',
      '12303',
      '12304',
      '12305',
      '12306',
      '12307',
      '12308',
      '12309'
    ],
    '067': [
      '13020',
      '13021',
      '13027',
      '13029',
      '13030',
      '13031',
      '13035',
      '13037',
      '13039',
      '13041',
      '13051',
      '13052',
      '13057',
      '13060',
      '13063',
      '13066',
      '13077',
      '13078',
      '13080',
      '13082',
      '13084',
      '13088',
      '13090',
      '13104',
      '13108',
      '13110',
      '13112',
      '13116',
      '13120',
      '13122',
      '13135',
      '13138',
      '13141',
      '13152',
      '13153',
      '13158',
      '13159',
      '13164',
      '13166',
      '13202',
      '13203',
      '13204',
      '13205',
      '13206',
      '13207',
      '13208',
      '13209',
      '13210',
      '13211',
      '13212',
      '13214',
      '13215',
      '13219',
      '13224',
      '13290'
    ],
    '105': [
      '10940',
      '12458',
      '12483',
      '12566',
      '12701',
      '12719',
      '12720',
      '12721',
      '12722',
      '12723',
      '12724',
      '12725',
      '12726',
      '12729',
      '12732',
      '12733',
      '12734',
      '12736',
      '12737',
      '12738',
      '12740',
      '12741',
      '12742',
      '12743',
      '12745',
      '12747',
      '12748',
      '12749',
      '12750',
      '12751',
      '12752',
      '12754',
      '12758',
      '12759',
      '12760',
      '12762',
      '12763',
      '12764',
      '12765',
      '12766',
      '12767',
      '12768',
      '12769',
      '12770',
      '12775',
      '12776',
      '12777',
      '12778',
      '12779',
      '12780',
      '12781',
      '12783',
      '12784',
      '12785',
      '12786',
      '12787',
      '12788',
      '12789',
      '12790',
      '12791',
      '12792'
    ],
    '113': [
      '12801',
      '12804',
      '12808',
      '12810',
      '12811',
      '12814',
      '12815',
      '12817',
      '12824',
      '12835',
      '12836',
      '12843',
      '12844',
      '12845',
      '12846',
      '12853',
      '12856',
      '12857',
      '12860',
      '12862',
      '12870',
      '12874',
      '12878',
      '12885',
      '12886'
    ],
    '071': [
      '10910',
      '10911',
      '10914',
      '10915',
      '10916',
      '10917',
      '10918',
      '10919',
      '10921',
      '10922',
      '10924',
      '10925',
      '10926',
      '10928',
      '10930',
      '10932',
      '10933',
      '10940',
      '10941',
      '10950',
      '10953',
      '10958',
      '10963',
      '10969',
      '10973',
      '10975',
      '10979',
      '10985',
      '10987',
      '10988',
      '10990',
      '10992',
      '10996',
      '10998',
      '12518',
      '12520',
      '12542',
      '12543',
      '12549',
      '12550',
      '12553',
      '12566',
      '12575',
      '12577',
      '12586',
      '12589',
      '12721',
      '12729',
      '12746',
      '12771',
      '12780',
      '12785'
    ],
    '075': [
      '13028',
      '13036',
      '13042',
      '13044',
      '13069',
      '13074',
      '13076',
      '13083',
      '13103',
      '13111',
      '13114',
      '13115',
      '13126',
      '13131',
      '13132',
      '13135',
      '13142',
      '13144',
      '13145',
      '13156',
      '13167',
      '13302',
      '13316',
      '13437',
      '13493',
      '13661'
    ],
    '047': [
      '11201',
      '11203',
      '11204',
      '11205',
      '11206',
      '11207',
      '11208',
      '11209',
      '11210',
      '11211',
      '11212',
      '11213',
      '11214',
      '11215',
      '11216',
      '11217',
      '11218',
      '11219',
      '11220',
      '11221',
      '11222',
      '11223',
      '11224',
      '11225',
      '11226',
      '11228',
      '11229',
      '11230',
      '11231',
      '11232',
      '11233',
      '11234',
      '11235',
      '11236',
      '11237',
      '11238',
      '11239',
      '11425'
    ],
    '007': [
      '13730',
      '13736',
      '13744',
      '13746',
      '13748',
      '13754',
      '13760',
      '13777',
      '13778',
      '13787',
      '13790',
      '13794',
      '13795',
      '13797',
      '13802',
      '13803',
      '13811',
      '13813',
      '13826',
      '13833',
      '13835',
      '13850',
      '13862',
      '13865',
      '13901',
      '13902',
      '13903',
      '13904',
      '13905'
    ],
    '023': [
      '13040',
      '13045',
      '13052',
      '13053',
      '13077',
      '13087',
      '13101',
      '13141',
      '13158',
      '13159',
      '13784',
      '13803',
      '13835',
      '13841',
      '13862',
      '13863'
    ],
    '043': [
      '13304',
      '13322',
      '13324',
      '13329',
      '13331',
      '13338',
      '13339',
      '13340',
      '13350',
      '13357',
      '13361',
      '13365',
      '13367',
      '13406',
      '13407',
      '13413',
      '13416',
      '13420',
      '13431',
      '13438',
      '13439',
      '13454',
      '13456',
      '13470',
      '13472',
      '13475',
      '13491',
      '13502'
    ],
    '101': [
      '14418',
      '14437',
      '14512',
      '14529',
      '14572',
      '14801',
      '14803',
      '14804',
      '14806',
      '14807',
      '14808',
      '14809',
      '14810',
      '14812',
      '14814',
      '14815',
      '14819',
      '14820',
      '14821',
      '14823',
      '14826',
      '14827',
      '14830',
      '14837',
      '14839',
      '14840',
      '14843',
      '14855',
      '14856',
      '14858',
      '14870',
      '14871',
      '14873',
      '14874',
      '14877',
      '14879',
      '14885',
      '14893',
      '14897',
      '14898'
    ],
    '065': [
      '13042',
      '13054',
      '13123',
      '13157',
      '13162',
      '13301',
      '13303',
      '13304',
      '13308',
      '13309',
      '13313',
      '13316',
      '13318',
      '13319',
      '13321',
      '13322',
      '13323',
      '13328',
      '13338',
      '13341',
      '13352',
      '13354',
      '13362',
      '13363',
      '13402',
      '13403',
      '13413',
      '13417',
      '13421',
      '13424',
      '13425',
      '13431',
      '13435',
      '13438',
      '13440',
      '13441',
      '13456',
      '13461',
      '13469',
      '13471',
      '13476',
      '13477',
      '13478',
      '13480',
      '13483',
      '13486',
      '13489',
      '13490',
      '13492',
      '13494',
      '13495',
      '13501',
      '13502'
    ],
    '061': [
      '10001',
      '10002',
      '10003',
      '10004',
      '10005',
      '10006',
      '10007',
      '10009',
      '10010',
      '10011',
      '10012',
      '10013',
      '10014',
      '10016',
      '10017',
      '10018',
      '10019',
      '10020',
      '10021',
      '10022',
      '10023',
      '10024',
      '10025',
      '10026',
      '10027',
      '10028',
      '10029',
      '10030',
      '10031',
      '10032',
      '10033',
      '10034',
      '10035',
      '10036',
      '10037',
      '10038',
      '10039',
      '10040',
      '10044',
      '10065',
      '10069',
      '10075',
      '10103',
      '10110',
      '10111',
      '10112',
      '10115',
      '10119',
      '10128',
      '10152',
      '10153',
      '10154',
      '10162',
      '10165',
      '10167',
      '10168',
      '10169',
      '10170',
      '10171',
      '10172',
      '10173',
      '10174',
      '10177',
      '10199',
      '10271',
      '10278',
      '10279',
      '10280',
      '10282',
      '10463'
    ],
    '049': [
      '13305',
      '13309',
      '13312',
      '13316',
      '13325',
      '13327',
      '13343',
      '13345',
      '13367',
      '13368',
      '13404',
      '13433',
      '13437',
      '13471',
      '13473',
      '13489',
      '13601',
      '13619',
      '13620',
      '13626',
      '13648',
      '13665',
      '13682'
    ],
    '019': [
      '12901',
      '12903',
      '12910',
      '12911',
      '12912',
      '12918',
      '12919',
      '12920',
      '12921',
      '12923',
      '12924',
      '12929',
      '12933',
      '12934',
      '12935',
      '12944',
      '12952',
      '12955',
      '12958',
      '12959',
      '12962',
      '12972',
      '12978',
      '12979',
      '12981',
      '12985',
      '12992'
    ],
    '015': [
      '14812',
      '14814',
      '14816',
      '14824',
      '14825',
      '14830',
      '14838',
      '14845',
      '14859',
      '14861',
      '14864',
      '14871',
      '14872',
      '14889',
      '14892',
      '14894',
      '14901',
      '14903',
      '14904',
      '14905'
    ],
    '083': [
      '12018',
      '12022',
      '12028',
      '12033',
      '12040',
      '12052',
      '12057',
      '12061',
      '12062',
      '12063',
      '12089',
      '12090',
      '12094',
      '12118',
      '12121',
      '12123',
      '12138',
      '12140',
      '12144',
      '12153',
      '12154',
      '12156',
      '12168',
      '12169',
      '12180',
      '12182',
      '12184',
      '12185',
      '12196',
      '12198'
    ],
    '013': [
      '14048',
      '14062',
      '14063',
      '14081',
      '14135',
      '14136',
      '14138',
      '14701',
      '14710',
      '14712',
      '14716',
      '14718',
      '14720',
      '14722',
      '14723',
      '14724',
      '14726',
      '14728',
      '14732',
      '14733',
      '14736',
      '14738',
      '14740',
      '14742',
      '14747',
      '14750',
      '14752',
      '14756',
      '14757',
      '14767',
      '14769',
      '14775',
      '14781',
      '14782',
      '14784',
      '14787'
    ],
    '073': [
      '14058',
      '14098',
      '14103',
      '14105',
      '14125',
      '14411',
      '14420',
      '14422',
      '14464',
      '14470',
      '14476',
      '14477',
      '14479',
      '14571'
    ],
    '003': [
      '14024',
      '14060',
      '14065',
      '14536',
      '14707',
      '14708',
      '14709',
      '14711',
      '14714',
      '14715',
      '14717',
      '14721',
      '14727',
      '14735',
      '14739',
      '14744',
      '14754',
      '14770',
      '14774',
      '14777',
      '14802',
      '14803',
      '14804',
      '14806',
      '14807',
      '14813',
      '14822',
      '14836',
      '14843',
      '14846',
      '14877',
      '14880',
      '14884',
      '14895',
      '14897'
    ]
  },
  '17': {
    '167': [
      '62515',
      '62520',
      '62530',
      '62536',
      '62539',
      '62545',
      '62548',
      '62558',
      '62561',
      '62563',
      '62613',
      '62615',
      '62625',
      '62629',
      '62661',
      '62670',
      '62677',
      '62684',
      '62689',
      '62690',
      '62692',
      '62693',
      '62701',
      '62702',
      '62703',
      '62704',
      '62707',
      '62711',
      '62712'
    ],
    '079': [
      '62420',
      '62424',
      '62425',
      '62432',
      '62434',
      '62436',
      '62445',
      '62448',
      '62449',
      '62459',
      '62467',
      '62475',
      '62479',
      '62480',
      '62481'
    ],
    '131': [
      '61231',
      '61260',
      '61262',
      '61263',
      '61264',
      '61272',
      '61276',
      '61279',
      '61281',
      '61412',
      '61442',
      '61465',
      '61466',
      '61476',
      '61486'
    ],
    '067': [
      '61420',
      '61450',
      '62311',
      '62313',
      '62316',
      '62321',
      '62326',
      '62330',
      '62334',
      '62336',
      '62341',
      '62349',
      '62351',
      '62354',
      '62358',
      '62367',
      '62373',
      '62374',
      '62379',
      '62380'
    ],
    '119': [
      '62001',
      '62002',
      '62010',
      '62012',
      '62018',
      '62021',
      '62024',
      '62025',
      '62034',
      '62035',
      '62040',
      '62046',
      '62048',
      '62058',
      '62060',
      '62061',
      '62062',
      '62067',
      '62074',
      '62084',
      '62087',
      '62088',
      '62090',
      '62095',
      '62097',
      '62201',
      '62234',
      '62249',
      '62275',
      '62281',
      '62293',
      '62294'
    ],
    '153': [
      '62923',
      '62926',
      '62941',
      '62956',
      '62963',
      '62964',
      '62970',
      '62976',
      '62992',
      '62996'
    ],
    '055': [
      '62812',
      '62819',
      '62822',
      '62825',
      '62836',
      '62856',
      '62860',
      '62865',
      '62874',
      '62883',
      '62884',
      '62890',
      '62891',
      '62896',
      '62897',
      '62983',
      '62999'
    ],
    '109': [
      '61411',
      '61416',
      '61420',
      '61422',
      '61438',
      '61440',
      '61450',
      '61455',
      '61459',
      '61470',
      '61475',
      '61482',
      '61484',
      '62326',
      '62367',
      '62374'
    ],
    '053': [
      '60917',
      '60919',
      '60921',
      '60929',
      '60933',
      '60936',
      '60946',
      '60948',
      '60949',
      '60952',
      '60957',
      '60959',
      '60960',
      '60962',
      '60968',
      '61731',
      '61773',
      '61845'
    ],
    '063': [
      '60407',
      '60410',
      '60416',
      '60420',
      '60424',
      '60437',
      '60444',
      '60447',
      '60450',
      '60470',
      '60474',
      '60479',
      '60481',
      '60541',
      '60961',
      '61360'
    ],
    '171': [
      '62082',
      '62610',
      '62621',
      '62628',
      '62650',
      '62663',
      '62665',
      '62668',
      '62694'
    ],
    '085': [
      '61001',
      '61025',
      '61028',
      '61036',
      '61041',
      '61044',
      '61048',
      '61053',
      '61059',
      '61062',
      '61074',
      '61075',
      '61085',
      '61087'
    ],
    '059': [
      '62867',
      '62869',
      '62871',
      '62930',
      '62931',
      '62934',
      '62954',
      '62979',
      '62984'
    ],
    '137': [
      '62601',
      '62611',
      '62612',
      '62621',
      '62628',
      '62631',
      '62638',
      '62650',
      '62665',
      '62667',
      '62668',
      '62670',
      '62692',
      '62694',
      '62695'
    ],
    '111': [
      '60010',
      '60012',
      '60013',
      '60014',
      '60021',
      '60033',
      '60034',
      '60042',
      '60050',
      '60051',
      '60071',
      '60072',
      '60081',
      '60097',
      '60098',
      '60102',
      '60135',
      '60142',
      '60152',
      '60156',
      '60180',
      '61038'
    ],
    '165': [
      '62869',
      '62890',
      '62917',
      '62930',
      '62934',
      '62935',
      '62946',
      '62947',
      '62965',
      '62977',
      '62987'
    ],
    '193': [
      '62820',
      '62821',
      '62827',
      '62835',
      '62844',
      '62861',
      '62862',
      '62867',
      '62869',
      '62887'
    ],
    '121': [
      '62801',
      '62807',
      '62830',
      '62838',
      '62849',
      '62851',
      '62853',
      '62854',
      '62870',
      '62875',
      '62881',
      '62882',
      '62889',
      '62892',
      '62893'
    ],
    '117': [
      '62002',
      '62009',
      '62012',
      '62014',
      '62023',
      '62033',
      '62044',
      '62056',
      '62063',
      '62069',
      '62074',
      '62079',
      '62085',
      '62088',
      '62093',
      '62560',
      '62572',
      '62626',
      '62630',
      '62638',
      '62640',
      '62649',
      '62667',
      '62672',
      '62674',
      '62685',
      '62690'
    ],
    '035': [
      '62420',
      '62428',
      '62436',
      '62440',
      '62445',
      '62447',
      '62462',
      '62467',
      '62468',
      '62469',
      '62481'
    ],
    '033': [
      '62413',
      '62421',
      '62427',
      '62433',
      '62442',
      '62449',
      '62451',
      '62454',
      '62466',
      '62478'
    ],
    '037': [
      '60111',
      '60112',
      '60115',
      '60129',
      '60135',
      '60140',
      '60145',
      '60146',
      '60150',
      '60151',
      '60152',
      '60178',
      '60511',
      '60518',
      '60520',
      '60530',
      '60531',
      '60548',
      '60550',
      '60552',
      '60556',
      '61068'
    ],
    '159': [
      '62419',
      '62421',
      '62425',
      '62448',
      '62450',
      '62452',
      '62466',
      '62868'
    ],
    '147': [
      '61813',
      '61818',
      '61830',
      '61839',
      '61842',
      '61851',
      '61853',
      '61854',
      '61855',
      '61856',
      '61884',
      '61913',
      '61929',
      '61936'
    ],
    '145': [
      '62237',
      '62238',
      '62263',
      '62268',
      '62272',
      '62274',
      '62831',
      '62832',
      '62883',
      '62888',
      '62916',
      '62997'
    ],
    '177': [
      '61007',
      '61013',
      '61018',
      '61019',
      '61027',
      '61032',
      '61039',
      '61044',
      '61047',
      '61048',
      '61050',
      '61060',
      '61062',
      '61063',
      '61067',
      '61070',
      '61078',
      '61087',
      '61089'
    ],
    '191': [
      '62446',
      '62450',
      '62809',
      '62810',
      '62814',
      '62820',
      '62823',
      '62824',
      '62833',
      '62837',
      '62842',
      '62843',
      '62850',
      '62851',
      '62868',
      '62878',
      '62886',
      '62895',
      '62899'
    ],
    '163': [
      '62059',
      '62060',
      '62201',
      '62203',
      '62204',
      '62205',
      '62206',
      '62207',
      '62208',
      '62220',
      '62221',
      '62223',
      '62225',
      '62226',
      '62232',
      '62234',
      '62236',
      '62239',
      '62240',
      '62243',
      '62254',
      '62255',
      '62257',
      '62258',
      '62260',
      '62264',
      '62265',
      '62269',
      '62282',
      '62285',
      '62289',
      '62293',
      '62294',
      '62298'
    ],
    '179': [
      '61530',
      '61534',
      '61535',
      '61546',
      '61550',
      '61554',
      '61564',
      '61568',
      '61571',
      '61610',
      '61611',
      '61721',
      '61732',
      '61733',
      '61734',
      '61742',
      '61747',
      '61755',
      '61759',
      '62635',
      '62682'
    ],
    '057': [
      '61415',
      '61427',
      '61431',
      '61432',
      '61433',
      '61441',
      '61459',
      '61477',
      '61482',
      '61484',
      '61501',
      '61519',
      '61520',
      '61524',
      '61531',
      '61533',
      '61542',
      '61543',
      '61544',
      '61553',
      '61563',
      '61569',
      '62644'
    ],
    '203': [
      '61516',
      '61530',
      '61545',
      '61548',
      '61561',
      '61570',
      '61611',
      '61725',
      '61729',
      '61733',
      '61738',
      '61742',
      '61744',
      '61748',
      '61760',
      '61771'
    ],
    '103': [
      '60518',
      '60530',
      '60553',
      '61006',
      '61021',
      '61031',
      '61042',
      '61057',
      '61064',
      '61068',
      '61071',
      '61081',
      '61310',
      '61318',
      '61324',
      '61330',
      '61331',
      '61342',
      '61349',
      '61353',
      '61367',
      '61376',
      '61378'
    ],
    '095': [
      '61401',
      '61410',
      '61414',
      '61428',
      '61430',
      '61434',
      '61436',
      '61439',
      '61448',
      '61449',
      '61458',
      '61467',
      '61472',
      '61474',
      '61485',
      '61488',
      '61489',
      '61490',
      '61529',
      '61531',
      '61544',
      '61572'
    ],
    '051': [
      '62011',
      '62032',
      '62075',
      '62080',
      '62253',
      '62262',
      '62411',
      '62414',
      '62418',
      '62422',
      '62426',
      '62431',
      '62458',
      '62471',
      '62838',
      '62875',
      '62880',
      '62885',
      '62892'
    ],
    '009': [
      '62323',
      '62324',
      '62353',
      '62375',
      '62378'
    ],
    '173': [
      '61914',
      '61928',
      '61957',
      '62401',
      '62414',
      '62422',
      '62431',
      '62438',
      '62444',
      '62447',
      '62461',
      '62462',
      '62463',
      '62465',
      '62510',
      '62534',
      '62550',
      '62553',
      '62557',
      '62565',
      '62571'
    ],
    '133': [
      '62236',
      '62244',
      '62248',
      '62264',
      '62277',
      '62278',
      '62279',
      '62295',
      '62298'
    ],
    '151': [
      '62910',
      '62928',
      '62938',
      '62943',
      '62946',
      '62947',
      '62960',
      '62972',
      '62985',
      '62987'
    ],
    '071': [
      '61418',
      '61420',
      '61425',
      '61437',
      '61442',
      '61447',
      '61450',
      '61453',
      '61454',
      '61460',
      '61462',
      '61469',
      '61471',
      '61476',
      '61478',
      '61480',
      '62330'
    ],
    '141': [
      '60113',
      '60129',
      '60146',
      '61006',
      '61007',
      '61010',
      '61015',
      '61020',
      '61021',
      '61030',
      '61031',
      '61039',
      '61043',
      '61047',
      '61049',
      '61051',
      '61052',
      '61054',
      '61061',
      '61064',
      '61068',
      '61078',
      '61084',
      '61091',
      '61102',
      '61109'
    ],
    '047': [
      '62476',
      '62806',
      '62815',
      '62818',
      '62833',
      '62844'
    ],
    '197': [
      '60401',
      '60403',
      '60404',
      '60407',
      '60408',
      '60410',
      '60416',
      '60417',
      '60421',
      '60423',
      '60431',
      '60432',
      '60433',
      '60435',
      '60436',
      '60439',
      '60440',
      '60441',
      '60442',
      '60446',
      '60447',
      '60448',
      '60449',
      '60451',
      '60466',
      '60467',
      '60468',
      '60471',
      '60475',
      '60477',
      '60481',
      '60484',
      '60487',
      '60490',
      '60491',
      '60503',
      '60543',
      '60544',
      '60564',
      '60565',
      '60585',
      '60586',
      '60940',
      '60950'
    ],
    '195': [
      '61014',
      '61037',
      '61064',
      '61071',
      '61081',
      '61230',
      '61243',
      '61250',
      '61251',
      '61252',
      '61261',
      '61270',
      '61277',
      '61283'
    ],
    '023': [
      '61933',
      '61944',
      '62413',
      '62420',
      '62423',
      '62441',
      '62442',
      '62449',
      '62474',
      '62477',
      '62478'
    ],
    '043': [
      '60007',
      '60101',
      '60103',
      '60106',
      '60108',
      '60126',
      '60133',
      '60137',
      '60139',
      '60143',
      '60148',
      '60157',
      '60172',
      '60174',
      '60181',
      '60184',
      '60185',
      '60187',
      '60188',
      '60189',
      '60190',
      '60191',
      '60439',
      '60440',
      '60502',
      '60504',
      '60510',
      '60514',
      '60515',
      '60516',
      '60517',
      '60519',
      '60521',
      '60523',
      '60527',
      '60532',
      '60540',
      '60555',
      '60559',
      '60561',
      '60563',
      '60564',
      '60565'
    ],
    '065': [
      '62810',
      '62817',
      '62828',
      '62829',
      '62835',
      '62859',
      '62860',
      '62869',
      '62887',
      '62890',
      '62895',
      '62935'
    ],
    '139': [
      '61911',
      '61914',
      '61925',
      '61928',
      '61937',
      '61951',
      '61957',
      '62534'
    ],
    '013': [
      '62006',
      '62013',
      '62036',
      '62045',
      '62047',
      '62053',
      '62065',
      '62070',
      '62355',
      '62366'
    ],
    '003': [
      '62914',
      '62952',
      '62957',
      '62962',
      '62969',
      '62988',
      '62990',
      '62992'
    ],
    '123': [
      '61336',
      '61358',
      '61369',
      '61375',
      '61377',
      '61421',
      '61424',
      '61479',
      '61491',
      '61523',
      '61526',
      '61537',
      '61540',
      '61541',
      '61565',
      '61570',
      '61760'
    ],
    '099': [
      '60470',
      '60518',
      '60531',
      '60537',
      '60541',
      '60548',
      '60549',
      '60551',
      '60552',
      '60557',
      '61301',
      '61316',
      '61321',
      '61325',
      '61332',
      '61334',
      '61341',
      '61342',
      '61348',
      '61350',
      '61354',
      '61358',
      '61360',
      '61364',
      '61370',
      '61372',
      '61373',
      '61377'
    ],
    '183': [
      '60932',
      '60942',
      '60960',
      '60963',
      '61810',
      '61811',
      '61812',
      '61814',
      '61817',
      '61831',
      '61832',
      '61833',
      '61834',
      '61841',
      '61844',
      '61846',
      '61848',
      '61849',
      '61850',
      '61857',
      '61858',
      '61859',
      '61862',
      '61865',
      '61870',
      '61876',
      '61883'
    ],
    '029': [
      '61910',
      '61911',
      '61912',
      '61920',
      '61928',
      '61930',
      '61931',
      '61933',
      '61938',
      '61943',
      '61951',
      '62420',
      '62440',
      '62447',
      '62469',
      '62474'
    ],
    '021': [
      '62075',
      '62083',
      '62510',
      '62513',
      '62517',
      '62531',
      '62538',
      '62540',
      '62545',
      '62546',
      '62547',
      '62550',
      '62555',
      '62556',
      '62557',
      '62558',
      '62563',
      '62567',
      '62568',
      '62570'
    ],
    '181': [
      '62902',
      '62905',
      '62906',
      '62912',
      '62920',
      '62923',
      '62926',
      '62939',
      '62952',
      '62957',
      '62958',
      '62961',
      '62998'
    ],
    '031': [
      '60004',
      '60005',
      '60007',
      '60008',
      '60010',
      '60015',
      '60016',
      '60018',
      '60022',
      '60025',
      '60026',
      '60029',
      '60043',
      '60053',
      '60056',
      '60062',
      '60067',
      '60068',
      '60070',
      '60074',
      '60076',
      '60077',
      '60089',
      '60090',
      '60091',
      '60093',
      '60103',
      '60104',
      '60107',
      '60120',
      '60126',
      '60130',
      '60131',
      '60133',
      '60141',
      '60153',
      '60154',
      '60155',
      '60160',
      '60162',
      '60163',
      '60164',
      '60165',
      '60169',
      '60171',
      '60172',
      '60173',
      '60176',
      '60192',
      '60193',
      '60194',
      '60195',
      '60201',
      '60202',
      '60203',
      '60301',
      '60302',
      '60304',
      '60305',
      '60402',
      '60406',
      '60409',
      '60411',
      '60415',
      '60419',
      '60422',
      '60423',
      '60425',
      '60426',
      '60428',
      '60429',
      '60430',
      '60438',
      '60439',
      '60443',
      '60445',
      '60452',
      '60453',
      '60455',
      '60456',
      '60457',
      '60458',
      '60459',
      '60461',
      '60462',
      '60463',
      '60464',
      '60465',
      '60466',
      '60467',
      '60469',
      '60471',
      '60472',
      '60473',
      '60475',
      '60476',
      '60477',
      '60478',
      '60480',
      '60482',
      '60487',
      '60501',
      '60513',
      '60521',
      '60525',
      '60526',
      '60527',
      '60534',
      '60546',
      '60558',
      '60601',
      '60602',
      '60603',
      '60604',
      '60605',
      '60606',
      '60607',
      '60608',
      '60609',
      '60610',
      '60611',
      '60612',
      '60613',
      '60614',
      '60615',
      '60616',
      '60617',
      '60618',
      '60619',
      '60620',
      '60621',
      '60622',
      '60623',
      '60624',
      '60625',
      '60626',
      '60628',
      '60629',
      '60630',
      '60631',
      '60632',
      '60633',
      '60634',
      '60636',
      '60637',
      '60638',
      '60639',
      '60640',
      '60641',
      '60642',
      '60643',
      '60644',
      '60645',
      '60646',
      '60647',
      '60649',
      '60651',
      '60652',
      '60653',
      '60654',
      '60655',
      '60656',
      '60657',
      '60659',
      '60660',
      '60661',
      '60706',
      '60707',
      '60712',
      '60714',
      '60803',
      '60804',
      '60805',
      '60827'
    ],
    '091': [
      '60401',
      '60424',
      '60468',
      '60481',
      '60901',
      '60910',
      '60913',
      '60914',
      '60915',
      '60917',
      '60919',
      '60922',
      '60935',
      '60940',
      '60941',
      '60950',
      '60954',
      '60958',
      '60961',
      '60964',
      '60969'
    ],
    '025': [
      '62426',
      '62434',
      '62443',
      '62448',
      '62824',
      '62838',
      '62839',
      '62854',
      '62858',
      '62868',
      '62879',
      '62899'
    ],
    '169': [
      '61440',
      '61452',
      '61484',
      '62311',
      '62319',
      '62344',
      '62346',
      '62353',
      '62367',
      '62624',
      '62639',
      '62681'
    ],
    '201': [
      '60146',
      '61008',
      '61011',
      '61016',
      '61019',
      '61020',
      '61024',
      '61047',
      '61063',
      '61072',
      '61073',
      '61077',
      '61079',
      '61080',
      '61084',
      '61088',
      '61101',
      '61102',
      '61103',
      '61104',
      '61107',
      '61108',
      '61109',
      '61111',
      '61112',
      '61114',
      '61115'
    ],
    '039': [
      '61727',
      '61735',
      '61745',
      '61749',
      '61750',
      '61752',
      '61754',
      '61756',
      '61777',
      '61778',
      '61842',
      '61882',
      '62512'
    ],
    '149': [
      '62312',
      '62314',
      '62323',
      '62340',
      '62343',
      '62345',
      '62352',
      '62355',
      '62356',
      '62357',
      '62361',
      '62362',
      '62363',
      '62366',
      '62370'
    ],
    '011': [
      '61283',
      '61312',
      '61314',
      '61315',
      '61317',
      '61320',
      '61322',
      '61323',
      '61328',
      '61329',
      '61330',
      '61337',
      '61338',
      '61342',
      '61344',
      '61345',
      '61346',
      '61349',
      '61354',
      '61356',
      '61359',
      '61361',
      '61362',
      '61368',
      '61374',
      '61376',
      '61379',
      '61421',
      '61443',
      '61537',
      '61560'
    ],
    '041': [
      '61852',
      '61872',
      '61910',
      '61911',
      '61913',
      '61917',
      '61919',
      '61930',
      '61941',
      '61942',
      '61943',
      '61953',
      '61956'
    ],
    '027': [
      '62215',
      '62216',
      '62218',
      '62219',
      '62230',
      '62231',
      '62245',
      '62249',
      '62250',
      '62253',
      '62265',
      '62266',
      '62275',
      '62293',
      '62801',
      '62875',
      '62882'
    ],
    '199': [
      '62841',
      '62890',
      '62896',
      '62901',
      '62902',
      '62915',
      '62917',
      '62918',
      '62921',
      '62922',
      '62924',
      '62933',
      '62939',
      '62948',
      '62949',
      '62951',
      '62958',
      '62959',
      '62974',
      '62987'
    ],
    '143': [
      '61451',
      '61489',
      '61517',
      '61523',
      '61525',
      '61526',
      '61528',
      '61529',
      '61531',
      '61533',
      '61536',
      '61539',
      '61547',
      '61552',
      '61559',
      '61562',
      '61565',
      '61569',
      '61602',
      '61603',
      '61604',
      '61605',
      '61606',
      '61607',
      '61614',
      '61615',
      '61616',
      '61625'
    ],
    '175': [
      '61345',
      '61421',
      '61426',
      '61434',
      '61443',
      '61449',
      '61451',
      '61479',
      '61483',
      '61491',
      '61559'
    ],
    '185': [
      '62410',
      '62460',
      '62476',
      '62811',
      '62818',
      '62852',
      '62863'
    ],
    '089': [
      '60010',
      '60102',
      '60109',
      '60110',
      '60118',
      '60119',
      '60120',
      '60123',
      '60124',
      '60134',
      '60136',
      '60140',
      '60142',
      '60144',
      '60151',
      '60174',
      '60175',
      '60177',
      '60178',
      '60184',
      '60185',
      '60502',
      '60504',
      '60505',
      '60506',
      '60510',
      '60511',
      '60538',
      '60539',
      '60542',
      '60543',
      '60554'
    ],
    '045': [
      '61810',
      '61870',
      '61876',
      '61917',
      '61924',
      '61932',
      '61933',
      '61940',
      '61942',
      '61943',
      '61944',
      '61949',
      '61955',
      '62423',
      '62441'
    ],
    '107': [
      '61721',
      '61723',
      '61749',
      '61751',
      '61754',
      '62512',
      '62518',
      '62519',
      '62541',
      '62543',
      '62548',
      '62613',
      '62634',
      '62635',
      '62643',
      '62656',
      '62666',
      '62671',
      '62682',
      '62693'
    ],
    '155': [
      '61326',
      '61327',
      '61334',
      '61335',
      '61336',
      '61340',
      '61354',
      '61363',
      '61560'
    ],
    '127': [
      '62908',
      '62910',
      '62941',
      '62943',
      '62953',
      '62956',
      '62960'
    ],
    '161': [
      '61201',
      '61232',
      '61236',
      '61239',
      '61240',
      '61242',
      '61244',
      '61256',
      '61257',
      '61259',
      '61260',
      '61264',
      '61265',
      '61272',
      '61273',
      '61275',
      '61278',
      '61279',
      '61281',
      '61282',
      '61284'
    ],
    '081': [
      '62801',
      '62808',
      '62810',
      '62814',
      '62816',
      '62830',
      '62846',
      '62851',
      '62864',
      '62872',
      '62877',
      '62883',
      '62889',
      '62893',
      '62894',
      '62898'
    ],
    '097': [
      '60002',
      '60010',
      '60013',
      '60015',
      '60020',
      '60021',
      '60030',
      '60031',
      '60035',
      '60040',
      '60041',
      '60042',
      '60044',
      '60045',
      '60046',
      '60047',
      '60048',
      '60050',
      '60051',
      '60060',
      '60061',
      '60064',
      '60069',
      '60073',
      '60074',
      '60081',
      '60083',
      '60084',
      '60085',
      '60087',
      '60088',
      '60089',
      '60096',
      '60099'
    ],
    '125': [
      '61532',
      '61546',
      '61567',
      '62617',
      '62627',
      '62633',
      '62644',
      '62655',
      '62664',
      '62671',
      '62682'
    ],
    '001': [
      '62301',
      '62305',
      '62311',
      '62312',
      '62314',
      '62316',
      '62320',
      '62324',
      '62325',
      '62338',
      '62339',
      '62343',
      '62346',
      '62347',
      '62348',
      '62349',
      '62351',
      '62359',
      '62360',
      '62365',
      '62376',
      '62379'
    ],
    '129': [
      '62613',
      '62642',
      '62666',
      '62673',
      '62675',
      '62677',
      '62688'
    ],
    '017': [
      '62611',
      '62612',
      '62618',
      '62622',
      '62627',
      '62673',
      '62691'
    ],
    '087': [
      '62908',
      '62912',
      '62922',
      '62923',
      '62939',
      '62943',
      '62967',
      '62972',
      '62985',
      '62995'
    ],
    '115': [
      '61749',
      '61756',
      '61818',
      '61830',
      '61882',
      '61925',
      '61937',
      '62501',
      '62513',
      '62514',
      '62521',
      '62522',
      '62523',
      '62526',
      '62532',
      '62535',
      '62537',
      '62539',
      '62543',
      '62544',
      '62547',
      '62549',
      '62550',
      '62551',
      '62554',
      '62573'
    ],
    '005': [
      '62019',
      '62074',
      '62077',
      '62086',
      '62246',
      '62253',
      '62262',
      '62273',
      '62275',
      '62284'
    ],
    '189': [
      '62214',
      '62237',
      '62257',
      '62263',
      '62268',
      '62271',
      '62801',
      '62803',
      '62808',
      '62831',
      '62848',
      '62876',
      '62877'
    ],
    '077': [
      '62280',
      '62832',
      '62901',
      '62902',
      '62903',
      '62905',
      '62907',
      '62916',
      '62924',
      '62927',
      '62932',
      '62940',
      '62942',
      '62950',
      '62958',
      '62966',
      '62975',
      '62994'
    ],
    '157': [
      '62217',
      '62233',
      '62237',
      '62241',
      '62242',
      '62257',
      '62261',
      '62272',
      '62277',
      '62278',
      '62280',
      '62286',
      '62288',
      '62292',
      '62297',
      '62907',
      '62916',
      '63673'
    ],
    '069': [
      '62919',
      '62931',
      '62938',
      '62947',
      '62982'
    ],
    '093': [
      '60431',
      '60447',
      '60450',
      '60503',
      '60504',
      '60512',
      '60536',
      '60537',
      '60538',
      '60541',
      '60543',
      '60544',
      '60545',
      '60548',
      '60554',
      '60560',
      '60585',
      '60586'
    ],
    '105': [
      '60420',
      '60460',
      '60917',
      '60919',
      '60920',
      '60921',
      '60929',
      '60934',
      '60946',
      '60952',
      '60959',
      '60961',
      '61311',
      '61313',
      '61319',
      '61333',
      '61364',
      '61726',
      '61731',
      '61739',
      '61740',
      '61741',
      '61743',
      '61744',
      '61764',
      '61769',
      '61775'
    ],
    '113': [
      '60936',
      '61701',
      '61704',
      '61705',
      '61720',
      '61722',
      '61723',
      '61724',
      '61725',
      '61726',
      '61728',
      '61729',
      '61730',
      '61731',
      '61732',
      '61736',
      '61737',
      '61738',
      '61739',
      '61744',
      '61745',
      '61748',
      '61752',
      '61753',
      '61754',
      '61761',
      '61770',
      '61772',
      '61774',
      '61776',
      '61842',
      '61843'
    ],
    '187': [
      '61401',
      '61410',
      '61412',
      '61415',
      '61417',
      '61423',
      '61435',
      '61447',
      '61453',
      '61462',
      '61473',
      '61474',
      '61475',
      '61478'
    ],
    '075': [
      '60911',
      '60912',
      '60918',
      '60922',
      '60924',
      '60926',
      '60927',
      '60928',
      '60930',
      '60931',
      '60938',
      '60941',
      '60942',
      '60945',
      '60948',
      '60951',
      '60953',
      '60955',
      '60960',
      '60964',
      '60966',
      '60968',
      '60970',
      '60973',
      '60974'
    ],
    '007': [
      '60033',
      '60135',
      '60145',
      '60146',
      '61008',
      '61011',
      '61012',
      '61016',
      '61038',
      '61065',
      '61073',
      '61080',
      '61107',
      '61111',
      '61114'
    ],
    '135': [
      '62015',
      '62017',
      '62019',
      '62032',
      '62049',
      '62051',
      '62056',
      '62069',
      '62074',
      '62075',
      '62076',
      '62077',
      '62080',
      '62083',
      '62086',
      '62089',
      '62091',
      '62094',
      '62533',
      '62538',
      '62546',
      '62553',
      '62557',
      '62558',
      '62560',
      '62572',
      '62640',
      '62690'
    ],
    '101': [
      '62417',
      '62427',
      '62439',
      '62460',
      '62466'
    ],
    '061': [
      '62016',
      '62027',
      '62044',
      '62050',
      '62054',
      '62078',
      '62081',
      '62082',
      '62092',
      '62098',
      '62630'
    ],
    '049': [
      '62401',
      '62411',
      '62414',
      '62424',
      '62426',
      '62443',
      '62445',
      '62458',
      '62461',
      '62467',
      '62473',
      '62838',
      '62858'
    ],
    '019': [
      '60936',
      '60949',
      '60957',
      '60960',
      '61801',
      '61802',
      '61810',
      '61815',
      '61816',
      '61820',
      '61821',
      '61822',
      '61840',
      '61843',
      '61845',
      '61847',
      '61849',
      '61851',
      '61852',
      '61853',
      '61859',
      '61862',
      '61863',
      '61864',
      '61866',
      '61871',
      '61872',
      '61873',
      '61874',
      '61875',
      '61877',
      '61878',
      '61880',
      '61884',
      '61956'
    ],
    '083': [
      '62012',
      '62022',
      '62028',
      '62030',
      '62031',
      '62035',
      '62037',
      '62052',
      '62054',
      '62063',
      '62079'
    ],
    '073': [
      '61234',
      '61235',
      '61238',
      '61240',
      '61241',
      '61250',
      '61254',
      '61258',
      '61262',
      '61273',
      '61274',
      '61277',
      '61344',
      '61361',
      '61413',
      '61414',
      '61419',
      '61434',
      '61443',
      '61465',
      '61468',
      '61490'
    ],
    '015': [
      '61014',
      '61046',
      '61051',
      '61053',
      '61062',
      '61074',
      '61078',
      '61252',
      '61285'
    ]
  },
  '53': {
    '009': [
      '98305',
      '98326',
      '98331',
      '98350',
      '98357',
      '98362',
      '98363',
      '98381',
      '98382'
    ],
    '001': [
      '98857',
      '99032',
      '99105',
      '99159',
      '99169',
      '99326',
      '99333',
      '99341',
      '99344',
      '99371'
    ],
    '017': [
      '98802',
      '98812',
      '98813',
      '98816',
      '98823',
      '98830',
      '98843',
      '98845',
      '98848',
      '98850',
      '98858',
      '99115',
      '99116',
      '99133'
    ],
    '055': [
      '98221',
      '98222',
      '98243',
      '98245',
      '98250',
      '98261',
      '98279',
      '98280',
      '98286',
      '98297'
    ],
    '029': [
      '98236',
      '98239',
      '98249',
      '98253',
      '98260',
      '98277',
      '98278',
      '98282'
    ],
    '041': [
      '98304',
      '98336',
      '98355',
      '98356',
      '98361',
      '98377',
      '98531',
      '98532',
      '98533',
      '98538',
      '98542',
      '98544',
      '98564',
      '98565',
      '98568',
      '98570',
      '98572',
      '98579',
      '98582',
      '98585',
      '98591',
      '98593',
      '98596'
    ],
    '005': [
      '98930',
      '98944',
      '99320',
      '99336',
      '99337',
      '99338',
      '99345',
      '99346',
      '99350',
      '99352',
      '99353',
      '99354'
    ],
    '053': [
      '98022',
      '98047',
      '98092',
      '98303',
      '98304',
      '98321',
      '98323',
      '98327',
      '98328',
      '98329',
      '98330',
      '98332',
      '98333',
      '98335',
      '98338',
      '98349',
      '98351',
      '98354',
      '98360',
      '98371',
      '98372',
      '98373',
      '98374',
      '98375',
      '98385',
      '98387',
      '98388',
      '98390',
      '98391',
      '98394',
      '98396',
      '98402',
      '98403',
      '98404',
      '98405',
      '98406',
      '98407',
      '98408',
      '98409',
      '98416',
      '98418',
      '98421',
      '98422',
      '98424',
      '98430',
      '98433',
      '98438',
      '98439',
      '98443',
      '98444',
      '98445',
      '98446',
      '98447',
      '98465',
      '98466',
      '98467',
      '98498',
      '98499',
      '98558',
      '98580'
    ],
    '031': [
      '98320',
      '98325',
      '98331',
      '98339',
      '98358',
      '98365',
      '98368',
      '98376',
      '98382'
    ],
    '077': [
      '98901',
      '98902',
      '98903',
      '98908',
      '98921',
      '98923',
      '98929',
      '98930',
      '98932',
      '98933',
      '98935',
      '98936',
      '98937',
      '98938',
      '98939',
      '98942',
      '98944',
      '98947',
      '98948',
      '98951',
      '98952',
      '98953',
      '99350'
    ],
    '027': [
      '98520',
      '98526',
      '98535',
      '98536',
      '98537',
      '98541',
      '98547',
      '98550',
      '98552',
      '98557',
      '98559',
      '98562',
      '98563',
      '98568',
      '98569',
      '98571',
      '98575',
      '98583',
      '98587',
      '98595'
    ],
    '069': [
      '98612',
      '98621',
      '98632',
      '98638',
      '98643',
      '98647'
    ],
    '071': [
      '99323',
      '99324',
      '99329',
      '99348',
      '99360',
      '99361',
      '99362',
      '99363'
    ],
    '067': [
      '98501',
      '98502',
      '98503',
      '98506',
      '98512',
      '98513',
      '98516',
      '98530',
      '98531',
      '98576',
      '98579',
      '98589',
      '98597'
    ],
    '057': [
      '98221',
      '98229',
      '98232',
      '98233',
      '98235',
      '98237',
      '98238',
      '98241',
      '98255',
      '98257',
      '98263',
      '98267',
      '98273',
      '98274',
      '98283',
      '98284',
      '98292'
    ],
    '025': [
      '98823',
      '98824',
      '98832',
      '98837',
      '98848',
      '98851',
      '98853',
      '98857',
      '98860',
      '99103',
      '99115',
      '99116',
      '99123',
      '99133',
      '99135',
      '99321',
      '99344',
      '99349',
      '99357'
    ],
    '059': [
      '98605',
      '98610',
      '98616',
      '98639',
      '98648',
      '98651',
      '98671',
      '98672'
    ],
    '021': [
      '99301',
      '99326',
      '99330',
      '99335',
      '99343',
      '99344',
      '99359',
      '99371'
    ],
    '039': [
      '98602',
      '98605',
      '98613',
      '98617',
      '98619',
      '98620',
      '98628',
      '98635',
      '98650',
      '98670',
      '98672',
      '98673',
      '99322',
      '99350',
      '99356'
    ],
    '011': [
      '98601',
      '98604',
      '98606',
      '98607',
      '98629',
      '98642',
      '98660',
      '98661',
      '98662',
      '98663',
      '98664',
      '98665',
      '98671',
      '98674',
      '98675',
      '98682',
      '98683',
      '98684',
      '98685',
      '98686'
    ],
    '003': [
      '99401',
      '99402',
      '99403'
    ],
    '035': [
      '98110',
      '98310',
      '98311',
      '98312',
      '98314',
      '98315',
      '98329',
      '98337',
      '98340',
      '98342',
      '98345',
      '98346',
      '98353',
      '98359',
      '98364',
      '98366',
      '98367',
      '98370',
      '98380',
      '98383',
      '98392',
      '98528'
    ],
    '033': [
      '98001',
      '98002',
      '98003',
      '98004',
      '98005',
      '98006',
      '98007',
      '98008',
      '98010',
      '98011',
      '98014',
      '98019',
      '98022',
      '98023',
      '98024',
      '98027',
      '98028',
      '98029',
      '98030',
      '98031',
      '98032',
      '98033',
      '98034',
      '98038',
      '98039',
      '98040',
      '98042',
      '98045',
      '98047',
      '98050',
      '98051',
      '98052',
      '98053',
      '98055',
      '98056',
      '98057',
      '98058',
      '98059',
      '98065',
      '98068',
      '98070',
      '98072',
      '98074',
      '98075',
      '98077',
      '98092',
      '98101',
      '98102',
      '98103',
      '98104',
      '98105',
      '98106',
      '98107',
      '98108',
      '98109',
      '98112',
      '98115',
      '98116',
      '98117',
      '98118',
      '98119',
      '98121',
      '98122',
      '98125',
      '98126',
      '98133',
      '98134',
      '98136',
      '98144',
      '98146',
      '98148',
      '98154',
      '98155',
      '98158',
      '98164',
      '98166',
      '98168',
      '98174',
      '98177',
      '98178',
      '98188',
      '98195',
      '98198',
      '98199',
      '98224',
      '98288',
      '98354'
    ],
    '037': [
      '98068',
      '98828',
      '98901',
      '98922',
      '98925',
      '98926',
      '98934',
      '98937',
      '98940',
      '98941',
      '98943',
      '98946',
      '98950'
    ],
    '051': [
      '99006',
      '99009',
      '99119',
      '99139',
      '99152',
      '99153',
      '99156',
      '99180'
    ],
    '023': [
      '99347',
      '99403'
    ],
    '043': [
      '98832',
      '99008',
      '99013',
      '99029',
      '99032',
      '99103',
      '99117',
      '99122',
      '99133',
      '99134',
      '99144',
      '99147',
      '99154',
      '99159',
      '99169',
      '99185'
    ],
    '065': [
      '99006',
      '99013',
      '99026',
      '99034',
      '99040',
      '99101',
      '99109',
      '99110',
      '99114',
      '99122',
      '99126',
      '99129',
      '99131',
      '99137',
      '99141',
      '99148',
      '99151',
      '99157',
      '99167',
      '99173',
      '99181'
    ],
    '047': [
      '98812',
      '98813',
      '98814',
      '98819',
      '98827',
      '98829',
      '98833',
      '98834',
      '98840',
      '98841',
      '98844',
      '98846',
      '98849',
      '98855',
      '98856',
      '98859',
      '98862',
      '99116',
      '99124',
      '99155',
      '99166'
    ],
    '063': [
      '99001',
      '99003',
      '99004',
      '99005',
      '99006',
      '99008',
      '99009',
      '99011',
      '99012',
      '99013',
      '99016',
      '99018',
      '99019',
      '99020',
      '99021',
      '99022',
      '99023',
      '99025',
      '99026',
      '99027',
      '99029',
      '99030',
      '99031',
      '99033',
      '99036',
      '99037',
      '99039',
      '99110',
      '99156',
      '99170',
      '99201',
      '99202',
      '99203',
      '99204',
      '99205',
      '99206',
      '99207',
      '99208',
      '99212',
      '99216',
      '99217',
      '99218',
      '99223',
      '99224'
    ],
    '045': [
      '98524',
      '98528',
      '98541',
      '98546',
      '98548',
      '98555',
      '98557',
      '98560',
      '98584',
      '98588',
      '98592'
    ],
    '061': [
      '98012',
      '98020',
      '98021',
      '98026',
      '98036',
      '98037',
      '98043',
      '98072',
      '98077',
      '98087',
      '98201',
      '98203',
      '98204',
      '98205',
      '98207',
      '98208',
      '98223',
      '98241',
      '98251',
      '98252',
      '98256',
      '98258',
      '98270',
      '98271',
      '98272',
      '98275',
      '98290',
      '98292',
      '98294',
      '98296'
    ],
    '049': [
      '98527',
      '98537',
      '98547',
      '98577',
      '98586',
      '98590',
      '98614',
      '98621',
      '98624',
      '98631',
      '98638',
      '98640',
      '98641',
      '98644'
    ],
    '019': [
      '99118',
      '99121',
      '99138',
      '99140',
      '99141',
      '99146',
      '99150',
      '99155',
      '99160',
      '99166'
    ],
    '075': [
      '99004',
      '99017',
      '99033',
      '99102',
      '99111',
      '99113',
      '99125',
      '99128',
      '99130',
      '99136',
      '99143',
      '99149',
      '99158',
      '99161',
      '99163',
      '99164',
      '99170',
      '99171',
      '99174',
      '99176',
      '99179',
      '99333',
      '99347'
    ],
    '007': [
      '98801',
      '98811',
      '98815',
      '98816',
      '98817',
      '98821',
      '98822',
      '98826',
      '98828',
      '98831',
      '98836',
      '98847',
      '98852'
    ],
    '013': [
      '99328',
      '99347',
      '99348',
      '99359',
      '99361'
    ],
    '073': [
      '98220',
      '98225',
      '98226',
      '98229',
      '98230',
      '98237',
      '98240',
      '98244',
      '98247',
      '98248',
      '98262',
      '98264',
      '98266',
      '98276',
      '98281',
      '98283',
      '98284',
      '98295',
      '98862'
    ],
    '015': [
      '98581',
      '98601',
      '98603',
      '98611',
      '98616',
      '98625',
      '98626',
      '98632',
      '98645',
      '98649',
      '98674'
    ]
  },
  '37': {
    '167': [
      '28001',
      '28009',
      '28071',
      '28097',
      '28107',
      '28109',
      '28124',
      '28127',
      '28128',
      '28129',
      '28137',
      '28163'
    ],
    '079': [
      '27828',
      '27863',
      '27883',
      '27888',
      '28513',
      '28530',
      '28538',
      '28551',
      '28554',
      '28580'
    ],
    '131': [
      '27820',
      '27831',
      '27832',
      '27842',
      '27845',
      '27853',
      '27855',
      '27862',
      '27866',
      '27869',
      '27876',
      '27897'
    ],
    '193': [
      '27020',
      '28606',
      '28618',
      '28621',
      '28624',
      '28635',
      '28642',
      '28644',
      '28649',
      '28651',
      '28654',
      '28659',
      '28665',
      '28669',
      '28670',
      '28676',
      '28683',
      '28685',
      '28689',
      '28694',
      '28697'
    ],
    '119': [
      '28031',
      '28036',
      '28078',
      '28104',
      '28105',
      '28107',
      '28134',
      '28202',
      '28203',
      '28204',
      '28205',
      '28206',
      '28207',
      '28208',
      '28209',
      '28210',
      '28211',
      '28212',
      '28213',
      '28214',
      '28215',
      '28216',
      '28217',
      '28226',
      '28227',
      '28244',
      '28262',
      '28269',
      '28270',
      '28273',
      '28277',
      '28278',
      '28280',
      '28282'
    ],
    '153': [
      '27306',
      '28330',
      '28338',
      '28345',
      '28347',
      '28363',
      '28367',
      '28379'
    ],
    '055': [
      '27915',
      '27920',
      '27936',
      '27943',
      '27948',
      '27949',
      '27953',
      '27954',
      '27959',
      '27968',
      '27972',
      '27978',
      '27981',
      '27982'
    ],
    '109': [
      '28006',
      '28021',
      '28033',
      '28037',
      '28080',
      '28090',
      '28092',
      '28164',
      '28168',
      '28650',
      '28673'
    ],
    '199': [
      '28714',
      '28740',
      '28754',
      '28777'
    ],
    '053': [
      '27916',
      '27917',
      '27923',
      '27927',
      '27929',
      '27939',
      '27941',
      '27947',
      '27950',
      '27956',
      '27958',
      '27964',
      '27965',
      '27966',
      '27973'
    ],
    '063': [
      '27278',
      '27503',
      '27517',
      '27560',
      '27572',
      '27613',
      '27617',
      '27701',
      '27703',
      '27704',
      '27705',
      '27707',
      '27709',
      '27712',
      '27713'
    ],
    '171': [
      '27007',
      '27017',
      '27024',
      '27030',
      '27041',
      '27043',
      '27047',
      '27053',
      '28621',
      '28676',
      '28683'
    ],
    '177': [
      '27826',
      '27925',
      '27928'
    ],
    '059': [
      '27006',
      '27014',
      '27028',
      '27055',
      '28634'
    ],
    '137': [
      '28510',
      '28515',
      '28529',
      '28537',
      '28552',
      '28556',
      '28560',
      '28571',
      '28583',
      '28587'
    ],
    '111': [
      '28167',
      '28655',
      '28657',
      '28711',
      '28749',
      '28752',
      '28761',
      '28762',
      '28777'
    ],
    '121': [
      '28705',
      '28740',
      '28749',
      '28777'
    ],
    '117': [
      '27812',
      '27825',
      '27840',
      '27841',
      '27843',
      '27846',
      '27857',
      '27861',
      '27871',
      '27892'
    ],
    '035': [
      '28037',
      '28090',
      '28092',
      '28168',
      '28601',
      '28602',
      '28609',
      '28610',
      '28612',
      '28613',
      '28650',
      '28658',
      '28673',
      '28682'
    ],
    '033': [
      '27212',
      '27217',
      '27244',
      '27249',
      '27291',
      '27302',
      '27305',
      '27311',
      '27314',
      '27315',
      '27320',
      '27326',
      '27343',
      '27379',
      '27541'
    ],
    '037': [
      '27207',
      '27208',
      '27252',
      '27298',
      '27312',
      '27330',
      '27344',
      '27349',
      '27355',
      '27502',
      '27516',
      '27517',
      '27519',
      '27523',
      '27540',
      '27559',
      '27562',
      '27713'
    ],
    '159': [
      '27013',
      '27054',
      '28023',
      '28039',
      '28071',
      '28072',
      '28081',
      '28083',
      '28088',
      '28115',
      '28125',
      '28137',
      '28138',
      '28144',
      '28146',
      '28147',
      '28159'
    ],
    '147': [
      '27812',
      '27827',
      '27828',
      '27829',
      '27834',
      '27837',
      '27852',
      '27858',
      '27871',
      '27879',
      '27884',
      '27886',
      '27888',
      '27889',
      '28513',
      '28530',
      '28586',
      '28590'
    ],
    '145': [
      '27291',
      '27305',
      '27343',
      '27541',
      '27565',
      '27572',
      '27573',
      '27574',
      '27583'
    ],
    '085': [
      '27330',
      '27332',
      '27501',
      '27504',
      '27505',
      '27521',
      '27526',
      '27540',
      '27546',
      '27592',
      '28323',
      '28326',
      '28334',
      '28339',
      '28356',
      '28390'
    ],
    '191': [
      '27524',
      '27530',
      '27531',
      '27534',
      '27542',
      '27569',
      '27830',
      '27863',
      '27883',
      '28333',
      '28365',
      '28551',
      '28578'
    ],
    '163': [
      '28318',
      '28328',
      '28334',
      '28341',
      '28344',
      '28365',
      '28366',
      '28382',
      '28385',
      '28393',
      '28441',
      '28444',
      '28447',
      '28453',
      '28458',
      '28478'
    ],
    '179': [
      '28079',
      '28103',
      '28104',
      '28105',
      '28107',
      '28108',
      '28110',
      '28112',
      '28133',
      '28173',
      '28174',
      '28227'
    ],
    '057': [
      '27012',
      '27107',
      '27127',
      '27239',
      '27262',
      '27265',
      '27284',
      '27292',
      '27295',
      '27299',
      '27360',
      '28127'
    ],
    '103': [
      '28501',
      '28504',
      '28526',
      '28555',
      '28562',
      '28572',
      '28573',
      '28574',
      '28585'
    ],
    '095': [
      '27810',
      '27824',
      '27826',
      '27860',
      '27875',
      '27885',
      '27960'
    ],
    '051': [
      '28301',
      '28303',
      '28304',
      '28305',
      '28306',
      '28307',
      '28308',
      '28310',
      '28311',
      '28312',
      '28314',
      '28318',
      '28334',
      '28342',
      '28344',
      '28348',
      '28356',
      '28371',
      '28382',
      '28384',
      '28390',
      '28391',
      '28395'
    ],
    '067': [
      '27009',
      '27012',
      '27019',
      '27021',
      '27023',
      '27040',
      '27043',
      '27045',
      '27050',
      '27051',
      '27052',
      '27101',
      '27103',
      '27104',
      '27105',
      '27106',
      '27107',
      '27109',
      '27110',
      '27127',
      '27265',
      '27284'
    ],
    '173': [
      '28702',
      '28713',
      '28719',
      '28771',
      '28789'
    ],
    '133': [
      '28445',
      '28454',
      '28460',
      '28518',
      '28521',
      '28539',
      '28540',
      '28543',
      '28544',
      '28546',
      '28547',
      '28555',
      '28572',
      '28574',
      '28582',
      '28584'
    ],
    '071': [
      '28006',
      '28012',
      '28016',
      '28021',
      '28032',
      '28033',
      '28034',
      '28052',
      '28054',
      '28056',
      '28077',
      '28086',
      '28092',
      '28098',
      '28101',
      '28120',
      '28164'
    ],
    '141': [
      '28411',
      '28421',
      '28425',
      '28435',
      '28443',
      '28445',
      '28447',
      '28448',
      '28454',
      '28457',
      '28466',
      '28478'
    ],
    '047': [
      '28320',
      '28423',
      '28424',
      '28430',
      '28431',
      '28432',
      '28433',
      '28436',
      '28438',
      '28439',
      '28442',
      '28450',
      '28455',
      '28456',
      '28463',
      '28472'
    ],
    '197': [
      '27011',
      '27018',
      '27020',
      '27055',
      '28642',
      '28689'
    ],
    '031': [
      '28511',
      '28512',
      '28516',
      '28520',
      '28524',
      '28528',
      '28531',
      '28553',
      '28557',
      '28570',
      '28575',
      '28577',
      '28579',
      '28581',
      '28582',
      '28584',
      '28589',
      '28594'
    ],
    '023': [
      '28090',
      '28601',
      '28602',
      '28612',
      '28619',
      '28628',
      '28637',
      '28645',
      '28655',
      '28657',
      '28666',
      '28667',
      '28671',
      '28690',
      '28761'
    ],
    '043': [
      '28902',
      '28904',
      '28909'
    ],
    '065': [
      '27801',
      '27803',
      '27809',
      '27812',
      '27819',
      '27822',
      '27829',
      '27834',
      '27843',
      '27852',
      '27864',
      '27878',
      '27881',
      '27886',
      '27891'
    ],
    '081': [
      '27214',
      '27215',
      '27233',
      '27235',
      '27244',
      '27249',
      '27260',
      '27262',
      '27263',
      '27265',
      '27282',
      '27283',
      '27284',
      '27298',
      '27301',
      '27310',
      '27313',
      '27317',
      '27320',
      '27357',
      '27358',
      '27377',
      '27401',
      '27403',
      '27405',
      '27406',
      '27407',
      '27408',
      '27409',
      '27410',
      '27455'
    ],
    '013': [
      '27806',
      '27808',
      '27810',
      '27814',
      '27817',
      '27821',
      '27834',
      '27837',
      '27860',
      '27865',
      '27871',
      '27889',
      '27892',
      '28586'
    ],
    '003': [
      '28601',
      '28625',
      '28630',
      '28636',
      '28654',
      '28678',
      '28681',
      '28689'
    ],
    '123': [
      '27209',
      '27229',
      '27281',
      '27306',
      '27341',
      '27356',
      '27371',
      '28127'
    ],
    '099': [
      '28707',
      '28717',
      '28719',
      '28723',
      '28725',
      '28736',
      '28741',
      '28747',
      '28751',
      '28774',
      '28779',
      '28783',
      '28786',
      '28789'
    ],
    '183': [
      '27501',
      '27502',
      '27511',
      '27513',
      '27518',
      '27519',
      '27520',
      '27522',
      '27523',
      '27526',
      '27529',
      '27539',
      '27540',
      '27545',
      '27560',
      '27562',
      '27571',
      '27587',
      '27591',
      '27592',
      '27596',
      '27597',
      '27601',
      '27603',
      '27604',
      '27605',
      '27606',
      '27607',
      '27608',
      '27609',
      '27610',
      '27612',
      '27613',
      '27614',
      '27615',
      '27616',
      '27617',
      '27703'
    ],
    '029': [
      '27909',
      '27921',
      '27973',
      '27974',
      '27976'
    ],
    '021': [
      '28701',
      '28704',
      '28709',
      '28711',
      '28715',
      '28730',
      '28732',
      '28748',
      '28753',
      '28757',
      '28759',
      '28778',
      '28787',
      '28801',
      '28803',
      '28804',
      '28805',
      '28806'
    ],
    '181': [
      '27507',
      '27536',
      '27537',
      '27544',
      '27553',
      '27556',
      '27563',
      '27565'
    ],
    '195': [
      '27542',
      '27557',
      '27803',
      '27807',
      '27813',
      '27822',
      '27829',
      '27830',
      '27851',
      '27852',
      '27873',
      '27878',
      '27880',
      '27883',
      '27888',
      '27893',
      '27896'
    ],
    '091': [
      '27805',
      '27818',
      '27855',
      '27897',
      '27910',
      '27922',
      '27924',
      '27942',
      '27986'
    ],
    '025': [
      '28025',
      '28027',
      '28036',
      '28071',
      '28075',
      '28078',
      '28081',
      '28083',
      '28097',
      '28107',
      '28124',
      '28138',
      '28213',
      '28215',
      '28262',
      '28269'
    ],
    '169': [
      '27009',
      '27016',
      '27019',
      '27021',
      '27022',
      '27025',
      '27030',
      '27041',
      '27042',
      '27043',
      '27045',
      '27046',
      '27050',
      '27052',
      '27053',
      '27357'
    ],
    '151': [
      '27203',
      '27205',
      '27208',
      '27233',
      '27239',
      '27248',
      '27260',
      '27263',
      '27283',
      '27292',
      '27298',
      '27313',
      '27316',
      '27317',
      '27325',
      '27341',
      '27344',
      '27350',
      '27355',
      '27360',
      '27370',
      '27371'
    ],
    '009': [
      '28615',
      '28617',
      '28626',
      '28629',
      '28631',
      '28640',
      '28643',
      '28644',
      '28672',
      '28684',
      '28693',
      '28694',
      '28698'
    ],
    '039': [
      '28781',
      '28901',
      '28902',
      '28905',
      '28906'
    ],
    '149': [
      '28139',
      '28722',
      '28756',
      '28773',
      '28782',
      '28792'
    ],
    '011': [
      '28604',
      '28605',
      '28611',
      '28616',
      '28622',
      '28646',
      '28647',
      '28652',
      '28657',
      '28662',
      '28679',
      '28777'
    ],
    '041': [
      '27919',
      '27932',
      '27946',
      '27980'
    ],
    '027': [
      '28601',
      '28605',
      '28606',
      '28611',
      '28630',
      '28638',
      '28645',
      '28667'
    ],
    '135': [
      '27231',
      '27243',
      '27278',
      '27302',
      '27510',
      '27514',
      '27516',
      '27517',
      '27541',
      '27572',
      '27583',
      '27705',
      '27707'
    ],
    '143': [
      '27919',
      '27944',
      '27980',
      '27985'
    ],
    '175': [
      '28708',
      '28712',
      '28718',
      '28739',
      '28742',
      '28747',
      '28766',
      '28768',
      '28772',
      '28774'
    ],
    '185': [
      '27537',
      '27551',
      '27553',
      '27563',
      '27589',
      '27842',
      '27844',
      '27850'
    ],
    '089': [
      '28704',
      '28711',
      '28726',
      '28729',
      '28731',
      '28732',
      '28735',
      '28739',
      '28742',
      '28759',
      '28766',
      '28773',
      '28790',
      '28791',
      '28792'
    ],
    '045': [
      '28017',
      '28020',
      '28021',
      '28040',
      '28073',
      '28086',
      '28089',
      '28090',
      '28114',
      '28150',
      '28152',
      '28169'
    ],
    '107': [
      '28501',
      '28504',
      '28525',
      '28526',
      '28530',
      '28538',
      '28551',
      '28572',
      '28578',
      '28580'
    ],
    '155': [
      '28340',
      '28357',
      '28358',
      '28360',
      '28364',
      '28369',
      '28371',
      '28372',
      '28375',
      '28377',
      '28383',
      '28384',
      '28386'
    ],
    '127': [
      '27557',
      '27597',
      '27803',
      '27804',
      '27807',
      '27809',
      '27816',
      '27822',
      '27856',
      '27878',
      '27880',
      '27882',
      '27891',
      '27896'
    ],
    '161': [
      '28018',
      '28019',
      '28020',
      '28040',
      '28043',
      '28076',
      '28114',
      '28139',
      '28150',
      '28160',
      '28167',
      '28711',
      '28720',
      '28746',
      '28756'
    ],
    '139': [
      '27909'
    ],
    '097': [
      '27013',
      '27020',
      '27028',
      '27055',
      '28036',
      '28115',
      '28117',
      '28125',
      '28166',
      '28625',
      '28634',
      '28636',
      '28660',
      '28677',
      '28678',
      '28689'
    ],
    '125': [
      '27208',
      '27209',
      '27242',
      '27281',
      '27325',
      '27330',
      '27341',
      '27356',
      '27376',
      '28315',
      '28326',
      '28327',
      '28347',
      '28350',
      '28373',
      '28374',
      '28387',
      '28394'
    ],
    '001': [
      '27201',
      '27215',
      '27217',
      '27244',
      '27249',
      '27253',
      '27258',
      '27298',
      '27302',
      '27312',
      '27340',
      '27349'
    ],
    '129': [
      '28401',
      '28403',
      '28405',
      '28409',
      '28411',
      '28412',
      '28428',
      '28429',
      '28449',
      '28480'
    ],
    '017': [
      '28306',
      '28312',
      '28320',
      '28332',
      '28337',
      '28384',
      '28392',
      '28399',
      '28423',
      '28433',
      '28434',
      '28438',
      '28441',
      '28444',
      '28447',
      '28448',
      '28456'
    ],
    '087': [
      '28716',
      '28721',
      '28743',
      '28745',
      '28751',
      '28785',
      '28786'
    ],
    '115': [
      '28743',
      '28748',
      '28753',
      '28754',
      '28787'
    ],
    '005': [
      '28623',
      '28627',
      '28631',
      '28644',
      '28663',
      '28668',
      '28675'
    ],
    '189': [
      '28604',
      '28605',
      '28607',
      '28618',
      '28645',
      '28679',
      '28684',
      '28692',
      '28698'
    ],
    '077': [
      '27507',
      '27509',
      '27522',
      '27525',
      '27544',
      '27565',
      '27572',
      '27581',
      '27582',
      '27587',
      '27596'
    ],
    '157': [
      '27025',
      '27027',
      '27048',
      '27214',
      '27249',
      '27288',
      '27311',
      '27320',
      '27326',
      '27357',
      '27358'
    ],
    '069': [
      '27508',
      '27525',
      '27537',
      '27544',
      '27549',
      '27587',
      '27589',
      '27596',
      '27597',
      '27816',
      '27882'
    ],
    '093': [
      '28304',
      '28306',
      '28315',
      '28357',
      '28364',
      '28371',
      '28376',
      '28377',
      '28386'
    ],
    '105': [
      '27330',
      '27332',
      '27505',
      '28326'
    ],
    '113': [
      '28734',
      '28741',
      '28763',
      '28775',
      '28781'
    ],
    '187': [
      '27860',
      '27928',
      '27962',
      '27970'
    ],
    '075': [
      '28702',
      '28733',
      '28771'
    ],
    '083': [
      '27823',
      '27839',
      '27843',
      '27844',
      '27850',
      '27870',
      '27874',
      '27890',
      '27891'
    ],
    '165': [
      '28343',
      '28345',
      '28351',
      '28352',
      '28363',
      '28364',
      '28396'
    ],
    '101': [
      '27501',
      '27504',
      '27520',
      '27524',
      '27527',
      '27529',
      '27542',
      '27555',
      '27557',
      '27568',
      '27569',
      '27576',
      '27577',
      '27591',
      '27592',
      '27597',
      '27603',
      '28334',
      '28366'
    ],
    '061': [
      '28325',
      '28341',
      '28349',
      '28365',
      '28393',
      '28398',
      '28444',
      '28453',
      '28458',
      '28464',
      '28466',
      '28478',
      '28508',
      '28518',
      '28521',
      '28525',
      '28572',
      '28574',
      '28578'
    ],
    '049': [
      '28519',
      '28523',
      '28526',
      '28527',
      '28530',
      '28532',
      '28533',
      '28560',
      '28562',
      '28573',
      '28586'
    ],
    '019': [
      '28420',
      '28422',
      '28436',
      '28451',
      '28452',
      '28456',
      '28461',
      '28462',
      '28465',
      '28467',
      '28468',
      '28469',
      '28470',
      '28479'
    ],
    '007': [
      '28007',
      '28091',
      '28102',
      '28103',
      '28119',
      '28133',
      '28135',
      '28170'
    ],
    '073': [
      '27926',
      '27935',
      '27937',
      '27938',
      '27946',
      '27979'
    ],
    '015': [
      '27805',
      '27847',
      '27849',
      '27872',
      '27910',
      '27924',
      '27957',
      '27967',
      '27983'
    ]
  },
  '51': {
    '800': [
      '23321',
      '23432',
      '23433',
      '23434',
      '23435',
      '23436',
      '23437',
      '23438'
    ],
    '167': [
      '24224',
      '24225',
      '24237',
      '24260',
      '24266',
      '24280',
      '24283',
      '24609',
      '24639',
      '24649'
    ],
    '079': [
      '22923',
      '22935',
      '22940',
      '22968',
      '22973'
    ],
    '131': [
      '23307',
      '23310',
      '23313',
      '23316',
      '23347',
      '23350',
      '23354',
      '23398',
      '23405',
      '23408',
      '23413',
      '23486'
    ],
    '193': [
      '22435',
      '22443',
      '22469',
      '22485',
      '22488',
      '22520',
      '22529',
      '22572'
    ],
    '119': [
      '22504',
      '23032',
      '23043',
      '23070',
      '23071',
      '23079',
      '23092',
      '23149',
      '23169',
      '23175',
      '23176',
      '23180'
    ],
    '153': [
      '20109',
      '20110',
      '20111',
      '20112',
      '20136',
      '20137',
      '20143',
      '20155',
      '20169',
      '20181',
      '22025',
      '22026',
      '22125',
      '22134',
      '22172',
      '22191',
      '22192',
      '22193'
    ],
    '109': [
      '22942',
      '22947',
      '22974',
      '23015',
      '23024',
      '23065',
      '23084',
      '23093',
      '23102',
      '23117',
      '23192'
    ],
    '678': [
      '24450'
    ],
    '640': [
      '24333'
    ],
    '053': [
      '23803',
      '23805',
      '23824',
      '23830',
      '23833',
      '23840',
      '23841',
      '23850',
      '23872',
      '23882',
      '23885',
      '23894'
    ],
    '840': [
      '22601'
    ],
    '610': [
      '22042',
      '22044',
      '22046',
      '22205'
    ],
    '063': [
      '24072',
      '24079',
      '24091',
      '24105',
      '24120',
      '24138',
      '24141',
      '24149',
      '24162',
      '24352',
      '24380'
    ],
    '171': [
      '22641',
      '22644',
      '22652',
      '22654',
      '22657',
      '22660',
      '22664',
      '22810',
      '22815',
      '22824',
      '22842',
      '22844',
      '22845',
      '22847',
      '22853'
    ],
    '177': [
      '22407',
      '22408',
      '22508',
      '22534',
      '22551',
      '22553',
      '22580',
      '22960',
      '23015',
      '23024',
      '23117'
    ],
    '650': [
      '23605',
      '23651',
      '23661',
      '23663',
      '23664',
      '23665',
      '23666',
      '23669'
    ],
    '059': [
      '20120',
      '20121',
      '20124',
      '20151',
      '20170',
      '20171',
      '20190',
      '20191',
      '20194',
      '22003',
      '22015',
      '22027',
      '22030',
      '22031',
      '22032',
      '22033',
      '22035',
      '22039',
      '22041',
      '22042',
      '22043',
      '22044',
      '22046',
      '22060',
      '22066',
      '22079',
      '22101',
      '22102',
      '22124',
      '22150',
      '22151',
      '22152',
      '22153',
      '22180',
      '22181',
      '22182',
      '22185',
      '22207',
      '22302',
      '22303',
      '22304',
      '22306',
      '22307',
      '22308',
      '22309',
      '22310',
      '22311',
      '22312',
      '22315'
    ],
    '143': [
      '24054',
      '24069',
      '24137',
      '24139',
      '24161',
      '24527',
      '24530',
      '24531',
      '24540',
      '24541',
      '24549',
      '24557',
      '24563',
      '24565',
      '24566',
      '24569',
      '24586',
      '24594',
      '24597'
    ],
    '111': [
      '23824',
      '23920',
      '23924',
      '23937',
      '23938',
      '23942',
      '23944',
      '23947',
      '23952',
      '23954',
      '23970',
      '23974'
    ],
    '165': [
      '22801',
      '22802',
      '22811',
      '22812',
      '22815',
      '22820',
      '22821',
      '22827',
      '22830',
      '22831',
      '22832',
      '22834',
      '22840',
      '22841',
      '22844',
      '22846',
      '22849',
      '22850',
      '22853',
      '24441',
      '24471',
      '24486'
    ],
    '530': [
      '24416'
    ],
    '670': [
      '23860'
    ],
    '035': [
      '24053',
      '24105',
      '24120',
      '24312',
      '24317',
      '24325',
      '24328',
      '24330',
      '24333',
      '24343',
      '24347',
      '24350',
      '24351',
      '24352',
      '24380',
      '24381'
    ],
    '033': [
      '22408',
      '22427',
      '22514',
      '22535',
      '22538',
      '22546',
      '22580',
      '23015',
      '23047',
      '23069'
    ],
    '037': [
      '23923',
      '23924',
      '23934',
      '23937',
      '23947',
      '23958',
      '23959',
      '23962',
      '23963',
      '23964',
      '23967',
      '23976',
      '24528'
    ],
    '159': [
      '22435',
      '22460',
      '22548',
      '22572'
    ],
    '147': [
      '23901',
      '23909',
      '23922',
      '23923',
      '23934',
      '23942',
      '23943',
      '23947',
      '23954',
      '23958',
      '23960',
      '23966'
    ],
    '195': [
      '24216',
      '24219',
      '24230',
      '24246',
      '24273',
      '24279',
      '24283',
      '24293'
    ],
    '590': [
      '24540',
      '24541'
    ],
    '145': [
      '23113',
      '23120',
      '23139',
      '23160'
    ],
    '085': [
      '22546',
      '23005',
      '23015',
      '23024',
      '23047',
      '23059',
      '23069',
      '23102',
      '23111',
      '23116',
      '23146',
      '23192'
    ],
    '191': [
      '24202',
      '24210',
      '24211',
      '24236',
      '24258',
      '24270',
      '24319',
      '24340',
      '24361',
      '24370'
    ],
    '141': [
      '24053',
      '24055',
      '24076',
      '24082',
      '24088',
      '24091',
      '24120',
      '24133',
      '24165',
      '24171',
      '24185',
      '24343'
    ],
    '089': [
      '24054',
      '24055',
      '24078',
      '24089',
      '24102',
      '24112',
      '24148',
      '24165',
      '24168',
      '24530'
    ],
    '163': [
      '24066',
      '24415',
      '24416',
      '24435',
      '24439',
      '24450',
      '24459',
      '24472',
      '24473',
      '24483',
      '24555',
      '24578',
      '24579'
    ],
    '580': [
      '24426'
    ],
    '700': [
      '23601',
      '23602',
      '23603',
      '23604',
      '23605',
      '23606',
      '23607',
      '23608'
    ],
    '570': [
      '23834'
    ],
    '770': [
      '24011',
      '24012',
      '24013',
      '24014',
      '24015',
      '24016',
      '24017',
      '24018',
      '24019'
    ],
    '179': [
      '22134',
      '22405',
      '22406',
      '22554',
      '22556'
    ],
    '057': [
      '22436',
      '22437',
      '22438',
      '22454',
      '22476',
      '22504',
      '22509',
      '22560',
      '23115',
      '23148'
    ],
    '740': [
      '23701',
      '23702',
      '23703',
      '23704',
      '23707',
      '23708',
      '23709'
    ],
    '735': [
      '23662'
    ],
    '095': [
      '23089',
      '23168',
      '23185',
      '23188'
    ],
    '620': [
      '23851'
    ],
    '051': [
      '24217',
      '24220',
      '24226',
      '24228',
      '24230',
      '24237',
      '24256',
      '24269',
      '24272',
      '24283',
      '24607'
    ],
    '009': [
      '22922',
      '22967',
      '24483',
      '24521',
      '24526',
      '24553',
      '24572',
      '24574',
      '24595'
    ],
    '173': [
      '24311',
      '24318',
      '24319',
      '24354',
      '24368',
      '24370',
      '24375',
      '24378'
    ],
    '133': [
      '22432',
      '22435',
      '22473',
      '22482',
      '22511',
      '22530',
      '22539',
      '22579'
    ],
    '600': [
      '22030',
      '22031',
      '22032'
    ],
    '071': [
      '24086',
      '24093',
      '24124',
      '24128',
      '24134',
      '24136',
      '24147',
      '24150',
      '24167',
      '24315'
    ],
    '710': [
      '23502',
      '23503',
      '23504',
      '23505',
      '23507',
      '23508',
      '23509',
      '23510',
      '23511',
      '23513',
      '23517',
      '23518',
      '23523',
      '23551'
    ],
    '047': [
      '20106',
      '20186',
      '22701',
      '22713',
      '22714',
      '22718',
      '22724',
      '22726',
      '22729',
      '22733',
      '22734',
      '22735',
      '22736',
      '22737',
      '22740',
      '22741'
    ],
    '197': [
      '24312',
      '24313',
      '24322',
      '24323',
      '24324',
      '24343',
      '24350',
      '24360',
      '24368',
      '24374',
      '24382'
    ],
    '520': [
      '24201',
      '24202'
    ],
    '023': [
      '24012',
      '24019',
      '24064',
      '24066',
      '24077',
      '24083',
      '24085',
      '24090',
      '24130',
      '24153',
      '24175',
      '24422'
    ],
    '810': [
      '23451',
      '23452',
      '23453',
      '23454',
      '23455',
      '23456',
      '23457',
      '23459',
      '23460',
      '23461',
      '23462',
      '23464'
    ],
    '043': [
      '20130',
      '20135',
      '22611',
      '22620',
      '22630',
      '22646',
      '22663'
    ],
    '065': [
      '22947',
      '22963',
      '22974',
      '23022',
      '23038',
      '23055',
      '23084',
      '23093',
      '24590'
    ],
    '081': [
      '23847',
      '23856',
      '23867',
      '23879'
    ],
    '013': [
      '22101',
      '22201',
      '22202',
      '22203',
      '22204',
      '22205',
      '22206',
      '22207',
      '22209',
      '22211',
      '22213',
      '22214'
    ],
    '003': [
      '22901',
      '22902',
      '22903',
      '22904',
      '22911',
      '22920',
      '22923',
      '22931',
      '22932',
      '22935',
      '22936',
      '22937',
      '22938',
      '22940',
      '22942',
      '22943',
      '22946',
      '22947',
      '22959',
      '22968',
      '22969',
      '22974',
      '24562',
      '24590'
    ],
    '099': [
      '22448',
      '22485'
    ],
    '750': [
      '24141',
      '24142'
    ],
    '515': [
      '24523'
    ],
    '183': [
      '23830',
      '23837',
      '23842',
      '23847',
      '23867',
      '23882',
      '23884',
      '23888',
      '23890',
      '23891',
      '23897'
    ],
    '029': [
      '23004',
      '23040',
      '23123',
      '23901',
      '23921',
      '23936',
      '24522',
      '24553',
      '24562',
      '24590',
      '24599'
    ],
    '021': [
      '24084',
      '24124',
      '24314',
      '24315',
      '24318',
      '24366'
    ],
    '181': [
      '23839',
      '23842',
      '23846',
      '23881',
      '23883',
      '23888',
      '23890',
      '23899'
    ],
    '031': [
      '23963',
      '24501',
      '24502',
      '24504',
      '24517',
      '24528',
      '24538',
      '24550',
      '24551',
      '24554',
      '24569',
      '24571',
      '24588'
    ],
    '091': [
      '24413',
      '24433',
      '24442',
      '24458',
      '24465',
      '24484',
      '24487'
    ],
    '720': [
      '24273'
    ],
    '540': [
      '22901',
      '22902',
      '22903',
      '22904'
    ],
    '169': [
      '24202',
      '24221',
      '24230',
      '24244',
      '24245',
      '24250',
      '24251',
      '24258',
      '24271',
      '24290'
    ],
    '025': [
      '23821',
      '23824',
      '23843',
      '23845',
      '23847',
      '23856',
      '23857',
      '23868',
      '23876',
      '23887',
      '23889',
      '23893',
      '23919',
      '23920',
      '23938',
      '23950'
    ],
    '121': [
      '24060',
      '24070',
      '24073',
      '24087',
      '24138',
      '24141',
      '24149',
      '24162',
      '24347'
    ],
    '550': [
      '23320',
      '23321',
      '23322',
      '23323',
      '23324',
      '23325'
    ],
    '790': [
      '24401',
      '24482'
    ],
    '149': [
      '23801',
      '23805',
      '23830',
      '23842',
      '23860',
      '23875',
      '23881'
    ],
    '011': [
      '23958',
      '23960',
      '23963',
      '24504',
      '24522',
      '24538',
      '24553',
      '24593'
    ],
    '103': [
      '22473',
      '22480',
      '22482',
      '22503',
      '22576',
      '22578'
    ],
    '690': [
      '24112'
    ],
    '041': [
      '23112',
      '23113',
      '23114',
      '23120',
      '23224',
      '23225',
      '23234',
      '23235',
      '23236',
      '23237',
      '23803',
      '23806',
      '23831',
      '23832',
      '23834',
      '23836',
      '23838'
    ],
    '685': [
      '20111'
    ],
    '027': [
      '24217',
      '24239',
      '24256',
      '24260',
      '24603',
      '24607',
      '24614',
      '24620',
      '24622',
      '24628',
      '24631',
      '24634',
      '24639',
      '24646',
      '24649',
      '24656',
      '24657'
    ],
    '760': [
      '23173',
      '23219',
      '23220',
      '23221',
      '23222',
      '23223',
      '23224',
      '23225',
      '23226',
      '23227',
      '23230',
      '23231',
      '23234',
      '23235'
    ],
    '199': [
      '23185',
      '23188',
      '23602',
      '23603',
      '23665',
      '23690',
      '23691',
      '23692',
      '23693',
      '23696'
    ],
    '137': [
      '22433',
      '22508',
      '22542',
      '22567',
      '22701',
      '22733',
      '22923',
      '22942',
      '22960',
      '22972'
    ],
    '175': [
      '23827',
      '23828',
      '23829',
      '23837',
      '23844',
      '23847',
      '23851',
      '23866',
      '23874',
      '23878',
      '23888',
      '23898'
    ],
    '185': [
      '24314',
      '24316',
      '24377',
      '24601',
      '24602',
      '24604',
      '24605',
      '24606',
      '24609',
      '24612',
      '24613',
      '24622',
      '24630',
      '24635',
      '24637',
      '24639',
      '24641',
      '24651'
    ],
    '036': [
      '23030',
      '23140',
      '23185',
      '23231'
    ],
    '045': [
      '24070',
      '24127',
      '24128',
      '24131'
    ],
    '595': [
      '23847'
    ],
    '107': [
      '20105',
      '20117',
      '20118',
      '20120',
      '20129',
      '20132',
      '20135',
      '20141',
      '20147',
      '20148',
      '20152',
      '20158',
      '20164',
      '20165',
      '20166',
      '20175',
      '20176',
      '20180',
      '20184',
      '20197',
      '22066'
    ],
    '155': [
      '24058',
      '24084',
      '24132',
      '24141',
      '24301',
      '24313',
      '24324',
      '24347'
    ],
    '127': [
      '23011',
      '23089',
      '23124',
      '23140',
      '23141',
      '23181'
    ],
    '161': [
      '24012',
      '24014',
      '24015',
      '24018',
      '24019',
      '24020',
      '24059',
      '24065',
      '24070',
      '24087',
      '24153',
      '24175',
      '24179'
    ],
    '139': [
      '22650',
      '22827',
      '22835',
      '22849',
      '22851'
    ],
    '097': [
      '22437',
      '22514',
      '22560',
      '23023',
      '23085',
      '23091',
      '23108',
      '23110',
      '23126',
      '23148',
      '23149',
      '23156',
      '23161',
      '23177'
    ],
    '125': [
      '22920',
      '22922',
      '22938',
      '22949',
      '22958',
      '22964',
      '22967',
      '22969',
      '22971',
      '22976',
      '24464',
      '24521',
      '24553',
      '24562',
      '24581',
      '24599'
    ],
    '001': [
      '23301',
      '23302',
      '23303',
      '23306',
      '23308',
      '23336',
      '23337',
      '23356',
      '23357',
      '23358',
      '23359',
      '23389',
      '23395',
      '23401',
      '23407',
      '23409',
      '23410',
      '23414',
      '23415',
      '23416',
      '23417',
      '23418',
      '23420',
      '23421',
      '23422',
      '23423',
      '23426',
      '23427',
      '23440',
      '23441',
      '23442',
      '23480',
      '23488'
    ],
    '117': [
      '23915',
      '23917',
      '23919',
      '23920',
      '23924',
      '23927',
      '23950',
      '23964',
      '23968',
      '23970',
      '24529',
      '24580',
      '24598'
    ],
    '017': [
      '24412',
      '24432',
      '24445',
      '24460',
      '24484',
      '24487'
    ],
    '087': [
      '23030',
      '23059',
      '23060',
      '23075',
      '23150',
      '23222',
      '23223',
      '23226',
      '23227',
      '23228',
      '23229',
      '23230',
      '23231',
      '23233',
      '23238',
      '23250',
      '23294'
    ],
    '115': [
      '23021',
      '23025',
      '23035',
      '23045',
      '23050',
      '23056',
      '23064',
      '23066',
      '23068',
      '23076',
      '23109',
      '23119',
      '23125',
      '23128',
      '23130',
      '23138',
      '23163'
    ],
    '680': [
      '24501',
      '24502',
      '24503',
      '24504',
      '24551'
    ],
    '005': [
      '24085',
      '24422',
      '24426',
      '24445',
      '24448',
      '24457',
      '24474'
    ],
    '775': [
      '24018',
      '24153'
    ],
    '067': [
      '24055',
      '24059',
      '24065',
      '24067',
      '24079',
      '24088',
      '24091',
      '24092',
      '24101',
      '24102',
      '24112',
      '24121',
      '24137',
      '24151',
      '24176',
      '24184'
    ],
    '157': [
      '20106',
      '22623',
      '22627',
      '22640',
      '22713',
      '22716',
      '22740',
      '22747',
      '22749'
    ],
    '683': [
      '20109',
      '20110'
    ],
    '093': [
      '23304',
      '23314',
      '23315',
      '23430',
      '23487',
      '23851',
      '23866',
      '23898'
    ],
    '630': [
      '22401'
    ],
    '105': [
      '24219',
      '24221',
      '24243',
      '24244',
      '24248',
      '24263',
      '24265',
      '24277',
      '24281',
      '24282'
    ],
    '113': [
      '22701',
      '22709',
      '22711',
      '22715',
      '22719',
      '22722',
      '22723',
      '22727',
      '22730',
      '22731',
      '22732',
      '22733',
      '22735',
      '22738',
      '22740',
      '22743',
      '22835',
      '22948',
      '22960',
      '22973',
      '22989'
    ],
    '187': [
      '22610',
      '22623',
      '22630',
      '22642',
      '22645',
      '22655',
      '22657'
    ],
    '075': [
      '23038',
      '23039',
      '23063',
      '23065',
      '23084',
      '23093',
      '23102',
      '23103',
      '23117',
      '23129',
      '23146',
      '23153',
      '23160',
      '23233',
      '23238'
    ],
    '510': [
      '22206',
      '22301',
      '22302',
      '22304',
      '22305',
      '22311',
      '22312',
      '22314'
    ],
    '820': [
      '22980'
    ],
    '083': [
      '23962',
      '24520',
      '24534',
      '24539',
      '24557',
      '24558',
      '24565',
      '24569',
      '24577',
      '24589',
      '24592',
      '24594',
      '24597',
      '24598'
    ],
    '077': [
      '24292',
      '24326',
      '24330',
      '24333',
      '24348',
      '24350',
      '24363',
      '24378'
    ],
    '660': [
      '22801',
      '22802',
      '22807'
    ],
    '830': [
      '23185',
      '23187',
      '23188'
    ],
    '135': [
      '23002',
      '23083',
      '23824',
      '23922',
      '23930'
    ],
    '730': [
      '23803',
      '23805'
    ],
    '101': [
      '23009',
      '23069',
      '23086',
      '23106',
      '23177',
      '23181'
    ],
    '061': [
      '20106',
      '20115',
      '20117',
      '20119',
      '20130',
      '20137',
      '20139',
      '20144',
      '20181',
      '20184',
      '20186',
      '20187',
      '20198',
      '22406',
      '22630',
      '22639',
      '22642',
      '22643',
      '22712',
      '22720',
      '22728',
      '22734',
      '22742'
    ],
    '049': [
      '23027',
      '23038',
      '23040',
      '23123',
      '23139',
      '23901',
      '23936'
    ],
    '069': [
      '22601',
      '22602',
      '22603',
      '22624',
      '22625',
      '22630',
      '22637',
      '22645',
      '22654',
      '22655',
      '22656',
      '22663'
    ],
    '019': [
      '24064',
      '24095',
      '24101',
      '24104',
      '24121',
      '24122',
      '24139',
      '24174',
      '24179',
      '24502',
      '24503',
      '24523',
      '24526',
      '24536',
      '24550',
      '24551',
      '24556',
      '24570',
      '24571'
    ],
    '007': [
      '23002',
      '23083',
      '23833',
      '23850',
      '23966'
    ],
    '073': [
      '23050',
      '23061',
      '23062',
      '23072',
      '23128',
      '23149'
    ],
    '015': [
      '22812',
      '22841',
      '22843',
      '22920',
      '22939',
      '22952',
      '22958',
      '22980',
      '24401',
      '24411',
      '24421',
      '24430',
      '24431',
      '24432',
      '24437',
      '24439',
      '24440',
      '24441',
      '24459',
      '24467',
      '24472',
      '24476',
      '24477',
      '24479',
      '24482',
      '24485',
      '24486'
    ]
  },
  '20': {
    '167': [
      '67481',
      '67490',
      '67626',
      '67634',
      '67640',
      '67648',
      '67649',
      '67651',
      '67658',
      '67665',
      '67673'
    ],
    '079': [
      '66866',
      '67020',
      '67056',
      '67062',
      '67107',
      '67114',
      '67117',
      '67135',
      '67147',
      '67151',
      '67154',
      '67522'
    ],
    '131': [
      '66403',
      '66404',
      '66408',
      '66415',
      '66416',
      '66417',
      '66428',
      '66521',
      '66522',
      '66534',
      '66538',
      '66540',
      '66544',
      '66550'
    ],
    '193': [
      '67701',
      '67732',
      '67734',
      '67743',
      '67748',
      '67753',
      '67764'
    ],
    '119': [
      '67837',
      '67844',
      '67864',
      '67867',
      '67869'
    ],
    '153': [
      '67701',
      '67730',
      '67732',
      '67734',
      '67739',
      '67743',
      '67744',
      '67745',
      '67749',
      '67753'
    ],
    '055': [
      '67835',
      '67838',
      '67846',
      '67851',
      '67853',
      '67868',
      '67871'
    ],
    '109': [
      '67747',
      '67748',
      '67761',
      '67764',
      '67861',
      '67871'
    ],
    '205': [
      '66710',
      '66714',
      '66717',
      '66720',
      '66736',
      '66757',
      '66776',
      '66777',
      '67047'
    ],
    '053': [
      '67425',
      '67427',
      '67439',
      '67444',
      '67450',
      '67454',
      '67459',
      '67464',
      '67490'
    ],
    '063': [
      '67584',
      '67736',
      '67737',
      '67738',
      '67748',
      '67751',
      '67752',
      '67839',
      '67871'
    ],
    '171': [
      '67863',
      '67871'
    ],
    '085': [
      '66416',
      '66418',
      '66419',
      '66422',
      '66432',
      '66436',
      '66439',
      '66440',
      '66509',
      '66512',
      '66516',
      '66536',
      '66540',
      '66550',
      '66552',
      '66617'
    ],
    '059': [
      '66006',
      '66033',
      '66042',
      '66064',
      '66067',
      '66076',
      '66078',
      '66079',
      '66080',
      '66092',
      '66095',
      '66528'
    ],
    '143': [
      '67401',
      '67422',
      '67432',
      '67436',
      '67445',
      '67458',
      '67466',
      '67467',
      '67470',
      '67480',
      '67484'
    ],
    '111': [
      '66413',
      '66523',
      '66801',
      '66830',
      '66833',
      '66835',
      '66846',
      '66854',
      '66860',
      '66864',
      '66865',
      '66868'
    ],
    '121': [
      '66013',
      '66021',
      '66026',
      '66040',
      '66042',
      '66053',
      '66064',
      '66071',
      '66072',
      '66083',
      '66092'
    ],
    '117': [
      '66403',
      '66406',
      '66411',
      '66412',
      '66427',
      '66438',
      '66508',
      '66518',
      '66541',
      '66544',
      '66548'
    ],
    '035': [
      '67005',
      '67008',
      '67019',
      '67023',
      '67024',
      '67038',
      '67039',
      '67051',
      '67072',
      '67110',
      '67119',
      '67131',
      '67146',
      '67156'
    ],
    '033': [
      '67029',
      '67127',
      '67155'
    ],
    '037': [
      '66701',
      '66711',
      '66712',
      '66724',
      '66734',
      '66735',
      '66743',
      '66746',
      '66753',
      '66756',
      '66760',
      '66762',
      '66763',
      '66780',
      '66781'
    ],
    '159': [
      '67427',
      '67444',
      '67457',
      '67491',
      '67502',
      '67512',
      '67524',
      '67525',
      '67526',
      '67546',
      '67554',
      '67573',
      '67579'
    ],
    '147': [
      '66951',
      '67621',
      '67639',
      '67644',
      '67646',
      '67647',
      '67661',
      '67664'
    ],
    '145': [
      '67519',
      '67523',
      '67529',
      '67550',
      '67557',
      '67559',
      '67567',
      '67574',
      '67575'
    ],
    '177': [
      '66050',
      '66402',
      '66409',
      '66431',
      '66512',
      '66524',
      '66533',
      '66536',
      '66539',
      '66542',
      '66546',
      '66603',
      '66604',
      '66605',
      '66606',
      '66607',
      '66608',
      '66609',
      '66610',
      '66611',
      '66612',
      '66614',
      '66615',
      '66616',
      '66617',
      '66618',
      '66619',
      '66621',
      '66622'
    ],
    '191': [
      '67004',
      '67013',
      '67018',
      '67022',
      '67026',
      '67031',
      '67049',
      '67051',
      '67103',
      '67105',
      '67106',
      '67110',
      '67118',
      '67119',
      '67120',
      '67140',
      '67146',
      '67152'
    ],
    '163': [
      '67632',
      '67646',
      '67651',
      '67657',
      '67663',
      '67669',
      '67675'
    ],
    '199': [
      '67758',
      '67761',
      '67762'
    ],
    '179': [
      '67635',
      '67737',
      '67738',
      '67740',
      '67751',
      '67752',
      '67753',
      '67757'
    ],
    '057': [
      '67563',
      '67801',
      '67834',
      '67841',
      '67842',
      '67843',
      '67844',
      '67865',
      '67876',
      '67882'
    ],
    '203': [
      '67861',
      '67863',
      '67871'
    ],
    '103': [
      '66002',
      '66007',
      '66012',
      '66020',
      '66027',
      '66043',
      '66044',
      '66048',
      '66052',
      '66054',
      '66086',
      '66097',
      '66109'
    ],
    '095': [
      '67025',
      '67035',
      '67058',
      '67068',
      '67111',
      '67112',
      '67118',
      '67142',
      '67159',
      '67570'
    ],
    '051': [
      '67556',
      '67601',
      '67637',
      '67640',
      '67651',
      '67660',
      '67663',
      '67667',
      '67671',
      '67674'
    ],
    '009': [
      '67450',
      '67511',
      '67525',
      '67526',
      '67530',
      '67544',
      '67564',
      '67565',
      '67567',
      '67665'
    ],
    '173': [
      '67001',
      '67016',
      '67017',
      '67020',
      '67025',
      '67026',
      '67030',
      '67031',
      '67037',
      '67050',
      '67052',
      '67055',
      '67060',
      '67067',
      '67101',
      '67106',
      '67108',
      '67110',
      '67118',
      '67120',
      '67135',
      '67147',
      '67149',
      '67202',
      '67203',
      '67204',
      '67205',
      '67206',
      '67207',
      '67208',
      '67209',
      '67210',
      '67211',
      '67212',
      '67213',
      '67214',
      '67215',
      '67216',
      '67217',
      '67218',
      '67219',
      '67220',
      '67223',
      '67226',
      '67227',
      '67228',
      '67230',
      '67232',
      '67235',
      '67260'
    ],
    '133': [
      '66720',
      '66733',
      '66740',
      '66748',
      '66771',
      '66775',
      '66776',
      '66780',
      '67357'
    ],
    '151': [
      '67021',
      '67028',
      '67035',
      '67059',
      '67065',
      '67066',
      '67124',
      '67134',
      '67557',
      '67583'
    ],
    '071': [
      '67879'
    ],
    '141': [
      '67437',
      '67473',
      '67474',
      '67485',
      '67623',
      '67648',
      '67649',
      '67651',
      '67658',
      '67673'
    ],
    '047': [
      '67059',
      '67519',
      '67547',
      '67552',
      '67557',
      '67563'
    ],
    '197': [
      '66401',
      '66407',
      '66413',
      '66423',
      '66431',
      '66501',
      '66502',
      '66507',
      '66526',
      '66536',
      '66547',
      '66614',
      '66615',
      '66834'
    ],
    '195': [
      '67521',
      '67572',
      '67584',
      '67631',
      '67637',
      '67656',
      '67657',
      '67672'
    ],
    '023': [
      '67731',
      '67741',
      '67745',
      '67756'
    ],
    '043': [
      '66002',
      '66008',
      '66017',
      '66024',
      '66035',
      '66041',
      '66087',
      '66090',
      '66094',
      '66532'
    ],
    '065': [
      '67625',
      '67631',
      '67632',
      '67642',
      '67645',
      '67646',
      '67650',
      '67656',
      '67657',
      '67659',
      '67672'
    ],
    '013': [
      '66094',
      '66424',
      '66425',
      '66434',
      '66439',
      '66515',
      '66516',
      '66527',
      '66532',
      '66534',
      '66550'
    ],
    '003': [
      '66014',
      '66015',
      '66032',
      '66033',
      '66039',
      '66080',
      '66091',
      '66093',
      '66095',
      '66758'
    ],
    '123': [
      '67418',
      '67420',
      '67430',
      '67446',
      '67452',
      '67478',
      '67485'
    ],
    '099': [
      '66753',
      '66776',
      '67330',
      '67332',
      '67335',
      '67336',
      '67337',
      '67341',
      '67342',
      '67351',
      '67354',
      '67356',
      '67357'
    ],
    '183': [
      '66932',
      '66951',
      '66952',
      '66967',
      '67437',
      '67474',
      '67628',
      '67638'
    ],
    '029': [
      '66901',
      '66938',
      '66948',
      '67417',
      '67420',
      '67436',
      '67445',
      '67466'
    ],
    '021': [
      '66713',
      '66724',
      '66725',
      '66728',
      '66739',
      '66753',
      '66762',
      '66770',
      '66773',
      '66778',
      '66781',
      '66782',
      '67336',
      '67356'
    ],
    '181': [
      '67732',
      '67733',
      '67735',
      '67741'
    ],
    '031': [
      '66093',
      '66758',
      '66839',
      '66852',
      '66854',
      '66856',
      '66857',
      '66864',
      '66868',
      '66871'
    ],
    '091': [
      '66013',
      '66018',
      '66019',
      '66021',
      '66025',
      '66030',
      '66031',
      '66061',
      '66062',
      '66083',
      '66085',
      '66202',
      '66203',
      '66204',
      '66205',
      '66206',
      '66207',
      '66208',
      '66209',
      '66210',
      '66211',
      '66212',
      '66213',
      '66214',
      '66215',
      '66216',
      '66217',
      '66218',
      '66219',
      '66220',
      '66221',
      '66223',
      '66224',
      '66226',
      '66227'
    ],
    '025': [
      '67127',
      '67831',
      '67834',
      '67840',
      '67842',
      '67865'
    ],
    '169': [
      '67401',
      '67416',
      '67425',
      '67442',
      '67448',
      '67456',
      '67470',
      '67480',
      '67484'
    ],
    '201': [
      '66412',
      '66548',
      '66933',
      '66937',
      '66938',
      '66943',
      '66944',
      '66945',
      '66946',
      '66953',
      '66955',
      '66958',
      '66962',
      '66968'
    ],
    '039': [
      '67629',
      '67635',
      '67643',
      '67653',
      '67739',
      '67749',
      '67757'
    ],
    '149': [
      '66407',
      '66422',
      '66427',
      '66432',
      '66502',
      '66520',
      '66521',
      '66535',
      '66536',
      '66547',
      '66549'
    ],
    '011': [
      '66010',
      '66701',
      '66711',
      '66716',
      '66738',
      '66741',
      '66746',
      '66754',
      '66755',
      '66769',
      '66779',
      '66780'
    ],
    '041': [
      '66441',
      '67410',
      '67431',
      '67441',
      '67448',
      '67449',
      '67451',
      '67475',
      '67480',
      '67482',
      '67487',
      '67492'
    ],
    '157': [
      '66901',
      '66930',
      '66935',
      '66939',
      '66940',
      '66948',
      '66959',
      '66960',
      '66964',
      '66966',
      '68325'
    ],
    '135': [
      '67515',
      '67516',
      '67518',
      '67521',
      '67556',
      '67560',
      '67572',
      '67584'
    ],
    '137': [
      '67622',
      '67629',
      '67645',
      '67646',
      '67647',
      '67653',
      '67654',
      '67664'
    ],
    '175': [
      '67859',
      '67869',
      '67870',
      '67877',
      '67901',
      '67952'
    ],
    '185': [
      '67526',
      '67530',
      '67545',
      '67550',
      '67557',
      '67567',
      '67576',
      '67578',
      '67583'
    ],
    '089': [
      '66936',
      '66939',
      '66941',
      '66942',
      '66949',
      '66952',
      '66956',
      '66963',
      '66970',
      '67430',
      '67446',
      '68978'
    ],
    '045': [
      '66006',
      '66021',
      '66025',
      '66044',
      '66045',
      '66046',
      '66047',
      '66049',
      '66050',
      '66092',
      '66409',
      '66524'
    ],
    '107': [
      '66010',
      '66014',
      '66026',
      '66033',
      '66040',
      '66056',
      '66072',
      '66075',
      '66738',
      '66754',
      '66767'
    ],
    '155': [
      '67020',
      '67025',
      '67035',
      '67068',
      '67108',
      '67501',
      '67502',
      '67505',
      '67510',
      '67514',
      '67522',
      '67543',
      '67546',
      '67561',
      '67566',
      '67568',
      '67570',
      '67579',
      '67581',
      '67583'
    ],
    '127': [
      '66834',
      '66838',
      '66846',
      '66849',
      '66859',
      '66872',
      '66873',
      '67449'
    ],
    '161': [
      '66411',
      '66441',
      '66442',
      '66449',
      '66502',
      '66503',
      '66506',
      '66517',
      '66531',
      '66554',
      '66933',
      '67432',
      '67447'
    ],
    '139': [
      '66413',
      '66414',
      '66451',
      '66510',
      '66523',
      '66524',
      '66528',
      '66537',
      '66543',
      '66546',
      '66856',
      '66868',
      '66871'
    ],
    '067': [
      '67855',
      '67880'
    ],
    '097': [
      '67028',
      '67029',
      '67054',
      '67059',
      '67109',
      '67155'
    ],
    '125': [
      '66736',
      '66757',
      '67301',
      '67333',
      '67335',
      '67337',
      '67340',
      '67344',
      '67347',
      '67351',
      '67363',
      '67364'
    ],
    '001': [
      '66015',
      '66039',
      '66716',
      '66720',
      '66732',
      '66748',
      '66749',
      '66751',
      '66755',
      '66772'
    ],
    '129': [
      '67855',
      '67862',
      '67950',
      '67953',
      '67954'
    ],
    '017': [
      '66801',
      '66838',
      '66840',
      '66843',
      '66845',
      '66846',
      '66850',
      '66862',
      '66865',
      '66869'
    ],
    '087': [
      '66002',
      '66044',
      '66054',
      '66060',
      '66066',
      '66070',
      '66073',
      '66086',
      '66088',
      '66097',
      '66419',
      '66429',
      '66436',
      '66512',
      '66617'
    ],
    '115': [
      '66840',
      '66851',
      '66858',
      '66859',
      '66861',
      '66866',
      '67053',
      '67063',
      '67073',
      '67114',
      '67151',
      '67428',
      '67438',
      '67449',
      '67475',
      '67483'
    ],
    '005': [
      '66002',
      '66016',
      '66023',
      '66041',
      '66058',
      '66060',
      '66088',
      '66424',
      '66436',
      '66439'
    ],
    '189': [
      '67901',
      '67951',
      '67952',
      '67954'
    ],
    '077': [
      '67003',
      '67004',
      '67009',
      '67018',
      '67036',
      '67049',
      '67058',
      '67061',
      '67150',
      '67159'
    ],
    '027': [
      '66937',
      '66962',
      '67410',
      '67432',
      '67447',
      '67458',
      '67466',
      '67468',
      '67487'
    ],
    '069': [
      '67835',
      '67837',
      '67841',
      '67844',
      '67846',
      '67853',
      '67867'
    ],
    '093': [
      '67838',
      '67857',
      '67860',
      '67880'
    ],
    '081': [
      '67837',
      '67870',
      '67877'
    ],
    '105': [
      '67418',
      '67423',
      '67439',
      '67452',
      '67455',
      '67481',
      '67484',
      '67490',
      '67648'
    ],
    '113': [
      '67062',
      '67107',
      '67114',
      '67428',
      '67443',
      '67448',
      '67456',
      '67460',
      '67464',
      '67491',
      '67546'
    ],
    '187': [
      '67855',
      '67862',
      '67878'
    ],
    '207': [
      '66748',
      '66758',
      '66761',
      '66777',
      '66783',
      '66852'
    ],
    '075': [
      '67836',
      '67857',
      '67878'
    ],
    '209': [
      '66012',
      '66101',
      '66102',
      '66103',
      '66104',
      '66105',
      '66106',
      '66109',
      '66111',
      '66112',
      '66115',
      '66118',
      '66217'
    ],
    '007': [
      '67028',
      '67057',
      '67061',
      '67065',
      '67070',
      '67071',
      '67104',
      '67134',
      '67138',
      '67143'
    ],
    '165': [
      '67511',
      '67513',
      '67520',
      '67548',
      '67553',
      '67556',
      '67559',
      '67565',
      '67567',
      '67575'
    ],
    '101': [
      '67518',
      '67584',
      '67839',
      '67846',
      '67850'
    ],
    '061': [
      '66441',
      '66442',
      '66502',
      '66514',
      '66834',
      '66849',
      '66872',
      '67487'
    ],
    '049': [
      '66736',
      '67047',
      '67122',
      '67137',
      '67344',
      '67345',
      '67346',
      '67349',
      '67352',
      '67353'
    ],
    '019': [
      '67024',
      '67334',
      '67344',
      '67346',
      '67347',
      '67352',
      '67353',
      '67355',
      '67360',
      '67361'
    ],
    '083': [
      '67523',
      '67547',
      '67563',
      '67849',
      '67854',
      '67876'
    ],
    '073': [
      '66777',
      '66852',
      '66853',
      '66860',
      '66863',
      '66870',
      '67045',
      '67047',
      '67122',
      '67137'
    ],
    '015': [
      '66840',
      '66842',
      '67002',
      '67008',
      '67010',
      '67012',
      '67017',
      '67039',
      '67041',
      '67042',
      '67072',
      '67074',
      '67114',
      '67123',
      '67132',
      '67133',
      '67144',
      '67154'
    ]
  },
  '01': {
    '079': [
      '35565',
      '35603',
      '35618',
      '35619',
      '35643',
      '35650',
      '35651',
      '35654',
      '35672',
      '35673'
    ],
    '123': [
      '35010',
      '35072',
      '35089',
      '36078',
      '36255',
      '36256',
      '36276',
      '36850',
      '36853',
      '36861',
      '36866',
      '36879'
    ],
    '099': [
      '36033',
      '36401',
      '36425',
      '36439',
      '36444',
      '36445',
      '36460',
      '36470',
      '36471',
      '36475',
      '36480',
      '36481',
      '36502',
      '36751',
      '36753',
      '36768'
    ],
    '067': [
      '36027',
      '36303',
      '36310',
      '36317',
      '36319',
      '36345',
      '36353',
      '36373'
    ],
    '119': [
      '35442',
      '35459',
      '35460',
      '35464',
      '35470',
      '35477',
      '36901',
      '36907',
      '36922',
      '36925'
    ],
    '055': [
      '35901',
      '35903',
      '35904',
      '35905',
      '35906',
      '35907',
      '35952',
      '35954',
      '35956',
      '35961',
      '35972',
      '35990',
      '36272'
    ],
    '029': [
      '36207',
      '36258',
      '36262',
      '36264',
      '36269',
      '36272',
      '36273'
    ],
    '109': [
      '36005',
      '36010',
      '36034',
      '36035',
      '36049',
      '36069',
      '36079',
      '36081',
      '36082'
    ],
    '021': [
      '35040',
      '35045',
      '35046',
      '35085',
      '35115',
      '35171',
      '36006',
      '36051',
      '36091',
      '36750',
      '36758',
      '36790',
      '36792',
      '36793'
    ],
    '053': [
      '36420',
      '36426',
      '36432',
      '36441',
      '36483',
      '36502',
      '36543',
      '36562'
    ],
    '031': [
      '36009',
      '36010',
      '36034',
      '36079',
      '36311',
      '36316',
      '36318',
      '36322',
      '36323',
      '36330',
      '36346',
      '36351',
      '36360',
      '36453',
      '36467',
      '36477'
    ],
    '091': [
      '36722',
      '36728',
      '36732',
      '36736',
      '36738',
      '36742',
      '36748',
      '36754',
      '36763',
      '36769',
      '36773',
      '36782',
      '36783',
      '36784',
      '36786'
    ],
    '063': [
      '35442',
      '35443',
      '35462',
      '35463',
      '35464',
      '35469',
      '35480',
      '36740'
    ],
    '025': [
      '36436',
      '36446',
      '36451',
      '36482',
      '36524',
      '36540',
      '36545',
      '36727',
      '36751',
      '36784'
    ],
    '059': [
      '35564',
      '35565',
      '35571',
      '35581',
      '35582',
      '35585',
      '35593',
      '35653',
      '35654'
    ],
    '061': [
      '36301',
      '36305',
      '36313',
      '36314',
      '36316',
      '36318',
      '36322',
      '36340',
      '36344',
      '36352',
      '36375',
      '36442',
      '36453',
      '36477'
    ],
    '111': [
      '36258',
      '36263',
      '36264',
      '36266',
      '36274',
      '36276',
      '36278',
      '36280'
    ],
    '039': [
      '36028',
      '36038',
      '36420',
      '36421',
      '36442',
      '36453',
      '36455',
      '36456',
      '36467',
      '36474',
      '36476',
      '36483'
    ],
    '097': [
      '36505',
      '36509',
      '36512',
      '36513',
      '36521',
      '36522',
      '36523',
      '36525',
      '36528',
      '36541',
      '36544',
      '36560',
      '36568',
      '36571',
      '36572',
      '36575',
      '36582',
      '36587',
      '36590',
      '36602',
      '36603',
      '36604',
      '36605',
      '36606',
      '36607',
      '36608',
      '36609',
      '36610',
      '36611',
      '36612',
      '36613',
      '36615',
      '36616',
      '36617',
      '36618',
      '36619',
      '36688',
      '36693',
      '36695'
    ],
    '011': [
      '36005',
      '36029',
      '36052',
      '36053',
      '36081',
      '36089',
      '36860'
    ],
    '117': [
      '35007',
      '35022',
      '35035',
      '35040',
      '35043',
      '35051',
      '35078',
      '35080',
      '35094',
      '35114',
      '35115',
      '35124',
      '35143',
      '35147',
      '35176',
      '35178',
      '35186',
      '35187',
      '35216',
      '35242',
      '35243',
      '35244'
    ],
    '041': [
      '36009',
      '36028',
      '36034',
      '36035',
      '36036',
      '36037',
      '36041',
      '36042',
      '36046',
      '36049',
      '36071'
    ],
    '033': [
      '35616',
      '35646',
      '35653',
      '35654',
      '35660',
      '35661',
      '35672',
      '35674',
      '38852'
    ],
    '037': [
      '35010',
      '35046',
      '35072',
      '35089',
      '35136',
      '35150',
      '35151',
      '35183',
      '36026',
      '36080',
      '36091'
    ],
    '085': [
      '36032',
      '36040',
      '36043',
      '36047',
      '36752',
      '36761',
      '36785'
    ],
    '017': [
      '36274',
      '36276',
      '36801',
      '36850',
      '36852',
      '36854',
      '36855',
      '36862',
      '36863',
      '36879'
    ],
    '089': [
      '35739',
      '35741',
      '35748',
      '35749',
      '35750',
      '35756',
      '35757',
      '35758',
      '35759',
      '35760',
      '35761',
      '35763',
      '35773',
      '35776',
      '35801',
      '35802',
      '35803',
      '35805',
      '35806',
      '35808',
      '35810',
      '35811',
      '35816',
      '35824',
      '35896'
    ],
    '045': [
      '36010',
      '36303',
      '36311',
      '36317',
      '36322',
      '36330',
      '36345',
      '36350',
      '36352',
      '36353',
      '36360',
      '36362',
      '36371',
      '36374'
    ],
    '131': [
      '36435',
      '36481',
      '36720',
      '36722',
      '36723',
      '36726',
      '36728',
      '36751',
      '36753',
      '36761',
      '36766',
      '36768',
      '36769',
      '36783',
      '36784'
    ],
    '107': [
      '35442',
      '35447',
      '35461',
      '35466',
      '35481',
      '35555',
      '35574',
      '35576'
    ],
    '127': [
      '35006',
      '35033',
      '35062',
      '35063',
      '35130',
      '35148',
      '35501',
      '35503',
      '35504',
      '35546',
      '35549',
      '35550',
      '35554',
      '35578',
      '35579',
      '35580',
      '35584',
      '35587'
    ],
    '101': [
      '36013',
      '36036',
      '36041',
      '36043',
      '36046',
      '36047',
      '36052',
      '36064',
      '36069',
      '36104',
      '36105',
      '36106',
      '36107',
      '36108',
      '36109',
      '36110',
      '36111',
      '36112',
      '36113',
      '36115',
      '36116',
      '36117'
    ],
    '121': [
      '35014',
      '35032',
      '35044',
      '35072',
      '35096',
      '35149',
      '35150',
      '35151',
      '35160',
      '36203',
      '36260',
      '36268'
    ],
    '057': [
      '35542',
      '35545',
      '35546',
      '35549',
      '35554',
      '35555',
      '35559',
      '35563',
      '35574',
      '35594'
    ],
    '001': [
      '36003',
      '36006',
      '36022',
      '36051',
      '36066',
      '36067',
      '36091',
      '36703',
      '36749',
      '36758'
    ],
    '129': [
      '36513',
      '36518',
      '36522',
      '36529',
      '36538',
      '36539',
      '36548',
      '36553',
      '36556',
      '36558',
      '36569',
      '36581',
      '36583',
      '36584',
      '36585',
      '36919'
    ],
    '103': [
      '35019',
      '35087',
      '35175',
      '35179',
      '35601',
      '35603',
      '35619',
      '35621',
      '35622',
      '35640',
      '35670',
      '35673',
      '35754',
      '35775'
    ],
    '095': [
      '35016',
      '35087',
      '35175',
      '35747',
      '35754',
      '35755',
      '35760',
      '35769',
      '35776',
      '35950',
      '35951',
      '35957',
      '35962',
      '35975',
      '35976',
      '35980'
    ],
    '087': [
      '36029',
      '36031',
      '36039',
      '36075',
      '36083',
      '36088',
      '36089',
      '36804',
      '36830',
      '36832',
      '36860',
      '36866'
    ],
    '115': [
      '35004',
      '35052',
      '35054',
      '35094',
      '35112',
      '35120',
      '35121',
      '35125',
      '35128',
      '35131',
      '35133',
      '35135',
      '35146',
      '35173',
      '35178',
      '35953',
      '35972',
      '35987'
    ],
    '035': [
      '36033',
      '36401',
      '36421',
      '36426',
      '36432',
      '36454',
      '36456',
      '36460',
      '36471',
      '36473',
      '36474',
      '36475',
      '36502'
    ],
    '005': [
      '36005',
      '36016',
      '36017',
      '36027',
      '36048',
      '36053',
      '36311',
      '36317',
      '36374'
    ],
    '051': [
      '35010',
      '36020',
      '36022',
      '36024',
      '36025',
      '36026',
      '36051',
      '36054',
      '36066',
      '36078',
      '36080',
      '36092',
      '36093'
    ],
    '077': [
      '35610',
      '35614',
      '35630',
      '35633',
      '35634',
      '35645',
      '35648',
      '35652',
      '35677'
    ],
    '027': [
      '35072',
      '35082',
      '35150',
      '35160',
      '36251',
      '36255',
      '36256',
      '36258',
      '36266',
      '36267',
      '36268',
      '36276'
    ],
    '069': [
      '36301',
      '36303',
      '36305',
      '36312',
      '36319',
      '36320',
      '36321',
      '36343',
      '36345',
      '36350',
      '36352',
      '36370',
      '36375',
      '36376'
    ],
    '093': [
      '35543',
      '35548',
      '35552',
      '35554',
      '35563',
      '35564',
      '35565',
      '35570',
      '35571',
      '35581',
      '35593',
      '35594'
    ],
    '009': [
      '35013',
      '35016',
      '35031',
      '35049',
      '35063',
      '35079',
      '35097',
      '35121',
      '35126',
      '35133',
      '35146',
      '35172',
      '35180',
      '35952',
      '35957',
      '35976',
      '35980'
    ],
    '105': [
      '35034',
      '36701',
      '36744',
      '36756',
      '36759',
      '36765',
      '36786',
      '36793'
    ],
    '113': [
      '31905',
      '36027',
      '36804',
      '36856',
      '36858',
      '36859',
      '36860',
      '36867',
      '36869',
      '36870',
      '36871',
      '36874',
      '36875'
    ],
    '133': [
      '35053',
      '35098',
      '35179',
      '35503',
      '35540',
      '35541',
      '35553',
      '35565',
      '35572',
      '35575',
      '35577',
      '35578'
    ],
    '071': [
      '35740',
      '35744',
      '35745',
      '35746',
      '35748',
      '35751',
      '35752',
      '35755',
      '35764',
      '35765',
      '35766',
      '35768',
      '35769',
      '35771',
      '35772',
      '35774',
      '35776',
      '35958',
      '35966',
      '35978',
      '35979'
    ],
    '075': [
      '35544',
      '35552',
      '35555',
      '35563',
      '35570',
      '35574',
      '35576',
      '35586',
      '35592'
    ],
    '047': [
      '36701',
      '36703',
      '36749',
      '36758',
      '36759',
      '36761',
      '36767',
      '36773',
      '36775',
      '36785'
    ],
    '081': [
      '36801',
      '36804',
      '36830',
      '36832',
      '36849',
      '36850',
      '36852',
      '36854',
      '36865',
      '36866',
      '36867',
      '36870',
      '36874',
      '36877',
      '36879'
    ],
    '007': [
      '35034',
      '35035',
      '35042',
      '35074',
      '35111',
      '35115',
      '35184',
      '35188',
      '35456',
      '36750',
      '36792',
      '36793'
    ],
    '023': [
      '36558',
      '36904',
      '36908',
      '36910',
      '36912',
      '36913',
      '36915',
      '36916',
      '36919',
      '36921',
      '36922'
    ],
    '043': [
      '35016',
      '35019',
      '35033',
      '35053',
      '35055',
      '35057',
      '35058',
      '35070',
      '35077',
      '35083',
      '35087',
      '35098',
      '35179',
      '35540',
      '35541',
      '35619',
      '35621',
      '35622',
      '35976'
    ],
    '065': [
      '35441',
      '35474',
      '36738',
      '36742',
      '36744',
      '36765',
      '36776'
    ],
    '125': [
      '35006',
      '35111',
      '35188',
      '35401',
      '35404',
      '35405',
      '35406',
      '35444',
      '35446',
      '35452',
      '35453',
      '35456',
      '35457',
      '35458',
      '35463',
      '35466',
      '35469',
      '35473',
      '35474',
      '35475',
      '35476',
      '35480',
      '35490',
      '35546',
      '35555',
      '35574',
      '35579'
    ],
    '049': [
      '35765',
      '35771',
      '35951',
      '35954',
      '35957',
      '35961',
      '35962',
      '35963',
      '35966',
      '35967',
      '35968',
      '35971',
      '35974',
      '35975',
      '35978',
      '35979',
      '35981',
      '35984',
      '35986',
      '35988',
      '35989'
    ],
    '019': [
      '30165',
      '35901',
      '35903',
      '35959',
      '35960',
      '35961',
      '35967',
      '35973',
      '35983',
      '35984',
      '36272'
    ],
    '003': [
      '36480',
      '36502',
      '36507',
      '36511',
      '36526',
      '36527',
      '36530',
      '36532',
      '36535',
      '36542',
      '36549',
      '36550',
      '36551',
      '36555',
      '36559',
      '36561',
      '36562',
      '36564',
      '36567',
      '36574',
      '36576',
      '36578',
      '36579',
      '36580'
    ],
    '083': [
      '35610',
      '35611',
      '35613',
      '35614',
      '35615',
      '35620',
      '35647',
      '35649',
      '35652',
      '35671',
      '35739',
      '35749',
      '35756',
      '35757',
      '35773'
    ],
    '013': [
      '36030',
      '36032',
      '36033',
      '36037',
      '36042',
      '36071',
      '36456',
      '36474'
    ],
    '073': [
      '35005',
      '35006',
      '35020',
      '35022',
      '35023',
      '35036',
      '35060',
      '35061',
      '35062',
      '35063',
      '35064',
      '35068',
      '35071',
      '35073',
      '35079',
      '35080',
      '35091',
      '35094',
      '35111',
      '35116',
      '35117',
      '35118',
      '35119',
      '35126',
      '35127',
      '35130',
      '35139',
      '35146',
      '35148',
      '35172',
      '35173',
      '35180',
      '35203',
      '35204',
      '35205',
      '35206',
      '35207',
      '35208',
      '35209',
      '35210',
      '35211',
      '35212',
      '35213',
      '35214',
      '35215',
      '35216',
      '35217',
      '35218',
      '35221',
      '35222',
      '35223',
      '35224',
      '35226',
      '35228',
      '35229',
      '35233',
      '35234',
      '35235',
      '35242',
      '35243',
      '35244',
      '35254',
      '35444'
    ],
    '015': [
      '35096',
      '35905',
      '36201',
      '36203',
      '36205',
      '36206',
      '36207',
      '36250',
      '36260',
      '36265',
      '36271',
      '36272',
      '36277',
      '36279'
    ]
  },
  '38': {
    '079': [
      '58316',
      '58329',
      '58353',
      '58363',
      '58366',
      '58367',
      '58369',
      '58384',
      '58385'
    ],
    '099': [
      '56744',
      '58210',
      '58225',
      '58227',
      '58229',
      '58231',
      '58233',
      '58237',
      '58243',
      '58250',
      '58261',
      '58270',
      '58273',
      '58321',
      '58345'
    ],
    '067': [
      '58216',
      '58220',
      '58222',
      '58225',
      '58227',
      '58238',
      '58241',
      '58243',
      '58262',
      '58265',
      '58271',
      '58276',
      '58282'
    ],
    '055': [
      '58531',
      '58540',
      '58559',
      '58565',
      '58575',
      '58576',
      '58577',
      '58579',
      '58716',
      '58723',
      '58735',
      '58759',
      '58763',
      '58770',
      '58771',
      '58775',
      '58778',
      '58779'
    ],
    '029': [
      '57648',
      '58524',
      '58542',
      '58544',
      '58549',
      '58552',
      '58573'
    ],
    '021': [
      '58413',
      '58433',
      '58436',
      '58439',
      '58441',
      '58456',
      '58458',
      '58474'
    ],
    '053': [
      '58634',
      '58757',
      '58763',
      '58801',
      '58831',
      '58835',
      '58838',
      '58847',
      '58854',
      '59221',
      '59270'
    ],
    '031': [
      '58356',
      '58421',
      '58443',
      '58445',
      '58455',
      '58464',
      '58484'
    ],
    '091': [
      '58016',
      '58035',
      '58046',
      '58056',
      '58064',
      '58212',
      '58230',
      '58240',
      '58267',
      '58274',
      '58277'
    ],
    '063': [
      '58212',
      '58224',
      '58254',
      '58259',
      '58266',
      '58272',
      '58321',
      '58344',
      '58361',
      '58380'
    ],
    '025': [
      '58601',
      '58625',
      '58626',
      '58630',
      '58636',
      '58638',
      '58640',
      '58642',
      '58652',
      '58656',
      '58757',
      '58854'
    ],
    '059': [
      '58520',
      '58535',
      '58554',
      '58563',
      '58566',
      '58570',
      '58631',
      '58638'
    ],
    '039': [
      '58056',
      '58212',
      '58416',
      '58425',
      '58429',
      '58448',
      '58452',
      '58464',
      '58484',
      '58492'
    ],
    '011': [
      '58623',
      '58643',
      '58649',
      '58651',
      '58653'
    ],
    '041': [
      '58562',
      '58646',
      '58647',
      '58649',
      '58650'
    ],
    '027': [
      '58356',
      '58374',
      '58380',
      '58381',
      '58421',
      '58445',
      '58464'
    ],
    '037': [
      '57638',
      '57645',
      '57660',
      '58520',
      '58529',
      '58533',
      '58535',
      '58562',
      '58564',
      '58569',
      '58631',
      '58638'
    ],
    '085': [
      '57638',
      '57641',
      '57642',
      '57645',
      '57660',
      '58528',
      '58538',
      '58568',
      '58570'
    ],
    '103': [
      '58341',
      '58348',
      '58356',
      '58374',
      '58418',
      '58421',
      '58422',
      '58423',
      '58438',
      '58451',
      '58486',
      '58758'
    ],
    '089': [
      '58601',
      '58622',
      '58630',
      '58638',
      '58641',
      '58647',
      '58652',
      '58655',
      '58656'
    ],
    '045': [
      '58049',
      '58415',
      '58431',
      '58433',
      '58454',
      '58456',
      '58458',
      '58461',
      '58466',
      '58472',
      '58490'
    ],
    '081': [
      '58013',
      '58017',
      '58032',
      '58040',
      '58043',
      '58053',
      '58060',
      '58067',
      '58069',
      '58474'
    ],
    '097': [
      '58009',
      '58016',
      '58035',
      '58038',
      '58045',
      '58048',
      '58218',
      '58219',
      '58223',
      '58240',
      '58257',
      '58274',
      '58275'
    ],
    '057': [
      '58523',
      '58541',
      '58545',
      '58571',
      '58580',
      '58625',
      '58631',
      '58636',
      '58638'
    ],
    '001': [
      '57638',
      '58562',
      '58639',
      '58646',
      '58649',
      '58650'
    ],
    '017': [
      '58002',
      '58004',
      '58005',
      '58006',
      '58007',
      '58011',
      '58012',
      '58021',
      '58027',
      '58029',
      '58031',
      '58035',
      '58036',
      '58038',
      '58042',
      '58046',
      '58047',
      '58048',
      '58051',
      '58052',
      '58059',
      '58064',
      '58071',
      '58078',
      '58079',
      '58102',
      '58103',
      '58104',
      '58105'
    ],
    '087': [
      '58620',
      '58623',
      '58643',
      '58647',
      '58651',
      '58653',
      '58654'
    ],
    '095': [
      '58317',
      '58324',
      '58331',
      '58339',
      '58346',
      '58352',
      '58363',
      '58365',
      '58367',
      '58372',
      '58377'
    ],
    '035': [
      '56744',
      '58201',
      '58202',
      '58203',
      '58204',
      '58205',
      '58212',
      '58214',
      '58228',
      '58231',
      '58233',
      '58235',
      '58240',
      '58244',
      '58251',
      '58256',
      '58258',
      '58261',
      '58266',
      '58267',
      '58275',
      '58278'
    ],
    '005': [
      '58301',
      '58325',
      '58332',
      '58335',
      '58341',
      '58343',
      '58346',
      '58348',
      '58351',
      '58357',
      '58368',
      '58370',
      '58374',
      '58379',
      '58380',
      '58381',
      '58386'
    ],
    '051': [
      '58413',
      '58440',
      '58456',
      '58460',
      '58495',
      '58581'
    ],
    '077': [
      '58001',
      '58008',
      '58015',
      '58018',
      '58030',
      '58041',
      '58047',
      '58051',
      '58052',
      '58053',
      '58057',
      '58058',
      '58061',
      '58075',
      '58076',
      '58077',
      '58081'
    ],
    '033': [
      '58621',
      '58632',
      '58645',
      '58654'
    ],
    '069': [
      '58332',
      '58341',
      '58368',
      '58384',
      '58385',
      '58386',
      '58710',
      '58758',
      '58788'
    ],
    '093': [
      '58401',
      '58402',
      '58405',
      '58420',
      '58421',
      '58424',
      '58426',
      '58442',
      '58454',
      '58455',
      '58466',
      '58467',
      '58472',
      '58475',
      '58476',
      '58481',
      '58483',
      '58486',
      '58492',
      '58496',
      '58497'
    ],
    '009': [
      '58318',
      '58329',
      '58384',
      '58711',
      '58740',
      '58748',
      '58750',
      '58760',
      '58761',
      '58762',
      '58782',
      '58783',
      '58789',
      '58793'
    ],
    '105': [
      '58755',
      '58795',
      '58801',
      '58830',
      '58843',
      '58845',
      '58849',
      '58852',
      '58853',
      '58856'
    ],
    '071': [
      '58229',
      '58301',
      '58321',
      '58325',
      '58327',
      '58330',
      '58338',
      '58344',
      '58345',
      '58351',
      '58362',
      '58377',
      '58382'
    ],
    '075': [
      '58718',
      '58725',
      '58734',
      '58740',
      '58746',
      '58750',
      '58761',
      '58782',
      '58787'
    ],
    '047': [
      '58440',
      '58442',
      '58460',
      '58483',
      '58495',
      '58549',
      '58561'
    ],
    '083': [
      '58341',
      '58430',
      '58444',
      '58463',
      '58559',
      '58710',
      '58723',
      '58736',
      '58758'
    ],
    '023': [
      '58730',
      '58755',
      '58765',
      '58795',
      '58830',
      '58833',
      '58844',
      '58845',
      '58856',
      '59275'
    ],
    '043': [
      '58418',
      '58423',
      '58428',
      '58467',
      '58475',
      '58478',
      '58482',
      '58483',
      '58487',
      '58488',
      '58524',
      '58532',
      '58549'
    ],
    '101': [
      '58701',
      '58702',
      '58703',
      '58704',
      '58705',
      '58707',
      '58716',
      '58718',
      '58721',
      '58722',
      '58725',
      '58731',
      '58733',
      '58734',
      '58735',
      '58740',
      '58746',
      '58756',
      '58759',
      '58768',
      '58771',
      '58779',
      '58781',
      '58785',
      '58790'
    ],
    '065': [
      '58523',
      '58530',
      '58545',
      '58554',
      '58563',
      '58571'
    ],
    '061': [
      '58718',
      '58734',
      '58756',
      '58763',
      '58769',
      '58770',
      '58771',
      '58773',
      '58776',
      '58784',
      '58794',
      '58852'
    ],
    '049': [
      '58368',
      '58384',
      '58710',
      '58712',
      '58713',
      '58723',
      '58731',
      '58736',
      '58741',
      '58744',
      '58748',
      '58762',
      '58768',
      '58778',
      '58788',
      '58789',
      '58790',
      '58792'
    ],
    '019': [
      '58227',
      '58229',
      '58239',
      '58249',
      '58260',
      '58269',
      '58281',
      '58282',
      '58311',
      '58323',
      '58338',
      '58352',
      '58355',
      '58372',
      '58377'
    ],
    '003': [
      '58027',
      '58031',
      '58046',
      '58049',
      '58056',
      '58062',
      '58063',
      '58064',
      '58065',
      '58071',
      '58072',
      '58429',
      '58461',
      '58466',
      '58479',
      '58480',
      '58481',
      '58492',
      '58497'
    ],
    '007': [
      '58620',
      '58622',
      '58627',
      '58634',
      '58642',
      '58645'
    ],
    '013': [
      '58721',
      '58727',
      '58737',
      '58746',
      '58752',
      '58755',
      '58772',
      '58773',
      '58784'
    ],
    '073': [
      '58027',
      '58033',
      '58049',
      '58052',
      '58054',
      '58057',
      '58060',
      '58062',
      '58068',
      '58069',
      '58490'
    ],
    '015': [
      '58477',
      '58494',
      '58501',
      '58503',
      '58504',
      '58505',
      '58521',
      '58532',
      '58558',
      '58560',
      '58572',
      '58579'
    ]
  },
  '09': {
    '003': [
      '06001',
      '06002',
      '06010',
      '06013',
      '06016',
      '06019',
      '06020',
      '06022',
      '06023',
      '06026',
      '06027',
      '06032',
      '06033',
      '06035',
      '06037',
      '06040',
      '06042',
      '06051',
      '06052',
      '06053',
      '06060',
      '06062',
      '06065',
      '06067',
      '06070',
      '06073',
      '06074',
      '06078',
      '06081',
      '06082',
      '06085',
      '06088',
      '06089',
      '06090',
      '06091',
      '06092',
      '06093',
      '06095',
      '06096',
      '06103',
      '06105',
      '06106',
      '06107',
      '06108',
      '06109',
      '06110',
      '06111',
      '06112',
      '06114',
      '06117',
      '06118',
      '06119',
      '06120',
      '06160',
      '06444',
      '06447',
      '06451',
      '06467',
      '06479',
      '06489'
    ],
    '001': [
      '06468',
      '06470',
      '06482',
      '06484',
      '06604',
      '06605',
      '06606',
      '06607',
      '06608',
      '06610',
      '06611',
      '06612',
      '06614',
      '06615',
      '06776',
      '06784',
      '06801',
      '06804',
      '06807',
      '06810',
      '06811',
      '06812',
      '06820',
      '06824',
      '06825',
      '06830',
      '06831',
      '06840',
      '06850',
      '06851',
      '06853',
      '06854',
      '06855',
      '06856',
      '06870',
      '06877',
      '06878',
      '06880',
      '06883',
      '06890',
      '06896',
      '06897',
      '06901',
      '06902',
      '06903',
      '06905',
      '06906',
      '06907'
    ],
    '009': [
      '06401',
      '06403',
      '06405',
      '06410',
      '06418',
      '06437',
      '06443',
      '06450',
      '06451',
      '06460',
      '06461',
      '06471',
      '06472',
      '06473',
      '06477',
      '06478',
      '06483',
      '06488',
      '06492',
      '06510',
      '06511',
      '06512',
      '06513',
      '06514',
      '06515',
      '06516',
      '06517',
      '06518',
      '06519',
      '06524',
      '06525',
      '06702',
      '06704',
      '06705',
      '06706',
      '06708',
      '06710',
      '06712',
      '06716',
      '06762',
      '06770'
    ],
    '007': [
      '06409',
      '06412',
      '06413',
      '06414',
      '06415',
      '06416',
      '06417',
      '06419',
      '06420',
      '06422',
      '06423',
      '06424',
      '06426',
      '06438',
      '06441',
      '06442',
      '06455',
      '06456',
      '06457',
      '06469',
      '06475',
      '06480',
      '06481',
      '06498'
    ],
    '005': [
      '06018',
      '06021',
      '06024',
      '06031',
      '06039',
      '06057',
      '06058',
      '06059',
      '06061',
      '06063',
      '06065',
      '06068',
      '06069',
      '06098',
      '06708',
      '06750',
      '06751',
      '06752',
      '06754',
      '06755',
      '06756',
      '06757',
      '06758',
      '06759',
      '06763',
      '06776',
      '06777',
      '06778',
      '06779',
      '06782',
      '06783',
      '06784',
      '06785',
      '06786',
      '06787',
      '06790',
      '06791',
      '06793',
      '06794',
      '06795',
      '06796',
      '06798'
    ],
    '011': [
      '06249',
      '06254',
      '06320',
      '06330',
      '06333',
      '06334',
      '06335',
      '06336',
      '06339',
      '06340',
      '06350',
      '06351',
      '06353',
      '06355',
      '06357',
      '06359',
      '06360',
      '06365',
      '06370',
      '06371',
      '06375',
      '06376',
      '06378',
      '06379',
      '06380',
      '06382',
      '06384',
      '06385',
      '06389',
      '06415',
      '06420'
    ],
    '013': [
      '06029',
      '06043',
      '06066',
      '06071',
      '06076',
      '06084',
      '06226',
      '06231',
      '06232',
      '06237',
      '06238',
      '06242',
      '06248',
      '06250',
      '06268',
      '06269',
      '06279'
    ],
    '015': [
      '06226',
      '06234',
      '06235',
      '06239',
      '06241',
      '06242',
      '06243',
      '06247',
      '06250',
      '06255',
      '06256',
      '06259',
      '06260',
      '06262',
      '06263',
      '06264',
      '06266',
      '06277',
      '06278',
      '06280',
      '06281',
      '06282',
      '06330',
      '06331',
      '06332',
      '06354',
      '06373',
      '06374',
      '06377',
      '06384',
      '06387'
    ]
  },
  '42': {
    '079': [
      '17814',
      '17815',
      '17878',
      '18201',
      '18202',
      '18216',
      '18219',
      '18221',
      '18222',
      '18223',
      '18224',
      '18225',
      '18234',
      '18239',
      '18241',
      '18244',
      '18246',
      '18247',
      '18249',
      '18251',
      '18255',
      '18256',
      '18424',
      '18601',
      '18602',
      '18603',
      '18610',
      '18611',
      '18612',
      '18615',
      '18617',
      '18618',
      '18621',
      '18622',
      '18627',
      '18634',
      '18635',
      '18636',
      '18640',
      '18641',
      '18642',
      '18643',
      '18644',
      '18651',
      '18654',
      '18655',
      '18656',
      '18660',
      '18661',
      '18701',
      '18702',
      '18703',
      '18704',
      '18705',
      '18706',
      '18707',
      '18708',
      '18709',
      '18710',
      '18711',
      '18762',
      '18764',
      '18765',
      '18766',
      '18767',
      '18769',
      '18773',
    ],
    '123': [
      '16312',
      '16313',
      '16329',
      '16340',
      '16345',
      '16347',
      '16350',
      '16351',
      '16352',
      '16354',
      '16365',
      '16371',
      '16402',
      '16405',
      '16407',
      '16416',
      '16420',
      '16434',
      '16436',
      '16701'
    ],
    '099': [
      '17006',
      '17020',
      '17021',
      '17024',
      '17035',
      '17037',
      '17040',
      '17045',
      '17047',
      '17053',
      '17062',
      '17068',
      '17069',
      '17071',
      '17074',
      '17090'
    ],
    '067': [
      '17021',
      '17035',
      '17045',
      '17049',
      '17056',
      '17058',
      '17059',
      '17062',
      '17076',
      '17082',
      '17086',
      '17094',
      '17243',
      '17853'
    ],
    '119': [
      '17810',
      '17829',
      '17835',
      '17837',
      '17844',
      '17845',
      '17855',
      '17856',
      '17870',
      '17880',
      '17885',
      '17886',
      '17887',
      '17889'
    ],
    '055': [
      '17021',
      '17201',
      '17202',
      '17210',
      '17214',
      '17217',
      '17219',
      '17220',
      '17221',
      '17222',
      '17224',
      '17225',
      '17235',
      '17236',
      '17237',
      '17240',
      '17244',
      '17246',
      '17247',
      '17250',
      '17251',
      '17252',
      '17254',
      '17256',
      '17257',
      '17261',
      '17262',
      '17263',
      '17265',
      '17268',
      '17270',
      '17271',
      '17272'
    ],
    '029': [
      '17527',
      '19073',
      '19087',
      '19301',
      '19310',
      '19311',
      '19312',
      '19316',
      '19317',
      '19319',
      '19320',
      '19330',
      '19333',
      '19335',
      '19341',
      '19342',
      '19343',
      '19344',
      '19345',
      '19348',
      '19350',
      '19352',
      '19355',
      '19358',
      '19362',
      '19363',
      '19365',
      '19367',
      '19372',
      '19374',
      '19375',
      '19380',
      '19382',
      '19383',
      '19390',
      '19425',
      '19442',
      '19457',
      '19460',
      '19465',
      '19475',
      '19520',
      '19543'
    ],
    '109': [
      '17045',
      '17063',
      '17086',
      '17812',
      '17813',
      '17827',
      '17841',
      '17842',
      '17853',
      '17861',
      '17862',
      '17864',
      '17870',
      '17876',
      '17889'
    ],
    '021': [
      '15714',
      '15722',
      '15724',
      '15737',
      '15738',
      '15760',
      '15762',
      '15773',
      '15775',
      '15901',
      '15902',
      '15904',
      '15905',
      '15906',
      '15909',
      '15921',
      '15922',
      '15925',
      '15927',
      '15930',
      '15931',
      '15934',
      '15938',
      '15940',
      '15942',
      '15943',
      '15945',
      '15946',
      '15948',
      '15951',
      '15952',
      '15955',
      '15956',
      '15957',
      '15958',
      '15960',
      '15961',
      '15962',
      '15963',
      '16613',
      '16619',
      '16624',
      '16627',
      '16630',
      '16636',
      '16639',
      '16640',
      '16641',
      '16646',
      '16668',
      '16699'
    ],
    '053': [
      '15828',
      '16217',
      '16239',
      '16321',
      '16322',
      '16341',
      '16347',
      '16351',
      '16353',
      '16370'
    ],
    '031': [
      '15829',
      '15860',
      '15864',
      '16028',
      '16036',
      '16049',
      '16054',
      '16213',
      '16214',
      '16224',
      '16230',
      '16232',
      '16233',
      '16235',
      '16240',
      '16242',
      '16248',
      '16254',
      '16255',
      '16258',
      '16260',
      '16319',
      '16326',
      '16331',
      '16332',
      '16334',
      '16353',
      '16361',
      '16364',
      '16373'
    ],
    '091': [
      '18041',
      '18054',
      '18070',
      '18073',
      '18074',
      '18076',
      '18915',
      '18936',
      '18964',
      '18969',
      '19001',
      '19002',
      '19003',
      '19004',
      '19006',
      '19009',
      '19010',
      '19012',
      '19025',
      '19027',
      '19031',
      '19034',
      '19035',
      '19038',
      '19040',
      '19041',
      '19044',
      '19046',
      '19066',
      '19072',
      '19075',
      '19083',
      '19085',
      '19087',
      '19090',
      '19095',
      '19096',
      '19118',
      '19150',
      '19401',
      '19403',
      '19405',
      '19406',
      '19422',
      '19426',
      '19428',
      '19435',
      '19436',
      '19437',
      '19438',
      '19440',
      '19444',
      '19446',
      '19453',
      '19454',
      '19456',
      '19460',
      '19462',
      '19464',
      '19468',
      '19472',
      '19473',
      '19474',
      '19477',
      '19492',
      '19504',
      '19505',
      '19512',
      '19525'
    ],
    '063': [
      '15618',
      '15681',
      '15701',
      '15710',
      '15712',
      '15713',
      '15714',
      '15716',
      '15717',
      '15723',
      '15724',
      '15725',
      '15727',
      '15728',
      '15729',
      '15731',
      '15732',
      '15734',
      '15739',
      '15741',
      '15742',
      '15745',
      '15746',
      '15747',
      '15748',
      '15750',
      '15752',
      '15754',
      '15756',
      '15757',
      '15759',
      '15761',
      '15765',
      '15767',
      '15771',
      '15772',
      '15774',
      '15777',
      '15783',
      '15920',
      '15929',
      '15944',
      '15949',
      '15954',
      '15957',
      '15961',
      '16211',
      '16222',
      '16246',
      '16256'
    ],
    '025': [
      '18012',
      '18030',
      '18058',
      '18071',
      '18201',
      '18210',
      '18211',
      '18212',
      '18216',
      '18229',
      '18230',
      '18232',
      '18235',
      '18237',
      '18240',
      '18244',
      '18250',
      '18254',
      '18255',
      '18610',
      '18624',
      '18661'
    ],
    '059': [
      '15310',
      '15315',
      '15316',
      '15320',
      '15322',
      '15325',
      '15327',
      '15329',
      '15334',
      '15337',
      '15338',
      '15341',
      '15344',
      '15345',
      '15346',
      '15349',
      '15351',
      '15352',
      '15353',
      '15357',
      '15359',
      '15362',
      '15364',
      '15370',
      '15377',
      '15380'
    ],
    '111': [
      '15411',
      '15424',
      '15501',
      '15502',
      '15510',
      '15520',
      '15530',
      '15531',
      '15532',
      '15534',
      '15538',
      '15540',
      '15541',
      '15542',
      '15544',
      '15545',
      '15546',
      '15547',
      '15551',
      '15552',
      '15555',
      '15557',
      '15558',
      '15559',
      '15560',
      '15561',
      '15562',
      '15563',
      '15564',
      '15622',
      '15905',
      '15924',
      '15926',
      '15928',
      '15935',
      '15936',
      '15937',
      '15953',
      '15963'
    ],
    '039': [
      '16110',
      '16111',
      '16125',
      '16131',
      '16134',
      '16314',
      '16316',
      '16317',
      '16327',
      '16328',
      '16335',
      '16354',
      '16360',
      '16401',
      '16403',
      '16404',
      '16406',
      '16407',
      '16412',
      '16422',
      '16424',
      '16433',
      '16434',
      '16435',
      '16438',
      '16440',
      '16441'
    ],
    '011': [
      '17026',
      '17067',
      '17087',
      '17555',
      '17569',
      '18011',
      '18031',
      '18041',
      '18056',
      '18062',
      '18070',
      '18092',
      '19464',
      '19503',
      '19504',
      '19505',
      '19506',
      '19507',
      '19508',
      '19510',
      '19511',
      '19512',
      '19518',
      '19519',
      '19520',
      '19522',
      '19523',
      '19526',
      '19529',
      '19530',
      '19533',
      '19534',
      '19535',
      '19536',
      '19538',
      '19539',
      '19540',
      '19541',
      '19542',
      '19543',
      '19544',
      '19545',
      '19547',
      '19550',
      '19551',
      '19554',
      '19555',
      '19559',
      '19560',
      '19562',
      '19564',
      '19565',
      '19567',
      '19601',
      '19602',
      '19604',
      '19605',
      '19606',
      '19607',
      '19608',
      '19609',
      '19610',
      '19611'
    ],
    '117': [
      '16901',
      '16911',
      '16912',
      '16914',
      '16917',
      '16920',
      '16921',
      '16928',
      '16929',
      '16930',
      '16932',
      '16933',
      '16935',
      '16936',
      '16938',
      '16939',
      '16940',
      '16942',
      '16943',
      '16946',
      '16947',
      '16950',
      '17724',
      '17727',
      '17765'
    ],
    '041': [
      '17007',
      '17011',
      '17013',
      '17015',
      '17019',
      '17025',
      '17027',
      '17043',
      '17050',
      '17053',
      '17055',
      '17065',
      '17070',
      '17072',
      '17081',
      '17093',
      '17240',
      '17241',
      '17257',
      '17266',
      '17307',
      '17324'
    ],
    '033': [
      '15721',
      '15724',
      '15742',
      '15753',
      '15757',
      '15767',
      '15801',
      '15824',
      '15840',
      '15848',
      '15849',
      '15856',
      '15866',
      '16616',
      '16620',
      '16627',
      '16639',
      '16645',
      '16646',
      '16651',
      '16656',
      '16661',
      '16666',
      '16671',
      '16680',
      '16692',
      '16821',
      '16825',
      '16830',
      '16833',
      '16834',
      '16836',
      '16837',
      '16838',
      '16839',
      '16840',
      '16843',
      '16845',
      '16847',
      '16849',
      '16855',
      '16858',
      '16860',
      '16861',
      '16863',
      '16866',
      '16871',
      '16876',
      '16878',
      '16879',
      '16881'
    ],
    '037': [
      '17756',
      '17774',
      '17814',
      '17815',
      '17820',
      '17821',
      '17824',
      '17846',
      '17859',
      '17878',
      '17888',
      '17920',
      '17921',
      '17945',
      '17985',
      '18603',
      '18631',
      '18635',
      '18655'
    ],
    '125': [
      '15004',
      '15012',
      '15017',
      '15019',
      '15021',
      '15022',
      '15025',
      '15026',
      '15033',
      '15038',
      '15053',
      '15054',
      '15055',
      '15057',
      '15060',
      '15063',
      '15067',
      '15078',
      '15126',
      '15129',
      '15241',
      '15301',
      '15311',
      '15312',
      '15313',
      '15314',
      '15317',
      '15321',
      '15322',
      '15323',
      '15324',
      '15329',
      '15330',
      '15331',
      '15332',
      '15333',
      '15340',
      '15342',
      '15345',
      '15347',
      '15348',
      '15350',
      '15358',
      '15360',
      '15361',
      '15363',
      '15366',
      '15367',
      '15368',
      '15370',
      '15376',
      '15377',
      '15378',
      '15379',
      '15412',
      '15417',
      '15419',
      '15423',
      '15427',
      '15429',
      '15432',
      '15434',
      '15477',
      '15483'
    ],
    '085': [
      '16057',
      '16110',
      '16113',
      '16114',
      '16121',
      '16124',
      '16125',
      '16127',
      '16130',
      '16133',
      '16134',
      '16137',
      '16142',
      '16143',
      '16145',
      '16146',
      '16148',
      '16150',
      '16151',
      '16153',
      '16154',
      '16156',
      '16159',
      '16161',
      '16311',
      '16314',
      '16342',
      '16362'
    ],
    '103': [
      '18302',
      '18324',
      '18325',
      '18328',
      '18336',
      '18337',
      '18340',
      '18371',
      '18405',
      '18425',
      '18426',
      '18428',
      '18435',
      '18445',
      '18451',
      '18457',
      '18458',
      '18460',
      '18464'
    ],
    '097': [
      '17017',
      '17730',
      '17749',
      '17756',
      '17772',
      '17777',
      '17801',
      '17821',
      '17823',
      '17824',
      '17830',
      '17832',
      '17834',
      '17836',
      '17840',
      '17847',
      '17850',
      '17851',
      '17857',
      '17860',
      '17865',
      '17866',
      '17867',
      '17868',
      '17872',
      '17881',
      '17941',
      '17964'
    ],
    '045': [
      '19003',
      '19008',
      '19010',
      '19013',
      '19014',
      '19015',
      '19017',
      '19018',
      '19022',
      '19023',
      '19026',
      '19029',
      '19032',
      '19033',
      '19036',
      '19041',
      '19043',
      '19050',
      '19060',
      '19061',
      '19063',
      '19064',
      '19070',
      '19073',
      '19074',
      '19076',
      '19078',
      '19079',
      '19081',
      '19082',
      '19083',
      '19085',
      '19086',
      '19087',
      '19094',
      '19096',
      '19113',
      '19312',
      '19317',
      '19319',
      '19342',
      '19373',
      '19382'
    ],
    '131': [
      '18414',
      '18419',
      '18446',
      '18612',
      '18614',
      '18615',
      '18618',
      '18623',
      '18625',
      '18629',
      '18630',
      '18636',
      '18657',
      '18844'
    ],
    '107': [
      '17901',
      '17921',
      '17922',
      '17923',
      '17925',
      '17929',
      '17930',
      '17931',
      '17932',
      '17933',
      '17934',
      '17935',
      '17936',
      '17938',
      '17941',
      '17943',
      '17944',
      '17945',
      '17946',
      '17948',
      '17949',
      '17951',
      '17952',
      '17953',
      '17954',
      '17957',
      '17959',
      '17960',
      '17961',
      '17963',
      '17964',
      '17965',
      '17966',
      '17967',
      '17968',
      '17970',
      '17972',
      '17974',
      '17976',
      '17978',
      '17979',
      '17980',
      '17981',
      '17982',
      '17983',
      '17985',
      '18201',
      '18202',
      '18211',
      '18214',
      '18218',
      '18220',
      '18231',
      '18237',
      '18240',
      '18241',
      '18242',
      '18245',
      '18248',
      '18252',
      '19549'
    ],
    '089': [
      '18058',
      '18071',
      '18210',
      '18301',
      '18302',
      '18320',
      '18321',
      '18322',
      '18323',
      '18324',
      '18325',
      '18326',
      '18327',
      '18330',
      '18331',
      '18332',
      '18333',
      '18334',
      '18335',
      '18341',
      '18342',
      '18344',
      '18346',
      '18347',
      '18348',
      '18349',
      '18350',
      '18352',
      '18353',
      '18354',
      '18355',
      '18356',
      '18357',
      '18360',
      '18370',
      '18372',
      '18424',
      '18466',
      '18610'
    ],
    '127': [
      '18405',
      '18415',
      '18417',
      '18421',
      '18424',
      '18427',
      '18428',
      '18431',
      '18436',
      '18437',
      '18438',
      '18439',
      '18443',
      '18444',
      '18445',
      '18449',
      '18453',
      '18454',
      '18455',
      '18456',
      '18459',
      '18460',
      '18461',
      '18462',
      '18463',
      '18465',
      '18469',
      '18470',
      '18472',
      '18473',
      '18847'
    ],
    '065': [
      '15711',
      '15715',
      '15730',
      '15733',
      '15744',
      '15764',
      '15767',
      '15770',
      '15776',
      '15778',
      '15780',
      '15781',
      '15784',
      '15823',
      '15824',
      '15825',
      '15828',
      '15829',
      '15840',
      '15847',
      '15851',
      '15853',
      '15860',
      '15863',
      '15864',
      '15865',
      '16217',
      '16222',
      '16240'
    ],
    '121': [
      '16038',
      '16127',
      '16153',
      '16232',
      '16301',
      '16311',
      '16314',
      '16317',
      '16319',
      '16323',
      '16341',
      '16342',
      '16343',
      '16344',
      '16346',
      '16353',
      '16354',
      '16362',
      '16364',
      '16372',
      '16373',
      '16374'
    ],
    '057': [
      '15533',
      '15536',
      '16674',
      '16689',
      '16691',
      '16694',
      '17212',
      '17215',
      '17223',
      '17224',
      '17228',
      '17229',
      '17233',
      '17236',
      '17238',
      '17264',
      '17267'
    ],
    '001': [
      '17019',
      '17222',
      '17301',
      '17304',
      '17306',
      '17307',
      '17316',
      '17320',
      '17324',
      '17325',
      '17331',
      '17340',
      '17343',
      '17344',
      '17350',
      '17353',
      '17372'
    ],
    '129': [
      '15012',
      '15062',
      '15068',
      '15072',
      '15083',
      '15085',
      '15087',
      '15089',
      '15131',
      '15137',
      '15146',
      '15448',
      '15479',
      '15501',
      '15531',
      '15601',
      '15610',
      '15611',
      '15612',
      '15613',
      '15615',
      '15616',
      '15617',
      '15618',
      '15620',
      '15621',
      '15622',
      '15623',
      '15624',
      '15625',
      '15626',
      '15627',
      '15628',
      '15629',
      '15632',
      '15633',
      '15634',
      '15635',
      '15636',
      '15637',
      '15638',
      '15639',
      '15640',
      '15641',
      '15642',
      '15644',
      '15646',
      '15647',
      '15650',
      '15655',
      '15656',
      '15658',
      '15660',
      '15661',
      '15662',
      '15663',
      '15665',
      '15666',
      '15668',
      '15670',
      '15671',
      '15672',
      '15675',
      '15676',
      '15677',
      '15678',
      '15679',
      '15680',
      '15681',
      '15683',
      '15684',
      '15687',
      '15688',
      '15689',
      '15690',
      '15691',
      '15692',
      '15693',
      '15695',
      '15696',
      '15697',
      '15698',
      '15717',
      '15779',
      '15923',
      '15944',
      '15954',
      '16229'
    ],
    '017': [
      '18036',
      '18041',
      '18054',
      '18055',
      '18073',
      '18077',
      '18081',
      '18901',
      '18902',
      '18912',
      '18913',
      '18914',
      '18917',
      '18920',
      '18923',
      '18925',
      '18929',
      '18930',
      '18932',
      '18935',
      '18938',
      '18940',
      '18942',
      '18944',
      '18947',
      '18950',
      '18951',
      '18954',
      '18955',
      '18960',
      '18962',
      '18964',
      '18966',
      '18969',
      '18970',
      '18972',
      '18974',
      '18976',
      '18977',
      '18980',
      '19006',
      '19007',
      '19020',
      '19021',
      '19030',
      '19040',
      '19047',
      '19053',
      '19054',
      '19055',
      '19056',
      '19057',
      '19067',
      '19440'
    ],
    '115': [
      '18407',
      '18413',
      '18419',
      '18421',
      '18430',
      '18441',
      '18446',
      '18465',
      '18470',
      '18623',
      '18630',
      '18801',
      '18812',
      '18816',
      '18818',
      '18821',
      '18822',
      '18823',
      '18824',
      '18825',
      '18826',
      '18828',
      '18830',
      '18834',
      '18842',
      '18843',
      '18844',
      '18847'
    ],
    '087': [
      '17002',
      '17004',
      '17009',
      '17029',
      '17044',
      '17051',
      '17063',
      '17066',
      '17075',
      '17084',
      '17099',
      '17841'
    ],
    '095': [
      '18013',
      '18014',
      '18015',
      '18017',
      '18018',
      '18020',
      '18032',
      '18035',
      '18038',
      '18040',
      '18042',
      '18043',
      '18044',
      '18045',
      '18055',
      '18063',
      '18064',
      '18067',
      '18072',
      '18077',
      '18083',
      '18085',
      '18086',
      '18088',
      '18091',
      '18109',
      '18343',
      '18351'
    ],
    '035': [
      '16822',
      '16841',
      '16848',
      '16871',
      '16872',
      '17721',
      '17729',
      '17740',
      '17745',
      '17747',
      '17748',
      '17750',
      '17751',
      '17760',
      '17764',
      '17767',
      '17778',
      '17779'
    ],
    '005': [
      '15613',
      '15618',
      '15656',
      '15673',
      '15686',
      '15690',
      '15732',
      '15736',
      '15774',
      '16025',
      '16028',
      '16041',
      '16049',
      '16055',
      '16201',
      '16210',
      '16212',
      '16218',
      '16222',
      '16223',
      '16224',
      '16226',
      '16228',
      '16229',
      '16236',
      '16238',
      '16240',
      '16242',
      '16244',
      '16245',
      '16249',
      '16250',
      '16253',
      '16259',
      '16262',
      '16263'
    ],
    '051': [
      '15012',
      '15401',
      '15410',
      '15413',
      '15417',
      '15420',
      '15421',
      '15422',
      '15424',
      '15425',
      '15428',
      '15430',
      '15431',
      '15433',
      '15435',
      '15436',
      '15437',
      '15438',
      '15440',
      '15442',
      '15443',
      '15444',
      '15445',
      '15446',
      '15447',
      '15449',
      '15450',
      '15451',
      '15454',
      '15455',
      '15456',
      '15458',
      '15459',
      '15460',
      '15461',
      '15462',
      '15463',
      '15464',
      '15466',
      '15467',
      '15468',
      '15469',
      '15470',
      '15472',
      '15473',
      '15474',
      '15475',
      '15476',
      '15478',
      '15480',
      '15482',
      '15484',
      '15486',
      '15489',
      '15490',
      '15492',
      '15557',
      '15610',
      '15622',
      '15631',
      '15666',
      '15683'
    ],
    '077': [
      '18002',
      '18003',
      '18011',
      '18015',
      '18016',
      '18017',
      '18018',
      '18031',
      '18032',
      '18034',
      '18036',
      '18037',
      '18041',
      '18046',
      '18049',
      '18051',
      '18052',
      '18053',
      '18059',
      '18062',
      '18066',
      '18068',
      '18069',
      '18078',
      '18079',
      '18080',
      '18087',
      '18092',
      '18101',
      '18102',
      '18103',
      '18104',
      '18105',
      '18106',
      '18109',
      '18195',
      '18951',
      '19529',
      '19530',
      '19539'
    ],
    '027': [
      '16666',
      '16677',
      '16686',
      '16801',
      '16802',
      '16803',
      '16820',
      '16822',
      '16823',
      '16826',
      '16827',
      '16828',
      '16829',
      '16832',
      '16835',
      '16841',
      '16844',
      '16845',
      '16851',
      '16852',
      '16853',
      '16854',
      '16859',
      '16860',
      '16865',
      '16866',
      '16868',
      '16870',
      '16872',
      '16874',
      '16875',
      '16877',
      '16882'
    ],
    '069': [
      '18403',
      '18407',
      '18410',
      '18411',
      '18414',
      '18416',
      '18419',
      '18420',
      '18421',
      '18424',
      '18433',
      '18434',
      '18436',
      '18440',
      '18444',
      '18446',
      '18447',
      '18448',
      '18452',
      '18471',
      '18501',
      '18502',
      '18503',
      '18504',
      '18505',
      '18507',
      '18508',
      '18509',
      '18510',
      '18512',
      '18515',
      '18517',
      '18518',
      '18519',
      '18540',
      '18577',
      '18615',
      '18641',
      '18653'
    ],
    '093': [
      '17756',
      '17772',
      '17777',
      '17815',
      '17820',
      '17821',
      '17822',
      '17847',
      '17884'
    ],
    '009': [
      '15521',
      '15522',
      '15533',
      '15534',
      '15535',
      '15537',
      '15539',
      '15545',
      '15550',
      '15554',
      '15559',
      '15946',
      '16625',
      '16633',
      '16650',
      '16655',
      '16657',
      '16659',
      '16662',
      '16664',
      '16667',
      '16670',
      '16672',
      '16673',
      '16678',
      '16679',
      '16694',
      '16695',
      '17211'
    ],
    '105': [
      '16720',
      '16743',
      '16746',
      '16748',
      '16915',
      '16922',
      '16923',
      '16927',
      '16937',
      '16941',
      '16943',
      '16948',
      '16950',
      '17729'
    ],
    '113': [
      '17701',
      '17731',
      '17737',
      '17758',
      '17768',
      '17774',
      '17814',
      '18614',
      '18616',
      '18619',
      '18626',
      '18628',
      '18632',
      '18833'
    ],
    '133': [
      '17011',
      '17019',
      '17055',
      '17070',
      '17301',
      '17302',
      '17309',
      '17311',
      '17313',
      '17314',
      '17315',
      '17316',
      '17317',
      '17318',
      '17319',
      '17321',
      '17322',
      '17327',
      '17329',
      '17331',
      '17339',
      '17345',
      '17347',
      '17349',
      '17352',
      '17355',
      '17356',
      '17360',
      '17361',
      '17362',
      '17363',
      '17364',
      '17365',
      '17366',
      '17368',
      '17370',
      '17371',
      '17372',
      '17401',
      '17402',
      '17403',
      '17404',
      '17406',
      '17407',
      '17408'
    ],
    '071': [
      '17022',
      '17073',
      '17501',
      '17502',
      '17505',
      '17507',
      '17508',
      '17509',
      '17512',
      '17516',
      '17517',
      '17518',
      '17519',
      '17520',
      '17522',
      '17527',
      '17529',
      '17532',
      '17535',
      '17536',
      '17538',
      '17540',
      '17543',
      '17545',
      '17547',
      '17550',
      '17551',
      '17552',
      '17554',
      '17555',
      '17557',
      '17560',
      '17562',
      '17563',
      '17565',
      '17566',
      '17569',
      '17570',
      '17572',
      '17576',
      '17578',
      '17579',
      '17581',
      '17582',
      '17584',
      '17601',
      '17602',
      '17603',
      '17606',
      '19310',
      '19344',
      '19362',
      '19363',
      '19501',
      '19540',
      '19543',
      '19551'
    ],
    '075': [
      '17003',
      '17010',
      '17016',
      '17026',
      '17028',
      '17033',
      '17038',
      '17039',
      '17041',
      '17042',
      '17046',
      '17064',
      '17067',
      '17073',
      '17077',
      '17078',
      '17083',
      '17087',
      '17088',
      '17545',
      '17963',
      '19551'
    ],
    '047': [
      '15821',
      '15823',
      '15827',
      '15832',
      '15841',
      '15845',
      '15846',
      '15853',
      '15857',
      '15860',
      '15868',
      '15870',
      '16728',
      '16734',
      '16735'
    ],
    '007': [
      '15001',
      '15003',
      '15005',
      '15009',
      '15010',
      '15026',
      '15027',
      '15042',
      '15043',
      '15050',
      '15052',
      '15059',
      '15061',
      '15066',
      '15074',
      '15077',
      '15081',
      '15143',
      '16037',
      '16063',
      '16115',
      '16117',
      '16120',
      '16123',
      '16136',
      '16141',
      '16157'
    ],
    '023': [
      '15832',
      '15834',
      '15861',
      '16720'
    ],
    '043': [
      '17005',
      '17017',
      '17018',
      '17020',
      '17022',
      '17023',
      '17028',
      '17030',
      '17032',
      '17033',
      '17034',
      '17036',
      '17048',
      '17057',
      '17061',
      '17078',
      '17080',
      '17097',
      '17098',
      '17101',
      '17102',
      '17103',
      '17104',
      '17109',
      '17110',
      '17111',
      '17112',
      '17113',
      '17120',
      '17980'
    ],
    '101': [
      '19102',
      '19103',
      '19104',
      '19106',
      '19107',
      '19109',
      '19111',
      '19112',
      '19114',
      '19115',
      '19116',
      '19118',
      '19119',
      '19120',
      '19121',
      '19122',
      '19123',
      '19124',
      '19125',
      '19126',
      '19127',
      '19128',
      '19129',
      '19130',
      '19131',
      '19132',
      '19133',
      '19134',
      '19135',
      '19136',
      '19137',
      '19138',
      '19139',
      '19140',
      '19141',
      '19142',
      '19143',
      '19144',
      '19145',
      '19146',
      '19147',
      '19148',
      '19149',
      '19150',
      '19151',
      '19152',
      '19153',
      '19154'
    ],
    '081': [
      '16930',
      '16938',
      '17701',
      '17702',
      '17723',
      '17724',
      '17727',
      '17728',
      '17737',
      '17739',
      '17740',
      '17742',
      '17744',
      '17745',
      '17752',
      '17754',
      '17756',
      '17758',
      '17762',
      '17763',
      '17765',
      '17771',
      '17774',
      '17776',
      '17810',
      '17814',
      '17846'
    ],
    '061': [
      '16611',
      '16621',
      '16622',
      '16623',
      '16634',
      '16638',
      '16647',
      '16652',
      '16657',
      '16669',
      '16674',
      '16678',
      '16683',
      '16685',
      '16686',
      '16694',
      '16865',
      '16877',
      '17002',
      '17052',
      '17060',
      '17066',
      '17213',
      '17215',
      '17229',
      '17239',
      '17243',
      '17249',
      '17253',
      '17255',
      '17260',
      '17264'
    ],
    '049': [
      '16401',
      '16403',
      '16407',
      '16410',
      '16411',
      '16412',
      '16415',
      '16417',
      '16421',
      '16423',
      '16426',
      '16427',
      '16428',
      '16435',
      '16438',
      '16441',
      '16442',
      '16443',
      '16444',
      '16501',
      '16502',
      '16503',
      '16504',
      '16505',
      '16506',
      '16507',
      '16508',
      '16509',
      '16510',
      '16511',
      '16546',
      '16563'
    ],
    '019': [
      '15044',
      '15086',
      '16001',
      '16002',
      '16020',
      '16022',
      '16023',
      '16024',
      '16025',
      '16027',
      '16029',
      '16030',
      '16033',
      '16034',
      '16035',
      '16037',
      '16038',
      '16040',
      '16041',
      '16045',
      '16046',
      '16048',
      '16049',
      '16050',
      '16051',
      '16052',
      '16053',
      '16055',
      '16056',
      '16057',
      '16059',
      '16061',
      '16063',
      '16066',
      '16127',
      '16229',
      '16373'
    ],
    '003': [
      '15003',
      '15005',
      '15006',
      '15007',
      '15014',
      '15015',
      '15017',
      '15018',
      '15020',
      '15024',
      '15025',
      '15026',
      '15028',
      '15030',
      '15031',
      '15034',
      '15035',
      '15037',
      '15044',
      '15045',
      '15046',
      '15047',
      '15049',
      '15051',
      '15056',
      '15057',
      '15063',
      '15064',
      '15065',
      '15068',
      '15071',
      '15075',
      '15076',
      '15082',
      '15084',
      '15085',
      '15086',
      '15088',
      '15089',
      '15090',
      '15101',
      '15102',
      '15104',
      '15106',
      '15108',
      '15110',
      '15112',
      '15116',
      '15120',
      '15122',
      '15126',
      '15129',
      '15131',
      '15132',
      '15133',
      '15135',
      '15136',
      '15137',
      '15139',
      '15140',
      '15142',
      '15143',
      '15144',
      '15145',
      '15146',
      '15147',
      '15148',
      '15201',
      '15202',
      '15203',
      '15204',
      '15205',
      '15206',
      '15207',
      '15208',
      '15209',
      '15210',
      '15211',
      '15212',
      '15213',
      '15214',
      '15215',
      '15216',
      '15217',
      '15218',
      '15219',
      '15220',
      '15221',
      '15222',
      '15223',
      '15224',
      '15225',
      '15226',
      '15227',
      '15228',
      '15229',
      '15232',
      '15233',
      '15234',
      '15235',
      '15236',
      '15237',
      '15238',
      '15239',
      '15241',
      '15243',
      '15260',
      '15290',
      '15332',
      '15642',
      '15668',
      '16046',
      '16055',
      '16059'
    ],
    '083': [
      '15870',
      '16333',
      '16701',
      '16720',
      '16724',
      '16725',
      '16726',
      '16727',
      '16729',
      '16730',
      '16731',
      '16732',
      '16733',
      '16735',
      '16738',
      '16740',
      '16743',
      '16744',
      '16745',
      '16748',
      '16749',
      '16750'
    ],
    '013': [
      '15946',
      '16601',
      '16602',
      '16613',
      '16617',
      '16625',
      '16631',
      '16635',
      '16636',
      '16637',
      '16641',
      '16648',
      '16655',
      '16662',
      '16665',
      '16673',
      '16682',
      '16686',
      '16693'
    ],
    '073': [
      '16037',
      '16051',
      '16057',
      '16101',
      '16102',
      '16105',
      '16112',
      '16116',
      '16117',
      '16120',
      '16123',
      '16127',
      '16132',
      '16140',
      '16141',
      '16142',
      '16143',
      '16155',
      '16156',
      '16157',
      '16159',
      '16160'
    ],
    '015': [
      '16914',
      '16925',
      '16926',
      '16936',
      '16947',
      '17724',
      '18623',
      '18810',
      '18814',
      '18817',
      '18829',
      '18830',
      '18831',
      '18832',
      '18833',
      '18837',
      '18840',
      '18845',
      '18846',
      '18848',
      '18850',
      '18851',
      '18853',
      '18854'
    ]
  },
  '18': {
    '167': [
      '47802',
      '47803',
      '47804',
      '47805',
      '47807',
      '47809',
      '47834',
      '47842',
      '47850',
      '47858',
      '47863',
      '47866',
      '47869',
      '47871',
      '47874',
      '47876',
      '47880',
      '47885'
    ],
    '079': [
      '47223',
      '47227',
      '47229',
      '47230',
      '47231',
      '47232',
      '47265',
      '47270',
      '47273',
      '47274',
      '47282',
      '47283'
    ],
    '131': [
      '46366',
      '46374',
      '46511',
      '46939',
      '46960',
      '46968',
      '46978',
      '46985',
      '46996',
      '47946',
      '47957'
    ],
    '067': [
      '46076',
      '46901',
      '46902',
      '46919',
      '46929',
      '46932',
      '46936',
      '46979'
    ],
    '119': [
      '46120',
      '46166',
      '47404',
      '47427',
      '47431',
      '47433',
      '47455',
      '47456',
      '47459',
      '47460',
      '47471',
      '47833',
      '47841',
      '47868'
    ],
    '153': [
      '47438',
      '47561',
      '47838',
      '47848',
      '47849',
      '47850',
      '47855',
      '47858',
      '47861',
      '47865',
      '47879',
      '47882'
    ],
    '055': [
      '47403',
      '47424',
      '47438',
      '47441',
      '47443',
      '47449',
      '47453',
      '47457',
      '47459',
      '47460',
      '47462',
      '47465',
      '47471',
      '47529',
      '47578',
      '47848'
    ],
    '109': [
      '46106',
      '46111',
      '46113',
      '46118',
      '46120',
      '46125',
      '46151',
      '46157',
      '46158',
      '46160',
      '46166',
      '46180',
      '47433',
      '47456'
    ],
    '053': [
      '46036',
      '46919',
      '46928',
      '46930',
      '46933',
      '46938',
      '46940',
      '46952',
      '46953',
      '46957',
      '46986',
      '46987',
      '46989',
      '46991'
    ],
    '063': [
      '46103',
      '46112',
      '46113',
      '46118',
      '46121',
      '46122',
      '46123',
      '46147',
      '46149',
      '46158',
      '46165',
      '46167',
      '46168',
      '46180',
      '46231',
      '46234',
      '46278'
    ],
    '171': [
      '47906',
      '47917',
      '47918',
      '47921',
      '47932',
      '47970',
      '47975',
      '47982',
      '47991',
      '47993'
    ],
    '085': [
      '46502',
      '46504',
      '46508',
      '46510',
      '46524',
      '46538',
      '46539',
      '46542',
      '46550',
      '46555',
      '46562',
      '46567',
      '46580',
      '46582',
      '46590',
      '46732',
      '46787',
      '46910',
      '46962',
      '46982'
    ],
    '059': [
      '46040',
      '46055',
      '46056',
      '46064',
      '46115',
      '46117',
      '46130',
      '46140',
      '46161',
      '46163',
      '46186',
      '46229',
      '46239',
      '47384'
    ],
    '143': [
      '47102',
      '47138',
      '47147',
      '47170',
      '47177',
      '47230'
    ],
    '111': [
      '46310',
      '46349',
      '46379',
      '46381',
      '47922',
      '47943',
      '47948',
      '47951',
      '47963',
      '47964',
      '47978'
    ],
    '117': [
      '47108',
      '47118',
      '47125',
      '47140',
      '47175',
      '47432',
      '47452',
      '47454',
      '47469',
      '47527'
    ],
    '035': [
      '46001',
      '46012',
      '46017',
      '46989',
      '47302',
      '47303',
      '47304',
      '47305',
      '47306',
      '47320',
      '47334',
      '47336',
      '47338',
      '47342',
      '47354',
      '47356',
      '47367',
      '47368',
      '47383',
      '47396'
    ],
    '033': [
      '46705',
      '46706',
      '46710',
      '46721',
      '46730',
      '46738',
      '46742',
      '46747',
      '46748',
      '46755',
      '46763',
      '46785',
      '46788',
      '46793'
    ],
    '037': [
      '47432',
      '47513',
      '47521',
      '47527',
      '47532',
      '47541',
      '47542',
      '47546',
      '47564',
      '47575',
      '47580',
      '47590'
    ],
    '159': [
      '46031',
      '46036',
      '46045',
      '46047',
      '46049',
      '46050',
      '46068',
      '46072',
      '46076',
      '46902'
    ],
    '147': [
      '47523',
      '47531',
      '47532',
      '47536',
      '47537',
      '47541',
      '47550',
      '47552',
      '47577',
      '47579',
      '47588',
      '47601',
      '47611',
      '47615',
      '47634',
      '47635',
      '47637'
    ],
    '145': [
      '46110',
      '46124',
      '46126',
      '46130',
      '46131',
      '46144',
      '46150',
      '46161',
      '46162',
      '46163',
      '46176',
      '46182',
      '46259',
      '47234',
      '47240',
      '47272'
    ],
    '177': [
      '47324',
      '47327',
      '47330',
      '47335',
      '47339',
      '47341',
      '47345',
      '47346',
      '47354',
      '47357',
      '47358',
      '47360',
      '47374',
      '47393'
    ],
    '163': [
      '47633',
      '47639',
      '47708',
      '47710',
      '47711',
      '47712',
      '47713',
      '47714',
      '47715',
      '47720',
      '47725'
    ],
    '121': [
      '46135',
      '46175',
      '47832',
      '47834',
      '47836',
      '47837',
      '47859',
      '47860',
      '47862',
      '47872',
      '47874',
      '47952',
      '47989'
    ],
    '057': [
      '46030',
      '46031',
      '46032',
      '46033',
      '46034',
      '46037',
      '46038',
      '46040',
      '46055',
      '46060',
      '46062',
      '46069',
      '46074',
      '46077',
      '46240',
      '46250',
      '46256',
      '46260',
      '46280',
      '46290'
    ],
    '103': [
      '46901',
      '46910',
      '46911',
      '46914',
      '46919',
      '46926',
      '46932',
      '46951',
      '46958',
      '46959',
      '46970',
      '46974'
    ],
    '095': [
      '46001',
      '46011',
      '46012',
      '46013',
      '46016',
      '46017',
      '46036',
      '46040',
      '46044',
      '46048',
      '46051',
      '46056',
      '46060',
      '46063',
      '46064',
      '46070',
      '46928',
      '47356'
    ],
    '051': [
      '47612',
      '47613',
      '47616',
      '47619',
      '47633',
      '47639',
      '47640',
      '47648',
      '47649',
      '47654',
      '47660',
      '47665',
      '47666',
      '47670',
      '47683',
      '47725'
    ],
    '009': [
      '46952',
      '47336',
      '47348',
      '47359'
    ],
    '173': [
      '47523',
      '47537',
      '47541',
      '47601',
      '47610',
      '47613',
      '47619',
      '47630',
      '47637',
      '47660',
      '47725'
    ],
    '133': [
      '46105',
      '46120',
      '46121',
      '46128',
      '46135',
      '46171',
      '46172',
      '46175',
      '47456',
      '47840',
      '47868',
      '47872',
      '47954',
      '47989'
    ],
    '139': [
      '46104',
      '46115',
      '46127',
      '46133',
      '46140',
      '46146',
      '46148',
      '46150',
      '46155',
      '46156',
      '46161',
      '46173',
      '46182',
      '47240',
      '47352'
    ],
    '071': [
      '47102',
      '47220',
      '47229',
      '47235',
      '47260',
      '47264',
      '47274',
      '47281'
    ],
    '141': [
      '46506',
      '46530',
      '46536',
      '46544',
      '46545',
      '46550',
      '46552',
      '46554',
      '46556',
      '46561',
      '46573',
      '46574',
      '46595',
      '46601',
      '46613',
      '46614',
      '46615',
      '46616',
      '46617',
      '46619',
      '46628',
      '46635',
      '46637'
    ],
    '047': [
      '45053',
      '46173',
      '47006',
      '47010',
      '47012',
      '47016',
      '47024',
      '47030',
      '47035',
      '47036',
      '47060',
      '47240',
      '47331',
      '47353'
    ],
    '023': [
      '46035',
      '46039',
      '46041',
      '46049',
      '46050',
      '46057',
      '46058',
      '46065',
      '46069',
      '46979'
    ],
    '043': [
      '47106',
      '47117',
      '47119',
      '47122',
      '47124',
      '47136',
      '47150',
      '47165',
      '47172'
    ],
    '081': [
      '46106',
      '46124',
      '46131',
      '46142',
      '46143',
      '46151',
      '46160',
      '46162',
      '46164',
      '46181',
      '46184',
      '46259'
    ],
    '013': [
      '46160',
      '46164',
      '46181',
      '47201',
      '47235',
      '47274',
      '47401',
      '47448',
      '47468'
    ],
    '003': [
      '46704',
      '46706',
      '46723',
      '46733',
      '46741',
      '46743',
      '46745',
      '46748',
      '46765',
      '46773',
      '46774',
      '46777',
      '46783',
      '46788',
      '46797',
      '46798',
      '46802',
      '46803',
      '46804',
      '46805',
      '46806',
      '46807',
      '46808',
      '46809',
      '46814',
      '46815',
      '46816',
      '46818',
      '46819',
      '46825',
      '46835',
      '46845'
    ],
    '123': [
      '47118',
      '47137',
      '47513',
      '47514',
      '47515',
      '47520',
      '47525',
      '47532',
      '47551',
      '47574',
      '47576',
      '47577',
      '47586',
      '47588'
    ],
    '099': [
      '46501',
      '46504',
      '46506',
      '46511',
      '46536',
      '46537',
      '46539',
      '46550',
      '46563',
      '46570',
      '46574'
    ],
    '183': [
      '46562',
      '46723',
      '46725',
      '46750',
      '46764',
      '46783',
      '46787',
      '46818',
      '46962'
    ],
    '029': [
      '47001',
      '47012',
      '47018',
      '47022',
      '47025',
      '47031',
      '47032',
      '47041',
      '47060'
    ],
    '021': [
      '47427',
      '47438',
      '47802',
      '47833',
      '47834',
      '47837',
      '47840',
      '47841',
      '47846',
      '47853',
      '47857',
      '47858',
      '47868',
      '47874',
      '47881'
    ],
    '181': [
      '46978',
      '46985',
      '46996',
      '47906',
      '47920',
      '47923',
      '47925',
      '47926',
      '47929',
      '47950',
      '47959',
      '47960',
      '47978',
      '47980',
      '47995'
    ],
    '031': [
      '46156',
      '46173',
      '47006',
      '47240',
      '47244',
      '47263',
      '47272',
      '47283'
    ],
    '091': [
      '46340',
      '46345',
      '46346',
      '46348',
      '46350',
      '46360',
      '46365',
      '46371',
      '46382',
      '46390',
      '46391',
      '46532',
      '46552',
      '46554',
      '46574'
    ],
    '169': [
      '46702',
      '46911',
      '46919',
      '46940',
      '46941',
      '46943',
      '46946',
      '46962',
      '46970',
      '46974',
      '46982',
      '46984',
      '46990',
      '46992'
    ],
    '025': [
      '47116',
      '47118',
      '47123',
      '47137',
      '47140',
      '47145',
      '47175',
      '47513'
    ],
    '179': [
      '46714',
      '46731',
      '46740',
      '46759',
      '46766',
      '46770',
      '46777',
      '46781',
      '46783',
      '46791',
      '46792',
      '46798',
      '46799',
      '46952',
      '47359'
    ],
    '151': [
      '46703',
      '46705',
      '46737',
      '46742',
      '46747',
      '46761',
      '46776',
      '46779',
      '46793'
    ],
    '039': [
      '46507',
      '46514',
      '46516',
      '46517',
      '46526',
      '46528',
      '46530',
      '46540',
      '46542',
      '46543',
      '46550',
      '46553',
      '46561',
      '46567',
      '46573',
      '46767'
    ],
    '149': [
      '46366',
      '46374',
      '46511',
      '46531',
      '46532',
      '46534',
      '46563',
      '46574',
      '46960',
      '46968',
      '46996'
    ],
    '011': [
      '46035',
      '46050',
      '46052',
      '46069',
      '46071',
      '46075',
      '46077',
      '46112',
      '46147',
      '46268',
      '47933',
      '47968'
    ],
    '041': [
      '46127',
      '46133',
      '47325',
      '47331',
      '47357'
    ],
    '157': [
      '46058',
      '46923',
      '47901',
      '47904',
      '47905',
      '47906',
      '47907',
      '47909',
      '47918',
      '47920',
      '47924',
      '47930',
      '47941',
      '47955',
      '47967',
      '47970',
      '47981',
      '47983',
      '47992'
    ],
    '165': [
      '47842',
      '47847',
      '47854',
      '47884',
      '47928',
      '47932',
      '47966',
      '47974'
    ],
    '137': [
      '47006',
      '47017',
      '47018',
      '47023',
      '47031',
      '47034',
      '47037',
      '47041',
      '47042',
      '47224',
      '47240',
      '47250'
    ],
    '175': [
      '47106',
      '47108',
      '47120',
      '47125',
      '47126',
      '47164',
      '47165',
      '47167',
      '47170',
      '47281'
    ],
    '089': [
      '46303',
      '46307',
      '46311',
      '46312',
      '46319',
      '46320',
      '46321',
      '46322',
      '46323',
      '46324',
      '46327',
      '46341',
      '46342',
      '46356',
      '46373',
      '46375',
      '46376',
      '46377',
      '46394',
      '46402',
      '46403',
      '46404',
      '46405',
      '46406',
      '46407',
      '46408',
      '46409',
      '46410'
    ],
    '045': [
      '47918',
      '47932',
      '47949',
      '47952',
      '47958',
      '47969',
      '47987'
    ],
    '107': [
      '46035',
      '47916',
      '47930',
      '47933',
      '47940',
      '47954',
      '47955',
      '47965',
      '47967',
      '47968',
      '47981',
      '47989',
      '47990',
      '47994'
    ],
    '155': [
      '47011',
      '47020',
      '47038',
      '47040',
      '47043',
      '47224',
      '47250'
    ],
    '127': [
      '46301',
      '46304',
      '46307',
      '46341',
      '46342',
      '46347',
      '46348',
      '46360',
      '46368',
      '46383',
      '46385',
      '46390',
      '46391',
      '46393'
    ],
    '161': [
      '47003',
      '47010',
      '47325',
      '47331',
      '47353',
      '47374'
    ],
    '065': [
      '46117',
      '46148',
      '46186',
      '47302',
      '47327',
      '47334',
      '47337',
      '47344',
      '47346',
      '47351',
      '47352',
      '47354',
      '47356',
      '47360',
      '47361',
      '47362',
      '47384',
      '47385',
      '47386',
      '47387',
      '47388'
    ],
    '097': [
      '46077',
      '46107',
      '46113',
      '46183',
      '46201',
      '46202',
      '46203',
      '46204',
      '46205',
      '46208',
      '46214',
      '46216',
      '46217',
      '46218',
      '46219',
      '46220',
      '46221',
      '46222',
      '46224',
      '46225',
      '46226',
      '46227',
      '46228',
      '46229',
      '46231',
      '46234',
      '46235',
      '46236',
      '46237',
      '46239',
      '46240',
      '46241',
      '46250',
      '46254',
      '46256',
      '46259',
      '46260',
      '46268',
      '46278'
    ],
    '061': [
      '47110',
      '47112',
      '47114',
      '47115',
      '47117',
      '47122',
      '47124',
      '47135',
      '47136',
      '47142',
      '47160',
      '47161',
      '47164',
      '47166'
    ],
    '001': [
      '46711',
      '46714',
      '46733',
      '46740',
      '46772',
      '46773',
      '46777',
      '47326'
    ],
    '129': [
      '47612',
      '47616',
      '47620',
      '47631',
      '47633',
      '47638',
      '47712'
    ],
    '017': [
      '46926',
      '46932',
      '46947',
      '46950',
      '46961',
      '46967',
      '46970',
      '46978',
      '46988',
      '46994',
      '46998'
    ],
    '087': [
      '46540',
      '46543',
      '46565',
      '46571',
      '46746',
      '46747',
      '46761',
      '46767',
      '46771',
      '46776',
      '46786',
      '46795'
    ],
    '115': [
      '47001',
      '47011',
      '47017',
      '47018',
      '47040'
    ],
    '005': [
      '46124',
      '47201',
      '47203',
      '47226',
      '47232',
      '47244',
      '47246',
      '47247',
      '47274',
      '47280',
      '47283'
    ],
    '077': [
      '47043',
      '47138',
      '47147',
      '47224',
      '47227',
      '47230',
      '47231',
      '47243',
      '47250',
      '47270'
    ],
    '027': [
      '47501',
      '47519',
      '47529',
      '47553',
      '47558',
      '47562',
      '47568'
    ],
    '069': [
      '46702',
      '46750',
      '46770',
      '46783',
      '46792',
      '46940',
      '46952',
      '46991'
    ],
    '093': [
      '47264',
      '47420',
      '47421',
      '47436',
      '47437',
      '47446',
      '47451',
      '47452',
      '47462',
      '47467',
      '47470'
    ],
    '105': [
      '46151',
      '47264',
      '47401',
      '47403',
      '47404',
      '47405',
      '47406',
      '47408',
      '47429',
      '47433',
      '47434',
      '47436',
      '47448',
      '47458',
      '47460',
      '47462',
      '47464',
      '47468'
    ],
    '113': [
      '46562',
      '46571',
      '46701',
      '46710',
      '46723',
      '46725',
      '46732',
      '46748',
      '46755',
      '46760',
      '46763',
      '46764',
      '46767',
      '46784',
      '46794',
      '46795'
    ],
    '075': [
      '47320',
      '47326',
      '47336',
      '47369',
      '47371',
      '47373',
      '47380',
      '47381',
      '47390'
    ],
    '007': [
      '47917',
      '47921',
      '47942',
      '47944',
      '47948',
      '47970',
      '47971',
      '47975',
      '47977'
    ],
    '135': [
      '47320',
      '47340',
      '47354',
      '47355',
      '47358',
      '47360',
      '47368',
      '47371',
      '47373',
      '47380',
      '47382',
      '47390',
      '47393',
      '47394'
    ],
    '101': [
      '47432',
      '47446',
      '47470',
      '47522',
      '47553',
      '47562',
      '47581'
    ],
    '125': [
      '47542',
      '47564',
      '47567',
      '47584',
      '47585',
      '47590',
      '47598',
      '47619',
      '47640',
      '47660'
    ],
    '049': [
      '46511',
      '46539',
      '46570',
      '46910',
      '46922',
      '46931',
      '46939',
      '46951',
      '46960',
      '46975',
      '46982'
    ],
    '019': [
      '47104',
      '47106',
      '47111',
      '47119',
      '47126',
      '47129',
      '47130',
      '47141',
      '47143',
      '47147',
      '47150',
      '47162',
      '47163',
      '47172',
      '47177'
    ],
    '083': [
      '47512',
      '47516',
      '47524',
      '47528',
      '47535',
      '47557',
      '47561',
      '47578',
      '47591',
      '47596',
      '47597'
    ],
    '073': [
      '46310',
      '46374',
      '46392',
      '47922',
      '47943',
      '47946',
      '47948',
      '47957',
      '47959',
      '47977',
      '47978',
      '47995'
    ],
    '015': [
      '46041',
      '46065',
      '46913',
      '46915',
      '46917',
      '46920',
      '46923',
      '46929',
      '46947',
      '47920',
      '47923',
      '47926',
      '47950',
      '47960',
      '47997'
    ]
  },
  '27': {
    '167': [
      '56514',
      '56520',
      '56522',
      '56543',
      '56553',
      '56560',
      '56565',
      '56579',
      '56583',
      '56594'
    ],
    '079': [
      '56011',
      '56017',
      '56028',
      '56044',
      '56050',
      '56052',
      '56057',
      '56058',
      '56063',
      '56069',
      '56071',
      '56082',
      '56096'
    ],
    '131': [
      '55018',
      '55019',
      '55021',
      '55046',
      '55049',
      '55052',
      '55053',
      '55057',
      '55087',
      '55088',
      '55946',
      '56052',
      '56069',
      '56071',
      '56093',
      '56096'
    ],
    '009': [
      '55371',
      '56304',
      '56329',
      '56330',
      '56357',
      '56367',
      '56373',
      '56377',
      '56379'
    ],
    '119': [
      '56517',
      '56523',
      '56535',
      '56540',
      '56542',
      '56556',
      '56568',
      '56592',
      '56621',
      '56634',
      '56644',
      '56646',
      '56651',
      '56684',
      '56710',
      '56715',
      '56716',
      '56721',
      '56722',
      '56723',
      '56736',
      '56742',
      '56744',
      '56750',
      '56762'
    ],
    '153': [
      '56318',
      '56319',
      '56331',
      '56336',
      '56347',
      '56360',
      '56378',
      '56382',
      '56389',
      '56434',
      '56437',
      '56438',
      '56440',
      '56443',
      '56446',
      '56453',
      '56466',
      '56479',
      '56481',
      '56482'
    ],
    '055': [
      '55919',
      '55921',
      '55931',
      '55941',
      '55943',
      '55947',
      '55954',
      '55971',
      '55974'
    ],
    '109': [
      '55901',
      '55902',
      '55904',
      '55905',
      '55906',
      '55920',
      '55923',
      '55929',
      '55932',
      '55934',
      '55940',
      '55944',
      '55956',
      '55960',
      '55963',
      '55964',
      '55972',
      '55976',
      '55991'
    ],
    '053': [
      '55111',
      '55305',
      '55311',
      '55316',
      '55327',
      '55328',
      '55331',
      '55340',
      '55341',
      '55343',
      '55344',
      '55345',
      '55346',
      '55347',
      '55356',
      '55357',
      '55359',
      '55364',
      '55369',
      '55373',
      '55374',
      '55375',
      '55384',
      '55387',
      '55388',
      '55391',
      '55401',
      '55402',
      '55403',
      '55404',
      '55405',
      '55406',
      '55407',
      '55408',
      '55409',
      '55410',
      '55411',
      '55412',
      '55413',
      '55414',
      '55415',
      '55416',
      '55417',
      '55418',
      '55419',
      '55420',
      '55421',
      '55422',
      '55423',
      '55424',
      '55425',
      '55426',
      '55427',
      '55428',
      '55429',
      '55430',
      '55431',
      '55435',
      '55436',
      '55437',
      '55438',
      '55439',
      '55441',
      '55442',
      '55443',
      '55444',
      '55445',
      '55446',
      '55447',
      '55450',
      '55454',
      '55455'
    ],
    '063': [
      '51360',
      '56101',
      '56111',
      '56118',
      '56119',
      '56137',
      '56143',
      '56150',
      '56159',
      '56160',
      '56161',
      '56167',
      '56187'
    ],
    '171': [
      '55301',
      '55302',
      '55313',
      '55320',
      '55321',
      '55327',
      '55328',
      '55330',
      '55341',
      '55349',
      '55358',
      '55362',
      '55363',
      '55373',
      '55374',
      '55376',
      '55382',
      '55388',
      '55390'
    ],
    '059': [
      '55006',
      '55008',
      '55017',
      '55029',
      '55040',
      '55056',
      '55070',
      '55079',
      '55080',
      '55371',
      '55398',
      '56353',
      '56358'
    ],
    '137': [
      '55602',
      '55616',
      '55702',
      '55703',
      '55705',
      '55706',
      '55708',
      '55710',
      '55711',
      '55713',
      '55717',
      '55719',
      '55720',
      '55723',
      '55724',
      '55725',
      '55731',
      '55732',
      '55734',
      '55736',
      '55738',
      '55741',
      '55746',
      '55750',
      '55751',
      '55758',
      '55763',
      '55765',
      '55766',
      '55768',
      '55771',
      '55779',
      '55781',
      '55782',
      '55790',
      '55792',
      '55798',
      '55802',
      '55803',
      '55804',
      '55805',
      '55806',
      '55807',
      '55808',
      '55810',
      '55811',
      '55812',
      '55814',
      '56649',
      '56669'
    ],
    '111': [
      '56309',
      '56315',
      '56324',
      '56326',
      '56361',
      '56437',
      '56446',
      '56453',
      '56464',
      '56477',
      '56482',
      '56501',
      '56514',
      '56515',
      '56518',
      '56522',
      '56524',
      '56527',
      '56528',
      '56533',
      '56534',
      '56537',
      '56544',
      '56551',
      '56567',
      '56571',
      '56572',
      '56573',
      '56576',
      '56579',
      '56586',
      '56587',
      '56588'
    ],
    '117': [
      '56128',
      '56139',
      '56140',
      '56144',
      '56149',
      '56164',
      '56170',
      '56177',
      '56186'
    ],
    '035': [
      '56338',
      '56364',
      '56401',
      '56425',
      '56431',
      '56435',
      '56441',
      '56442',
      '56444',
      '56447',
      '56448',
      '56449',
      '56450',
      '56455',
      '56456',
      '56465',
      '56468',
      '56472',
      '56474'
    ],
    '033': [
      '56019',
      '56083',
      '56101',
      '56118',
      '56131',
      '56137',
      '56145',
      '56152',
      '56159',
      '56166',
      '56174',
      '56180',
      '56183'
    ],
    '037': [
      '55009',
      '55024',
      '55031',
      '55033',
      '55044',
      '55057',
      '55065',
      '55068',
      '55075',
      '55076',
      '55077',
      '55085',
      '55089',
      '55118',
      '55120',
      '55121',
      '55122',
      '55123',
      '55124',
      '55150',
      '55306',
      '55337'
    ],
    '159': [
      '56434',
      '56464',
      '56470',
      '56477',
      '56479',
      '56481',
      '56482'
    ],
    '147': [
      '55049',
      '55052',
      '55060',
      '55917',
      '55924',
      '55985',
      '56026',
      '56046',
      '56072',
      '56093'
    ],
    '145': [
      '55320',
      '55329',
      '55353',
      '55382',
      '55389',
      '56301',
      '56303',
      '56307',
      '56310',
      '56312',
      '56314',
      '56316',
      '56320',
      '56321',
      '56325',
      '56331',
      '56335',
      '56336',
      '56340',
      '56352',
      '56356',
      '56362',
      '56367',
      '56368',
      '56369',
      '56371',
      '56374',
      '56375',
      '56376',
      '56377',
      '56378',
      '56387'
    ],
    '085': [
      '55312',
      '55336',
      '55350',
      '55354',
      '55370',
      '55381',
      '55385',
      '55395',
      '55397'
    ],
    '163': [
      '55001',
      '55003',
      '55016',
      '55025',
      '55033',
      '55038',
      '55042',
      '55043',
      '55047',
      '55055',
      '55071',
      '55073',
      '55082',
      '55090',
      '55110',
      '55115',
      '55125',
      '55128',
      '55129'
    ],
    '121': [
      '56215',
      '56226',
      '56244',
      '56308',
      '56316',
      '56323',
      '56327',
      '56334',
      '56343',
      '56349',
      '56381',
      '56385'
    ],
    '057': [
      '56433',
      '56436',
      '56458',
      '56461',
      '56464',
      '56467',
      '56470',
      '56601',
      '56633',
      '56678'
    ],
    '103': [
      '55332',
      '55334',
      '55335',
      '56003',
      '56021',
      '56054',
      '56058',
      '56073',
      '56074',
      '56082'
    ],
    '095': [
      '55371',
      '56313',
      '56330',
      '56342',
      '56353',
      '56357',
      '56358',
      '56359',
      '56363',
      '56386',
      '56450'
    ],
    '051': [
      '56248',
      '56274',
      '56309',
      '56311',
      '56339',
      '56343',
      '56531',
      '56565',
      '56590'
    ],
    '067': [
      '56201',
      '56209',
      '56216',
      '56251',
      '56252',
      '56253',
      '56273',
      '56279',
      '56281',
      '56282',
      '56284',
      '56288',
      '56289',
      '56312',
      '56316',
      '56362'
    ],
    '173': [
      '56214',
      '56218',
      '56220',
      '56223',
      '56229',
      '56237',
      '56241',
      '56245',
      '56264',
      '56265',
      '56280',
      '56291',
      '56297'
    ],
    '133': [
      '56116',
      '56128',
      '56129',
      '56134',
      '56138',
      '56144',
      '56146',
      '56147',
      '56156',
      '56158',
      '56173',
      '57030',
      '57068'
    ],
    '071': [
      '55771',
      '55772',
      '56623',
      '56627',
      '56629',
      '56639',
      '56649',
      '56653',
      '56654',
      '56658',
      '56660',
      '56661',
      '56668',
      '56669'
    ],
    '141': [
      '55308',
      '55309',
      '55319',
      '55330',
      '55371',
      '55398',
      '56304'
    ],
    '047': [
      '55912',
      '55917',
      '55953',
      '56007',
      '56009',
      '56016',
      '56020',
      '56026',
      '56029',
      '56032',
      '56035',
      '56036',
      '56042',
      '56043',
      '56045',
      '56072',
      '56089',
      '56097'
    ],
    '023': [
      '56208',
      '56222',
      '56241',
      '56252',
      '56260',
      '56262',
      '56265',
      '56271',
      '56282',
      '56295'
    ],
    '043': [
      '56013',
      '56014',
      '56023',
      '56025',
      '56027',
      '56033',
      '56047',
      '56051',
      '56068',
      '56097',
      '56098'
    ],
    '065': [
      '55006',
      '55007',
      '55030',
      '55037',
      '55051',
      '55072',
      '56342',
      '56353',
      '56358'
    ],
    '013': [
      '56001',
      '56010',
      '56024',
      '56034',
      '56037',
      '56048',
      '56055',
      '56062',
      '56063',
      '56065',
      '56068',
      '56073',
      '56078',
      '56080',
      '56088',
      '56090'
    ],
    '003': [
      '55005',
      '55011',
      '55014',
      '55025',
      '55038',
      '55070',
      '55079',
      '55092',
      '55110',
      '55126',
      '55303',
      '55304',
      '55330',
      '55421',
      '55432',
      '55433',
      '55434',
      '55448',
      '55449'
    ],
    '123': [
      '55038',
      '55101',
      '55102',
      '55103',
      '55104',
      '55105',
      '55106',
      '55107',
      '55108',
      '55109',
      '55110',
      '55112',
      '55113',
      '55114',
      '55116',
      '55117',
      '55118',
      '55119',
      '55126',
      '55127',
      '55130',
      '55155',
      '55418',
      '55421',
      '55432',
      '55449'
    ],
    '099': [
      '55909',
      '55912',
      '55917',
      '55918',
      '55926',
      '55933',
      '55936',
      '55950',
      '55951',
      '55953',
      '55961',
      '55967',
      '55970',
      '55973',
      '55975',
      '55977',
      '55982'
    ],
    '029': [
      '56470',
      '56557',
      '56589',
      '56621',
      '56634',
      '56644',
      '56652',
      '56671',
      '56676',
      '56678'
    ],
    '021': [
      '55785',
      '56401',
      '56433',
      '56435',
      '56448',
      '56452',
      '56461',
      '56466',
      '56468',
      '56472',
      '56473',
      '56474',
      '56477',
      '56479',
      '56481',
      '56484',
      '56626',
      '56633',
      '56636',
      '56641',
      '56655',
      '56662',
      '56672'
    ],
    '031': [
      '55604',
      '55605',
      '55606',
      '55612',
      '55613',
      '55615'
    ],
    '091': [
      '56013',
      '56027',
      '56031',
      '56039',
      '56075',
      '56088',
      '56098',
      '56121',
      '56127',
      '56160',
      '56162',
      '56171',
      '56176',
      '56181'
    ],
    '139': [
      '55020',
      '55044',
      '55054',
      '55088',
      '55352',
      '55372',
      '55378',
      '55379',
      '56011',
      '56071'
    ],
    '025': [
      '55006',
      '55008',
      '55012',
      '55013',
      '55025',
      '55032',
      '55045',
      '55056',
      '55069',
      '55073',
      '55074',
      '55079',
      '55080',
      '55084',
      '55092'
    ],
    '169': [
      '55910',
      '55923',
      '55925',
      '55943',
      '55947',
      '55952',
      '55959',
      '55962',
      '55964',
      '55969',
      '55971',
      '55972',
      '55979',
      '55987'
    ],
    '151': [
      '56207',
      '56208',
      '56215',
      '56226',
      '56231',
      '56244',
      '56249',
      '56252',
      '56262',
      '56271',
      '56289'
    ],
    '039': [
      '55917',
      '55924',
      '55927',
      '55940',
      '55944',
      '55955',
      '55963',
      '55985'
    ],
    '149': [
      '56207',
      '56221',
      '56235',
      '56244',
      '56248',
      '56267',
      '56323',
      '56343'
    ],
    '011': [
      '56210',
      '56211',
      '56221',
      '56225',
      '56227',
      '56236',
      '56240',
      '56276',
      '56278'
    ],
    '041': [
      '56308',
      '56315',
      '56319',
      '56326',
      '56327',
      '56332',
      '56334',
      '56339',
      '56343',
      '56349',
      '56354',
      '56355',
      '56360',
      '56361',
      '56385',
      '56446'
    ],
    '157': [
      '55027',
      '55041',
      '55906',
      '55910',
      '55932',
      '55945',
      '55956',
      '55957',
      '55960',
      '55964',
      '55968',
      '55981',
      '55991'
    ],
    '165': [
      '56019',
      '56022',
      '56056',
      '56060',
      '56062',
      '56081',
      '56088',
      '56120',
      '56160',
      '56162'
    ],
    '143': [
      '55307',
      '55314',
      '55334',
      '55335',
      '55336',
      '55338',
      '55339',
      '55366',
      '55385',
      '55396',
      '56011',
      '56044',
      '56054',
      '56058'
    ],
    '089': [
      '56701',
      '56710',
      '56713',
      '56724',
      '56725',
      '56727',
      '56732',
      '56737',
      '56738',
      '56744',
      '56757',
      '56758',
      '56759',
      '56760',
      '56762'
    ],
    '045': [
      '55922',
      '55923',
      '55935',
      '55939',
      '55949',
      '55951',
      '55954',
      '55961',
      '55962',
      '55965',
      '55971',
      '55974',
      '55975',
      '55976',
      '55979',
      '55990'
    ],
    '107': [
      '56510',
      '56516',
      '56519',
      '56540',
      '56541',
      '56545',
      '56548',
      '56550',
      '56557',
      '56574',
      '56581',
      '56584'
    ],
    '155': [
      '56211',
      '56219',
      '56221',
      '56236',
      '56240',
      '56248',
      '56274',
      '56296',
      '56583'
    ],
    '127': [
      '55333',
      '56083',
      '56085',
      '56087',
      '56152',
      '56166',
      '56175',
      '56180',
      '56214',
      '56224',
      '56255',
      '56258',
      '56263',
      '56266',
      '56270',
      '56283',
      '56284',
      '56287',
      '56292',
      '56293',
      '56294'
    ],
    '161': [
      '55052',
      '56026',
      '56028',
      '56048',
      '56063',
      '56068',
      '56072',
      '56078',
      '56091',
      '56093',
      '56096',
      '56097'
    ],
    '081': [
      '56113',
      '56136',
      '56142',
      '56149',
      '56164',
      '56178',
      '56220',
      '56264',
      '56280',
      '56291',
      '57026'
    ],
    '097': [
      '56314',
      '56318',
      '56328',
      '56329',
      '56338',
      '56340',
      '56345',
      '56364',
      '56373',
      '56382',
      '56384',
      '56443',
      '56449',
      '56466',
      '56473',
      '56475'
    ],
    '061': [
      '55709',
      '55716',
      '55721',
      '55722',
      '55723',
      '55736',
      '55742',
      '55744',
      '55746',
      '55748',
      '55752',
      '55753',
      '55764',
      '55769',
      '55775',
      '55781',
      '55784',
      '55786',
      '55793',
      '56628',
      '56630',
      '56636',
      '56637',
      '56639',
      '56657',
      '56659',
      '56661',
      '56680',
      '56681',
      '56688'
    ],
    '001': [
      '55735',
      '55748',
      '55752',
      '55760',
      '55783',
      '55785',
      '55787',
      '55798',
      '56342',
      '56350',
      '56431',
      '56469'
    ],
    '129': [
      '55310',
      '55314',
      '55332',
      '55333',
      '55342',
      '55350',
      '55385',
      '56228',
      '56230',
      '56241',
      '56260',
      '56270',
      '56277',
      '56283',
      '56284',
      '56285'
    ],
    '017': [
      '55707',
      '55718',
      '55720',
      '55726',
      '55733',
      '55749',
      '55756',
      '55757',
      '55767',
      '55780',
      '55783',
      '55787',
      '55797',
      '55798',
      '55810'
    ],
    '087': [
      '56516',
      '56542',
      '56557',
      '56566',
      '56589',
      '56592',
      '56621',
      '56651'
    ],
    '115': [
      '55006',
      '55007',
      '55030',
      '55036',
      '55037',
      '55063',
      '55069',
      '55072',
      '55704',
      '55712',
      '55735',
      '55749',
      '55756',
      '55767',
      '55783',
      '55795'
    ],
    '005': [
      '56464',
      '56470',
      '56501',
      '56511',
      '56521',
      '56544',
      '56552',
      '56554',
      '56569',
      '56570',
      '56572',
      '56575',
      '56577',
      '56578',
      '56585',
      '56589',
      '56591',
      '56593'
    ],
    '077': [
      '56623',
      '56673',
      '56686',
      '56711',
      '56741'
    ],
    '027': [
      '56514',
      '56519',
      '56525',
      '56529',
      '56536',
      '56546',
      '56547',
      '56549',
      '56552',
      '56554',
      '56560',
      '56580',
      '56585'
    ],
    '069': [
      '56720',
      '56728',
      '56729',
      '56731',
      '56732',
      '56733',
      '56734',
      '56735',
      '56755',
      '58225'
    ],
    '093': [
      '55324',
      '55325',
      '55329',
      '55350',
      '55353',
      '55355',
      '55382',
      '55389',
      '56209',
      '56228',
      '56243',
      '56362'
    ],
    '105': [
      '56110',
      '56117',
      '56119',
      '56122',
      '56128',
      '56129',
      '56131',
      '56141',
      '56147',
      '56153',
      '56155',
      '56158',
      '56165',
      '56167',
      '56168',
      '56185',
      '56187'
    ],
    '113': [
      '56684',
      '56701',
      '56725',
      '56742',
      '56748',
      '56750',
      '56754'
    ],
    '075': [
      '55601',
      '55603',
      '55607',
      '55609',
      '55614',
      '55616',
      '55731'
    ],
    '083': [
      '56115',
      '56132',
      '56157',
      '56169',
      '56170',
      '56175',
      '56178',
      '56229',
      '56239',
      '56258',
      '56263',
      '56264',
      '56291'
    ],
    '135': [
      '56673',
      '56714',
      '56726',
      '56732',
      '56735',
      '56751',
      '56756',
      '56759',
      '56761',
      '56763'
    ],
    '101': [
      '56114',
      '56115',
      '56122',
      '56123',
      '56125',
      '56128',
      '56131',
      '56132',
      '56141',
      '56151',
      '56170',
      '56172',
      '56175',
      '56180',
      '56183',
      '56186'
    ],
    '125': [
      '56535',
      '56715',
      '56716',
      '56736',
      '56742',
      '56748',
      '56750'
    ],
    '049': [
      '55009',
      '55018',
      '55026',
      '55027',
      '55033',
      '55041',
      '55053',
      '55065',
      '55066',
      '55089',
      '55946',
      '55956',
      '55963',
      '55983',
      '55985',
      '55992'
    ],
    '019': [
      '55315',
      '55317',
      '55318',
      '55322',
      '55328',
      '55331',
      '55339',
      '55360',
      '55367',
      '55368',
      '55375',
      '55386',
      '55387',
      '55388',
      '55395',
      '55397',
      '56011'
    ],
    '007': [
      '56601',
      '56630',
      '56633',
      '56647',
      '56650',
      '56652',
      '56661',
      '56663',
      '56666',
      '56667',
      '56670',
      '56671',
      '56676',
      '56678',
      '56683',
      '56685',
      '56687',
      '56727'
    ],
    '073': [
      '56208',
      '56212',
      '56218',
      '56220',
      '56232',
      '56256',
      '56257',
      '56265',
      '56276',
      '56278'
    ],
    '015': [
      '55333',
      '56019',
      '56041',
      '56062',
      '56073',
      '56081',
      '56083',
      '56085',
      '56087',
      '56266'
    ]
  },
  '41': {
    '067': [
      '97005',
      '97006',
      '97007',
      '97008',
      '97035',
      '97056',
      '97062',
      '97064',
      '97070',
      '97106',
      '97109',
      '97113',
      '97116',
      '97117',
      '97119',
      '97123',
      '97124',
      '97125',
      '97132',
      '97133',
      '97140',
      '97144',
      '97210',
      '97221',
      '97223',
      '97224',
      '97225',
      '97229',
      '97231'
    ],
    '001': [
      '97814',
      '97819',
      '97833',
      '97834',
      '97837',
      '97840',
      '97867',
      '97869',
      '97870',
      '97877',
      '97884',
      '97905',
      '97907'
    ],
    '017': [
      '97701',
      '97702',
      '97703',
      '97707',
      '97712',
      '97739',
      '97756',
      '97759',
      '97760'
    ],
    '055': [
      '97029',
      '97033',
      '97039',
      '97050',
      '97065'
    ],
    '029': [
      '97497',
      '97501',
      '97502',
      '97503',
      '97504',
      '97520',
      '97522',
      '97524',
      '97525',
      '97526',
      '97527',
      '97530',
      '97535',
      '97536',
      '97537',
      '97539',
      '97540',
      '97541'
    ],
    '035': [
      '97601',
      '97603',
      '97604',
      '97621',
      '97622',
      '97623',
      '97624',
      '97625',
      '97626',
      '97627',
      '97632',
      '97633',
      '97634',
      '97639',
      '97731',
      '97733',
      '97737',
      '97739'
    ],
    '041': [
      '97324',
      '97326',
      '97341',
      '97343',
      '97357',
      '97364',
      '97365',
      '97366',
      '97367',
      '97368',
      '97369',
      '97376',
      '97380',
      '97388',
      '97390',
      '97391',
      '97394',
      '97498'
    ],
    '005': [
      '97002',
      '97004',
      '97009',
      '97011',
      '97013',
      '97015',
      '97017',
      '97019',
      '97022',
      '97023',
      '97027',
      '97028',
      '97032',
      '97034',
      '97035',
      '97038',
      '97042',
      '97045',
      '97049',
      '97055',
      '97062',
      '97067',
      '97068',
      '97070',
      '97071',
      '97080',
      '97086',
      '97089',
      '97132',
      '97140',
      '97202',
      '97206',
      '97219',
      '97222',
      '97267',
      '97362',
      '97375'
    ],
    '053': [
      '97101',
      '97304',
      '97338',
      '97344',
      '97347',
      '97351',
      '97361',
      '97370',
      '97371',
      '97378',
      '97396'
    ],
    '051': [
      '97009',
      '97014',
      '97019',
      '97024',
      '97030',
      '97034',
      '97035',
      '97056',
      '97060',
      '97080',
      '97133',
      '97201',
      '97202',
      '97203',
      '97204',
      '97205',
      '97206',
      '97208',
      '97209',
      '97210',
      '97211',
      '97212',
      '97213',
      '97214',
      '97215',
      '97216',
      '97217',
      '97218',
      '97219',
      '97220',
      '97221',
      '97222',
      '97225',
      '97227',
      '97229',
      '97230',
      '97231',
      '97232',
      '97233',
      '97236',
      '97239',
      '97266'
    ],
    '033': [
      '97497',
      '97523',
      '97526',
      '97527',
      '97531',
      '97532',
      '97534',
      '97538',
      '97543',
      '97544'
    ],
    '069': [
      '97750',
      '97830',
      '97848',
      '97874'
    ],
    '009': [
      '97016',
      '97018',
      '97048',
      '97051',
      '97053',
      '97054',
      '97056',
      '97064',
      '97231'
    ],
    '025': [
      '97710',
      '97720',
      '97721',
      '97722',
      '97732',
      '97736',
      '97738',
      '97751',
      '97758',
      '97904'
    ],
    '059': [
      '97801',
      '97810',
      '97813',
      '97826',
      '97835',
      '97838',
      '97859',
      '97862',
      '97868',
      '97875',
      '97880',
      '97882',
      '97886',
      '99362'
    ],
    '021': [
      '97812',
      '97823',
      '97830',
      '97843'
    ],
    '039': [
      '97324',
      '97390',
      '97401',
      '97402',
      '97403',
      '97404',
      '97405',
      '97408',
      '97412',
      '97413',
      '97419',
      '97424',
      '97426',
      '97430',
      '97431',
      '97434',
      '97437',
      '97438',
      '97439',
      '97446',
      '97448',
      '97451',
      '97452',
      '97453',
      '97454',
      '97455',
      '97456',
      '97461',
      '97463',
      '97477',
      '97478',
      '97480',
      '97487',
      '97488',
      '97489',
      '97490',
      '97492',
      '97493',
      '97498'
    ],
    '011': [
      '97411',
      '97414',
      '97420',
      '97423',
      '97449',
      '97450',
      '97458',
      '97459',
      '97466'
    ],
    '045': [
      '89421',
      '97710',
      '97901',
      '97903',
      '97906',
      '97907',
      '97908',
      '97909',
      '97910',
      '97911',
      '97913',
      '97914',
      '97918',
      '97920'
    ],
    '047': [
      '97002',
      '97020',
      '97026',
      '97032',
      '97071',
      '97137',
      '97301',
      '97302',
      '97303',
      '97305',
      '97306',
      '97317',
      '97325',
      '97342',
      '97346',
      '97350',
      '97352',
      '97358',
      '97360',
      '97362',
      '97373',
      '97375',
      '97381',
      '97383',
      '97384',
      '97385',
      '97392'
    ],
    '027': [
      '97014',
      '97031',
      '97041'
    ],
    '037': [
      '97620',
      '97630',
      '97635',
      '97636',
      '97637',
      '97638',
      '97640',
      '97641',
      '97735',
      '97758'
    ],
    '031': [
      '97711',
      '97730',
      '97734',
      '97741',
      '97754',
      '97759',
      '97760',
      '97761'
    ],
    '061': [
      '97814',
      '97824',
      '97827',
      '97841',
      '97850',
      '97867',
      '97876',
      '97883'
    ],
    '023': [
      '97817',
      '97820',
      '97825',
      '97845',
      '97848',
      '97856',
      '97864',
      '97865',
      '97869',
      '97873',
      '97877',
      '97880'
    ],
    '043': [
      '97321',
      '97322',
      '97327',
      '97329',
      '97333',
      '97345',
      '97346',
      '97348',
      '97350',
      '97352',
      '97355',
      '97358',
      '97360',
      '97374',
      '97377',
      '97383',
      '97386',
      '97389',
      '97413',
      '97446',
      '97759'
    ],
    '071': [
      '97101',
      '97111',
      '97114',
      '97115',
      '97119',
      '97123',
      '97127',
      '97128',
      '97132',
      '97140',
      '97148',
      '97304',
      '97347',
      '97378',
      '97396'
    ],
    '063': [
      '97828',
      '97842',
      '97846',
      '97857',
      '97885'
    ],
    '065': [
      '97001',
      '97021',
      '97037',
      '97040',
      '97057',
      '97058',
      '97063',
      '97761'
    ],
    '057': [
      '97102',
      '97107',
      '97108',
      '97112',
      '97118',
      '97122',
      '97130',
      '97131',
      '97134',
      '97135',
      '97136',
      '97141',
      '97147',
      '97149',
      '97347',
      '97368'
    ],
    '049': [
      '97818',
      '97826',
      '97836',
      '97838',
      '97839',
      '97843',
      '97844'
    ],
    '019': [
      '97410',
      '97416',
      '97417',
      '97424',
      '97429',
      '97435',
      '97436',
      '97441',
      '97442',
      '97443',
      '97447',
      '97457',
      '97459',
      '97462',
      '97467',
      '97469',
      '97470',
      '97471',
      '97473',
      '97479',
      '97481',
      '97484',
      '97486',
      '97493',
      '97494',
      '97495',
      '97496',
      '97499',
      '97731'
    ],
    '003': [
      '97321',
      '97324',
      '97326',
      '97330',
      '97331',
      '97333',
      '97361',
      '97370',
      '97448',
      '97456'
    ],
    '007': [
      '97016',
      '97102',
      '97103',
      '97110',
      '97121',
      '97131',
      '97138',
      '97145',
      '97146'
    ],
    '013': [
      '97701',
      '97751',
      '97752',
      '97753',
      '97754',
      '97756',
      '97760'
    ],
    '015': [
      '97406',
      '97415',
      '97444',
      '97450',
      '97465',
      '97476'
    ]
  },
  '02': {
    '063': [
      '99686',
      '99574',
      '99693'
    ],
    '066': [
      '99586',
      '99566',
      '99588',
      '99573',
    ],
    '270': [
      '99554',
      '99563',
      '99581',
      '99585',
      '99604',
      '99620',
      '99632',
      '99650',
      '99657',
      '99658',
      '99662',
      '99666'
    ],
    '261': [
      '99566',
      '99573',
      '99574',
      '99586',
      '99588',
      '99677',
      '99686',
      '99693',
      '99737',
      '99780'
    ],
    '105': [
      '99820',
      '99825',
      '99826',
      '99827',
      '99829',
      '99832',
      '99841'
    ],
    '090': [
      '99701',
      '99702',
      '99703',
      '99705',
      '99709',
      '99712',
      '99714',
      '99760',
      '99775'
    ],
    '195': [
      '99830',
      '99833',
      '99836'
    ],
    '230': [
      '99840'
    ],
    '164': [
      '99548',
      '99549',
      '99564',
      '99565',
      '99579',
      '99606',
      '99613',
      '99625',
      '99640',
      '99647',
      '99648',
      '99649',
      '99653',
      '99695'
    ],
    '282': [
      '99689'
    ],
    '070': [
      '99555',
      '99569',
      '99576',
      '99580',
      '99628',
      '99636',
      '99678'
    ],
    '100': [
      '99827'
    ],
    '110': [
      '99801',
      '99824'
    ],
    '198': [
      '99901',
      '99918',
      '99919',
      '99921',
      '99922',
      '99923',
      '99925',
      '99926',
      '99927'
    ],
    '220': [
      '99835'
    ],
    '122': [
      '99556',
      '99568',
      '99572',
      '99603',
      '99605',
      '99610',
      '99611',
      '99631',
      '99639',
      '99663',
      '99664',
      '99669',
      '99672',
      '99682'
    ],
    '150': [
      '99550',
      '99615',
      '99624',
      '99643',
      '99644'
    ],
    '050': [
      '99503',
      '99551',
      '99552',
      '99557',
      '99559',
      '99561',
      '99575',
      '99578',
      '99589',
      '99607',
      '99609',
      '99614',
      '99621',
      '99622',
      '99626',
      '99630',
      '99634',
      '99637',
      '99641',
      '99651',
      '99655',
      '99656',
      '99668',
      '99679',
      '99680',
      '99681',
      '99690'
    ],
    '180': [
      '99659',
      '99671',
      '99684',
      '99739',
      '99742',
      '99753',
      '99762',
      '99769',
      '99771',
      '99772',
      '99778',
      '99783',
      '99784',
      '99785'
    ],
    '016': [
      '99546',
      '99547',
      '99591',
      '99638',
      '99660',
      '99685',
      '99692'
    ],
    '020': [
      '99501',
      '99502',
      '99503',
      '99504',
      '99505',
      '99506',
      '99507',
      '99508',
      '99513',
      '99515',
      '99516',
      '99517',
      '99518',
      '99540',
      '99567',
      '99577',
      '99587'
    ],
    '130': [
      '99901'
    ],
    '290': [
      '99558',
      '99590',
      '99602',
      '99627',
      '99665',
      '99691',
      '99701',
      '99720',
      '99722',
      '99724',
      '99726',
      '99730',
      '99733',
      '99738',
      '99740',
      '99741',
      '99745',
      '99746',
      '99748',
      '99754',
      '99756',
      '99757',
      '99758',
      '99760',
      '99765',
      '99767',
      '99768',
      '99774',
      '99777',
      '99781',
      '99788',
      '99790'
    ],
    '013': [
      '99553',
      '99571',
      '99583',
      '99612',
      '99661'
    ],
    '060': [
      '99613',
      '99633',
      '99670'
    ],
    '185': [
      '99510',
      '99519',
      '99721',
      '99723',
      '99734',
      '99747',
      '99759',
      '99766',
      '99782',
      '99789',
      '99791'
    ],
    '068': [
      '99704',
      '99729',
      '99743',
      '99744',
      '99755',
      '99760'
    ],
    '240': [
      '99706',
      '99732',
      '99737',
      '99738',
      '99764',
      '99776',
      '99780'
    ],
    '275': [
      '99903',
      '99929'
    ],
    '188': [
      '99727',
      '99736',
      '99749',
      '99750',
      '99751',
      '99752',
      '99761',
      '99763',
      '99770',
      '99773',
      '99786'
    ],
    '170': [
      '99588',
      '99645',
      '99652',
      '99654',
      '99667',
      '99674',
      '99676',
      '99683',
      '99688',
      '99693',
      '99694',
      '99729'
    ]
  },
  '56': {
    '001': [
      '82051',
      '82052',
      '82055',
      '82058',
      '82063',
      '82070',
      '82072',
      '82073',
      '82083',
      '82084',
      '82201',
      '82329',
      '82637'
    ],
    '017': [
      '82401',
      '82430',
      '82443'
    ],
    '029': [
      '82190',
      '82414',
      '82421',
      '82423',
      '82433',
      '82435',
      '82440',
      '82450'
    ],
    '041': [
      '82930',
      '82933',
      '82936',
      '82937',
      '82939',
      '82944'
    ],
    '005': [
      '82716',
      '82718',
      '82725',
      '82727',
      '82731',
      '82732',
      '82831'
    ],
    '031': [
      '82201',
      '82210',
      '82213',
      '82214',
      '82215'
    ],
    '033': [
      '82801',
      '82831',
      '82832',
      '82833',
      '82835',
      '82836',
      '82837',
      '82838',
      '82839',
      '82842',
      '82844',
      '82845'
    ],
    '009': [
      '82229',
      '82633',
      '82636',
      '82637'
    ],
    '025': [
      '82601',
      '82604',
      '82609',
      '82620',
      '82630',
      '82635',
      '82636',
      '82638',
      '82639',
      '82642',
      '82643',
      '82644',
      '82646',
      '82648'
    ],
    '021': [
      '82001',
      '82005',
      '82007',
      '82009',
      '82050',
      '82053',
      '82054',
      '82059',
      '82060',
      '82061',
      '82081',
      '82082'
    ],
    '039': [
      '82190',
      '82925',
      '83001',
      '83011',
      '83012',
      '83013',
      '83014',
      '83025',
      '83414'
    ],
    '011': [
      '57717',
      '82710',
      '82711',
      '82712',
      '82714',
      '82720',
      '82721',
      '82729',
      '82730'
    ],
    '035': [
      '82922',
      '82923',
      '82925',
      '82932',
      '82941',
      '83001',
      '83113',
      '83115'
    ],
    '027': [
      '82222',
      '82224',
      '82225',
      '82227',
      '82242',
      '82633',
      '82701'
    ],
    '037': [
      '82301',
      '82322',
      '82336',
      '82901',
      '82929',
      '82932',
      '82934',
      '82935',
      '82938',
      '82942',
      '82943',
      '82945'
    ],
    '023': [
      '83101',
      '83110',
      '83111',
      '83112',
      '83114',
      '83116',
      '83118',
      '83119',
      '83120',
      '83121',
      '83122',
      '83123',
      '83124',
      '83126',
      '83127',
      '83128'
    ],
    '043': [
      '82401',
      '82442'
    ],
    '045': [
      '82701',
      '82715',
      '82723',
      '82729',
      '82730'
    ],
    '019': [
      '82639',
      '82640',
      '82831',
      '82832',
      '82834'
    ],
    '015': [
      '82210',
      '82212',
      '82217',
      '82219',
      '82221',
      '82223',
      '82225',
      '82240',
      '82243',
      '82244'
    ],
    '007': [
      '82083',
      '82301',
      '82321',
      '82323',
      '82324',
      '82325',
      '82327',
      '82329',
      '82331',
      '82332',
      '82334',
      '82335',
      '82604',
      '82620'
    ],
    '013': [
      '82301',
      '82501',
      '82510',
      '82512',
      '82513',
      '82514',
      '82515',
      '82516',
      '82520',
      '82523',
      '82642',
      '82649'
    ],
    '003': [
      '82401',
      '82410',
      '82411',
      '82412',
      '82420',
      '82421',
      '82422',
      '82423',
      '82426',
      '82428',
      '82431',
      '82432',
      '82434',
      '82435',
      '82441'
    ]
  },
  '45': {
    '079': [
      '29016',
      '29036',
      '29044',
      '29045',
      '29052',
      '29061',
      '29063',
      '29075',
      '29078',
      '29130',
      '29147',
      '29180',
      '29201',
      '29202',
      '29203',
      '29204',
      '29205',
      '29206',
      '29207',
      '29208',
      '29209',
      '29210',
      '29212',
      '29223',
      '29225',
      '29229'
    ],
    '009': [
      '29003',
      '29042',
      '29081',
      '29082',
      '29432',
      '29481',
      '29817',
      '29843'
    ],
    '055': [
      '29009',
      '29010',
      '29020',
      '29032',
      '29045',
      '29058',
      '29067',
      '29074',
      '29078',
      '29128',
      '29130',
      '29175',
      '29718'
    ],
    '029': [
      '29081',
      '29082',
      '29435',
      '29438',
      '29446',
      '29452',
      '29472',
      '29474',
      '29475',
      '29481',
      '29488',
      '29493',
      '29929',
      '29945'
    ],
    '021': [
      '29307',
      '29323',
      '29330',
      '29340',
      '29341',
      '29372',
      '29702'
    ],
    '053': [
      '29909',
      '29912',
      '29916',
      '29922',
      '29927',
      '29934',
      '29936',
      '29943',
      '29944',
      '29945'
    ],
    '031': [
      '29069',
      '29079',
      '29101',
      '29161',
      '29501',
      '29506',
      '29532',
      '29540',
      '29550',
      '29593'
    ],
    '091': [
      '29702',
      '29704',
      '29706',
      '29707',
      '29708',
      '29710',
      '29712',
      '29715',
      '29717',
      '29726',
      '29730',
      '29732',
      '29733',
      '29742',
      '29743',
      '29745'
    ],
    '063': [
      '29006',
      '29033',
      '29036',
      '29053',
      '29054',
      '29063',
      '29070',
      '29072',
      '29073',
      '29075',
      '29112',
      '29123',
      '29160',
      '29169',
      '29170',
      '29172',
      '29210',
      '29212'
    ],
    '025': [
      '29009',
      '29101',
      '29520',
      '29550',
      '29584',
      '29593',
      '29709',
      '29718',
      '29727',
      '29728',
      '29741'
    ],
    '059': [
      '29178',
      '29325',
      '29332',
      '29335',
      '29351',
      '29355',
      '29360',
      '29370',
      '29384',
      '29388',
      '29644',
      '29645',
      '29654',
      '29692'
    ],
    '039': [
      '29014',
      '29015',
      '29016',
      '29031',
      '29055',
      '29065',
      '29130',
      '29180'
    ],
    '011': [
      '29812',
      '29817',
      '29826',
      '29836',
      '29843',
      '29849',
      '29853'
    ],
    '041': [
      '29114',
      '29161',
      '29501',
      '29505',
      '29506',
      '29530',
      '29541',
      '29554',
      '29555',
      '29560',
      '29583',
      '29591'
    ],
    '033': [
      '29536',
      '29543',
      '29547',
      '29563',
      '29565',
      '29567',
      '29574',
      '29581',
      '29592'
    ],
    '037': [
      '29129',
      '29138',
      '29821',
      '29824',
      '29832',
      '29835',
      '29838',
      '29845',
      '29847',
      '29860'
    ],
    '085': [
      '29001',
      '29040',
      '29051',
      '29062',
      '29080',
      '29104',
      '29114',
      '29125',
      '29128',
      '29150',
      '29152',
      '29153',
      '29154',
      '29162',
      '29168'
    ],
    '089': [
      '29056',
      '29111',
      '29510',
      '29518',
      '29554',
      '29555',
      '29556',
      '29560',
      '29564',
      '29580',
      '29590'
    ],
    '045': [
      '29322',
      '29356',
      '29601',
      '29605',
      '29607',
      '29609',
      '29611',
      '29613',
      '29614',
      '29615',
      '29617',
      '29627',
      '29635',
      '29644',
      '29650',
      '29651',
      '29654',
      '29661',
      '29662',
      '29669',
      '29673',
      '29680',
      '29681',
      '29683',
      '29687',
      '29690'
    ],
    '081': [
      '29006',
      '29037',
      '29070',
      '29105',
      '29127',
      '29129',
      '29138',
      '29166',
      '29666',
      '29824',
      '29832',
      '29848'
    ],
    '057': [
      '29058',
      '29067',
      '29707',
      '29720'
    ],
    '001': [
      '29620',
      '29627',
      '29628',
      '29638',
      '29639',
      '29646',
      '29649',
      '29653',
      '29654',
      '29655',
      '29659',
      '29692',
      '29819'
    ],
    '017': [
      '29030',
      '29047',
      '29053',
      '29112',
      '29118',
      '29135',
      '29160'
    ],
    '087': [
      '29031',
      '29178',
      '29321',
      '29335',
      '29353',
      '29364',
      '29372',
      '29374',
      '29379'
    ],
    '035': [
      '29018',
      '29414',
      '29418',
      '29420',
      '29426',
      '29432',
      '29437',
      '29448',
      '29456',
      '29470',
      '29471',
      '29472',
      '29477',
      '29483',
      '29485'
    ],
    '005': [
      '29810',
      '29812',
      '29827',
      '29836',
      '29849',
      '29932'
    ],
    '051': [
      '29511',
      '29526',
      '29527',
      '29544',
      '29545',
      '29566',
      '29568',
      '29569',
      '29572',
      '29575',
      '29576',
      '29577',
      '29579',
      '29581',
      '29582',
      '29588'
    ],
    '077': [
      '29611',
      '29630',
      '29631',
      '29634',
      '29635',
      '29640',
      '29642',
      '29657',
      '29661',
      '29667',
      '29670',
      '29671',
      '29678',
      '29682',
      '29685'
    ],
    '027': [
      '29001',
      '29051',
      '29056',
      '29102',
      '29111',
      '29114',
      '29125',
      '29148',
      '29162',
      '29560'
    ],
    '069': [
      '29512',
      '29516',
      '29525',
      '29565',
      '29570',
      '29594',
      '29596'
    ],
    '067': [
      '29519',
      '29546',
      '29565',
      '29571',
      '29574',
      '29581',
      '29592'
    ],
    '071': [
      '29036',
      '29037',
      '29075',
      '29108',
      '29122',
      '29126',
      '29127',
      '29145',
      '29178',
      '29332',
      '29355'
    ],
    '075': [
      '29018',
      '29030',
      '29038',
      '29039',
      '29047',
      '29048',
      '29059',
      '29107',
      '29112',
      '29113',
      '29115',
      '29117',
      '29118',
      '29133',
      '29135',
      '29137',
      '29142',
      '29146',
      '29163',
      '29432',
      '29436'
    ],
    '047': [
      '29138',
      '29638',
      '29646',
      '29649',
      '29653',
      '29666',
      '29692',
      '29819',
      '29848'
    ],
    '083': [
      '29301',
      '29302',
      '29303',
      '29306',
      '29307',
      '29316',
      '29320',
      '29322',
      '29323',
      '29324',
      '29329',
      '29330',
      '29331',
      '29333',
      '29334',
      '29335',
      '29338',
      '29346',
      '29349',
      '29356',
      '29365',
      '29368',
      '29369',
      '29372',
      '29373',
      '29374',
      '29375',
      '29376',
      '29377',
      '29378',
      '29385',
      '29388',
      '29650',
      '29651'
    ],
    '023': [
      '29014',
      '29031',
      '29055',
      '29704',
      '29706',
      '29712',
      '29714',
      '29724',
      '29729',
      '29730',
      '29742'
    ],
    '043': [
      '29440',
      '29510',
      '29554',
      '29576',
      '29585'
    ],
    '065': [
      '29620',
      '29628',
      '29819',
      '29821',
      '29835',
      '29838',
      '29840',
      '29844',
      '29845',
      '29848',
      '29899'
    ],
    '061': [
      '29009',
      '29010',
      '29020',
      '29032',
      '29040',
      '29046',
      '29069',
      '29080',
      '29104',
      '29128',
      '29153',
      '29550'
    ],
    '049': [
      '29911',
      '29916',
      '29918',
      '29921',
      '29922',
      '29923',
      '29924',
      '29932',
      '29934',
      '29939',
      '29944',
      '29945'
    ],
    '019': [
      '29401',
      '29403',
      '29404',
      '29405',
      '29406',
      '29407',
      '29409',
      '29412',
      '29414',
      '29418',
      '29420',
      '29423',
      '29424',
      '29426',
      '29429',
      '29438',
      '29439',
      '29449',
      '29450',
      '29451',
      '29455',
      '29456',
      '29458',
      '29464',
      '29466',
      '29470',
      '29482',
      '29483',
      '29485',
      '29487'
    ],
    '015': [
      '29059',
      '29406',
      '29410',
      '29431',
      '29434',
      '29436',
      '29445',
      '29450',
      '29453',
      '29456',
      '29461',
      '29468',
      '29469',
      '29472',
      '29479',
      '29483',
      '29492'
    ],
    '007': [
      '29611',
      '29621',
      '29624',
      '29625',
      '29626',
      '29627',
      '29630',
      '29631',
      '29642',
      '29643',
      '29654',
      '29655',
      '29657',
      '29669',
      '29670',
      '29673',
      '29684',
      '29689',
      '29697'
    ],
    '013': [
      '29902',
      '29904',
      '29905',
      '29906',
      '29907',
      '29909',
      '29910',
      '29915',
      '29920',
      '29926',
      '29927',
      '29928',
      '29935',
      '29940',
      '29941',
      '29945'
    ],
    '073': [
      '29643',
      '29658',
      '29664',
      '29665',
      '29672',
      '29676',
      '29678',
      '29686',
      '29689',
      '29691',
      '29693',
      '29696'
    ],
    '003': [
      '29006',
      '29105',
      '29129',
      '29137',
      '29146',
      '29164',
      '29801',
      '29803',
      '29805',
      '29809',
      '29816',
      '29828',
      '29829',
      '29831',
      '29834',
      '29841',
      '29842',
      '29847',
      '29850',
      '29851',
      '29853',
      '29856',
      '29860'
    ]
  },
  '48': {
    '167': [
      '77510',
      '77511',
      '77517',
      '77518',
      '77539',
      '77546',
      '77550',
      '77551',
      '77554',
      '77563',
      '77565',
      '77568',
      '77573',
      '77590',
      '77591',
      '77617',
      '77623',
      '77650'
    ],
    '227': [
      '79511',
      '79713',
      '79720',
      '79733',
      '79748'
    ],
    '079': [
      '79314',
      '79346',
      '79379'
    ],
    '067': [
      '75551',
      '75555',
      '75556',
      '75560',
      '75562',
      '75563',
      '75565',
      '75566',
      '75568',
      '75572',
      '75630',
      '75638',
      '75656',
      '75657'
    ],
    '119': [
      '75415',
      '75428',
      '75432',
      '75441',
      '75448',
      '75450',
      '75469'
    ],
    '173': [
      '79720',
      '79739',
      '79782'
    ],
    '367': [
      '76008',
      '76020',
      '76023',
      '76035',
      '76049',
      '76066',
      '76067',
      '76082',
      '76085',
      '76086',
      '76087',
      '76088',
      '76108',
      '76126',
      '76462',
      '76486',
      '76487',
      '76490'
    ],
    '263': [
      '79518',
      '79528',
      '79549'
    ],
    '311': [
      '78007',
      '78021',
      '78071',
      '78072',
      '78075'
    ],
    '053': [
      '76527',
      '76539',
      '76549',
      '76550',
      '78605',
      '78608',
      '78611',
      '78639',
      '78642',
      '78654',
      '78657',
      '78669'
    ],
    '389': [
      '79718',
      '79770',
      '79772',
      '79780',
      '79785'
    ],
    '063': [
      '75451',
      '75686'
    ],
    '171': [
      '78028',
      '78058',
      '78618',
      '78624',
      '78631',
      '78671',
      '78675'
    ],
    '229': [
      '79837',
      '79839',
      '79847',
      '79851',
      '79855',
      '79938'
    ],
    '265': [
      '78010',
      '78013',
      '78024',
      '78025',
      '78028',
      '78029',
      '78055',
      '78058',
      '78631'
    ],
    '059': [
      '76437',
      '76443',
      '76464',
      '76469',
      '79504',
      '79510',
      '79541',
      '79601',
      '79602'
    ],
    '111': [
      '79022',
      '79051',
      '79087'
    ],
    '069': [
      '79027',
      '79031',
      '79042',
      '79043',
      '79045',
      '79052',
      '79063',
      '79085',
      '79088',
      '79347'
    ],
    '209': [
      '78610',
      '78619',
      '78620',
      '78623',
      '78640',
      '78652',
      '78656',
      '78663',
      '78666',
      '78676',
      '78737'
    ],
    '299': [
      '76831',
      '76885',
      '78607',
      '78609',
      '78639',
      '78643',
      '78657',
      '78672'
    ],
    '309': [
      '76524',
      '76557',
      '76624',
      '76630',
      '76633',
      '76638',
      '76640',
      '76643',
      '76654',
      '76655',
      '76657',
      '76664',
      '76673',
      '76682',
      '76689',
      '76691',
      '76701',
      '76704',
      '76705',
      '76706',
      '76707',
      '76708',
      '76710',
      '76711',
      '76712',
      '76798'
    ],
    '037': [
      '75501',
      '75503',
      '75554',
      '75559',
      '75561',
      '75567',
      '75569',
      '75570',
      '75573',
      '75574'
    ],
    '159': [
      '75455',
      '75457',
      '75478',
      '75480',
      '75481',
      '75487',
      '75494'
    ],
    '437': [
      '79042',
      '79052',
      '79072',
      '79088'
    ],
    '085': [
      '75002',
      '75009',
      '75013',
      '75023',
      '75024',
      '75025',
      '75034',
      '75035',
      '75044',
      '75048',
      '75069',
      '75070',
      '75071',
      '75074',
      '75075',
      '75078',
      '75080',
      '75082',
      '75087',
      '75093',
      '75094',
      '75098',
      '75164',
      '75166',
      '75173',
      '75189',
      '75248',
      '75252',
      '75287',
      '75407',
      '75409',
      '75424',
      '75442',
      '75452',
      '75454',
      '75491',
      '75495'
    ],
    '191': [
      '79226',
      '79233',
      '79239',
      '79245',
      '79259',
      '79261'
    ],
    '103': [
      '79731',
      '79756',
      '79766'
    ],
    '239': [
      '77455',
      '77465',
      '77957',
      '77961',
      '77962',
      '77969',
      '77970',
      '77971',
      '77979',
      '77991'
    ],
    '351': [
      '75928',
      '75932',
      '75933',
      '75948',
      '75951',
      '75956',
      '75966',
      '75977',
      '77612',
      '77614',
      '77632'
    ],
    '045': [
      '79088',
      '79226',
      '79255',
      '79257'
    ],
    '321': [
      '77414',
      '77415',
      '77419',
      '77428',
      '77440',
      '77456',
      '77457',
      '77458',
      '77465',
      '77468',
      '77480',
      '77482',
      '77483'
    ],
    '335': [
      '79512',
      '79532',
      '79565'
    ],
    '121': [
      '75007',
      '75009',
      '75010',
      '75019',
      '75022',
      '75024',
      '75028',
      '75034',
      '75056',
      '75057',
      '75065',
      '75067',
      '75068',
      '75077',
      '75078',
      '75093',
      '75287',
      '76052',
      '76078',
      '76092',
      '76177',
      '76201',
      '76205',
      '76207',
      '76208',
      '76209',
      '76210',
      '76226',
      '76227',
      '76234',
      '76247',
      '76249',
      '76258',
      '76259',
      '76262',
      '76266',
      '76272'
    ],
    '205': [
      '79018',
      '79022',
      '79044'
    ],
    '261': [
      '78338',
      '78385',
      '78580'
    ],
    '489': [
      '78561',
      '78569',
      '78580',
      '78590',
      '78594',
      '78598'
    ],
    '297': [
      '78008',
      '78022',
      '78060',
      '78071',
      '78075',
      '78102',
      '78118',
      '78119',
      '78368',
      '78372',
      '78383'
    ],
    '107': [
      '79235',
      '79250',
      '79322',
      '79343',
      '79356',
      '79357',
      '79370'
    ],
    '095': [
      '76837',
      '76858',
      '76862',
      '76866',
      '76875'
    ],
    '215': [
      '78501',
      '78503',
      '78504',
      '78516',
      '78537',
      '78538',
      '78539',
      '78541',
      '78542',
      '78543',
      '78549',
      '78557',
      '78558',
      '78599',
      '78560',
      '78562',
      '78563',
      '78565',
      '78569',
      '78570',
      '78572',
      '78573',
      '78574',
      '78576',
      '78577',
      '78579',
      '78589',
      '78595',
      '78596'
    ],
    '051': [
      '76567',
      '77836',
      '77878',
      '77879'
    ],
    '451': [
      '76861',
      '76901',
      '76903',
      '76904',
      '76905',
      '76908',
      '76934',
      '76935',
      '76937',
      '76939',
      '76940',
      '76955',
      '76957',
      '76958'
    ],
    '491': [
      '76511',
      '76527',
      '76530',
      '76537',
      '76573',
      '76574',
      '76577',
      '76578',
      '78613',
      '78615',
      '78621',
      '78626',
      '78628',
      '78633',
      '78634',
      '78641',
      '78642',
      '78664',
      '78665',
      '78681',
      '78717',
      '78726',
      '78727',
      '78728',
      '78729',
      '78750',
      '78759'
    ],
    '009': [
      '76308',
      '76310',
      '76351',
      '76360',
      '76366',
      '76370',
      '76374',
      '76379',
      '76389'
    ],
    '133': [
      '76435',
      '76437',
      '76445',
      '76448',
      '76454',
      '76466',
      '76470',
      '76471',
      '76475'
    ],
    '365': [
      '75631',
      '75633',
      '75639',
      '75643',
      '75669',
      '75691',
      '75954',
      '75974',
      '75975'
    ],
    '345': [
      '79201',
      '79234',
      '79235',
      '79244',
      '79255',
      '79256'
    ],
    '417': [
      '76430',
      '76464',
      '79504',
      '79533',
      '79601'
    ],
    '213': [
      '75124',
      '75143',
      '75147',
      '75148',
      '75156',
      '75163',
      '75751',
      '75752',
      '75756',
      '75758',
      '75763',
      '75770',
      '75778'
    ],
    '043': [
      '79735',
      '79830',
      '79831',
      '79834',
      '79842',
      '79852'
    ],
    '329': [
      '79701',
      '79703',
      '79705',
      '79706',
      '79707',
      '79765',
      '79766'
    ],
    '411': [
      '76824',
      '76832',
      '76842',
      '76869',
      '76871',
      '76872',
      '76877'
    ],
    '065': [
      '79036',
      '79039',
      '79068',
      '79080',
      '79097',
      '79108'
    ],
    '447': [
      '76372',
      '76430',
      '76483',
      '76491',
      '79533'
    ],
    '003': [
      '79714'
    ],
    '377': [
      '79843',
      '79845',
      '79846',
      '79854'
    ],
    '251': [
      '76009',
      '76028',
      '76031',
      '76033',
      '76035',
      '76036',
      '76044',
      '76049',
      '76050',
      '76058',
      '76059',
      '76061',
      '76063',
      '76070',
      '76084',
      '76093'
    ],
    '431': [
      '76945',
      '76951'
    ],
    '047': [
      '78353',
      '78355'
    ],
    '183': [
      '75601',
      '75602',
      '75603',
      '75604',
      '75605',
      '75641',
      '75645',
      '75647',
      '75662',
      '75693'
    ],
    '471': [
      '75852',
      '75862',
      '77320',
      '77334',
      '77340',
      '77342',
      '77358',
      '77367',
      '77831',
      '77873'
    ],
    '379': [
      '75410',
      '75440',
      '75453',
      '75472'
    ],
    '245': [
      '77613',
      '77619',
      '77622',
      '77627',
      '77629',
      '77640',
      '77642',
      '77651',
      '77655',
      '77665',
      '77701',
      '77702',
      '77703',
      '77705',
      '77706',
      '77707',
      '77708',
      '77713'
    ],
    '181': [
      '75020',
      '75021',
      '75058',
      '75076',
      '75090',
      '75092',
      '75414',
      '75459',
      '75489',
      '75490',
      '75491',
      '75495',
      '76233',
      '76245',
      '76258',
      '76264',
      '76268',
      '76271',
      '76273'
    ],
    '031': [
      '78070',
      '78606',
      '78620',
      '78635',
      '78636',
      '78643',
      '78663',
      '78669',
      '78671',
      '78676'
    ],
    '465': [
      '76943',
      '76950',
      '78837',
      '78840',
      '78843',
      '78871'
    ],
    '025': [
      '78102',
      '78104',
      '78119',
      '78125',
      '78142',
      '78145',
      '78146',
      '78162',
      '78387',
      '78389',
      '78391'
    ],
    '419': [
      '75643',
      '75935',
      '75954',
      '75972',
      '75973',
      '75974',
      '75975'
    ],
    '289': [
      '75831',
      '75833',
      '75846',
      '75850',
      '75855',
      '77855',
      '77865',
      '77871'
    ],
    '453': [
      '78610',
      '78612',
      '78613',
      '78615',
      '78617',
      '78620',
      '78621',
      '78634',
      '78641',
      '78645',
      '78652',
      '78653',
      '78654',
      '78660',
      '78663',
      '78664',
      '78669',
      '78701',
      '78702',
      '78703',
      '78704',
      '78705',
      '78712',
      '78719',
      '78721',
      '78722',
      '78723',
      '78724',
      '78725',
      '78726',
      '78727',
      '78728',
      '78729',
      '78730',
      '78731',
      '78732',
      '78733',
      '78734',
      '78735',
      '78736',
      '78737',
      '78738',
      '78739',
      '78741',
      '78742',
      '78744',
      '78745',
      '78746',
      '78747',
      '78748',
      '78749',
      '78750',
      '78751',
      '78752',
      '78753',
      '78754',
      '78756',
      '78757',
      '78758',
      '78759'
    ],
    '039': [
      '77422',
      '77430',
      '77444',
      '77480',
      '77486',
      '77511',
      '77515',
      '77531',
      '77534',
      '77541',
      '77566',
      '77577',
      '77578',
      '77581',
      '77583',
      '77584'
    ],
    '149': [
      '78932',
      '78938',
      '78940',
      '78941',
      '78942',
      '78945',
      '78946',
      '78949',
      '78954',
      '78956',
      '78957',
      '78959',
      '78962',
      '78963'
    ],
    '395': [
      '76629',
      '76653',
      '76687',
      '77807',
      '77808',
      '77837',
      '77856',
      '77859',
      '77867'
    ],
    '217': [
      '76050',
      '76055',
      '76093',
      '76621',
      '76622',
      '76627',
      '76628',
      '76631',
      '76636',
      '76645',
      '76648',
      '76650',
      '76660',
      '76666',
      '76670',
      '76673',
      '76676',
      '76691',
      '76692'
    ],
    '027': [
      '76501',
      '76502',
      '76504',
      '76508',
      '76511',
      '76513',
      '76519',
      '76527',
      '76528',
      '76534',
      '76539',
      '76541',
      '76542',
      '76543',
      '76544',
      '76548',
      '76549',
      '76554',
      '76557',
      '76559',
      '76569',
      '76571',
      '76579'
    ],
    '151': [
      '79520',
      '79526',
      '79534',
      '79543',
      '79545',
      '79546',
      '79556',
      '79560',
      '79561'
    ],
    '269': [
      '79236',
      '79248'
    ],
    '273': [
      '78355',
      '78363',
      '78379',
      '78418'
    ],
    '481': [
      '77420',
      '77432',
      '77434',
      '77435',
      '77436',
      '77437',
      '77443',
      '77448',
      '77453',
      '77454',
      '77455',
      '77467',
      '77485',
      '77488'
    ],
    '199': [
      '77369',
      '77374',
      '77376',
      '77519',
      '77564',
      '77585',
      '77625',
      '77656',
      '77657',
      '77659',
      '77663'
    ],
    '415': [
      '79512',
      '79517',
      '79526',
      '79527',
      '79532',
      '79545',
      '79546',
      '79549'
    ],
    '137': [
      '76950',
      '78828',
      '78833',
      '78880'
    ],
    '017': [
      '79324',
      '79325',
      '79344',
      '79346',
      '79347',
      '79371'
    ],
    '185': [
      '77356',
      '77363',
      '77484',
      '77830',
      '77831',
      '77861',
      '77868',
      '77873',
      '77876'
    ],
    '089': [
      '77412',
      '77434',
      '77435',
      '77442',
      '77460',
      '77470',
      '77475',
      '77964',
      '78933',
      '78934',
      '78935',
      '78938',
      '78940',
      '78943',
      '78950',
      '78951',
      '78962'
    ],
    '011': [
      '79019',
      '79042',
      '79094',
      '79226'
    ],
    '301': [
      '79754'
    ],
    '259': [
      '78004',
      '78006',
      '78013',
      '78015',
      '78027',
      '78070',
      '78606',
      '78624'
    ],
    '127': [
      '78014',
      '78827',
      '78830',
      '78834',
      '78836',
      '78839'
    ],
    '461': [
      '79731',
      '79752',
      '79755',
      '79778'
    ],
    '087': [
      '79079',
      '79095',
      '79230',
      '79245',
      '79251'
    ],
    '081': [
      '76933',
      '76945',
      '76949',
      '76953',
      '79506'
    ],
    '097': [
      '76233',
      '76234',
      '76238',
      '76239',
      '76240',
      '76250',
      '76252',
      '76253',
      '76263',
      '76265',
      '76266',
      '76271',
      '76272',
      '76273'
    ],
    '287': [
      '77853',
      '78621',
      '78650',
      '78659',
      '78942',
      '78946',
      '78947',
      '78948'
    ],
    '001': [
      '75751',
      '75763',
      '75779',
      '75801',
      '75803',
      '75832',
      '75839',
      '75844',
      '75853',
      '75861'
    ],
    '129': [
      '79039',
      '79057',
      '79226',
      '79237',
      '79240',
      '79245'
    ],
    '369': [
      '79009',
      '79035',
      '79053',
      '79325',
      '79347'
    ],
    '115': [
      '79331',
      '79351',
      '79377',
      '79713'
    ],
    '211': [
      '79011',
      '79014'
    ],
    '255': [
      '78113',
      '78116',
      '78117',
      '78118',
      '78119',
      '78141',
      '78144',
      '78151'
    ],
    '005': [
      '75901',
      '75904',
      '75941',
      '75949',
      '75969',
      '75980'
    ],
    '189': [
      '79021',
      '79032',
      '79041',
      '79052',
      '79064',
      '79072',
      '79250',
      '79311'
    ],
    '439': [
      '75022',
      '75028',
      '75050',
      '75051',
      '75052',
      '75054',
      '76001',
      '76002',
      '76006',
      '76008',
      '76010',
      '76011',
      '76012',
      '76013',
      '76014',
      '76015',
      '76016',
      '76017',
      '76018',
      '76020',
      '76021',
      '76022',
      '76028',
      '76034',
      '76036',
      '76039',
      '76040',
      '76051',
      '76052',
      '76053',
      '76054',
      '76060',
      '76063',
      '76071',
      '76092',
      '76102',
      '76103',
      '76104',
      '76105',
      '76106',
      '76107',
      '76108',
      '76109',
      '76110',
      '76111',
      '76112',
      '76114',
      '76115',
      '76116',
      '76117',
      '76118',
      '76119',
      '76120',
      '76123',
      '76126',
      '76127',
      '76129',
      '76131',
      '76132',
      '76133',
      '76134',
      '76135',
      '76137',
      '76140',
      '76148',
      '76155',
      '76164',
      '76177',
      '76179',
      '76180',
      '76182',
      '76244',
      '76248',
      '76262'
    ],
    '339': [
      '77301',
      '77302',
      '77303',
      '77304',
      '77306',
      '77316',
      '77318',
      '77328',
      '77339',
      '77354',
      '77355',
      '77356',
      '77357',
      '77358',
      '77362',
      '77365',
      '77372',
      '77378',
      '77380',
      '77381',
      '77382',
      '77384',
      '77385',
      '77386',
      '77447',
      '77873'
    ],
    '237': [
      '76230',
      '76365',
      '76389',
      '76426',
      '76427',
      '76431',
      '76458',
      '76459',
      '76486',
      '76487'
    ],
    '331': [
      '76511',
      '76518',
      '76519',
      '76520',
      '76523',
      '76534',
      '76556',
      '76567',
      '76569',
      '76570',
      '76577',
      '77836',
      '77857'
    ],
    '359': [
      '79001',
      '79010',
      '79018',
      '79092',
      '79098',
      '88430'
    ],
    '105': [
      '76932',
      '76943',
      '79744',
      '79781',
      '79942'
    ],
    '315': [
      '75555',
      '75630',
      '75640',
      '75651',
      '75657',
      '75668',
      '75683'
    ],
    '507': [
      '78829',
      '78839',
      '78872'
    ],
    '303': [
      '79250',
      '79311',
      '79329',
      '79343',
      '79350',
      '79358',
      '79363',
      '79364',
      '79366',
      '79382',
      '79401',
      '79403',
      '79404',
      '79406',
      '79407',
      '79410',
      '79411',
      '79412',
      '79413',
      '79414',
      '79415',
      '79416',
      '79423',
      '79424'
    ],
    '429': [
      '76424',
      '76429',
      '76437',
      '76450',
      '76464',
      '76470',
      '76475',
      '76481'
    ],
    '401': [
      '75603',
      '75641',
      '75652',
      '75654',
      '75662',
      '75667',
      '75669',
      '75681',
      '75682',
      '75684',
      '75691',
      '75760',
      '75784',
      '75789',
      '75946',
      '75975'
    ],
    '293': [
      '75838',
      '75846',
      '76635',
      '76642',
      '76648',
      '76653',
      '76664',
      '76667',
      '76673',
      '76678',
      '76686',
      '76687',
      '76693'
    ],
    '409': [
      '78336',
      '78352',
      '78359',
      '78362',
      '78368',
      '78370',
      '78374',
      '78387',
      '78390'
    ],
    '493': [
      '78064',
      '78101',
      '78112',
      '78113',
      '78114',
      '78121',
      '78140',
      '78143',
      '78147',
      '78160',
      '78161',
      '78223'
    ],
    '165': [
      '79323',
      '79331',
      '79342',
      '79359',
      '79360'
    ],
    '257': [
      '75114',
      '75126',
      '75142',
      '75143',
      '75147',
      '75157',
      '75158',
      '75159',
      '75160',
      '75161',
      '75169',
      '75474'
    ],
    '101': [
      '79201',
      '79248',
      '79252'
    ],
    '125': [
      '79220',
      '79229',
      '79243',
      '79370'
    ],
    '467': [
      '75103',
      '75117',
      '75124',
      '75127',
      '75140',
      '75147',
      '75169',
      '75752',
      '75754',
      '75756',
      '75758',
      '75778',
      '75790'
    ],
    '049': [
      '76432',
      '76443',
      '76471',
      '76474',
      '76801',
      '76802',
      '76823',
      '76827',
      '76857',
      '76890'
    ],
    '295': [
      '79005',
      '79014',
      '79024',
      '79034',
      '79046',
      '79056'
    ],
    '487': [
      '76360',
      '76364',
      '76373',
      '76384',
      '79225',
      '79247'
    ],
    '477': [
      '77426',
      '77833',
      '77835',
      '77868',
      '77880',
      '78932',
      '78946'
    ],
    '323': [
      '78852',
      '78860',
      '78877'
    ],
    '073': [
      '75654',
      '75757',
      '75759',
      '75764',
      '75766',
      '75780',
      '75784',
      '75785',
      '75789',
      '75925',
      '75969',
      '75976'
    ],
    '221': [
      '76033',
      '76035',
      '76048',
      '76049',
      '76087',
      '76433',
      '76462',
      '76476'
    ],
    '441': [
      '79508',
      '79530',
      '79536',
      '79541',
      '79561',
      '79562',
      '79563',
      '79566',
      '79567',
      '79601',
      '79602',
      '79603',
      '79605',
      '79606',
      '79607',
      '79699'
    ],
    '131': [
      '78332',
      '78341',
      '78349',
      '78357',
      '78361',
      '78376',
      '78384'
    ],
    '193': [
      '76436',
      '76457',
      '76525',
      '76531',
      '76538',
      '76565',
      '76566',
      '76637',
      '76844'
    ],
    '153': [
      '79231',
      '79235',
      '79241',
      '79250',
      '79255',
      '79258'
    ],
    '055': [
      '78610',
      '78616',
      '78622',
      '78632',
      '78640',
      '78644',
      '78648',
      '78655',
      '78656',
      '78661',
      '78662',
      '78666',
      '78953',
      '78959'
    ],
    '195': [
      '79040',
      '79062',
      '79081'
    ],
    '109': [
      '79772',
      '79847',
      '79855',
      '88220'
    ],
    '347': [
      '75760',
      '75788',
      '75937',
      '75943',
      '75944',
      '75946',
      '75961',
      '75962',
      '75964',
      '75965',
      '75978'
    ],
    '333': [
      '76442',
      '76531',
      '76801',
      '76844',
      '76853',
      '76864',
      '76870',
      '76890'
    ],
    '305': [
      '79345',
      '79351',
      '79356',
      '79364',
      '79373',
      '79381',
      '79423'
    ],
    '291': [
      '77327',
      '77328',
      '77368',
      '77369',
      '77371',
      '77372',
      '77533',
      '77535',
      '77538',
      '77560',
      '77561',
      '77564',
      '77575'
    ],
    '459': [
      '75494',
      '75604',
      '75605',
      '75640',
      '75644',
      '75645',
      '75647',
      '75683',
      '75686',
      '75755',
      '75765'
    ],
    '391': [
      '77950',
      '77990',
      '78340',
      '78377',
      '78393'
    ],
    '479': [
      '78019',
      '78040',
      '78041',
      '78043',
      '78044',
      '78045',
      '78046',
      '78344',
      '78369',
      '78371'
    ],
    '219': [
      '79313',
      '79336',
      '79339',
      '79353',
      '79358',
      '79363',
      '79367',
      '79372',
      '79380',
      '79407'
    ],
    '099': [
      '76522',
      '76525',
      '76528',
      '76538',
      '76539',
      '76544',
      '76549',
      '76557',
      '76561',
      '76566',
      '76596',
      '76597',
      '76598',
      '76599',
      '76634',
      '76657',
      '76689'
    ],
    '117': [
      '79001',
      '79025',
      '79035',
      '79045',
      '79092',
      '79098'
    ],
    '035': [
      '76043',
      '76457',
      '76633',
      '76634',
      '76637',
      '76649',
      '76652',
      '76665',
      '76671',
      '76689',
      '76690'
    ],
    '317': [
      '79331',
      '79713',
      '79748',
      '79749',
      '79782',
      '79783'
    ],
    '033': [
      '79351',
      '79511',
      '79517',
      '79527',
      '79713',
      '79720',
      '79738'
    ],
    '499': [
      '75410',
      '75431',
      '75440',
      '75451',
      '75471',
      '75494',
      '75497',
      '75755',
      '75765',
      '75773',
      '75783'
    ],
    '147': [
      '75413',
      '75418',
      '75423',
      '75424',
      '75438',
      '75439',
      '75446',
      '75447',
      '75449',
      '75452',
      '75469',
      '75475',
      '75476',
      '75479',
      '75488',
      '75490',
      '75491',
      '75492',
      '75496'
    ],
    '497': [
      '76020',
      '76023',
      '76052',
      '76071',
      '76073',
      '76078',
      '76082',
      '76225',
      '76234',
      '76249',
      '76259',
      '76270',
      '76426',
      '76431',
      '76487'
    ],
    '281': [
      '76522',
      '76525',
      '76539',
      '76550',
      '76844',
      '76853'
    ],
    '145': [
      '76519',
      '76524',
      '76570',
      '76579',
      '76629',
      '76630',
      '76632',
      '76653',
      '76655',
      '76656',
      '76661',
      '76664',
      '76680',
      '76682',
      '76685',
      '76687',
      '76706'
    ],
    '177': [
      '77954',
      '77984',
      '78122',
      '78140',
      '78159',
      '78614',
      '78629',
      '78632',
      '78648',
      '78677',
      '78941',
      '78959'
    ],
    '421': [
      '73949',
      '79040',
      '79084',
      '79086'
    ],
    '163': [
      '78005',
      '78017',
      '78057',
      '78061',
      '78886'
    ],
    '179': [
      '79039',
      '79054',
      '79057',
      '79059',
      '79065'
    ],
    '057': [
      '77465',
      '77978',
      '77979',
      '77982',
      '77983',
      '77990'
    ],
    '203': [
      '75601',
      '75602',
      '75605',
      '75640',
      '75642',
      '75650',
      '75651',
      '75657',
      '75661',
      '75670',
      '75672',
      '75692'
    ],
    '223': [
      '75420',
      '75431',
      '75433',
      '75437',
      '75453',
      '75471',
      '75472',
      '75478',
      '75481',
      '75482',
      '75494',
      '75497'
    ],
    '225': [
      '75835',
      '75839',
      '75844',
      '75847',
      '75849',
      '75851',
      '75856',
      '75858'
    ],
    '475': [
      '79719',
      '79742',
      '79756',
      '79772',
      '79777',
      '79788'
    ],
    '423': [
      '75647',
      '75662',
      '75684',
      '75701',
      '75702',
      '75703',
      '75704',
      '75705',
      '75706',
      '75707',
      '75708',
      '75709',
      '75750',
      '75757',
      '75762',
      '75771',
      '75773',
      '75789',
      '75790',
      '75791',
      '75792'
    ],
    '275': [
      '76363',
      '76371',
      '76380',
      '79227',
      '79505',
      '79529'
    ],
    '413': [
      '76841',
      '76935',
      '76936'
    ],
    '363': [
      '76066',
      '76067',
      '76429',
      '76449',
      '76450',
      '76453',
      '76462',
      '76463',
      '76472',
      '76475',
      '76484',
      '76486'
    ],
    '307': [
      '76825',
      '76836',
      '76842',
      '76852',
      '76858',
      '76871',
      '76872',
      '76887'
    ],
    '501': [
      '79323',
      '79355',
      '79359',
      '79376'
    ],
    '341': [
      '79013',
      '79018',
      '79029',
      '79036',
      '79058',
      '79083',
      '79086',
      '79105'
    ],
    '071': [
      '77514',
      '77521',
      '77523',
      '77535',
      '77560',
      '77575',
      '77580',
      '77597',
      '77661',
      '77665'
    ],
    '141': [
      '79821',
      '79835',
      '79836',
      '79838',
      '79849',
      '79853',
      '79901',
      '79902',
      '79903',
      '79904',
      '79905',
      '79906',
      '79907',
      '79908',
      '79911',
      '79912',
      '79915',
      '79916',
      '79920',
      '79922',
      '79924',
      '79925',
      '79927',
      '79928',
      '79930',
      '79932',
      '79934',
      '79935',
      '79936',
      '79938',
      '88063'
    ],
    '383': [
      '76932',
      '79739'
    ],
    '197': [
      '79225',
      '79252'
    ],
    '243': [
      '79734',
      '79854',
      '79855'
    ],
    '361': [
      '77611',
      '77630',
      '77632',
      '77662'
    ],
    '023': [
      '76380'
    ],
    '285': [
      '77964',
      '77975',
      '77984',
      '77987',
      '77995',
      '78956'
    ],
    '375': [
      '79010',
      '79012',
      '79036',
      '79058',
      '79098',
      '79101',
      '79102',
      '79103',
      '79104',
      '79106',
      '79107',
      '79108',
      '79109',
      '79111',
      '79118',
      '79121',
      '79124'
    ],
    '399': [
      '76821',
      '76861',
      '76865',
      '76875',
      '76882',
      '76933',
      '79519',
      '79538',
      '79566',
      '79567'
    ],
    '139': [
      '75101',
      '75104',
      '75119',
      '75125',
      '75146',
      '75152',
      '75154',
      '75165',
      '75167',
      '76041',
      '76050',
      '76064',
      '76065',
      '76084',
      '76623',
      '76651',
      '76670'
    ],
    '355': [
      '78330',
      '78335',
      '78336',
      '78339',
      '78343',
      '78351',
      '78373',
      '78380',
      '78383',
      '78401',
      '78402',
      '78404',
      '78405',
      '78406',
      '78407',
      '78408',
      '78409',
      '78410',
      '78411',
      '78412',
      '78413',
      '78414',
      '78415',
      '78416',
      '78417',
      '78418',
      '78419'
    ],
    '233': [
      '79007',
      '79036',
      '79062',
      '79078',
      '79080',
      '79081',
      '79083'
    ],
    '013': [
      '78005',
      '78008',
      '78011',
      '78012',
      '78026',
      '78050',
      '78052',
      '78064',
      '78065',
      '78069',
      '78073',
      '78113',
      '78114',
      '78264'
    ],
    '485': [
      '76301',
      '76302',
      '76305',
      '76306',
      '76308',
      '76309',
      '76310',
      '76311',
      '76354',
      '76360',
      '76367'
    ],
    '353': [
      '79506',
      '79532',
      '79535',
      '79536',
      '79537',
      '79545',
      '79556',
      '79561'
    ],
    '393': [
      '79059',
      '79065'
    ],
    '123': [
      '77954',
      '77974',
      '77994',
      '77995',
      '78141',
      '78164',
      '78629'
    ],
    '445': [
      '79316',
      '79345',
      '79359',
      '79378'
    ],
    '337': [
      '76228',
      '76230',
      '76239',
      '76251',
      '76255',
      '76261',
      '76265',
      '76270'
    ],
    '385': [
      '78024',
      '78058',
      '78828',
      '78833',
      '78873',
      '78879',
      '78880',
      '78885'
    ],
    '397': [
      '75032',
      '75087',
      '75088',
      '75089',
      '75098',
      '75132',
      '75160',
      '75189'
    ],
    '029': [
      '78002',
      '78006',
      '78015',
      '78023',
      '78039',
      '78052',
      '78069',
      '78073',
      '78101',
      '78108',
      '78109',
      '78112',
      '78124',
      '78148',
      '78150',
      '78152',
      '78154',
      '78201',
      '78202',
      '78203',
      '78204',
      '78205',
      '78207',
      '78208',
      '78209',
      '78210',
      '78211',
      '78212',
      '78213',
      '78214',
      '78215',
      '78216',
      '78217',
      '78218',
      '78219',
      '78220',
      '78221',
      '78222',
      '78223',
      '78224',
      '78225',
      '78226',
      '78227',
      '78228',
      '78229',
      '78230',
      '78231',
      '78232',
      '78233',
      '78234',
      '78235',
      '78236',
      '78237',
      '78238',
      '78239',
      '78240',
      '78242',
      '78243',
      '78244',
      '78245',
      '78247',
      '78248',
      '78249',
      '78250',
      '78251',
      '78252',
      '78253',
      '78254',
      '78255',
      '78256',
      '78257',
      '78258',
      '78259',
      '78260',
      '78261',
      '78263',
      '78264',
      '78266'
    ],
    '371': [
      '79730',
      '79735',
      '79743',
      '79744',
      '79781',
      '79848'
    ],
    '021': [
      '78602',
      '78612',
      '78616',
      '78617',
      '78621',
      '78650',
      '78659',
      '78662',
      '78941',
      '78942',
      '78945',
      '78953',
      '78957'
    ],
    '091': [
      '78006',
      '78070',
      '78108',
      '78130',
      '78132',
      '78133',
      '78154',
      '78163',
      '78266',
      '78606',
      '78623',
      '78666'
    ],
    '373': [
      '75934',
      '75936',
      '75939',
      '75960',
      '77326',
      '77335',
      '77350',
      '77351',
      '77360'
    ],
    '169': [
      '79330',
      '79356',
      '79364'
    ],
    '279': [
      '79031',
      '79041',
      '79043',
      '79064',
      '79082',
      '79312',
      '79313',
      '79326',
      '79339',
      '79347',
      '79369',
      '79371'
    ],
    '201': [
      '77002',
      '77003',
      '77004',
      '77005',
      '77006',
      '77007',
      '77008',
      '77009',
      '77010',
      '77011',
      '77012',
      '77013',
      '77014',
      '77015',
      '77016',
      '77017',
      '77018',
      '77019',
      '77020',
      '77021',
      '77022',
      '77023',
      '77024',
      '77025',
      '77026',
      '77027',
      '77028',
      '77029',
      '77030',
      '77031',
      '77032',
      '77033',
      '77034',
      '77035',
      '77036',
      '77037',
      '77038',
      '77039',
      '77040',
      '77041',
      '77042',
      '77043',
      '77044',
      '77045',
      '77046',
      '77047',
      '77048',
      '77049',
      '77050',
      '77051',
      '77053',
      '77054',
      '77055',
      '77056',
      '77057',
      '77058',
      '77059',
      '77060',
      '77061',
      '77062',
      '77063',
      '77064',
      '77065',
      '77066',
      '77067',
      '77068',
      '77069',
      '77070',
      '77071',
      '77072',
      '77073',
      '77074',
      '77075',
      '77076',
      '77077',
      '77078',
      '77079',
      '77080',
      '77081',
      '77082',
      '77083',
      '77084',
      '77085',
      '77086',
      '77087',
      '77088',
      '77089',
      '77090',
      '77091',
      '77092',
      '77093',
      '77094',
      '77095',
      '77096',
      '77098',
      '77099',
      '77201',
      '77336',
      '77338',
      '77339',
      '77345',
      '77346',
      '77357',
      '77365',
      '77373',
      '77375',
      '77377',
      '77379',
      '77388',
      '77389',
      '77396',
      '77401',
      '77429',
      '77433',
      '77447',
      '77449',
      '77450',
      '77477',
      '77484',
      '77489',
      '77493',
      '77494',
      '77502',
      '77503',
      '77504',
      '77505',
      '77506',
      '77507',
      '77520',
      '77521',
      '77530',
      '77532',
      '77536',
      '77546',
      '77547',
      '77562',
      '77571',
      '77581',
      '77586',
      '77587',
      '77598'
    ],
    '469': [
      '77901',
      '77904',
      '77905',
      '77951',
      '77968',
      '77973',
      '77974',
      '77976',
      '77977',
      '77979',
      '77988',
      '77995'
    ],
    '427': [
      '78536',
      '78545',
      '78548',
      '78582',
      '78584',
      '78585',
      '78588',
      '78591'
    ],
    '267': [
      '76849',
      '76854',
      '76874',
      '78631'
    ],
    '157': [
      '77053',
      '77083',
      '77085',
      '77099',
      '77406',
      '77407',
      '77417',
      '77423',
      '77430',
      '77435',
      '77441',
      '77444',
      '77450',
      '77451',
      '77459',
      '77461',
      '77464',
      '77469',
      '77471',
      '77476',
      '77477',
      '77478',
      '77479',
      '77481',
      '77485',
      '77489',
      '77493',
      '77494',
      '77498',
      '77545',
      '77583',
      '77584'
    ],
    '403': [
      '75930',
      '75931',
      '75948',
      '75959',
      '75968',
      '75972'
    ],
    '231': [
      '75135',
      '75160',
      '75169',
      '75189',
      '75401',
      '75402',
      '75422',
      '75423',
      '75428',
      '75442',
      '75449',
      '75452',
      '75453',
      '75474',
      '75496'
    ],
    '449': [
      '75455',
      '75487',
      '75493',
      '75558',
      '75571',
      '75686'
    ],
    '283': [
      '78001',
      '78014',
      '78017',
      '78019',
      '78021'
    ],
    '313': [
      '75852',
      '77864',
      '77871',
      '77872'
    ],
    '495': [
      '79745',
      '79756',
      '79789'
    ],
    '319': [
      '76820',
      '76825',
      '76842',
      '76856',
      '76869',
      '78618'
    ],
    '143': [
      '76401',
      '76402',
      '76433',
      '76445',
      '76446',
      '76453',
      '76457',
      '76462',
      '76463',
      '76649'
    ],
    '175': [
      '77905',
      '77960',
      '77963',
      '77993',
      '78107',
      '78164'
    ],
    '253': [
      '79501',
      '79503',
      '79520',
      '79525',
      '79533',
      '79536',
      '79553',
      '79560',
      '79601'
    ],
    '433': [
      '79502',
      '79528',
      '79540',
      '79546'
    ],
    '455': [
      '75845',
      '75847',
      '75851',
      '75856',
      '75862',
      '75904',
      '75926'
    ],
    '155': [
      '76384',
      '79227'
    ],
    '161': [
      '75831',
      '75838',
      '75840',
      '75848',
      '75855',
      '75859',
      '75860',
      '76667',
      '76693'
    ],
    '483': [
      '79003',
      '79011',
      '79057',
      '79061',
      '79079',
      '79096'
    ],
    '387': [
      '75412',
      '75417',
      '75426',
      '75435',
      '75436',
      '75550',
      '75554'
    ],
    '457': [
      '75936',
      '75938',
      '75942',
      '75979',
      '77616',
      '77624',
      '77656',
      '77660',
      '77663',
      '77664'
    ],
    '425': [
      '76043',
      '76048',
      '76070',
      '76077',
      '76433'
    ],
    '349': [
      '75102',
      '75105',
      '75109',
      '75110',
      '75119',
      '75144',
      '75153',
      '75155',
      '75859',
      '76626',
      '76639',
      '76641',
      '76648',
      '76679',
      '76681'
    ],
    '473': [
      '77355',
      '77363',
      '77423',
      '77445',
      '77446',
      '77447',
      '77466',
      '77484',
      '77493',
      '77494',
      '77868'
    ],
    '435': [
      '76950'
    ],
    '343': [
      '75568',
      '75571',
      '75638',
      '75656',
      '75668',
      '75686'
    ],
    '235': [
      '76901',
      '76904',
      '76930',
      '76941'
    ],
    '041': [
      '77801',
      '77802',
      '77803',
      '77807',
      '77808',
      '77840',
      '77845',
      '77859',
      '77868'
    ],
    '503': [
      '76372',
      '76374',
      '76424',
      '76450',
      '76460',
      '76481'
    ],
    '077': [
      '76228',
      '76230',
      '76261',
      '76305',
      '76310',
      '76357',
      '76365',
      '76377',
      '76389'
    ],
    '093': [
      '76432',
      '76436',
      '76442',
      '76444',
      '76445',
      '76446',
      '76452',
      '76454',
      '76455',
      '76471',
      '76474',
      '76857',
      '76890'
    ],
    '505': [
      '78067',
      '78076',
      '78361',
      '78545',
      '78564'
    ],
    '113': [
      '75001',
      '75006',
      '75007',
      '75019',
      '75038',
      '75039',
      '75040',
      '75041',
      '75042',
      '75043',
      '75044',
      '75048',
      '75050',
      '75051',
      '75052',
      '75054',
      '75060',
      '75061',
      '75062',
      '75063',
      '75067',
      '75080',
      '75081',
      '75082',
      '75088',
      '75089',
      '75098',
      '75104',
      '75115',
      '75116',
      '75125',
      '75134',
      '75137',
      '75141',
      '75146',
      '75149',
      '75150',
      '75154',
      '75159',
      '75172',
      '75180',
      '75181',
      '75182',
      '75201',
      '75202',
      '75203',
      '75204',
      '75205',
      '75206',
      '75207',
      '75208',
      '75209',
      '75210',
      '75211',
      '75212',
      '75214',
      '75215',
      '75216',
      '75217',
      '75218',
      '75219',
      '75220',
      '75223',
      '75224',
      '75225',
      '75226',
      '75227',
      '75228',
      '75229',
      '75230',
      '75231',
      '75232',
      '75233',
      '75234',
      '75235',
      '75236',
      '75237',
      '75238',
      '75240',
      '75241',
      '75243',
      '75244',
      '75246',
      '75247',
      '75248',
      '75249',
      '75251',
      '75252',
      '75253',
      '75254',
      '75270',
      '75287',
      '75390',
      '76051'
    ],
    '327': [
      '76841',
      '76848',
      '76854',
      '76859'
    ],
    '325': [
      '78003',
      '78009',
      '78016',
      '78023',
      '78039',
      '78052',
      '78056',
      '78057',
      '78059',
      '78066',
      '78253',
      '78850',
      '78861',
      '78884',
      '78886'
    ],
    '187': [
      '78108',
      '78121',
      '78123',
      '78124',
      '78130',
      '78140',
      '78154',
      '78155',
      '78638',
      '78648',
      '78655',
      '78666',
      '78670'
    ],
    '207': [
      '76388',
      '79521',
      '79529',
      '79533',
      '79539',
      '79544',
      '79547',
      '79548',
      '79553'
    ],
    '075': [
      '79201',
      '79230',
      '79259'
    ],
    '463': [
      '78801',
      '78802',
      '78833',
      '78838',
      '78870',
      '78879',
      '78881',
      '78884'
    ],
    '247': [
      '78361'
    ],
    '381': [
      '79012',
      '79015',
      '79016',
      '79042',
      '79091',
      '79098',
      '79103',
      '79106',
      '79109',
      '79110',
      '79118',
      '79119',
      '79121',
      '79124'
    ],
    '241': [
      '75931',
      '75933',
      '75951',
      '75956',
      '75980',
      '77612',
      '77615',
      '77662'
    ],
    '083': [
      '76443',
      '76801',
      '76823',
      '76828',
      '76834',
      '76845',
      '76873',
      '76878',
      '76882',
      '76884',
      '76888',
      '79510',
      '79519',
      '79538'
    ],
    '271': [
      '78832'
    ],
    '405': [
      '75929',
      '75930',
      '75935',
      '75968',
      '75972'
    ],
    '249': [
      '78332',
      '78342',
      '78355',
      '78372',
      '78375',
      '78380',
      '78383',
      '78384'
    ],
    '135': [
      '79741',
      '79758',
      '79759',
      '79761',
      '79762',
      '79763',
      '79764',
      '79765',
      '79766'
    ],
    '357': [
      '79005',
      '79033',
      '79070',
      '79081',
      '79093'
    ],
    '061': [
      '78520',
      '78521',
      '78526',
      '78535',
      '78550',
      '78552',
      '78559',
      '78566',
      '78567',
      '78569',
      '78575',
      '78578',
      '78583',
      '78586',
      '78592',
      '78593',
      '78597'
    ],
    '443': [
      '78851',
      '79781',
      '79848'
    ],
    '019': [
      '78003',
      '78023',
      '78055',
      '78063',
      '78883',
      '78884',
      '78885'
    ],
    '007': [
      '77950',
      '78336',
      '78358',
      '78382',
      '78390'
    ],
    '407': [
      '77320',
      '77328',
      '77331',
      '77358',
      '77359',
      '77364',
      '77371',
      '77378'
    ],
    '277': [
      '75411',
      '75416',
      '75421',
      '75435',
      '75436',
      '75446',
      '75460',
      '75462',
      '75468',
      '75470',
      '75473',
      '75477',
      '75486'
    ],
    '015': [
      '77418',
      '77426',
      '77473',
      '77474',
      '77485',
      '77833',
      '77835',
      '78931',
      '78933',
      '78940',
      '78944',
      '78950',
      '78954'
    ]
  },
  '10': {
    '005': [
      '19930',
      '19931',
      '19933',
      '19939',
      '19940',
      '19941',
      '19944',
      '19945',
      '19947',
      '19950',
      '19951',
      '19952',
      '19956',
      '19958',
      '19960',
      '19963',
      '19966',
      '19967',
      '19968',
      '19970',
      '19971',
      '19973',
      '19975'
    ],
    '001': [
      '19901',
      '19902',
      '19904',
      '19934',
      '19936',
      '19938',
      '19943',
      '19946',
      '19950',
      '19952',
      '19953',
      '19954',
      '19955',
      '19962',
      '19963',
      '19964',
      '19977',
      '19979'
    ],
    '003': [
      '19701',
      '19702',
      '19703',
      '19706',
      '19707',
      '19709',
      '19710',
      '19711',
      '19713',
      '19716',
      '19717',
      '19720',
      '19730',
      '19731',
      '19732',
      '19733',
      '19734',
      '19735',
      '19736',
      '19801',
      '19802',
      '19803',
      '19804',
      '19805',
      '19806',
      '19807',
      '19808',
      '19809',
      '19810',
      '19938',
      '19977',
      '21912'
    ]
  },
  '12': {
    '079': [
      '32059',
      '32331',
      '32336',
      '32340',
      '32350'
    ],
    '123': [
      '32331',
      '32336',
      '32347',
      '32348',
      '32356',
      '32359'
    ],
    '131': [
      '32413',
      '32433',
      '32435',
      '32439',
      '32455',
      '32459',
      '32461',
      '32462',
      '32464',
      '32539',
      '32550',
      '32567',
      '32578'
    ],
    '067': [
      '32008',
      '32066'
    ],
    '119': [
      '32159',
      '32163',
      '32162',
      '33513',
      '33514',
      '33521',
      '33538',
      '33585',
      '33597',
      '34484',
      '34785'
    ],
    '055': [
      '33825',
      '33852',
      '33857',
      '33870',
      '33872',
      '33875',
      '33876',
      '33960',
      '34974'
    ],
    '029': [
      '32008',
      '32359',
      '32628',
      '32648',
      '32680',
      '32692'
    ],
    '109': [
      '32033',
      '32080',
      '32081',
      '32082',
      '32084',
      '32086',
      '32092',
      '32095',
      '32145',
      '32224',
      '32259'
    ],
    '021': [
      '34101',
      '34102',
      '34103',
      '34104',
      '34105',
      '34108',
      '34109',
      '34110',
      '34112',
      '34113',
      '34114',
      '34116',
      '34117',
      '34119',
      '34120',
      '34134',
      '34137',
      '34138',
      '34139',
      '34140',
      '34141',
      '34142',
      '34145'
    ],
    '053': [
      '33523',
      '33597',
      '34601',
      '34602',
      '34604',
      '34606',
      '34607',
      '34608',
      '34609',
      '34613',
      '34614',
      '34661'
    ],
    '031': [
      '32073',
      '32202',
      '32204',
      '32205',
      '32206',
      '32207',
      '32208',
      '32209',
      '32210',
      '32211',
      '32212',
      '32216',
      '32217',
      '32218',
      '32219',
      '32220',
      '32221',
      '32222',
      '32223',
      '32224',
      '32225',
      '32226',
      '32227',
      '32228',
      '32233',
      '32234',
      '32244',
      '32246',
      '32250',
      '32254',
      '32256',
      '32257',
      '32258',
      '32266',
      '32277'
    ],
    '091': [
      '32531',
      '32536',
      '32539',
      '32541',
      '32542',
      '32544',
      '32547',
      '32548',
      '32564',
      '32567',
      '32569',
      '32578',
      '32579',
      '32580'
    ],
    '063': [
      '32420',
      '32423',
      '32426',
      '32431',
      '32432',
      '32440',
      '32442',
      '32443',
      '32445',
      '32446',
      '32447',
      '32448',
      '32460'
    ],
    '059': [
      '32425',
      '32440',
      '32455',
      '32464'
    ],
    '111': [
      '34945',
      '34946',
      '34947',
      '34949',
      '34950',
      '34951',
      '34952',
      '34953',
      '34957',
      '34972',
      '34981',
      '34982',
      '34983',
      '34984',
      '34986',
      '34987',
      '34990',
      '34994'
    ],
    '039': [
      '32324',
      '32330',
      '32332',
      '32333',
      '32343',
      '32351',
      '32352'
    ],
    '097': [
      '33848',
      '33896',
      '34739',
      '34741',
      '34743',
      '34744',
      '34746',
      '34747',
      '34758',
      '34759',
      '34769',
      '34771',
      '34772',
      '34773',
      '34972'
    ],
    '011': [
      '33004',
      '33009',
      '33019',
      '33020',
      '33021',
      '33023',
      '33024',
      '33025',
      '33026',
      '33027',
      '33028',
      '33029',
      '33060',
      '33062',
      '33063',
      '33064',
      '33065',
      '33066',
      '33067',
      '33068',
      '33069',
      '33071',
      '33073',
      '33076',
      '33301',
      '33304',
      '33305',
      '33306',
      '33308',
      '33309',
      '33311',
      '33312',
      '33313',
      '33314',
      '33315',
      '33316',
      '33317',
      '33319',
      '33321',
      '33322',
      '33323',
      '33324',
      '33325',
      '33326',
      '33327',
      '33328',
      '33330',
      '33331',
      '33332',
      '33334',
      '33351',
      '33441',
      '33442'
    ],
    '117': [
      '32701',
      '32703',
      '32707',
      '32708',
      '32714',
      '32730',
      '32732',
      '32746',
      '32750',
      '32751',
      '32765',
      '32766',
      '32771',
      '32773',
      '32779',
      '32792'
    ],
    '041': [
      '32008',
      '32619',
      '32643',
      '32669',
      '32693'
    ],
    '033': [
      '32501',
      '32502',
      '32503',
      '32504',
      '32505',
      '32506',
      '32507',
      '32508',
      '32509',
      '32511',
      '32514',
      '32526',
      '32533',
      '32534',
      '32535',
      '32561',
      '32568',
      '32577'
    ],
    '037': [
      '32320',
      '32321',
      '32322',
      '32323',
      '32328',
      '32346',
      '32358'
    ],
    '085': [
      '33438',
      '33455',
      '33458',
      '33469',
      '33478',
      '34956',
      '34957',
      '34974',
      '34990',
      '34994',
      '34996',
      '34997'
    ],
    '071': [
      '33901',
      '33903',
      '33904',
      '33905',
      '33907',
      '33908',
      '33909',
      '33912',
      '33913',
      '33914',
      '33916',
      '33917',
      '33919',
      '33920',
      '33921',
      '33922',
      '33924',
      '33928',
      '33931',
      '33936',
      '33945',
      '33955',
      '33956',
      '33957',
      '33965',
      '33966',
      '33967',
      '33971',
      '33972',
      '33973',
      '33974',
      '33976',
      '33990',
      '33991',
      '33993',
      '34110',
      '34119',
      '34134',
      '34135'
    ],
    '017': [
      '34428',
      '34429',
      '34433',
      '34434',
      '34436',
      '34442',
      '34445',
      '34446',
      '34448',
      '34449',
      '34450',
      '34452',
      '34453',
      '34461',
      '34465'
    ],
    '089': [
      '32009',
      '32011',
      '32034',
      '32046',
      '32097',
      '32234'
    ],
    '045': [
      '32456',
      '32465'
    ],
    '057': [
      '33503',
      '33510',
      '33511',
      '33527',
      '33534',
      '33540',
      '33547',
      '33548',
      '33549',
      '33556',
      '33558',
      '33559',
      '33563',
      '33565',
      '33566',
      '33567',
      '33569',
      '33570',
      '33572',
      '33573',
      '33578',
      '33579',
      '33584',
      '33592',
      '33594',
      '33596',
      '33598',
      '33602',
      '33603',
      '33604',
      '33605',
      '33606',
      '33607',
      '33609',
      '33610',
      '33611',
      '33612',
      '33613',
      '33614',
      '33615',
      '33616',
      '33617',
      '33618',
      '33619',
      '33620',
      '33621',
      '33624',
      '33625',
      '33626',
      '33629',
      '33634',
      '33635',
      '33637',
      '33647',
      '33810'
    ],
    '107': [
      '32043',
      '32112',
      '32131',
      '32134',
      '32139',
      '32140',
      '32147',
      '32148',
      '32157',
      '32177',
      '32181',
      '32187',
      '32189',
      '32193',
      '32640',
      '32666'
    ],
    '127': [
      '32102',
      '32114',
      '32117',
      '32118',
      '32119',
      '32124',
      '32127',
      '32128',
      '32129',
      '32130',
      '32132',
      '32136',
      '32141',
      '32168',
      '32169',
      '32174',
      '32176',
      '32180',
      '32190',
      '32713',
      '32720',
      '32724',
      '32725',
      '32738',
      '32744',
      '32754',
      '32759',
      '32763',
      '32764'
    ],
    '101': [
      '33523',
      '33525',
      '33540',
      '33541',
      '33542',
      '33543',
      '33544',
      '33545',
      '33548',
      '33549',
      '33556',
      '33558',
      '33559',
      '33576',
      '33597',
      '34610',
      '34637',
      '34638',
      '34639',
      '34652',
      '34653',
      '34654',
      '34655',
      '34667',
      '34668',
      '34669',
      '34679',
      '34690',
      '34691'
    ],
    '121': [
      '32008',
      '32024',
      '32060',
      '32062',
      '32064',
      '32071',
      '32094',
      '32096'
    ],
    '125': [
      '32054',
      '32058',
      '32083',
      '32697'
    ],
    '001': [
      '32601',
      '32603',
      '32605',
      '32606',
      '32607',
      '32608',
      '32609',
      '32612',
      '32615',
      '32616',
      '32618',
      '32622',
      '32631',
      '32640',
      '32641',
      '32643',
      '32653',
      '32658',
      '32666',
      '32667',
      '32669',
      '32694'
    ],
    '129': [
      '32327',
      '32346',
      '32355',
      '32358'
    ],
    '103': [
      '33701',
      '33702',
      '33703',
      '33704',
      '33705',
      '33706',
      '33707',
      '33708',
      '33709',
      '33710',
      '33711',
      '33712',
      '33713',
      '33714',
      '33715',
      '33716',
      '33744',
      '33755',
      '33756',
      '33759',
      '33760',
      '33761',
      '33762',
      '33763',
      '33764',
      '33765',
      '33767',
      '33770',
      '33771',
      '33772',
      '33773',
      '33774',
      '33776',
      '33777',
      '33778',
      '33781',
      '33782',
      '33785',
      '33786',
      '34677',
      '34681',
      '34683',
      '34684',
      '34685',
      '34688',
      '34689',
      '34695',
      '34698'
    ],
    '115': [
      '34223',
      '34224',
      '34228',
      '34229',
      '34231',
      '34232',
      '34233',
      '34234',
      '34235',
      '34236',
      '34237',
      '34238',
      '34239',
      '34240',
      '34241',
      '34242',
      '34243',
      '34251',
      '34266',
      '34275',
      '34285',
      '34286',
      '34287',
      '34288',
      '34289',
      '34291',
      '34292',
      '34293'
    ],
    '087': [
      '33001',
      '33034',
      '33036',
      '33037',
      '33040',
      '33042',
      '33043',
      '33050',
      '33051',
      '33070',
      '34141'
    ],
    '095': [
      '32703',
      '32709',
      '32712',
      '32751',
      '32757',
      '32776',
      '32789',
      '32792',
      '32798',
      '32801',
      '32803',
      '32804',
      '32805',
      '32806',
      '32807',
      '32808',
      '32809',
      '32810',
      '32811',
      '32812',
      '32814',
      '32817',
      '32818',
      '32819',
      '32820',
      '32821',
      '32822',
      '32824',
      '32825',
      '32826',
      '32827',
      '32828',
      '32829',
      '32830',
      '32831',
      '32832',
      '32833',
      '32835',
      '32836',
      '32837',
      '32839',
      '34734',
      '34747',
      '34760',
      '34761',
      '34786',
      '34787'
    ],
    '035': [
      '32110',
      '32136',
      '32137',
      '32164',
      '32174',
      '32180'
    ],
    '005': [
      '32401',
      '32403',
      '32404',
      '32405',
      '32407',
      '32408',
      '32409',
      '32410',
      '32413',
      '32437',
      '32438',
      '32444',
      '32456',
      '32462',
      '32466'
    ],
    '051': [
      '33440',
      '33930',
      '33935',
      '33936',
      '34142'
    ],
    '077': [
      '32321',
      '32334'
    ],
    '027': [
      '34266',
      '34268',
      '34269'
    ],
    '069': [
      '32102',
      '32134',
      '32159',
      '32195',
      '32702',
      '32720',
      '32726',
      '32735',
      '32736',
      '32757',
      '32767',
      '32776',
      '32778',
      '32784',
      '34705',
      '34711',
      '34714',
      '34715',
      '34731',
      '34736',
      '34737',
      '34748',
      '34753',
      '34756',
      '34762',
      '34787',
      '34788',
      '34797'
    ],
    '093': [
      '34972',
      '34974'
    ],
    '009': [
      '32754',
      '32780',
      '32796',
      '32901',
      '32903',
      '32904',
      '32905',
      '32907',
      '32908',
      '32909',
      '32920',
      '32922',
      '32925',
      '32926',
      '32927',
      '32931',
      '32934',
      '32935',
      '32937',
      '32940',
      '32948',
      '32949',
      '32950',
      '32951',
      '32952',
      '32953',
      '32955',
      '32976'
    ],
    '105': [
      '33801',
      '33803',
      '33805',
      '33809',
      '33810',
      '33811',
      '33812',
      '33813',
      '33815',
      '33823',
      '33825',
      '33827',
      '33830',
      '33834',
      '33837',
      '33838',
      '33839',
      '33841',
      '33843',
      '33844',
      '33847',
      '33849',
      '33850',
      '33851',
      '33853',
      '33854',
      '33855',
      '33856',
      '33859',
      '33860',
      '33867',
      '33868',
      '33877',
      '33880',
      '33881',
      '33884',
      '33896',
      '33897',
      '33898',
      '34714',
      '34759'
    ],
    '113': [
      '32530',
      '32531',
      '32561',
      '32563',
      '32564',
      '32565',
      '32566',
      '32570',
      '32571',
      '32583'
    ],
    '133': [
      '32420',
      '32425',
      '32427',
      '32428',
      '32431',
      '32437',
      '32438',
      '32462',
      '32463',
      '32466'
    ],
    '086': [
      '33010',
      '33012',
      '33013',
      '33014',
      '33015',
      '33016',
      '33018',
      '33030',
      '33031',
      '33032',
      '33033',
      '33034',
      '33035',
      '33039',
      '33054',
      '33055',
      '33056',
      '33101',
      '33109',
      '33122',
      '33125',
      '33126',
      '33127',
      '33128',
      '33129',
      '33130',
      '33131',
      '33132',
      '33133',
      '33134',
      '33135',
      '33136',
      '33137',
      '33138',
      '33139',
      '33140',
      '33141',
      '33142',
      '33143',
      '33144',
      '33145',
      '33146',
      '33147',
      '33149',
      '33150',
      '33154',
      '33155',
      '33156',
      '33157',
      '33158',
      '33160',
      '33161',
      '33162',
      '33165',
      '33166',
      '33167',
      '33168',
      '33169',
      '33170',
      '33172',
      '33173',
      '33174',
      '33175',
      '33176',
      '33177',
      '33178',
      '33179',
      '33180',
      '33181',
      '33182',
      '33183',
      '33184',
      '33185',
      '33186',
      '33187',
      '33189',
      '33190',
      '33193',
      '33194',
      '33196'
    ],
    '075': [
      '32618',
      '32621',
      '32625',
      '32626',
      '32639',
      '32668',
      '32669',
      '32683',
      '32693',
      '32696',
      '34431',
      '34449',
      '34498'
    ],
    '047': [
      '32052',
      '32053',
      '32096'
    ],
    '081': [
      '33598',
      '33834',
      '34201',
      '34202',
      '34203',
      '34205',
      '34207',
      '34208',
      '34209',
      '34210',
      '34211',
      '34212',
      '34215',
      '34216',
      '34217',
      '34219',
      '34221',
      '34222',
      '34228',
      '34243',
      '34251'
    ],
    '083': [
      '32113',
      '32133',
      '32134',
      '32162',
      '32179',
      '32195',
      '32617',
      '32664',
      '32667',
      '32668',
      '32681',
      '32686',
      '32696',
      '32702',
      '32784',
      '34420',
      '34431',
      '34432',
      '34470',
      '34471',
      '34472',
      '34473',
      '34474',
      '34475',
      '34476',
      '34479',
      '34480',
      '34481',
      '34482',
      '34488',
      '34491'
    ],
    '023': [
      '32024',
      '32025',
      '32038',
      '32055',
      '32061',
      '32094',
      '32096',
      '32643'
    ],
    '043': [
      '33471',
      '33935',
      '33944',
      '33960',
      '34974'
    ],
    '099': [
      '33401',
      '33403',
      '33404',
      '33405',
      '33406',
      '33407',
      '33408',
      '33409',
      '33410',
      '33411',
      '33412',
      '33413',
      '33414',
      '33415',
      '33417',
      '33418',
      '33426',
      '33428',
      '33430',
      '33431',
      '33432',
      '33433',
      '33434',
      '33435',
      '33436',
      '33437',
      '33438',
      '33440',
      '33444',
      '33445',
      '33446',
      '33449',
      '33458',
      '33460',
      '33461',
      '33462',
      '33463',
      '33467',
      '33469',
      '33470',
      '33472',
      '33473',
      '33476',
      '33477',
      '33478',
      '33480',
      '33483',
      '33484',
      '33486',
      '33487',
      '33493',
      '33496',
      '33498'
    ],
    '065': [
      '32331',
      '32336',
      '32344',
      '32361'
    ],
    '061': [
      '32948',
      '32958',
      '32960',
      '32962',
      '32963',
      '32966',
      '32967',
      '32968',
      '32970'
    ],
    '049': [
      '33825',
      '33834',
      '33865',
      '33873',
      '33890'
    ],
    '019': [
      '32003',
      '32043',
      '32058',
      '32065',
      '32068',
      '32073',
      '32079',
      '32091',
      '32234',
      '32656',
      '32666'
    ],
    '015': [
      '33917',
      '33921',
      '33946',
      '33947',
      '33948',
      '33950',
      '33952',
      '33953',
      '33954',
      '33955',
      '33980',
      '33981',
      '33982',
      '33983',
      '34223',
      '34224'
    ],
    '007': [
      '32026',
      '32044',
      '32054',
      '32058',
      '32083',
      '32091',
      '32622',
      '32656',
      '32666'
    ],
    '013': [
      '32421',
      '32424',
      '32430',
      '32438',
      '32442',
      '32449'
    ],
    '073': [
      '32301',
      '32303',
      '32304',
      '32305',
      '32308',
      '32309',
      '32310',
      '32311',
      '32312',
      '32317',
      '32327',
      '32399'
    ],
    '003': [
      '32040',
      '32063',
      '32072',
      '32087',
      '32234'
    ]
  },
  '06': {
    '079': [
      '93252',
      '93254',
      '93401',
      '93402',
      '93405',
      '93410',
      '93420',
      '93422',
      '93424',
      '93426',
      '93428',
      '93430',
      '93432',
      '93433',
      '93442',
      '93444',
      '93445',
      '93446',
      '93449',
      '93451',
      '93452',
      '93453',
      '93454',
      '93458',
      '93461',
      '93465'
    ],
    '099': [
      '95230',
      '95307',
      '95313',
      '95316',
      '95319',
      '95323',
      '95326',
      '95328',
      '95329',
      '95350',
      '95351',
      '95354',
      '95355',
      '95356',
      '95357',
      '95358',
      '95360',
      '95361',
      '95363',
      '95367',
      '95368',
      '95380',
      '95382',
      '95385',
      '95386',
      '95387'
    ],
    '067': [
      '94571',
      '95608',
      '95610',
      '95615',
      '95621',
      '95624',
      '95626',
      '95628',
      '95630',
      '95632',
      '95638',
      '95639',
      '95641',
      '95652',
      '95655',
      '95660',
      '95662',
      '95670',
      '95673',
      '95680',
      '95683',
      '95690',
      '95693',
      '95742',
      '95757',
      '95758',
      '95811',
      '95814',
      '95815',
      '95816',
      '95817',
      '95818',
      '95819',
      '95820',
      '95821',
      '95822',
      '95823',
      '95824',
      '95825',
      '95826',
      '95827',
      '95828',
      '95829',
      '95830',
      '95831',
      '95832',
      '95833',
      '95834',
      '95835',
      '95837',
      '95838',
      '95841',
      '95842',
      '95843',
      '95864'
    ],
    '055': [
      '94503',
      '94508',
      '94515',
      '94558',
      '94559',
      '94567',
      '94573',
      '94574',
      '94576',
      '94589',
      '94599'
    ],
    '029': [
      '93203',
      '93205',
      '93206',
      '93215',
      '93220',
      '93222',
      '93224',
      '93225',
      '93226',
      '93238',
      '93240',
      '93241',
      '93243',
      '93249',
      '93250',
      '93251',
      '93252',
      '93255',
      '93263',
      '93268',
      '93276',
      '93280',
      '93283',
      '93285',
      '93287',
      '93301',
      '93304',
      '93305',
      '93306',
      '93307',
      '93308',
      '93309',
      '93311',
      '93312',
      '93313',
      '93314',
      '93461',
      '93501',
      '93505',
      '93516',
      '93518',
      '93519',
      '93523',
      '93524',
      '93527',
      '93528',
      '93531',
      '93554',
      '93555',
      '93560',
      '93561'
    ],
    '109': [
      '95223',
      '95305',
      '95310',
      '95311',
      '95314',
      '95321',
      '95327',
      '95329',
      '95335',
      '95346',
      '95364',
      '95370',
      '95372',
      '95375',
      '95379',
      '95383',
      '95389'
    ],
    '021': [
      '95920',
      '95939',
      '95943',
      '95951',
      '95963',
      '95970',
      '95988'
    ],
    '053': [
      '93210',
      '93426',
      '93450',
      '93451',
      '93461',
      '93901',
      '93905',
      '93906',
      '93907',
      '93908',
      '93920',
      '93921',
      '93923',
      '93924',
      '93925',
      '93926',
      '93927',
      '93928',
      '93930',
      '93932',
      '93933',
      '93940',
      '93943',
      '93950',
      '93953',
      '93954',
      '93955',
      '93960',
      '93962',
      '95004',
      '95012',
      '95039',
      '95076'
    ],
    '031': [
      '93201',
      '93202',
      '93204',
      '93212',
      '93230',
      '93239',
      '93242',
      '93245',
      '93266',
      '93631',
      '93656'
    ],
    '091': [
      '89439',
      '95910',
      '95922',
      '95936',
      '95944',
      '95960',
      '96105',
      '96111',
      '96118',
      '96124',
      '96125',
      '96126'
    ],
    '063': [
      '95915',
      '95923',
      '95934',
      '95947',
      '95956',
      '95971',
      '95981',
      '95983',
      '95984',
      '96020',
      '96103',
      '96105',
      '96106',
      '96118',
      '96122',
      '96129',
      '96135',
      '96137'
    ],
    '025': [
      '92004',
      '92222',
      '92225',
      '92227',
      '92231',
      '92233',
      '92243',
      '92249',
      '92250',
      '92251',
      '92257',
      '92259',
      '92266',
      '92273',
      '92274',
      '92275',
      '92281',
      '92283'
    ],
    '059': [
      '90620',
      '90621',
      '90623',
      '90630',
      '90631',
      '90638',
      '90680',
      '90720',
      '90740',
      '90742',
      '90743',
      '90815',
      '92530',
      '92602',
      '92603',
      '92604',
      '92606',
      '92610',
      '92612',
      '92614',
      '92617',
      '92618',
      '92620',
      '92624',
      '92625',
      '92626',
      '92627',
      '92629',
      '92630',
      '92637',
      '92646',
      '92647',
      '92648',
      '92649',
      '92651',
      '92653',
      '92655',
      '92656',
      '92657',
      '92660',
      '92661',
      '92662',
      '92663',
      '92672',
      '92673',
      '92675',
      '92676',
      '92677',
      '92678',
      '92679',
      '92683',
      '92688',
      '92691',
      '92692',
      '92694',
      '92701',
      '92703',
      '92704',
      '92705',
      '92706',
      '92707',
      '92708',
      '92780',
      '92782',
      '92801',
      '92802',
      '92804',
      '92805',
      '92806',
      '92807',
      '92808',
      '92821',
      '92823',
      '92831',
      '92832',
      '92833',
      '92835',
      '92840',
      '92841',
      '92843',
      '92844',
      '92845',
      '92861',
      '92865',
      '92866',
      '92867',
      '92868',
      '92869',
      '92870',
      '92886',
      '92887'
    ],
    '111': [
      '90265',
      '91304',
      '91307',
      '91311',
      '91320',
      '91360',
      '91361',
      '91362',
      '91377',
      '93001',
      '93003',
      '93004',
      '93010',
      '93012',
      '93013',
      '93015',
      '93021',
      '93022',
      '93023',
      '93030',
      '93033',
      '93035',
      '93036',
      '93040',
      '93041',
      '93042',
      '93043',
      '93060',
      '93063',
      '93064',
      '93065',
      '93066',
      '93225',
      '93252'
    ],
    '039': [
      '93601',
      '93604',
      '93610',
      '93614',
      '93622',
      '93623',
      '93626',
      '93636',
      '93637',
      '93638',
      '93643',
      '93644',
      '93645',
      '93653',
      '93669'
    ],
    '011': [
      '95645',
      '95912',
      '95932',
      '95950',
      '95955',
      '95970',
      '95979',
      '95987'
    ],
    '041': [
      '94901',
      '94903',
      '94904',
      '94920',
      '94924',
      '94925',
      '94929',
      '94930',
      '94933',
      '94937',
      '94938',
      '94939',
      '94940',
      '94941',
      '94945',
      '94946',
      '94947',
      '94949',
      '94950',
      '94952',
      '94956',
      '94957',
      '94960',
      '94963',
      '94964',
      '94965',
      '94970',
      '94971',
      '94972',
      '94973'
    ],
    '033': [
      '95422',
      '95423',
      '95426',
      '95435',
      '95443',
      '95451',
      '95453',
      '95457',
      '95458',
      '95461',
      '95464',
      '95467',
      '95469',
      '95485',
      '95493'
    ],
    '037': [
      '90001',
      '90002',
      '90003',
      '90004',
      '90005',
      '90006',
      '90007',
      '90008',
      '90010',
      '90011',
      '90012',
      '90013',
      '90014',
      '90015',
      '90016',
      '90017',
      '90018',
      '90019',
      '90020',
      '90021',
      '90022',
      '90023',
      '90024',
      '90025',
      '90026',
      '90027',
      '90028',
      '90029',
      '90031',
      '90032',
      '90033',
      '90034',
      '90035',
      '90036',
      '90037',
      '90038',
      '90039',
      '90040',
      '90041',
      '90042',
      '90043',
      '90044',
      '90045',
      '90046',
      '90047',
      '90048',
      '90049',
      '90056',
      '90057',
      '90058',
      '90059',
      '90061',
      '90062',
      '90063',
      '90064',
      '90065',
      '90066',
      '90067',
      '90068',
      '90069',
      '90071',
      '90073',
      '90077',
      '90079',
      '90089',
      '90090',
      '90094',
      '90095',
      '90201',
      '90210',
      '90211',
      '90212',
      '90220',
      '90221',
      '90222',
      '90230',
      '90232',
      '90240',
      '90241',
      '90242',
      '90245',
      '90247',
      '90248',
      '90249',
      '90250',
      '90254',
      '90255',
      '90260',
      '90262',
      '90263',
      '90265',
      '90266',
      '90270',
      '90272',
      '90274',
      '90275',
      '90277',
      '90278',
      '90280',
      '90290',
      '90291',
      '90292',
      '90293',
      '90301',
      '90302',
      '90303',
      '90304',
      '90305',
      '90401',
      '90402',
      '90403',
      '90404',
      '90405',
      '90501',
      '90502',
      '90503',
      '90504',
      '90505',
      '90506',
      '90601',
      '90602',
      '90603',
      '90604',
      '90605',
      '90606',
      '90623',
      '90630',
      '90631',
      '90638',
      '90640',
      '90650',
      '90660',
      '90670',
      '90701',
      '90703',
      '90704',
      '90706',
      '90710',
      '90712',
      '90713',
      '90715',
      '90716',
      '90717',
      '90723',
      '90731',
      '90732',
      '90740',
      '90744',
      '90745',
      '90746',
      '90747',
      '90755',
      '90802',
      '90803',
      '90804',
      '90805',
      '90806',
      '90807',
      '90808',
      '90810',
      '90813',
      '90814',
      '90815',
      '90822',
      '90831',
      '91001',
      '91006',
      '91007',
      '91008',
      '91010',
      '91011',
      '91016',
      '91020',
      '91024',
      '91030',
      '91040',
      '91042',
      '91046',
      '91101',
      '91103',
      '91104',
      '91105',
      '91106',
      '91107',
      '91108',
      '91201',
      '91202',
      '91203',
      '91204',
      '91205',
      '91206',
      '91207',
      '91208',
      '91210',
      '91214',
      '91301',
      '91302',
      '91303',
      '91304',
      '91306',
      '91307',
      '91311',
      '91316',
      '91321',
      '91324',
      '91325',
      '91326',
      '91330',
      '91331',
      '91335',
      '91340',
      '91342',
      '91343',
      '91344',
      '91345',
      '91350',
      '91351',
      '91352',
      '91354',
      '91355',
      '91356',
      '91361',
      '91362',
      '91364',
      '91367',
      '91371',
      '91381',
      '91384',
      '91387',
      '91390',
      '91401',
      '91402',
      '91403',
      '91405',
      '91406',
      '91411',
      '91423',
      '91436',
      '91501',
      '91502',
      '91504',
      '91505',
      '91506',
      '91601',
      '91602',
      '91604',
      '91605',
      '91606',
      '91607',
      '91608',
      '91702',
      '91706',
      '91711',
      '91722',
      '91723',
      '91724',
      '91731',
      '91732',
      '91733',
      '91740',
      '91741',
      '91744',
      '91745',
      '91746',
      '91748',
      '91750',
      '91754',
      '91755',
      '91759',
      '91765',
      '91766',
      '91767',
      '91768',
      '91770',
      '91773',
      '91775',
      '91776',
      '91780',
      '91789',
      '91790',
      '91791',
      '91792',
      '91801',
      '91803',
      '92397',
      '93243',
      '93510',
      '93532',
      '93534',
      '93535',
      '93536',
      '93543',
      '93544',
      '93550',
      '93551',
      '93552',
      '93553',
      '93560',
      '93563',
      '93591'
    ],
    '085': [
      '94022',
      '94024',
      '94028',
      '94040',
      '94041',
      '94043',
      '94085',
      '94086',
      '94087',
      '94089',
      '94301',
      '94303',
      '94304',
      '94305',
      '94306',
      '94550',
      '95002',
      '95008',
      '95013',
      '95014',
      '95020',
      '95023',
      '95030',
      '95032',
      '95033',
      '95035',
      '95037',
      '95046',
      '95050',
      '95051',
      '95053',
      '95054',
      '95070',
      '95076',
      '95110',
      '95111',
      '95112',
      '95113',
      '95116',
      '95117',
      '95118',
      '95119',
      '95120',
      '95121',
      '95122',
      '95123',
      '95124',
      '95125',
      '95126',
      '95127',
      '95128',
      '95129',
      '95130',
      '95131',
      '95132',
      '95133',
      '95134',
      '95135',
      '95136',
      '95138',
      '95139',
      '95140',
      '95148'
    ],
    '103': [
      '95963',
      '95973',
      '96021',
      '96022',
      '96029',
      '96035',
      '96055',
      '96059',
      '96061',
      '96063',
      '96074',
      '96075',
      '96076',
      '96080',
      '96090',
      '96092'
    ],
    '089': [
      '96001',
      '96002',
      '96003',
      '96007',
      '96008',
      '96011',
      '96013',
      '96016',
      '96017',
      '96019',
      '96022',
      '96025',
      '96028',
      '96033',
      '96040',
      '96047',
      '96051',
      '96056',
      '96059',
      '96062',
      '96065',
      '96069',
      '96071',
      '96073',
      '96075',
      '96076',
      '96084',
      '96087',
      '96088',
      '96096'
    ],
    '045': [
      '95410',
      '95415',
      '95417',
      '95420',
      '95425',
      '95427',
      '95428',
      '95429',
      '95432',
      '95437',
      '95445',
      '95449',
      '95454',
      '95456',
      '95459',
      '95460',
      '95463',
      '95466',
      '95468',
      '95469',
      '95470',
      '95482',
      '95488',
      '95490',
      '95494',
      '95585',
      '95587',
      '95589'
    ],
    '107': [
      '93201',
      '93207',
      '93208',
      '93212',
      '93215',
      '93218',
      '93219',
      '93221',
      '93223',
      '93235',
      '93238',
      '93244',
      '93247',
      '93256',
      '93257',
      '93258',
      '93260',
      '93261',
      '93262',
      '93265',
      '93267',
      '93270',
      '93271',
      '93272',
      '93274',
      '93277',
      '93286',
      '93291',
      '93292',
      '93527',
      '93603',
      '93615',
      '93618',
      '93619',
      '93631',
      '93633',
      '93646',
      '93647',
      '93654',
      '93666',
      '93673'
    ],
    '081': [
      '94002',
      '94005',
      '94010',
      '94014',
      '94015',
      '94019',
      '94020',
      '94021',
      '94025',
      '94027',
      '94028',
      '94030',
      '94037',
      '94038',
      '94044',
      '94060',
      '94061',
      '94062',
      '94063',
      '94065',
      '94066',
      '94070',
      '94074',
      '94080',
      '94128',
      '94303',
      '94401',
      '94402',
      '94403',
      '94404'
    ],
    '097': [
      '94515',
      '94922',
      '94923',
      '94928',
      '94931',
      '94951',
      '94952',
      '94954',
      '95401',
      '95403',
      '95404',
      '95405',
      '95407',
      '95409',
      '95412',
      '95419',
      '95421',
      '95425',
      '95430',
      '95431',
      '95436',
      '95439',
      '95441',
      '95442',
      '95444',
      '95446',
      '95448',
      '95450',
      '95452',
      '95462',
      '95465',
      '95471',
      '95472',
      '95476',
      '95486',
      '95492',
      '95497'
    ],
    '057': [
      '95602',
      '95715',
      '95728',
      '95945',
      '95946',
      '95949',
      '95959',
      '95960',
      '95975',
      '95977',
      '95986',
      '96111',
      '96161'
    ],
    '001': [
      '94501',
      '94502',
      '94505',
      '94514',
      '94536',
      '94538',
      '94539',
      '94541',
      '94542',
      '94544',
      '94545',
      '94546',
      '94550',
      '94551',
      '94552',
      '94555',
      '94560',
      '94566',
      '94568',
      '94577',
      '94578',
      '94579',
      '94580',
      '94586',
      '94587',
      '94588',
      '94601',
      '94602',
      '94603',
      '94605',
      '94606',
      '94607',
      '94608',
      '94609',
      '94610',
      '94611',
      '94612',
      '94613',
      '94618',
      '94619',
      '94621',
      '94702',
      '94703',
      '94704',
      '94705',
      '94706',
      '94707',
      '94708',
      '94709',
      '94710',
      '94720',
      '95377',
      '95391'
    ],
    '017': [
      '95614',
      '95619',
      '95623',
      '95629',
      '95633',
      '95634',
      '95635',
      '95636',
      '95651',
      '95664',
      '95667',
      '95672',
      '95682',
      '95684',
      '95709',
      '95720',
      '95721',
      '95726',
      '95735',
      '95762',
      '96142',
      '96150',
      '96155'
    ],
    '115': [
      '95692',
      '95901',
      '95903',
      '95914',
      '95918',
      '95919',
      '95922',
      '95925',
      '95930',
      '95935',
      '95941',
      '95960',
      '95961',
      '95962',
      '95966',
      '95977',
      '95981'
    ],
    '087': [
      '95003',
      '95005',
      '95006',
      '95007',
      '95010',
      '95017',
      '95018',
      '95019',
      '95033',
      '95041',
      '95060',
      '95062',
      '95064',
      '95065',
      '95066',
      '95073',
      '95076'
    ],
    '095': [
      '94510',
      '94512',
      '94533',
      '94534',
      '94535',
      '94571',
      '94585',
      '94589',
      '94590',
      '94591',
      '94592',
      '95612',
      '95618',
      '95620',
      '95625',
      '95687',
      '95688',
      '95690',
      '95694'
    ],
    '035': [
      '96006',
      '96009',
      '96056',
      '96068',
      '96105',
      '96109',
      '96110',
      '96113',
      '96114',
      '96117',
      '96119',
      '96121',
      '96123',
      '96128',
      '96130',
      '96132',
      '96136',
      '96137'
    ],
    '005': [
      '95601',
      '95629',
      '95640',
      '95642',
      '95646',
      '95665',
      '95666',
      '95669',
      '95675',
      '95685',
      '95689',
      '95699'
    ],
    '051': [
      '89010',
      '93512',
      '93514',
      '93517',
      '93529',
      '93541',
      '93546',
      '96107',
      '96133'
    ],
    '077': [
      '95202',
      '95203',
      '95204',
      '95205',
      '95206',
      '95207',
      '95209',
      '95210',
      '95211',
      '95212',
      '95215',
      '95219',
      '95220',
      '95227',
      '95230',
      '95231',
      '95234',
      '95236',
      '95237',
      '95240',
      '95242',
      '95258',
      '95304',
      '95320',
      '95330',
      '95336',
      '95337',
      '95361',
      '95366',
      '95376',
      '95377',
      '95385',
      '95391',
      '95632',
      '95641',
      '95686'
    ],
    '027': [
      '89019',
      '89060',
      '89061',
      '92328',
      '92384',
      '92389',
      '93513',
      '93514',
      '93522',
      '93526',
      '93527',
      '93530',
      '93545',
      '93549',
      '93592'
    ],
    '069': [
      '93210',
      '93930',
      '95004',
      '95023',
      '95043',
      '95045',
      '95075'
    ],
    '093': [
      '95568',
      '96014',
      '96023',
      '96025',
      '96027',
      '96031',
      '96032',
      '96034',
      '96037',
      '96038',
      '96039',
      '96044',
      '96049',
      '96050',
      '96057',
      '96058',
      '96064',
      '96067',
      '96085',
      '96086',
      '96094',
      '96097',
      '96134'
    ],
    '009': [
      '95222',
      '95223',
      '95224',
      '95225',
      '95226',
      '95228',
      '95230',
      '95232',
      '95233',
      '95245',
      '95246',
      '95247',
      '95248',
      '95249',
      '95250',
      '95251',
      '95252',
      '95254',
      '95255',
      '95257'
    ],
    '105': [
      '95527',
      '95552',
      '95563',
      '95595',
      '96010',
      '96024',
      '96041',
      '96046',
      '96048',
      '96052',
      '96076',
      '96091',
      '96093'
    ],
    '113': [
      '95605',
      '95606',
      '95607',
      '95612',
      '95615',
      '95616',
      '95618',
      '95620',
      '95627',
      '95637',
      '95645',
      '95653',
      '95679',
      '95691',
      '95694',
      '95695',
      '95697',
      '95698',
      '95776',
      '95912',
      '95937'
    ],
    '071': [
      '89019',
      '91701',
      '91708',
      '91709',
      '91710',
      '91730',
      '91737',
      '91739',
      '91759',
      '91761',
      '91762',
      '91763',
      '91764',
      '91766',
      '91784',
      '91786',
      '92242',
      '92252',
      '92256',
      '92267',
      '92268',
      '92277',
      '92278',
      '92280',
      '92284',
      '92285',
      '92301',
      '92304',
      '92305',
      '92307',
      '92308',
      '92309',
      '92310',
      '92311',
      '92313',
      '92314',
      '92315',
      '92316',
      '92321',
      '92322',
      '92324',
      '92325',
      '92327',
      '92332',
      '92333',
      '92335',
      '92336',
      '92337',
      '92338',
      '92339',
      '92341',
      '92342',
      '92344',
      '92345',
      '92346',
      '92347',
      '92352',
      '92354',
      '92356',
      '92358',
      '92359',
      '92363',
      '92364',
      '92365',
      '92368',
      '92371',
      '92372',
      '92373',
      '92374',
      '92376',
      '92377',
      '92378',
      '92382',
      '92385',
      '92386',
      '92391',
      '92392',
      '92394',
      '92395',
      '92397',
      '92398',
      '92399',
      '92401',
      '92404',
      '92405',
      '92407',
      '92408',
      '92410',
      '92411',
      '92509',
      '92880',
      '93516',
      '93555',
      '93558',
      '93562'
    ],
    '075': [
      '94102',
      '94103',
      '94104',
      '94105',
      '94107',
      '94108',
      '94109',
      '94110',
      '94111',
      '94112',
      '94114',
      '94115',
      '94116',
      '94117',
      '94118',
      '94121',
      '94122',
      '94123',
      '94124',
      '94127',
      '94129',
      '94130',
      '94131',
      '94132',
      '94133',
      '94134',
      '94158'
    ],
    '047': [
      '93610',
      '93620',
      '93622',
      '93635',
      '93665',
      '95301',
      '95303',
      '95312',
      '95315',
      '95317',
      '95322',
      '95324',
      '95333',
      '95334',
      '95340',
      '95341',
      '95348',
      '95360',
      '95365',
      '95369',
      '95374',
      '95380',
      '95388'
    ],
    '007': [
      '95901',
      '95914',
      '95916',
      '95917',
      '95926',
      '95928',
      '95930',
      '95938',
      '95941',
      '95942',
      '95948',
      '95954',
      '95965',
      '95966',
      '95968',
      '95969',
      '95973',
      '95974',
      '95978'
    ],
    '023': [
      '95501',
      '95503',
      '95511',
      '95514',
      '95519',
      '95521',
      '95524',
      '95525',
      '95526',
      '95528',
      '95536',
      '95537',
      '95540',
      '95542',
      '95545',
      '95546',
      '95547',
      '95549',
      '95550',
      '95551',
      '95553',
      '95554',
      '95555',
      '95556',
      '95558',
      '95559',
      '95560',
      '95562',
      '95564',
      '95565',
      '95569',
      '95570',
      '95571',
      '95573',
      '95589'
    ],
    '043': [
      '93601',
      '93623',
      '93653',
      '95306',
      '95311',
      '95318',
      '95321',
      '95325',
      '95329',
      '95338',
      '95345',
      '95369',
      '95389'
    ],
    '101': [
      '95626',
      '95645',
      '95659',
      '95668',
      '95674',
      '95692',
      '95837',
      '95948',
      '95953',
      '95957',
      '95982',
      '95991',
      '95993'
    ],
    '065': [
      '91752',
      '92028',
      '92201',
      '92203',
      '92210',
      '92211',
      '92220',
      '92223',
      '92225',
      '92230',
      '92234',
      '92236',
      '92239',
      '92240',
      '92241',
      '92253',
      '92254',
      '92258',
      '92260',
      '92262',
      '92264',
      '92270',
      '92274',
      '92276',
      '92277',
      '92282',
      '92320',
      '92324',
      '92373',
      '92399',
      '92501',
      '92503',
      '92504',
      '92505',
      '92506',
      '92507',
      '92508',
      '92509',
      '92518',
      '92530',
      '92532',
      '92536',
      '92539',
      '92543',
      '92544',
      '92545',
      '92548',
      '92549',
      '92551',
      '92553',
      '92555',
      '92557',
      '92561',
      '92562',
      '92563',
      '92567',
      '92570',
      '92571',
      '92582',
      '92583',
      '92584',
      '92585',
      '92586',
      '92587',
      '92590',
      '92591',
      '92592',
      '92595',
      '92596',
      '92860',
      '92879',
      '92880',
      '92881',
      '92882',
      '92883'
    ],
    '061': [
      '95602',
      '95603',
      '95604',
      '95626',
      '95631',
      '95648',
      '95650',
      '95658',
      '95661',
      '95663',
      '95668',
      '95677',
      '95678',
      '95681',
      '95701',
      '95703',
      '95713',
      '95714',
      '95715',
      '95717',
      '95722',
      '95724',
      '95728',
      '95736',
      '95746',
      '95747',
      '95765',
      '96140',
      '96141',
      '96142',
      '96143',
      '96145',
      '96146',
      '96148',
      '96161'
    ],
    '049': [
      '96006',
      '96015',
      '96054',
      '96056',
      '96101',
      '96104',
      '96108',
      '96110',
      '96112',
      '96115',
      '96116',
      '96134',
      '97635'
    ],
    '019': [
      '93210',
      '93234',
      '93242',
      '93602',
      '93605',
      '93606',
      '93608',
      '93609',
      '93611',
      '93612',
      '93616',
      '93618',
      '93619',
      '93620',
      '93621',
      '93622',
      '93624',
      '93625',
      '93626',
      '93627',
      '93628',
      '93630',
      '93631',
      '93633',
      '93634',
      '93640',
      '93641',
      '93646',
      '93648',
      '93650',
      '93651',
      '93652',
      '93654',
      '93656',
      '93657',
      '93660',
      '93662',
      '93664',
      '93667',
      '93668',
      '93675',
      '93701',
      '93702',
      '93703',
      '93704',
      '93705',
      '93706',
      '93710',
      '93711',
      '93720',
      '93721',
      '93722',
      '93723',
      '93725',
      '93726',
      '93727',
      '93728',
      '93730'
    ],
    '003': [
      '95223',
      '95646',
      '96120'
    ],
    '083': [
      '93001',
      '93013',
      '93067',
      '93101',
      '93103',
      '93105',
      '93108',
      '93109',
      '93110',
      '93111',
      '93117',
      '93252',
      '93254',
      '93427',
      '93429',
      '93434',
      '93436',
      '93437',
      '93440',
      '93441',
      '93454',
      '93455',
      '93458',
      '93460',
      '93463'
    ],
    '013': [
      '94505',
      '94506',
      '94507',
      '94509',
      '94511',
      '94513',
      '94514',
      '94516',
      '94517',
      '94518',
      '94519',
      '94520',
      '94521',
      '94523',
      '94525',
      '94526',
      '94528',
      '94530',
      '94531',
      '94547',
      '94548',
      '94549',
      '94551',
      '94553',
      '94556',
      '94561',
      '94563',
      '94564',
      '94565',
      '94569',
      '94572',
      '94575',
      '94582',
      '94583',
      '94588',
      '94595',
      '94596',
      '94597',
      '94598',
      '94611',
      '94706',
      '94707',
      '94708',
      '94801',
      '94803',
      '94804',
      '94805',
      '94806',
      '94850',
      '95219'
    ],
    '073': [
      '91901',
      '91902',
      '91905',
      '91906',
      '91910',
      '91911',
      '91913',
      '91914',
      '91915',
      '91916',
      '91917',
      '91931',
      '91932',
      '91934',
      '91935',
      '91941',
      '91942',
      '91945',
      '91948',
      '91950',
      '91962',
      '91963',
      '91977',
      '91978',
      '91980',
      '92003',
      '92004',
      '92007',
      '92008',
      '92009',
      '92010',
      '92011',
      '92014',
      '92019',
      '92020',
      '92021',
      '92024',
      '92025',
      '92026',
      '92027',
      '92028',
      '92029',
      '92036',
      '92037',
      '92040',
      '92054',
      '92055',
      '92056',
      '92057',
      '92058',
      '92059',
      '92060',
      '92061',
      '92064',
      '92065',
      '92066',
      '92067',
      '92069',
      '92070',
      '92071',
      '92075',
      '92078',
      '92081',
      '92082',
      '92083',
      '92084',
      '92086',
      '92091',
      '92101',
      '92102',
      '92103',
      '92104',
      '92105',
      '92106',
      '92107',
      '92108',
      '92109',
      '92110',
      '92111',
      '92113',
      '92114',
      '92115',
      '92116',
      '92117',
      '92118',
      '92119',
      '92120',
      '92121',
      '92122',
      '92123',
      '92124',
      '92126',
      '92127',
      '92128',
      '92129',
      '92130',
      '92131',
      '92132',
      '92134',
      '92135',
      '92139',
      '92140',
      '92145',
      '92147',
      '92154',
      '92155',
      '92173',
      '92672'
    ],
    '015': [
      '95531',
      '95543',
      '95548',
      '95567'
    ]
  },
  '25': {
    '027': [
      '01005',
      '01031',
      '01037',
      '01068',
      '01074',
      '01082',
      '01083',
      '01092',
      '01094',
      '01331',
      '01366',
      '01368',
      '01420',
      '01430',
      '01434',
      '01436',
      '01438',
      '01440',
      '01451',
      '01452',
      '01453',
      '01462',
      '01467',
      '01468',
      '01473',
      '01475',
      '01501',
      '01503',
      '01504',
      '01505',
      '01506',
      '01507',
      '01510',
      '01515',
      '01516',
      '01518',
      '01519',
      '01520',
      '01522',
      '01523',
      '01524',
      '01525',
      '01527',
      '01529',
      '01531',
      '01532',
      '01534',
      '01535',
      '01536',
      '01537',
      '01540',
      '01541',
      '01542',
      '01543',
      '01545',
      '01550',
      '01560',
      '01561',
      '01562',
      '01564',
      '01566',
      '01568',
      '01569',
      '01570',
      '01571',
      '01581',
      '01583',
      '01585',
      '01588',
      '01590',
      '01602',
      '01603',
      '01604',
      '01605',
      '01606',
      '01607',
      '01608',
      '01609',
      '01610',
      '01611',
      '01612',
      '01740',
      '01745',
      '01747',
      '01756',
      '01757',
      '01772'
    ],
    '001': [
      '02532',
      '02534',
      '02536',
      '02537',
      '02540',
      '02542',
      '02543',
      '02553',
      '02556',
      '02559',
      '02561',
      '02562',
      '02563',
      '02601',
      '02630',
      '02631',
      '02632',
      '02633',
      '02635',
      '02637',
      '02638',
      '02639',
      '02641',
      '02642',
      '02643',
      '02644',
      '02645',
      '02646',
      '02647',
      '02648',
      '02649',
      '02650',
      '02651',
      '02652',
      '02653',
      '02655',
      '02657',
      '02659',
      '02660',
      '02663',
      '02664',
      '02666',
      '02667',
      '02668',
      '02669',
      '02670',
      '02671',
      '02672',
      '02673',
      '02675'
    ],
    '017': [
      '01431',
      '01432',
      '01434',
      '01450',
      '01460',
      '01463',
      '01464',
      '01469',
      '01474',
      '01701',
      '01702',
      '01718',
      '01719',
      '01720',
      '01721',
      '01730',
      '01731',
      '01741',
      '01742',
      '01746',
      '01748',
      '01749',
      '01752',
      '01754',
      '01760',
      '01770',
      '01773',
      '01775',
      '01776',
      '01778',
      '01801',
      '01803',
      '01821',
      '01824',
      '01826',
      '01827',
      '01850',
      '01851',
      '01852',
      '01854',
      '01862',
      '01863',
      '01864',
      '01867',
      '01876',
      '01879',
      '01880',
      '01886',
      '01887',
      '01890',
      '01940',
      '02138',
      '02139',
      '02140',
      '02141',
      '02142',
      '02143',
      '02144',
      '02145',
      '02148',
      '02149',
      '02155',
      '02176',
      '02180',
      '02420',
      '02421',
      '02451',
      '02452',
      '02453',
      '02458',
      '02459',
      '02460',
      '02461',
      '02462',
      '02464',
      '02465',
      '02466',
      '02467',
      '02468',
      '02472',
      '02474',
      '02476',
      '02478',
      '02493'
    ],
    '023': [
      '02043',
      '02045',
      '02047',
      '02050',
      '02061',
      '02066',
      '02301',
      '02302',
      '02324',
      '02330',
      '02332',
      '02333',
      '02338',
      '02339',
      '02341',
      '02346',
      '02347',
      '02351',
      '02359',
      '02360',
      '02364',
      '02366',
      '02367',
      '02370',
      '02379',
      '02382',
      '02532',
      '02538',
      '02558',
      '02571',
      '02576',
      '02738',
      '02739',
      '02770'
    ],
    '005': [
      '02048',
      '02356',
      '02357',
      '02375',
      '02702',
      '02703',
      '02715',
      '02717',
      '02718',
      '02719',
      '02720',
      '02721',
      '02723',
      '02724',
      '02725',
      '02726',
      '02740',
      '02743',
      '02744',
      '02745',
      '02746',
      '02747',
      '02748',
      '02760',
      '02763',
      '02764',
      '02766',
      '02767',
      '02769',
      '02771',
      '02777',
      '02779',
      '02780',
      '02790',
      '02791',
      '02861'
    ],
    '021': [
      '02019',
      '02021',
      '02025',
      '02026',
      '02030',
      '02032',
      '02035',
      '02038',
      '02052',
      '02053',
      '02054',
      '02056',
      '02062',
      '02067',
      '02071',
      '02072',
      '02081',
      '02090',
      '02093',
      '02169',
      '02170',
      '02171',
      '02184',
      '02186',
      '02188',
      '02189',
      '02190',
      '02191',
      '02215',
      '02322',
      '02343',
      '02368',
      '02445',
      '02446',
      '02457',
      '02467',
      '02481',
      '02482',
      '02492',
      '02494',
      '02762'
    ],
    '003': [
      '01029',
      '01201',
      '01220',
      '01222',
      '01223',
      '01224',
      '01225',
      '01226',
      '01229',
      '01230',
      '01235',
      '01236',
      '01237',
      '01238',
      '01240',
      '01242',
      '01244',
      '01245',
      '01247',
      '01253',
      '01254',
      '01255',
      '01256',
      '01257',
      '01258',
      '01259',
      '01260',
      '01262',
      '01264',
      '01266',
      '01267',
      '01270',
      '01343'
    ],
    '009': [
      '01810',
      '01830',
      '01832',
      '01833',
      '01834',
      '01835',
      '01840',
      '01841',
      '01843',
      '01844',
      '01845',
      '01860',
      '01901',
      '01902',
      '01904',
      '01905',
      '01906',
      '01907',
      '01908',
      '01913',
      '01915',
      '01921',
      '01922',
      '01923',
      '01929',
      '01930',
      '01937',
      '01938',
      '01940',
      '01944',
      '01945',
      '01949',
      '01950',
      '01951',
      '01952',
      '01960',
      '01966',
      '01969',
      '01970',
      '01982',
      '01983',
      '01984',
      '01985'
    ],
    '025': [
      '02108',
      '02109',
      '02110',
      '02111',
      '02113',
      '02114',
      '02115',
      '02116',
      '02118',
      '02119',
      '02120',
      '02121',
      '02122',
      '02124',
      '02125',
      '02126',
      '02127',
      '02128',
      '02129',
      '02130',
      '02131',
      '02132',
      '02134',
      '02135',
      '02136',
      '02149',
      '02150',
      '02151',
      '02152',
      '02163',
      '02199',
      '02203',
      '02210',
      '02215',
      '02467'
    ],
    '019': [
      '02554',
      '02564',
      '02584'
    ],
    '011': [
      '01002',
      '01039',
      '01054',
      '01072',
      '01093',
      '01301',
      '01330',
      '01337',
      '01338',
      '01339',
      '01340',
      '01341',
      '01342',
      '01344',
      '01346',
      '01347',
      '01349',
      '01350',
      '01351',
      '01354',
      '01355',
      '01360',
      '01364',
      '01367',
      '01370',
      '01373',
      '01375',
      '01376',
      '01378',
      '01379'
    ],
    '007': [
      '02535',
      '02539',
      '02543',
      '02557',
      '02568',
      '02575',
      '02713'
    ],
    '013': [
      '01001',
      '01008',
      '01009',
      '01010',
      '01011',
      '01013',
      '01020',
      '01022',
      '01028',
      '01030',
      '01034',
      '01036',
      '01040',
      '01050',
      '01056',
      '01057',
      '01069',
      '01071',
      '01077',
      '01079',
      '01080',
      '01081',
      '01085',
      '01086',
      '01089',
      '01095',
      '01097',
      '01103',
      '01104',
      '01105',
      '01106',
      '01107',
      '01108',
      '01109',
      '01118',
      '01119',
      '01128',
      '01129',
      '01151',
      '01199',
      '01521'
    ],
    '015': [
      '01002',
      '01003',
      '01007',
      '01011',
      '01012',
      '01026',
      '01027',
      '01032',
      '01033',
      '01035',
      '01038',
      '01039',
      '01050',
      '01053',
      '01060',
      '01062',
      '01063',
      '01066',
      '01070',
      '01073',
      '01075',
      '01082',
      '01084',
      '01088',
      '01096',
      '01098',
      '01243'
    ]
  },
  '24': {
    '047': [
      '21804',
      '21811',
      '21813',
      '21822',
      '21829',
      '21841',
      '21842',
      '21851',
      '21862',
      '21863',
      '21864',
      '21872'
    ],
    '001': [
      '21502',
      '21521',
      '21524',
      '21529',
      '21530',
      '21532',
      '21539',
      '21540',
      '21542',
      '21543',
      '21545',
      '21555',
      '21557',
      '21562',
      '21766'
    ],
    '015': [
      '21901',
      '21902',
      '21903',
      '21904',
      '21911',
      '21912',
      '21913',
      '21914',
      '21915',
      '21916',
      '21917',
      '21918',
      '21919',
      '21920',
      '21921',
      '21930'
    ],
    '017': [
      '20601',
      '20602',
      '20603',
      '20607',
      '20611',
      '20612',
      '20613',
      '20616',
      '20617',
      '20622',
      '20625',
      '20632',
      '20637',
      '20640',
      '20645',
      '20646',
      '20658',
      '20659',
      '20662',
      '20664',
      '20675',
      '20677',
      '20693',
      '20695'
    ],
    '029': [
      '21610',
      '21620',
      '21635',
      '21645',
      '21650',
      '21651',
      '21661',
      '21667',
      '21678'
    ],
    '035': [
      '21607',
      '21617',
      '21619',
      '21620',
      '21623',
      '21628',
      '21638',
      '21640',
      '21644',
      '21649',
      '21651',
      '21657',
      '21658',
      '21666',
      '21668'
    ],
    '021': [
      '20842',
      '20871',
      '21701',
      '21702',
      '21703',
      '21704',
      '21705',
      '21710',
      '21714',
      '21716',
      '21717',
      '21718',
      '21719',
      '21727',
      '21754',
      '21755',
      '21757',
      '21758',
      '21762',
      '21769',
      '21770',
      '21771',
      '21773',
      '21774',
      '21776',
      '21777',
      '21778',
      '21780',
      '21783',
      '21787',
      '21788',
      '21790',
      '21791',
      '21793',
      '21798'
    ],
    '031': [
      '20705',
      '20707',
      '20777',
      '20812',
      '20814',
      '20815',
      '20816',
      '20817',
      '20818',
      '20832',
      '20833',
      '20837',
      '20838',
      '20839',
      '20841',
      '20842',
      '20850',
      '20851',
      '20852',
      '20853',
      '20854',
      '20855',
      '20860',
      '20861',
      '20862',
      '20866',
      '20868',
      '20871',
      '20872',
      '20874',
      '20876',
      '20877',
      '20878',
      '20879',
      '20880',
      '20882',
      '20886',
      '20895',
      '20896',
      '20899',
      '20901',
      '20902',
      '20903',
      '20904',
      '20905',
      '20906',
      '20910',
      '20912',
      '21771'
    ],
    '033': [
      '20601',
      '20607',
      '20608',
      '20613',
      '20623',
      '20705',
      '20706',
      '20707',
      '20708',
      '20710',
      '20712',
      '20715',
      '20716',
      '20720',
      '20721',
      '20722',
      '20735',
      '20737',
      '20740',
      '20742',
      '20743',
      '20744',
      '20745',
      '20746',
      '20747',
      '20748',
      '20762',
      '20769',
      '20770',
      '20772',
      '20774',
      '20781',
      '20782',
      '20783',
      '20784',
      '20785',
      '20903',
      '20904',
      '20912'
    ],
    '009': [
      '20615',
      '20629',
      '20639',
      '20657',
      '20676',
      '20678',
      '20685',
      '20688',
      '20689',
      '20714',
      '20732',
      '20736',
      '20754'
    ],
    '025': [
      '21001',
      '21005',
      '21009',
      '21010',
      '21013',
      '21014',
      '21015',
      '21017',
      '21028',
      '21034',
      '21040',
      '21047',
      '21050',
      '21078',
      '21082',
      '21084',
      '21085',
      '21087',
      '21111',
      '21130',
      '21132',
      '21154',
      '21160',
      '21161'
    ],
    '005': [
      '21013',
      '21030',
      '21031',
      '21043',
      '21051',
      '21052',
      '21053',
      '21057',
      '21071',
      '21074',
      '21082',
      '21085',
      '21087',
      '21093',
      '21102',
      '21104',
      '21105',
      '21111',
      '21117',
      '21120',
      '21128',
      '21131',
      '21133',
      '21136',
      '21152',
      '21153',
      '21155',
      '21156',
      '21161',
      '21162',
      '21163',
      '21204',
      '21206',
      '21207',
      '21208',
      '21209',
      '21210',
      '21212',
      '21215',
      '21219',
      '21220',
      '21221',
      '21222',
      '21224',
      '21227',
      '21228',
      '21229',
      '21234',
      '21236',
      '21237',
      '21239',
      '21244',
      '21250',
      '21252',
      '21286'
    ],
    '039': [
      '21817',
      '21821',
      '21822',
      '21824',
      '21838',
      '21851',
      '21853',
      '21866',
      '21867',
      '21871',
      '21890'
    ],
    '011': [
      '21629',
      '21632',
      '21636',
      '21639',
      '21640',
      '21641',
      '21649',
      '21655',
      '21660'
    ],
    '510': [
      '21201',
      '21202',
      '21205',
      '21206',
      '21207',
      '21208',
      '21209',
      '21210',
      '21211',
      '21212',
      '21213',
      '21214',
      '21215',
      '21216',
      '21217',
      '21218',
      '21222',
      '21223',
      '21224',
      '21225',
      '21226',
      '21227',
      '21228',
      '21229',
      '21230',
      '21231',
      '21234',
      '21236',
      '21237',
      '21239',
      '21251'
    ],
    '027': [
      '20701',
      '20723',
      '20759',
      '20763',
      '20777',
      '20794',
      '20833',
      '21029',
      '21036',
      '21042',
      '21043',
      '21044',
      '21045',
      '21046',
      '21075',
      '21076',
      '21104',
      '21163',
      '21723',
      '21737',
      '21738',
      '21771',
      '21784',
      '21794',
      '21797'
    ],
    '037': [
      '20606',
      '20609',
      '20618',
      '20619',
      '20620',
      '20621',
      '20622',
      '20624',
      '20626',
      '20628',
      '20630',
      '20634',
      '20636',
      '20650',
      '20653',
      '20659',
      '20660',
      '20667',
      '20670',
      '20674',
      '20680',
      '20684',
      '20686',
      '20687',
      '20690',
      '20692'
    ],
    '023': [
      '21520',
      '21521',
      '21522',
      '21523',
      '21531',
      '21532',
      '21536',
      '21538',
      '21539',
      '21541',
      '21550',
      '21561',
      '21562'
    ],
    '043': [
      '21711',
      '21713',
      '21719',
      '21722',
      '21733',
      '21734',
      '21740',
      '21742',
      '21746',
      '21750',
      '21756',
      '21758',
      '21767',
      '21769',
      '21779',
      '21780',
      '21781',
      '21782',
      '21783',
      '21795'
    ],
    '045': [
      '21801',
      '21802',
      '21804',
      '21810',
      '21814',
      '21822',
      '21826',
      '21830',
      '21837',
      '21840',
      '21849',
      '21850',
      '21856',
      '21861',
      '21865',
      '21874',
      '21875'
    ],
    '019': [
      '21613',
      '21622',
      '21626',
      '21627',
      '21631',
      '21632',
      '21634',
      '21643',
      '21648',
      '21659',
      '21664',
      '21669',
      '21672',
      '21675',
      '21677',
      '21835',
      '21869'
    ],
    '003': [
      '20701',
      '20711',
      '20714',
      '20724',
      '20733',
      '20736',
      '20751',
      '20754',
      '20755',
      '20758',
      '20764',
      '20765',
      '20776',
      '20778',
      '20779',
      '20794',
      '21012',
      '21032',
      '21035',
      '21037',
      '21054',
      '21056',
      '21060',
      '21061',
      '21076',
      '21077',
      '21090',
      '21108',
      '21113',
      '21114',
      '21122',
      '21140',
      '21144',
      '21146',
      '21225',
      '21226',
      '21240',
      '21401',
      '21402',
      '21403',
      '21405',
      '21409'
    ],
    '013': [
      '21048',
      '21074',
      '21102',
      '21104',
      '21136',
      '21155',
      '21157',
      '21158',
      '21757',
      '21771',
      '21776',
      '21784',
      '21787',
      '21791',
      '21797'
    ],
    '041': [
      '21601',
      '21612',
      '21624',
      '21625',
      '21647',
      '21652',
      '21653',
      '21654',
      '21657',
      '21662',
      '21663',
      '21665',
      '21671',
      '21673',
      '21676',
      '21679'
    ]
  },
  '21': {
    '167': [
      '40310',
      '40330',
      '40372',
      '40468'
    ],
    '079': [
      '40403',
      '40419',
      '40444',
      '40461',
      '40484'
    ],
    '131': [
      '40808',
      '40810',
      '40816',
      '40827',
      '40840',
      '40844',
      '40858',
      '40868',
      '40874',
      '40979',
      '41714',
      '41723',
      '41749',
      '41762',
      '41764',
      '41766',
      '41775',
      '41776',
      '41777'
    ],
    '193': [
      '40972',
      '41367',
      '41701',
      '41712',
      '41713',
      '41719',
      '41721',
      '41722',
      '41723',
      '41727',
      '41729',
      '41731',
      '41735',
      '41739',
      '41745',
      '41746',
      '41751',
      '41754',
      '41759',
      '41760',
      '41763',
      '41773',
      '41774'
    ],
    '119': [
      '41339',
      '41630',
      '41640',
      '41643',
      '41701',
      '41722',
      '41725',
      '41739',
      '41740',
      '41759',
      '41772',
      '41773',
      '41817',
      '41822',
      '41828',
      '41831',
      '41834',
      '41836',
      '41839',
      '41843',
      '41844',
      '41847',
      '41859',
      '41861',
      '41862'
    ],
    '153': [
      '41464',
      '41465',
      '41632',
      '41640'
    ],
    '055': [
      '42037',
      '42064',
      '42078',
      '42084',
      '42411',
      '42450',
      '42459'
    ],
    '109': [
      '40402',
      '40434',
      '40445',
      '40447',
      '40481',
      '40486'
    ],
    '145': [
      '42001',
      '42003',
      '42027',
      '42053',
      '42069',
      '42082',
      '42086'
    ],
    '053': [
      '42602',
      '42603',
      '42633',
      '42717'
    ],
    '063': [
      '40351',
      '41149',
      '41164',
      '41171',
      '41180',
      '41472'
    ],
    '171': [
      '42133',
      '42140',
      '42151',
      '42157',
      '42166',
      '42167'
    ],
    '177': [
      '42256',
      '42321',
      '42323',
      '42324',
      '42325',
      '42326',
      '42330',
      '42332',
      '42337',
      '42339',
      '42344',
      '42345',
      '42367',
      '42372',
      '42374',
      '42464'
    ],
    '229': [
      '40033',
      '40040',
      '40069',
      '40078',
      '40330',
      '40468'
    ],
    '059': [
      '42301',
      '42303',
      '42327',
      '42348',
      '42351',
      '42355',
      '42356',
      '42366',
      '42376',
      '42378'
    ],
    '143': [
      '42038',
      '42055',
      '42411',
      '42445'
    ],
    '111': [
      '40023',
      '40025',
      '40041',
      '40059',
      '40118',
      '40177',
      '40202',
      '40203',
      '40204',
      '40205',
      '40206',
      '40207',
      '40208',
      '40209',
      '40210',
      '40211',
      '40212',
      '40213',
      '40214',
      '40215',
      '40216',
      '40217',
      '40218',
      '40219',
      '40220',
      '40222',
      '40223',
      '40228',
      '40229',
      '40231',
      '40241',
      '40242',
      '40243',
      '40245',
      '40258',
      '40272',
      '40280',
      '40291',
      '40299'
    ],
    '165': [
      '40316',
      '40322',
      '40346',
      '40371',
      '40380',
      '40387'
    ],
    '117': [
      '41011',
      '41014',
      '41015',
      '41016',
      '41017',
      '41018',
      '41030',
      '41033',
      '41042',
      '41051',
      '41063',
      '41094'
    ],
    '035': [
      '42020',
      '42025',
      '42036',
      '42040',
      '42048',
      '42049',
      '42054',
      '42071',
      '42076'
    ],
    '033': [
      '42038',
      '42064',
      '42408',
      '42411',
      '42445'
    ],
    '037': [
      '41001',
      '41006',
      '41007',
      '41059',
      '41071',
      '41073',
      '41074',
      '41075',
      '41076',
      '41085',
      '41099'
    ],
    '159': [
      '41203',
      '41214',
      '41224',
      '41230',
      '41231',
      '41250',
      '41262',
      '41267'
    ],
    '147': [
      '40769',
      '42631',
      '42634',
      '42635',
      '42638',
      '42647',
      '42649',
      '42653'
    ],
    '205': [
      '40313',
      '40351'
    ],
    '085': [
      '40119',
      '42207',
      '42349',
      '42361',
      '42712',
      '42721',
      '42726',
      '42754',
      '42762'
    ],
    '191': [
      '41003',
      '41004',
      '41006',
      '41007',
      '41030',
      '41033',
      '41035',
      '41040',
      '41043',
      '41097'
    ],
    '103': [
      '40007',
      '40011',
      '40019',
      '40031',
      '40036',
      '40050',
      '40055',
      '40057',
      '40058',
      '40068',
      '40070',
      '40075',
      '40601'
    ],
    '239': [
      '40347',
      '40383',
      '40390',
      '40601'
    ],
    '163': [
      '40104',
      '40108',
      '40117',
      '40121',
      '40142',
      '40155',
      '40157',
      '40161',
      '40175',
      '40176'
    ],
    '121': [
      '40701',
      '40734',
      '40759',
      '40763',
      '40771',
      '40903',
      '40906',
      '40915',
      '40921',
      '40923',
      '40935',
      '40943',
      '40946',
      '40949',
      '40953',
      '40982',
      '40995',
      '40997'
    ],
    '057': [
      '42129',
      '42167',
      '42717',
      '42731'
    ],
    '203': [
      '40403',
      '40409',
      '40419',
      '40445',
      '40447',
      '40456',
      '40460'
    ],
    '223': [
      '40006',
      '40011',
      '40045',
      '40055'
    ],
    '225': [
      '42404',
      '42437',
      '42459',
      '42461',
      '42462'
    ],
    '169': [
      '42124',
      '42129',
      '42154',
      '42166',
      '42214',
      '42731',
      '42746',
      '42749'
    ],
    '095': [
      '40801',
      '40806',
      '40807',
      '40808',
      '40810',
      '40815',
      '40818',
      '40819',
      '40820',
      '40823',
      '40824',
      '40828',
      '40829',
      '40830',
      '40831',
      '40840',
      '40843',
      '40847',
      '40849',
      '40854',
      '40855',
      '40863',
      '40865',
      '40870',
      '40873',
      '40927',
      '40964',
      '40988'
    ],
    '047': [
      '42215',
      '42217',
      '42223',
      '42232',
      '42236',
      '42240',
      '42254',
      '42262',
      '42266',
      '42408',
      '42442',
      '42453',
      '42464'
    ],
    '209': [
      '40324',
      '40347',
      '40370',
      '40379',
      '40511',
      '41010'
    ],
    '067': [
      '40361',
      '40502',
      '40503',
      '40504',
      '40505',
      '40506',
      '40507',
      '40508',
      '40509',
      '40510',
      '40511',
      '40513',
      '40514',
      '40515',
      '40516',
      '40517'
    ],
    '173': [
      '40334',
      '40337',
      '40346',
      '40353',
      '40360',
      '40361'
    ],
    '133': [
      '40823',
      '40826',
      '40862',
      '41517',
      '41537',
      '41731',
      '41804',
      '41810',
      '41812',
      '41815',
      '41819',
      '41821',
      '41824',
      '41825',
      '41826',
      '41832',
      '41833',
      '41835',
      '41837',
      '41838',
      '41840',
      '41845',
      '41848',
      '41849',
      '41855',
      '41858'
    ],
    '151': [
      '40385',
      '40403',
      '40404',
      '40461',
      '40475'
    ],
    '071': [
      '41216',
      '41265',
      '41601',
      '41602',
      '41603',
      '41604',
      '41605',
      '41606',
      '41607',
      '41612',
      '41615',
      '41616',
      '41619',
      '41621',
      '41622',
      '41630',
      '41631',
      '41635',
      '41636',
      '41640',
      '41642',
      '41643',
      '41645',
      '41647',
      '41649',
      '41650',
      '41653',
      '41655',
      '41659',
      '41660',
      '41663',
      '41666',
      '41667',
      '41669'
    ],
    '141': [
      '42134',
      '42202',
      '42204',
      '42206',
      '42256',
      '42265',
      '42274',
      '42276'
    ],
    '215': [
      '40008',
      '40013',
      '40023',
      '40046',
      '40047',
      '40065',
      '40071'
    ],
    '197': [
      '40312',
      '40376',
      '40380',
      '41360'
    ],
    '031': [
      '42101',
      '42256',
      '42261',
      '42273',
      '42275',
      '42320',
      '42333',
      '42339',
      '42721'
    ],
    '213': [
      '42104',
      '42134',
      '42170',
      '42202',
      '42206'
    ],
    '023': [
      '41002',
      '41004',
      '41040',
      '41043',
      '41044',
      '41064'
    ],
    '043': [
      '40351',
      '41132',
      '41142',
      '41143',
      '41146',
      '41164',
      '41168',
      '41180'
    ],
    '081': [
      '41003',
      '41010',
      '41030',
      '41035',
      '41046',
      '41052',
      '41097'
    ],
    '233': [
      '42404',
      '42406',
      '42409',
      '42437',
      '42441',
      '42450',
      '42452',
      '42455',
      '42456',
      '42463'
    ],
    '013': [
      '40813',
      '40845',
      '40856',
      '40902',
      '40913',
      '40939',
      '40940',
      '40958',
      '40965',
      '40977',
      '40988'
    ],
    '003': [
      '42120',
      '42122',
      '42133',
      '42134',
      '42153',
      '42164'
    ],
    '123': [
      '40051',
      '40052',
      '42716',
      '42718',
      '42748',
      '42757',
      '42764',
      '42776',
      '42784'
    ],
    '099': [
      '42127',
      '42713',
      '42722',
      '42729',
      '42746',
      '42749',
      '42757',
      '42765',
      '42782',
      '42784'
    ],
    '183': [
      '40119',
      '42320',
      '42328',
      '42333',
      '42338',
      '42343',
      '42347',
      '42349',
      '42352',
      '42354',
      '42361',
      '42366',
      '42368',
      '42369',
      '42370',
      '42376',
      '42378'
    ],
    '009': [
      '42123',
      '42127',
      '42130',
      '42133',
      '42141',
      '42154',
      '42156',
      '42160',
      '42166',
      '42171',
      '42749'
    ],
    '029': [
      '40013',
      '40047',
      '40071',
      '40109',
      '40110',
      '40150',
      '40165',
      '40177',
      '40229',
      '40272',
      '40299'
    ],
    '021': [
      '40328',
      '40422',
      '40440',
      '40464',
      '40468'
    ],
    '181': [
      '40311',
      '40350',
      '40374',
      '41031',
      '41039'
    ],
    '195': [
      '41501',
      '41503',
      '41512',
      '41513',
      '41514',
      '41519',
      '41522',
      '41524',
      '41526',
      '41527',
      '41528',
      '41531',
      '41534',
      '41535',
      '41537',
      '41538',
      '41539',
      '41540',
      '41543',
      '41544',
      '41547',
      '41548',
      '41553',
      '41554',
      '41555',
      '41557',
      '41558',
      '41559',
      '41560',
      '41562',
      '41563',
      '41564',
      '41566',
      '41567',
      '41568',
      '41571',
      '41572'
    ],
    '091': [
      '42343',
      '42348',
      '42351',
      '42366',
      '42368'
    ],
    '139': [
      '42028',
      '42045',
      '42047',
      '42058',
      '42078',
      '42081',
      '42083'
    ],
    '025': [
      '41301',
      '41311',
      '41314',
      '41317',
      '41339',
      '41348',
      '41366',
      '41385',
      '41390'
    ],
    '179': [
      '40004',
      '40008',
      '40012',
      '40013',
      '40020',
      '40037',
      '40048',
      '40051',
      '40052',
      '40107'
    ],
    '201': [
      '40311',
      '41004',
      '41044',
      '41064'
    ],
    '115': [
      '41204',
      '41216',
      '41219',
      '41222',
      '41226',
      '41232',
      '41234',
      '41238',
      '41240',
      '41254',
      '41255',
      '41256',
      '41257',
      '41260',
      '41263',
      '41265',
      '41268',
      '41271',
      '41274'
    ],
    '039': [
      '42021',
      '42023',
      '42035',
      '42039'
    ],
    '149': [
      '42322',
      '42327',
      '42350',
      '42352',
      '42371',
      '42372',
      '42376'
    ],
    '011': [
      '40334',
      '40346',
      '40353',
      '40358',
      '40360',
      '40371',
      '40374'
    ],
    '217': [
      '40009',
      '42716',
      '42718',
      '42728',
      '42733',
      '42758'
    ],
    '041': [
      '40006',
      '40011',
      '40045',
      '40075',
      '41008',
      '41045',
      '41083',
      '41098'
    ],
    '027': [
      '40111',
      '40115',
      '40119',
      '40140',
      '40142',
      '40143',
      '40144',
      '40145',
      '40146',
      '40152',
      '40157',
      '40170',
      '40171',
      '40175',
      '40176',
      '40178',
      '42754'
    ],
    '231': [
      '42518',
      '42544',
      '42602',
      '42633'
    ],
    '199': [
      '40409',
      '40419',
      '42501',
      '42503',
      '42518',
      '42519',
      '42533',
      '42544',
      '42553',
      '42567',
      '42642'
    ],
    '137': [
      '40409',
      '40419',
      '40422',
      '40437',
      '40440',
      '40442',
      '40444',
      '40448',
      '40484',
      '40489',
      '42567'
    ],
    '175': [
      '40387',
      '41332',
      '41352',
      '41408',
      '41421',
      '41425',
      '41472'
    ],
    '185': [
      '40010',
      '40014',
      '40026',
      '40031',
      '40055',
      '40056',
      '40059',
      '40068',
      '40077',
      '40241'
    ],
    '089': [
      '41101',
      '41102',
      '41121',
      '41139',
      '41141',
      '41143',
      '41144',
      '41169',
      '41174',
      '41175',
      '41183'
    ],
    '045': [
      '40328',
      '40437',
      '40442',
      '40464',
      '40489',
      '42516',
      '42528',
      '42539',
      '42541',
      '42565',
      '42566',
      '42642',
      '42733'
    ],
    '107': [
      '42408',
      '42410',
      '42413',
      '42431',
      '42436',
      '42440',
      '42441',
      '42442',
      '42445',
      '42450',
      '42453',
      '42456',
      '42464'
    ],
    '155': [
      '40009',
      '40033',
      '40037',
      '40049',
      '40052',
      '40060',
      '40062',
      '40063',
      '40069',
      '40328',
      '42718'
    ],
    '127': [
      '41124',
      '41129',
      '41132',
      '41159',
      '41180',
      '41201',
      '41230',
      '41232',
      '41254',
      '41264'
    ],
    '161': [
      '41034',
      '41044',
      '41055',
      '41056',
      '41062'
    ],
    '065': [
      '40336',
      '40472'
    ],
    '097': [
      '40370',
      '41003',
      '41010',
      '41031'
    ],
    '061': [
      '42101',
      '42160',
      '42163',
      '42171',
      '42207',
      '42210',
      '42259',
      '42275',
      '42285',
      '42721',
      '42729',
      '42754'
    ],
    '001': [
      '42129',
      '42528',
      '42539',
      '42642',
      '42715',
      '42728',
      '42733',
      '42741',
      '42743',
      '42753'
    ],
    '129': [
      '40336',
      '40447',
      '41301',
      '41311',
      '41339',
      '41397'
    ],
    '017': [
      '40311',
      '40324',
      '40348',
      '40361',
      '40374',
      '40511',
      '40516',
      '41031'
    ],
    '211': [
      '40003',
      '40019',
      '40022',
      '40023',
      '40046',
      '40057',
      '40065',
      '40067',
      '40068',
      '40071',
      '40076',
      '40245',
      '40601'
    ],
    '087': [
      '42214',
      '42716',
      '42718',
      '42743',
      '42746',
      '42757',
      '42764',
      '42782'
    ],
    '235': [
      '40701',
      '40759',
      '40763',
      '40769',
      '40771',
      '40906',
      '40940'
    ],
    '005': [
      '40046',
      '40076',
      '40342',
      '40372'
    ],
    '189': [
      '40447',
      '40486',
      '40983',
      '41314',
      '41721'
    ],
    '077': [
      '41045',
      '41046',
      '41083',
      '41086',
      '41092',
      '41095'
    ],
    '157': [
      '42025',
      '42029',
      '42044',
      '42048',
      '42054',
      '42066',
      '42082'
    ],
    '069': [
      '41039',
      '41041',
      '41049',
      '41055',
      '41093'
    ],
    '093': [
      '40051',
      '40121',
      '40150',
      '40160',
      '40162',
      '40175',
      '40177',
      '42701',
      '42712',
      '42724',
      '42732',
      '42740',
      '42776',
      '42784',
      '42788'
    ],
    '105': [
      '42021',
      '42031',
      '42032',
      '42039',
      '42041',
      '42085',
      '42088'
    ],
    '113': [
      '40339',
      '40356',
      '40383',
      '40390',
      '40515'
    ],
    '219': [
      '42204',
      '42220',
      '42234',
      '42256',
      '42265',
      '42266',
      '42280',
      '42286',
      '42345'
    ],
    '187': [
      '40359',
      '40363',
      '40379',
      '41010',
      '41046',
      '41083',
      '41086',
      '41098'
    ],
    '207': [
      '42528',
      '42544',
      '42602',
      '42629',
      '42642'
    ],
    '075': [
      '38079',
      '42041',
      '42050'
    ],
    '051': [
      '40486',
      '40741',
      '40744',
      '40874',
      '40913',
      '40914',
      '40941',
      '40962',
      '40972',
      '40983',
      '41714'
    ],
    '083': [
      '42025',
      '42027',
      '42035',
      '42039',
      '42040',
      '42041',
      '42049',
      '42051',
      '42054',
      '42061',
      '42066',
      '42069',
      '42071',
      '42079',
      '42082',
      '42085',
      '42088'
    ],
    '135': [
      '41093',
      '41135',
      '41141',
      '41164',
      '41166',
      '41174',
      '41179',
      '41189'
    ],
    '227': [
      '42101',
      '42102',
      '42103',
      '42104',
      '42122',
      '42159',
      '42170',
      '42171',
      '42206',
      '42274'
    ],
    '101': [
      '42301',
      '42406',
      '42420',
      '42451',
      '42452',
      '42458',
      '42462'
    ],
    '125': [
      '40701',
      '40729',
      '40734',
      '40737',
      '40740',
      '40741',
      '40743',
      '40744',
      '40962'
    ],
    '049': [
      '40353',
      '40391',
      '40515'
    ],
    '237': [
      '41301',
      '41332',
      '41360',
      '41365'
    ],
    '019': [
      '41101',
      '41102',
      '41129',
      '41168'
    ],
    '221': [
      '42211',
      '42215',
      '42232',
      '42236'
    ],
    '007': [
      '42022',
      '42024',
      '42053',
      '42056',
      '42060',
      '42087'
    ],
    '073': [
      '40003',
      '40076',
      '40347',
      '40379',
      '40601',
      '40604'
    ],
    '015': [
      '41005',
      '41018',
      '41030',
      '41042',
      '41048',
      '41080',
      '41091',
      '41092',
      '41094'
    ]
  },
  '13': {
    '167': [
      '30413',
      '31002',
      '31049',
      '31089',
      '31096'
    ],
    '079': [
      '31008',
      '31016',
      '31030',
      '31050',
      '31052',
      '31066',
      '31078'
    ],
    '131': [
      '31773',
      '31779',
      '31792',
      '39827',
      '39828',
      '39897'
    ],
    '193': [
      '31030',
      '31041',
      '31057',
      '31063',
      '31068',
      '31076',
      '31711',
      '31806'
    ],
    '119': [
      '30520',
      '30521',
      '30530',
      '30538',
      '30553',
      '30557',
      '30577',
      '30633',
      '30662'
    ],
    '153': [
      '31005',
      '31008',
      '31025',
      '31028',
      '31030',
      '31036',
      '31047',
      '31069',
      '31088',
      '31091',
      '31093',
      '31098'
    ],
    '055': [
      '30105',
      '30165',
      '30730',
      '30731',
      '30747',
      '30753'
    ],
    '109': [
      '30417',
      '30423',
      '30427',
      '30429'
    ],
    '297': [
      '30012',
      '30014',
      '30019',
      '30025',
      '30052',
      '30054',
      '30620',
      '30641',
      '30650',
      '30655',
      '30656'
    ],
    '007': [
      '31721',
      '39813',
      '39837',
      '39841',
      '39862',
      '39870'
    ],
    '311': [
      '30528',
      '30533',
      '30545',
      '30564',
      '30571'
    ],
    '305': [
      '31518',
      '31543',
      '31545',
      '31546',
      '31555',
      '31560'
    ],
    '291': [
      '30512',
      '30560',
      '30572',
      '30582'
    ],
    '063': [
      '30215',
      '30228',
      '30236',
      '30238',
      '30250',
      '30260',
      '30273',
      '30274',
      '30281',
      '30288',
      '30294',
      '30296',
      '30297',
      '30337',
      '30349',
      '30354'
    ],
    '171': [
      '30204',
      '30224',
      '30233',
      '30257',
      '30285',
      '30295',
      '31016',
      '31097'
    ],
    '177': [
      '31701',
      '31707',
      '31721',
      '31743',
      '31763',
      '31764',
      '31787'
    ],
    '229': [
      '31510',
      '31516',
      '31518',
      '31551',
      '31556',
      '31557'
    ],
    '265': [
      '30631',
      '30664',
      '30669',
      '30678'
    ],
    '155': [
      '31733',
      '31749',
      '31750',
      '31774',
      '31783',
      '31798'
    ],
    '059': [
      '30601',
      '30602',
      '30605',
      '30606',
      '30607',
      '30609',
      '30622',
      '30646',
      '30683'
    ],
    '137': [
      '30510',
      '30511',
      '30523',
      '30531',
      '30535',
      '30563',
      '30573',
      '30577'
    ],
    '111': [
      '30512',
      '30513',
      '30522',
      '30541',
      '30555',
      '30559',
      '30560',
      '30572'
    ],
    '069': [
      '31512',
      '31519',
      '31533',
      '31535',
      '31552',
      '31554',
      '31567',
      '31624',
      '31650',
      '31798'
    ],
    '299': [
      '31501',
      '31503',
      '31550',
      '31552',
      '31554',
      '31624',
      '31631'
    ],
    '117': [
      '30004',
      '30005',
      '30024',
      '30028',
      '30040',
      '30041',
      '30097',
      '30107',
      '30506',
      '30534'
    ],
    '035': [
      '30216',
      '30233',
      '30234',
      '30248',
      '31029'
    ],
    '033': [
      '30426',
      '30434',
      '30441',
      '30442',
      '30456',
      '30805',
      '30815',
      '30816',
      '30830'
    ],
    '263': [
      '31801',
      '31810',
      '31812',
      '31816',
      '31826',
      '31827',
      '31831',
      '31836'
    ],
    '159': [
      '30014',
      '30055',
      '30056',
      '31038',
      '31064',
      '31085'
    ],
    '147': [
      '30516',
      '30520',
      '30553',
      '30624',
      '30634',
      '30635',
      '30643',
      '30662'
    ],
    '281': [
      '30546',
      '30582'
    ],
    '205': [
      '31705',
      '31716',
      '31730',
      '31744',
      '31765',
      '31779',
      '31784'
    ],
    '085': [
      '30143',
      '30148',
      '30533',
      '30534',
      '30536'
    ],
    '191': [
      '31304',
      '31305',
      '31323',
      '31327',
      '31331'
    ],
    '251': [
      '30426',
      '30446',
      '30449',
      '30455',
      '30467'
    ],
    '275': [
      '31626',
      '31720',
      '31738',
      '31757',
      '31765',
      '31773',
      '31778',
      '31792'
    ],
    '163': [
      '30413',
      '30434',
      '30477',
      '30803',
      '30810',
      '30816',
      '30818',
      '30823',
      '30833'
    ],
    '029': [
      '31308',
      '31321',
      '31324'
    ],
    '321': [
      '31705',
      '31714',
      '31744',
      '31771',
      '31772',
      '31775',
      '31781',
      '31789',
      '31791',
      '31795',
      '31796'
    ],
    '121': [
      '30004',
      '30005',
      '30009',
      '30022',
      '30024',
      '30075',
      '30076',
      '30092',
      '30097',
      '30213',
      '30268',
      '30291',
      '30296',
      '30303',
      '30305',
      '30306',
      '30307',
      '30308',
      '30309',
      '30310',
      '30311',
      '30312',
      '30313',
      '30314',
      '30315',
      '30316',
      '30317',
      '30318',
      '30319',
      '30324',
      '30326',
      '30327',
      '30328',
      '30331',
      '30332',
      '30334',
      '30336',
      '30337',
      '30338',
      '30339',
      '30342',
      '30344',
      '30349',
      '30350',
      '30354',
      '30363'
    ],
    '057': [
      '30004',
      '30028',
      '30040',
      '30075',
      '30101',
      '30102',
      '30107',
      '30114',
      '30115',
      '30143',
      '30183',
      '30184',
      '30188',
      '30189',
      '30534'
    ],
    '027': [
      '31602',
      '31625',
      '31626',
      '31629',
      '31638',
      '31643',
      '31720',
      '31778'
    ],
    '103': [
      '30446',
      '31302',
      '31303',
      '31307',
      '31312',
      '31318',
      '31326',
      '31329'
    ],
    '225': [
      '31008',
      '31030',
      '31069'
    ],
    '259': [
      '31805',
      '31814',
      '31815',
      '31821',
      '31825'
    ],
    '095': [
      '31701',
      '31705',
      '31707',
      '31721',
      '31763',
      '31791'
    ],
    '215': [
      '31801',
      '31808',
      '31820',
      '31829',
      '31901',
      '31903',
      '31904',
      '31905',
      '31906',
      '31907',
      '31909'
    ],
    '241': [
      '30523',
      '30525',
      '30537',
      '30552',
      '30562',
      '30568',
      '30573',
      '30576',
      '30581'
    ],
    '307': [
      '31780',
      '31803',
      '31824',
      '31825',
      '31832',
      '39877'
    ],
    '067': [
      '30008',
      '30060',
      '30062',
      '30064',
      '30066',
      '30067',
      '30068',
      '30075',
      '30080',
      '30082',
      '30101',
      '30102',
      '30106',
      '30126',
      '30127',
      '30141',
      '30144',
      '30152',
      '30157',
      '30168',
      '30188',
      '30339'
    ],
    '173': [
      '31635',
      '31639',
      '31641',
      '31645',
      '31649'
    ],
    '133': [
      '30642',
      '30650',
      '30665',
      '30669',
      '30677',
      '30678'
    ],
    '239': [
      '39854',
      '39867'
    ],
    '071': [
      '31637',
      '31722',
      '31738',
      '31744',
      '31747',
      '31756',
      '31765',
      '31768',
      '31771',
      '31775',
      '31778',
      '31788'
    ],
    '141': [
      '30678',
      '31045',
      '31061',
      '31087'
    ],
    '053': [
      '31805',
      '31905'
    ],
    '047': [
      '30707',
      '30710',
      '30726',
      '30736',
      '30739',
      '30741',
      '30742',
      '30755'
    ],
    '197': [
      '31058',
      '31801',
      '31803'
    ],
    '243': [
      '39836',
      '39840',
      '39867',
      '39886'
    ],
    '195': [
      '30530',
      '30565',
      '30601',
      '30624',
      '30627',
      '30628',
      '30629',
      '30633',
      '30646',
      '30662'
    ],
    '061': [
      '39824',
      '39836',
      '39846',
      '39851',
      '39854',
      '39867'
    ],
    '213': [
      '30705',
      '30708',
      '30711',
      '30721',
      '30735',
      '30751'
    ],
    '023': [
      '31012',
      '31014',
      '31017',
      '31022',
      '31065'
    ],
    '043': [
      '30420',
      '30439',
      '30451'
    ],
    '065': [
      '31623',
      '31630',
      '31631',
      '31634',
      '31635',
      '31642'
    ],
    '099': [
      '39813',
      '39823',
      '39824',
      '39837',
      '39841',
      '39845',
      '39861'
    ],
    '139': [
      '30501',
      '30504',
      '30506',
      '30507',
      '30510',
      '30517',
      '30518',
      '30519',
      '30527',
      '30531',
      '30533',
      '30542',
      '30543',
      '30548',
      '30554',
      '30564',
      '30566',
      '30567',
      '30575'
    ],
    '145': [
      '31804',
      '31807',
      '31808',
      '31811',
      '31820',
      '31822',
      '31823',
      '31826',
      '31829',
      '31830',
      '31831',
      '31833'
    ],
    '233': [
      '30104',
      '30113',
      '30125',
      '30147',
      '30153',
      '30173',
      '30178'
    ],
    '013': [
      '30011',
      '30517',
      '30548',
      '30620',
      '30622',
      '30656',
      '30666',
      '30680'
    ],
    '003': [
      '31552',
      '31624',
      '31635',
      '31642',
      '31650'
    ],
    '123': [
      '30175',
      '30513',
      '30522',
      '30536',
      '30540'
    ],
    '277': [
      '31637',
      '31733',
      '31749',
      '31775',
      '31793',
      '31794',
      '31795'
    ],
    '183': [
      '30427',
      '31301',
      '31313',
      '31316',
      '31323'
    ],
    '271': [
      '30411',
      '31037',
      '31055',
      '31060',
      '31077',
      '31083',
      '31544',
      '31549'
    ],
    '093': [
      '31007',
      '31051',
      '31063',
      '31070',
      '31091',
      '31092'
    ],
    '201': [
      '39823',
      '39837',
      '39841',
      '39845',
      '39859'
    ],
    '245': [
      '30805',
      '30812',
      '30813',
      '30815',
      '30901',
      '30904',
      '30905',
      '30906',
      '30907',
      '30909',
      '30912'
    ],
    '021': [
      '31020',
      '31052',
      '31066',
      '31201',
      '31204',
      '31206',
      '31207',
      '31210',
      '31211',
      '31213',
      '31216',
      '31217',
      '31220'
    ],
    '181': [
      '30668',
      '30817'
    ],
    '031': [
      '30415',
      '30417',
      '30425',
      '30450',
      '30452',
      '30458',
      '30460',
      '30461',
      '30471',
      '31308',
      '31321'
    ],
    '091': [
      '31011',
      '31012',
      '31014',
      '31023',
      '31037',
      '31060',
      '31077'
    ],
    '285': [
      '30217',
      '30230',
      '30240',
      '30241',
      '31822',
      '31833'
    ],
    '025': [
      '31503',
      '31542',
      '31543',
      '31553',
      '31557',
      '31566'
    ],
    '169': [
      '31031',
      '31032',
      '31033',
      '31038',
      '31046',
      '31061',
      '31211',
      '31217'
    ],
    '279': [
      '30436',
      '30473',
      '30474',
      '30475'
    ],
    '151': [
      '30228',
      '30233',
      '30234',
      '30236',
      '30248',
      '30252',
      '30253',
      '30273',
      '30281',
      '30294'
    ],
    '235': [
      '31023',
      '31036',
      '31071',
      '31091'
    ],
    '289': [
      '31014',
      '31017',
      '31020',
      '31031',
      '31044',
      '31217'
    ],
    '149': [
      '30108',
      '30170',
      '30217',
      '30230',
      '30263'
    ],
    '011': [
      '30510',
      '30511',
      '30521',
      '30529',
      '30530',
      '30543',
      '30547',
      '30554',
      '30558',
      '30577',
      '30633'
    ],
    '217': [
      '30013',
      '30014',
      '30016',
      '30025',
      '30052',
      '30054',
      '30055',
      '30056',
      '30070',
      '30164',
      '30233'
    ],
    '267': [
      '30417',
      '30420',
      '30421',
      '30427',
      '30436',
      '30438',
      '30453'
    ],
    '157': [
      '30517',
      '30529',
      '30530',
      '30543',
      '30548',
      '30549',
      '30558',
      '30565',
      '30567',
      '30575',
      '30601',
      '30607',
      '30622',
      '30666',
      '30680'
    ],
    '231': [
      '30206',
      '30224',
      '30256',
      '30257',
      '30258',
      '30285',
      '30292',
      '30295'
    ],
    '269': [
      '31006',
      '31039',
      '31058',
      '31076',
      '31081',
      '31812'
    ],
    '179': [
      '31301',
      '31309',
      '31313',
      '31314',
      '31315',
      '31320',
      '31323'
    ],
    '283': [
      '30457',
      '30470',
      '30474',
      '31002'
    ],
    '313': [
      '30710',
      '30720',
      '30721',
      '30735',
      '30736',
      '30740',
      '30755',
      '30756'
    ],
    '199': [
      '30218',
      '30220',
      '30222',
      '30230',
      '30251',
      '30276',
      '30293',
      '31816',
      '31822',
      '31830'
    ],
    '319': [
      '31003',
      '31017',
      '31031',
      '31042',
      '31044',
      '31054',
      '31061',
      '31090'
    ],
    '143': [
      '30110',
      '30113',
      '30153',
      '30176',
      '30179',
      '30182'
    ],
    '039': [
      '31537',
      '31547',
      '31548',
      '31558',
      '31565',
      '31568',
      '31569'
    ],
    '175': [
      '30411',
      '30428',
      '30454',
      '30457',
      '31002',
      '31009',
      '31019',
      '31021',
      '31022',
      '31027',
      '31065',
      '31075'
    ],
    '253': [
      '39825',
      '39845',
      '39859',
      '39861'
    ],
    '185': [
      '31601',
      '31602',
      '31605',
      '31606',
      '31632',
      '31636',
      '31641',
      '31645',
      '31698',
      '31699'
    ],
    '089': [
      '30002',
      '30012',
      '30021',
      '30030',
      '30032',
      '30033',
      '30034',
      '30035',
      '30038',
      '30039',
      '30058',
      '30072',
      '30079',
      '30083',
      '30084',
      '30087',
      '30088',
      '30094',
      '30288',
      '30294',
      '30306',
      '30307',
      '30315',
      '30316',
      '30317',
      '30319',
      '30322',
      '30324',
      '30329',
      '30338',
      '30340',
      '30341',
      '30345',
      '30346',
      '30350',
      '30360'
    ],
    '045': [
      '30108',
      '30110',
      '30116',
      '30117',
      '30118',
      '30170',
      '30179',
      '30180',
      '30182',
      '30185'
    ],
    '301': [
      '30807',
      '30810',
      '30820',
      '30821',
      '30823',
      '30824',
      '30828',
      '31045'
    ],
    '107': [
      '30401',
      '30425',
      '30436',
      '30439',
      '30441',
      '30448',
      '30457',
      '30464',
      '30471',
      '30474',
      '31002',
      '31049'
    ],
    '227': [
      '30107',
      '30139',
      '30143',
      '30148',
      '30175',
      '30177',
      '30183',
      '30534',
      '30734'
    ],
    '127': [
      '31520',
      '31522',
      '31523',
      '31524',
      '31525',
      '31527',
      '31543',
      '31561',
      '31566'
    ],
    '161': [
      '31510',
      '31513',
      '31519',
      '31532',
      '31539'
    ],
    '261': [
      '31709',
      '31711',
      '31719',
      '31735',
      '31743',
      '31764',
      '31780',
      '31787'
    ],
    '081': [
      '31015',
      '31072',
      '31092',
      '31712'
    ],
    '051': [
      '31302',
      '31322',
      '31328',
      '31401',
      '31404',
      '31405',
      '31406',
      '31407',
      '31408',
      '31409',
      '31410',
      '31411',
      '31415',
      '31419'
    ],
    '097': [
      '30106',
      '30122',
      '30134',
      '30135',
      '30168',
      '30180',
      '30185',
      '30187'
    ],
    '287': [
      '31714',
      '31733',
      '31783',
      '31790'
    ],
    '001': [
      '31513',
      '31518',
      '31539',
      '31555',
      '31563'
    ],
    '129': [
      '30103',
      '30139',
      '30171',
      '30701',
      '30733',
      '30734',
      '30735',
      '30746'
    ],
    '017': [
      '31079',
      '31750',
      '31783',
      '31798'
    ],
    '211': [
      '30055',
      '30056',
      '30621',
      '30623',
      '30625',
      '30641',
      '30650',
      '30663'
    ],
    '087': [
      '31730',
      '39815',
      '39817',
      '39819',
      '39825',
      '39834',
      '39837',
      '39897'
    ],
    '115': [
      '30103',
      '30104',
      '30105',
      '30124',
      '30125',
      '30145',
      '30147',
      '30149',
      '30161',
      '30165',
      '30173',
      '30701',
      '30733'
    ],
    '255': [
      '30205',
      '30223',
      '30224',
      '30228',
      '30248',
      '30284',
      '30292'
    ],
    '005': [
      '31510',
      '31551',
      '31554'
    ],
    '189': [
      '30802',
      '30808',
      '30814',
      '30824'
    ],
    '077': [
      '30220',
      '30230',
      '30259',
      '30263',
      '30265',
      '30268',
      '30275',
      '30276',
      '30277',
      '30289'
    ],
    '317': [
      '30631',
      '30660',
      '30668',
      '30673',
      '30817'
    ],
    '237': [
      '30625',
      '31024',
      '31061'
    ],
    '309': [
      '30411',
      '30428',
      '31037',
      '31083',
      '31549'
    ],
    '105': [
      '30624',
      '30634',
      '30635',
      '30662'
    ],
    '113': [
      '30205',
      '30214',
      '30215',
      '30238',
      '30269',
      '30276',
      '30290'
    ],
    '219': [
      '30605',
      '30606',
      '30619',
      '30621',
      '30622',
      '30666',
      '30677',
      '30680'
    ],
    '187': [
      '30528',
      '30533',
      '30534',
      '30564'
    ],
    '207': [
      '30204',
      '30233',
      '31016',
      '31029',
      '31046',
      '31066',
      '31097',
      '31210',
      '31220'
    ],
    '315': [
      '31001',
      '31015',
      '31071',
      '31072',
      '31079',
      '31084',
      '31750'
    ],
    '075': [
      '31620',
      '31627',
      '31632',
      '31637',
      '31647'
    ],
    '223': [
      '30101',
      '30127',
      '30132',
      '30134',
      '30141',
      '30153',
      '30157',
      '30178',
      '30179',
      '30180'
    ],
    '247': [
      '30012',
      '30013',
      '30016',
      '30052',
      '30058',
      '30094',
      '30252',
      '30281',
      '30294'
    ],
    '209': [
      '30410',
      '30412',
      '30445',
      '30457',
      '30470',
      '30473',
      '30474'
    ],
    '303': [
      '30413',
      '30820',
      '31018',
      '31035',
      '31067',
      '31082',
      '31087',
      '31089',
      '31094',
      '31096'
    ],
    '009': [
      '31031',
      '31033',
      '31061',
      '31062',
      '31082',
      '31087'
    ],
    '293': [
      '30204',
      '30256',
      '30258',
      '30285',
      '30286',
      '31016',
      '31097'
    ],
    '037': [
      '39813',
      '39842',
      '39846',
      '39862',
      '39866',
      '39886'
    ],
    '249': [
      '31803',
      '31806'
    ],
    '273': [
      '31721',
      '39826',
      '39842',
      '39877',
      '39885',
      '39886'
    ],
    '165': [
      '30425',
      '30441',
      '30442',
      '30822'
    ],
    '101': [
      '31630',
      '31631',
      '31636',
      '31648'
    ],
    '257': [
      '30511',
      '30538',
      '30557',
      '30577'
    ],
    '135': [
      '30011',
      '30017',
      '30019',
      '30024',
      '30039',
      '30043',
      '30044',
      '30045',
      '30046',
      '30047',
      '30052',
      '30071',
      '30078',
      '30084',
      '30087',
      '30092',
      '30093',
      '30096',
      '30097',
      '30340',
      '30360',
      '30517',
      '30518',
      '30519',
      '30548',
      '30620'
    ],
    '125': [
      '30810',
      '30820',
      '30823',
      '30828'
    ],
    '049': [
      '31537',
      '31553',
      '31562',
      '31631'
    ],
    '295': [
      '30707',
      '30725',
      '30728',
      '30731',
      '30736',
      '30738',
      '30739',
      '30740',
      '30741',
      '30742',
      '30747',
      '30750',
      '30753'
    ],
    '019': [
      '31622',
      '31637',
      '31639',
      '31645',
      '31749',
      '31794'
    ],
    '015': [
      '30101',
      '30102',
      '30103',
      '30104',
      '30120',
      '30121',
      '30137',
      '30139',
      '30145',
      '30161',
      '30171',
      '30178',
      '30183',
      '30184'
    ],
    '083': [
      '30731',
      '30738',
      '30750',
      '30752',
      '30757'
    ],
    '073': [
      '30802',
      '30809',
      '30813',
      '30814',
      '30903',
      '30907',
      '30909'
    ],
    '221': [
      '30619',
      '30627',
      '30628',
      '30629',
      '30630',
      '30648',
      '30660',
      '30667',
      '30669',
      '30683'
    ]
  },
  '49': {
    '041': [
      '84620',
      '84652',
      '84654',
      '84657',
      '84701',
      '84711',
      '84724',
      '84730',
      '84739',
      '84744',
      '84754',
      '84766'
    ],
    '057': [
      '84067',
      '84310',
      '84315',
      '84317',
      '84401',
      '84403',
      '84404',
      '84405',
      '84414'
    ],
    '001': [
      '84713',
      '84731',
      '84751',
      '84752',
      '84753'
    ],
    '017': [
      '84533',
      '84712',
      '84716',
      '84718',
      '84726',
      '84735',
      '84736',
      '84759',
      '84762',
      '84764',
      '84775',
      '84776'
    ],
    '055': [
      '84715',
      '84734',
      '84747',
      '84749',
      '84773',
      '84775'
    ],
    '029': [
      '84018',
      '84050'
    ],
    '047': [
      '84026',
      '84035',
      '84039',
      '84052',
      '84053',
      '84063',
      '84066',
      '84076',
      '84078',
      '84085',
      '84540'
    ],
    '005': [
      '84304',
      '84305',
      '84308',
      '84318',
      '84319',
      '84320',
      '84321',
      '84325',
      '84326',
      '84327',
      '84328',
      '84332',
      '84333',
      '84335',
      '84338',
      '84339',
      '84341'
    ],
    '053': [
      '84722',
      '84725',
      '84733',
      '84737',
      '84738',
      '84745',
      '84746',
      '84756',
      '84757',
      '84763',
      '84765',
      '84767',
      '84770',
      '84774',
      '84779',
      '84780',
      '84781',
      '84782',
      '84783',
      '84784',
      '84790'
    ],
    '051': [
      '84032',
      '84036',
      '84049',
      '84060',
      '84082',
      '84604'
    ],
    '027': [
      '84624',
      '84631',
      '84635',
      '84636',
      '84637',
      '84638',
      '84640',
      '84649',
      '84656',
      '84713',
      '84728',
      '84751'
    ],
    '009': [
      '84023',
      '84046',
      '84078'
    ],
    '025': [
      '84533',
      '84710',
      '84718',
      '84729',
      '84741',
      '84755',
      '84758',
      '84762'
    ],
    '021': [
      '84714',
      '84719',
      '84720',
      '84721',
      '84742',
      '84753',
      '84756',
      '84757',
      '84760',
      '84761',
      '84772'
    ],
    '039': [
      '84621',
      '84622',
      '84623',
      '84627',
      '84629',
      '84630',
      '84632',
      '84634',
      '84642',
      '84643',
      '84646',
      '84647',
      '84662',
      '84665',
      '84667'
    ],
    '011': [
      '84010',
      '84014',
      '84015',
      '84025',
      '84037',
      '84040',
      '84041',
      '84054',
      '84056',
      '84075',
      '84087',
      '84315',
      '84405'
    ],
    '035': [
      '84006',
      '84009',
      '84020',
      '84044',
      '84047',
      '84065',
      '84070',
      '84081',
      '84084',
      '84088',
      '84092',
      '84093',
      '84094',
      '84095',
      '84096',
      '84101',
      '84102',
      '84103',
      '84104',
      '84105',
      '84106',
      '84107',
      '84108',
      '84109',
      '84111',
      '84112',
      '84113',
      '84115',
      '84116',
      '84117',
      '84118',
      '84119',
      '84120',
      '84121',
      '84123',
      '84124',
      '84128',
      '84144',
      '84180'
    ],
    '033': [
      '84028',
      '84038',
      '84064',
      '84086'
    ],
    '037': [
      '81324',
      '84511',
      '84512',
      '84530',
      '84531',
      '84532',
      '84533',
      '84534',
      '84535',
      '84536',
      '86044',
      '86514'
    ],
    '031': [
      '84712',
      '84723',
      '84732',
      '84740',
      '84743',
      '84744',
      '84750'
    ],
    '023': [
      '84022',
      '84034',
      '84083',
      '84628',
      '84639',
      '84645',
      '84648',
      '84656'
    ],
    '043': [
      '82930',
      '84017',
      '84024',
      '84033',
      '84036',
      '84055',
      '84060',
      '84061',
      '84098'
    ],
    '045': [
      '84022',
      '84029',
      '84034',
      '84069',
      '84071',
      '84074',
      '84080',
      '84083',
      '84628'
    ],
    '049': [
      '84003',
      '84004',
      '84005',
      '84013',
      '84020',
      '84042',
      '84043',
      '84045',
      '84057',
      '84058',
      '84062',
      '84097',
      '84526',
      '84601',
      '84604',
      '84606',
      '84626',
      '84629',
      '84633',
      '84651',
      '84653',
      '84655',
      '84660',
      '84663',
      '84664'
    ],
    '019': [
      '84515',
      '84525',
      '84532',
      '84540'
    ],
    '003': [
      '83312',
      '83342',
      '84083',
      '84301',
      '84302',
      '84306',
      '84307',
      '84309',
      '84311',
      '84312',
      '84313',
      '84314',
      '84316',
      '84324',
      '84329',
      '84330',
      '84331',
      '84334',
      '84336',
      '84337',
      '84340'
    ],
    '007': [
      '84501',
      '84520',
      '84526',
      '84529',
      '84539',
      '84542'
    ],
    '013': [
      '84001',
      '84002',
      '84007',
      '84021',
      '84027',
      '84031',
      '84051',
      '84052',
      '84053',
      '84066',
      '84072',
      '84073',
      '84526'
    ],
    '015': [
      '84513',
      '84516',
      '84518',
      '84521',
      '84522',
      '84523',
      '84525',
      '84528',
      '84537'
    ]
  },
  '26': {
    '079': [
      '49612',
      '49633',
      '49646',
      '49659',
      '49676',
      '49680',
      '49690',
      '49738'
    ],
    '123': [
      '49303',
      '49307',
      '49309',
      '49312',
      '49327',
      '49329',
      '49330',
      '49336',
      '49337',
      '49338',
      '49343',
      '49346',
      '49349',
      '49412',
      '49421',
      '49425',
      '49457',
      '49459',
      '49677'
    ],
    '099': [
      '48005',
      '48015',
      '48021',
      '48026',
      '48035',
      '48036',
      '48038',
      '48041',
      '48042',
      '48043',
      '48044',
      '48045',
      '48047',
      '48048',
      '48050',
      '48051',
      '48062',
      '48065',
      '48066',
      '48080',
      '48081',
      '48082',
      '48088',
      '48089',
      '48091',
      '48092',
      '48093',
      '48094',
      '48095',
      '48096',
      '48236',
      '48306',
      '48310',
      '48312',
      '48313',
      '48314',
      '48315',
      '48316',
      '48317',
      '48397'
    ],
    '009': [
      '49611',
      '49612',
      '49615',
      '49622',
      '49627',
      '49629',
      '49648',
      '49659',
      '49676',
      '49690',
      '49712',
      '49713',
      '49720',
      '49727',
      '49729',
      '49730'
    ],
    '119': [
      '48619',
      '49709',
      '49746',
      '49751',
      '49756'
    ],
    '153': [
      '49817',
      '49836',
      '49840',
      '49854',
      '49883',
      '49884',
      '49895'
    ],
    '055': [
      '49620',
      '49633',
      '49637',
      '49643',
      '49649',
      '49666',
      '49680',
      '49683',
      '49684',
      '49686',
      '49690'
    ],
    '029': [
      '49712',
      '49713',
      '49720',
      '49727',
      '49729',
      '49730',
      '49770',
      '49782',
      '49795',
      '49796'
    ],
    '109': [
      '49807',
      '49812',
      '49821',
      '49847',
      '49848',
      '49858',
      '49863',
      '49873',
      '49874',
      '49886',
      '49887',
      '49892',
      '49893',
      '49896'
    ],
    '021': [
      '49022',
      '49038',
      '49047',
      '49084',
      '49085',
      '49098',
      '49101',
      '49102',
      '49103',
      '49104',
      '49106',
      '49107',
      '49111',
      '49113',
      '49115',
      '49116',
      '49117',
      '49119',
      '49120',
      '49125',
      '49126',
      '49127',
      '49128',
      '49129'
    ],
    '053': [
      '49911',
      '49938',
      '49947',
      '49959',
      '49968',
      '49969',
      '54540'
    ],
    '031': [
      '49701',
      '49705',
      '49706',
      '49716',
      '49717',
      '49718',
      '49721',
      '49749',
      '49755',
      '49765',
      '49769',
      '49791',
      '49795',
      '49799'
    ],
    '091': [
      '48176',
      '49220',
      '49221',
      '49228',
      '49229',
      '49230',
      '49233',
      '49235',
      '49236',
      '49238',
      '49247',
      '49248',
      '49253',
      '49256',
      '49265',
      '49267',
      '49268',
      '49276',
      '49279',
      '49286',
      '49287',
      '49288',
      '49289'
    ],
    '063': [
      '48413',
      '48432',
      '48441',
      '48445',
      '48456',
      '48467',
      '48468',
      '48470',
      '48475',
      '48720',
      '48725',
      '48726',
      '48731',
      '48735',
      '48754',
      '48755',
      '48759',
      '48767'
    ],
    '139': [
      '49315',
      '49318',
      '49330',
      '49401',
      '49403',
      '49404',
      '49415',
      '49417',
      '49418',
      '49423',
      '49424',
      '49426',
      '49428',
      '49434',
      '49435',
      '49448',
      '49451',
      '49456',
      '49460',
      '49464',
      '49534',
      '49544'
    ],
    '025': [
      '48813',
      '49011',
      '49012',
      '49014',
      '49015',
      '49017',
      '49021',
      '49029',
      '49033',
      '49034',
      '49037',
      '49051',
      '49052',
      '49068',
      '49076',
      '49092',
      '49094',
      '49224',
      '49237',
      '49245',
      '49252',
      '49284'
    ],
    '059': [
      '49082',
      '49220',
      '49227',
      '49232',
      '49233',
      '49242',
      '49246',
      '49247',
      '49249',
      '49250',
      '49252',
      '49255',
      '49262',
      '49266',
      '49271',
      '49274',
      '49282',
      '49288'
    ],
    '151': [
      '48032',
      '48097',
      '48401',
      '48416',
      '48419',
      '48422',
      '48426',
      '48427',
      '48434',
      '48441',
      '48450',
      '48453',
      '48454',
      '48456',
      '48465',
      '48466',
      '48469',
      '48471',
      '48472',
      '48475',
      '48726'
    ],
    '111': [
      '48612',
      '48615',
      '48618',
      '48623',
      '48626',
      '48628',
      '48637',
      '48640',
      '48642',
      '48652',
      '48657',
      '48662',
      '48667',
      '48858',
      '48880',
      '48883'
    ],
    '039': [
      '48653',
      '49733',
      '49738',
      '49756'
    ],
    '149': [
      '49011',
      '49030',
      '49032',
      '49040',
      '49042',
      '49066',
      '49067',
      '49072',
      '49075',
      '49091',
      '49093',
      '49097',
      '49099'
    ],
    '011': [
      '48610',
      '48650',
      '48658',
      '48659',
      '48703',
      '48749',
      '48763',
      '48765',
      '48766'
    ],
    '095': [
      '49748',
      '49853',
      '49868'
    ],
    '117': [
      '48809',
      '48811',
      '48818',
      '48829',
      '48834',
      '48838',
      '48845',
      '48850',
      '48852',
      '48877',
      '48884',
      '48885',
      '48886',
      '48888',
      '48891',
      '49310',
      '49322',
      '49326',
      '49329',
      '49336',
      '49337',
      '49339',
      '49343',
      '49347'
    ],
    '035': [
      '48617',
      '48622',
      '48624',
      '48625',
      '48632',
      '48633',
      '49665'
    ],
    '157': [
      '48415',
      '48420',
      '48435',
      '48453',
      '48464',
      '48701',
      '48723',
      '48726',
      '48727',
      '48729',
      '48733',
      '48734',
      '48735',
      '48741',
      '48744',
      '48746',
      '48757',
      '48759',
      '48760',
      '48767',
      '48768'
    ],
    '037': [
      '48808',
      '48820',
      '48822',
      '48823',
      '48831',
      '48835',
      '48837',
      '48840',
      '48845',
      '48848',
      '48853',
      '48866',
      '48873',
      '48875',
      '48879',
      '48894',
      '48906'
    ],
    '159': [
      '49009',
      '49010',
      '49013',
      '49022',
      '49026',
      '49027',
      '49038',
      '49043',
      '49045',
      '49047',
      '49055',
      '49056',
      '49057',
      '49064',
      '49065',
      '49067',
      '49071',
      '49078',
      '49079',
      '49087',
      '49090',
      '49098'
    ],
    '147': [
      '48001',
      '48002',
      '48006',
      '48014',
      '48022',
      '48023',
      '48027',
      '48028',
      '48032',
      '48039',
      '48040',
      '48041',
      '48049',
      '48054',
      '48059',
      '48060',
      '48063',
      '48064',
      '48074',
      '48079',
      '48097',
      '48416',
      '48444'
    ],
    '083': [
      '49805',
      '49901',
      '49913',
      '49918',
      '49945',
      '49950'
    ],
    '145': [
      '48415',
      '48417',
      '48420',
      '48457',
      '48460',
      '48601',
      '48602',
      '48603',
      '48604',
      '48607',
      '48609',
      '48614',
      '48616',
      '48623',
      '48626',
      '48637',
      '48638',
      '48649',
      '48655',
      '48708',
      '48722',
      '48724',
      '48734',
      '48747',
      '48757',
      '48807',
      '48831',
      '48841'
    ],
    '085': [
      '49304',
      '49309',
      '49402',
      '49623',
      '49642',
      '49644',
      '49655',
      '49656',
      '49677',
      '49688'
    ],
    '143': [
      '48624',
      '48627',
      '48629',
      '48630',
      '48651',
      '48653',
      '48656'
    ],
    '103': [
      '49808',
      '49814',
      '49818',
      '49819',
      '49822',
      '49831',
      '49833',
      '49841',
      '49849',
      '49855',
      '49861',
      '49866',
      '49871',
      '49879',
      '49880',
      '49885'
    ],
    '089': [
      '49621',
      '49630',
      '49636',
      '49653',
      '49654',
      '49664',
      '49670',
      '49674',
      '49682',
      '49684'
    ],
    '045': [
      '48813',
      '48821',
      '48827',
      '48837',
      '48849',
      '48861',
      '48876',
      '48890',
      '48897',
      '48906',
      '48911',
      '48917',
      '49021',
      '49073',
      '49076',
      '49096',
      '49284'
    ],
    '131': [
      '49910',
      '49912',
      '49925',
      '49929',
      '49947',
      '49948',
      '49952',
      '49953',
      '49960',
      '49965',
      '49967',
      '49971'
    ],
    '107': [
      '48850',
      '48886',
      '49305',
      '49307',
      '49310',
      '49320',
      '49332',
      '49336',
      '49337',
      '49338',
      '49340',
      '49342',
      '49346',
      '49631',
      '49639',
      '49679'
    ],
    '155': [
      '48414',
      '48418',
      '48429',
      '48433',
      '48436',
      '48449',
      '48460',
      '48476',
      '48616',
      '48649',
      '48817',
      '48831',
      '48840',
      '48841',
      '48848',
      '48857',
      '48866',
      '48867',
      '48872'
    ],
    '127': [
      '49402',
      '49412',
      '49420',
      '49421',
      '49425',
      '49436',
      '49437',
      '49446',
      '49449',
      '49452',
      '49455',
      '49459'
    ],
    '163': [
      '48101',
      '48111',
      '48120',
      '48122',
      '48124',
      '48125',
      '48126',
      '48127',
      '48128',
      '48134',
      '48135',
      '48138',
      '48141',
      '48146',
      '48150',
      '48152',
      '48154',
      '48164',
      '48167',
      '48168',
      '48170',
      '48173',
      '48174',
      '48180',
      '48183',
      '48184',
      '48185',
      '48186',
      '48187',
      '48188',
      '48192',
      '48193',
      '48195',
      '48201',
      '48202',
      '48203',
      '48204',
      '48205',
      '48206',
      '48207',
      '48208',
      '48209',
      '48210',
      '48211',
      '48212',
      '48213',
      '48214',
      '48215',
      '48216',
      '48217',
      '48218',
      '48219',
      '48221',
      '48223',
      '48224',
      '48225',
      '48226',
      '48227',
      '48228',
      '48229',
      '48230',
      '48233',
      '48234',
      '48235',
      '48236',
      '48238',
      '48239',
      '48240',
      '48242',
      '48243'
    ],
    '137': [
      '49730',
      '49733',
      '49735',
      '49738',
      '49751',
      '49756',
      '49795'
    ],
    '081': [
      '48809',
      '48815',
      '48838',
      '49301',
      '49302',
      '49306',
      '49315',
      '49316',
      '49318',
      '49319',
      '49321',
      '49325',
      '49326',
      '49327',
      '49330',
      '49331',
      '49333',
      '49341',
      '49343',
      '49345',
      '49347',
      '49348',
      '49403',
      '49418',
      '49503',
      '49504',
      '49505',
      '49506',
      '49507',
      '49508',
      '49509',
      '49512',
      '49519',
      '49525',
      '49534',
      '49544',
      '49546',
      '49548'
    ],
    '121': [
      '49303',
      '49318',
      '49330',
      '49403',
      '49404',
      '49412',
      '49415',
      '49425',
      '49437',
      '49440',
      '49441',
      '49442',
      '49444',
      '49445',
      '49448',
      '49451',
      '49456',
      '49457',
      '49461'
    ],
    '057': [
      '48614',
      '48615',
      '48637',
      '48662',
      '48801',
      '48806',
      '48807',
      '48811',
      '48831',
      '48832',
      '48835',
      '48845',
      '48847',
      '48856',
      '48871',
      '48874',
      '48877',
      '48879',
      '48880',
      '48889'
    ],
    '001': [
      '48647',
      '48705',
      '48721',
      '48728',
      '48737',
      '48738',
      '48740',
      '48742',
      '48745',
      '48750',
      '48761',
      '48762',
      '49747'
    ],
    '129': [
      '48610',
      '48624',
      '48635',
      '48654',
      '48661',
      '48739',
      '48756',
      '48761'
    ],
    '017': [
      '48604',
      '48611',
      '48613',
      '48623',
      '48631',
      '48634',
      '48642',
      '48650',
      '48652',
      '48658',
      '48659',
      '48706',
      '48708',
      '48710',
      '48732',
      '48747',
      '48757'
    ],
    '033': [
      '49710',
      '49715',
      '49724',
      '49725',
      '49726',
      '49728',
      '49736',
      '49748',
      '49752',
      '49768',
      '49774',
      '49780',
      '49783',
      '49788',
      '49793'
    ],
    '087': [
      '48003',
      '48014',
      '48371',
      '48412',
      '48416',
      '48421',
      '48423',
      '48428',
      '48435',
      '48438',
      '48440',
      '48444',
      '48446',
      '48453',
      '48455',
      '48461',
      '48462',
      '48464',
      '48727',
      '48744',
      '48760'
    ],
    '115': [
      '48111',
      '48117',
      '48131',
      '48133',
      '48134',
      '48140',
      '48144',
      '48145',
      '48157',
      '48159',
      '48160',
      '48161',
      '48162',
      '48164',
      '48166',
      '48177',
      '48179',
      '48182',
      '48191',
      '49229',
      '49238',
      '49267',
      '49270',
      '49276'
    ],
    '041': [
      '49807',
      '49817',
      '49818',
      '49829',
      '49835',
      '49837',
      '49854',
      '49864',
      '49872',
      '49878',
      '49880',
      '49894',
      '49895'
    ],
    '005': [
      '49010',
      '49026',
      '49055',
      '49056',
      '49070',
      '49078',
      '49080',
      '49090',
      '49315',
      '49316',
      '49323',
      '49328',
      '49333',
      '49335',
      '49344',
      '49348',
      '49406',
      '49408',
      '49419',
      '49423',
      '49426',
      '49434',
      '49450',
      '49453',
      '49464'
    ],
    '051': [
      '48610',
      '48612',
      '48613',
      '48618',
      '48624',
      '48628',
      '48652'
    ],
    '077': [
      '49001',
      '49002',
      '49004',
      '49006',
      '49007',
      '49008',
      '49009',
      '49012',
      '49024',
      '49034',
      '49048',
      '49052',
      '49053',
      '49060',
      '49071',
      '49074',
      '49078',
      '49080',
      '49083',
      '49087',
      '49088',
      '49097'
    ],
    '027': [
      '49031',
      '49042',
      '49045',
      '49047',
      '49061',
      '49067',
      '49093',
      '49095',
      '49099',
      '49102',
      '49111',
      '49112',
      '49120',
      '49130'
    ],
    '069': [
      '48730',
      '48737',
      '48738',
      '48739',
      '48743',
      '48745',
      '48748',
      '48750',
      '48761',
      '48763',
      '48765',
      '48770'
    ],
    '093': [
      '48114',
      '48116',
      '48137',
      '48139',
      '48143',
      '48169',
      '48178',
      '48189',
      '48353',
      '48380',
      '48418',
      '48430',
      '48442',
      '48451',
      '48816',
      '48836',
      '48843',
      '48855',
      '48872',
      '48892',
      '49285'
    ],
    '067': [
      '48809',
      '48815',
      '48834',
      '48837',
      '48838',
      '48845',
      '48846',
      '48849',
      '48851',
      '48860',
      '48861',
      '48865',
      '48870',
      '48873',
      '48875',
      '48881',
      '48890',
      '49325',
      '49331'
    ],
    '105': [
      '49402',
      '49405',
      '49410',
      '49411',
      '49431',
      '49449',
      '49454',
      '49458',
      '49644',
      '49660'
    ],
    '113': [
      '49601',
      '49632',
      '49633',
      '49651',
      '49657',
      '49663',
      '49665',
      '49667'
    ],
    '133': [
      '48632',
      '49338',
      '49631',
      '49639',
      '49655',
      '49665',
      '49677',
      '49679',
      '49688'
    ],
    '071': [
      '49879',
      '49902',
      '49903',
      '49915',
      '49920',
      '49927',
      '49935',
      '49967',
      '54554'
    ],
    '141': [
      '49707',
      '49743',
      '49759',
      '49765',
      '49776',
      '49777',
      '49779'
    ],
    '075': [
      '48158',
      '48827',
      '49201',
      '49202',
      '49203',
      '49224',
      '49230',
      '49233',
      '49234',
      '49237',
      '49240',
      '49241',
      '49245',
      '49246',
      '49249',
      '49250',
      '49251',
      '49252',
      '49254',
      '49259',
      '49261',
      '49263',
      '49264',
      '49269',
      '49272',
      '49277',
      '49283',
      '49284',
      '49285'
    ],
    '047': [
      '49701',
      '49706',
      '49713',
      '49716',
      '49718',
      '49722',
      '49740',
      '49755',
      '49764',
      '49769',
      '49770'
    ],
    '161': [
      '48103',
      '48104',
      '48105',
      '48108',
      '48109',
      '48111',
      '48118',
      '48130',
      '48137',
      '48158',
      '48160',
      '48167',
      '48168',
      '48169',
      '48170',
      '48176',
      '48178',
      '48189',
      '48190',
      '48191',
      '48197',
      '48198',
      '49236',
      '49240',
      '49285',
      '49287'
    ],
    '165': [
      '49601',
      '49618',
      '49620',
      '49625',
      '49633',
      '49638',
      '49649',
      '49663',
      '49668',
      '49688',
      '49689'
    ],
    '061': [
      '49805',
      '49905',
      '49913',
      '49916',
      '49917',
      '49921',
      '49922',
      '49930',
      '49931',
      '49934',
      '49942',
      '49945',
      '49952',
      '49955',
      '49958',
      '49961',
      '49963',
      '49965',
      '49967'
    ],
    '097': [
      '49719',
      '49745',
      '49757',
      '49760',
      '49762',
      '49774',
      '49775',
      '49780',
      '49781',
      '49793',
      '49820',
      '49827',
      '49836',
      '49838',
      '49853'
    ],
    '023': [
      '49011',
      '49028',
      '49030',
      '49036',
      '49040',
      '49082',
      '49089',
      '49092',
      '49094',
      '49245',
      '49252',
      '49255',
      '49274'
    ],
    '043': [
      '49801',
      '49802',
      '49807',
      '49815',
      '49831',
      '49834',
      '49852',
      '49870',
      '49873',
      '49876',
      '49877',
      '49881',
      '49892'
    ],
    '135': [
      '48619',
      '48621',
      '48636',
      '48647',
      '48654',
      '48728',
      '48761',
      '49756'
    ],
    '101': [
      '49613',
      '49614',
      '49619',
      '49625',
      '49626',
      '49634',
      '49638',
      '49644',
      '49645',
      '49660',
      '49668',
      '49675',
      '49683',
      '49689'
    ],
    '065': [
      '48819',
      '48821',
      '48823',
      '48825',
      '48827',
      '48840',
      '48842',
      '48854',
      '48864',
      '48872',
      '48892',
      '48895',
      '48906',
      '48910',
      '48911',
      '48912',
      '48915',
      '48917',
      '48921',
      '48933',
      '49251',
      '49264',
      '49285'
    ],
    '125': [
      '48009',
      '48017',
      '48025',
      '48030',
      '48033',
      '48034',
      '48067',
      '48069',
      '48070',
      '48071',
      '48072',
      '48073',
      '48075',
      '48076',
      '48083',
      '48084',
      '48085',
      '48098',
      '48165',
      '48167',
      '48178',
      '48220',
      '48237',
      '48301',
      '48302',
      '48304',
      '48306',
      '48307',
      '48309',
      '48320',
      '48322',
      '48323',
      '48324',
      '48326',
      '48327',
      '48328',
      '48329',
      '48331',
      '48334',
      '48335',
      '48336',
      '48340',
      '48341',
      '48342',
      '48346',
      '48348',
      '48350',
      '48356',
      '48357',
      '48359',
      '48360',
      '48362',
      '48363',
      '48367',
      '48370',
      '48371',
      '48374',
      '48375',
      '48377',
      '48380',
      '48381',
      '48382',
      '48383',
      '48386',
      '48390',
      '48393',
      '48428',
      '48430',
      '48439',
      '48442',
      '48462'
    ],
    '049': [
      '48411',
      '48415',
      '48418',
      '48420',
      '48421',
      '48423',
      '48429',
      '48430',
      '48433',
      '48436',
      '48437',
      '48438',
      '48439',
      '48442',
      '48449',
      '48451',
      '48457',
      '48458',
      '48462',
      '48463',
      '48464',
      '48473',
      '48502',
      '48503',
      '48504',
      '48505',
      '48506',
      '48507',
      '48509',
      '48519',
      '48529',
      '48532',
      '48551',
      '48553',
      '48554',
      '48746'
    ],
    '019': [
      '49613',
      '49616',
      '49617',
      '49628',
      '49630',
      '49635',
      '49640',
      '49643',
      '49650',
      '49683',
      '49684'
    ],
    '003': [
      '49806',
      '49816',
      '49822',
      '49825',
      '49826',
      '49839',
      '49855',
      '49862',
      '49878',
      '49883',
      '49884',
      '49885',
      '49891',
      '49895'
    ],
    '007': [
      '48762',
      '49707',
      '49744',
      '49746',
      '49747',
      '49753',
      '49766',
      '49776'
    ],
    '013': [
      '49861',
      '49908',
      '49919',
      '49946',
      '49958',
      '49962',
      '49970'
    ],
    '073': [
      '48617',
      '48618',
      '48622',
      '48632',
      '48829',
      '48858',
      '48877',
      '48878',
      '48880',
      '48883',
      '48893',
      '48896',
      '49305',
      '49310',
      '49340'
    ],
    '015': [
      '48849',
      '48897',
      '49017',
      '49021',
      '49046',
      '49050',
      '49058',
      '49060',
      '49073',
      '49080',
      '49083',
      '49316',
      '49325',
      '49333',
      '49344',
      '49348'
    ]
  },
  '31': {
    '167': [
      '68629',
      '68631',
      '68641',
      '68643',
      '68701',
      '68740',
      '68748',
      '68768',
      '68779'
    ],
    '079': [
      '68801',
      '68803',
      '68810',
      '68824',
      '68832',
      '68876',
      '68883',
      '68956'
    ],
    '131': [
      '68301',
      '68307',
      '68317',
      '68324',
      '68329',
      '68344',
      '68346',
      '68347',
      '68382',
      '68410',
      '68413',
      '68417',
      '68418',
      '68446',
      '68448',
      '68454'
    ],
    '067': [
      '68301',
      '68309',
      '68310',
      '68318',
      '68328',
      '68331',
      '68341',
      '68342',
      '68357',
      '68358',
      '68381',
      '68415',
      '68422',
      '68458',
      '68465',
      '68466'
    ],
    '119': [
      '68642',
      '68644',
      '68701',
      '68715',
      '68748',
      '68752',
      '68758',
      '68781'
    ],
    '153': [
      '68005',
      '68028',
      '68046',
      '68059',
      '68069',
      '68113',
      '68123',
      '68128',
      '68133',
      '68136',
      '68138',
      '68147',
      '68157'
    ],
    '055': [
      '68007',
      '68010',
      '68022',
      '68064',
      '68069',
      '68102',
      '68104',
      '68105',
      '68106',
      '68107',
      '68108',
      '68110',
      '68111',
      '68112',
      '68114',
      '68116',
      '68117',
      '68118',
      '68122',
      '68124',
      '68127',
      '68130',
      '68131',
      '68132',
      '68134',
      '68135',
      '68137',
      '68142',
      '68144',
      '68147',
      '68152',
      '68154',
      '68157',
      '68164',
      '68178'
    ],
    '109': [
      '68017',
      '68065',
      '68301',
      '68317',
      '68333',
      '68336',
      '68339',
      '68347',
      '68358',
      '68366',
      '68368',
      '68372',
      '68402',
      '68404',
      '68419',
      '68428',
      '68430',
      '68438',
      '68461',
      '68462',
      '68502',
      '68503',
      '68504',
      '68505',
      '68506',
      '68507',
      '68508',
      '68510',
      '68512',
      '68514',
      '68516',
      '68517',
      '68520',
      '68521',
      '68522',
      '68523',
      '68524',
      '68526',
      '68527',
      '68528',
      '68531',
      '68532'
    ],
    '053': [
      '68025',
      '68031',
      '68044',
      '68057',
      '68063',
      '68072',
      '68621',
      '68633',
      '68649',
      '68664'
    ],
    '063': [
      '69001',
      '69022',
      '69025',
      '69028',
      '69029',
      '69034',
      '69038',
      '69039',
      '69042'
    ],
    '171': [
      '69142',
      '69161',
      '69163',
      '69166'
    ],
    '177': [
      '68002',
      '68007',
      '68008',
      '68023',
      '68029',
      '68031',
      '68034',
      '68044',
      '68064',
      '68068',
      '68112',
      '68122',
      '68142',
      '68152'
    ],
    '059': [
      '68322',
      '68351',
      '68354',
      '68361',
      '68365',
      '68406',
      '68416',
      '68436',
      '68444',
      '68453',
      '68979'
    ],
    '137': [
      '68836',
      '68863',
      '68923',
      '68924',
      '68927',
      '68940',
      '68949',
      '68958',
      '68982'
    ],
    '111': [
      '69025',
      '69029',
      '69039',
      '69101',
      '69120',
      '69123',
      '69132',
      '69138',
      '69143',
      '69151',
      '69163',
      '69165',
      '69169',
      '69170'
    ],
    '117': [
      '69101',
      '69165',
      '69167'
    ],
    '035': [
      '68452',
      '68818',
      '68901',
      '68933',
      '68934',
      '68935',
      '68938',
      '68941',
      '68944',
      '68954',
      '68975',
      '68979',
      '68980'
    ],
    '033': [
      '69131',
      '69141',
      '69149',
      '69156',
      '69162'
    ],
    '037': [
      '68601',
      '68629',
      '68633',
      '68641',
      '68643',
      '68659',
      '68661'
    ],
    '159': [
      '68065',
      '68313',
      '68314',
      '68339',
      '68343',
      '68359',
      '68360',
      '68364',
      '68367',
      '68405',
      '68423',
      '68428',
      '68434',
      '68439',
      '68456'
    ],
    '147': [
      '68337',
      '68345',
      '68355',
      '68376',
      '68431',
      '68433',
      '68437',
      '68442',
      '68457'
    ],
    '145': [
      '69001',
      '69020',
      '69022',
      '69024',
      '69026',
      '69034',
      '69036'
    ],
    '085': [
      '69024',
      '69032',
      '69038',
      '69040',
      '69045',
      '69134',
      '69169'
    ],
    '163': [
      '68815',
      '68817',
      '68844',
      '68852',
      '68853',
      '68869',
      '68871'
    ],
    '121': [
      '68628',
      '68663',
      '68801',
      '68816',
      '68826',
      '68827',
      '68864',
      '68872'
    ],
    '057': [
      '69021',
      '69030',
      '69037',
      '69041',
      '69045',
      '80758'
    ],
    '103': [
      '68753',
      '68759',
      '68778',
      '69201',
      '69210',
      '69220'
    ],
    '095': [
      '68338',
      '68341',
      '68342',
      '68350',
      '68352',
      '68377',
      '68424',
      '68440'
    ],
    '051': [
      '68710',
      '68728',
      '68732',
      '68733',
      '68751',
      '68757',
      '68770',
      '68784',
      '68785',
      '68787'
    ],
    '009': [
      '68813',
      '68821',
      '68833',
      '69157'
    ],
    '173': [
      '68020',
      '68039',
      '68047',
      '68055',
      '68062',
      '68067',
      '68071',
      '68733'
    ],
    '133': [
      '66541',
      '68323',
      '68345',
      '68376',
      '68380',
      '68381',
      '68420',
      '68441',
      '68447'
    ],
    '071': [
      '68823'
    ],
    '141': [
      '68601',
      '68631',
      '68634',
      '68640',
      '68642',
      '68643',
      '68644',
      '68647',
      '68653',
      '68660',
      '68758'
    ],
    '047': [
      '68834',
      '68836',
      '68850',
      '68863',
      '68878',
      '68937',
      '69028',
      '69029',
      '69130',
      '69138',
      '69171'
    ],
    '023': [
      '68001',
      '68014',
      '68036',
      '68601',
      '68624',
      '68626',
      '68632',
      '68635',
      '68658',
      '68662',
      '68667',
      '68669'
    ],
    '043': [
      '68030',
      '68731',
      '68733',
      '68741',
      '68743',
      '68770',
      '68776',
      '68785'
    ],
    '081': [
      '68371',
      '68628',
      '68654',
      '68818',
      '68832',
      '68841',
      '68843',
      '68846',
      '68854',
      '68865',
      '68944',
      '68980'
    ],
    '013': [
      '69301',
      '69348'
    ],
    '003': [
      '68636',
      '68720',
      '68726',
      '68729',
      '68735',
      '68756',
      '68761',
      '68764',
      '68769',
      '68773',
      '68781'
    ],
    '123': [
      '69125',
      '69131',
      '69148',
      '69301',
      '69331',
      '69334',
      '69336'
    ],
    '099': [
      '68840',
      '68845',
      '68847',
      '68924',
      '68945',
      '68956',
      '68959',
      '68982'
    ],
    '183': [
      '68622',
      '68636',
      '68637',
      '68665',
      '68735'
    ],
    '029': [
      '69023',
      '69027',
      '69030',
      '69033',
      '69045',
      '69168'
    ],
    '021': [
      '51557',
      '68019',
      '68020',
      '68029',
      '68038',
      '68045',
      '68061'
    ],
    '181': [
      '68928',
      '68930',
      '68932',
      '68942',
      '68952',
      '68957',
      '68970'
    ],
    '031': [
      '69135',
      '69152',
      '69161',
      '69166',
      '69201',
      '69211',
      '69212',
      '69216',
      '69218',
      '69219',
      '69220',
      '69221',
      '69333',
      '69343',
      '69350',
      '69366'
    ],
    '091': [
      '69152'
    ],
    '139': [
      '68701',
      '68747',
      '68752',
      '68765',
      '68767',
      '68769',
      '68771'
    ],
    '025': [
      '68003',
      '68016',
      '68037',
      '68048',
      '68058',
      '68304',
      '68307',
      '68347',
      '68349',
      '68366',
      '68403',
      '68407',
      '68409',
      '68413',
      '68455',
      '68462',
      '68463'
    ],
    '169': [
      '68303',
      '68315',
      '68322',
      '68325',
      '68326',
      '68327',
      '68335',
      '68340',
      '68362',
      '68370',
      '68375'
    ],
    '151': [
      '68333',
      '68341',
      '68343',
      '68359',
      '68406',
      '68445',
      '68453',
      '68464',
      '68465'
    ],
    '039': [
      '68004',
      '68045',
      '68047',
      '68633',
      '68641',
      '68716',
      '68788',
      '68791'
    ],
    '149': [
      '68714',
      '68759',
      '68823',
      '69217'
    ],
    '011': [
      '68620',
      '68627',
      '68652',
      '68655',
      '68660',
      '68758'
    ],
    '041': [
      '68813',
      '68814',
      '68815',
      '68822',
      '68825',
      '68828',
      '68834',
      '68855',
      '68856',
      '68860',
      '68866',
      '68874',
      '68878',
      '68881',
      '69120',
      '69138'
    ],
    '027': [
      '57078',
      '68717',
      '68727',
      '68730',
      '68736',
      '68739',
      '68745',
      '68749',
      '68757',
      '68771',
      '68774',
      '68786',
      '68792'
    ],
    '179': [
      '68047',
      '68723',
      '68740',
      '68745',
      '68771',
      '68784',
      '68787',
      '68790',
      '68791'
    ],
    '165': [
      '69339',
      '69346',
      '69348',
      '69354',
      '69357',
      '69358',
      '69361'
    ],
    '143': [
      '68316',
      '68367',
      '68601',
      '68628',
      '68651',
      '68654',
      '68662',
      '68663',
      '68666'
    ],
    '175': [
      '68815',
      '68823',
      '68828',
      '68837',
      '68859',
      '68862'
    ],
    '185': [
      '68316',
      '68319',
      '68351',
      '68354',
      '68367',
      '68371',
      '68401',
      '68460',
      '68467',
      '68654'
    ],
    '089': [
      '68711',
      '68713',
      '68725',
      '68726',
      '68734',
      '68735',
      '68742',
      '68746',
      '68763',
      '68764',
      '68766',
      '68777',
      '68780'
    ],
    '045': [
      '69337',
      '69339',
      '69347',
      '69348',
      '69354',
      '69367'
    ],
    '107': [
      '68718',
      '68724',
      '68729',
      '68730',
      '68760',
      '68764',
      '68783',
      '68786',
      '68789'
    ],
    '155': [
      '68003',
      '68015',
      '68017',
      '68018',
      '68025',
      '68033',
      '68036',
      '68040',
      '68041',
      '68042',
      '68050',
      '68064',
      '68065',
      '68066',
      '68070',
      '68073',
      '68648'
    ],
    '127': [
      '68305',
      '68320',
      '68321',
      '68376',
      '68378',
      '68379',
      '68414',
      '68421',
      '68442'
    ],
    '161': [
      '69301',
      '69335',
      '69337',
      '69340',
      '69343',
      '69347',
      '69351',
      '69360',
      '69365'
    ],
    '065': [
      '68922',
      '68926',
      '68936',
      '68946',
      '68948',
      '68967',
      '68977',
      '69022',
      '69046'
    ],
    '097': [
      '68329',
      '68332',
      '68348',
      '68378',
      '68443',
      '68447',
      '68450'
    ],
    '125': [
      '68623',
      '68638',
      '68640',
      '68660',
      '68663',
      '68864'
    ],
    '001': [
      '68883',
      '68901',
      '68925',
      '68928',
      '68930',
      '68932',
      '68941',
      '68950',
      '68955',
      '68956',
      '68973',
      '68980'
    ],
    '129': [
      '68335',
      '68934',
      '68935',
      '68942',
      '68943',
      '68957',
      '68961',
      '68964',
      '68974',
      '68978'
    ],
    '017': [
      '69210',
      '69214',
      '69217'
    ],
    '087': [
      '69024',
      '69040',
      '69043',
      '69044'
    ],
    '115': [
      '68823',
      '68879'
    ],
    '005': [
      '69121',
      '69146',
      '69350',
      '69366'
    ],
    '077': [
      '68665',
      '68842',
      '68875',
      '68882'
    ],
    '157': [
      '69334',
      '69341',
      '69352',
      '69353',
      '69355',
      '69356',
      '69357',
      '69358',
      '69361'
    ],
    '069': [
      '69147',
      '69148',
      '69149',
      '69154',
      '69333',
      '69351'
    ],
    '093': [
      '68817',
      '68820',
      '68824',
      '68831',
      '68835',
      '68838',
      '68864',
      '68872',
      '68873',
      '68882'
    ],
    '105': [
      '69128',
      '69133',
      '69145'
    ],
    '113': [
      '68833',
      '69120',
      '69163'
    ],
    '075': [
      '69333',
      '69350',
      '69366'
    ],
    '083': [
      '68920',
      '68949',
      '68966',
      '68967',
      '68969',
      '68971',
      '68977',
      '68982'
    ],
    '135': [
      '69134',
      '69140',
      '69150',
      '69168',
      '69169'
    ],
    '101': [
      '69122',
      '69127',
      '69144',
      '69146',
      '69147',
      '69153',
      '69155'
    ],
    '061': [
      '68929',
      '68932',
      '68939',
      '68947',
      '68960',
      '68972',
      '68981',
      '68982'
    ],
    '049': [
      '69122',
      '69129'
    ],
    '019': [
      '68812',
      '68836',
      '68840',
      '68844',
      '68845',
      '68847',
      '68849',
      '68858',
      '68861',
      '68866',
      '68869',
      '68870',
      '68876'
    ],
    '007': [
      '69128',
      '69145',
      '69156',
      '69334',
      '69341',
      '69345',
      '82082'
    ],
    '073': [
      '68850',
      '68922',
      '68927',
      '68936',
      '68937',
      '68948',
      '68976',
      '69028'
    ],
    '015': [
      '68719',
      '68722',
      '68746',
      '68755',
      '68777'
    ]
  },
  '16': {
    '079': [
      '83802',
      '83808',
      '83810',
      '83812',
      '83830',
      '83837',
      '83839',
      '83846',
      '83849',
      '83850',
      '83861',
      '83867',
      '83868',
      '83873',
      '83874'
    ],
    '009': [
      '83824',
      '83830',
      '83833',
      '83851',
      '83861',
      '83866',
      '83870'
    ],
    '055': [
      '83801',
      '83803',
      '83810',
      '83814',
      '83815',
      '83833',
      '83835',
      '83842',
      '83854',
      '83858',
      '83861',
      '83869',
      '83876'
    ],
    '029': [
      '83111',
      '83120',
      '83217',
      '83241',
      '83254',
      '83276',
      '83285'
    ],
    '021': [
      '83805',
      '83826',
      '83845',
      '83847',
      '83864'
    ],
    '053': [
      '83301',
      '83325',
      '83335',
      '83338',
      '83347'
    ],
    '031': [
      '83211',
      '83311',
      '83312',
      '83318',
      '83323',
      '83342',
      '83344',
      '83346',
      '83350'
    ],
    '063': [
      '83324',
      '83338',
      '83347',
      '83349',
      '83352'
    ],
    '025': [
      '83322',
      '83327',
      '83337',
      '83352'
    ],
    '059': [
      '83226',
      '83235',
      '83253',
      '83462',
      '83463',
      '83464',
      '83465',
      '83466',
      '83467',
      '83468',
      '83469'
    ],
    '039': [
      '83337',
      '83601',
      '83623',
      '83624',
      '83627',
      '83633',
      '83647',
      '83648',
      '83716'
    ],
    '011': [
      '83202',
      '83203',
      '83210',
      '83215',
      '83218',
      '83221',
      '83236',
      '83262',
      '83274',
      '83277',
      '83402'
    ],
    '035': [
      '83520',
      '83537',
      '83541',
      '83544',
      '83546',
      '83553',
      '83823',
      '83827'
    ],
    '027': [
      '83605',
      '83607',
      '83626',
      '83634',
      '83641',
      '83644',
      '83651',
      '83656',
      '83660',
      '83669',
      '83676',
      '83686',
      '83687'
    ],
    '037': [
      '83226',
      '83227',
      '83235',
      '83251',
      '83253',
      '83255',
      '83278'
    ],
    '085': [
      '83611',
      '83615',
      '83638',
      '83657',
      '83677'
    ],
    '045': [
      '83607',
      '83616',
      '83617',
      '83629',
      '83636',
      '83657',
      '83670'
    ],
    '065': [
      '83434',
      '83436',
      '83440',
      '83443',
      '83445',
      '83448',
      '83451',
      '83460'
    ],
    '057': [
      '83535',
      '83537',
      '83806',
      '83823',
      '83832',
      '83834',
      '83843',
      '83844',
      '83855',
      '83857',
      '83871',
      '83872',
      '99128'
    ],
    '001': [
      '83616',
      '83634',
      '83641',
      '83642',
      '83646',
      '83669',
      '83687',
      '83702',
      '83703',
      '83704',
      '83705',
      '83706',
      '83709',
      '83712',
      '83713',
      '83714',
      '83716'
    ],
    '017': [
      '83801',
      '83803',
      '83804',
      '83809',
      '83811',
      '83813',
      '83821',
      '83822',
      '83825',
      '83836',
      '83840',
      '83841',
      '83848',
      '83852',
      '83856',
      '83860',
      '83864',
      '83869'
    ],
    '087': [
      '83610',
      '83645',
      '83661',
      '83672'
    ],
    '041': [
      '83228',
      '83232',
      '83237',
      '83241',
      '83263',
      '83283',
      '83286'
    ],
    '005': [
      '83201',
      '83202',
      '83204',
      '83209',
      '83214',
      '83234',
      '83245',
      '83246',
      '83250',
      '83281'
    ],
    '051': [
      '83401',
      '83402',
      '83425',
      '83431',
      '83434',
      '83435',
      '83442',
      '83443',
      '83444',
      '83450'
    ],
    '077': [
      '83204',
      '83211',
      '83212',
      '83271'
    ],
    '033': [
      '83423',
      '83435',
      '83446',
      '83464'
    ],
    '069': [
      '83501',
      '83524',
      '83535',
      '83537',
      '83540',
      '83541',
      '83545',
      '83548',
      '83555',
      '83832'
    ],
    '067': [
      '83318',
      '83336',
      '83347',
      '83350'
    ],
    '071': [
      '83212',
      '83243',
      '83252',
      '83342'
    ],
    '075': [
      '83607',
      '83619',
      '83655',
      '83660',
      '83661',
      '97913'
    ],
    '047': [
      '83314',
      '83316',
      '83330',
      '83332',
      '83338',
      '83355'
    ],
    '083': [
      '83301',
      '83302',
      '83314',
      '83316',
      '83321',
      '83328',
      '83332',
      '83334',
      '83341',
      '83344'
    ],
    '023': [
      '83213',
      '83244',
      '83255'
    ],
    '043': [
      '83420',
      '83421',
      '83429',
      '83433',
      '83436',
      '83440',
      '83445',
      '83448',
      '83451'
    ],
    '081': [
      '83422',
      '83424',
      '83436',
      '83452',
      '83455'
    ],
    '061': [
      '83523',
      '83524',
      '83536',
      '83543',
      '83544',
      '83548',
      '83555'
    ],
    '049': [
      '59847',
      '83522',
      '83525',
      '83526',
      '83530',
      '83533',
      '83536',
      '83539',
      '83542',
      '83547',
      '83549',
      '83552',
      '83554',
      '83638',
      '83654',
      '83671'
    ],
    '019': [
      '83236',
      '83274',
      '83285',
      '83401',
      '83402',
      '83404',
      '83406',
      '83427',
      '83428',
      '83443',
      '83449',
      '83454'
    ],
    '015': [
      '83602',
      '83622',
      '83629',
      '83631',
      '83637',
      '83666',
      '83670',
      '83702',
      '83716'
    ],
    '007': [
      '83220',
      '83223',
      '83233',
      '83238',
      '83239',
      '83254',
      '83261',
      '83272',
      '83276',
      '83287'
    ],
    '013': [
      '83313',
      '83320',
      '83333',
      '83340',
      '83348',
      '83350',
      '83352',
      '83353',
      '83354'
    ],
    '073': [
      '83302',
      '83316',
      '83604',
      '83624',
      '83627',
      '83628',
      '83639',
      '83641',
      '83650',
      '89832',
      '97910'
    ],
    '003': [
      '83612',
      '83632',
      '83638',
      '83643',
      '83654'
    ]
  },
  '44': {
    '005': [
      '02835',
      '02837',
      '02840',
      '02841',
      '02842',
      '02871',
      '02872',
      '02878'
    ],
    '007': [
      '02802',
      '02814',
      '02815',
      '02816',
      '02825',
      '02826',
      '02828',
      '02830',
      '02831',
      '02838',
      '02839',
      '02857',
      '02858',
      '02859',
      '02860',
      '02861',
      '02863',
      '02864',
      '02865',
      '02876',
      '02895',
      '02896',
      '02903',
      '02904',
      '02905',
      '02906',
      '02907',
      '02908',
      '02909',
      '02910',
      '02911',
      '02912',
      '02914',
      '02915',
      '02916',
      '02917',
      '02919',
      '02920',
      '02921'
    ],
    '001': [
      '02806',
      '02809',
      '02885'
    ],
    '009': [
      '02804',
      '02807',
      '02808',
      '02812',
      '02813',
      '02822',
      '02832',
      '02833',
      '02836',
      '02852',
      '02873',
      '02874',
      '02875',
      '02879',
      '02881',
      '02882',
      '02891',
      '02892',
      '02894',
      '02898'
    ],
    '003': [
      '02816',
      '02817',
      '02818',
      '02827',
      '02831',
      '02886',
      '02888',
      '02889',
      '02893'
    ]
  },
  '29': {
    '167': [
      '65601',
      '65613',
      '65617',
      '65622',
      '65640',
      '65648',
      '65649',
      '65650',
      '65663',
      '65674',
      '65685',
      '65710',
      '65725',
      '65727',
      '65767',
      '65770',
      '65781'
    ],
    '079': [
      '64635',
      '64641',
      '64642',
      '64648',
      '64652',
      '64679',
      '64683'
    ],
    '131': [
      '65017',
      '65026',
      '65032',
      '65040',
      '65047',
      '65049',
      '65058',
      '65064',
      '65065',
      '65072',
      '65075',
      '65079',
      '65082',
      '65083',
      '65452',
      '65459',
      '65486'
    ],
    '067': [
      '65608',
      '65637',
      '65638',
      '65652',
      '65689',
      '65702',
      '65704',
      '65711',
      '65717',
      '65720',
      '65746',
      '65755',
      '65768',
      '65775',
      '65793'
    ],
    '119': [
      '64831',
      '64843',
      '64847',
      '64850',
      '64854',
      '64856',
      '64861',
      '64863',
      '64865',
      '64867',
      '65647',
      '65730',
      '65745',
      '65772'
    ],
    '153': [
      '65608',
      '65609',
      '65618',
      '65626',
      '65637',
      '65638',
      '65655',
      '65676',
      '65715',
      '65729',
      '65733',
      '65755',
      '65760',
      '65761',
      '65762',
      '65766',
      '65773',
      '65784',
      '65790'
    ],
    '055': [
      '63080',
      '65066',
      '65441',
      '65446',
      '65449',
      '65453',
      '65456',
      '65535',
      '65559',
      '65560',
      '65565',
      '65586'
    ],
    '109': [
      '64748',
      '64848',
      '64862',
      '64873',
      '65604',
      '65605',
      '65610',
      '65612',
      '65646',
      '65654',
      '65664',
      '65682',
      '65705',
      '65707',
      '65708',
      '65712',
      '65723',
      '65738',
      '65752',
      '65756',
      '65769'
    ],
    '145': [
      '64804',
      '64840',
      '64842',
      '64843',
      '64844',
      '64850',
      '64858',
      '64861',
      '64862',
      '64865',
      '64866',
      '64867',
      '64873',
      '65723'
    ],
    '053': [
      '65018',
      '65025',
      '65068',
      '65081',
      '65233',
      '65237',
      '65276',
      '65287',
      '65322',
      '65347',
      '65348',
      '65354'
    ],
    '063': [
      '64422',
      '64429',
      '64430',
      '64463',
      '64469',
      '64474',
      '64490',
      '64494',
      '64497',
      '64670'
    ],
    '171': [
      '63535',
      '63544',
      '63551',
      '63559',
      '63560',
      '63565',
      '63567',
      '64655',
      '64667',
      '64672'
    ],
    '085': [
      '65355',
      '65634',
      '65650',
      '65668',
      '65674',
      '65724',
      '65732',
      '65735',
      '65767',
      '65774',
      '65779'
    ],
    '229': [
      '65470',
      '65543',
      '65660',
      '65662',
      '65667',
      '65702',
      '65704',
      '65711',
      '65713',
      '65717',
      '65746'
    ],
    '059': [
      '65463',
      '65536',
      '65590',
      '65622',
      '65632',
      '65644',
      '65648',
      '65685',
      '65722',
      '65764',
      '65767',
      '65783',
      '65786'
    ],
    '143': [
      '63801',
      '63828',
      '63833',
      '63845',
      '63848',
      '63860',
      '63862',
      '63863',
      '63866',
      '63867',
      '63868',
      '63869',
      '63870',
      '63873',
      '63874',
      '63878'
    ],
    '111': [
      '63435',
      '63438',
      '63440',
      '63446',
      '63447',
      '63448',
      '63452',
      '63454',
      '63457',
      '63471',
      '63473'
    ],
    '209': [
      '65610',
      '65611',
      '65616',
      '65624',
      '65631',
      '65633',
      '65656',
      '65669',
      '65681',
      '65686',
      '65705',
      '65714',
      '65728',
      '65737',
      '65747'
    ],
    '117': [
      '64601',
      '64624',
      '64625',
      '64635',
      '64638',
      '64643',
      '64648',
      '64656',
      '64664',
      '64683',
      '64686',
      '64688'
    ],
    '035': [
      '63935',
      '63937',
      '63941',
      '63943',
      '63965'
    ],
    '033': [
      '64035',
      '64622',
      '64623',
      '64624',
      '64633',
      '64638',
      '64639',
      '64643',
      '64668',
      '64682'
    ],
    '186': [
      '63028',
      '63036',
      '63627',
      '63628',
      '63640',
      '63645',
      '63670',
      '63673'
    ],
    '037': [
      '64012',
      '64034',
      '64061',
      '64078',
      '64080',
      '64082',
      '64083',
      '64090',
      '64147',
      '64701',
      '64725',
      '64734',
      '64739',
      '64742',
      '64743',
      '64746',
      '64747'
    ],
    '159': [
      '65301',
      '65332',
      '65333',
      '65334',
      '65335',
      '65336',
      '65337',
      '65340',
      '65345',
      '65347',
      '65350',
      '65351',
      '65360'
    ],
    '147': [
      '64423',
      '64427',
      '64428',
      '64431',
      '64432',
      '64433',
      '64434',
      '64445',
      '64455',
      '64457',
      '64461',
      '64468',
      '64475',
      '64476',
      '64479',
      '64486',
      '64487',
      '64489'
    ],
    '205': [
      '63434',
      '63437',
      '63439',
      '63440',
      '63443',
      '63450',
      '63451',
      '63468',
      '63469'
    ],
    '177': [
      '64017',
      '64024',
      '64035',
      '64036',
      '64062',
      '64077',
      '64084',
      '64085',
      '64624',
      '64637',
      '64668',
      '64671'
    ],
    '163': [
      '63330',
      '63334',
      '63336',
      '63339',
      '63343',
      '63344',
      '63353',
      '63359',
      '63382',
      '63433',
      '63441',
      '63459'
    ],
    '175': [
      '65230',
      '65239',
      '65243',
      '65244',
      '65257',
      '65259',
      '65260',
      '65263',
      '65270',
      '65278'
    ],
    '179': [
      '63620',
      '63625',
      '63629',
      '63633',
      '63638',
      '63654',
      '63656',
      '63665',
      '63666',
      '63957',
      '63965',
      '65440'
    ],
    '057': [
      '64748',
      '64756',
      '64759',
      '65601',
      '65603',
      '65604',
      '65635',
      '65646',
      '65661',
      '65682',
      '65752',
      '65770',
      '65785'
    ],
    '203': [
      '63629',
      '63638',
      '65438',
      '65466',
      '65479',
      '65548',
      '65560',
      '65571',
      '65588'
    ],
    '103': [
      '63446',
      '63458',
      '63460',
      '63464',
      '63469',
      '63531',
      '63533',
      '63537',
      '63547',
      '63563'
    ],
    '225': [
      '65632',
      '65644',
      '65648',
      '65652',
      '65706',
      '65713',
      '65742',
      '65746',
      '65757'
    ],
    '095': [
      '64014',
      '64015',
      '64016',
      '64029',
      '64030',
      '64034',
      '64050',
      '64052',
      '64053',
      '64054',
      '64055',
      '64056',
      '64057',
      '64058',
      '64063',
      '64064',
      '64065',
      '64066',
      '64070',
      '64075',
      '64080',
      '64081',
      '64082',
      '64086',
      '64088',
      '64101',
      '64102',
      '64105',
      '64106',
      '64108',
      '64109',
      '64110',
      '64111',
      '64112',
      '64113',
      '64114',
      '64120',
      '64123',
      '64124',
      '64125',
      '64126',
      '64127',
      '64128',
      '64129',
      '64130',
      '64131',
      '64132',
      '64133',
      '64134',
      '64136',
      '64137',
      '64138',
      '64139',
      '64145',
      '64146',
      '64147',
      '64149',
      '64192'
    ],
    '215': [
      '65444',
      '65464',
      '65468',
      '65479',
      '65483',
      '65484',
      '65542',
      '65548',
      '65552',
      '65555',
      '65557',
      '65564',
      '65570',
      '65571',
      '65589',
      '65660',
      '65689',
      '65711',
      '65793'
    ],
    '135': [
      '65011',
      '65018',
      '65023',
      '65025',
      '65034',
      '65046',
      '65050',
      '65074',
      '65081',
      '65084',
      '65287'
    ],
    '009': [
      '64842',
      '64874',
      '65605',
      '65623',
      '65624',
      '65625',
      '65633',
      '65641',
      '65647',
      '65656',
      '65658',
      '65708',
      '65723',
      '65734',
      '65745',
      '65747',
      '65769',
      '65772'
    ],
    '173': [
      '63352',
      '63382',
      '63401',
      '63436',
      '63441',
      '63456',
      '63459',
      '63462',
      '63467'
    ],
    '133': [
      '63820',
      '63823',
      '63834',
      '63845',
      '63882'
    ],
    '151': [
      '65001',
      '65013',
      '65014',
      '65016',
      '65024',
      '65035',
      '65048',
      '65051',
      '65054',
      '65058',
      '65061',
      '65062',
      '65076',
      '65085',
      '65101'
    ],
    '071': [
      '63013',
      '63014',
      '63015',
      '63037',
      '63039',
      '63041',
      '63055',
      '63056',
      '63060',
      '63061',
      '63068',
      '63069',
      '63072',
      '63073',
      '63077',
      '63079',
      '63080',
      '63084',
      '63089',
      '63090',
      '63091',
      '65441'
    ],
    '141': [
      '65011',
      '65026',
      '65034',
      '65037',
      '65072',
      '65078',
      '65079',
      '65081',
      '65084',
      '65329',
      '65345',
      '65348',
      '65350',
      '65354'
    ],
    '047': [
      '64024',
      '64048',
      '64060',
      '64062',
      '64068',
      '64072',
      '64077',
      '64089',
      '64116',
      '64117',
      '64118',
      '64119',
      '64155',
      '64156',
      '64157',
      '64158',
      '64161',
      '64165',
      '64166',
      '64167',
      '64492'
    ],
    '197': [
      '63535',
      '63536',
      '63541',
      '63546',
      '63548',
      '63561'
    ],
    '195': [
      '64020',
      '65320',
      '65321',
      '65327',
      '65330',
      '65333',
      '65339',
      '65340',
      '65344',
      '65347',
      '65349',
      '65351'
    ],
    '213': [
      '65608',
      '65611',
      '65614',
      '65616',
      '65627',
      '65653',
      '65672',
      '65679',
      '65680',
      '65731',
      '65733',
      '65737',
      '65739',
      '65740',
      '65744',
      '65759',
      '65761',
      '65771',
      '72644'
    ],
    '023': [
      '63901',
      '63902',
      '63932',
      '63937',
      '63940',
      '63945',
      '63953',
      '63954',
      '63961',
      '63962',
      '63966',
      '63967'
    ],
    '043': [
      '65610',
      '65614',
      '65619',
      '65620',
      '65629',
      '65630',
      '65631',
      '65652',
      '65653',
      '65656',
      '65657',
      '65669',
      '65714',
      '65720',
      '65721',
      '65737',
      '65738',
      '65742',
      '65753',
      '65754'
    ],
    '065': [
      '63629',
      '65401',
      '65440',
      '65462',
      '65501',
      '65541',
      '65542',
      '65560'
    ],
    '139': [
      '63333',
      '63350',
      '63351',
      '63359',
      '63361',
      '63363',
      '63381',
      '63384',
      '65041',
      '65069'
    ],
    '013': [
      '64720',
      '64722',
      '64723',
      '64724',
      '64725',
      '64730',
      '64742',
      '64745',
      '64752',
      '64770',
      '64779',
      '64780',
      '64788'
    ],
    '003': [
      '64421',
      '64427',
      '64430',
      '64436',
      '64449',
      '64455',
      '64457',
      '64459',
      '64463',
      '64480',
      '64483',
      '64485',
      '64494',
      '64505',
      '64506'
    ],
    '123': [
      '63620',
      '63621',
      '63636',
      '63645',
      '63650',
      '63655'
    ],
    '099': [
      '63010',
      '63012',
      '63015',
      '63016',
      '63019',
      '63020',
      '63023',
      '63025',
      '63026',
      '63028',
      '63041',
      '63047',
      '63048',
      '63049',
      '63050',
      '63051',
      '63052',
      '63053',
      '63057',
      '63069',
      '63070',
      '63072',
      '63087',
      '63627',
      '63628'
    ],
    '183': [
      '63301',
      '63303',
      '63304',
      '63332',
      '63341',
      '63348',
      '63357',
      '63366',
      '63367',
      '63368',
      '63373',
      '63376',
      '63385',
      '63386'
    ],
    '029': [
      '65017',
      '65020',
      '65037',
      '65047',
      '65049',
      '65052',
      '65065',
      '65079',
      '65324',
      '65326',
      '65463',
      '65536',
      '65556',
      '65567',
      '65591',
      '65634',
      '65786',
      '65787'
    ],
    '021': [
      '64401',
      '64439',
      '64440',
      '64443',
      '64444',
      '64448',
      '64454',
      '64484',
      '64490',
      '64501',
      '64503',
      '64504',
      '64505',
      '64506',
      '64507'
    ],
    '181': [
      '63901',
      '63935',
      '63939',
      '63942',
      '63943',
      '63953',
      '63954',
      '63955'
    ],
    '031': [
      '63701',
      '63703',
      '63730',
      '63732',
      '63739',
      '63740',
      '63743',
      '63744',
      '63745',
      '63747',
      '63755',
      '63764',
      '63766',
      '63769',
      '63770',
      '63771',
      '63775',
      '63780',
      '63785'
    ],
    '091': [
      '65548',
      '65609',
      '65626',
      '65637',
      '65689',
      '65692',
      '65775',
      '65777',
      '65788',
      '65789',
      '65790',
      '65793'
    ],
    '169': [
      '65452',
      '65457',
      '65459',
      '65461',
      '65473',
      '65534',
      '65550',
      '65552',
      '65556',
      '65583',
      '65584'
    ],
    '025': [
      '64429',
      '64465',
      '64624',
      '64625',
      '64637',
      '64644',
      '64649',
      '64650',
      '64671'
    ],
    '121': [
      '63431',
      '63437',
      '63530',
      '63532',
      '63534',
      '63538',
      '63539',
      '63549',
      '63552',
      '63557',
      '63558',
      '64631',
      '65247',
      '65260'
    ],
    '201': [
      '63735',
      '63736',
      '63740',
      '63742',
      '63758',
      '63767',
      '63771',
      '63774',
      '63780',
      '63784',
      '63801',
      '63823',
      '63824',
      '63834'
    ],
    '039': [
      '64744',
      '64756',
      '64784',
      '65601',
      '65635',
      '65640',
      '65649',
      '65674',
      '65785'
    ],
    '149': [
      '63935',
      '63941',
      '63942',
      '65438',
      '65588',
      '65606',
      '65690',
      '65692',
      '65775',
      '65778',
      '65788',
      '65791'
    ],
    '011': [
      '64748',
      '64755',
      '64759',
      '64762',
      '64769',
      '64784',
      '64832',
      '64855'
    ],
    '217': [
      '64728',
      '64741',
      '64744',
      '64750',
      '64752',
      '64765',
      '64767',
      '64771',
      '64772',
      '64778',
      '64779',
      '64783',
      '64784',
      '64790'
    ],
    '041': [
      '63558',
      '64628',
      '64658',
      '64660',
      '64676',
      '64681',
      '65236',
      '65246',
      '65254',
      '65261',
      '65281',
      '65286'
    ],
    '027': [
      '63361',
      '63384',
      '63388',
      '65039',
      '65043',
      '65059',
      '65063',
      '65067',
      '65069',
      '65077',
      '65080',
      '65101',
      '65201',
      '65202',
      '65231',
      '65240',
      '65251',
      '65262',
      '65264'
    ],
    '199': [
      '52542',
      '52573',
      '63432',
      '63474',
      '63531',
      '63536',
      '63543',
      '63555',
      '63563'
    ],
    '137': [
      '63437',
      '63443',
      '63450',
      '63456',
      '63462',
      '63468',
      '65240',
      '65243',
      '65258',
      '65260',
      '65263',
      '65265',
      '65270',
      '65275',
      '65282',
      '65283'
    ],
    '223': [
      '63636',
      '63655',
      '63763',
      '63934',
      '63937',
      '63944',
      '63951',
      '63952',
      '63956',
      '63957',
      '63964',
      '63966',
      '63967'
    ],
    '185': [
      '64724',
      '64738',
      '64740',
      '64744',
      '64763',
      '64770',
      '64776',
      '64780',
      '64781',
      '64783',
      '65674',
      '65774',
      '65785'
    ],
    '089': [
      '65230',
      '65243',
      '65248',
      '65250',
      '65254',
      '65256',
      '65257',
      '65274',
      '65279'
    ],
    '045': [
      '52626',
      '63430',
      '63432',
      '63435',
      '63445',
      '63453',
      '63465',
      '63472',
      '63473',
      '63474'
    ],
    '107': [
      '64001',
      '64011',
      '64017',
      '64020',
      '64021',
      '64022',
      '64037',
      '64067',
      '64071',
      '64074',
      '64075',
      '64076',
      '64093',
      '64096',
      '64097',
      '65321',
      '65327'
    ],
    '155': [
      '63826',
      '63827',
      '63830',
      '63839',
      '63848',
      '63849',
      '63851',
      '63852',
      '63853',
      '63873',
      '63877',
      '63879'
    ],
    '127': [
      '63401',
      '63438',
      '63439',
      '63440',
      '63443',
      '63454',
      '63456',
      '63461',
      '63463',
      '63471'
    ],
    '161': [
      '65401',
      '65436',
      '65449',
      '65459',
      '65461',
      '65462',
      '65529',
      '65550',
      '65559',
      '65560'
    ],
    '081': [
      '64424',
      '64426',
      '64442',
      '64456',
      '64458',
      '64467',
      '64471',
      '64481',
      '64632',
      '64636',
      '64642',
      '64657',
      '64670'
    ],
    '097': [
      '64748',
      '64755',
      '64801',
      '64804',
      '64830',
      '64832',
      '64833',
      '64834',
      '64835',
      '64836',
      '64840',
      '64841',
      '64848',
      '64849',
      '64855',
      '64857',
      '64859',
      '64862',
      '64870'
    ],
    '125': [
      '65001',
      '65013',
      '65014',
      '65058',
      '65401',
      '65443',
      '65459',
      '65559',
      '65580',
      '65582'
    ],
    '001': [
      '63501',
      '63533',
      '63540',
      '63544',
      '63546',
      '63547',
      '63549',
      '63557',
      '63559'
    ],
    '129': [
      '64632',
      '64645',
      '64661',
      '64667',
      '64673',
      '64679'
    ],
    '017': [
      '63655',
      '63662',
      '63730',
      '63750',
      '63751',
      '63760',
      '63764',
      '63766',
      '63781',
      '63782',
      '63785',
      '63787',
      '63960'
    ],
    '211': [
      '63544',
      '63545',
      '63556',
      '63560',
      '63566',
      '64630',
      '64641',
      '64645',
      '64646',
      '64667'
    ],
    '087': [
      '64437',
      '64446',
      '64451',
      '64466',
      '64470',
      '64473',
      '64487'
    ],
    '115': [
      '63557',
      '63566',
      '64628',
      '64630',
      '64631',
      '64635',
      '64646',
      '64651',
      '64653',
      '64658',
      '64659',
      '64674',
      '64688'
    ],
    '005': [
      '51640',
      '64428',
      '64446',
      '64482',
      '64487',
      '64491',
      '64496',
      '64498'
    ],
    '189': [
      '63005',
      '63011',
      '63017',
      '63021',
      '63025',
      '63026',
      '63031',
      '63033',
      '63034',
      '63038',
      '63040',
      '63042',
      '63043',
      '63044',
      '63045',
      '63049',
      '63069',
      '63074',
      '63088',
      '63105',
      '63114',
      '63117',
      '63119',
      '63120',
      '63121',
      '63122',
      '63123',
      '63124',
      '63125',
      '63126',
      '63127',
      '63128',
      '63129',
      '63130',
      '63131',
      '63132',
      '63133',
      '63134',
      '63135',
      '63136',
      '63137',
      '63138',
      '63140',
      '63141',
      '63143',
      '63144',
      '63146'
    ],
    '077': [
      '65604',
      '65610',
      '65612',
      '65617',
      '65619',
      '65648',
      '65721',
      '65725',
      '65738',
      '65742',
      '65757',
      '65770',
      '65781',
      '65802',
      '65803',
      '65804',
      '65806',
      '65807',
      '65809',
      '65810'
    ],
    '157': [
      '63673',
      '63732',
      '63746',
      '63748',
      '63770',
      '63775',
      '63783'
    ],
    '069': [
      '63821',
      '63829',
      '63837',
      '63847',
      '63849',
      '63852',
      '63855',
      '63857',
      '63863',
      '63876',
      '63877',
      '63880',
      '63933'
    ],
    '093': [
      '63620',
      '63621',
      '63623',
      '63624',
      '63625',
      '63631',
      '63636',
      '63650',
      '63654',
      '63656',
      '63663',
      '63675',
      '65439',
      '65565',
      '65566'
    ],
    '105': [
      '65463',
      '65470',
      '65534',
      '65536',
      '65543',
      '65552',
      '65556',
      '65567',
      '65632',
      '65662',
      '65722'
    ],
    '113': [
      '63333',
      '63334',
      '63343',
      '63344',
      '63347',
      '63348',
      '63349',
      '63359',
      '63362',
      '63369',
      '63370',
      '63377',
      '63379',
      '63381',
      '63383',
      '63387',
      '63389',
      '63390'
    ],
    '219': [
      '63348',
      '63349',
      '63351',
      '63357',
      '63363',
      '63381',
      '63383',
      '63390',
      '65041'
    ],
    '187': [
      '63036',
      '63087',
      '63601',
      '63624',
      '63626',
      '63628',
      '63630',
      '63637',
      '63640',
      '63645',
      '63648',
      '63650',
      '63653'
    ],
    '207': [
      '63730',
      '63735',
      '63738',
      '63771',
      '63801',
      '63822',
      '63825',
      '63833',
      '63841',
      '63846',
      '63870',
      '63936',
      '63960'
    ],
    '075': [
      '64402',
      '64438',
      '64441',
      '64453',
      '64463',
      '64471',
      '64479',
      '64489',
      '64499',
      '64657',
      '64670'
    ],
    '510': [
      '63101',
      '63102',
      '63103',
      '63104',
      '63105',
      '63106',
      '63107',
      '63108',
      '63109',
      '63110',
      '63111',
      '63112',
      '63113',
      '63115',
      '63116',
      '63117',
      '63118',
      '63119',
      '63120',
      '63123',
      '63125',
      '63130',
      '63133',
      '63136',
      '63137',
      '63139',
      '63143',
      '63147',
      '63155'
    ],
    '051': [
      '65023',
      '65032',
      '65040',
      '65053',
      '65058',
      '65074',
      '65076',
      '65101',
      '65109'
    ],
    '007': [
      '63345',
      '63352',
      '63359',
      '63382',
      '63384',
      '65232',
      '65240',
      '65243',
      '65264',
      '65265',
      '65280',
      '65284',
      '65285'
    ],
    '165': [
      '64018',
      '64079',
      '64089',
      '64092',
      '64098',
      '64118',
      '64150',
      '64151',
      '64152',
      '64153',
      '64154',
      '64163',
      '64164',
      '64439',
      '64440',
      '64444',
      '64484'
    ],
    '227': [
      '64420',
      '64441',
      '64456',
      '64475',
      '64486',
      '64499'
    ],
    '101': [
      '64011',
      '64019',
      '64020',
      '64040',
      '64061',
      '64070',
      '64076',
      '64093',
      '64726',
      '64733',
      '64747',
      '64761',
      '65305',
      '65332',
      '65336',
      '65351',
      '65360'
    ],
    '061': [
      '64429',
      '64497',
      '64620',
      '64625',
      '64636',
      '64640',
      '64642',
      '64644',
      '64647',
      '64648',
      '64649',
      '64654',
      '64657',
      '64670',
      '64689'
    ],
    '049': [
      '64048',
      '64062',
      '64429',
      '64454',
      '64465',
      '64474',
      '64477',
      '64490',
      '64492',
      '64493'
    ],
    '019': [
      '65010',
      '65039',
      '65201',
      '65202',
      '65203',
      '65215',
      '65240',
      '65243',
      '65255',
      '65256',
      '65279',
      '65284'
    ],
    '221': [
      '63020',
      '63030',
      '63071',
      '63080',
      '63622',
      '63624',
      '63626',
      '63628',
      '63630',
      '63631',
      '63648',
      '63660',
      '63664',
      '63674',
      '65441',
      '65565'
    ],
    '083': [
      '64726',
      '64733',
      '64735',
      '64739',
      '64740',
      '64761',
      '64770',
      '64788',
      '65323',
      '65360'
    ],
    '073': [
      '63014',
      '63068',
      '63091',
      '65014',
      '65041',
      '65051',
      '65061',
      '65062',
      '65066',
      '65453',
      '65559'
    ],
    '015': [
      '64735',
      '64776',
      '65078',
      '65325',
      '65326',
      '65335',
      '65338',
      '65345',
      '65355',
      '65360',
      '65634',
      '65735',
      '65779'
    ]
  },
  '23': {
    '027': [
      '04354',
      '04438',
      '04496',
      '04848',
      '04849',
      '04915',
      '04921',
      '04922',
      '04941',
      '04949',
      '04951',
      '04952',
      '04973',
      '04974',
      '04981',
      '04986',
      '04987',
      '04988'
    ],
    '001': [
      '04210',
      '04222',
      '04228',
      '04236',
      '04240',
      '04250',
      '04252',
      '04253',
      '04254',
      '04256',
      '04258',
      '04263',
      '04274',
      '04280',
      '04282'
    ],
    '017': [
      '03579',
      '04010',
      '04022',
      '04037',
      '04040',
      '04041',
      '04051',
      '04068',
      '04088',
      '04216',
      '04217',
      '04219',
      '04220',
      '04221',
      '04224',
      '04226',
      '04227',
      '04231',
      '04237',
      '04238',
      '04255',
      '04257',
      '04261',
      '04267',
      '04268',
      '04270',
      '04271',
      '04275',
      '04276',
      '04281',
      '04286',
      '04289',
      '04290',
      '04292',
      '04964'
    ],
    '029': [
      '04413',
      '04424',
      '04454',
      '04490',
      '04491',
      '04492',
      '04606',
      '04611',
      '04619',
      '04622',
      '04623',
      '04626',
      '04628',
      '04630',
      '04631',
      '04637',
      '04643',
      '04648',
      '04649',
      '04652',
      '04654',
      '04655',
      '04657',
      '04658',
      '04666',
      '04667',
      '04668',
      '04671',
      '04680',
      '04686',
      '04691',
      '04694'
    ],
    '023': [
      '04008',
      '04086',
      '04287',
      '04345',
      '04357',
      '04530',
      '04548',
      '04562',
      '04579'
    ],
    '005': [
      '04003',
      '04009',
      '04011',
      '04015',
      '04017',
      '04019',
      '04021',
      '04024',
      '04029',
      '04032',
      '04038',
      '04039',
      '04040',
      '04050',
      '04055',
      '04057',
      '04062',
      '04066',
      '04069',
      '04071',
      '04074',
      '04079',
      '04084',
      '04085',
      '04091',
      '04092',
      '04096',
      '04097',
      '04101',
      '04102',
      '04103',
      '04105',
      '04106',
      '04107',
      '04108',
      '04109',
      '04110',
      '04260'
    ],
    '021': [
      '04406',
      '04414',
      '04415',
      '04426',
      '04441',
      '04442',
      '04443',
      '04462',
      '04463',
      '04464',
      '04479',
      '04481',
      '04485',
      '04732',
      '04942'
    ],
    '031': [
      '03901',
      '03902',
      '03903',
      '03904',
      '03905',
      '03906',
      '03907',
      '03908',
      '03909',
      '03910',
      '03911',
      '04001',
      '04002',
      '04005',
      '04006',
      '04020',
      '04027',
      '04030',
      '04042',
      '04043',
      '04046',
      '04047',
      '04048',
      '04049',
      '04056',
      '04061',
      '04063',
      '04064',
      '04072',
      '04073',
      '04076',
      '04083',
      '04087',
      '04090',
      '04093',
      '04095'
    ],
    '003': [
      '04424',
      '04451',
      '04459',
      '04460',
      '04471',
      '04497',
      '04730',
      '04732',
      '04733',
      '04734',
      '04735',
      '04736',
      '04739',
      '04740',
      '04741',
      '04742',
      '04743',
      '04745',
      '04746',
      '04747',
      '04750',
      '04756',
      '04757',
      '04758',
      '04760',
      '04761',
      '04762',
      '04763',
      '04764',
      '04765',
      '04766',
      '04768',
      '04769',
      '04772',
      '04773',
      '04774',
      '04776',
      '04779',
      '04780',
      '04781',
      '04783',
      '04785',
      '04786',
      '04787'
    ],
    '009': [
      '04408',
      '04416',
      '04421',
      '04429',
      '04431',
      '04472',
      '04476',
      '04605',
      '04607',
      '04609',
      '04612',
      '04613',
      '04614',
      '04616',
      '04617',
      '04622',
      '04624',
      '04625',
      '04627',
      '04629',
      '04634',
      '04635',
      '04640',
      '04642',
      '04644',
      '04646',
      '04650',
      '04653',
      '04660',
      '04662',
      '04664',
      '04669',
      '04673',
      '04674',
      '04675',
      '04676',
      '04677',
      '04679',
      '04681',
      '04683',
      '04684',
      '04685',
      '04693'
    ],
    '025': [
      '04478',
      '04911',
      '04912',
      '04920',
      '04923',
      '04924',
      '04925',
      '04929',
      '04930',
      '04937',
      '04942',
      '04943',
      '04944',
      '04945',
      '04950',
      '04957',
      '04958',
      '04961',
      '04965',
      '04967',
      '04971',
      '04975',
      '04976',
      '04978',
      '04979',
      '04985'
    ],
    '019': [
      '04401',
      '04410',
      '04411',
      '04412',
      '04417',
      '04418',
      '04419',
      '04422',
      '04427',
      '04428',
      '04429',
      '04430',
      '04434',
      '04435',
      '04444',
      '04448',
      '04449',
      '04450',
      '04451',
      '04453',
      '04455',
      '04456',
      '04457',
      '04459',
      '04460',
      '04461',
      '04462',
      '04468',
      '04469',
      '04473',
      '04474',
      '04475',
      '04487',
      '04488',
      '04489',
      '04493',
      '04495',
      '04497',
      '04733',
      '04765',
      '04777',
      '04928',
      '04930',
      '04932',
      '04933',
      '04939',
      '04953',
      '04969'
    ],
    '011': [
      '04259',
      '04265',
      '04284',
      '04330',
      '04343',
      '04344',
      '04345',
      '04346',
      '04347',
      '04349',
      '04350',
      '04351',
      '04352',
      '04355',
      '04358',
      '04359',
      '04360',
      '04363',
      '04364',
      '04901',
      '04910',
      '04917',
      '04918',
      '04926',
      '04927',
      '04962',
      '04963',
      '04988',
      '04989'
    ],
    '007': [
      '04224',
      '04234',
      '04239',
      '04285',
      '04294',
      '04936',
      '04938',
      '04940',
      '04947',
      '04955',
      '04956',
      '04964',
      '04966',
      '04970',
      '04982',
      '04983',
      '04984',
      '04992'
    ],
    '013': [
      '04547',
      '04563',
      '04574',
      '04645',
      '04841',
      '04843',
      '04847',
      '04851',
      '04853',
      '04854',
      '04855',
      '04856',
      '04858',
      '04859',
      '04860',
      '04861',
      '04862',
      '04863',
      '04864'
    ],
    '015': [
      '04342',
      '04348',
      '04353',
      '04535',
      '04537',
      '04538',
      '04539',
      '04541',
      '04543',
      '04544',
      '04551',
      '04553',
      '04554',
      '04555',
      '04556',
      '04558',
      '04564',
      '04568',
      '04570',
      '04571',
      '04572',
      '04573',
      '04575',
      '04576',
      '04578',
      '04852'
    ]
  },
  '72': {
    '079': [
      '00647',
      '00667'
    ],
    '123': [
      '00704',
      '00751'
    ],
    '131': [
      '00669',
      '00678',
      '00685'
    ],
    '009': [
      '00705',
      '00786'
    ],
    '119': [
      '00745'
    ],
    '153': [
      '00606',
      '00637',
      '00698'
    ],
    '055': [
      '00647',
      '00653'
    ],
    '029': [
      '00729',
      '00745',
      '00777'
    ],
    '109': [
      '00723'
    ],
    '021': [
      '00934',
      '00956',
      '00957',
      '00959',
      '00960',
      '00961',
      '00966',
      '00969',
      '00971'
    ],
    '053': [
      '00738',
      '00740'
    ],
    '031': [
      '00924',
      '00976',
      '00979',
      '00982',
      '00983',
      '00985',
      '00987'
    ],
    '091': [
      '00638',
      '00674'
    ],
    '063': [
      '00777',
      '00778'
    ],
    '139': [
      '00926',
      '00976',
      '00987'
    ],
    '025': [
      '00725',
      '00727'
    ],
    '059': [
      '00656',
      '00698'
    ],
    '061': [
      '00725',
      '00926',
      '00934',
      '00959',
      '00965',
      '00966',
      '00968',
      '00969',
      '00971'
    ],
    '151': [
      '00767'
    ],
    '111': [
      '00624'
    ],
    '039': [
      '00638',
      '00650'
    ],
    '149': [
      '00766'
    ],
    '011': [
      '00610',
      '00670',
      '00680'
    ],
    '117': [
      '00677'
    ],
    '035': [
      '00725',
      '00736'
    ],
    '027': [
      '00627'
    ],
    '037': [
      '00735'
    ],
    '147': [
      '00765'
    ],
    '145': [
      '00674',
      '00693',
      '00694'
    ],
    '085': [
      '00771',
      '00777'
    ],
    '143': [
      '00646',
      '00692',
      '00693'
    ],
    '103': [
      '00718',
      '00735'
    ],
    '097': [
      '00680',
      '00682'
    ],
    '045': [
      '00703',
      '00782',
      '00794'
    ],
    '107': [
      '00720',
      '00766'
    ],
    '089': [
      '00773'
    ],
    '127': [
      '00725',
      '00901',
      '00906',
      '00907',
      '00909',
      '00911',
      '00912',
      '00913',
      '00915',
      '00917',
      '00918',
      '00920',
      '00921',
      '00923',
      '00924',
      '00925',
      '00926',
      '00927',
      '00936',
      '00966',
      '00969',
      '00971',
      '00976'
    ],
    '137': [
      '00949',
      '00951',
      '00952',
      '00953',
      '00959'
    ],
    '101': [
      '00687'
    ],
    '121': [
      '00606',
      '00637'
    ],
    '057': [
      '00704',
      '00784'
    ],
    '001': [
      '00601',
      '00631',
      '00641',
      '00731'
    ],
    '129': [
      '00725',
      '00754',
      '00778'
    ],
    '017': [
      '00612',
      '00617',
      '00650'
    ],
    '095': [
      '00707'
    ],
    '087': [
      '00729',
      '00772'
    ],
    '115': [
      '00678'
    ],
    '041': [
      '00736',
      '00739'
    ],
    '005': [
      '00603',
      '00690'
    ],
    '051': [
      '00646',
      '00950'
    ],
    '077': [
      '00777'
    ],
    '033': [
      '00949',
      '00961',
      '00962',
      '00965'
    ],
    '069': [
      '00741',
      '00767',
      '00791'
    ],
    '093': [
      '00606'
    ],
    '067': [
      '00660'
    ],
    '105': [
      '00719'
    ],
    '113': [
      '00624',
      '00715',
      '00716',
      '00717',
      '00728',
      '00730',
      '00731',
      '00780'
    ],
    '133': [
      '00757'
    ],
    '054': [
      '00617',
      '00650'
    ],
    '135': [
      '00719',
      '00953',
      '00956',
      '00957',
      '00959'
    ],
    '071': [
      '00662',
      '00678',
      '00685'
    ],
    '141': [
      '00601',
      '00641',
      '00650',
      '00664'
    ],
    '075': [
      '00715',
      '00780',
      '00795'
    ],
    '047': [
      '00719',
      '00783',
      '00953'
    ],
    '081': [
      '00631',
      '00669'
    ],
    '083': [
      '00669',
      '00670'
    ],
    '023': [
      '00622',
      '00623'
    ],
    '043': [
      '00705',
      '00720',
      '00769',
      '00794'
    ],
    '099': [
      '00676'
    ],
    '065': [
      '00659'
    ],
    '125': [
      '00667',
      '00680',
      '00683'
    ],
    '049': [
      '00775'
    ],
    '019': [
      '00794'
    ],
    '015': [
      '00714'
    ],
    '007': [
      '00703',
      '00725'
    ],
    '013': [
      '00612',
      '00616',
      '00650',
      '00652',
      '00688'
    ],
    '073': [
      '00664'
    ],
    '003': [
      '00602',
      '00610',
      '00676',
      '00677'
    ]
  },
  '11': {
    '001': [
      '20001',
      '20002',
      '20003',
      '20004',
      '20005',
      '20006',
      '20007',
      '20008',
      '20009',
      '20010',
      '20011',
      '20012',
      '20015',
      '20016',
      '20017',
      '20018',
      '20019',
      '20020',
      '20024',
      '20032',
      '20036',
      '20037',
      '20045',
      '20052',
      '20053',
      '20057',
      '20064',
      '20202',
      '20204',
      '20228',
      '20230',
      '20240',
      '20245',
      '20260',
      '20307',
      '20317',
      '20319',
      '20373',
      '20390',
      '20405',
      '20418',
      '20427',
      '20506',
      '20510',
      '20520',
      '20535',
      '20540',
      '20551',
      '20553',
      '20560',
      '20565',
      '20566',
      '20593'
    ]
  },
  '15': {
    '005': [
      '96742'
    ],
    '007': [
      '96703',
      '96705',
      '96714',
      '96716',
      '96722',
      '96741',
      '96746',
      '96747',
      '96751',
      '96752',
      '96754',
      '96756',
      '96765',
      '96766',
      '96769',
      '96796'
    ],
    '009': [
      '96708',
      '96713',
      '96729',
      '96732',
      '96748',
      '96753',
      '96757',
      '96761',
      '96763',
      '96768',
      '96770',
      '96779',
      '96790',
      '96793'
    ],
    '001': [
      '96704',
      '96710',
      '96719',
      '96720',
      '96725',
      '96726',
      '96727',
      '96728',
      '96737',
      '96738',
      '96740',
      '96743',
      '96749',
      '96750',
      '96755',
      '96760',
      '96764',
      '96771',
      '96772',
      '96773',
      '96774',
      '96776',
      '96777',
      '96778',
      '96780',
      '96781',
      '96783',
      '96785'
    ],
    '003': [
      '96701',
      '96706',
      '96707',
      '96712',
      '96717',
      '96730',
      '96731',
      '96734',
      '96744',
      '96759',
      '96762',
      '96782',
      '96786',
      '96789',
      '96791',
      '96792',
      '96795',
      '96797',
      '96813',
      '96814',
      '96815',
      '96816',
      '96817',
      '96818',
      '96819',
      '96821',
      '96822',
      '96825',
      '96826',
      '96850',
      '96853',
      '96857',
      '96859',
      '96860',
      '96863'
    ]
  },
  '22': {
    '079': [
      '70656',
      '71301',
      '71302',
      '71303',
      '71325',
      '71328',
      '71346',
      '71351',
      '71360',
      '71367',
      '71405',
      '71409',
      '71423',
      '71424',
      '71427',
      '71430',
      '71433',
      '71438',
      '71447',
      '71455',
      '71463',
      '71466',
      '71467',
      '71472',
      '71485'
    ],
    '123': [
      '71237',
      '71253',
      '71263',
      '71266'
    ],
    '099': [
      '70339',
      '70380',
      '70392',
      '70512',
      '70517',
      '70518',
      '70519',
      '70582',
      '70757'
    ],
    '009': [
      '71322',
      '71323',
      '71327',
      '71328',
      '71331',
      '71333',
      '71339',
      '71341',
      '71350',
      '71351',
      '71355',
      '71356',
      '71360',
      '71362',
      '71369'
    ],
    '119': [
      '71018',
      '71021',
      '71023',
      '71024',
      '71038',
      '71039',
      '71055',
      '71067',
      '71071',
      '71072',
      '71073',
      '71075'
    ],
    '055': [
      '70501',
      '70503',
      '70506',
      '70507',
      '70508',
      '70517',
      '70518',
      '70520',
      '70529',
      '70555',
      '70558',
      '70578',
      '70583',
      '70592'
    ],
    '029': [
      '71316',
      '71326',
      '71334',
      '71343',
      '71354',
      '71373',
      '71377'
    ],
    '109': [
      '70301',
      '70343',
      '70344',
      '70352',
      '70353',
      '70356',
      '70359',
      '70360',
      '70363',
      '70364',
      '70377',
      '70395',
      '70397'
    ],
    '021': [
      '71418',
      '71435',
      '71441',
      '71465'
    ],
    '053': [
      '70532',
      '70546',
      '70549',
      '70581',
      '70591',
      '70640',
      '70647',
      '70648',
      '70650',
      '70657'
    ],
    '031': [
      '71027',
      '71030',
      '71032',
      '71046',
      '71049',
      '71052',
      '71063',
      '71078',
      '71419'
    ],
    '091': [
      '70422',
      '70441',
      '70443',
      '70444',
      '70453',
      '70706',
      '70744'
    ],
    '063': [
      '70403',
      '70443',
      '70449',
      '70453',
      '70462',
      '70466',
      '70706',
      '70711',
      '70726',
      '70733',
      '70739',
      '70744',
      '70754',
      '70774',
      '70785'
    ],
    '059': [
      '71328',
      '71342',
      '71343',
      '71371',
      '71441',
      '71465',
      '71479',
      '71480'
    ],
    '025': [
      '71326',
      '71340',
      '71343',
      '71368',
      '71401',
      '71418',
      '71425',
      '71435',
      '71465'
    ],
    '121': [
      '70710',
      '70719',
      '70729',
      '70764',
      '70767'
    ],
    '111': [
      '71222',
      '71234',
      '71241',
      '71256',
      '71260',
      '71277',
      '71280',
      '71749'
    ],
    '039': [
      '70515',
      '70524',
      '70535',
      '70554',
      '70576',
      '70580',
      '70585',
      '70586',
      '71325',
      '71367',
      '71463'
    ],
    '011': [
      '70633',
      '70634',
      '70637',
      '70652',
      '70653',
      '70657',
      '70660',
      '70661',
      '70662'
    ],
    '117': [
      '70426',
      '70427',
      '70431',
      '70438',
      '70450'
    ],
    '041': [
      '71219',
      '71232',
      '71243',
      '71295',
      '71336',
      '71378'
    ],
    '027': [
      '71001',
      '71003',
      '71038',
      '71040',
      '71048',
      '71055',
      '71079',
      '71222',
      '71235',
      '71256',
      '71749'
    ],
    '037': [
      '70722',
      '70730',
      '70748',
      '70761',
      '70777',
      '70789',
      '70791'
    ],
    '125': [
      '70712',
      '70775',
      '70782',
      '70787'
    ],
    '085': [
      '71065',
      '71403',
      '71406',
      '71419',
      '71426',
      '71429',
      '71439',
      '71449',
      '71450',
      '71462',
      '71469',
      '71486'
    ],
    '017': [
      '71004',
      '71007',
      '71029',
      '71033',
      '71043',
      '71044',
      '71046',
      '71047',
      '71051',
      '71060',
      '71061',
      '71069',
      '71082',
      '71101',
      '71103',
      '71104',
      '71105',
      '71106',
      '71107',
      '71108',
      '71109',
      '71115',
      '71118',
      '71119',
      '71129'
    ],
    '089': [
      '70030',
      '70031',
      '70039',
      '70047',
      '70057',
      '70068',
      '70070',
      '70079',
      '70080',
      '70087'
    ],
    '045': [
      '70513',
      '70518',
      '70528',
      '70533',
      '70544',
      '70552',
      '70560',
      '70563',
      '70582',
      '70592'
    ],
    '107': [
      '71357',
      '71366',
      '71375'
    ],
    '127': [
      '71031',
      '71404',
      '71410',
      '71422',
      '71454',
      '71457',
      '71465',
      '71473',
      '71479',
      '71483'
    ],
    '081': [
      '71019',
      '71034',
      '71052',
      '71068',
      '71115'
    ],
    '097': [
      '70512',
      '70520',
      '70525',
      '70535',
      '70541',
      '70550',
      '70570',
      '70577',
      '70584',
      '70586',
      '70589',
      '70750',
      '71322',
      '71345',
      '71353',
      '71356',
      '71358',
      '71362'
    ],
    '057': [
      '70030',
      '70301',
      '70343',
      '70345',
      '70354',
      '70355',
      '70357',
      '70359',
      '70364',
      '70373',
      '70374',
      '70375',
      '70377',
      '70394'
    ],
    '001': [
      '70515',
      '70516',
      '70525',
      '70526',
      '70529',
      '70531',
      '70534',
      '70535',
      '70537',
      '70543',
      '70546',
      '70556',
      '70559',
      '70578',
      '70583'
    ],
    '103': [
      '70420',
      '70427',
      '70431',
      '70433',
      '70435',
      '70437',
      '70445',
      '70447',
      '70448',
      '70452',
      '70458',
      '70460',
      '70461',
      '70463',
      '70464',
      '70471'
    ],
    '115': [
      '70634',
      '70639',
      '70656',
      '70659',
      '71403',
      '71438',
      '71439',
      '71446',
      '71447',
      '71459',
      '71461',
      '71474'
    ],
    '087': [
      '70032',
      '70043',
      '70075',
      '70085',
      '70092'
    ],
    '095': [
      '70049',
      '70051',
      '70068',
      '70076',
      '70084',
      '70090'
    ],
    '035': [
      '71237',
      '71254',
      '71276',
      '71286'
    ],
    '005': [
      '70346',
      '70725',
      '70734',
      '70737',
      '70769',
      '70774',
      '70778'
    ],
    '051': [
      '70001',
      '70002',
      '70003',
      '70005',
      '70006',
      '70036',
      '70053',
      '70056',
      '70058',
      '70062',
      '70065',
      '70067',
      '70072',
      '70094',
      '70121',
      '70123',
      '70358'
    ],
    '077': [
      '70715',
      '70729',
      '70732',
      '70736',
      '70747',
      '70749',
      '70750',
      '70752',
      '70753',
      '70755',
      '70756',
      '70757',
      '70759',
      '70760',
      '70762',
      '70773',
      '70783'
    ],
    '033': [
      '70714',
      '70722',
      '70739',
      '70748',
      '70770',
      '70777',
      '70791',
      '70801',
      '70802',
      '70803',
      '70805',
      '70806',
      '70807',
      '70808',
      '70809',
      '70810',
      '70811',
      '70812',
      '70814',
      '70815',
      '70816',
      '70817',
      '70818',
      '70819',
      '70820',
      '70836'
    ],
    '069': [
      '71019',
      '71031',
      '71065',
      '71070',
      '71411',
      '71414',
      '71416',
      '71427',
      '71447',
      '71450',
      '71452',
      '71455',
      '71456',
      '71457',
      '71468',
      '71469'
    ],
    '093': [
      '70052',
      '70071',
      '70086',
      '70090',
      '70723',
      '70743',
      '70763'
    ],
    '067': [
      '71220',
      '71223',
      '71229',
      '71250',
      '71261',
      '71264',
      '71280'
    ],
    '105': [
      '70401',
      '70402',
      '70403',
      '70422',
      '70433',
      '70435',
      '70436',
      '70437',
      '70442',
      '70443',
      '70444',
      '70446',
      '70451',
      '70454',
      '70455',
      '70456',
      '70462',
      '70465',
      '70466'
    ],
    '113': [
      '70510',
      '70526',
      '70528',
      '70533',
      '70542',
      '70548',
      '70555',
      '70560',
      '70575',
      '70578',
      '70592'
    ],
    '071': [
      '70112',
      '70113',
      '70114',
      '70115',
      '70116',
      '70117',
      '70118',
      '70119',
      '70122',
      '70124',
      '70125',
      '70126',
      '70127',
      '70128',
      '70129',
      '70130',
      '70131',
      '70139',
      '70163'
    ],
    '075': [
      '70037',
      '70038',
      '70040',
      '70041',
      '70050',
      '70082',
      '70083',
      '70091'
    ],
    '047': [
      '70721',
      '70740',
      '70757',
      '70764',
      '70772',
      '70776',
      '70780',
      '70788'
    ],
    '083': [
      '71232',
      '71237',
      '71259',
      '71264',
      '71269',
      '71279',
      '71418'
    ],
    '023': [
      '70542',
      '70549',
      '70607',
      '70630',
      '70631',
      '70632',
      '70643',
      '70645'
    ],
    '043': [
      '71360',
      '71404',
      '71407',
      '71417',
      '71423',
      '71432',
      '71454',
      '71467'
    ],
    '065': [
      '71232',
      '71233',
      '71282'
    ],
    '101': [
      '70340',
      '70342',
      '70380',
      '70392',
      '70514',
      '70523',
      '70538',
      '70544'
    ],
    '061': [
      '71001',
      '71227',
      '71234',
      '71235',
      '71245',
      '71270',
      '71272',
      '71275'
    ],
    '049': [
      '71226',
      '71227',
      '71238',
      '71247',
      '71251',
      '71268',
      '71270'
    ],
    '019': [
      '70601',
      '70605',
      '70607',
      '70611',
      '70615',
      '70630',
      '70633',
      '70646',
      '70647',
      '70657',
      '70661',
      '70663',
      '70665',
      '70668',
      '70669'
    ],
    '003': [
      '70532',
      '70637',
      '70638',
      '70644',
      '70648',
      '70651',
      '70654',
      '70655',
      '70656',
      '70657',
      '70658',
      '71463'
    ],
    '007': [
      '70301',
      '70339',
      '70341',
      '70372',
      '70380',
      '70390',
      '70391',
      '70393'
    ],
    '013': [
      '71001',
      '71008',
      '71016',
      '71024',
      '71028',
      '71039',
      '71045',
      '71055',
      '71068',
      '71070',
      '71251',
      '71268',
      '71275'
    ],
    '073': [
      '71201',
      '71202',
      '71203',
      '71209',
      '71225',
      '71227',
      '71229',
      '71234',
      '71238',
      '71280',
      '71291',
      '71292',
      '71418'
    ],
    '015': [
      '71006',
      '71018',
      '71023',
      '71037',
      '71051',
      '71064',
      '71067',
      '71071',
      '71075',
      '71104',
      '71105',
      '71107',
      '71110',
      '71111',
      '71112'
    ]
  },
  '78': {
    '010': [
      '00820',
      '00821',
      '00822',
      '00823',
      '00824',
      '00840',
      '00841',
      '00850',
      '00851'
    ],
    '020': [
      '00830',
      '00831'
    ],
    '030': [
      '00801',
      '00802',
      '00803',
      '00804',
      '00805'
    ]
  }
}

// keys are state FIPS code, values are state readable names
export const stateFIPS = {
  '10': 'Delaware',
  '11': 'District of Columbia',
  '12': 'Florida',
  '13': 'Georgia',
  '15': 'Hawaii',
  '16': 'Idaho',
  '17': 'Illinois',
  '18': 'Indiana',
  '19': 'Iowa',
  '20': 'Kansas',
  '21': 'Kentucky',
  '22': 'Louisiana',
  '23': 'Maine',
  '24': 'Maryland',
  '25': 'Massachusetts',
  '26': 'Michigan',
  '27': 'Minnesota',
  '28': 'Mississippi',
  '29': 'Missouri',
  '30': 'Montana',
  '31': 'Nebraska',
  '32': 'Nevada',
  '33': 'New Hampshire',
  '34': 'New Jersey',
  '35': 'New Mexico',
  '36': 'New York',
  '37': 'North Carolina',
  '38': 'North Dakota',
  '39': 'Ohio',
  '40': 'Oklahoma',
  '41': 'Oregon',
  '42': 'Pennsylvania',
  '44': 'Rhode Island',
  '45': 'South Carolina',
  '46': 'South Dakota',
  '47': 'Tennessee',
  '48': 'Texas',
  '49': 'Utah',
  '50': 'Vermont',
  '51': 'Virginia',
  '53': 'Washington',
  '54': 'West Virginia',
  '55': 'Wisconsin',
  '56': 'Wyoming',
  '01': 'Alabama',
  '02': 'Alaska',
  '04': 'Arizona',
  '05': 'Arkansas',
  '06': 'California',
  '08': 'Colorado',
  '09': 'Connecticut',
  '72': 'Puerto Rico',
  '78': 'Virgin Islands',
}

// keys are state FIPS code, values are state readable names
export const stateToFIPS = {
  'Delaware': '10',
  'District of Columbia': '11',
  'Florida': '12',
  'Georgia': '13',
  'Hawaii': '15',
  'Idaho': '16',
  'Illinois': '17',
  'Indiana': '18',
  'Iowa': '19',
  'Kansas': '20',
  'Kentucky': '21',
  'Louisiana': '22',
  'Maine': '23',
  'Maryland': '24',
  'Massachusetts': '25',
  'Michigan': '26',
  'Minnesota': '27',
  'Mississippi': '28',
  'Missouri': '29',
  'Montana': '30',
  'Nebraska': '31',
  'Nevada': '32',
  'New Hampshire': '33',
  'New Jersey': '34',
  'New Mexico': '35',
  'New York': '36',
  'North Carolina': '37',
  'North Dakota': '38',
  'Ohio': '39',
  'Oklahoma': '40',
  'Oregon': '41',
  'Pennsylvania': '42',
  'Rhode Island': '44',
  'South Carolina': '45',
  'South Dakota': '46',
  'Tennessee': '47',
  'Texas': '48',
  'Utah': '49',
  'Vermont': '50',
  'Virginia': '51',
  'Washington': '53',
  'West Virginia': '54',
  'Wisconsin': '55',
  'Wyoming': '56',
  'Alabama': '01',
  'Alaska': '02',
  'Arizona': '04',
  'Arkansas': '05',
  'California': '06',
  'Colorado': '08',
  'Connecticut': '09',
  'Virgin Islands': '78'
}


// keys are state FIPS codes, values are dicts. In those dicts, keys are county FIPS codes, and values are the readable county names.
export const countyFIPS = {
  '01': {
    '001': 'Autauga',
    '003': 'Baldwin',
    '005': 'Barbour',
    '007': 'Bibb',
    '009': 'Blount',
    '011': 'Bullock',
    '013': 'Butler',
    '015': 'Calhoun',
    '017': 'Chambers',
    '019': 'Cherokee',
    '021': 'Chilton',
    '023': 'Choctaw',
    '025': 'Clarke',
    '027': 'Clay',
    '029': 'Cleburne',
    '031': 'Coffee',
    '033': 'Colbert',
    '035': 'Conecuh',
    '037': 'Coosa',
    '039': 'Covington',
    '041': 'Crenshaw',
    '043': 'Cullman',
    '045': 'Dale',
    '047': 'Dallas',
    '049': 'DeKalb',
    '051': 'Elmore',
    '053': 'Escambia',
    '055': 'Etowah',
    '057': 'Fayette',
    '059': 'Franklin',
    '061': 'Geneva',
    '063': 'Greene',
    '065': 'Hale',
    '067': 'Henry',
    '069': 'Houston',
    '071': 'Jackson',
    '073': 'Jefferson',
    '075': 'Lamar',
    '077': 'Lauderdale',
    '079': 'Lawrence',
    '081': 'Lee',
    '083': 'Limestone',
    '085': 'Lowndes',
    '087': 'Macon',
    '089': 'Madison',
    '091': 'Marengo',
    '093': 'Marion',
    '095': 'Marshall',
    '097': 'Mobile',
    '099': 'Monroe',
    '101': 'Montgomery',
    '103': 'Morgan',
    '105': 'Perry',
    '107': 'Pickens',
    '109': 'Pike',
    '111': 'Randolph',
    '113': 'Russell',
    '115': 'St Clair',
    '117': 'Shelby',
    '119': 'Sumter',
    '121': 'Talladega',
    '123': 'Tallapoosa',
    '125': 'Tuscaloosa',
    '127': 'Walker',
    '129': 'Washington',
    '131': 'Wilcox',
    '133': 'Winston'
  },
  '02': {
    '013': 'Aleutians East',
    '016': 'Aleutians West',
    '020': 'Anchorage',
    '050': 'Bethel',
    '060': 'Bristol Bay',
    '063': 'Chugach',
    '066': 'Copper River',
    '068': 'Denali',
    '070': 'Dillingham',
    '090': 'Fairbanks North Star',
    '100': 'Haines',
    '105': 'Hoonah-Angoon',
    '110': 'Juneau',
    '122': 'Kenai Peninsula',
    '130': 'Ketchikan Gateway',
    '150': 'Kodiak Island',
    '158': 'Kusilvak',
    '164': 'Lake and Peninsula',
    '170': 'Matanuska-Susitna',
    '180': 'Nome',
    '185': 'North Slope',
    '188': 'Northwest Arctic',
    '195': 'Petersburg',
    '198': 'Prince of Wales-Hyder',
    '220': 'Sitka',
    '230': 'Skagway',
    '240': 'Southeast Fairbanks',
    '275': 'Wrangell',
    '282': 'Yakutat',
    '290': 'Yukon-Koyukuk',
    '063': 'Chugach',
    '066': 'Copper River'
  },
  '04': {
    '001': 'Apache',
    '003': 'Cochise',
    '005': 'Coconino',
    '007': 'Gila',
    '009': 'Graham',
    '011': 'Greenlee',
    '012': 'La Paz',
    '013': 'Maricopa',
    '015': 'Mohave',
    '017': 'Navajo',
    '019': 'Pima',
    '021': 'Pinal',
    '023': 'Santa Cruz',
    '025': 'Yavapai',
    '027': 'Yuma'
  },
  '05': {
    '001': 'Arkansas',
    '003': 'Ashley',
    '005': 'Baxter',
    '007': 'Benton',
    '009': 'Boone',
    '011': 'Bradley',
    '013': 'Calhoun',
    '015': 'Carroll',
    '017': 'Chicot',
    '019': 'Clark',
    '021': 'Clay',
    '023': 'Cleburne',
    '025': 'Cleveland',
    '027': 'Columbia',
    '029': 'Conway',
    '031': 'Craighead',
    '033': 'Crawford',
    '035': 'Crittenden',
    '037': 'Cross',
    '039': 'Dallas',
    '041': 'Desha',
    '043': 'Drew',
    '045': 'Faulkner',
    '047': 'Franklin',
    '049': 'Fulton',
    '051': 'Garland',
    '053': 'Grant',
    '055': 'Greene',
    '057': 'Hempstead',
    '059': 'Hot Spring',
    '061': 'Howard',
    '063': 'Independence',
    '065': 'Izard',
    '067': 'Jackson',
    '069': 'Jefferson',
    '071': 'Johnson',
    '073': 'Lafayette',
    '075': 'Lawrence',
    '077': 'Lee',
    '079': 'Lincoln',
    '081': 'Little River',
    '083': 'Logan',
    '085': 'Lonoke',
    '087': 'Madison',
    '089': 'Marion',
    '091': 'Miller',
    '093': 'Mississippi',
    '095': 'Monroe',
    '097': 'Montgomery',
    '099': 'Nevada',
    '101': 'Newton',
    '103': 'Ouachita',
    '105': 'Perry',
    '107': 'Phillips',
    '109': 'Pike',
    '111': 'Poinsett',
    '113': 'Polk',
    '115': 'Pope',
    '117': 'Prairie',
    '119': 'Pulaski',
    '121': 'Randolph',
    '123': 'St Francis',
    '125': 'Saline',
    '127': 'Scott',
    '129': 'Searcy',
    '131': 'Sebastian',
    '133': 'Sevier',
    '135': 'Sharp',
    '137': 'Stone',
    '139': 'Union',
    '141': 'Van Buren',
    '143': 'Washington',
    '145': 'White',
    '147': 'Woodruff',
    '149': 'Yell'
  },
  '06': {
    '001': 'Alameda',
    '003': 'Alpine',
    '005': 'Amador',
    '007': 'Butte',
    '009': 'Calaveras',
    '011': 'Colusa',
    '013': 'Contra Costa',
    '015': 'Del Norte',
    '017': 'El Dorado',
    '019': 'Fresno',
    '021': 'Glenn',
    '023': 'Humboldt',
    '025': 'Imperial',
    '027': 'Inyo',
    '029': 'Kern',
    '031': 'Kings',
    '033': 'Lake',
    '035': 'Lassen',
    '037': 'Los Angeles',
    '039': 'Madera',
    '041': 'Marin',
    '043': 'Mariposa',
    '045': 'Mendocino',
    '047': 'Merced',
    '049': 'Modoc',
    '051': 'Mono',
    '053': 'Monterey',
    '055': 'Napa',
    '057': 'Nevada',
    '059': 'Orange',
    '061': 'Placer',
    '063': 'Plumas',
    '065': 'Riverside',
    '067': 'Sacramento',
    '069': 'San Benito',
    '071': 'San Bernardino',
    '073': 'San Diego',
    '075': 'San Francisco',
    '077': 'San Joaquin',
    '079': 'San Luis Obispo',
    '081': 'San Mateo',
    '083': 'Santa Barbara',
    '085': 'Santa Clara',
    '087': 'Santa Cruz',
    '089': 'Shasta',
    '091': 'Sierra',
    '093': 'Siskiyou',
    '095': 'Solano',
    '097': 'Sonoma',
    '099': 'Stanislaus',
    '101': 'Sutter',
    '103': 'Tehama',
    '105': 'Trinity',
    '107': 'Tulare',
    '109': 'Tuolumne',
    '111': 'Ventura',
    '113': 'Yolo',
    '115': 'Yuba'
  },
  '08': {
    '001': 'Adams',
    '003': 'Alamosa',
    '005': 'Arapahoe',
    '007': 'Archuleta',
    '009': 'Baca',
    '011': 'Bent',
    '013': 'Boulder',
    '014': 'Broomfield',
    '015': 'Chaffee',
    '017': 'Cheyenne',
    '019': 'Clear Creek',
    '021': 'Conejos',
    '023': 'Costilla',
    '025': 'Crowley',
    '027': 'Custer',
    '029': 'Delta',
    '031': 'Denver',
    '033': 'Dolores',
    '035': 'Douglas',
    '037': 'Eagle',
    '039': 'Elbert',
    '041': 'El Paso',
    '043': 'Fremont',
    '045': 'Garfield',
    '047': 'Gilpin',
    '049': 'Grand',
    '051': 'Gunnison',
    '053': 'Hinsdale',
    '055': 'Huerfano',
    '057': 'Jackson',
    '059': 'Jefferson',
    '061': 'Kiowa',
    '063': 'Kit Carson',
    '065': 'Lake',
    '067': 'La Plata',
    '069': 'Larimer',
    '071': 'Las Animas',
    '073': 'Lincoln',
    '075': 'Logan',
    '077': 'Mesa',
    '079': 'Mineral',
    '081': 'Moffat',
    '083': 'Montezuma',
    '085': 'Montrose',
    '087': 'Morgan',
    '089': 'Otero',
    '091': 'Ouray',
    '093': 'Park',
    '095': 'Phillips',
    '097': 'Pitkin',
    '099': 'Prowers',
    '101': 'Pueblo',
    '103': 'Rio Blanco',
    '105': 'Rio Grande',
    '107': 'Routt',
    '109': 'Saguache',
    '111': 'San Juan',
    '113': 'San Miguel',
    '115': 'Sedgwick',
    '117': 'Summit',
    '119': 'Teller',
    '121': 'Washington',
    '123': 'Weld',
    '125': 'Yuma'
  },
  '09': {
    '001': 'Fairfield',
    '003': 'Hartford',
    '005': 'Litchfield',
    '007': 'Middlesex',
    '009': 'New Haven',
    '011': 'New London',
    '013': 'Tolland',
    '015': 'Windham'
  },
  '10': {
    '001': 'Kent',
    '003': 'New Castle',
    '005': 'Sussex'
  },
  '11': {
    '001': 'District of Columbia'
  },
  '12': {
    '001': 'Alachua',
    '003': 'Baker',
    '005': 'Bay',
    '007': 'Bradford',
    '009': 'Brevard',
    '011': 'Broward',
    '013': 'Calhoun',
    '015': 'Charlotte',
    '017': 'Citrus',
    '019': 'Clay',
    '021': 'Collier',
    '023': 'Columbia',
    '027': 'DeSoto',
    '029': 'Dixie',
    '031': 'Duval',
    '033': 'Escambia',
    '035': 'Flagler',
    '037': 'Franklin',
    '039': 'Gadsden',
    '041': 'Gilchrist',
    '043': 'Glades',
    '045': 'Gulf',
    '047': 'Hamilton',
    '049': 'Hardee',
    '051': 'Hendry',
    '053': 'Hernando',
    '055': 'Highlands',
    '057': 'Hillsborough',
    '059': 'Holmes',
    '061': 'Indian River',
    '063': 'Jackson',
    '065': 'Jefferson',
    '067': 'Lafayette',
    '069': 'Lake',
    '071': 'Lee',
    '073': 'Leon',
    '075': 'Levy',
    '077': 'Liberty',
    '079': 'Madison',
    '081': 'Manatee',
    '083': 'Marion',
    '085': 'Martin',
    '086': 'Miami-Dade',
    '087': 'Monroe',
    '089': 'Nassau',
    '091': 'Okaloosa',
    '093': 'Okeechobee',
    '095': 'Orange',
    '097': 'Osceola',
    '099': 'Palm Beach',
    '101': 'Pasco',
    '103': 'Pinellas',
    '105': 'Polk',
    '107': 'Putnam',
    '109': 'St Johns',
    '111': 'St Lucie',
    '113': 'Santa Rosa',
    '115': 'Sarasota',
    '117': 'Seminole',
    '119': 'Sumter',
    '121': 'Suwannee',
    '123': 'Taylor',
    '125': 'Union',
    '127': 'Volusia',
    '129': 'Wakulla',
    '131': 'Walton',
    '133': 'Washington'
  },
  '13': {
    '001': 'Appling',
    '003': 'Atkinson',
    '005': 'Bacon',
    '007': 'Baker',
    '009': 'Baldwin',
    '011': 'Banks',
    '013': 'Barrow',
    '015': 'Bartow',
    '017': 'Ben Hill',
    '019': 'Berrien',
    '021': 'Bibb',
    '023': 'Bleckley',
    '025': 'Brantley',
    '027': 'Brooks',
    '029': 'Bryan',
    '031': 'Bulloch',
    '033': 'Burke',
    '035': 'Butts',
    '037': 'Calhoun',
    '039': 'Camden',
    '043': 'Candler',
    '045': 'Carroll',
    '047': 'Catoosa',
    '049': 'Charlton',
    '051': 'Chatham',
    '053': 'Chattahoochee',
    '055': 'Chattooga',
    '057': 'Cherokee',
    '059': 'Clarke',
    '061': 'Clay',
    '063': 'Clayton',
    '065': 'Clinch',
    '067': 'Cobb',
    '069': 'Coffee',
    '071': 'Colquitt',
    '073': 'Columbia',
    '075': 'Cook',
    '077': 'Coweta',
    '079': 'Crawford',
    '081': 'Crisp',
    '083': 'Dade',
    '085': 'Dawson',
    '087': 'Decatur',
    '089': 'DeKalb',
    '091': 'Dodge',
    '093': 'Dooly',
    '095': 'Dougherty',
    '097': 'Douglas',
    '099': 'Early',
    '101': 'Echols',
    '103': 'Effingham',
    '105': 'Elbert',
    '107': 'Emanuel',
    '109': 'Evans',
    '111': 'Fannin',
    '113': 'Fayette',
    '115': 'Floyd',
    '117': 'Forsyth',
    '119': 'Franklin',
    '121': 'Fulton',
    '123': 'Gilmer',
    '125': 'Glascock',
    '127': 'Glynn',
    '129': 'Gordon',
    '131': 'Grady',
    '133': 'Greene',
    '135': 'Gwinnett',
    '137': 'Habersham',
    '139': 'Hall',
    '141': 'Hancock',
    '143': 'Haralson',
    '145': 'Harris',
    '147': 'Hart',
    '149': 'Heard',
    '151': 'Henry',
    '153': 'Houston',
    '155': 'Irwin',
    '157': 'Jackson',
    '159': 'Jasper',
    '161': 'Jeff Davis',
    '163': 'Jefferson',
    '165': 'Jenkins',
    '167': 'Johnson',
    '169': 'Jones',
    '171': 'Lamar',
    '173': 'Lanier',
    '175': 'Laurens',
    '177': 'Lee',
    '179': 'Liberty',
    '181': 'Lincoln',
    '183': 'Long',
    '185': 'Lowndes',
    '187': 'Lumpkin',
    '189': 'McDuffie',
    '191': 'McIntosh',
    '193': 'Macon',
    '195': 'Madison',
    '197': 'Marion',
    '199': 'Meriwether',
    '201': 'Miller',
    '205': 'Mitchell',
    '207': 'Monroe',
    '209': 'Montgomery',
    '211': 'Morgan',
    '213': 'Murray',
    '215': 'Muscogee',
    '217': 'Newton',
    '219': 'Oconee',
    '221': 'Oglethorpe',
    '223': 'Paulding',
    '225': 'Peach',
    '227': 'Pickens',
    '229': 'Pierce',
    '231': 'Pike',
    '233': 'Polk',
    '235': 'Pulaski',
    '237': 'Putnam',
    '239': 'Quitman',
    '241': 'Rabun',
    '243': 'Randolph',
    '245': 'Richmond',
    '247': 'Rockdale',
    '249': 'Schley',
    '251': 'Screven',
    '253': 'Seminole',
    '255': 'Spalding',
    '257': 'Stephens',
    '259': 'Stewart',
    '261': 'Sumter',
    '263': 'Talbot',
    '265': 'Taliaferro',
    '267': 'Tattnall',
    '269': 'Taylor',
    '271': 'Telfair',
    '273': 'Terrell',
    '275': 'Thomas',
    '277': 'Tift',
    '279': 'Toombs',
    '281': 'Towns',
    '283': 'Treutlen',
    '285': 'Troup',
    '287': 'Turner',
    '289': 'Twiggs',
    '291': 'Union',
    '293': 'Upson',
    '295': 'Walker',
    '297': 'Walton',
    '299': 'Ware',
    '301': 'Warren',
    '303': 'Washington',
    '305': 'Wayne',
    '307': 'Webster',
    '309': 'Wheeler',
    '311': 'White',
    '313': 'Whitfield',
    '315': 'Wilcox',
    '317': 'Wilkes',
    '319': 'Wilkinson',
    '321': 'Worth'
  },
  '15': {
    '001': 'Hawaii',
    '003': 'Honolulu',
    '005': 'Kalawao',
    '007': 'Kauai',
    '009': 'Maui'
  },
  '16': {
    '001': 'Ada',
    '003': 'Adams',
    '005': 'Bannock',
    '007': 'Bear Lake',
    '009': 'Benewah',
    '011': 'Bingham',
    '013': 'Blaine',
    '015': 'Boise',
    '017': 'Bonner',
    '019': 'Bonneville',
    '021': 'Boundary',
    '023': 'Butte',
    '025': 'Camas',
    '027': 'Canyon',
    '029': 'Caribou',
    '031': 'Cassia',
    '033': 'Clark',
    '035': 'Clearwater',
    '037': 'Custer',
    '039': 'Elmore',
    '041': 'Franklin',
    '043': 'Fremont',
    '045': 'Gem',
    '047': 'Gooding',
    '049': 'Idaho',
    '051': 'Jefferson',
    '053': 'Jerome',
    '055': 'Kootenai',
    '057': 'Latah',
    '059': 'Lemhi',
    '061': 'Lewis',
    '063': 'Lincoln',
    '065': 'Madison',
    '067': 'Minidoka',
    '069': 'Nez Perce',
    '071': 'Oneida',
    '073': 'Owyhee',
    '075': 'Payette',
    '077': 'Power',
    '079': 'Shoshone',
    '081': 'Teton',
    '083': 'Twin Falls',
    '085': 'Valley',
    '087': 'Washington'
  },
  '17': {
    '001': 'Adams',
    '003': 'Alexander',
    '005': 'Bond',
    '007': 'Boone',
    '009': 'Brown',
    '011': 'Bureau',
    '013': 'Calhoun',
    '015': 'Carroll',
    '017': 'Cass',
    '019': 'Champaign',
    '021': 'Christian',
    '023': 'Clark',
    '025': 'Clay',
    '027': 'Clinton',
    '029': 'Coles',
    '031': 'Cook',
    '033': 'Crawford',
    '035': 'Cumberland',
    '037': 'DeKalb',
    '039': 'De Witt',
    '041': 'Douglas',
    '043': 'DuPage',
    '045': 'Edgar',
    '047': 'Edwards',
    '049': 'Effingham',
    '051': 'Fayette',
    '053': 'Ford',
    '055': 'Franklin',
    '057': 'Fulton',
    '059': 'Gallatin',
    '061': 'Greene',
    '063': 'Grundy',
    '065': 'Hamilton',
    '067': 'Hancock',
    '069': 'Hardin',
    '071': 'Henderson',
    '073': 'Henry',
    '075': 'Iroquois',
    '077': 'Jackson',
    '079': 'Jasper',
    '081': 'Jefferson',
    '083': 'Jersey',
    '085': 'Jo Daviess',
    '087': 'Johnson',
    '089': 'Kane',
    '091': 'Kankakee',
    '093': 'Kendall',
    '095': 'Knox',
    '097': 'Lake',
    '099': 'LaSalle',
    '101': 'Lawrence',
    '103': 'Lee',
    '105': 'Livingston',
    '107': 'Logan',
    '109': 'McDonough',
    '111': 'McHenry',
    '113': 'McLean',
    '115': 'Macon',
    '117': 'Macoupin',
    '119': 'Madison',
    '121': 'Marion',
    '123': 'Marshall',
    '125': 'Mason',
    '127': 'Massac',
    '129': 'Menard',
    '131': 'Mercer',
    '133': 'Monroe',
    '135': 'Montgomery',
    '137': 'Morgan',
    '139': 'Moultrie',
    '141': 'Ogle',
    '143': 'Peoria',
    '145': 'Perry',
    '147': 'Piatt',
    '149': 'Pike',
    '151': 'Pope',
    '153': 'Pulaski',
    '155': 'Putnam',
    '157': 'Randolph',
    '159': 'Richland',
    '161': 'Rock Island',
    '163': 'St Clair',
    '165': 'Saline',
    '167': 'Sangamon',
    '169': 'Schuyler',
    '171': 'Scott',
    '173': 'Shelby',
    '175': 'Stark',
    '177': 'Stephenson',
    '179': 'Tazewell',
    '181': 'Union',
    '183': 'Vermilion',
    '185': 'Wabash',
    '187': 'Warren',
    '189': 'Washington',
    '191': 'Wayne',
    '193': 'White',
    '195': 'Whiteside',
    '197': 'Will',
    '199': 'Williamson',
    '201': 'Winnebago',
    '203': 'Woodford'
  },
  '18': {
    '001': 'Adams',
    '003': 'Allen',
    '005': 'Bartholomew',
    '007': 'Benton',
    '009': 'Blackford',
    '011': 'Boone',
    '013': 'Brown',
    '015': 'Carroll',
    '017': 'Cass',
    '019': 'Clark',
    '021': 'Clay',
    '023': 'Clinton',
    '025': 'Crawford',
    '027': 'Daviess',
    '029': 'Dearborn',
    '031': 'Decatur',
    '033': 'DeKalb',
    '035': 'Delaware',
    '037': 'Dubois',
    '039': 'Elkhart',
    '041': 'Fayette',
    '043': 'Floyd',
    '045': 'Fountain',
    '047': 'Franklin',
    '049': 'Fulton',
    '051': 'Gibson',
    '053': 'Grant',
    '055': 'Greene',
    '057': 'Hamilton',
    '059': 'Hancock',
    '061': 'Harrison',
    '063': 'Hendricks',
    '065': 'Henry',
    '067': 'Howard',
    '069': 'Huntington',
    '071': 'Jackson',
    '073': 'Jasper',
    '075': 'Jay',
    '077': 'Jefferson',
    '079': 'Jennings',
    '081': 'Johnson',
    '083': 'Knox',
    '085': 'Kosciusko',
    '087': 'LaGrange',
    '089': 'Lake',
    '091': 'LaPorte',
    '093': 'Lawrence',
    '095': 'Madison',
    '097': 'Marion',
    '099': 'Marshall',
    '101': 'Martin',
    '103': 'Miami',
    '105': 'Monroe',
    '107': 'Montgomery',
    '109': 'Morgan',
    '111': 'Newton',
    '113': 'Noble',
    '115': 'Ohio',
    '117': 'Orange',
    '119': 'Owen',
    '121': 'Parke',
    '123': 'Perry',
    '125': 'Pike',
    '127': 'Porter',
    '129': 'Posey',
    '131': 'Pulaski',
    '133': 'Putnam',
    '135': 'Randolph',
    '137': 'Ripley',
    '139': 'Rush',
    '141': 'St Joseph',
    '143': 'Scott',
    '145': 'Shelby',
    '147': 'Spencer',
    '149': 'Starke',
    '151': 'Steuben',
    '153': 'Sullivan',
    '155': 'Switzerland',
    '157': 'Tippecanoe',
    '159': 'Tipton',
    '161': 'Union',
    '163': 'Vanderburgh',
    '165': 'Vermillion',
    '167': 'Vigo',
    '169': 'Wabash',
    '171': 'Warren',
    '173': 'Warrick',
    '175': 'Washington',
    '177': 'Wayne',
    '179': 'Wells',
    '181': 'White',
    '183': 'Whitley'
  },
  '19': {
    '001': 'Adair',
    '003': 'Adams',
    '005': 'Allamakee',
    '007': 'Appanoose',
    '009': 'Audubon',
    '011': 'Benton',
    '013': 'Black Hawk',
    '015': 'Boone',
    '017': 'Bremer',
    '019': 'Buchanan',
    '021': 'Buena Vista',
    '023': 'Butler',
    '025': 'Calhoun',
    '027': 'Carroll',
    '029': 'Cass',
    '031': 'Cedar',
    '033': 'Cerro Gordo',
    '035': 'Cherokee',
    '037': 'Chickasaw',
    '039': 'Clarke',
    '041': 'Clay',
    '043': 'Clayton',
    '045': 'Clinton',
    '047': 'Crawford',
    '049': 'Dallas',
    '051': 'Davis',
    '053': 'Decatur',
    '055': 'Delaware',
    '057': 'Des Moines',
    '059': 'Dickinson',
    '061': 'Dubuque',
    '063': 'Emmet',
    '065': 'Fayette',
    '067': 'Floyd',
    '069': 'Franklin',
    '071': 'Fremont',
    '073': 'Greene',
    '075': 'Grundy',
    '077': 'Guthrie',
    '079': 'Hamilton',
    '081': 'Hancock',
    '083': 'Hardin',
    '085': 'Harrison',
    '087': 'Henry',
    '089': 'Howard',
    '091': 'Humboldt',
    '093': 'Ida',
    '095': 'Iowa',
    '097': 'Jackson',
    '099': 'Jasper',
    '101': 'Jefferson',
    '103': 'Johnson',
    '105': 'Jones',
    '107': 'Keokuk',
    '109': 'Kossuth',
    '111': 'Lee',
    '113': 'Linn',
    '115': 'Louisa',
    '117': 'Lucas',
    '119': 'Lyon',
    '121': 'Madison',
    '123': 'Mahaska',
    '125': 'Marion',
    '127': 'Marshall',
    '129': 'Mills',
    '131': 'Mitchell',
    '133': 'Monona',
    '135': 'Monroe',
    '137': 'Montgomery',
    '139': 'Muscatine',
    '141': 'O\'Brien',
    '143': 'Osceola',
    '145': 'Page',
    '147': 'Palo Alto',
    '149': 'Plymouth',
    '151': 'Pocahontas',
    '153': 'Polk',
    '155': 'Pottawattamie',
    '157': 'Poweshiek',
    '159': 'Ringgold',
    '161': 'Sac',
    '163': 'Scott',
    '165': 'Shelby',
    '167': 'Sioux',
    '169': 'Story',
    '171': 'Tama',
    '173': 'Taylor',
    '175': 'Union',
    '177': 'Van Buren',
    '179': 'Wapello',
    '181': 'Warren',
    '183': 'Washington',
    '185': 'Wayne',
    '187': 'Webster',
    '189': 'Winnebago',
    '191': 'Winneshiek',
    '193': 'Woodbury',
    '195': 'Worth',
    '197': 'Wright'
  },
  '20': {
    '001': 'Allen',
    '003': 'Anderson',
    '005': 'Atchison',
    '007': 'Barber',
    '009': 'Barton',
    '011': 'Bourbon',
    '013': 'Brown',
    '015': 'Butler',
    '017': 'Chase',
    '019': 'Chautauqua',
    '021': 'Cherokee',
    '023': 'Cheyenne',
    '025': 'Clark',
    '027': 'Clay',
    '029': 'Cloud',
    '031': 'Coffey',
    '033': 'Comanche',
    '035': 'Cowley',
    '037': 'Crawford',
    '039': 'Decatur',
    '041': 'Dickinson',
    '043': 'Doniphan',
    '045': 'Douglas',
    '047': 'Edwards',
    '049': 'Elk',
    '051': 'Ellis',
    '053': 'Ellsworth',
    '055': 'Finney',
    '057': 'Ford',
    '059': 'Franklin',
    '061': 'Geary',
    '063': 'Gove',
    '065': 'Graham',
    '067': 'Grant',
    '069': 'Gray',
    '071': 'Greeley',
    '073': 'Greenwood',
    '075': 'Hamilton',
    '077': 'Harper',
    '079': 'Harvey',
    '081': 'Haskell',
    '083': 'Hodgeman',
    '085': 'Jackson',
    '087': 'Jefferson',
    '089': 'Jewell',
    '091': 'Johnson',
    '093': 'Kearny',
    '095': 'Kingman',
    '097': 'Kiowa',
    '099': 'Labette',
    '101': 'Lane',
    '103': 'Leavenworth',
    '105': 'Lincoln',
    '107': 'Linn',
    '109': 'Logan',
    '111': 'Lyon',
    '113': 'McPherson',
    '115': 'Marion',
    '117': 'Marshall',
    '119': 'Meade',
    '121': 'Miami',
    '123': 'Mitchell',
    '125': 'Montgomery',
    '127': 'Morris',
    '129': 'Morton',
    '131': 'Nemaha',
    '133': 'Neosho',
    '135': 'Ness',
    '137': 'Norton',
    '139': 'Osage',
    '141': 'Osborne',
    '143': 'Ottawa',
    '145': 'Pawnee',
    '147': 'Phillips',
    '149': 'Pottawatomie',
    '151': 'Pratt',
    '153': 'Rawlins',
    '155': 'Reno',
    '157': 'Republic',
    '159': 'Rice',
    '161': 'Riley',
    '163': 'Rooks',
    '165': 'Rush',
    '167': 'Russell',
    '169': 'Saline',
    '171': 'Scott',
    '173': 'Sedgwick',
    '175': 'Seward',
    '177': 'Shawnee',
    '179': 'Sheridan',
    '181': 'Sherman',
    '183': 'Smith',
    '185': 'Stafford',
    '187': 'Stanton',
    '189': 'Stevens',
    '191': 'Sumner',
    '193': 'Thomas',
    '195': 'Trego',
    '197': 'Wabaunsee',
    '199': 'Wallace',
    '201': 'Washington',
    '203': 'Wichita',
    '205': 'Wilson',
    '207': 'Woodson',
    '209': 'Wyandotte'
  },
  '21': {
    '001': 'Adair',
    '003': 'Allen',
    '005': 'Anderson',
    '007': 'Ballard',
    '009': 'Barren',
    '011': 'Bath',
    '013': 'Bell',
    '015': 'Boone',
    '017': 'Bourbon',
    '019': 'Boyd',
    '021': 'Boyle',
    '023': 'Bracken',
    '025': 'Breathitt',
    '027': 'Breckinridge',
    '029': 'Bullitt',
    '031': 'Butler',
    '033': 'Caldwell',
    '035': 'Calloway',
    '037': 'Campbell',
    '039': 'Carlisle',
    '041': 'Carroll',
    '043': 'Carter',
    '045': 'Casey',
    '047': 'Christian',
    '049': 'Clark',
    '051': 'Clay',
    '053': 'Clinton',
    '055': 'Crittenden',
    '057': 'Cumberland',
    '059': 'Daviess',
    '061': 'Edmonson',
    '063': 'Elliott',
    '065': 'Estill',
    '067': 'Fayette',
    '069': 'Fleming',
    '071': 'Floyd',
    '073': 'Franklin',
    '075': 'Fulton',
    '077': 'Gallatin',
    '079': 'Garrard',
    '081': 'Grant',
    '083': 'Graves',
    '085': 'Grayson',
    '087': 'Green',
    '089': 'Greenup',
    '091': 'Hancock',
    '093': 'Hardin',
    '095': 'Harlan',
    '097': 'Harrison',
    '099': 'Hart',
    '101': 'Henderson',
    '103': 'Henry',
    '105': 'Hickman',
    '107': 'Hopkins',
    '109': 'Jackson',
    '111': 'Jefferson',
    '113': 'Jessamine',
    '115': 'Johnson',
    '117': 'Kenton',
    '119': 'Knott',
    '121': 'Knox',
    '123': 'Larue',
    '125': 'Laurel',
    '127': 'Lawrence',
    '129': 'Lee',
    '131': 'Leslie',
    '133': 'Letcher',
    '135': 'Lewis',
    '137': 'Lincoln',
    '139': 'Livingston',
    '141': 'Logan',
    '143': 'Lyon',
    '145': 'McCracken',
    '147': 'McCreary',
    '149': 'McLean',
    '151': 'Madison',
    '153': 'Magoffin',
    '155': 'Marion',
    '157': 'Marshall',
    '159': 'Martin',
    '161': 'Mason',
    '163': 'Meade',
    '165': 'Menifee',
    '167': 'Mercer',
    '169': 'Metcalfe',
    '171': 'Monroe',
    '173': 'Montgomery',
    '175': 'Morgan',
    '177': 'Muhlenberg',
    '179': 'Nelson',
    '181': 'Nicholas',
    '183': 'Ohio',
    '185': 'Oldham',
    '187': 'Owen',
    '189': 'Owsley',
    '191': 'Pendleton',
    '193': 'Perry',
    '195': 'Pike',
    '197': 'Powell',
    '199': 'Pulaski',
    '201': 'Robertson',
    '203': 'Rockcastle',
    '205': 'Rowan',
    '207': 'Russell',
    '209': 'Scott',
    '211': 'Shelby',
    '213': 'Simpson',
    '215': 'Spencer',
    '217': 'Taylor',
    '219': 'Todd',
    '221': 'Trigg',
    '223': 'Trimble',
    '225': 'Union',
    '227': 'Warren',
    '229': 'Washington',
    '231': 'Wayne',
    '233': 'Webster',
    '235': 'Whitley',
    '237': 'Wolfe',
    '239': 'Woodford'
  },
  '22': {
    '001': 'Acadia',
    '003': 'Allen',
    '005': 'Ascension',
    '007': 'Assumption',
    '009': 'Avoyelles',
    '011': 'Beauregard',
    '013': 'Bienville',
    '015': 'Bossier',
    '017': 'Caddo',
    '019': 'Calcasieu',
    '021': 'Caldwell',
    '023': 'Cameron',
    '025': 'Catahoula',
    '027': 'Claiborne',
    '029': 'Concordia',
    '031': 'De Soto',
    '033': 'East Baton Rouge',
    '035': 'East Carroll',
    '037': 'East Feliciana',
    '039': 'Evangeline',
    '041': 'Franklin',
    '043': 'Grant',
    '045': 'Iberia',
    '047': 'Iberville',
    '049': 'Jackson',
    '051': 'Jefferson',
    '053': 'Jefferson Davis',
    '055': 'Lafayette',
    '057': 'Lafourche',
    '059': 'La Salle',
    '061': 'Lincoln',
    '063': 'Livingston',
    '065': 'Madison',
    '067': 'Morehouse',
    '069': 'Natchitoches',
    '071': 'Orleans',
    '073': 'Ouachita',
    '075': 'Plaquemines',
    '077': 'Pointe Coupee',
    '079': 'Rapides',
    '081': 'Red River',
    '083': 'Richland',
    '085': 'Sabine',
    '087': 'St Bernard',
    '089': 'St Charles',
    '091': 'St Helena',
    '093': 'St James',
    '095': 'St John the Baptist',
    '097': 'St Landry',
    '099': 'St Martin',
    '101': 'St Mary',
    '103': 'St Tammany',
    '105': 'Tangipahoa',
    '107': 'Tensas',
    '109': 'Terrebonne',
    '111': 'Union',
    '113': 'Vermilion',
    '115': 'Vernon',
    '117': 'Washington',
    '119': 'Webster',
    '121': 'West Baton Rouge',
    '123': 'West Carroll',
    '125': 'West Feliciana',
    '127': 'Winn'
  },
  '23': {
    '001': 'Androscoggin',
    '003': 'Aroostook',
    '005': 'Cumberland',
    '007': 'Franklin',
    '009': 'Hancock',
    '011': 'Kennebec',
    '013': 'Knox',
    '015': 'Lincoln',
    '017': 'Oxford',
    '019': 'Penobscot',
    '021': 'Piscataquis',
    '023': 'Sagadahoc',
    '025': 'Somerset',
    '027': 'Waldo',
    '029': 'Washington',
    '031': 'York'
  },
  '24': {
    '001': 'Allegany',
    '003': 'Anne Arundel',
    '005': 'Baltimore',
    '009': 'Calvert',
    '011': 'Caroline',
    '013': 'Carroll',
    '015': 'Cecil',
    '017': 'Charles',
    '019': 'Dorchester',
    '021': 'Frederick',
    '023': 'Garrett',
    '025': 'Harford',
    '027': 'Howard',
    '029': 'Kent',
    '031': 'Montgomery',
    '033': 'Prince George\'s',
    '035': 'Queen Anne\'s',
    '037': 'St Mary\'s',
    '039': 'Somerset',
    '041': 'Talbot',
    '043': 'Washington',
    '045': 'Wicomico',
    '047': 'Worcester',
    '510': 'Baltimore City'
  },
  '25': {
    '001': 'Barnstable',
    '003': 'Berkshire',
    '005': 'Bristol',
    '007': 'Dukes',
    '009': 'Essex',
    '011': 'Franklin',
    '013': 'Hampden',
    '015': 'Hampshire',
    '017': 'Middlesex',
    '019': 'Nantucket',
    '021': 'Norfolk',
    '023': 'Plymouth',
    '025': 'Suffolk',
    '027': 'Worcester'
  },
  '26': {
    '001': 'Alcona',
    '003': 'Alger',
    '005': 'Allegan',
    '007': 'Alpena',
    '009': 'Antrim',
    '011': 'Arenac',
    '013': 'Baraga',
    '015': 'Barry',
    '017': 'Bay',
    '019': 'Benzie',
    '021': 'Berrien',
    '023': 'Branch',
    '025': 'Calhoun',
    '027': 'Cass',
    '029': 'Charlevoix',
    '031': 'Cheboygan',
    '033': 'Chippewa',
    '035': 'Clare',
    '037': 'Clinton',
    '039': 'Crawford',
    '041': 'Delta',
    '043': 'Dickinson',
    '045': 'Eaton',
    '047': 'Emmet',
    '049': 'Genesee',
    '051': 'Gladwin',
    '053': 'Gogebic',
    '055': 'Grand Traverse',
    '057': 'Gratiot',
    '059': 'Hillsdale',
    '061': 'Houghton',
    '063': 'Huron',
    '065': 'Ingham',
    '067': 'Ionia',
    '069': 'Iosco',
    '071': 'Iron',
    '073': 'Isabella',
    '075': 'Jackson',
    '077': 'Kalamazoo',
    '079': 'Kalkaska',
    '081': 'Kent',
    '083': 'Keweenaw',
    '085': 'Lake',
    '087': 'Lapeer',
    '089': 'Leelanau',
    '091': 'Lenawee',
    '093': 'Livingston',
    '095': 'Luce',
    '097': 'Mackinac',
    '099': 'Macomb',
    '101': 'Manistee',
    '103': 'Marquette',
    '105': 'Mason',
    '107': 'Mecosta',
    '109': 'Menominee',
    '111': 'Midland',
    '113': 'Missaukee',
    '115': 'Monroe',
    '117': 'Montcalm',
    '119': 'Montmorency',
    '121': 'Muskegon',
    '123': 'Newaygo',
    '125': 'Oakland',
    '127': 'Oceana',
    '129': 'Ogemaw',
    '131': 'Ontonagon',
    '133': 'Osceola',
    '135': 'Oscoda',
    '137': 'Otsego',
    '139': 'Ottawa',
    '141': 'Presque Isle',
    '143': 'Roscommon',
    '145': 'Saginaw',
    '147': 'St Clair',
    '149': 'St Joseph',
    '151': 'Sanilac',
    '153': 'Schoolcraft',
    '155': 'Shiawassee',
    '157': 'Tuscola',
    '159': 'Van Buren',
    '161': 'Washtenaw',
    '163': 'Wayne',
    '165': 'Wexford'
  },
  '27': {
    '001': 'Aitkin',
    '003': 'Anoka',
    '005': 'Becker',
    '007': 'Beltrami',
    '009': 'Benton',
    '011': 'Big Stone',
    '013': 'Blue Earth',
    '015': 'Brown',
    '017': 'Carlton',
    '019': 'Carver',
    '021': 'Cass',
    '023': 'Chippewa',
    '025': 'Chisago',
    '027': 'Clay',
    '029': 'Clearwater',
    '031': 'Cook',
    '033': 'Cottonwood',
    '035': 'Crow Wing',
    '037': 'Dakota',
    '039': 'Dodge',
    '041': 'Douglas',
    '043': 'Faribault',
    '045': 'Fillmore',
    '047': 'Freeborn',
    '049': 'Goodhue',
    '051': 'Grant',
    '053': 'Hennepin',
    '055': 'Houston',
    '057': 'Hubbard',
    '059': 'Isanti',
    '061': 'Itasca',
    '063': 'Jackson',
    '065': 'Kanabec',
    '067': 'Kandiyohi',
    '069': 'Kittson',
    '071': 'Koochiching',
    '073': 'Lac qui Parle',
    '075': 'Lake',
    '077': 'Lake of the Woods',
    '079': 'Le Sueur',
    '081': 'Lincoln',
    '083': 'Lyon',
    '085': 'McLeod',
    '087': 'Mahnomen',
    '089': 'Marshall',
    '091': 'Martin',
    '093': 'Meeker',
    '095': 'Mille Lacs',
    '097': 'Morrison',
    '099': 'Mower',
    '101': 'Murray',
    '103': 'Nicollet',
    '105': 'Nobles',
    '107': 'Norman',
    '109': 'Olmsted',
    '111': 'Otter Tail',
    '113': 'Pennington',
    '115': 'Pine',
    '117': 'Pipestone',
    '119': 'Polk',
    '121': 'Pope',
    '123': 'Ramsey',
    '125': 'Red Lake',
    '127': 'Redwood',
    '129': 'Renville',
    '131': 'Rice',
    '133': 'Rock',
    '135': 'Roseau',
    '137': 'St Louis',
    '139': 'Scott',
    '141': 'Sherburne',
    '143': 'Sibley',
    '145': 'Stearns',
    '147': 'Steele',
    '149': 'Stevens',
    '151': 'Swift',
    '153': 'Todd',
    '155': 'Traverse',
    '157': 'Wabasha',
    '159': 'Wadena',
    '161': 'Waseca',
    '163': 'Washington',
    '165': 'Watonwan',
    '167': 'Wilkin',
    '169': 'Winona',
    '171': 'Wright',
    '173': 'Yellow Medicine'
  },
  '28': {
    '001': 'Adams',
    '003': 'Alcorn',
    '005': 'Amite',
    '007': 'Attala',
    '009': 'Benton',
    '011': 'Bolivar',
    '013': 'Calhoun',
    '015': 'Carroll',
    '017': 'Chickasaw',
    '019': 'Choctaw',
    '021': 'Claiborne',
    '023': 'Clarke',
    '025': 'Clay',
    '027': 'Coahoma',
    '029': 'Copiah',
    '031': 'Covington',
    '033': 'DeSoto',
    '035': 'Forrest',
    '037': 'Franklin',
    '039': 'George',
    '041': 'Greene',
    '043': 'Grenada',
    '045': 'Hancock',
    '047': 'Harrison',
    '049': 'Hinds',
    '051': 'Holmes',
    '053': 'Humphreys',
    '055': 'Issaquena',
    '057': 'Itawamba',
    '059': 'Jackson',
    '061': 'Jasper',
    '063': 'Jefferson',
    '065': 'Jefferson Davis',
    '067': 'Jones',
    '069': 'Kemper',
    '071': 'Lafayette',
    '073': 'Lamar',
    '075': 'Lauderdale',
    '077': 'Lawrence',
    '079': 'Leake',
    '081': 'Lee',
    '083': 'Leflore',
    '085': 'Lincoln',
    '087': 'Lowndes',
    '089': 'Madison',
    '091': 'Marion',
    '093': 'Marshall',
    '095': 'Monroe',
    '097': 'Montgomery',
    '099': 'Neshoba',
    '101': 'Newton',
    '103': 'Noxubee',
    '105': 'Oktibbeha',
    '107': 'Panola',
    '109': 'Pearl River',
    '111': 'Perry',
    '113': 'Pike',
    '115': 'Pontotoc',
    '117': 'Prentiss',
    '119': 'Quitman',
    '121': 'Rankin',
    '123': 'Scott',
    '125': 'Sharkey',
    '127': 'Simpson',
    '129': 'Smith',
    '131': 'Stone',
    '133': 'Sunflower',
    '135': 'Tallahatchie',
    '137': 'Tate',
    '139': 'Tippah',
    '141': 'Tishomingo',
    '143': 'Tunica',
    '145': 'Union',
    '147': 'Walthall',
    '149': 'Warren',
    '151': 'Washington',
    '153': 'Wayne',
    '155': 'Webster',
    '157': 'Wilkinson',
    '159': 'Winston',
    '161': 'Yalobusha',
    '163': 'Yazoo'
  },
  '29': {
    '001': 'Adair',
    '003': 'Andrew',
    '005': 'Atchison',
    '007': 'Audrain',
    '009': 'Barry',
    '011': 'Barton',
    '013': 'Bates',
    '015': 'Benton',
    '017': 'Bollinger',
    '019': 'Boone',
    '021': 'Buchanan',
    '023': 'Butler',
    '025': 'Caldwell',
    '027': 'Callaway',
    '029': 'Camden',
    '031': 'Cape Girardeau',
    '033': 'Carroll',
    '035': 'Carter',
    '037': 'Cass',
    '039': 'Cedar',
    '041': 'Chariton',
    '043': 'Christian',
    '045': 'Clark',
    '047': 'Clay',
    '049': 'Clinton',
    '051': 'Cole',
    '053': 'Cooper',
    '055': 'Crawford',
    '057': 'Dade',
    '059': 'Dallas',
    '061': 'Daviess',
    '063': 'DeKalb',
    '065': 'Dent',
    '067': 'Douglas',
    '069': 'Dunklin',
    '071': 'Franklin',
    '073': 'Gasconade',
    '075': 'Gentry',
    '077': 'Greene',
    '079': 'Grundy',
    '081': 'Harrison',
    '083': 'Henry',
    '085': 'Hickory',
    '087': 'Holt',
    '089': 'Howard',
    '091': 'Howell',
    '093': 'Iron',
    '095': 'Jackson',
    '097': 'Jasper',
    '099': 'Jefferson',
    '101': 'Johnson',
    '103': 'Knox',
    '105': 'Laclede',
    '107': 'Lafayette',
    '109': 'Lawrence',
    '111': 'Lewis',
    '113': 'Lincoln',
    '115': 'Linn',
    '117': 'Livingston',
    '119': 'McDonald',
    '121': 'Macon',
    '123': 'Madison',
    '125': 'Maries',
    '127': 'Marion',
    '129': 'Mercer',
    '131': 'Miller',
    '133': 'Mississippi',
    '135': 'Moniteau',
    '137': 'Monroe',
    '139': 'Montgomery',
    '141': 'Morgan',
    '143': 'New Madrid',
    '145': 'Newton',
    '147': 'Nodaway',
    '149': 'Oregon',
    '151': 'Osage',
    '153': 'Ozark',
    '155': 'Pemiscot',
    '157': 'Perry',
    '159': 'Pettis',
    '161': 'Phelps',
    '163': 'Pike',
    '165': 'Platte',
    '167': 'Polk',
    '169': 'Pulaski',
    '171': 'Putnam',
    '173': 'Ralls',
    '175': 'Randolph',
    '177': 'Ray',
    '179': 'Reynolds',
    '181': 'Ripley',
    '183': 'St Charles',
    '185': 'St Clair',
    '186': 'Ste Genevieve',
    '187': 'St Francois',
    '189': 'St Louis',
    '195': 'Saline',
    '197': 'Schuyler',
    '199': 'Scotland',
    '201': 'Scott',
    '203': 'Shannon',
    '205': 'Shelby',
    '207': 'Stoddard',
    '209': 'Stone',
    '211': 'Sullivan',
    '213': 'Taney',
    '215': 'Texas',
    '217': 'Vernon',
    '219': 'Warren',
    '221': 'Washington',
    '223': 'Wayne',
    '225': 'Webster',
    '227': 'Worth',
    '229': 'Wright',
    '510': 'St Louis City'
  },
  '30': {
    '001': 'Beaverhead',
    '003': 'Big Horn',
    '005': 'Blaine',
    '007': 'Broadwater',
    '009': 'Carbon',
    '011': 'Carter',
    '013': 'Cascade',
    '015': 'Chouteau',
    '017': 'Custer',
    '019': 'Daniels',
    '021': 'Dawson',
    '023': 'Deer Lodge',
    '025': 'Fallon',
    '027': 'Fergus',
    '029': 'Flathead',
    '031': 'Gallatin',
    '033': 'Garfield',
    '035': 'Glacier',
    '037': 'Golden Valley',
    '039': 'Granite',
    '041': 'Hill',
    '043': 'Jefferson',
    '045': 'Judith Basin',
    '047': 'Lake',
    '049': 'Lewis and Clark',
    '051': 'Liberty',
    '053': 'Lincoln',
    '055': 'McCone',
    '057': 'Madison',
    '059': 'Meagher',
    '061': 'Mineral',
    '063': 'Missoula',
    '065': 'Musselshell',
    '067': 'Park',
    '069': 'Petroleum',
    '071': 'Phillips',
    '073': 'Pondera',
    '075': 'Powder River',
    '077': 'Powell',
    '079': 'Prairie',
    '081': 'Ravalli',
    '083': 'Richland',
    '085': 'Roosevelt',
    '087': 'Rosebud',
    '089': 'Sanders',
    '091': 'Sheridan',
    '093': 'Silver Bow',
    '095': 'Stillwater',
    '097': 'Sweet Grass',
    '099': 'Teton',
    '101': 'Toole',
    '103': 'Treasure',
    '105': 'Valley',
    '107': 'Wheatland',
    '109': 'Wibaux',
    '111': 'Yellowstone'
  },
  '31': {
    '001': 'Adams',
    '003': 'Antelope',
    '005': 'Arthur',
    '007': 'Banner',
    '009': 'Blaine',
    '011': 'Boone',
    '013': 'Box Butte',
    '015': 'Boyd',
    '017': 'Brown',
    '019': 'Buffalo',
    '021': 'Burt',
    '023': 'Butler',
    '025': 'Cass',
    '027': 'Cedar',
    '029': 'Chase',
    '031': 'Cherry',
    '033': 'Cheyenne',
    '035': 'Clay',
    '037': 'Colfax',
    '039': 'Cuming',
    '041': 'Custer',
    '043': 'Dakota',
    '045': 'Dawes',
    '047': 'Dawson',
    '049': 'Deuel',
    '051': 'Dixon',
    '053': 'Dodge',
    '055': 'Douglas',
    '057': 'Dundy',
    '059': 'Fillmore',
    '061': 'Franklin',
    '063': 'Frontier',
    '065': 'Furnas',
    '067': 'Gage',
    '069': 'Garden',
    '071': 'Garfield',
    '073': 'Gosper',
    '075': 'Grant',
    '077': 'Greeley',
    '079': 'Hall',
    '081': 'Hamilton',
    '083': 'Harlan',
    '085': 'Hayes',
    '087': 'Hitchcock',
    '089': 'Holt',
    '091': 'Hooker',
    '093': 'Howard',
    '095': 'Jefferson',
    '097': 'Johnson',
    '099': 'Kearney',
    '101': 'Keith',
    '103': 'Keya Paha',
    '105': 'Kimball',
    '107': 'Knox',
    '109': 'Lancaster',
    '111': 'Lincoln',
    '113': 'Logan',
    '115': 'Loup',
    '117': 'McPherson',
    '119': 'Madison',
    '121': 'Merrick',
    '123': 'Morrill',
    '125': 'Nance',
    '127': 'Nemaha',
    '129': 'Nuckolls',
    '131': 'Otoe',
    '133': 'Pawnee',
    '135': 'Perkins',
    '137': 'Phelps',
    '139': 'Pierce',
    '141': 'Platte',
    '143': 'Polk',
    '145': 'Red Willow',
    '147': 'Richardson',
    '149': 'Rock',
    '151': 'Saline',
    '153': 'Sarpy',
    '155': 'Saunders',
    '157': 'Scotts Bluff',
    '159': 'Seward',
    '161': 'Sheridan',
    '163': 'Sherman',
    '165': 'Sioux',
    '167': 'Stanton',
    '169': 'Thayer',
    '171': 'Thomas',
    '173': 'Thurston',
    '175': 'Valley',
    '177': 'Washington',
    '179': 'Wayne',
    '181': 'Webster',
    '183': 'Wheeler',
    '185': 'York'
  },
  '32': {
    '001': 'Churchill',
    '003': 'Clark',
    '005': 'Douglas',
    '007': 'Elko',
    '009': 'Esmeralda',
    '011': 'Eureka',
    '013': 'Humboldt',
    '015': 'Lander',
    '017': 'Lincoln',
    '019': 'Lyon',
    '021': 'Mineral',
    '023': 'Nye',
    '027': 'Pershing',
    '029': 'Storey',
    '031': 'Washoe',
    '033': 'White Pine',
    '510': 'Carson City'
  },
  '33': {
    '001': 'Belknap',
    '003': 'Carroll',
    '005': 'Cheshire',
    '007': 'Coos',
    '009': 'Grafton',
    '011': 'Hillsborough',
    '013': 'Merrimack',
    '015': 'Rockingham',
    '017': 'Strafford',
    '019': 'Sullivan'
  },
  '34': {
    '001': 'Atlantic',
    '003': 'Bergen',
    '005': 'Burlington',
    '007': 'Camden',
    '009': 'Cape May',
    '011': 'Cumberland',
    '013': 'Essex',
    '015': 'Gloucester',
    '017': 'Hudson',
    '019': 'Hunterdon',
    '021': 'Mercer',
    '023': 'Middlesex',
    '025': 'Monmouth',
    '027': 'Morris',
    '029': 'Ocean',
    '031': 'Passaic',
    '033': 'Salem',
    '035': 'Somerset',
    '037': 'Sussex',
    '039': 'Union',
    '041': 'Warren'
  },
  '35': {
    '001': 'Bernalillo',
    '003': 'Catron',
    '005': 'Chaves',
    '006': 'Cibola',
    '007': 'Colfax',
    '009': 'Curry',
    '011': 'De Baca',
    '013': 'Doña Ana',
    '015': 'Eddy',
    '017': 'Grant',
    '019': 'Guadalupe',
    '021': 'Harding',
    '023': 'Hidalgo',
    '025': 'Lea',
    '027': 'Lincoln',
    '028': 'Los Alamos',
    '029': 'Luna',
    '031': 'McKinley',
    '033': 'Mora',
    '035': 'Otero',
    '037': 'Quay',
    '039': 'Rio Arriba',
    '041': 'Roosevelt',
    '043': 'Sandoval',
    '045': 'San Juan',
    '047': 'San Miguel',
    '049': 'Santa Fe',
    '051': 'Sierra',
    '053': 'Socorro',
    '055': 'Taos',
    '057': 'Torrance',
    '059': 'Union',
    '061': 'Valencia'
  },
  '36': {
    '001': 'Albany',
    '003': 'Allegany',
    '005': 'Bronx',
    '007': 'Broome',
    '009': 'Cattaraugus',
    '011': 'Cayuga',
    '013': 'Chautauqua',
    '015': 'Chemung',
    '017': 'Chenango',
    '019': 'Clinton',
    '021': 'Columbia',
    '023': 'Cortland',
    '025': 'Delaware',
    '027': 'Dutchess',
    '029': 'Erie',
    '031': 'Essex',
    '033': 'Franklin',
    '035': 'Fulton',
    '037': 'Genesee',
    '039': 'Greene',
    '041': 'Hamilton',
    '043': 'Herkimer',
    '045': 'Jefferson',
    '047': 'Kings',
    '049': 'Lewis',
    '051': 'Livingston',
    '053': 'Madison',
    '055': 'Monroe',
    '057': 'Montgomery',
    '059': 'Nassau',
    '061': 'New York',
    '063': 'Niagara',
    '065': 'Oneida',
    '067': 'Onondaga',
    '069': 'Ontario',
    '071': 'Orange',
    '073': 'Orleans',
    '075': 'Oswego',
    '077': 'Otsego',
    '079': 'Putnam',
    '081': 'Queens',
    '083': 'Rensselaer',
    '085': 'Richmond',
    '087': 'Rockland',
    '089': 'St Lawrence',
    '091': 'Saratoga',
    '093': 'Schenectady',
    '095': 'Schoharie',
    '097': 'Schuyler',
    '099': 'Seneca',
    '101': 'Steuben',
    '103': 'Suffolk',
    '105': 'Sullivan',
    '107': 'Tioga',
    '109': 'Tompkins',
    '111': 'Ulster',
    '113': 'Warren',
    '115': 'Washington',
    '117': 'Wayne',
    '119': 'Westchester',
    '121': 'Wyoming',
    '123': 'Yates'
  },
  '37': {
    '001': 'Alamance',
    '003': 'Alexander',
    '005': 'Alleghany',
    '007': 'Anson',
    '009': 'Ashe',
    '011': 'Avery',
    '013': 'Beaufort',
    '015': 'Bertie',
    '017': 'Bladen',
    '019': 'Brunswick',
    '021': 'Buncombe',
    '023': 'Burke',
    '025': 'Cabarrus',
    '027': 'Caldwell',
    '029': 'Camden',
    '031': 'Carteret',
    '033': 'Caswell',
    '035': 'Catawba',
    '037': 'Chatham',
    '039': 'Cherokee',
    '041': 'Chowan',
    '043': 'Clay',
    '045': 'Cleveland',
    '047': 'Columbus',
    '049': 'Craven',
    '051': 'Cumberland',
    '053': 'Currituck',
    '055': 'Dare',
    '057': 'Davidson',
    '059': 'Davie',
    '061': 'Duplin',
    '063': 'Durham',
    '065': 'Edgecombe',
    '067': 'Forsyth',
    '069': 'Franklin',
    '071': 'Gaston',
    '073': 'Gates',
    '075': 'Graham',
    '077': 'Granville',
    '079': 'Greene',
    '081': 'Guilford',
    '083': 'Halifax',
    '085': 'Harnett',
    '087': 'Haywood',
    '089': 'Henderson',
    '091': 'Hertford',
    '093': 'Hoke',
    '095': 'Hyde',
    '097': 'Iredell',
    '099': 'Jackson',
    '101': 'Johnston',
    '103': 'Jones',
    '105': 'Lee',
    '107': 'Lenoir',
    '109': 'Lincoln',
    '111': 'McDowell',
    '113': 'Macon',
    '115': 'Madison',
    '117': 'Martin',
    '119': 'Mecklenburg',
    '121': 'Mitchell',
    '123': 'Montgomery',
    '125': 'Moore',
    '127': 'Nash',
    '129': 'New Hanover',
    '131': 'Northampton',
    '133': 'Onslow',
    '135': 'Orange',
    '137': 'Pamlico',
    '139': 'Pasquotank',
    '141': 'Pender',
    '143': 'Perquimans',
    '145': 'Person',
    '147': 'Pitt',
    '149': 'Polk',
    '151': 'Randolph',
    '153': 'Richmond',
    '155': 'Robeson',
    '157': 'Rockingham',
    '159': 'Rowan',
    '161': 'Rutherford',
    '163': 'Sampson',
    '165': 'Scotland',
    '167': 'Stanly',
    '169': 'Stokes',
    '171': 'Surry',
    '173': 'Swain',
    '175': 'Transylvania',
    '177': 'Tyrrell',
    '179': 'Union',
    '181': 'Vance',
    '183': 'Wake',
    '185': 'Warren',
    '187': 'Washington',
    '189': 'Watauga',
    '191': 'Wayne',
    '193': 'Wilkes',
    '195': 'Wilson',
    '197': 'Yadkin',
    '199': 'Yancey'
  },
  '38': {
    '001': 'Adams',
    '003': 'Barnes',
    '005': 'Benson',
    '007': 'Billings',
    '009': 'Bottineau',
    '011': 'Bowman',
    '013': 'Burke',
    '015': 'Burleigh',
    '017': 'Cass',
    '019': 'Cavalier',
    '021': 'Dickey',
    '023': 'Divide',
    '025': 'Dunn',
    '027': 'Eddy',
    '029': 'Emmons',
    '031': 'Foster',
    '033': 'Golden Valley',
    '035': 'Grand Forks',
    '037': 'Grant',
    '039': 'Griggs',
    '041': 'Hettinger',
    '043': 'Kidder',
    '045': 'LaMoure',
    '047': 'Logan',
    '049': 'McHenry',
    '051': 'McIntosh',
    '053': 'McKenzie',
    '055': 'McLean',
    '057': 'Mercer',
    '059': 'Morton',
    '061': 'Mountrail',
    '063': 'Nelson',
    '065': 'Oliver',
    '067': 'Pembina',
    '069': 'Pierce',
    '071': 'Ramsey',
    '073': 'Ransom',
    '075': 'Renville',
    '077': 'Richland',
    '079': 'Rolette',
    '081': 'Sargent',
    '083': 'Sheridan',
    '085': 'Sioux',
    '087': 'Slope',
    '089': 'Stark',
    '091': 'Steele',
    '093': 'Stutsman',
    '095': 'Towner',
    '097': 'Traill',
    '099': 'Walsh',
    '101': 'Ward',
    '103': 'Wells',
    '105': 'Williams'
  },
  '39': {
    '001': 'Adams',
    '003': 'Allen',
    '005': 'Ashland',
    '007': 'Ashtabula',
    '009': 'Athens',
    '011': 'Auglaize',
    '013': 'Belmont',
    '015': 'Brown',
    '017': 'Butler',
    '019': 'Carroll',
    '021': 'Champaign',
    '023': 'Clark',
    '025': 'Clermont',
    '027': 'Clinton',
    '029': 'Columbiana',
    '031': 'Coshocton',
    '033': 'Crawford',
    '035': 'Cuyahoga',
    '037': 'Darke',
    '039': 'Defiance',
    '041': 'Delaware',
    '043': 'Erie',
    '045': 'Fairfield',
    '047': 'Fayette',
    '049': 'Franklin',
    '051': 'Fulton',
    '053': 'Gallia',
    '055': 'Geauga',
    '057': 'Greene',
    '059': 'Guernsey',
    '061': 'Hamilton',
    '063': 'Hancock',
    '065': 'Hardin',
    '067': 'Harrison',
    '069': 'Henry',
    '071': 'Highland',
    '073': 'Hocking',
    '075': 'Holmes',
    '077': 'Huron',
    '079': 'Jackson',
    '081': 'Jefferson',
    '083': 'Knox',
    '085': 'Lake',
    '087': 'Lawrence',
    '089': 'Licking',
    '091': 'Logan',
    '093': 'Lorain',
    '095': 'Lucas',
    '097': 'Madison',
    '099': 'Mahoning',
    '101': 'Marion',
    '103': 'Medina',
    '105': 'Meigs',
    '107': 'Mercer',
    '109': 'Miami',
    '111': 'Monroe',
    '113': 'Montgomery',
    '115': 'Morgan',
    '117': 'Morrow',
    '119': 'Muskingum',
    '121': 'Noble',
    '123': 'Ottawa',
    '125': 'Paulding',
    '127': 'Perry',
    '129': 'Pickaway',
    '131': 'Pike',
    '133': 'Portage',
    '135': 'Preble',
    '137': 'Putnam',
    '139': 'Richland',
    '141': 'Ross',
    '143': 'Sandusky',
    '145': 'Scioto',
    '147': 'Seneca',
    '149': 'Shelby',
    '151': 'Stark',
    '153': 'Summit',
    '155': 'Trumbull',
    '157': 'Tuscarawas',
    '159': 'Union',
    '161': 'Van Wert',
    '163': 'Vinton',
    '165': 'Warren',
    '167': 'Washington',
    '169': 'Wayne',
    '171': 'Williams',
    '173': 'Wood',
    '175': 'Wyandot'
  },
  '40': {
    '001': 'Adair',
    '003': 'Alfalfa',
    '005': 'Atoka',
    '007': 'Beaver',
    '009': 'Beckham',
    '011': 'Blaine',
    '013': 'Bryan',
    '015': 'Caddo',
    '017': 'Canadian',
    '019': 'Carter',
    '021': 'Cherokee',
    '023': 'Choctaw',
    '025': 'Cimarron',
    '027': 'Cleveland',
    '029': 'Coal',
    '031': 'Comanche',
    '033': 'Cotton',
    '035': 'Craig',
    '037': 'Creek',
    '039': 'Custer',
    '041': 'Delaware',
    '043': 'Dewey',
    '045': 'Ellis',
    '047': 'Garfield',
    '049': 'Garvin',
    '051': 'Grady',
    '053': 'Grant',
    '055': 'Greer',
    '057': 'Harmon',
    '059': 'Harper',
    '061': 'Haskell',
    '063': 'Hughes',
    '065': 'Jackson',
    '067': 'Jefferson',
    '069': 'Johnston',
    '071': 'Kay',
    '073': 'Kingfisher',
    '075': 'Kiowa',
    '077': 'Latimer',
    '079': 'Le Flore',
    '081': 'Lincoln',
    '083': 'Logan',
    '085': 'Love',
    '087': 'McClain',
    '089': 'McCurtain',
    '091': 'McIntosh',
    '093': 'Major',
    '095': 'Marshall',
    '097': 'Mayes',
    '099': 'Murray',
    '101': 'Muskogee',
    '103': 'Noble',
    '105': 'Nowata',
    '107': 'Okfuskee',
    '109': 'Oklahoma',
    '111': 'Okmulgee',
    '113': 'Osage',
    '115': 'Ottawa',
    '117': 'Pawnee',
    '119': 'Payne',
    '121': 'Pittsburg',
    '123': 'Pontotoc',
    '125': 'Pottawatomie',
    '127': 'Pushmataha',
    '129': 'Roger Mills',
    '131': 'Rogers',
    '133': 'Seminole',
    '135': 'Sequoyah',
    '137': 'Stephens',
    '139': 'Texas',
    '141': 'Tillman',
    '143': 'Tulsa',
    '145': 'Wagoner',
    '147': 'Washington',
    '149': 'Washita',
    '151': 'Woods',
    '153': 'Woodward'
  },
  '41': {
    '001': 'Baker',
    '003': 'Benton',
    '005': 'Clackamas',
    '007': 'Clatsop',
    '009': 'Columbia',
    '011': 'Coos',
    '013': 'Crook',
    '015': 'Curry',
    '017': 'Deschutes',
    '019': 'Douglas',
    '021': 'Gilliam',
    '023': 'Grant',
    '025': 'Harney',
    '027': 'Hood River',
    '029': 'Jackson',
    '031': 'Jefferson',
    '033': 'Josephine',
    '035': 'Klamath',
    '037': 'Lake',
    '039': 'Lane',
    '041': 'Lincoln',
    '043': 'Linn',
    '045': 'Malheur',
    '047': 'Marion',
    '049': 'Morrow',
    '051': 'Multnomah',
    '053': 'Polk',
    '055': 'Sherman',
    '057': 'Tillamook',
    '059': 'Umatilla',
    '061': 'Union',
    '063': 'Wallowa',
    '065': 'Wasco',
    '067': 'Washington',
    '069': 'Wheeler',
    '071': 'Yamhill'
  },
  '42': {
    '001': 'Adams',
    '003': 'Allegheny',
    '005': 'Armstrong',
    '007': 'Beaver',
    '009': 'Bedford',
    '011': 'Berks',
    '013': 'Blair',
    '015': 'Bradford',
    '017': 'Bucks',
    '019': 'Butler',
    '021': 'Cambria',
    '023': 'Cameron',
    '025': 'Carbon',
    '027': 'Centre',
    '029': 'Chester',
    '031': 'Clarion',
    '033': 'Clearfield',
    '035': 'Clinton',
    '037': 'Columbia',
    '039': 'Crawford',
    '041': 'Cumberland',
    '043': 'Dauphin',
    '045': 'Delaware',
    '047': 'Elk',
    '049': 'Erie',
    '051': 'Fayette',
    '053': 'Forest',
    '055': 'Franklin',
    '057': 'Fulton',
    '059': 'Greene',
    '061': 'Huntingdon',
    '063': 'Indiana',
    '065': 'Jefferson',
    '067': 'Juniata',
    '069': 'Lackawanna',
    '071': 'Lancaster',
    '073': 'Lawrence',
    '075': 'Lebanon',
    '077': 'Lehigh',
    '079': 'Luzerne',
    '081': 'Lycoming',
    '083': 'McKean',
    '085': 'Mercer',
    '087': 'Mifflin',
    '089': 'Monroe',
    '091': 'Montgomery',
    '093': 'Montour',
    '095': 'Northampton',
    '097': 'Northumberland',
    '099': 'Perry',
    '101': 'Philadelphia',
    '103': 'Pike',
    '105': 'Potter',
    '107': 'Schuylkill',
    '109': 'Snyder',
    '111': 'Somerset',
    '113': 'Sullivan',
    '115': 'Susquehanna',
    '117': 'Tioga',
    '119': 'Union',
    '121': 'Venango',
    '123': 'Warren',
    '125': 'Washington',
    '127': 'Wayne',
    '129': 'Westmoreland',
    '131': 'Wyoming',
    '133': 'York'
  },
  '44': {
    '001': 'Bristol',
    '003': 'Kent',
    '005': 'Newport',
    '007': 'Providence',
    '009': 'Washington'
  },
  '45': {
    '001': 'Abbeville',
    '003': 'Aiken',
    '005': 'Allendale',
    '007': 'Anderson',
    '009': 'Bamberg',
    '011': 'Barnwell',
    '013': 'Beaufort',
    '015': 'Berkeley',
    '017': 'Calhoun',
    '019': 'Charleston',
    '021': 'Cherokee',
    '023': 'Chester',
    '025': 'Chesterfield',
    '027': 'Clarendon',
    '029': 'Colleton',
    '031': 'Darlington',
    '033': 'Dillon',
    '035': 'Dorchester',
    '037': 'Edgefield',
    '039': 'Fairfield',
    '041': 'Florence',
    '043': 'Georgetown',
    '045': 'Greenville',
    '047': 'Greenwood',
    '049': 'Hampton',
    '051': 'Horry',
    '053': 'Jasper',
    '055': 'Kershaw',
    '057': 'Lancaster',
    '059': 'Laurens',
    '061': 'Lee',
    '063': 'Lexington',
    '065': 'McCormick',
    '067': 'Marion',
    '069': 'Marlboro',
    '071': 'Newberry',
    '073': 'Oconee',
    '075': 'Orangeburg',
    '077': 'Pickens',
    '079': 'Richland',
    '081': 'Saluda',
    '083': 'Spartanburg',
    '085': 'Sumter',
    '087': 'Union',
    '089': 'Williamsburg',
    '091': 'York'
  },
  '46': {
    '003': 'Aurora',
    '005': 'Beadle',
    '007': 'Bennett',
    '009': 'Bon Homme',
    '011': 'Brookings',
    '013': 'Brown',
    '015': 'Brule',
    '017': 'Buffalo',
    '019': 'Butte',
    '021': 'Campbell',
    '023': 'Charles Mix',
    '025': 'Clark',
    '027': 'Clay',
    '029': 'Codington',
    '031': 'Corson',
    '033': 'Custer',
    '035': 'Davison',
    '037': 'Day',
    '039': 'Deuel',
    '041': 'Dewey',
    '043': 'Douglas',
    '045': 'Edmunds',
    '047': 'Fall River',
    '049': 'Faulk',
    '051': 'Grant',
    '053': 'Gregory',
    '055': 'Haakon',
    '057': 'Hamlin',
    '059': 'Hand',
    '061': 'Hanson',
    '063': 'Harding',
    '065': 'Hughes',
    '067': 'Hutchinson',
    '069': 'Hyde',
    '071': 'Jackson',
    '073': 'Jerauld',
    '075': 'Jones',
    '077': 'Kingsbury',
    '079': 'Lake',
    '081': 'Lawrence',
    '083': 'Lincoln',
    '085': 'Lyman',
    '087': 'McCook',
    '089': 'McPherson',
    '091': 'Marshall',
    '093': 'Meade',
    '095': 'Mellette',
    '097': 'Miner',
    '099': 'Minnehaha',
    '101': 'Moody',
    '102': 'Oglala Lakota',
    '103': 'Pennington',
    '105': 'Perkins',
    '107': 'Potter',
    '109': 'Roberts',
    '111': 'Sanborn',
    '115': 'Spink',
    '117': 'Stanley',
    '119': 'Sully',
    '121': 'Todd',
    '123': 'Tripp',
    '125': 'Turner',
    '127': 'Union',
    '129': 'Walworth',
    '135': 'Yankton',
    '137': 'Ziebach'
  },
  '47': {
    '001': 'Anderson',
    '003': 'Bedford',
    '005': 'Benton',
    '007': 'Bledsoe',
    '009': 'Blount',
    '011': 'Bradley',
    '013': 'Campbell',
    '015': 'Cannon',
    '017': 'Carroll',
    '019': 'Carter',
    '021': 'Cheatham',
    '023': 'Chester',
    '025': 'Claiborne',
    '027': 'Clay',
    '029': 'Cocke',
    '031': 'Coffee',
    '033': 'Crockett',
    '035': 'Cumberland',
    '037': 'Davidson',
    '039': 'Decatur',
    '041': 'DeKalb',
    '043': 'Dickson',
    '045': 'Dyer',
    '047': 'Fayette',
    '049': 'Fentress',
    '051': 'Franklin',
    '053': 'Gibson',
    '055': 'Giles',
    '057': 'Grainger',
    '059': 'Greene',
    '061': 'Grundy',
    '063': 'Hamblen',
    '065': 'Hamilton',
    '067': 'Hancock',
    '069': 'Hardeman',
    '071': 'Hardin',
    '073': 'Hawkins',
    '075': 'Haywood',
    '077': 'Henderson',
    '079': 'Henry',
    '081': 'Hickman',
    '083': 'Houston',
    '085': 'Humphreys',
    '087': 'Jackson',
    '089': 'Jefferson',
    '091': 'Johnson',
    '093': 'Knox',
    '095': 'Lake',
    '097': 'Lauderdale',
    '099': 'Lawrence',
    '101': 'Lewis',
    '103': 'Lincoln',
    '105': 'Loudon',
    '107': 'McMinn',
    '109': 'McNairy',
    '111': 'Macon',
    '113': 'Madison',
    '115': 'Marion',
    '117': 'Marshall',
    '119': 'Maury',
    '121': 'Meigs',
    '123': 'Monroe',
    '125': 'Montgomery',
    '127': 'Moore',
    '129': 'Morgan',
    '131': 'Obion',
    '133': 'Overton',
    '135': 'Perry',
    '137': 'Pickett',
    '139': 'Polk',
    '141': 'Putnam',
    '143': 'Rhea',
    '145': 'Roane',
    '147': 'Robertson',
    '149': 'Rutherford',
    '151': 'Scott',
    '153': 'Sequatchie',
    '155': 'Sevier',
    '157': 'Shelby',
    '159': 'Smith',
    '161': 'Stewart',
    '163': 'Sullivan',
    '165': 'Sumner',
    '167': 'Tipton',
    '169': 'Trousdale',
    '171': 'Unicoi',
    '173': 'Union',
    '175': 'Van Buren',
    '177': 'Warren',
    '179': 'Washington',
    '181': 'Wayne',
    '183': 'Weakley',
    '185': 'White',
    '187': 'Williamson',
    '189': 'Wilson'
  },
  '48': {
    '001': 'Anderson',
    '003': 'Andrews',
    '005': 'Angelina',
    '007': 'Aransas',
    '009': 'Archer',
    '011': 'Armstrong',
    '013': 'Atascosa',
    '015': 'Austin',
    '017': 'Bailey',
    '019': 'Bandera',
    '021': 'Bastrop',
    '023': 'Baylor',
    '025': 'Bee',
    '027': 'Bell',
    '029': 'Bexar',
    '031': 'Blanco',
    '033': 'Borden',
    '035': 'Bosque',
    '037': 'Bowie',
    '039': 'Brazoria',
    '041': 'Brazos',
    '043': 'Brewster',
    '045': 'Briscoe',
    '047': 'Brooks',
    '049': 'Brown',
    '051': 'Burleson',
    '053': 'Burnet',
    '055': 'Caldwell',
    '057': 'Calhoun',
    '059': 'Callahan',
    '061': 'Cameron',
    '063': 'Camp',
    '065': 'Carson',
    '067': 'Cass',
    '069': 'Castro',
    '071': 'Chambers',
    '073': 'Cherokee',
    '075': 'Childress',
    '077': 'Clay',
    '079': 'Cochran',
    '081': 'Coke',
    '083': 'Coleman',
    '085': 'Collin',
    '087': 'Collingsworth',
    '089': 'Colorado',
    '091': 'Comal',
    '093': 'Comanche',
    '095': 'Concho',
    '097': 'Cooke',
    '099': 'Coryell',
    '101': 'Cottle',
    '103': 'Crane',
    '105': 'Crockett',
    '107': 'Crosby',
    '109': 'Culberson',
    '111': 'Dallam',
    '113': 'Dallas',
    '115': 'Dawson',
    '117': 'Deaf Smith',
    '119': 'Delta',
    '121': 'Denton',
    '123': 'DeWitt',
    '125': 'Dickens',
    '127': 'Dimmit',
    '129': 'Donley',
    '131': 'Duval',
    '133': 'Eastland',
    '135': 'Ector',
    '137': 'Edwards',
    '139': 'Ellis',
    '141': 'El Paso',
    '143': 'Erath',
    '145': 'Falls',
    '147': 'Fannin',
    '149': 'Fayette',
    '151': 'Fisher',
    '153': 'Floyd',
    '155': 'Foard',
    '157': 'Fort Bend',
    '159': 'Franklin',
    '161': 'Freestone',
    '163': 'Frio',
    '165': 'Gaines',
    '167': 'Galveston',
    '169': 'Garza',
    '171': 'Gillespie',
    '173': 'Glasscock',
    '175': 'Goliad',
    '177': 'Gonzales',
    '179': 'Gray',
    '181': 'Grayson',
    '183': 'Gregg',
    '185': 'Grimes',
    '187': 'Guadalupe',
    '189': 'Hale',
    '191': 'Hall',
    '193': 'Hamilton',
    '195': 'Hansford',
    '197': 'Hardeman',
    '199': 'Hardin',
    '201': 'Harris',
    '203': 'Harrison',
    '205': 'Hartley',
    '207': 'Haskell',
    '209': 'Hays',
    '211': 'Hemphill',
    '213': 'Henderson',
    '215': 'Hidalgo',
    '217': 'Hill',
    '219': 'Hockley',
    '221': 'Hood',
    '223': 'Hopkins',
    '225': 'Houston',
    '227': 'Howard',
    '229': 'Hudspeth',
    '231': 'Hunt',
    '233': 'Hutchinson',
    '235': 'Irion',
    '237': 'Jack',
    '239': 'Jackson',
    '241': 'Jasper',
    '243': 'Jeff Davis',
    '245': 'Jefferson',
    '247': 'Jim Hogg',
    '249': 'Jim Wells',
    '251': 'Johnson',
    '253': 'Jones',
    '255': 'Karnes',
    '257': 'Kaufman',
    '259': 'Kendall',
    '261': 'Kenedy',
    '263': 'Kent',
    '265': 'Kerr',
    '267': 'Kimble',
    '269': 'King',
    '271': 'Kinney',
    '273': 'Kleberg',
    '275': 'Knox',
    '277': 'Lamar',
    '279': 'Lamb',
    '281': 'Lampasas',
    '283': 'La Salle',
    '285': 'Lavaca',
    '287': 'Lee',
    '289': 'Leon',
    '291': 'Liberty',
    '293': 'Limestone',
    '295': 'Lipscomb',
    '297': 'Live Oak',
    '299': 'Llano',
    '301': 'Loving',
    '303': 'Lubbock',
    '305': 'Lynn',
    '307': 'McCulloch',
    '309': 'McLennan',
    '311': 'McMullen',
    '313': 'Madison',
    '315': 'Marion',
    '317': 'Martin',
    '319': 'Mason',
    '321': 'Matagorda',
    '323': 'Maverick',
    '325': 'Medina',
    '327': 'Menard',
    '329': 'Midland',
    '331': 'Milam',
    '333': 'Mills',
    '335': 'Mitchell',
    '337': 'Montague',
    '339': 'Montgomery',
    '341': 'Moore',
    '343': 'Morris',
    '345': 'Motley',
    '347': 'Nacogdoches',
    '349': 'Navarro',
    '351': 'Newton',
    '353': 'Nolan',
    '355': 'Nueces',
    '357': 'Ochiltree',
    '359': 'Oldham',
    '361': 'Orange',
    '363': 'Palo Pinto',
    '365': 'Panola',
    '367': 'Parker',
    '369': 'Parmer',
    '371': 'Pecos',
    '373': 'Polk',
    '375': 'Potter',
    '377': 'Presidio',
    '379': 'Rains',
    '381': 'Randall',
    '383': 'Reagan',
    '385': 'Real',
    '387': 'Red River',
    '389': 'Reeves',
    '391': 'Refugio',
    '393': 'Roberts',
    '395': 'Robertson',
    '397': 'Rockwall',
    '399': 'Runnels',
    '401': 'Rusk',
    '403': 'Sabine',
    '405': 'San Augustine',
    '407': 'San Jacinto',
    '409': 'San Patricio',
    '411': 'San Saba',
    '413': 'Schleicher',
    '415': 'Scurry',
    '417': 'Shackelford',
    '419': 'Shelby',
    '421': 'Sherman',
    '423': 'Smith',
    '425': 'Somervell',
    '427': 'Starr',
    '429': 'Stephens',
    '431': 'Sterling',
    '433': 'Stonewall',
    '435': 'Sutton',
    '437': 'Swisher',
    '439': 'Tarrant',
    '441': 'Taylor',
    '443': 'Terrell',
    '445': 'Terry',
    '447': 'Throckmorton',
    '449': 'Titus',
    '451': 'Tom Green',
    '453': 'Travis',
    '455': 'Trinity',
    '457': 'Tyler',
    '459': 'Upshur',
    '461': 'Upton',
    '463': 'Uvalde',
    '465': 'Val Verde',
    '467': 'Van Zandt',
    '469': 'Victoria',
    '471': 'Walker',
    '473': 'Waller',
    '475': 'Ward',
    '477': 'Washington',
    '479': 'Webb',
    '481': 'Wharton',
    '483': 'Wheeler',
    '485': 'Wichita',
    '487': 'Wilbarger',
    '489': 'Willacy',
    '491': 'Williamson',
    '493': 'Wilson',
    '495': 'Winkler',
    '497': 'Wise',
    '499': 'Wood',
    '501': 'Yoakum',
    '503': 'Young',
    '505': 'Zapata',
    '507': 'Zavala'
  },
  '49': {
    '001': 'Beaver',
    '003': 'Box Elder',
    '005': 'Cache',
    '007': 'Carbon',
    '009': 'Daggett',
    '011': 'Davis',
    '013': 'Duchesne',
    '015': 'Emery',
    '017': 'Garfield',
    '019': 'Grand',
    '021': 'Iron',
    '023': 'Juab',
    '025': 'Kane',
    '027': 'Millard',
    '029': 'Morgan',
    '031': 'Piute',
    '033': 'Rich',
    '035': 'Salt Lake',
    '037': 'San Juan',
    '039': 'Sanpete',
    '041': 'Sevier',
    '043': 'Summit',
    '045': 'Tooele',
    '047': 'Uintah',
    '049': 'Utah',
    '051': 'Wasatch',
    '053': 'Washington',
    '055': 'Wayne',
    '057': 'Weber'
  },
  '50': {
    '001': 'Addison',
    '003': 'Bennington',
    '005': 'Caledonia',
    '007': 'Chittenden',
    '009': 'Essex',
    '011': 'Franklin',
    '013': 'Grand Isle',
    '015': 'Lamoille',
    '017': 'Orange',
    '019': 'Orleans',
    '021': 'Rutland',
    '023': 'Washington',
    '025': 'Windham',
    '027': 'Windsor'
  },
  '51': {
    '001': 'Accomack',
    '003': 'Albemarle',
    '005': 'Alleghany',
    '007': 'Amelia',
    '009': 'Amherst',
    '011': 'Appomattox',
    '013': 'Arlington',
    '015': 'Augusta',
    '017': 'Bath',
    '019': 'Bedford',
    '021': 'Bland',
    '023': 'Botetourt',
    '025': 'Brunswick',
    '027': 'Buchanan',
    '029': 'Buckingham',
    '031': 'Campbell',
    '033': 'Caroline',
    '035': 'Carroll',
    '036': 'Charles City',
    '037': 'Charlotte',
    '041': 'Chesterfield',
    '043': 'Clarke',
    '045': 'Craig',
    '047': 'Culpeper',
    '049': 'Cumberland',
    '051': 'Dickenson',
    '053': 'Dinwiddie',
    '057': 'Essex',
    '059': 'Fairfax',
    '061': 'Fauquier',
    '063': 'Floyd',
    '065': 'Fluvanna',
    '067': 'Franklin',
    '069': 'Frederick',
    '071': 'Giles',
    '073': 'Gloucester',
    '075': 'Goochland',
    '077': 'Grayson',
    '079': 'Greene',
    '081': 'Greensville',
    '083': 'Halifax',
    '085': 'Hanover',
    '087': 'Henrico',
    '089': 'Henry',
    '091': 'Highland',
    '093': 'Isle of Wight',
    '095': 'James City',
    '097': 'King and Queen',
    '099': 'King George',
    '101': 'King William',
    '103': 'Lancaster',
    '105': 'Lee',
    '107': 'Loudoun',
    '109': 'Louisa',
    '111': 'Lunenburg',
    '113': 'Madison',
    '115': 'Mathews',
    '117': 'Mecklenburg',
    '119': 'Middlesex',
    '121': 'Montgomery',
    '125': 'Nelson',
    '127': 'New Kent',
    '131': 'Northampton',
    '133': 'Northumberland',
    '135': 'Nottoway',
    '137': 'Orange',
    '139': 'Page',
    '141': 'Patrick',
    '143': 'Pittsylvania',
    '145': 'Powhatan',
    '147': 'Prince Edward',
    '149': 'Prince George',
    '153': 'Prince William',
    '155': 'Pulaski',
    '157': 'Rappahannock',
    '159': 'Richmond',
    '161': 'Roanoke',
    '163': 'Rockbridge',
    '165': 'Rockingham',
    '167': 'Russell',
    '169': 'Scott',
    '171': 'Shenandoah',
    '173': 'Smyth',
    '175': 'Southampton',
    '177': 'Spotsylvania',
    '179': 'Stafford',
    '181': 'Surry',
    '183': 'Sussex',
    '185': 'Tazewell',
    '187': 'Warren',
    '191': 'Washington',
    '193': 'Westmoreland',
    '195': 'Wise',
    '197': 'Wythe',
    '199': 'York',
    '510': 'Alexandria City',
    '520': 'Bristol City',
    '530': 'Buena Vista City',
    '540': 'Charlottesville City',
    '550': 'Chesapeake City',
    '570': 'Colonial Heights City',
    '580': 'Covington City',
    '590': 'Danville City',
    '595': 'Emporia City',
    '600': 'Fairfax City',
    '610': 'Falls Church City',
    '620': 'Franklin City',
    '630': 'Fredericksburg City',
    '640': 'Galax City',
    '650': 'Hampton City',
    '660': 'Harrisonburg City',
    '670': 'Hopewell City',
    '678': 'Lexington City',
    '680': 'Lynchburg City',
    '683': 'Manassas City',
    '685': 'Manassas Park City',
    '690': 'Martinsville City',
    '700': 'Newport News City',
    '710': 'Norfolk City',
    '720': 'Norton City',
    '730': 'Petersburg City',
    '735': 'Poquoson City',
    '740': 'Portsmouth City',
    '750': 'Radford City',
    '760': 'Richmond City',
    '770': 'Roanoke City',
    '775': 'Salem City',
    '790': 'Staunton City',
    '800': 'Suffolk City',
    '810': 'Virginia Beach City',
    '820': 'Waynesboro City',
    '830': 'Williamsburg City',
    '840': 'Winchester City'
  },
  '53': {
    '001': 'Adams',
    '003': 'Asotin',
    '005': 'Benton',
    '007': 'Chelan',
    '009': 'Clallam',
    '011': 'Clark',
    '013': 'Columbia',
    '015': 'Cowlitz',
    '017': 'Douglas',
    '019': 'Ferry',
    '021': 'Franklin',
    '023': 'Garfield',
    '025': 'Grant',
    '027': 'Grays Harbor',
    '029': 'Island',
    '031': 'Jefferson',
    '033': 'King',
    '035': 'Kitsap',
    '037': 'Kittitas',
    '039': 'Klickitat',
    '041': 'Lewis',
    '043': 'Lincoln',
    '045': 'Mason',
    '047': 'Okanogan',
    '049': 'Pacific',
    '051': 'Pend Oreille',
    '053': 'Pierce',
    '055': 'San Juan',
    '057': 'Skagit',
    '059': 'Skamania',
    '061': 'Snohomish',
    '063': 'Spokane',
    '065': 'Stevens',
    '067': 'Thurston',
    '069': 'Wahkiakum',
    '071': 'Walla Walla',
    '073': 'Whatcom',
    '075': 'Whitman',
    '077': 'Yakima'
  },
  '54': {
    '001': 'Barbour',
    '003': 'Berkeley',
    '005': 'Boone',
    '007': 'Braxton',
    '009': 'Brooke',
    '011': 'Cabell',
    '013': 'Calhoun',
    '015': 'Clay',
    '017': 'Doddridge',
    '019': 'Fayette',
    '021': 'Gilmer',
    '023': 'Grant',
    '025': 'Greenbrier',
    '027': 'Hampshire',
    '029': 'Hancock',
    '031': 'Hardy',
    '033': 'Harrison',
    '035': 'Jackson',
    '037': 'Jefferson',
    '039': 'Kanawha',
    '041': 'Lewis',
    '043': 'Lincoln',
    '045': 'Logan',
    '047': 'McDowell',
    '049': 'Marion',
    '051': 'Marshall',
    '053': 'Mason',
    '055': 'Mercer',
    '057': 'Mineral',
    '059': 'Mingo',
    '061': 'Monongalia',
    '063': 'Monroe',
    '065': 'Morgan',
    '067': 'Nicholas',
    '069': 'Ohio',
    '071': 'Pendleton',
    '073': 'Pleasants',
    '075': 'Pocahontas',
    '077': 'Preston',
    '079': 'Putnam',
    '081': 'Raleigh',
    '083': 'Randolph',
    '085': 'Ritchie',
    '087': 'Roane',
    '089': 'Summers',
    '091': 'Taylor',
    '093': 'Tucker',
    '095': 'Tyler',
    '097': 'Upshur',
    '099': 'Wayne',
    '101': 'Webster',
    '103': 'Wetzel',
    '105': 'Wirt',
    '107': 'Wood',
    '109': 'Wyoming'
  },
  '55': {
    '001': 'Adams',
    '003': 'Ashland',
    '005': 'Barron',
    '007': 'Bayfield',
    '009': 'Brown',
    '011': 'Buffalo',
    '013': 'Burnett',
    '015': 'Calumet',
    '017': 'Chippewa',
    '019': 'Clark',
    '021': 'Columbia',
    '023': 'Crawford',
    '025': 'Dane',
    '027': 'Dodge',
    '029': 'Door',
    '031': 'Douglas',
    '033': 'Dunn',
    '035': 'Eau Claire',
    '037': 'Florence',
    '039': 'Fond du Lac',
    '041': 'Forest',
    '043': 'Grant',
    '045': 'Green',
    '047': 'Green Lake',
    '049': 'Iowa',
    '051': 'Iron',
    '053': 'Jackson',
    '055': 'Jefferson',
    '057': 'Juneau',
    '059': 'Kenosha',
    '061': 'Kewaunee',
    '063': 'La Crosse',
    '065': 'Lafayette',
    '067': 'Langlade',
    '069': 'Lincoln',
    '071': 'Manitowoc',
    '073': 'Marathon',
    '075': 'Marinette',
    '077': 'Marquette',
    '078': 'Menominee',
    '079': 'Milwaukee',
    '081': 'Monroe',
    '083': 'Oconto',
    '085': 'Oneida',
    '087': 'Outagamie',
    '089': 'Ozaukee',
    '091': 'Pepin',
    '093': 'Pierce',
    '095': 'Polk',
    '097': 'Portage',
    '099': 'Price',
    '101': 'Racine',
    '103': 'Richland',
    '105': 'Rock',
    '107': 'Rusk',
    '109': 'St Croix',
    '111': 'Sauk',
    '113': 'Sawyer',
    '115': 'Shawano',
    '117': 'Sheboygan',
    '119': 'Taylor',
    '121': 'Trempealeau',
    '123': 'Vernon',
    '125': 'Vilas',
    '127': 'Walworth',
    '129': 'Washburn',
    '131': 'Washington',
    '133': 'Waukesha',
    '135': 'Waupaca',
    '137': 'Waushara',
    '139': 'Winnebago',
    '141': 'Wood'
  },
  '56': {
    '001': 'Albany',
    '003': 'Big Horn',
    '005': 'Campbell',
    '007': 'Carbon',
    '009': 'Converse',
    '011': 'Crook',
    '013': 'Fremont',
    '015': 'Goshen',
    '017': 'Hot Springs',
    '019': 'Johnson',
    '021': 'Laramie',
    '023': 'Lincoln',
    '025': 'Natrona',
    '027': 'Niobrara',
    '029': 'Park',
    '031': 'Platte',
    '033': 'Sheridan',
    '035': 'Sublette',
    '037': 'Sweetwater',
    '039': 'Teton',
    '041': 'Uinta',
    '043': 'Washakie',
    '045': 'Weston'
  },
  '60': {
    '010': 'Eastern District',
    '020': 'Manu\'a',
    '050': 'Western District'
  },
  '66': {
    '010': 'Guam'
  },
  '69': {
    '100': 'Rota',
    '110': 'Saipan',
    '120': 'Tinian'
  },
  '72': {
    '001': 'Adjuntas',
    '003': 'Aguada',
    '005': 'Aguadilla',
    '007': 'Aguas Buenas',
    '009': 'Aibonito',
    '011': 'Añasco',
    '013': 'Arecibo',
    '015': 'Arroyo',
    '017': 'Barceloneta',
    '019': 'Barranquitas',
    '021': 'Bayamón',
    '023': 'Cabo Rojo',
    '025': 'Caguas',
    '027': 'Camuy',
    '029': 'Canóvanas',
    '031': 'Carolina',
    '033': 'Cataño',
    '035': 'Cayey',
    '037': 'Ceiba',
    '039': 'Ciales',
    '041': 'Cidra',
    '043': 'Coamo',
    '045': 'Comerío',
    '047': 'Corozal',
    '049': 'Culebra',
    '051': 'Dorado',
    '053': 'Fajardo',
    '054': 'Florida',
    '055': 'Guánica',
    '057': 'Guayama',
    '059': 'Guayanilla',
    '061': 'Guaynabo',
    '063': 'Gurabo',
    '065': 'Hatillo',
    '067': 'Hormigueros',
    '069': 'Humacao',
    '071': 'Isabela',
    '073': 'Jayuya',
    '075': 'Juana Díaz',
    '077': 'Juncos',
    '079': 'Lajas',
    '081': 'Lares',
    '083': 'Las Marías',
    '085': 'Las Piedras',
    '087': 'Loíza',
    '089': 'Luquillo',
    '091': 'Manatí',
    '093': 'Maricao',
    '095': 'Maunabo',
    '097': 'Mayagüez',
    '099': 'Moca',
    '101': 'Morovis',
    '103': 'Naguabo',
    '105': 'Naranjito',
    '107': 'Orocovis',
    '109': 'Patillas',
    '111': 'Peñuelas',
    '113': 'Ponce',
    '115': 'Quebradillas',
    '117': 'Rincón',
    '119': 'Río Grande',
    '121': 'Sabana Grande',
    '123': 'Salinas',
    '125': 'San Germán',
    '127': 'San Juan',
    '129': 'San Lorenzo',
    '131': 'San Sebastián',
    '133': 'Santa Isabel',
    '135': 'Toa Alta',
    '137': 'Toa Baja',
    '139': 'Trujillo Alto',
    '141': 'Utuado',
    '143': 'Vega Alta',
    '145': 'Vega Baja',
    '147': 'Vieques',
    '149': 'Villalba',
    '151': 'Yabucoa',
    '153': 'Yauco'
  },
  '78': {
    '010': 'St Croix',
    '020': 'St John',
    '030': 'St Thomas'
  }
}

export const statesFormattedForSelect =
[
  {
    'label': 'Alabama',
    'value': '01',
    'abbreviation': 'AL'
  },
  {
    'label': 'Alaska',
    'value': '02',
    'abbreviation': 'AK'
  },
  {
    'label': 'Arizona',
    'value': '04',
    'abbreviation': 'AZ'
  },
  {
    'label': 'Arkansas',
    'value': '05',
    'abbreviation': 'AR'
  },
  {
    'label': 'California',
    'value': '06',
    'abbreviation': 'CA'
  },
  {
    'label': 'Colorado',
    'value': '08',
    'abbreviation': 'CO'
  },
  {
    'label': 'Connecticut',
    'value': '09',
    'abbreviation': 'CT'
  },
  {
    'label': 'Delaware',
    'value': '10',
    'abbreviation': 'DE'
  },
  {
    'label': 'District of Columbia',
    'value': '11',
    'abbreviation': 'DC'
  },
  {
    'label': 'Florida',
    'value': '12',
    'abbreviation': 'FL'
  },
  {
    'label': 'Georgia',
    'value': '13',
    'abbreviation': 'GA'
  },
  {
    'label': 'Hawaii',
    'value': '15',
    'abbreviation': 'HI'
  },
  {
    'label': 'Idaho',
    'value': '16',
    'abbreviation': 'ID'
  },
  {
    'label': 'Illinois',
    'value': '17',
    'abbreviation': 'IL'
  },
  {
    'label': 'Indiana',
    'value': '18',
    'abbreviation': 'IN'
  },
  {
    'label': 'Iowa',
    'value': '19',
    'abbreviation': 'IA'
  },
  {
    'label': 'Kansas',
    'value': '20',
    'abbreviation': 'KS'
  },
  {
    'label': 'Kentucky',
    'value': '21',
    'abbreviation': 'KY'
  },
  {
    'label': 'Louisiana',
    'value': '22',
    'abbreviation': 'LA'
  },
  {
    'label': 'Maine',
    'value': '23',
    'abbreviation': 'ME'
  },
  {
    'label': 'Maryland',
    'value': '24',
    'abbreviation': 'MD'
  },
  {
    'label': 'Massachusetts',
    'value': '25',
    'abbreviation': 'MA'
  },
  {
    'label': 'Michigan',
    'value': '26',
    'abbreviation': 'MI'
  },
  {
    'label': 'Minnesota',
    'value': '27',
    'abbreviation': 'MN'
  },
  {
    'label': 'Mississippi',
    'value': '28',
    'abbreviation': 'MS'
  },
  {
    'label': 'Missouri',
    'value': '29',
    'abbreviation': 'MO'
  },
  {
    'label': 'Montana',
    'value': '30',
    'abbreviation': 'MT'
  },
  {
    'label': 'Nebraska',
    'value': '31',
    'abbreviation': 'NE'
  },
  {
    'label': 'Nevada',
    'value': '32',
    'abbreviation': 'NV'
  },
  {
    'label': 'New Hampshire',
    'value': '33',
    'abbreviation': 'NH'
  },
  {
    'label': 'New Jersey',
    'value': '34',
    'abbreviation': 'NJ'
  },
  {
    'label': 'New Mexico',
    'value': '35',
    'abbreviation': 'NM'
  },
  {
    'label': 'New York',
    'value': '36',
    'abbreviation': 'NY'
  },
  {
    'label': 'North Carolina',
    'value': '37',
    'abbreviation': 'NC'
  },
  {
    'label': 'North Dakota',
    'value': '38',
    'abbreviation': 'ND'
  },
  {
    'label': 'Ohio',
    'value': '39',
    'abbreviation': 'OH'
  },
  {
    'label': 'Oklahoma',
    'value': '40',
    'abbreviation': 'OK'
  },
  {
    'label': 'Oregon',
    'value': '41',
    'abbreviation': 'OR'
  },
  {
    'label': 'Pennsylvania',
    'value': '42',
    'abbreviation': 'PA'
  },
  {
    'label': 'Rhode Island',
    'value': '44',
    'abbreviation': 'RI'
  },
  {
    'label': 'South Carolina',
    'value': '45',
    'abbreviation': 'SC'
  },
  {
    'label': 'South Dakota',
    'value': '46',
    'abbreviation': 'SD'
  },
  {
    'label': 'Tennessee',
    'value': '47',
    'abbreviation': 'TN'
  },
  {
    'label': 'Texas',
    'value': '48',
    'abbreviation': 'TX'
  },
  {
    'label': 'Utah',
    'value': '49',
    'abbreviation': 'UT'
  },
  {
    'label': 'Vermont',
    'value': '50',
    'abbreviation': 'VT'
  },
  {
    'label': 'Virginia',
    'value': '51',
    'abbreviation': 'VA'
  },
  {
    'label': 'Virgin Islands',
    'value': '78',
    'abbreviation': 'VI'
  },
  {
    'label': 'Washington',
    'value': '53',
    'abbreviation': 'WA'
  },
  {
    'label': 'West Virginia',
    'value': '54',
    'abbreviation': 'WV'
  },
  {
    'label': 'Wisconsin',
    'value': '55',
    'abbreviation': 'WI'
  },
  {
    'label': 'Wyoming',
    'value': '56',
    'abbreviation': 'WY'
  }
]


export const formattedCountiesForSelect = {
  "01": [
    {
      "countyFIP": "001",
      "countyName": "Autauga"
    },
    {
      "countyFIP": "003",
      "countyName": "Baldwin"
    },
    {
      "countyFIP": "005",
      "countyName": "Barbour"
    },
    {
      "countyFIP": "007",
      "countyName": "Bibb"
    },
    {
      "countyFIP": "009",
      "countyName": "Blount"
    },
    {
      "countyFIP": "011",
      "countyName": "Bullock"
    },
    {
      "countyFIP": "013",
      "countyName": "Butler"
    },
    {
      "countyFIP": "015",
      "countyName": "Calhoun"
    },
    {
      "countyFIP": "017",
      "countyName": "Chambers"
    },
    {
      "countyFIP": "019",
      "countyName": "Cherokee"
    },
    {
      "countyFIP": "021",
      "countyName": "Chilton"
    },
    {
      "countyFIP": "023",
      "countyName": "Choctaw"
    },
    {
      "countyFIP": "025",
      "countyName": "Clarke"
    },
    {
      "countyFIP": "027",
      "countyName": "Clay"
    },
    {
      "countyFIP": "029",
      "countyName": "Cleburne"
    },
    {
      "countyFIP": "031",
      "countyName": "Coffee"
    },
    {
      "countyFIP": "033",
      "countyName": "Colbert"
    },
    {
      "countyFIP": "035",
      "countyName": "Conecuh"
    },
    {
      "countyFIP": "037",
      "countyName": "Coosa"
    },
    {
      "countyFIP": "039",
      "countyName": "Covington"
    },
    {
      "countyFIP": "041",
      "countyName": "Crenshaw"
    },
    {
      "countyFIP": "043",
      "countyName": "Cullman"
    },
    {
      "countyFIP": "045",
      "countyName": "Dale"
    },
    {
      "countyFIP": "047",
      "countyName": "Dallas"
    },
    {
      "countyFIP": "049",
      "countyName": "DeKalb"
    },
    {
      "countyFIP": "051",
      "countyName": "Elmore"
    },
    {
      "countyFIP": "053",
      "countyName": "Escambia"
    },
    {
      "countyFIP": "055",
      "countyName": "Etowah"
    },
    {
      "countyFIP": "057",
      "countyName": "Fayette"
    },
    {
      "countyFIP": "059",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "061",
      "countyName": "Geneva"
    },
    {
      "countyFIP": "063",
      "countyName": "Greene"
    },
    {
      "countyFIP": "065",
      "countyName": "Hale"
    },
    {
      "countyFIP": "067",
      "countyName": "Henry"
    },
    {
      "countyFIP": "069",
      "countyName": "Houston"
    },
    {
      "countyFIP": "071",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "073",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "075",
      "countyName": "Lamar"
    },
    {
      "countyFIP": "077",
      "countyName": "Lauderdale"
    },
    {
      "countyFIP": "079",
      "countyName": "Lawrence"
    },
    {
      "countyFIP": "081",
      "countyName": "Lee"
    },
    {
      "countyFIP": "083",
      "countyName": "Limestone"
    },
    {
      "countyFIP": "085",
      "countyName": "Lowndes"
    },
    {
      "countyFIP": "087",
      "countyName": "Macon"
    },
    {
      "countyFIP": "089",
      "countyName": "Madison"
    },
    {
      "countyFIP": "091",
      "countyName": "Marengo"
    },
    {
      "countyFIP": "093",
      "countyName": "Marion"
    },
    {
      "countyFIP": "095",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "097",
      "countyName": "Mobile"
    },
    {
      "countyFIP": "099",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "101",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "103",
      "countyName": "Morgan"
    },
    {
      "countyFIP": "105",
      "countyName": "Perry"
    },
    {
      "countyFIP": "107",
      "countyName": "Pickens"
    },
    {
      "countyFIP": "109",
      "countyName": "Pike"
    },
    {
      "countyFIP": "111",
      "countyName": "Randolph"
    },
    {
      "countyFIP": "113",
      "countyName": "Russell"
    },
    {
      "countyFIP": "115",
      "countyName": "St Clair"
    },
    {
      "countyFIP": "117",
      "countyName": "Shelby"
    },
    {
      "countyFIP": "119",
      "countyName": "Sumter"
    },
    {
      "countyFIP": "121",
      "countyName": "Talladega"
    },
    {
      "countyFIP": "123",
      "countyName": "Tallapoosa"
    },
    {
      "countyFIP": "125",
      "countyName": "Tuscaloosa"
    },
    {
      "countyFIP": "127",
      "countyName": "Walker"
    },
    {
      "countyFIP": "129",
      "countyName": "Washington"
    },
    {
      "countyFIP": "131",
      "countyName": "Wilcox"
    },
    {
      "countyFIP": "133",
      "countyName": "Winston"
    }
  ],
  "02": [
    {
      "countyFIP": "013",
      "countyName": "Aleutians East"
    },
    {
      "countyFIP": "016",
      "countyName": "Aleutians West"
    },
    {
      "countyFIP": "020",
      "countyName": "Anchorage"
    },
    {
      "countyFIP": "050",
      "countyName": "Bethel"
    },
    {
      "countyFIP": "060",
      "countyName": "Bristol Bay"
    },
    {
      "countyFIP": "063",
      "countyName": "Chugach"
    },
    {
      "countyFIP": "066",
      "countyName": "Copper River"
    },
    {
      "countyFIP": "068",
      "countyName": "Denali"
    },
    {
      "countyFIP": "070",
      "countyName": "Dillingham"
    },
    {
      "countyFIP": "090",
      "countyName": "Fairbanks North Star"
    },
    {
      "countyFIP": "100",
      "countyName": "Haines"
    },
    {
      "countyFIP": "105",
      "countyName": "Hoonah-Angoon"
    },
    {
      "countyFIP": "110",
      "countyName": "Juneau"
    },
    {
      "countyFIP": "122",
      "countyName": "Kenai Peninsula"
    },
    {
      "countyFIP": "130",
      "countyName": "Ketchikan Gateway"
    },
    {
      "countyFIP": "150",
      "countyName": "Kodiak Island"
    },
    {
      "countyFIP": "158",
      "countyName": "Kusilvak"
    },
    {
      "countyFIP": "164",
      "countyName": "Lake and Peninsula"
    },
    {
      "countyFIP": "170",
      "countyName": "Matanuska-Susitna"
    },
    {
      "countyFIP": "180",
      "countyName": "Nome"
    },
    {
      "countyFIP": "185",
      "countyName": "North Slope"
    },
    {
      "countyFIP": "188",
      "countyName": "Northwest Arctic"
    },
    {
      "countyFIP": "195",
      "countyName": "Petersburg"
    },
    {
      "countyFIP": "198",
      "countyName": "Prince of Wales-Hyder"
    },
    {
      "countyFIP": "220",
      "countyName": "Sitka"
    },
    {
      "countyFIP": "230",
      "countyName": "Skagway"
    },
    {
      "countyFIP": "240",
      "countyName": "Southeast Fairbanks"
    },
    {
      "countyFIP": "275",
      "countyName": "Wrangell"
    },
    {
      "countyFIP": "282",
      "countyName": "Yakutat"
    },
    {
      "countyFIP": "290",
      "countyName": "Yukon-Koyukuk"
    },
    {
      "countyFIP": "063",
      "countyName": "Chugach"
    },
    {
      "countyFIP": "066",
      "countyName": "Copper River"
    }
  ],
  "04": [
    {
      "countyFIP": "001",
      "countyName": "Apache"
    },
    {
      "countyFIP": "003",
      "countyName": "Cochise"
    },
    {
      "countyFIP": "005",
      "countyName": "Coconino"
    },
    {
      "countyFIP": "007",
      "countyName": "Gila"
    },
    {
      "countyFIP": "009",
      "countyName": "Graham"
    },
    {
      "countyFIP": "011",
      "countyName": "Greenlee"
    },
    {
      "countyFIP": "012",
      "countyName": "La Paz"
    },
    {
      "countyFIP": "013",
      "countyName": "Maricopa"
    },
    {
      "countyFIP": "015",
      "countyName": "Mohave"
    },
    {
      "countyFIP": "017",
      "countyName": "Navajo"
    },
    {
      "countyFIP": "019",
      "countyName": "Pima"
    },
    {
      "countyFIP": "021",
      "countyName": "Pinal"
    },
    {
      "countyFIP": "023",
      "countyName": "Santa Cruz"
    },
    {
      "countyFIP": "025",
      "countyName": "Yavapai"
    },
    {
      "countyFIP": "027",
      "countyName": "Yuma"
    }
  ],
  "05": [
    {
      "countyFIP": "001",
      "countyName": "Arkansas"
    },
    {
      "countyFIP": "003",
      "countyName": "Ashley"
    },
    {
      "countyFIP": "005",
      "countyName": "Baxter"
    },
    {
      "countyFIP": "007",
      "countyName": "Benton"
    },
    {
      "countyFIP": "009",
      "countyName": "Boone"
    },
    {
      "countyFIP": "011",
      "countyName": "Bradley"
    },
    {
      "countyFIP": "013",
      "countyName": "Calhoun"
    },
    {
      "countyFIP": "015",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "017",
      "countyName": "Chicot"
    },
    {
      "countyFIP": "019",
      "countyName": "Clark"
    },
    {
      "countyFIP": "021",
      "countyName": "Clay"
    },
    {
      "countyFIP": "023",
      "countyName": "Cleburne"
    },
    {
      "countyFIP": "025",
      "countyName": "Cleveland"
    },
    {
      "countyFIP": "027",
      "countyName": "Columbia"
    },
    {
      "countyFIP": "029",
      "countyName": "Conway"
    },
    {
      "countyFIP": "031",
      "countyName": "Craighead"
    },
    {
      "countyFIP": "033",
      "countyName": "Crawford"
    },
    {
      "countyFIP": "035",
      "countyName": "Crittenden"
    },
    {
      "countyFIP": "037",
      "countyName": "Cross"
    },
    {
      "countyFIP": "039",
      "countyName": "Dallas"
    },
    {
      "countyFIP": "041",
      "countyName": "Desha"
    },
    {
      "countyFIP": "043",
      "countyName": "Drew"
    },
    {
      "countyFIP": "045",
      "countyName": "Faulkner"
    },
    {
      "countyFIP": "047",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "049",
      "countyName": "Fulton"
    },
    {
      "countyFIP": "051",
      "countyName": "Garland"
    },
    {
      "countyFIP": "053",
      "countyName": "Grant"
    },
    {
      "countyFIP": "055",
      "countyName": "Greene"
    },
    {
      "countyFIP": "057",
      "countyName": "Hempstead"
    },
    {
      "countyFIP": "059",
      "countyName": "Hot Spring"
    },
    {
      "countyFIP": "061",
      "countyName": "Howard"
    },
    {
      "countyFIP": "063",
      "countyName": "Independence"
    },
    {
      "countyFIP": "065",
      "countyName": "Izard"
    },
    {
      "countyFIP": "067",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "069",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "071",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "073",
      "countyName": "Lafayette"
    },
    {
      "countyFIP": "075",
      "countyName": "Lawrence"
    },
    {
      "countyFIP": "077",
      "countyName": "Lee"
    },
    {
      "countyFIP": "079",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "081",
      "countyName": "Little River"
    },
    {
      "countyFIP": "083",
      "countyName": "Logan"
    },
    {
      "countyFIP": "085",
      "countyName": "Lonoke"
    },
    {
      "countyFIP": "087",
      "countyName": "Madison"
    },
    {
      "countyFIP": "089",
      "countyName": "Marion"
    },
    {
      "countyFIP": "091",
      "countyName": "Miller"
    },
    {
      "countyFIP": "093",
      "countyName": "Mississippi"
    },
    {
      "countyFIP": "095",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "097",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "099",
      "countyName": "Nevada"
    },
    {
      "countyFIP": "101",
      "countyName": "Newton"
    },
    {
      "countyFIP": "103",
      "countyName": "Ouachita"
    },
    {
      "countyFIP": "105",
      "countyName": "Perry"
    },
    {
      "countyFIP": "107",
      "countyName": "Phillips"
    },
    {
      "countyFIP": "109",
      "countyName": "Pike"
    },
    {
      "countyFIP": "111",
      "countyName": "Poinsett"
    },
    {
      "countyFIP": "113",
      "countyName": "Polk"
    },
    {
      "countyFIP": "115",
      "countyName": "Pope"
    },
    {
      "countyFIP": "117",
      "countyName": "Prairie"
    },
    {
      "countyFIP": "119",
      "countyName": "Pulaski"
    },
    {
      "countyFIP": "121",
      "countyName": "Randolph"
    },
    {
      "countyFIP": "123",
      "countyName": "St Francis"
    },
    {
      "countyFIP": "125",
      "countyName": "Saline"
    },
    {
      "countyFIP": "127",
      "countyName": "Scott"
    },
    {
      "countyFIP": "129",
      "countyName": "Searcy"
    },
    {
      "countyFIP": "131",
      "countyName": "Sebastian"
    },
    {
      "countyFIP": "133",
      "countyName": "Sevier"
    },
    {
      "countyFIP": "135",
      "countyName": "Sharp"
    },
    {
      "countyFIP": "137",
      "countyName": "Stone"
    },
    {
      "countyFIP": "139",
      "countyName": "Union"
    },
    {
      "countyFIP": "141",
      "countyName": "Van Buren"
    },
    {
      "countyFIP": "143",
      "countyName": "Washington"
    },
    {
      "countyFIP": "145",
      "countyName": "White"
    },
    {
      "countyFIP": "147",
      "countyName": "Woodruff"
    },
    {
      "countyFIP": "149",
      "countyName": "Yell"
    }
  ],
  "06": [
    {
      "countyFIP": "001",
      "countyName": "Alameda"
    },
    {
      "countyFIP": "003",
      "countyName": "Alpine"
    },
    {
      "countyFIP": "005",
      "countyName": "Amador"
    },
    {
      "countyFIP": "007",
      "countyName": "Butte"
    },
    {
      "countyFIP": "009",
      "countyName": "Calaveras"
    },
    {
      "countyFIP": "011",
      "countyName": "Colusa"
    },
    {
      "countyFIP": "013",
      "countyName": "Contra Costa"
    },
    {
      "countyFIP": "015",
      "countyName": "Del Norte"
    },
    {
      "countyFIP": "017",
      "countyName": "El Dorado"
    },
    {
      "countyFIP": "019",
      "countyName": "Fresno"
    },
    {
      "countyFIP": "021",
      "countyName": "Glenn"
    },
    {
      "countyFIP": "023",
      "countyName": "Humboldt"
    },
    {
      "countyFIP": "025",
      "countyName": "Imperial"
    },
    {
      "countyFIP": "027",
      "countyName": "Inyo"
    },
    {
      "countyFIP": "029",
      "countyName": "Kern"
    },
    {
      "countyFIP": "031",
      "countyName": "Kings"
    },
    {
      "countyFIP": "033",
      "countyName": "Lake"
    },
    {
      "countyFIP": "035",
      "countyName": "Lassen"
    },
    {
      "countyFIP": "037",
      "countyName": "Los Angeles"
    },
    {
      "countyFIP": "039",
      "countyName": "Madera"
    },
    {
      "countyFIP": "041",
      "countyName": "Marin"
    },
    {
      "countyFIP": "043",
      "countyName": "Mariposa"
    },
    {
      "countyFIP": "045",
      "countyName": "Mendocino"
    },
    {
      "countyFIP": "047",
      "countyName": "Merced"
    },
    {
      "countyFIP": "049",
      "countyName": "Modoc"
    },
    {
      "countyFIP": "051",
      "countyName": "Mono"
    },
    {
      "countyFIP": "053",
      "countyName": "Monterey"
    },
    {
      "countyFIP": "055",
      "countyName": "Napa"
    },
    {
      "countyFIP": "057",
      "countyName": "Nevada"
    },
    {
      "countyFIP": "059",
      "countyName": "Orange"
    },
    {
      "countyFIP": "061",
      "countyName": "Placer"
    },
    {
      "countyFIP": "063",
      "countyName": "Plumas"
    },
    {
      "countyFIP": "065",
      "countyName": "Riverside"
    },
    {
      "countyFIP": "067",
      "countyName": "Sacramento"
    },
    {
      "countyFIP": "069",
      "countyName": "San Benito"
    },
    {
      "countyFIP": "071",
      "countyName": "San Bernardino"
    },
    {
      "countyFIP": "073",
      "countyName": "San Diego"
    },
    {
      "countyFIP": "075",
      "countyName": "San Francisco"
    },
    {
      "countyFIP": "077",
      "countyName": "San Joaquin"
    },
    {
      "countyFIP": "079",
      "countyName": "San Luis Obispo"
    },
    {
      "countyFIP": "081",
      "countyName": "San Mateo"
    },
    {
      "countyFIP": "083",
      "countyName": "Santa Barbara"
    },
    {
      "countyFIP": "085",
      "countyName": "Santa Clara"
    },
    {
      "countyFIP": "087",
      "countyName": "Santa Cruz"
    },
    {
      "countyFIP": "089",
      "countyName": "Shasta"
    },
    {
      "countyFIP": "091",
      "countyName": "Sierra"
    },
    {
      "countyFIP": "093",
      "countyName": "Siskiyou"
    },
    {
      "countyFIP": "095",
      "countyName": "Solano"
    },
    {
      "countyFIP": "097",
      "countyName": "Sonoma"
    },
    {
      "countyFIP": "099",
      "countyName": "Stanislaus"
    },
    {
      "countyFIP": "101",
      "countyName": "Sutter"
    },
    {
      "countyFIP": "103",
      "countyName": "Tehama"
    },
    {
      "countyFIP": "105",
      "countyName": "Trinity"
    },
    {
      "countyFIP": "107",
      "countyName": "Tulare"
    },
    {
      "countyFIP": "109",
      "countyName": "Tuolumne"
    },
    {
      "countyFIP": "111",
      "countyName": "Ventura"
    },
    {
      "countyFIP": "113",
      "countyName": "Yolo"
    },
    {
      "countyFIP": "115",
      "countyName": "Yuba"
    }
  ],
  "08": [
    {
      "countyFIP": "001",
      "countyName": "Adams"
    },
    {
      "countyFIP": "003",
      "countyName": "Alamosa"
    },
    {
      "countyFIP": "005",
      "countyName": "Arapahoe"
    },
    {
      "countyFIP": "007",
      "countyName": "Archuleta"
    },
    {
      "countyFIP": "009",
      "countyName": "Baca"
    },
    {
      "countyFIP": "011",
      "countyName": "Bent"
    },
    {
      "countyFIP": "013",
      "countyName": "Boulder"
    },
    {
      "countyFIP": "014",
      "countyName": "Broomfield"
    },
    {
      "countyFIP": "015",
      "countyName": "Chaffee"
    },
    {
      "countyFIP": "017",
      "countyName": "Cheyenne"
    },
    {
      "countyFIP": "019",
      "countyName": "Clear Creek"
    },
    {
      "countyFIP": "021",
      "countyName": "Conejos"
    },
    {
      "countyFIP": "023",
      "countyName": "Costilla"
    },
    {
      "countyFIP": "025",
      "countyName": "Crowley"
    },
    {
      "countyFIP": "027",
      "countyName": "Custer"
    },
    {
      "countyFIP": "029",
      "countyName": "Delta"
    },
    {
      "countyFIP": "031",
      "countyName": "Denver"
    },
    {
      "countyFIP": "033",
      "countyName": "Dolores"
    },
    {
      "countyFIP": "035",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "037",
      "countyName": "Eagle"
    },
    {
      "countyFIP": "039",
      "countyName": "Elbert"
    },
    {
      "countyFIP": "041",
      "countyName": "El Paso"
    },
    {
      "countyFIP": "043",
      "countyName": "Fremont"
    },
    {
      "countyFIP": "045",
      "countyName": "Garfield"
    },
    {
      "countyFIP": "047",
      "countyName": "Gilpin"
    },
    {
      "countyFIP": "049",
      "countyName": "Grand"
    },
    {
      "countyFIP": "051",
      "countyName": "Gunnison"
    },
    {
      "countyFIP": "053",
      "countyName": "Hinsdale"
    },
    {
      "countyFIP": "055",
      "countyName": "Huerfano"
    },
    {
      "countyFIP": "057",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "059",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "061",
      "countyName": "Kiowa"
    },
    {
      "countyFIP": "063",
      "countyName": "Kit Carson"
    },
    {
      "countyFIP": "065",
      "countyName": "Lake"
    },
    {
      "countyFIP": "067",
      "countyName": "La Plata"
    },
    {
      "countyFIP": "069",
      "countyName": "Larimer"
    },
    {
      "countyFIP": "071",
      "countyName": "Las Animas"
    },
    {
      "countyFIP": "073",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "075",
      "countyName": "Logan"
    },
    {
      "countyFIP": "077",
      "countyName": "Mesa"
    },
    {
      "countyFIP": "079",
      "countyName": "Mineral"
    },
    {
      "countyFIP": "081",
      "countyName": "Moffat"
    },
    {
      "countyFIP": "083",
      "countyName": "Montezuma"
    },
    {
      "countyFIP": "085",
      "countyName": "Montrose"
    },
    {
      "countyFIP": "087",
      "countyName": "Morgan"
    },
    {
      "countyFIP": "089",
      "countyName": "Otero"
    },
    {
      "countyFIP": "091",
      "countyName": "Ouray"
    },
    {
      "countyFIP": "093",
      "countyName": "Park"
    },
    {
      "countyFIP": "095",
      "countyName": "Phillips"
    },
    {
      "countyFIP": "097",
      "countyName": "Pitkin"
    },
    {
      "countyFIP": "099",
      "countyName": "Prowers"
    },
    {
      "countyFIP": "101",
      "countyName": "Pueblo"
    },
    {
      "countyFIP": "103",
      "countyName": "Rio Blanco"
    },
    {
      "countyFIP": "105",
      "countyName": "Rio Grande"
    },
    {
      "countyFIP": "107",
      "countyName": "Routt"
    },
    {
      "countyFIP": "109",
      "countyName": "Saguache"
    },
    {
      "countyFIP": "111",
      "countyName": "San Juan"
    },
    {
      "countyFIP": "113",
      "countyName": "San Miguel"
    },
    {
      "countyFIP": "115",
      "countyName": "Sedgwick"
    },
    {
      "countyFIP": "117",
      "countyName": "Summit"
    },
    {
      "countyFIP": "119",
      "countyName": "Teller"
    },
    {
      "countyFIP": "121",
      "countyName": "Washington"
    },
    {
      "countyFIP": "123",
      "countyName": "Weld"
    },
    {
      "countyFIP": "125",
      "countyName": "Yuma"
    }
  ],
  "09": [
    {
      "countyFIP": "001",
      "countyName": "Fairfield"
    },
    {
      "countyFIP": "003",
      "countyName": "Hartford"
    },
    {
      "countyFIP": "005",
      "countyName": "Litchfield"
    },
    {
      "countyFIP": "007",
      "countyName": "Middlesex"
    },
    {
      "countyFIP": "009",
      "countyName": "New Haven"
    },
    {
      "countyFIP": "011",
      "countyName": "New London"
    },
    {
      "countyFIP": "013",
      "countyName": "Tolland"
    },
    {
      "countyFIP": "015",
      "countyName": "Windham"
    }
  ],
  "10": [
    {
      "countyFIP": "001",
      "countyName": "Kent"
    },
    {
      "countyFIP": "003",
      "countyName": "New Castle"
    },
    {
      "countyFIP": "005",
      "countyName": "Sussex"
    }
  ],
  "11": [
    {
      "countyFIP": "001",
      "countyName": "District of Columbia"
    }
  ],
  "12": [
    {
      "countyFIP": "001",
      "countyName": "Alachua"
    },
    {
      "countyFIP": "003",
      "countyName": "Baker"
    },
    {
      "countyFIP": "005",
      "countyName": "Bay"
    },
    {
      "countyFIP": "007",
      "countyName": "Bradford"
    },
    {
      "countyFIP": "009",
      "countyName": "Brevard"
    },
    {
      "countyFIP": "011",
      "countyName": "Broward"
    },
    {
      "countyFIP": "013",
      "countyName": "Calhoun"
    },
    {
      "countyFIP": "015",
      "countyName": "Charlotte"
    },
    {
      "countyFIP": "017",
      "countyName": "Citrus"
    },
    {
      "countyFIP": "019",
      "countyName": "Clay"
    },
    {
      "countyFIP": "021",
      "countyName": "Collier"
    },
    {
      "countyFIP": "023",
      "countyName": "Columbia"
    },
    {
      "countyFIP": "027",
      "countyName": "DeSoto"
    },
    {
      "countyFIP": "029",
      "countyName": "Dixie"
    },
    {
      "countyFIP": "031",
      "countyName": "Duval"
    },
    {
      "countyFIP": "033",
      "countyName": "Escambia"
    },
    {
      "countyFIP": "035",
      "countyName": "Flagler"
    },
    {
      "countyFIP": "037",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "039",
      "countyName": "Gadsden"
    },
    {
      "countyFIP": "041",
      "countyName": "Gilchrist"
    },
    {
      "countyFIP": "043",
      "countyName": "Glades"
    },
    {
      "countyFIP": "045",
      "countyName": "Gulf"
    },
    {
      "countyFIP": "047",
      "countyName": "Hamilton"
    },
    {
      "countyFIP": "049",
      "countyName": "Hardee"
    },
    {
      "countyFIP": "051",
      "countyName": "Hendry"
    },
    {
      "countyFIP": "053",
      "countyName": "Hernando"
    },
    {
      "countyFIP": "055",
      "countyName": "Highlands"
    },
    {
      "countyFIP": "057",
      "countyName": "Hillsborough"
    },
    {
      "countyFIP": "059",
      "countyName": "Holmes"
    },
    {
      "countyFIP": "061",
      "countyName": "Indian River"
    },
    {
      "countyFIP": "063",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "065",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "067",
      "countyName": "Lafayette"
    },
    {
      "countyFIP": "069",
      "countyName": "Lake"
    },
    {
      "countyFIP": "071",
      "countyName": "Lee"
    },
    {
      "countyFIP": "073",
      "countyName": "Leon"
    },
    {
      "countyFIP": "075",
      "countyName": "Levy"
    },
    {
      "countyFIP": "077",
      "countyName": "Liberty"
    },
    {
      "countyFIP": "079",
      "countyName": "Madison"
    },
    {
      "countyFIP": "081",
      "countyName": "Manatee"
    },
    {
      "countyFIP": "083",
      "countyName": "Marion"
    },
    {
      "countyFIP": "085",
      "countyName": "Martin"
    },
    {
      "countyFIP": "086",
      "countyName": "Miami-Dade"
    },
    {
      "countyFIP": "087",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "089",
      "countyName": "Nassau"
    },
    {
      "countyFIP": "091",
      "countyName": "Okaloosa"
    },
    {
      "countyFIP": "093",
      "countyName": "Okeechobee"
    },
    {
      "countyFIP": "095",
      "countyName": "Orange"
    },
    {
      "countyFIP": "097",
      "countyName": "Osceola"
    },
    {
      "countyFIP": "099",
      "countyName": "Palm Beach"
    },
    {
      "countyFIP": "101",
      "countyName": "Pasco"
    },
    {
      "countyFIP": "103",
      "countyName": "Pinellas"
    },
    {
      "countyFIP": "105",
      "countyName": "Polk"
    },
    {
      "countyFIP": "107",
      "countyName": "Putnam"
    },
    {
      "countyFIP": "109",
      "countyName": "St Johns"
    },
    {
      "countyFIP": "111",
      "countyName": "St Lucie"
    },
    {
      "countyFIP": "113",
      "countyName": "Santa Rosa"
    },
    {
      "countyFIP": "115",
      "countyName": "Sarasota"
    },
    {
      "countyFIP": "117",
      "countyName": "Seminole"
    },
    {
      "countyFIP": "119",
      "countyName": "Sumter"
    },
    {
      "countyFIP": "121",
      "countyName": "Suwannee"
    },
    {
      "countyFIP": "123",
      "countyName": "Taylor"
    },
    {
      "countyFIP": "125",
      "countyName": "Union"
    },
    {
      "countyFIP": "127",
      "countyName": "Volusia"
    },
    {
      "countyFIP": "129",
      "countyName": "Wakulla"
    },
    {
      "countyFIP": "131",
      "countyName": "Walton"
    },
    {
      "countyFIP": "133",
      "countyName": "Washington"
    }
  ],
  "13": [
    {
      "countyFIP": "001",
      "countyName": "Appling"
    },
    {
      "countyFIP": "003",
      "countyName": "Atkinson"
    },
    {
      "countyFIP": "005",
      "countyName": "Bacon"
    },
    {
      "countyFIP": "007",
      "countyName": "Baker"
    },
    {
      "countyFIP": "009",
      "countyName": "Baldwin"
    },
    {
      "countyFIP": "011",
      "countyName": "Banks"
    },
    {
      "countyFIP": "013",
      "countyName": "Barrow"
    },
    {
      "countyFIP": "015",
      "countyName": "Bartow"
    },
    {
      "countyFIP": "017",
      "countyName": "Ben Hill"
    },
    {
      "countyFIP": "019",
      "countyName": "Berrien"
    },
    {
      "countyFIP": "021",
      "countyName": "Bibb"
    },
    {
      "countyFIP": "023",
      "countyName": "Bleckley"
    },
    {
      "countyFIP": "025",
      "countyName": "Brantley"
    },
    {
      "countyFIP": "027",
      "countyName": "Brooks"
    },
    {
      "countyFIP": "029",
      "countyName": "Bryan"
    },
    {
      "countyFIP": "031",
      "countyName": "Bulloch"
    },
    {
      "countyFIP": "033",
      "countyName": "Burke"
    },
    {
      "countyFIP": "035",
      "countyName": "Butts"
    },
    {
      "countyFIP": "037",
      "countyName": "Calhoun"
    },
    {
      "countyFIP": "039",
      "countyName": "Camden"
    },
    {
      "countyFIP": "043",
      "countyName": "Candler"
    },
    {
      "countyFIP": "045",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "047",
      "countyName": "Catoosa"
    },
    {
      "countyFIP": "049",
      "countyName": "Charlton"
    },
    {
      "countyFIP": "051",
      "countyName": "Chatham"
    },
    {
      "countyFIP": "053",
      "countyName": "Chattahoochee"
    },
    {
      "countyFIP": "055",
      "countyName": "Chattooga"
    },
    {
      "countyFIP": "057",
      "countyName": "Cherokee"
    },
    {
      "countyFIP": "059",
      "countyName": "Clarke"
    },
    {
      "countyFIP": "061",
      "countyName": "Clay"
    },
    {
      "countyFIP": "063",
      "countyName": "Clayton"
    },
    {
      "countyFIP": "065",
      "countyName": "Clinch"
    },
    {
      "countyFIP": "067",
      "countyName": "Cobb"
    },
    {
      "countyFIP": "069",
      "countyName": "Coffee"
    },
    {
      "countyFIP": "071",
      "countyName": "Colquitt"
    },
    {
      "countyFIP": "073",
      "countyName": "Columbia"
    },
    {
      "countyFIP": "075",
      "countyName": "Cook"
    },
    {
      "countyFIP": "077",
      "countyName": "Coweta"
    },
    {
      "countyFIP": "079",
      "countyName": "Crawford"
    },
    {
      "countyFIP": "081",
      "countyName": "Crisp"
    },
    {
      "countyFIP": "083",
      "countyName": "Dade"
    },
    {
      "countyFIP": "085",
      "countyName": "Dawson"
    },
    {
      "countyFIP": "087",
      "countyName": "Decatur"
    },
    {
      "countyFIP": "089",
      "countyName": "DeKalb"
    },
    {
      "countyFIP": "091",
      "countyName": "Dodge"
    },
    {
      "countyFIP": "093",
      "countyName": "Dooly"
    },
    {
      "countyFIP": "095",
      "countyName": "Dougherty"
    },
    {
      "countyFIP": "097",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "099",
      "countyName": "Early"
    },
    {
      "countyFIP": "101",
      "countyName": "Echols"
    },
    {
      "countyFIP": "103",
      "countyName": "Effingham"
    },
    {
      "countyFIP": "105",
      "countyName": "Elbert"
    },
    {
      "countyFIP": "107",
      "countyName": "Emanuel"
    },
    {
      "countyFIP": "109",
      "countyName": "Evans"
    },
    {
      "countyFIP": "111",
      "countyName": "Fannin"
    },
    {
      "countyFIP": "113",
      "countyName": "Fayette"
    },
    {
      "countyFIP": "115",
      "countyName": "Floyd"
    },
    {
      "countyFIP": "117",
      "countyName": "Forsyth"
    },
    {
      "countyFIP": "119",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "121",
      "countyName": "Fulton"
    },
    {
      "countyFIP": "123",
      "countyName": "Gilmer"
    },
    {
      "countyFIP": "125",
      "countyName": "Glascock"
    },
    {
      "countyFIP": "127",
      "countyName": "Glynn"
    },
    {
      "countyFIP": "129",
      "countyName": "Gordon"
    },
    {
      "countyFIP": "131",
      "countyName": "Grady"
    },
    {
      "countyFIP": "133",
      "countyName": "Greene"
    },
    {
      "countyFIP": "135",
      "countyName": "Gwinnett"
    },
    {
      "countyFIP": "137",
      "countyName": "Habersham"
    },
    {
      "countyFIP": "139",
      "countyName": "Hall"
    },
    {
      "countyFIP": "141",
      "countyName": "Hancock"
    },
    {
      "countyFIP": "143",
      "countyName": "Haralson"
    },
    {
      "countyFIP": "145",
      "countyName": "Harris"
    },
    {
      "countyFIP": "147",
      "countyName": "Hart"
    },
    {
      "countyFIP": "149",
      "countyName": "Heard"
    },
    {
      "countyFIP": "151",
      "countyName": "Henry"
    },
    {
      "countyFIP": "153",
      "countyName": "Houston"
    },
    {
      "countyFIP": "155",
      "countyName": "Irwin"
    },
    {
      "countyFIP": "157",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "159",
      "countyName": "Jasper"
    },
    {
      "countyFIP": "161",
      "countyName": "Jeff Davis"
    },
    {
      "countyFIP": "163",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "165",
      "countyName": "Jenkins"
    },
    {
      "countyFIP": "167",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "169",
      "countyName": "Jones"
    },
    {
      "countyFIP": "171",
      "countyName": "Lamar"
    },
    {
      "countyFIP": "173",
      "countyName": "Lanier"
    },
    {
      "countyFIP": "175",
      "countyName": "Laurens"
    },
    {
      "countyFIP": "177",
      "countyName": "Lee"
    },
    {
      "countyFIP": "179",
      "countyName": "Liberty"
    },
    {
      "countyFIP": "181",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "183",
      "countyName": "Long"
    },
    {
      "countyFIP": "185",
      "countyName": "Lowndes"
    },
    {
      "countyFIP": "187",
      "countyName": "Lumpkin"
    },
    {
      "countyFIP": "189",
      "countyName": "McDuffie"
    },
    {
      "countyFIP": "191",
      "countyName": "McIntosh"
    },
    {
      "countyFIP": "193",
      "countyName": "Macon"
    },
    {
      "countyFIP": "195",
      "countyName": "Madison"
    },
    {
      "countyFIP": "197",
      "countyName": "Marion"
    },
    {
      "countyFIP": "199",
      "countyName": "Meriwether"
    },
    {
      "countyFIP": "201",
      "countyName": "Miller"
    },
    {
      "countyFIP": "205",
      "countyName": "Mitchell"
    },
    {
      "countyFIP": "207",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "209",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "211",
      "countyName": "Morgan"
    },
    {
      "countyFIP": "213",
      "countyName": "Murray"
    },
    {
      "countyFIP": "215",
      "countyName": "Muscogee"
    },
    {
      "countyFIP": "217",
      "countyName": "Newton"
    },
    {
      "countyFIP": "219",
      "countyName": "Oconee"
    },
    {
      "countyFIP": "221",
      "countyName": "Oglethorpe"
    },
    {
      "countyFIP": "223",
      "countyName": "Paulding"
    },
    {
      "countyFIP": "225",
      "countyName": "Peach"
    },
    {
      "countyFIP": "227",
      "countyName": "Pickens"
    },
    {
      "countyFIP": "229",
      "countyName": "Pierce"
    },
    {
      "countyFIP": "231",
      "countyName": "Pike"
    },
    {
      "countyFIP": "233",
      "countyName": "Polk"
    },
    {
      "countyFIP": "235",
      "countyName": "Pulaski"
    },
    {
      "countyFIP": "237",
      "countyName": "Putnam"
    },
    {
      "countyFIP": "239",
      "countyName": "Quitman"
    },
    {
      "countyFIP": "241",
      "countyName": "Rabun"
    },
    {
      "countyFIP": "243",
      "countyName": "Randolph"
    },
    {
      "countyFIP": "245",
      "countyName": "Richmond"
    },
    {
      "countyFIP": "247",
      "countyName": "Rockdale"
    },
    {
      "countyFIP": "249",
      "countyName": "Schley"
    },
    {
      "countyFIP": "251",
      "countyName": "Screven"
    },
    {
      "countyFIP": "253",
      "countyName": "Seminole"
    },
    {
      "countyFIP": "255",
      "countyName": "Spalding"
    },
    {
      "countyFIP": "257",
      "countyName": "Stephens"
    },
    {
      "countyFIP": "259",
      "countyName": "Stewart"
    },
    {
      "countyFIP": "261",
      "countyName": "Sumter"
    },
    {
      "countyFIP": "263",
      "countyName": "Talbot"
    },
    {
      "countyFIP": "265",
      "countyName": "Taliaferro"
    },
    {
      "countyFIP": "267",
      "countyName": "Tattnall"
    },
    {
      "countyFIP": "269",
      "countyName": "Taylor"
    },
    {
      "countyFIP": "271",
      "countyName": "Telfair"
    },
    {
      "countyFIP": "273",
      "countyName": "Terrell"
    },
    {
      "countyFIP": "275",
      "countyName": "Thomas"
    },
    {
      "countyFIP": "277",
      "countyName": "Tift"
    },
    {
      "countyFIP": "279",
      "countyName": "Toombs"
    },
    {
      "countyFIP": "281",
      "countyName": "Towns"
    },
    {
      "countyFIP": "283",
      "countyName": "Treutlen"
    },
    {
      "countyFIP": "285",
      "countyName": "Troup"
    },
    {
      "countyFIP": "287",
      "countyName": "Turner"
    },
    {
      "countyFIP": "289",
      "countyName": "Twiggs"
    },
    {
      "countyFIP": "291",
      "countyName": "Union"
    },
    {
      "countyFIP": "293",
      "countyName": "Upson"
    },
    {
      "countyFIP": "295",
      "countyName": "Walker"
    },
    {
      "countyFIP": "297",
      "countyName": "Walton"
    },
    {
      "countyFIP": "299",
      "countyName": "Ware"
    },
    {
      "countyFIP": "301",
      "countyName": "Warren"
    },
    {
      "countyFIP": "303",
      "countyName": "Washington"
    },
    {
      "countyFIP": "305",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "307",
      "countyName": "Webster"
    },
    {
      "countyFIP": "309",
      "countyName": "Wheeler"
    },
    {
      "countyFIP": "311",
      "countyName": "White"
    },
    {
      "countyFIP": "313",
      "countyName": "Whitfield"
    },
    {
      "countyFIP": "315",
      "countyName": "Wilcox"
    },
    {
      "countyFIP": "317",
      "countyName": "Wilkes"
    },
    {
      "countyFIP": "319",
      "countyName": "Wilkinson"
    },
    {
      "countyFIP": "321",
      "countyName": "Worth"
    }
  ],
  "15": [
    {
      "countyFIP": "001",
      "countyName": "Hawaii"
    },
    {
      "countyFIP": "003",
      "countyName": "Honolulu"
    },
    {
      "countyFIP": "005",
      "countyName": "Kalawao"
    },
    {
      "countyFIP": "007",
      "countyName": "Kauai"
    },
    {
      "countyFIP": "009",
      "countyName": "Maui"
    }
  ],
  "16": [
    {
      "countyFIP": "001",
      "countyName": "Ada"
    },
    {
      "countyFIP": "003",
      "countyName": "Adams"
    },
    {
      "countyFIP": "005",
      "countyName": "Bannock"
    },
    {
      "countyFIP": "007",
      "countyName": "Bear Lake"
    },
    {
      "countyFIP": "009",
      "countyName": "Benewah"
    },
    {
      "countyFIP": "011",
      "countyName": "Bingham"
    },
    {
      "countyFIP": "013",
      "countyName": "Blaine"
    },
    {
      "countyFIP": "015",
      "countyName": "Boise"
    },
    {
      "countyFIP": "017",
      "countyName": "Bonner"
    },
    {
      "countyFIP": "019",
      "countyName": "Bonneville"
    },
    {
      "countyFIP": "021",
      "countyName": "Boundary"
    },
    {
      "countyFIP": "023",
      "countyName": "Butte"
    },
    {
      "countyFIP": "025",
      "countyName": "Camas"
    },
    {
      "countyFIP": "027",
      "countyName": "Canyon"
    },
    {
      "countyFIP": "029",
      "countyName": "Caribou"
    },
    {
      "countyFIP": "031",
      "countyName": "Cassia"
    },
    {
      "countyFIP": "033",
      "countyName": "Clark"
    },
    {
      "countyFIP": "035",
      "countyName": "Clearwater"
    },
    {
      "countyFIP": "037",
      "countyName": "Custer"
    },
    {
      "countyFIP": "039",
      "countyName": "Elmore"
    },
    {
      "countyFIP": "041",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "043",
      "countyName": "Fremont"
    },
    {
      "countyFIP": "045",
      "countyName": "Gem"
    },
    {
      "countyFIP": "047",
      "countyName": "Gooding"
    },
    {
      "countyFIP": "049",
      "countyName": "Idaho"
    },
    {
      "countyFIP": "051",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "053",
      "countyName": "Jerome"
    },
    {
      "countyFIP": "055",
      "countyName": "Kootenai"
    },
    {
      "countyFIP": "057",
      "countyName": "Latah"
    },
    {
      "countyFIP": "059",
      "countyName": "Lemhi"
    },
    {
      "countyFIP": "061",
      "countyName": "Lewis"
    },
    {
      "countyFIP": "063",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "065",
      "countyName": "Madison"
    },
    {
      "countyFIP": "067",
      "countyName": "Minidoka"
    },
    {
      "countyFIP": "069",
      "countyName": "Nez Perce"
    },
    {
      "countyFIP": "071",
      "countyName": "Oneida"
    },
    {
      "countyFIP": "073",
      "countyName": "Owyhee"
    },
    {
      "countyFIP": "075",
      "countyName": "Payette"
    },
    {
      "countyFIP": "077",
      "countyName": "Power"
    },
    {
      "countyFIP": "079",
      "countyName": "Shoshone"
    },
    {
      "countyFIP": "081",
      "countyName": "Teton"
    },
    {
      "countyFIP": "083",
      "countyName": "Twin Falls"
    },
    {
      "countyFIP": "085",
      "countyName": "Valley"
    },
    {
      "countyFIP": "087",
      "countyName": "Washington"
    }
  ],
  "17": [
    {
      "countyFIP": "001",
      "countyName": "Adams"
    },
    {
      "countyFIP": "003",
      "countyName": "Alexander"
    },
    {
      "countyFIP": "005",
      "countyName": "Bond"
    },
    {
      "countyFIP": "007",
      "countyName": "Boone"
    },
    {
      "countyFIP": "009",
      "countyName": "Brown"
    },
    {
      "countyFIP": "011",
      "countyName": "Bureau"
    },
    {
      "countyFIP": "013",
      "countyName": "Calhoun"
    },
    {
      "countyFIP": "015",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "017",
      "countyName": "Cass"
    },
    {
      "countyFIP": "019",
      "countyName": "Champaign"
    },
    {
      "countyFIP": "021",
      "countyName": "Christian"
    },
    {
      "countyFIP": "023",
      "countyName": "Clark"
    },
    {
      "countyFIP": "025",
      "countyName": "Clay"
    },
    {
      "countyFIP": "027",
      "countyName": "Clinton"
    },
    {
      "countyFIP": "029",
      "countyName": "Coles"
    },
    {
      "countyFIP": "031",
      "countyName": "Cook"
    },
    {
      "countyFIP": "033",
      "countyName": "Crawford"
    },
    {
      "countyFIP": "035",
      "countyName": "Cumberland"
    },
    {
      "countyFIP": "037",
      "countyName": "DeKalb"
    },
    {
      "countyFIP": "039",
      "countyName": "De Witt"
    },
    {
      "countyFIP": "041",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "043",
      "countyName": "DuPage"
    },
    {
      "countyFIP": "045",
      "countyName": "Edgar"
    },
    {
      "countyFIP": "047",
      "countyName": "Edwards"
    },
    {
      "countyFIP": "049",
      "countyName": "Effingham"
    },
    {
      "countyFIP": "051",
      "countyName": "Fayette"
    },
    {
      "countyFIP": "053",
      "countyName": "Ford"
    },
    {
      "countyFIP": "055",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "057",
      "countyName": "Fulton"
    },
    {
      "countyFIP": "059",
      "countyName": "Gallatin"
    },
    {
      "countyFIP": "061",
      "countyName": "Greene"
    },
    {
      "countyFIP": "063",
      "countyName": "Grundy"
    },
    {
      "countyFIP": "065",
      "countyName": "Hamilton"
    },
    {
      "countyFIP": "067",
      "countyName": "Hancock"
    },
    {
      "countyFIP": "069",
      "countyName": "Hardin"
    },
    {
      "countyFIP": "071",
      "countyName": "Henderson"
    },
    {
      "countyFIP": "073",
      "countyName": "Henry"
    },
    {
      "countyFIP": "075",
      "countyName": "Iroquois"
    },
    {
      "countyFIP": "077",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "079",
      "countyName": "Jasper"
    },
    {
      "countyFIP": "081",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "083",
      "countyName": "Jersey"
    },
    {
      "countyFIP": "085",
      "countyName": "Jo Daviess"
    },
    {
      "countyFIP": "087",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "089",
      "countyName": "Kane"
    },
    {
      "countyFIP": "091",
      "countyName": "Kankakee"
    },
    {
      "countyFIP": "093",
      "countyName": "Kendall"
    },
    {
      "countyFIP": "095",
      "countyName": "Knox"
    },
    {
      "countyFIP": "097",
      "countyName": "Lake"
    },
    {
      "countyFIP": "099",
      "countyName": "LaSalle"
    },
    {
      "countyFIP": "101",
      "countyName": "Lawrence"
    },
    {
      "countyFIP": "103",
      "countyName": "Lee"
    },
    {
      "countyFIP": "105",
      "countyName": "Livingston"
    },
    {
      "countyFIP": "107",
      "countyName": "Logan"
    },
    {
      "countyFIP": "109",
      "countyName": "McDonough"
    },
    {
      "countyFIP": "111",
      "countyName": "McHenry"
    },
    {
      "countyFIP": "113",
      "countyName": "McLean"
    },
    {
      "countyFIP": "115",
      "countyName": "Macon"
    },
    {
      "countyFIP": "117",
      "countyName": "Macoupin"
    },
    {
      "countyFIP": "119",
      "countyName": "Madison"
    },
    {
      "countyFIP": "121",
      "countyName": "Marion"
    },
    {
      "countyFIP": "123",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "125",
      "countyName": "Mason"
    },
    {
      "countyFIP": "127",
      "countyName": "Massac"
    },
    {
      "countyFIP": "129",
      "countyName": "Menard"
    },
    {
      "countyFIP": "131",
      "countyName": "Mercer"
    },
    {
      "countyFIP": "133",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "135",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "137",
      "countyName": "Morgan"
    },
    {
      "countyFIP": "139",
      "countyName": "Moultrie"
    },
    {
      "countyFIP": "141",
      "countyName": "Ogle"
    },
    {
      "countyFIP": "143",
      "countyName": "Peoria"
    },
    {
      "countyFIP": "145",
      "countyName": "Perry"
    },
    {
      "countyFIP": "147",
      "countyName": "Piatt"
    },
    {
      "countyFIP": "149",
      "countyName": "Pike"
    },
    {
      "countyFIP": "151",
      "countyName": "Pope"
    },
    {
      "countyFIP": "153",
      "countyName": "Pulaski"
    },
    {
      "countyFIP": "155",
      "countyName": "Putnam"
    },
    {
      "countyFIP": "157",
      "countyName": "Randolph"
    },
    {
      "countyFIP": "159",
      "countyName": "Richland"
    },
    {
      "countyFIP": "161",
      "countyName": "Rock Island"
    },
    {
      "countyFIP": "163",
      "countyName": "St Clair"
    },
    {
      "countyFIP": "165",
      "countyName": "Saline"
    },
    {
      "countyFIP": "167",
      "countyName": "Sangamon"
    },
    {
      "countyFIP": "169",
      "countyName": "Schuyler"
    },
    {
      "countyFIP": "171",
      "countyName": "Scott"
    },
    {
      "countyFIP": "173",
      "countyName": "Shelby"
    },
    {
      "countyFIP": "175",
      "countyName": "Stark"
    },
    {
      "countyFIP": "177",
      "countyName": "Stephenson"
    },
    {
      "countyFIP": "179",
      "countyName": "Tazewell"
    },
    {
      "countyFIP": "181",
      "countyName": "Union"
    },
    {
      "countyFIP": "183",
      "countyName": "Vermilion"
    },
    {
      "countyFIP": "185",
      "countyName": "Wabash"
    },
    {
      "countyFIP": "187",
      "countyName": "Warren"
    },
    {
      "countyFIP": "189",
      "countyName": "Washington"
    },
    {
      "countyFIP": "191",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "193",
      "countyName": "White"
    },
    {
      "countyFIP": "195",
      "countyName": "Whiteside"
    },
    {
      "countyFIP": "197",
      "countyName": "Will"
    },
    {
      "countyFIP": "199",
      "countyName": "Williamson"
    },
    {
      "countyFIP": "201",
      "countyName": "Winnebago"
    },
    {
      "countyFIP": "203",
      "countyName": "Woodford"
    }
  ],
  "18": [
    {
      "countyFIP": "001",
      "countyName": "Adams"
    },
    {
      "countyFIP": "003",
      "countyName": "Allen"
    },
    {
      "countyFIP": "005",
      "countyName": "Bartholomew"
    },
    {
      "countyFIP": "007",
      "countyName": "Benton"
    },
    {
      "countyFIP": "009",
      "countyName": "Blackford"
    },
    {
      "countyFIP": "011",
      "countyName": "Boone"
    },
    {
      "countyFIP": "013",
      "countyName": "Brown"
    },
    {
      "countyFIP": "015",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "017",
      "countyName": "Cass"
    },
    {
      "countyFIP": "019",
      "countyName": "Clark"
    },
    {
      "countyFIP": "021",
      "countyName": "Clay"
    },
    {
      "countyFIP": "023",
      "countyName": "Clinton"
    },
    {
      "countyFIP": "025",
      "countyName": "Crawford"
    },
    {
      "countyFIP": "027",
      "countyName": "Daviess"
    },
    {
      "countyFIP": "029",
      "countyName": "Dearborn"
    },
    {
      "countyFIP": "031",
      "countyName": "Decatur"
    },
    {
      "countyFIP": "033",
      "countyName": "DeKalb"
    },
    {
      "countyFIP": "035",
      "countyName": "Delaware"
    },
    {
      "countyFIP": "037",
      "countyName": "Dubois"
    },
    {
      "countyFIP": "039",
      "countyName": "Elkhart"
    },
    {
      "countyFIP": "041",
      "countyName": "Fayette"
    },
    {
      "countyFIP": "043",
      "countyName": "Floyd"
    },
    {
      "countyFIP": "045",
      "countyName": "Fountain"
    },
    {
      "countyFIP": "047",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "049",
      "countyName": "Fulton"
    },
    {
      "countyFIP": "051",
      "countyName": "Gibson"
    },
    {
      "countyFIP": "053",
      "countyName": "Grant"
    },
    {
      "countyFIP": "055",
      "countyName": "Greene"
    },
    {
      "countyFIP": "057",
      "countyName": "Hamilton"
    },
    {
      "countyFIP": "059",
      "countyName": "Hancock"
    },
    {
      "countyFIP": "061",
      "countyName": "Harrison"
    },
    {
      "countyFIP": "063",
      "countyName": "Hendricks"
    },
    {
      "countyFIP": "065",
      "countyName": "Henry"
    },
    {
      "countyFIP": "067",
      "countyName": "Howard"
    },
    {
      "countyFIP": "069",
      "countyName": "Huntington"
    },
    {
      "countyFIP": "071",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "073",
      "countyName": "Jasper"
    },
    {
      "countyFIP": "075",
      "countyName": "Jay"
    },
    {
      "countyFIP": "077",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "079",
      "countyName": "Jennings"
    },
    {
      "countyFIP": "081",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "083",
      "countyName": "Knox"
    },
    {
      "countyFIP": "085",
      "countyName": "Kosciusko"
    },
    {
      "countyFIP": "087",
      "countyName": "LaGrange"
    },
    {
      "countyFIP": "089",
      "countyName": "Lake"
    },
    {
      "countyFIP": "091",
      "countyName": "LaPorte"
    },
    {
      "countyFIP": "093",
      "countyName": "Lawrence"
    },
    {
      "countyFIP": "095",
      "countyName": "Madison"
    },
    {
      "countyFIP": "097",
      "countyName": "Marion"
    },
    {
      "countyFIP": "099",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "101",
      "countyName": "Martin"
    },
    {
      "countyFIP": "103",
      "countyName": "Miami"
    },
    {
      "countyFIP": "105",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "107",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "109",
      "countyName": "Morgan"
    },
    {
      "countyFIP": "111",
      "countyName": "Newton"
    },
    {
      "countyFIP": "113",
      "countyName": "Noble"
    },
    {
      "countyFIP": "115",
      "countyName": "Ohio"
    },
    {
      "countyFIP": "117",
      "countyName": "Orange"
    },
    {
      "countyFIP": "119",
      "countyName": "Owen"
    },
    {
      "countyFIP": "121",
      "countyName": "Parke"
    },
    {
      "countyFIP": "123",
      "countyName": "Perry"
    },
    {
      "countyFIP": "125",
      "countyName": "Pike"
    },
    {
      "countyFIP": "127",
      "countyName": "Porter"
    },
    {
      "countyFIP": "129",
      "countyName": "Posey"
    },
    {
      "countyFIP": "131",
      "countyName": "Pulaski"
    },
    {
      "countyFIP": "133",
      "countyName": "Putnam"
    },
    {
      "countyFIP": "135",
      "countyName": "Randolph"
    },
    {
      "countyFIP": "137",
      "countyName": "Ripley"
    },
    {
      "countyFIP": "139",
      "countyName": "Rush"
    },
    {
      "countyFIP": "141",
      "countyName": "St Joseph"
    },
    {
      "countyFIP": "143",
      "countyName": "Scott"
    },
    {
      "countyFIP": "145",
      "countyName": "Shelby"
    },
    {
      "countyFIP": "147",
      "countyName": "Spencer"
    },
    {
      "countyFIP": "149",
      "countyName": "Starke"
    },
    {
      "countyFIP": "151",
      "countyName": "Steuben"
    },
    {
      "countyFIP": "153",
      "countyName": "Sullivan"
    },
    {
      "countyFIP": "155",
      "countyName": "Switzerland"
    },
    {
      "countyFIP": "157",
      "countyName": "Tippecanoe"
    },
    {
      "countyFIP": "159",
      "countyName": "Tipton"
    },
    {
      "countyFIP": "161",
      "countyName": "Union"
    },
    {
      "countyFIP": "163",
      "countyName": "Vanderburgh"
    },
    {
      "countyFIP": "165",
      "countyName": "Vermillion"
    },
    {
      "countyFIP": "167",
      "countyName": "Vigo"
    },
    {
      "countyFIP": "169",
      "countyName": "Wabash"
    },
    {
      "countyFIP": "171",
      "countyName": "Warren"
    },
    {
      "countyFIP": "173",
      "countyName": "Warrick"
    },
    {
      "countyFIP": "175",
      "countyName": "Washington"
    },
    {
      "countyFIP": "177",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "179",
      "countyName": "Wells"
    },
    {
      "countyFIP": "181",
      "countyName": "White"
    },
    {
      "countyFIP": "183",
      "countyName": "Whitley"
    }
  ],
  "19": [
    {
      "countyFIP": "001",
      "countyName": "Adair"
    },
    {
      "countyFIP": "003",
      "countyName": "Adams"
    },
    {
      "countyFIP": "005",
      "countyName": "Allamakee"
    },
    {
      "countyFIP": "007",
      "countyName": "Appanoose"
    },
    {
      "countyFIP": "009",
      "countyName": "Audubon"
    },
    {
      "countyFIP": "011",
      "countyName": "Benton"
    },
    {
      "countyFIP": "013",
      "countyName": "Black Hawk"
    },
    {
      "countyFIP": "015",
      "countyName": "Boone"
    },
    {
      "countyFIP": "017",
      "countyName": "Bremer"
    },
    {
      "countyFIP": "019",
      "countyName": "Buchanan"
    },
    {
      "countyFIP": "021",
      "countyName": "Buena Vista"
    },
    {
      "countyFIP": "023",
      "countyName": "Butler"
    },
    {
      "countyFIP": "025",
      "countyName": "Calhoun"
    },
    {
      "countyFIP": "027",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "029",
      "countyName": "Cass"
    },
    {
      "countyFIP": "031",
      "countyName": "Cedar"
    },
    {
      "countyFIP": "033",
      "countyName": "Cerro Gordo"
    },
    {
      "countyFIP": "035",
      "countyName": "Cherokee"
    },
    {
      "countyFIP": "037",
      "countyName": "Chickasaw"
    },
    {
      "countyFIP": "039",
      "countyName": "Clarke"
    },
    {
      "countyFIP": "041",
      "countyName": "Clay"
    },
    {
      "countyFIP": "043",
      "countyName": "Clayton"
    },
    {
      "countyFIP": "045",
      "countyName": "Clinton"
    },
    {
      "countyFIP": "047",
      "countyName": "Crawford"
    },
    {
      "countyFIP": "049",
      "countyName": "Dallas"
    },
    {
      "countyFIP": "051",
      "countyName": "Davis"
    },
    {
      "countyFIP": "053",
      "countyName": "Decatur"
    },
    {
      "countyFIP": "055",
      "countyName": "Delaware"
    },
    {
      "countyFIP": "057",
      "countyName": "Des Moines"
    },
    {
      "countyFIP": "059",
      "countyName": "Dickinson"
    },
    {
      "countyFIP": "061",
      "countyName": "Dubuque"
    },
    {
      "countyFIP": "063",
      "countyName": "Emmet"
    },
    {
      "countyFIP": "065",
      "countyName": "Fayette"
    },
    {
      "countyFIP": "067",
      "countyName": "Floyd"
    },
    {
      "countyFIP": "069",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "071",
      "countyName": "Fremont"
    },
    {
      "countyFIP": "073",
      "countyName": "Greene"
    },
    {
      "countyFIP": "075",
      "countyName": "Grundy"
    },
    {
      "countyFIP": "077",
      "countyName": "Guthrie"
    },
    {
      "countyFIP": "079",
      "countyName": "Hamilton"
    },
    {
      "countyFIP": "081",
      "countyName": "Hancock"
    },
    {
      "countyFIP": "083",
      "countyName": "Hardin"
    },
    {
      "countyFIP": "085",
      "countyName": "Harrison"
    },
    {
      "countyFIP": "087",
      "countyName": "Henry"
    },
    {
      "countyFIP": "089",
      "countyName": "Howard"
    },
    {
      "countyFIP": "091",
      "countyName": "Humboldt"
    },
    {
      "countyFIP": "093",
      "countyName": "Ida"
    },
    {
      "countyFIP": "095",
      "countyName": "Iowa"
    },
    {
      "countyFIP": "097",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "099",
      "countyName": "Jasper"
    },
    {
      "countyFIP": "101",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "103",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "105",
      "countyName": "Jones"
    },
    {
      "countyFIP": "107",
      "countyName": "Keokuk"
    },
    {
      "countyFIP": "109",
      "countyName": "Kossuth"
    },
    {
      "countyFIP": "111",
      "countyName": "Lee"
    },
    {
      "countyFIP": "113",
      "countyName": "Linn"
    },
    {
      "countyFIP": "115",
      "countyName": "Louisa"
    },
    {
      "countyFIP": "117",
      "countyName": "Lucas"
    },
    {
      "countyFIP": "119",
      "countyName": "Lyon"
    },
    {
      "countyFIP": "121",
      "countyName": "Madison"
    },
    {
      "countyFIP": "123",
      "countyName": "Mahaska"
    },
    {
      "countyFIP": "125",
      "countyName": "Marion"
    },
    {
      "countyFIP": "127",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "129",
      "countyName": "Mills"
    },
    {
      "countyFIP": "131",
      "countyName": "Mitchell"
    },
    {
      "countyFIP": "133",
      "countyName": "Monona"
    },
    {
      "countyFIP": "135",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "137",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "139",
      "countyName": "Muscatine"
    },
    {
      "countyFIP": "141",
      "countyName": "O'Brien"
    },
    {
      "countyFIP": "143",
      "countyName": "Osceola"
    },
    {
      "countyFIP": "145",
      "countyName": "Page"
    },
    {
      "countyFIP": "147",
      "countyName": "Palo Alto"
    },
    {
      "countyFIP": "149",
      "countyName": "Plymouth"
    },
    {
      "countyFIP": "151",
      "countyName": "Pocahontas"
    },
    {
      "countyFIP": "153",
      "countyName": "Polk"
    },
    {
      "countyFIP": "155",
      "countyName": "Pottawattamie"
    },
    {
      "countyFIP": "157",
      "countyName": "Poweshiek"
    },
    {
      "countyFIP": "159",
      "countyName": "Ringgold"
    },
    {
      "countyFIP": "161",
      "countyName": "Sac"
    },
    {
      "countyFIP": "163",
      "countyName": "Scott"
    },
    {
      "countyFIP": "165",
      "countyName": "Shelby"
    },
    {
      "countyFIP": "167",
      "countyName": "Sioux"
    },
    {
      "countyFIP": "169",
      "countyName": "Story"
    },
    {
      "countyFIP": "171",
      "countyName": "Tama"
    },
    {
      "countyFIP": "173",
      "countyName": "Taylor"
    },
    {
      "countyFIP": "175",
      "countyName": "Union"
    },
    {
      "countyFIP": "177",
      "countyName": "Van Buren"
    },
    {
      "countyFIP": "179",
      "countyName": "Wapello"
    },
    {
      "countyFIP": "181",
      "countyName": "Warren"
    },
    {
      "countyFIP": "183",
      "countyName": "Washington"
    },
    {
      "countyFIP": "185",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "187",
      "countyName": "Webster"
    },
    {
      "countyFIP": "189",
      "countyName": "Winnebago"
    },
    {
      "countyFIP": "191",
      "countyName": "Winneshiek"
    },
    {
      "countyFIP": "193",
      "countyName": "Woodbury"
    },
    {
      "countyFIP": "195",
      "countyName": "Worth"
    },
    {
      "countyFIP": "197",
      "countyName": "Wright"
    }
  ],
  "20": [
    {
      "countyFIP": "001",
      "countyName": "Allen"
    },
    {
      "countyFIP": "003",
      "countyName": "Anderson"
    },
    {
      "countyFIP": "005",
      "countyName": "Atchison"
    },
    {
      "countyFIP": "007",
      "countyName": "Barber"
    },
    {
      "countyFIP": "009",
      "countyName": "Barton"
    },
    {
      "countyFIP": "011",
      "countyName": "Bourbon"
    },
    {
      "countyFIP": "013",
      "countyName": "Brown"
    },
    {
      "countyFIP": "015",
      "countyName": "Butler"
    },
    {
      "countyFIP": "017",
      "countyName": "Chase"
    },
    {
      "countyFIP": "019",
      "countyName": "Chautauqua"
    },
    {
      "countyFIP": "021",
      "countyName": "Cherokee"
    },
    {
      "countyFIP": "023",
      "countyName": "Cheyenne"
    },
    {
      "countyFIP": "025",
      "countyName": "Clark"
    },
    {
      "countyFIP": "027",
      "countyName": "Clay"
    },
    {
      "countyFIP": "029",
      "countyName": "Cloud"
    },
    {
      "countyFIP": "031",
      "countyName": "Coffey"
    },
    {
      "countyFIP": "033",
      "countyName": "Comanche"
    },
    {
      "countyFIP": "035",
      "countyName": "Cowley"
    },
    {
      "countyFIP": "037",
      "countyName": "Crawford"
    },
    {
      "countyFIP": "039",
      "countyName": "Decatur"
    },
    {
      "countyFIP": "041",
      "countyName": "Dickinson"
    },
    {
      "countyFIP": "043",
      "countyName": "Doniphan"
    },
    {
      "countyFIP": "045",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "047",
      "countyName": "Edwards"
    },
    {
      "countyFIP": "049",
      "countyName": "Elk"
    },
    {
      "countyFIP": "051",
      "countyName": "Ellis"
    },
    {
      "countyFIP": "053",
      "countyName": "Ellsworth"
    },
    {
      "countyFIP": "055",
      "countyName": "Finney"
    },
    {
      "countyFIP": "057",
      "countyName": "Ford"
    },
    {
      "countyFIP": "059",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "061",
      "countyName": "Geary"
    },
    {
      "countyFIP": "063",
      "countyName": "Gove"
    },
    {
      "countyFIP": "065",
      "countyName": "Graham"
    },
    {
      "countyFIP": "067",
      "countyName": "Grant"
    },
    {
      "countyFIP": "069",
      "countyName": "Gray"
    },
    {
      "countyFIP": "071",
      "countyName": "Greeley"
    },
    {
      "countyFIP": "073",
      "countyName": "Greenwood"
    },
    {
      "countyFIP": "075",
      "countyName": "Hamilton"
    },
    {
      "countyFIP": "077",
      "countyName": "Harper"
    },
    {
      "countyFIP": "079",
      "countyName": "Harvey"
    },
    {
      "countyFIP": "081",
      "countyName": "Haskell"
    },
    {
      "countyFIP": "083",
      "countyName": "Hodgeman"
    },
    {
      "countyFIP": "085",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "087",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "089",
      "countyName": "Jewell"
    },
    {
      "countyFIP": "091",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "093",
      "countyName": "Kearny"
    },
    {
      "countyFIP": "095",
      "countyName": "Kingman"
    },
    {
      "countyFIP": "097",
      "countyName": "Kiowa"
    },
    {
      "countyFIP": "099",
      "countyName": "Labette"
    },
    {
      "countyFIP": "101",
      "countyName": "Lane"
    },
    {
      "countyFIP": "103",
      "countyName": "Leavenworth"
    },
    {
      "countyFIP": "105",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "107",
      "countyName": "Linn"
    },
    {
      "countyFIP": "109",
      "countyName": "Logan"
    },
    {
      "countyFIP": "111",
      "countyName": "Lyon"
    },
    {
      "countyFIP": "113",
      "countyName": "McPherson"
    },
    {
      "countyFIP": "115",
      "countyName": "Marion"
    },
    {
      "countyFIP": "117",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "119",
      "countyName": "Meade"
    },
    {
      "countyFIP": "121",
      "countyName": "Miami"
    },
    {
      "countyFIP": "123",
      "countyName": "Mitchell"
    },
    {
      "countyFIP": "125",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "127",
      "countyName": "Morris"
    },
    {
      "countyFIP": "129",
      "countyName": "Morton"
    },
    {
      "countyFIP": "131",
      "countyName": "Nemaha"
    },
    {
      "countyFIP": "133",
      "countyName": "Neosho"
    },
    {
      "countyFIP": "135",
      "countyName": "Ness"
    },
    {
      "countyFIP": "137",
      "countyName": "Norton"
    },
    {
      "countyFIP": "139",
      "countyName": "Osage"
    },
    {
      "countyFIP": "141",
      "countyName": "Osborne"
    },
    {
      "countyFIP": "143",
      "countyName": "Ottawa"
    },
    {
      "countyFIP": "145",
      "countyName": "Pawnee"
    },
    {
      "countyFIP": "147",
      "countyName": "Phillips"
    },
    {
      "countyFIP": "149",
      "countyName": "Pottawatomie"
    },
    {
      "countyFIP": "151",
      "countyName": "Pratt"
    },
    {
      "countyFIP": "153",
      "countyName": "Rawlins"
    },
    {
      "countyFIP": "155",
      "countyName": "Reno"
    },
    {
      "countyFIP": "157",
      "countyName": "Republic"
    },
    {
      "countyFIP": "159",
      "countyName": "Rice"
    },
    {
      "countyFIP": "161",
      "countyName": "Riley"
    },
    {
      "countyFIP": "163",
      "countyName": "Rooks"
    },
    {
      "countyFIP": "165",
      "countyName": "Rush"
    },
    {
      "countyFIP": "167",
      "countyName": "Russell"
    },
    {
      "countyFIP": "169",
      "countyName": "Saline"
    },
    {
      "countyFIP": "171",
      "countyName": "Scott"
    },
    {
      "countyFIP": "173",
      "countyName": "Sedgwick"
    },
    {
      "countyFIP": "175",
      "countyName": "Seward"
    },
    {
      "countyFIP": "177",
      "countyName": "Shawnee"
    },
    {
      "countyFIP": "179",
      "countyName": "Sheridan"
    },
    {
      "countyFIP": "181",
      "countyName": "Sherman"
    },
    {
      "countyFIP": "183",
      "countyName": "Smith"
    },
    {
      "countyFIP": "185",
      "countyName": "Stafford"
    },
    {
      "countyFIP": "187",
      "countyName": "Stanton"
    },
    {
      "countyFIP": "189",
      "countyName": "Stevens"
    },
    {
      "countyFIP": "191",
      "countyName": "Sumner"
    },
    {
      "countyFIP": "193",
      "countyName": "Thomas"
    },
    {
      "countyFIP": "195",
      "countyName": "Trego"
    },
    {
      "countyFIP": "197",
      "countyName": "Wabaunsee"
    },
    {
      "countyFIP": "199",
      "countyName": "Wallace"
    },
    {
      "countyFIP": "201",
      "countyName": "Washington"
    },
    {
      "countyFIP": "203",
      "countyName": "Wichita"
    },
    {
      "countyFIP": "205",
      "countyName": "Wilson"
    },
    {
      "countyFIP": "207",
      "countyName": "Woodson"
    },
    {
      "countyFIP": "209",
      "countyName": "Wyandotte"
    }
  ],
  "21": [
    {
      "countyFIP": "001",
      "countyName": "Adair"
    },
    {
      "countyFIP": "003",
      "countyName": "Allen"
    },
    {
      "countyFIP": "005",
      "countyName": "Anderson"
    },
    {
      "countyFIP": "007",
      "countyName": "Ballard"
    },
    {
      "countyFIP": "009",
      "countyName": "Barren"
    },
    {
      "countyFIP": "011",
      "countyName": "Bath"
    },
    {
      "countyFIP": "013",
      "countyName": "Bell"
    },
    {
      "countyFIP": "015",
      "countyName": "Boone"
    },
    {
      "countyFIP": "017",
      "countyName": "Bourbon"
    },
    {
      "countyFIP": "019",
      "countyName": "Boyd"
    },
    {
      "countyFIP": "021",
      "countyName": "Boyle"
    },
    {
      "countyFIP": "023",
      "countyName": "Bracken"
    },
    {
      "countyFIP": "025",
      "countyName": "Breathitt"
    },
    {
      "countyFIP": "027",
      "countyName": "Breckinridge"
    },
    {
      "countyFIP": "029",
      "countyName": "Bullitt"
    },
    {
      "countyFIP": "031",
      "countyName": "Butler"
    },
    {
      "countyFIP": "033",
      "countyName": "Caldwell"
    },
    {
      "countyFIP": "035",
      "countyName": "Calloway"
    },
    {
      "countyFIP": "037",
      "countyName": "Campbell"
    },
    {
      "countyFIP": "039",
      "countyName": "Carlisle"
    },
    {
      "countyFIP": "041",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "043",
      "countyName": "Carter"
    },
    {
      "countyFIP": "045",
      "countyName": "Casey"
    },
    {
      "countyFIP": "047",
      "countyName": "Christian"
    },
    {
      "countyFIP": "049",
      "countyName": "Clark"
    },
    {
      "countyFIP": "051",
      "countyName": "Clay"
    },
    {
      "countyFIP": "053",
      "countyName": "Clinton"
    },
    {
      "countyFIP": "055",
      "countyName": "Crittenden"
    },
    {
      "countyFIP": "057",
      "countyName": "Cumberland"
    },
    {
      "countyFIP": "059",
      "countyName": "Daviess"
    },
    {
      "countyFIP": "061",
      "countyName": "Edmonson"
    },
    {
      "countyFIP": "063",
      "countyName": "Elliott"
    },
    {
      "countyFIP": "065",
      "countyName": "Estill"
    },
    {
      "countyFIP": "067",
      "countyName": "Fayette"
    },
    {
      "countyFIP": "069",
      "countyName": "Fleming"
    },
    {
      "countyFIP": "071",
      "countyName": "Floyd"
    },
    {
      "countyFIP": "073",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "075",
      "countyName": "Fulton"
    },
    {
      "countyFIP": "077",
      "countyName": "Gallatin"
    },
    {
      "countyFIP": "079",
      "countyName": "Garrard"
    },
    {
      "countyFIP": "081",
      "countyName": "Grant"
    },
    {
      "countyFIP": "083",
      "countyName": "Graves"
    },
    {
      "countyFIP": "085",
      "countyName": "Grayson"
    },
    {
      "countyFIP": "087",
      "countyName": "Green"
    },
    {
      "countyFIP": "089",
      "countyName": "Greenup"
    },
    {
      "countyFIP": "091",
      "countyName": "Hancock"
    },
    {
      "countyFIP": "093",
      "countyName": "Hardin"
    },
    {
      "countyFIP": "095",
      "countyName": "Harlan"
    },
    {
      "countyFIP": "097",
      "countyName": "Harrison"
    },
    {
      "countyFIP": "099",
      "countyName": "Hart"
    },
    {
      "countyFIP": "101",
      "countyName": "Henderson"
    },
    {
      "countyFIP": "103",
      "countyName": "Henry"
    },
    {
      "countyFIP": "105",
      "countyName": "Hickman"
    },
    {
      "countyFIP": "107",
      "countyName": "Hopkins"
    },
    {
      "countyFIP": "109",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "111",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "113",
      "countyName": "Jessamine"
    },
    {
      "countyFIP": "115",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "117",
      "countyName": "Kenton"
    },
    {
      "countyFIP": "119",
      "countyName": "Knott"
    },
    {
      "countyFIP": "121",
      "countyName": "Knox"
    },
    {
      "countyFIP": "123",
      "countyName": "Larue"
    },
    {
      "countyFIP": "125",
      "countyName": "Laurel"
    },
    {
      "countyFIP": "127",
      "countyName": "Lawrence"
    },
    {
      "countyFIP": "129",
      "countyName": "Lee"
    },
    {
      "countyFIP": "131",
      "countyName": "Leslie"
    },
    {
      "countyFIP": "133",
      "countyName": "Letcher"
    },
    {
      "countyFIP": "135",
      "countyName": "Lewis"
    },
    {
      "countyFIP": "137",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "139",
      "countyName": "Livingston"
    },
    {
      "countyFIP": "141",
      "countyName": "Logan"
    },
    {
      "countyFIP": "143",
      "countyName": "Lyon"
    },
    {
      "countyFIP": "145",
      "countyName": "McCracken"
    },
    {
      "countyFIP": "147",
      "countyName": "McCreary"
    },
    {
      "countyFIP": "149",
      "countyName": "McLean"
    },
    {
      "countyFIP": "151",
      "countyName": "Madison"
    },
    {
      "countyFIP": "153",
      "countyName": "Magoffin"
    },
    {
      "countyFIP": "155",
      "countyName": "Marion"
    },
    {
      "countyFIP": "157",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "159",
      "countyName": "Martin"
    },
    {
      "countyFIP": "161",
      "countyName": "Mason"
    },
    {
      "countyFIP": "163",
      "countyName": "Meade"
    },
    {
      "countyFIP": "165",
      "countyName": "Menifee"
    },
    {
      "countyFIP": "167",
      "countyName": "Mercer"
    },
    {
      "countyFIP": "169",
      "countyName": "Metcalfe"
    },
    {
      "countyFIP": "171",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "173",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "175",
      "countyName": "Morgan"
    },
    {
      "countyFIP": "177",
      "countyName": "Muhlenberg"
    },
    {
      "countyFIP": "179",
      "countyName": "Nelson"
    },
    {
      "countyFIP": "181",
      "countyName": "Nicholas"
    },
    {
      "countyFIP": "183",
      "countyName": "Ohio"
    },
    {
      "countyFIP": "185",
      "countyName": "Oldham"
    },
    {
      "countyFIP": "187",
      "countyName": "Owen"
    },
    {
      "countyFIP": "189",
      "countyName": "Owsley"
    },
    {
      "countyFIP": "191",
      "countyName": "Pendleton"
    },
    {
      "countyFIP": "193",
      "countyName": "Perry"
    },
    {
      "countyFIP": "195",
      "countyName": "Pike"
    },
    {
      "countyFIP": "197",
      "countyName": "Powell"
    },
    {
      "countyFIP": "199",
      "countyName": "Pulaski"
    },
    {
      "countyFIP": "201",
      "countyName": "Robertson"
    },
    {
      "countyFIP": "203",
      "countyName": "Rockcastle"
    },
    {
      "countyFIP": "205",
      "countyName": "Rowan"
    },
    {
      "countyFIP": "207",
      "countyName": "Russell"
    },
    {
      "countyFIP": "209",
      "countyName": "Scott"
    },
    {
      "countyFIP": "211",
      "countyName": "Shelby"
    },
    {
      "countyFIP": "213",
      "countyName": "Simpson"
    },
    {
      "countyFIP": "215",
      "countyName": "Spencer"
    },
    {
      "countyFIP": "217",
      "countyName": "Taylor"
    },
    {
      "countyFIP": "219",
      "countyName": "Todd"
    },
    {
      "countyFIP": "221",
      "countyName": "Trigg"
    },
    {
      "countyFIP": "223",
      "countyName": "Trimble"
    },
    {
      "countyFIP": "225",
      "countyName": "Union"
    },
    {
      "countyFIP": "227",
      "countyName": "Warren"
    },
    {
      "countyFIP": "229",
      "countyName": "Washington"
    },
    {
      "countyFIP": "231",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "233",
      "countyName": "Webster"
    },
    {
      "countyFIP": "235",
      "countyName": "Whitley"
    },
    {
      "countyFIP": "237",
      "countyName": "Wolfe"
    },
    {
      "countyFIP": "239",
      "countyName": "Woodford"
    }
  ],
  "22": [
    {
      "countyFIP": "001",
      "countyName": "Acadia"
    },
    {
      "countyFIP": "003",
      "countyName": "Allen"
    },
    {
      "countyFIP": "005",
      "countyName": "Ascension"
    },
    {
      "countyFIP": "007",
      "countyName": "Assumption"
    },
    {
      "countyFIP": "009",
      "countyName": "Avoyelles"
    },
    {
      "countyFIP": "011",
      "countyName": "Beauregard"
    },
    {
      "countyFIP": "013",
      "countyName": "Bienville"
    },
    {
      "countyFIP": "015",
      "countyName": "Bossier"
    },
    {
      "countyFIP": "017",
      "countyName": "Caddo"
    },
    {
      "countyFIP": "019",
      "countyName": "Calcasieu"
    },
    {
      "countyFIP": "021",
      "countyName": "Caldwell"
    },
    {
      "countyFIP": "023",
      "countyName": "Cameron"
    },
    {
      "countyFIP": "025",
      "countyName": "Catahoula"
    },
    {
      "countyFIP": "027",
      "countyName": "Claiborne"
    },
    {
      "countyFIP": "029",
      "countyName": "Concordia"
    },
    {
      "countyFIP": "031",
      "countyName": "De Soto"
    },
    {
      "countyFIP": "033",
      "countyName": "East Baton Rouge"
    },
    {
      "countyFIP": "035",
      "countyName": "East Carroll"
    },
    {
      "countyFIP": "037",
      "countyName": "East Feliciana"
    },
    {
      "countyFIP": "039",
      "countyName": "Evangeline"
    },
    {
      "countyFIP": "041",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "043",
      "countyName": "Grant"
    },
    {
      "countyFIP": "045",
      "countyName": "Iberia"
    },
    {
      "countyFIP": "047",
      "countyName": "Iberville"
    },
    {
      "countyFIP": "049",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "051",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "053",
      "countyName": "Jefferson Davis"
    },
    {
      "countyFIP": "055",
      "countyName": "Lafayette"
    },
    {
      "countyFIP": "057",
      "countyName": "Lafourche"
    },
    {
      "countyFIP": "059",
      "countyName": "La Salle"
    },
    {
      "countyFIP": "061",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "063",
      "countyName": "Livingston"
    },
    {
      "countyFIP": "065",
      "countyName": "Madison"
    },
    {
      "countyFIP": "067",
      "countyName": "Morehouse"
    },
    {
      "countyFIP": "069",
      "countyName": "Natchitoches"
    },
    {
      "countyFIP": "071",
      "countyName": "Orleans"
    },
    {
      "countyFIP": "073",
      "countyName": "Ouachita"
    },
    {
      "countyFIP": "075",
      "countyName": "Plaquemines"
    },
    {
      "countyFIP": "077",
      "countyName": "Pointe Coupee"
    },
    {
      "countyFIP": "079",
      "countyName": "Rapides"
    },
    {
      "countyFIP": "081",
      "countyName": "Red River"
    },
    {
      "countyFIP": "083",
      "countyName": "Richland"
    },
    {
      "countyFIP": "085",
      "countyName": "Sabine"
    },
    {
      "countyFIP": "087",
      "countyName": "St Bernard"
    },
    {
      "countyFIP": "089",
      "countyName": "St Charles"
    },
    {
      "countyFIP": "091",
      "countyName": "St Helena"
    },
    {
      "countyFIP": "093",
      "countyName": "St James"
    },
    {
      "countyFIP": "095",
      "countyName": "St John the Baptist"
    },
    {
      "countyFIP": "097",
      "countyName": "St Landry"
    },
    {
      "countyFIP": "099",
      "countyName": "St Martin"
    },
    {
      "countyFIP": "101",
      "countyName": "St Mary"
    },
    {
      "countyFIP": "103",
      "countyName": "St Tammany"
    },
    {
      "countyFIP": "105",
      "countyName": "Tangipahoa"
    },
    {
      "countyFIP": "107",
      "countyName": "Tensas"
    },
    {
      "countyFIP": "109",
      "countyName": "Terrebonne"
    },
    {
      "countyFIP": "111",
      "countyName": "Union"
    },
    {
      "countyFIP": "113",
      "countyName": "Vermilion"
    },
    {
      "countyFIP": "115",
      "countyName": "Vernon"
    },
    {
      "countyFIP": "117",
      "countyName": "Washington"
    },
    {
      "countyFIP": "119",
      "countyName": "Webster"
    },
    {
      "countyFIP": "121",
      "countyName": "West Baton Rouge"
    },
    {
      "countyFIP": "123",
      "countyName": "West Carroll"
    },
    {
      "countyFIP": "125",
      "countyName": "West Feliciana"
    },
    {
      "countyFIP": "127",
      "countyName": "Winn"
    }
  ],
  "23": [
    {
      "countyFIP": "001",
      "countyName": "Androscoggin"
    },
    {
      "countyFIP": "003",
      "countyName": "Aroostook"
    },
    {
      "countyFIP": "005",
      "countyName": "Cumberland"
    },
    {
      "countyFIP": "007",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "009",
      "countyName": "Hancock"
    },
    {
      "countyFIP": "011",
      "countyName": "Kennebec"
    },
    {
      "countyFIP": "013",
      "countyName": "Knox"
    },
    {
      "countyFIP": "015",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "017",
      "countyName": "Oxford"
    },
    {
      "countyFIP": "019",
      "countyName": "Penobscot"
    },
    {
      "countyFIP": "021",
      "countyName": "Piscataquis"
    },
    {
      "countyFIP": "023",
      "countyName": "Sagadahoc"
    },
    {
      "countyFIP": "025",
      "countyName": "Somerset"
    },
    {
      "countyFIP": "027",
      "countyName": "Waldo"
    },
    {
      "countyFIP": "029",
      "countyName": "Washington"
    },
    {
      "countyFIP": "031",
      "countyName": "York"
    }
  ],
  "24": [
    {
      "countyFIP": "001",
      "countyName": "Allegany"
    },
    {
      "countyFIP": "003",
      "countyName": "Anne Arundel"
    },
    {
      "countyFIP": "005",
      "countyName": "Baltimore"
    },
    {
      "countyFIP": "009",
      "countyName": "Calvert"
    },
    {
      "countyFIP": "011",
      "countyName": "Caroline"
    },
    {
      "countyFIP": "013",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "015",
      "countyName": "Cecil"
    },
    {
      "countyFIP": "017",
      "countyName": "Charles"
    },
    {
      "countyFIP": "019",
      "countyName": "Dorchester"
    },
    {
      "countyFIP": "021",
      "countyName": "Frederick"
    },
    {
      "countyFIP": "023",
      "countyName": "Garrett"
    },
    {
      "countyFIP": "025",
      "countyName": "Harford"
    },
    {
      "countyFIP": "027",
      "countyName": "Howard"
    },
    {
      "countyFIP": "029",
      "countyName": "Kent"
    },
    {
      "countyFIP": "031",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "033",
      "countyName": "Prince George's"
    },
    {
      "countyFIP": "035",
      "countyName": "Queen Anne's"
    },
    {
      "countyFIP": "037",
      "countyName": "St Mary's"
    },
    {
      "countyFIP": "039",
      "countyName": "Somerset"
    },
    {
      "countyFIP": "041",
      "countyName": "Talbot"
    },
    {
      "countyFIP": "043",
      "countyName": "Washington"
    },
    {
      "countyFIP": "045",
      "countyName": "Wicomico"
    },
    {
      "countyFIP": "047",
      "countyName": "Worcester"
    },
    {
      "countyFIP": "510",
      "countyName": "Baltimore City"
    }
  ],
  "25": [
    {
      "countyFIP": "001",
      "countyName": "Barnstable"
    },
    {
      "countyFIP": "003",
      "countyName": "Berkshire"
    },
    {
      "countyFIP": "005",
      "countyName": "Bristol"
    },
    {
      "countyFIP": "007",
      "countyName": "Dukes"
    },
    {
      "countyFIP": "009",
      "countyName": "Essex"
    },
    {
      "countyFIP": "011",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "013",
      "countyName": "Hampden"
    },
    {
      "countyFIP": "015",
      "countyName": "Hampshire"
    },
    {
      "countyFIP": "017",
      "countyName": "Middlesex"
    },
    {
      "countyFIP": "019",
      "countyName": "Nantucket"
    },
    {
      "countyFIP": "021",
      "countyName": "Norfolk"
    },
    {
      "countyFIP": "023",
      "countyName": "Plymouth"
    },
    {
      "countyFIP": "025",
      "countyName": "Suffolk"
    },
    {
      "countyFIP": "027",
      "countyName": "Worcester"
    }
  ],
  "26": [
    {
      "countyFIP": "001",
      "countyName": "Alcona"
    },
    {
      "countyFIP": "003",
      "countyName": "Alger"
    },
    {
      "countyFIP": "005",
      "countyName": "Allegan"
    },
    {
      "countyFIP": "007",
      "countyName": "Alpena"
    },
    {
      "countyFIP": "009",
      "countyName": "Antrim"
    },
    {
      "countyFIP": "011",
      "countyName": "Arenac"
    },
    {
      "countyFIP": "013",
      "countyName": "Baraga"
    },
    {
      "countyFIP": "015",
      "countyName": "Barry"
    },
    {
      "countyFIP": "017",
      "countyName": "Bay"
    },
    {
      "countyFIP": "019",
      "countyName": "Benzie"
    },
    {
      "countyFIP": "021",
      "countyName": "Berrien"
    },
    {
      "countyFIP": "023",
      "countyName": "Branch"
    },
    {
      "countyFIP": "025",
      "countyName": "Calhoun"
    },
    {
      "countyFIP": "027",
      "countyName": "Cass"
    },
    {
      "countyFIP": "029",
      "countyName": "Charlevoix"
    },
    {
      "countyFIP": "031",
      "countyName": "Cheboygan"
    },
    {
      "countyFIP": "033",
      "countyName": "Chippewa"
    },
    {
      "countyFIP": "035",
      "countyName": "Clare"
    },
    {
      "countyFIP": "037",
      "countyName": "Clinton"
    },
    {
      "countyFIP": "039",
      "countyName": "Crawford"
    },
    {
      "countyFIP": "041",
      "countyName": "Delta"
    },
    {
      "countyFIP": "043",
      "countyName": "Dickinson"
    },
    {
      "countyFIP": "045",
      "countyName": "Eaton"
    },
    {
      "countyFIP": "047",
      "countyName": "Emmet"
    },
    {
      "countyFIP": "049",
      "countyName": "Genesee"
    },
    {
      "countyFIP": "051",
      "countyName": "Gladwin"
    },
    {
      "countyFIP": "053",
      "countyName": "Gogebic"
    },
    {
      "countyFIP": "055",
      "countyName": "Grand Traverse"
    },
    {
      "countyFIP": "057",
      "countyName": "Gratiot"
    },
    {
      "countyFIP": "059",
      "countyName": "Hillsdale"
    },
    {
      "countyFIP": "061",
      "countyName": "Houghton"
    },
    {
      "countyFIP": "063",
      "countyName": "Huron"
    },
    {
      "countyFIP": "065",
      "countyName": "Ingham"
    },
    {
      "countyFIP": "067",
      "countyName": "Ionia"
    },
    {
      "countyFIP": "069",
      "countyName": "Iosco"
    },
    {
      "countyFIP": "071",
      "countyName": "Iron"
    },
    {
      "countyFIP": "073",
      "countyName": "Isabella"
    },
    {
      "countyFIP": "075",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "077",
      "countyName": "Kalamazoo"
    },
    {
      "countyFIP": "079",
      "countyName": "Kalkaska"
    },
    {
      "countyFIP": "081",
      "countyName": "Kent"
    },
    {
      "countyFIP": "083",
      "countyName": "Keweenaw"
    },
    {
      "countyFIP": "085",
      "countyName": "Lake"
    },
    {
      "countyFIP": "087",
      "countyName": "Lapeer"
    },
    {
      "countyFIP": "089",
      "countyName": "Leelanau"
    },
    {
      "countyFIP": "091",
      "countyName": "Lenawee"
    },
    {
      "countyFIP": "093",
      "countyName": "Livingston"
    },
    {
      "countyFIP": "095",
      "countyName": "Luce"
    },
    {
      "countyFIP": "097",
      "countyName": "Mackinac"
    },
    {
      "countyFIP": "099",
      "countyName": "Macomb"
    },
    {
      "countyFIP": "101",
      "countyName": "Manistee"
    },
    {
      "countyFIP": "103",
      "countyName": "Marquette"
    },
    {
      "countyFIP": "105",
      "countyName": "Mason"
    },
    {
      "countyFIP": "107",
      "countyName": "Mecosta"
    },
    {
      "countyFIP": "109",
      "countyName": "Menominee"
    },
    {
      "countyFIP": "111",
      "countyName": "Midland"
    },
    {
      "countyFIP": "113",
      "countyName": "Missaukee"
    },
    {
      "countyFIP": "115",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "117",
      "countyName": "Montcalm"
    },
    {
      "countyFIP": "119",
      "countyName": "Montmorency"
    },
    {
      "countyFIP": "121",
      "countyName": "Muskegon"
    },
    {
      "countyFIP": "123",
      "countyName": "Newaygo"
    },
    {
      "countyFIP": "125",
      "countyName": "Oakland"
    },
    {
      "countyFIP": "127",
      "countyName": "Oceana"
    },
    {
      "countyFIP": "129",
      "countyName": "Ogemaw"
    },
    {
      "countyFIP": "131",
      "countyName": "Ontonagon"
    },
    {
      "countyFIP": "133",
      "countyName": "Osceola"
    },
    {
      "countyFIP": "135",
      "countyName": "Oscoda"
    },
    {
      "countyFIP": "137",
      "countyName": "Otsego"
    },
    {
      "countyFIP": "139",
      "countyName": "Ottawa"
    },
    {
      "countyFIP": "141",
      "countyName": "Presque Isle"
    },
    {
      "countyFIP": "143",
      "countyName": "Roscommon"
    },
    {
      "countyFIP": "145",
      "countyName": "Saginaw"
    },
    {
      "countyFIP": "147",
      "countyName": "St Clair"
    },
    {
      "countyFIP": "149",
      "countyName": "St Joseph"
    },
    {
      "countyFIP": "151",
      "countyName": "Sanilac"
    },
    {
      "countyFIP": "153",
      "countyName": "Schoolcraft"
    },
    {
      "countyFIP": "155",
      "countyName": "Shiawassee"
    },
    {
      "countyFIP": "157",
      "countyName": "Tuscola"
    },
    {
      "countyFIP": "159",
      "countyName": "Van Buren"
    },
    {
      "countyFIP": "161",
      "countyName": "Washtenaw"
    },
    {
      "countyFIP": "163",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "165",
      "countyName": "Wexford"
    }
  ],
  "27": [
    {
      "countyFIP": "001",
      "countyName": "Aitkin"
    },
    {
      "countyFIP": "003",
      "countyName": "Anoka"
    },
    {
      "countyFIP": "005",
      "countyName": "Becker"
    },
    {
      "countyFIP": "007",
      "countyName": "Beltrami"
    },
    {
      "countyFIP": "009",
      "countyName": "Benton"
    },
    {
      "countyFIP": "011",
      "countyName": "Big Stone"
    },
    {
      "countyFIP": "013",
      "countyName": "Blue Earth"
    },
    {
      "countyFIP": "015",
      "countyName": "Brown"
    },
    {
      "countyFIP": "017",
      "countyName": "Carlton"
    },
    {
      "countyFIP": "019",
      "countyName": "Carver"
    },
    {
      "countyFIP": "021",
      "countyName": "Cass"
    },
    {
      "countyFIP": "023",
      "countyName": "Chippewa"
    },
    {
      "countyFIP": "025",
      "countyName": "Chisago"
    },
    {
      "countyFIP": "027",
      "countyName": "Clay"
    },
    {
      "countyFIP": "029",
      "countyName": "Clearwater"
    },
    {
      "countyFIP": "031",
      "countyName": "Cook"
    },
    {
      "countyFIP": "033",
      "countyName": "Cottonwood"
    },
    {
      "countyFIP": "035",
      "countyName": "Crow Wing"
    },
    {
      "countyFIP": "037",
      "countyName": "Dakota"
    },
    {
      "countyFIP": "039",
      "countyName": "Dodge"
    },
    {
      "countyFIP": "041",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "043",
      "countyName": "Faribault"
    },
    {
      "countyFIP": "045",
      "countyName": "Fillmore"
    },
    {
      "countyFIP": "047",
      "countyName": "Freeborn"
    },
    {
      "countyFIP": "049",
      "countyName": "Goodhue"
    },
    {
      "countyFIP": "051",
      "countyName": "Grant"
    },
    {
      "countyFIP": "053",
      "countyName": "Hennepin"
    },
    {
      "countyFIP": "055",
      "countyName": "Houston"
    },
    {
      "countyFIP": "057",
      "countyName": "Hubbard"
    },
    {
      "countyFIP": "059",
      "countyName": "Isanti"
    },
    {
      "countyFIP": "061",
      "countyName": "Itasca"
    },
    {
      "countyFIP": "063",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "065",
      "countyName": "Kanabec"
    },
    {
      "countyFIP": "067",
      "countyName": "Kandiyohi"
    },
    {
      "countyFIP": "069",
      "countyName": "Kittson"
    },
    {
      "countyFIP": "071",
      "countyName": "Koochiching"
    },
    {
      "countyFIP": "073",
      "countyName": "Lac qui Parle"
    },
    {
      "countyFIP": "075",
      "countyName": "Lake"
    },
    {
      "countyFIP": "077",
      "countyName": "Lake of the Woods"
    },
    {
      "countyFIP": "079",
      "countyName": "Le Sueur"
    },
    {
      "countyFIP": "081",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "083",
      "countyName": "Lyon"
    },
    {
      "countyFIP": "085",
      "countyName": "McLeod"
    },
    {
      "countyFIP": "087",
      "countyName": "Mahnomen"
    },
    {
      "countyFIP": "089",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "091",
      "countyName": "Martin"
    },
    {
      "countyFIP": "093",
      "countyName": "Meeker"
    },
    {
      "countyFIP": "095",
      "countyName": "Mille Lacs"
    },
    {
      "countyFIP": "097",
      "countyName": "Morrison"
    },
    {
      "countyFIP": "099",
      "countyName": "Mower"
    },
    {
      "countyFIP": "101",
      "countyName": "Murray"
    },
    {
      "countyFIP": "103",
      "countyName": "Nicollet"
    },
    {
      "countyFIP": "105",
      "countyName": "Nobles"
    },
    {
      "countyFIP": "107",
      "countyName": "Norman"
    },
    {
      "countyFIP": "109",
      "countyName": "Olmsted"
    },
    {
      "countyFIP": "111",
      "countyName": "Otter Tail"
    },
    {
      "countyFIP": "113",
      "countyName": "Pennington"
    },
    {
      "countyFIP": "115",
      "countyName": "Pine"
    },
    {
      "countyFIP": "117",
      "countyName": "Pipestone"
    },
    {
      "countyFIP": "119",
      "countyName": "Polk"
    },
    {
      "countyFIP": "121",
      "countyName": "Pope"
    },
    {
      "countyFIP": "123",
      "countyName": "Ramsey"
    },
    {
      "countyFIP": "125",
      "countyName": "Red Lake"
    },
    {
      "countyFIP": "127",
      "countyName": "Redwood"
    },
    {
      "countyFIP": "129",
      "countyName": "Renville"
    },
    {
      "countyFIP": "131",
      "countyName": "Rice"
    },
    {
      "countyFIP": "133",
      "countyName": "Rock"
    },
    {
      "countyFIP": "135",
      "countyName": "Roseau"
    },
    {
      "countyFIP": "137",
      "countyName": "St Louis"
    },
    {
      "countyFIP": "139",
      "countyName": "Scott"
    },
    {
      "countyFIP": "141",
      "countyName": "Sherburne"
    },
    {
      "countyFIP": "143",
      "countyName": "Sibley"
    },
    {
      "countyFIP": "145",
      "countyName": "Stearns"
    },
    {
      "countyFIP": "147",
      "countyName": "Steele"
    },
    {
      "countyFIP": "149",
      "countyName": "Stevens"
    },
    {
      "countyFIP": "151",
      "countyName": "Swift"
    },
    {
      "countyFIP": "153",
      "countyName": "Todd"
    },
    {
      "countyFIP": "155",
      "countyName": "Traverse"
    },
    {
      "countyFIP": "157",
      "countyName": "Wabasha"
    },
    {
      "countyFIP": "159",
      "countyName": "Wadena"
    },
    {
      "countyFIP": "161",
      "countyName": "Waseca"
    },
    {
      "countyFIP": "163",
      "countyName": "Washington"
    },
    {
      "countyFIP": "165",
      "countyName": "Watonwan"
    },
    {
      "countyFIP": "167",
      "countyName": "Wilkin"
    },
    {
      "countyFIP": "169",
      "countyName": "Winona"
    },
    {
      "countyFIP": "171",
      "countyName": "Wright"
    },
    {
      "countyFIP": "173",
      "countyName": "Yellow Medicine"
    }
  ],
  "28": [
    {
      "countyFIP": "001",
      "countyName": "Adams"
    },
    {
      "countyFIP": "003",
      "countyName": "Alcorn"
    },
    {
      "countyFIP": "005",
      "countyName": "Amite"
    },
    {
      "countyFIP": "007",
      "countyName": "Attala"
    },
    {
      "countyFIP": "009",
      "countyName": "Benton"
    },
    {
      "countyFIP": "011",
      "countyName": "Bolivar"
    },
    {
      "countyFIP": "013",
      "countyName": "Calhoun"
    },
    {
      "countyFIP": "015",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "017",
      "countyName": "Chickasaw"
    },
    {
      "countyFIP": "019",
      "countyName": "Choctaw"
    },
    {
      "countyFIP": "021",
      "countyName": "Claiborne"
    },
    {
      "countyFIP": "023",
      "countyName": "Clarke"
    },
    {
      "countyFIP": "025",
      "countyName": "Clay"
    },
    {
      "countyFIP": "027",
      "countyName": "Coahoma"
    },
    {
      "countyFIP": "029",
      "countyName": "Copiah"
    },
    {
      "countyFIP": "031",
      "countyName": "Covington"
    },
    {
      "countyFIP": "033",
      "countyName": "DeSoto"
    },
    {
      "countyFIP": "035",
      "countyName": "Forrest"
    },
    {
      "countyFIP": "037",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "039",
      "countyName": "George"
    },
    {
      "countyFIP": "041",
      "countyName": "Greene"
    },
    {
      "countyFIP": "043",
      "countyName": "Grenada"
    },
    {
      "countyFIP": "045",
      "countyName": "Hancock"
    },
    {
      "countyFIP": "047",
      "countyName": "Harrison"
    },
    {
      "countyFIP": "049",
      "countyName": "Hinds"
    },
    {
      "countyFIP": "051",
      "countyName": "Holmes"
    },
    {
      "countyFIP": "053",
      "countyName": "Humphreys"
    },
    {
      "countyFIP": "055",
      "countyName": "Issaquena"
    },
    {
      "countyFIP": "057",
      "countyName": "Itawamba"
    },
    {
      "countyFIP": "059",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "061",
      "countyName": "Jasper"
    },
    {
      "countyFIP": "063",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "065",
      "countyName": "Jefferson Davis"
    },
    {
      "countyFIP": "067",
      "countyName": "Jones"
    },
    {
      "countyFIP": "069",
      "countyName": "Kemper"
    },
    {
      "countyFIP": "071",
      "countyName": "Lafayette"
    },
    {
      "countyFIP": "073",
      "countyName": "Lamar"
    },
    {
      "countyFIP": "075",
      "countyName": "Lauderdale"
    },
    {
      "countyFIP": "077",
      "countyName": "Lawrence"
    },
    {
      "countyFIP": "079",
      "countyName": "Leake"
    },
    {
      "countyFIP": "081",
      "countyName": "Lee"
    },
    {
      "countyFIP": "083",
      "countyName": "Leflore"
    },
    {
      "countyFIP": "085",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "087",
      "countyName": "Lowndes"
    },
    {
      "countyFIP": "089",
      "countyName": "Madison"
    },
    {
      "countyFIP": "091",
      "countyName": "Marion"
    },
    {
      "countyFIP": "093",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "095",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "097",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "099",
      "countyName": "Neshoba"
    },
    {
      "countyFIP": "101",
      "countyName": "Newton"
    },
    {
      "countyFIP": "103",
      "countyName": "Noxubee"
    },
    {
      "countyFIP": "105",
      "countyName": "Oktibbeha"
    },
    {
      "countyFIP": "107",
      "countyName": "Panola"
    },
    {
      "countyFIP": "109",
      "countyName": "Pearl River"
    },
    {
      "countyFIP": "111",
      "countyName": "Perry"
    },
    {
      "countyFIP": "113",
      "countyName": "Pike"
    },
    {
      "countyFIP": "115",
      "countyName": "Pontotoc"
    },
    {
      "countyFIP": "117",
      "countyName": "Prentiss"
    },
    {
      "countyFIP": "119",
      "countyName": "Quitman"
    },
    {
      "countyFIP": "121",
      "countyName": "Rankin"
    },
    {
      "countyFIP": "123",
      "countyName": "Scott"
    },
    {
      "countyFIP": "125",
      "countyName": "Sharkey"
    },
    {
      "countyFIP": "127",
      "countyName": "Simpson"
    },
    {
      "countyFIP": "129",
      "countyName": "Smith"
    },
    {
      "countyFIP": "131",
      "countyName": "Stone"
    },
    {
      "countyFIP": "133",
      "countyName": "Sunflower"
    },
    {
      "countyFIP": "135",
      "countyName": "Tallahatchie"
    },
    {
      "countyFIP": "137",
      "countyName": "Tate"
    },
    {
      "countyFIP": "139",
      "countyName": "Tippah"
    },
    {
      "countyFIP": "141",
      "countyName": "Tishomingo"
    },
    {
      "countyFIP": "143",
      "countyName": "Tunica"
    },
    {
      "countyFIP": "145",
      "countyName": "Union"
    },
    {
      "countyFIP": "147",
      "countyName": "Walthall"
    },
    {
      "countyFIP": "149",
      "countyName": "Warren"
    },
    {
      "countyFIP": "151",
      "countyName": "Washington"
    },
    {
      "countyFIP": "153",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "155",
      "countyName": "Webster"
    },
    {
      "countyFIP": "157",
      "countyName": "Wilkinson"
    },
    {
      "countyFIP": "159",
      "countyName": "Winston"
    },
    {
      "countyFIP": "161",
      "countyName": "Yalobusha"
    },
    {
      "countyFIP": "163",
      "countyName": "Yazoo"
    }
  ],
  "29": [
    {
      "countyFIP": "001",
      "countyName": "Adair"
    },
    {
      "countyFIP": "003",
      "countyName": "Andrew"
    },
    {
      "countyFIP": "005",
      "countyName": "Atchison"
    },
    {
      "countyFIP": "007",
      "countyName": "Audrain"
    },
    {
      "countyFIP": "009",
      "countyName": "Barry"
    },
    {
      "countyFIP": "011",
      "countyName": "Barton"
    },
    {
      "countyFIP": "013",
      "countyName": "Bates"
    },
    {
      "countyFIP": "015",
      "countyName": "Benton"
    },
    {
      "countyFIP": "017",
      "countyName": "Bollinger"
    },
    {
      "countyFIP": "019",
      "countyName": "Boone"
    },
    {
      "countyFIP": "021",
      "countyName": "Buchanan"
    },
    {
      "countyFIP": "023",
      "countyName": "Butler"
    },
    {
      "countyFIP": "025",
      "countyName": "Caldwell"
    },
    {
      "countyFIP": "027",
      "countyName": "Callaway"
    },
    {
      "countyFIP": "029",
      "countyName": "Camden"
    },
    {
      "countyFIP": "031",
      "countyName": "Cape Girardeau"
    },
    {
      "countyFIP": "033",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "035",
      "countyName": "Carter"
    },
    {
      "countyFIP": "037",
      "countyName": "Cass"
    },
    {
      "countyFIP": "039",
      "countyName": "Cedar"
    },
    {
      "countyFIP": "041",
      "countyName": "Chariton"
    },
    {
      "countyFIP": "043",
      "countyName": "Christian"
    },
    {
      "countyFIP": "045",
      "countyName": "Clark"
    },
    {
      "countyFIP": "047",
      "countyName": "Clay"
    },
    {
      "countyFIP": "049",
      "countyName": "Clinton"
    },
    {
      "countyFIP": "051",
      "countyName": "Cole"
    },
    {
      "countyFIP": "053",
      "countyName": "Cooper"
    },
    {
      "countyFIP": "055",
      "countyName": "Crawford"
    },
    {
      "countyFIP": "057",
      "countyName": "Dade"
    },
    {
      "countyFIP": "059",
      "countyName": "Dallas"
    },
    {
      "countyFIP": "061",
      "countyName": "Daviess"
    },
    {
      "countyFIP": "063",
      "countyName": "DeKalb"
    },
    {
      "countyFIP": "065",
      "countyName": "Dent"
    },
    {
      "countyFIP": "067",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "069",
      "countyName": "Dunklin"
    },
    {
      "countyFIP": "071",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "073",
      "countyName": "Gasconade"
    },
    {
      "countyFIP": "075",
      "countyName": "Gentry"
    },
    {
      "countyFIP": "077",
      "countyName": "Greene"
    },
    {
      "countyFIP": "079",
      "countyName": "Grundy"
    },
    {
      "countyFIP": "081",
      "countyName": "Harrison"
    },
    {
      "countyFIP": "083",
      "countyName": "Henry"
    },
    {
      "countyFIP": "085",
      "countyName": "Hickory"
    },
    {
      "countyFIP": "087",
      "countyName": "Holt"
    },
    {
      "countyFIP": "089",
      "countyName": "Howard"
    },
    {
      "countyFIP": "091",
      "countyName": "Howell"
    },
    {
      "countyFIP": "093",
      "countyName": "Iron"
    },
    {
      "countyFIP": "095",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "097",
      "countyName": "Jasper"
    },
    {
      "countyFIP": "099",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "101",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "103",
      "countyName": "Knox"
    },
    {
      "countyFIP": "105",
      "countyName": "Laclede"
    },
    {
      "countyFIP": "107",
      "countyName": "Lafayette"
    },
    {
      "countyFIP": "109",
      "countyName": "Lawrence"
    },
    {
      "countyFIP": "111",
      "countyName": "Lewis"
    },
    {
      "countyFIP": "113",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "115",
      "countyName": "Linn"
    },
    {
      "countyFIP": "117",
      "countyName": "Livingston"
    },
    {
      "countyFIP": "119",
      "countyName": "McDonald"
    },
    {
      "countyFIP": "121",
      "countyName": "Macon"
    },
    {
      "countyFIP": "123",
      "countyName": "Madison"
    },
    {
      "countyFIP": "125",
      "countyName": "Maries"
    },
    {
      "countyFIP": "127",
      "countyName": "Marion"
    },
    {
      "countyFIP": "129",
      "countyName": "Mercer"
    },
    {
      "countyFIP": "131",
      "countyName": "Miller"
    },
    {
      "countyFIP": "133",
      "countyName": "Mississippi"
    },
    {
      "countyFIP": "135",
      "countyName": "Moniteau"
    },
    {
      "countyFIP": "137",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "139",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "141",
      "countyName": "Morgan"
    },
    {
      "countyFIP": "143",
      "countyName": "New Madrid"
    },
    {
      "countyFIP": "145",
      "countyName": "Newton"
    },
    {
      "countyFIP": "147",
      "countyName": "Nodaway"
    },
    {
      "countyFIP": "149",
      "countyName": "Oregon"
    },
    {
      "countyFIP": "151",
      "countyName": "Osage"
    },
    {
      "countyFIP": "153",
      "countyName": "Ozark"
    },
    {
      "countyFIP": "155",
      "countyName": "Pemiscot"
    },
    {
      "countyFIP": "157",
      "countyName": "Perry"
    },
    {
      "countyFIP": "159",
      "countyName": "Pettis"
    },
    {
      "countyFIP": "161",
      "countyName": "Phelps"
    },
    {
      "countyFIP": "163",
      "countyName": "Pike"
    },
    {
      "countyFIP": "165",
      "countyName": "Platte"
    },
    {
      "countyFIP": "167",
      "countyName": "Polk"
    },
    {
      "countyFIP": "169",
      "countyName": "Pulaski"
    },
    {
      "countyFIP": "171",
      "countyName": "Putnam"
    },
    {
      "countyFIP": "173",
      "countyName": "Ralls"
    },
    {
      "countyFIP": "175",
      "countyName": "Randolph"
    },
    {
      "countyFIP": "177",
      "countyName": "Ray"
    },
    {
      "countyFIP": "179",
      "countyName": "Reynolds"
    },
    {
      "countyFIP": "181",
      "countyName": "Ripley"
    },
    {
      "countyFIP": "183",
      "countyName": "St Charles"
    },
    {
      "countyFIP": "185",
      "countyName": "St Clair"
    },
    {
      "countyFIP": "186",
      "countyName": "Ste Genevieve"
    },
    {
      "countyFIP": "187",
      "countyName": "St Francois"
    },
    {
      "countyFIP": "189",
      "countyName": "St Louis"
    },
    {
      "countyFIP": "195",
      "countyName": "Saline"
    },
    {
      "countyFIP": "197",
      "countyName": "Schuyler"
    },
    {
      "countyFIP": "199",
      "countyName": "Scotland"
    },
    {
      "countyFIP": "201",
      "countyName": "Scott"
    },
    {
      "countyFIP": "203",
      "countyName": "Shannon"
    },
    {
      "countyFIP": "205",
      "countyName": "Shelby"
    },
    {
      "countyFIP": "207",
      "countyName": "Stoddard"
    },
    {
      "countyFIP": "209",
      "countyName": "Stone"
    },
    {
      "countyFIP": "211",
      "countyName": "Sullivan"
    },
    {
      "countyFIP": "213",
      "countyName": "Taney"
    },
    {
      "countyFIP": "215",
      "countyName": "Texas"
    },
    {
      "countyFIP": "217",
      "countyName": "Vernon"
    },
    {
      "countyFIP": "219",
      "countyName": "Warren"
    },
    {
      "countyFIP": "221",
      "countyName": "Washington"
    },
    {
      "countyFIP": "223",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "225",
      "countyName": "Webster"
    },
    {
      "countyFIP": "227",
      "countyName": "Worth"
    },
    {
      "countyFIP": "229",
      "countyName": "Wright"
    },
    {
      "countyFIP": "510",
      "countyName": "St Louis City"
    }
  ],
  "30": [
    {
      "countyFIP": "001",
      "countyName": "Beaverhead"
    },
    {
      "countyFIP": "003",
      "countyName": "Big Horn"
    },
    {
      "countyFIP": "005",
      "countyName": "Blaine"
    },
    {
      "countyFIP": "007",
      "countyName": "Broadwater"
    },
    {
      "countyFIP": "009",
      "countyName": "Carbon"
    },
    {
      "countyFIP": "011",
      "countyName": "Carter"
    },
    {
      "countyFIP": "013",
      "countyName": "Cascade"
    },
    {
      "countyFIP": "015",
      "countyName": "Chouteau"
    },
    {
      "countyFIP": "017",
      "countyName": "Custer"
    },
    {
      "countyFIP": "019",
      "countyName": "Daniels"
    },
    {
      "countyFIP": "021",
      "countyName": "Dawson"
    },
    {
      "countyFIP": "023",
      "countyName": "Deer Lodge"
    },
    {
      "countyFIP": "025",
      "countyName": "Fallon"
    },
    {
      "countyFIP": "027",
      "countyName": "Fergus"
    },
    {
      "countyFIP": "029",
      "countyName": "Flathead"
    },
    {
      "countyFIP": "031",
      "countyName": "Gallatin"
    },
    {
      "countyFIP": "033",
      "countyName": "Garfield"
    },
    {
      "countyFIP": "035",
      "countyName": "Glacier"
    },
    {
      "countyFIP": "037",
      "countyName": "Golden Valley"
    },
    {
      "countyFIP": "039",
      "countyName": "Granite"
    },
    {
      "countyFIP": "041",
      "countyName": "Hill"
    },
    {
      "countyFIP": "043",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "045",
      "countyName": "Judith Basin"
    },
    {
      "countyFIP": "047",
      "countyName": "Lake"
    },
    {
      "countyFIP": "049",
      "countyName": "Lewis and Clark"
    },
    {
      "countyFIP": "051",
      "countyName": "Liberty"
    },
    {
      "countyFIP": "053",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "055",
      "countyName": "McCone"
    },
    {
      "countyFIP": "057",
      "countyName": "Madison"
    },
    {
      "countyFIP": "059",
      "countyName": "Meagher"
    },
    {
      "countyFIP": "061",
      "countyName": "Mineral"
    },
    {
      "countyFIP": "063",
      "countyName": "Missoula"
    },
    {
      "countyFIP": "065",
      "countyName": "Musselshell"
    },
    {
      "countyFIP": "067",
      "countyName": "Park"
    },
    {
      "countyFIP": "069",
      "countyName": "Petroleum"
    },
    {
      "countyFIP": "071",
      "countyName": "Phillips"
    },
    {
      "countyFIP": "073",
      "countyName": "Pondera"
    },
    {
      "countyFIP": "075",
      "countyName": "Powder River"
    },
    {
      "countyFIP": "077",
      "countyName": "Powell"
    },
    {
      "countyFIP": "079",
      "countyName": "Prairie"
    },
    {
      "countyFIP": "081",
      "countyName": "Ravalli"
    },
    {
      "countyFIP": "083",
      "countyName": "Richland"
    },
    {
      "countyFIP": "085",
      "countyName": "Roosevelt"
    },
    {
      "countyFIP": "087",
      "countyName": "Rosebud"
    },
    {
      "countyFIP": "089",
      "countyName": "Sanders"
    },
    {
      "countyFIP": "091",
      "countyName": "Sheridan"
    },
    {
      "countyFIP": "093",
      "countyName": "Silver Bow"
    },
    {
      "countyFIP": "095",
      "countyName": "Stillwater"
    },
    {
      "countyFIP": "097",
      "countyName": "Sweet Grass"
    },
    {
      "countyFIP": "099",
      "countyName": "Teton"
    },
    {
      "countyFIP": "101",
      "countyName": "Toole"
    },
    {
      "countyFIP": "103",
      "countyName": "Treasure"
    },
    {
      "countyFIP": "105",
      "countyName": "Valley"
    },
    {
      "countyFIP": "107",
      "countyName": "Wheatland"
    },
    {
      "countyFIP": "109",
      "countyName": "Wibaux"
    },
    {
      "countyFIP": "111",
      "countyName": "Yellowstone"
    }
  ],
  "31": [
    {
      "countyFIP": "001",
      "countyName": "Adams"
    },
    {
      "countyFIP": "003",
      "countyName": "Antelope"
    },
    {
      "countyFIP": "005",
      "countyName": "Arthur"
    },
    {
      "countyFIP": "007",
      "countyName": "Banner"
    },
    {
      "countyFIP": "009",
      "countyName": "Blaine"
    },
    {
      "countyFIP": "011",
      "countyName": "Boone"
    },
    {
      "countyFIP": "013",
      "countyName": "Box Butte"
    },
    {
      "countyFIP": "015",
      "countyName": "Boyd"
    },
    {
      "countyFIP": "017",
      "countyName": "Brown"
    },
    {
      "countyFIP": "019",
      "countyName": "Buffalo"
    },
    {
      "countyFIP": "021",
      "countyName": "Burt"
    },
    {
      "countyFIP": "023",
      "countyName": "Butler"
    },
    {
      "countyFIP": "025",
      "countyName": "Cass"
    },
    {
      "countyFIP": "027",
      "countyName": "Cedar"
    },
    {
      "countyFIP": "029",
      "countyName": "Chase"
    },
    {
      "countyFIP": "031",
      "countyName": "Cherry"
    },
    {
      "countyFIP": "033",
      "countyName": "Cheyenne"
    },
    {
      "countyFIP": "035",
      "countyName": "Clay"
    },
    {
      "countyFIP": "037",
      "countyName": "Colfax"
    },
    {
      "countyFIP": "039",
      "countyName": "Cuming"
    },
    {
      "countyFIP": "041",
      "countyName": "Custer"
    },
    {
      "countyFIP": "043",
      "countyName": "Dakota"
    },
    {
      "countyFIP": "045",
      "countyName": "Dawes"
    },
    {
      "countyFIP": "047",
      "countyName": "Dawson"
    },
    {
      "countyFIP": "049",
      "countyName": "Deuel"
    },
    {
      "countyFIP": "051",
      "countyName": "Dixon"
    },
    {
      "countyFIP": "053",
      "countyName": "Dodge"
    },
    {
      "countyFIP": "055",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "057",
      "countyName": "Dundy"
    },
    {
      "countyFIP": "059",
      "countyName": "Fillmore"
    },
    {
      "countyFIP": "061",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "063",
      "countyName": "Frontier"
    },
    {
      "countyFIP": "065",
      "countyName": "Furnas"
    },
    {
      "countyFIP": "067",
      "countyName": "Gage"
    },
    {
      "countyFIP": "069",
      "countyName": "Garden"
    },
    {
      "countyFIP": "071",
      "countyName": "Garfield"
    },
    {
      "countyFIP": "073",
      "countyName": "Gosper"
    },
    {
      "countyFIP": "075",
      "countyName": "Grant"
    },
    {
      "countyFIP": "077",
      "countyName": "Greeley"
    },
    {
      "countyFIP": "079",
      "countyName": "Hall"
    },
    {
      "countyFIP": "081",
      "countyName": "Hamilton"
    },
    {
      "countyFIP": "083",
      "countyName": "Harlan"
    },
    {
      "countyFIP": "085",
      "countyName": "Hayes"
    },
    {
      "countyFIP": "087",
      "countyName": "Hitchcock"
    },
    {
      "countyFIP": "089",
      "countyName": "Holt"
    },
    {
      "countyFIP": "091",
      "countyName": "Hooker"
    },
    {
      "countyFIP": "093",
      "countyName": "Howard"
    },
    {
      "countyFIP": "095",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "097",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "099",
      "countyName": "Kearney"
    },
    {
      "countyFIP": "101",
      "countyName": "Keith"
    },
    {
      "countyFIP": "103",
      "countyName": "Keya Paha"
    },
    {
      "countyFIP": "105",
      "countyName": "Kimball"
    },
    {
      "countyFIP": "107",
      "countyName": "Knox"
    },
    {
      "countyFIP": "109",
      "countyName": "Lancaster"
    },
    {
      "countyFIP": "111",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "113",
      "countyName": "Logan"
    },
    {
      "countyFIP": "115",
      "countyName": "Loup"
    },
    {
      "countyFIP": "117",
      "countyName": "McPherson"
    },
    {
      "countyFIP": "119",
      "countyName": "Madison"
    },
    {
      "countyFIP": "121",
      "countyName": "Merrick"
    },
    {
      "countyFIP": "123",
      "countyName": "Morrill"
    },
    {
      "countyFIP": "125",
      "countyName": "Nance"
    },
    {
      "countyFIP": "127",
      "countyName": "Nemaha"
    },
    {
      "countyFIP": "129",
      "countyName": "Nuckolls"
    },
    {
      "countyFIP": "131",
      "countyName": "Otoe"
    },
    {
      "countyFIP": "133",
      "countyName": "Pawnee"
    },
    {
      "countyFIP": "135",
      "countyName": "Perkins"
    },
    {
      "countyFIP": "137",
      "countyName": "Phelps"
    },
    {
      "countyFIP": "139",
      "countyName": "Pierce"
    },
    {
      "countyFIP": "141",
      "countyName": "Platte"
    },
    {
      "countyFIP": "143",
      "countyName": "Polk"
    },
    {
      "countyFIP": "145",
      "countyName": "Red Willow"
    },
    {
      "countyFIP": "147",
      "countyName": "Richardson"
    },
    {
      "countyFIP": "149",
      "countyName": "Rock"
    },
    {
      "countyFIP": "151",
      "countyName": "Saline"
    },
    {
      "countyFIP": "153",
      "countyName": "Sarpy"
    },
    {
      "countyFIP": "155",
      "countyName": "Saunders"
    },
    {
      "countyFIP": "157",
      "countyName": "Scotts Bluff"
    },
    {
      "countyFIP": "159",
      "countyName": "Seward"
    },
    {
      "countyFIP": "161",
      "countyName": "Sheridan"
    },
    {
      "countyFIP": "163",
      "countyName": "Sherman"
    },
    {
      "countyFIP": "165",
      "countyName": "Sioux"
    },
    {
      "countyFIP": "167",
      "countyName": "Stanton"
    },
    {
      "countyFIP": "169",
      "countyName": "Thayer"
    },
    {
      "countyFIP": "171",
      "countyName": "Thomas"
    },
    {
      "countyFIP": "173",
      "countyName": "Thurston"
    },
    {
      "countyFIP": "175",
      "countyName": "Valley"
    },
    {
      "countyFIP": "177",
      "countyName": "Washington"
    },
    {
      "countyFIP": "179",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "181",
      "countyName": "Webster"
    },
    {
      "countyFIP": "183",
      "countyName": "Wheeler"
    },
    {
      "countyFIP": "185",
      "countyName": "York"
    }
  ],
  "32": [
    {
      "countyFIP": "001",
      "countyName": "Churchill"
    },
    {
      "countyFIP": "003",
      "countyName": "Clark"
    },
    {
      "countyFIP": "005",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "007",
      "countyName": "Elko"
    },
    {
      "countyFIP": "009",
      "countyName": "Esmeralda"
    },
    {
      "countyFIP": "011",
      "countyName": "Eureka"
    },
    {
      "countyFIP": "013",
      "countyName": "Humboldt"
    },
    {
      "countyFIP": "015",
      "countyName": "Lander"
    },
    {
      "countyFIP": "017",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "019",
      "countyName": "Lyon"
    },
    {
      "countyFIP": "021",
      "countyName": "Mineral"
    },
    {
      "countyFIP": "023",
      "countyName": "Nye"
    },
    {
      "countyFIP": "027",
      "countyName": "Pershing"
    },
    {
      "countyFIP": "029",
      "countyName": "Storey"
    },
    {
      "countyFIP": "031",
      "countyName": "Washoe"
    },
    {
      "countyFIP": "033",
      "countyName": "White Pine"
    },
    {
      "countyFIP": "510",
      "countyName": "Carson City"
    }
  ],
  "33": [
    {
      "countyFIP": "001",
      "countyName": "Belknap"
    },
    {
      "countyFIP": "003",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "005",
      "countyName": "Cheshire"
    },
    {
      "countyFIP": "007",
      "countyName": "Coos"
    },
    {
      "countyFIP": "009",
      "countyName": "Grafton"
    },
    {
      "countyFIP": "011",
      "countyName": "Hillsborough"
    },
    {
      "countyFIP": "013",
      "countyName": "Merrimack"
    },
    {
      "countyFIP": "015",
      "countyName": "Rockingham"
    },
    {
      "countyFIP": "017",
      "countyName": "Strafford"
    },
    {
      "countyFIP": "019",
      "countyName": "Sullivan"
    }
  ],
  "34": [
    {
      "countyFIP": "001",
      "countyName": "Atlantic"
    },
    {
      "countyFIP": "003",
      "countyName": "Bergen"
    },
    {
      "countyFIP": "005",
      "countyName": "Burlington"
    },
    {
      "countyFIP": "007",
      "countyName": "Camden"
    },
    {
      "countyFIP": "009",
      "countyName": "Cape May"
    },
    {
      "countyFIP": "011",
      "countyName": "Cumberland"
    },
    {
      "countyFIP": "013",
      "countyName": "Essex"
    },
    {
      "countyFIP": "015",
      "countyName": "Gloucester"
    },
    {
      "countyFIP": "017",
      "countyName": "Hudson"
    },
    {
      "countyFIP": "019",
      "countyName": "Hunterdon"
    },
    {
      "countyFIP": "021",
      "countyName": "Mercer"
    },
    {
      "countyFIP": "023",
      "countyName": "Middlesex"
    },
    {
      "countyFIP": "025",
      "countyName": "Monmouth"
    },
    {
      "countyFIP": "027",
      "countyName": "Morris"
    },
    {
      "countyFIP": "029",
      "countyName": "Ocean"
    },
    {
      "countyFIP": "031",
      "countyName": "Passaic"
    },
    {
      "countyFIP": "033",
      "countyName": "Salem"
    },
    {
      "countyFIP": "035",
      "countyName": "Somerset"
    },
    {
      "countyFIP": "037",
      "countyName": "Sussex"
    },
    {
      "countyFIP": "039",
      "countyName": "Union"
    },
    {
      "countyFIP": "041",
      "countyName": "Warren"
    }
  ],
  "35": [
    {
      "countyFIP": "001",
      "countyName": "Bernalillo"
    },
    {
      "countyFIP": "003",
      "countyName": "Catron"
    },
    {
      "countyFIP": "005",
      "countyName": "Chaves"
    },
    {
      "countyFIP": "006",
      "countyName": "Cibola"
    },
    {
      "countyFIP": "007",
      "countyName": "Colfax"
    },
    {
      "countyFIP": "009",
      "countyName": "Curry"
    },
    {
      "countyFIP": "011",
      "countyName": "De Baca"
    },
    {
      "countyFIP": "013",
      "countyName": "Doña Ana"
    },
    {
      "countyFIP": "015",
      "countyName": "Eddy"
    },
    {
      "countyFIP": "017",
      "countyName": "Grant"
    },
    {
      "countyFIP": "019",
      "countyName": "Guadalupe"
    },
    {
      "countyFIP": "021",
      "countyName": "Harding"
    },
    {
      "countyFIP": "023",
      "countyName": "Hidalgo"
    },
    {
      "countyFIP": "025",
      "countyName": "Lea"
    },
    {
      "countyFIP": "027",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "028",
      "countyName": "Los Alamos"
    },
    {
      "countyFIP": "029",
      "countyName": "Luna"
    },
    {
      "countyFIP": "031",
      "countyName": "McKinley"
    },
    {
      "countyFIP": "033",
      "countyName": "Mora"
    },
    {
      "countyFIP": "035",
      "countyName": "Otero"
    },
    {
      "countyFIP": "037",
      "countyName": "Quay"
    },
    {
      "countyFIP": "039",
      "countyName": "Rio Arriba"
    },
    {
      "countyFIP": "041",
      "countyName": "Roosevelt"
    },
    {
      "countyFIP": "043",
      "countyName": "Sandoval"
    },
    {
      "countyFIP": "045",
      "countyName": "San Juan"
    },
    {
      "countyFIP": "047",
      "countyName": "San Miguel"
    },
    {
      "countyFIP": "049",
      "countyName": "Santa Fe"
    },
    {
      "countyFIP": "051",
      "countyName": "Sierra"
    },
    {
      "countyFIP": "053",
      "countyName": "Socorro"
    },
    {
      "countyFIP": "055",
      "countyName": "Taos"
    },
    {
      "countyFIP": "057",
      "countyName": "Torrance"
    },
    {
      "countyFIP": "059",
      "countyName": "Union"
    },
    {
      "countyFIP": "061",
      "countyName": "Valencia"
    }
  ],
  "36": [
    {
      "countyFIP": "001",
      "countyName": "Albany"
    },
    {
      "countyFIP": "003",
      "countyName": "Allegany"
    },
    {
      "countyFIP": "005",
      "countyName": "Bronx"
    },
    {
      "countyFIP": "007",
      "countyName": "Broome"
    },
    {
      "countyFIP": "009",
      "countyName": "Cattaraugus"
    },
    {
      "countyFIP": "011",
      "countyName": "Cayuga"
    },
    {
      "countyFIP": "013",
      "countyName": "Chautauqua"
    },
    {
      "countyFIP": "015",
      "countyName": "Chemung"
    },
    {
      "countyFIP": "017",
      "countyName": "Chenango"
    },
    {
      "countyFIP": "019",
      "countyName": "Clinton"
    },
    {
      "countyFIP": "021",
      "countyName": "Columbia"
    },
    {
      "countyFIP": "023",
      "countyName": "Cortland"
    },
    {
      "countyFIP": "025",
      "countyName": "Delaware"
    },
    {
      "countyFIP": "027",
      "countyName": "Dutchess"
    },
    {
      "countyFIP": "029",
      "countyName": "Erie"
    },
    {
      "countyFIP": "031",
      "countyName": "Essex"
    },
    {
      "countyFIP": "033",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "035",
      "countyName": "Fulton"
    },
    {
      "countyFIP": "037",
      "countyName": "Genesee"
    },
    {
      "countyFIP": "039",
      "countyName": "Greene"
    },
    {
      "countyFIP": "041",
      "countyName": "Hamilton"
    },
    {
      "countyFIP": "043",
      "countyName": "Herkimer"
    },
    {
      "countyFIP": "045",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "047",
      "countyName": "Kings"
    },
    {
      "countyFIP": "049",
      "countyName": "Lewis"
    },
    {
      "countyFIP": "051",
      "countyName": "Livingston"
    },
    {
      "countyFIP": "053",
      "countyName": "Madison"
    },
    {
      "countyFIP": "055",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "057",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "059",
      "countyName": "Nassau"
    },
    {
      "countyFIP": "061",
      "countyName": "New York"
    },
    {
      "countyFIP": "063",
      "countyName": "Niagara"
    },
    {
      "countyFIP": "065",
      "countyName": "Oneida"
    },
    {
      "countyFIP": "067",
      "countyName": "Onondaga"
    },
    {
      "countyFIP": "069",
      "countyName": "Ontario"
    },
    {
      "countyFIP": "071",
      "countyName": "Orange"
    },
    {
      "countyFIP": "073",
      "countyName": "Orleans"
    },
    {
      "countyFIP": "075",
      "countyName": "Oswego"
    },
    {
      "countyFIP": "077",
      "countyName": "Otsego"
    },
    {
      "countyFIP": "079",
      "countyName": "Putnam"
    },
    {
      "countyFIP": "081",
      "countyName": "Queens"
    },
    {
      "countyFIP": "083",
      "countyName": "Rensselaer"
    },
    {
      "countyFIP": "085",
      "countyName": "Richmond"
    },
    {
      "countyFIP": "087",
      "countyName": "Rockland"
    },
    {
      "countyFIP": "089",
      "countyName": "St Lawrence"
    },
    {
      "countyFIP": "091",
      "countyName": "Saratoga"
    },
    {
      "countyFIP": "093",
      "countyName": "Schenectady"
    },
    {
      "countyFIP": "095",
      "countyName": "Schoharie"
    },
    {
      "countyFIP": "097",
      "countyName": "Schuyler"
    },
    {
      "countyFIP": "099",
      "countyName": "Seneca"
    },
    {
      "countyFIP": "101",
      "countyName": "Steuben"
    },
    {
      "countyFIP": "103",
      "countyName": "Suffolk"
    },
    {
      "countyFIP": "105",
      "countyName": "Sullivan"
    },
    {
      "countyFIP": "107",
      "countyName": "Tioga"
    },
    {
      "countyFIP": "109",
      "countyName": "Tompkins"
    },
    {
      "countyFIP": "111",
      "countyName": "Ulster"
    },
    {
      "countyFIP": "113",
      "countyName": "Warren"
    },
    {
      "countyFIP": "115",
      "countyName": "Washington"
    },
    {
      "countyFIP": "117",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "119",
      "countyName": "Westchester"
    },
    {
      "countyFIP": "121",
      "countyName": "Wyoming"
    },
    {
      "countyFIP": "123",
      "countyName": "Yates"
    }
  ],
  "37": [
    {
      "countyFIP": "001",
      "countyName": "Alamance"
    },
    {
      "countyFIP": "003",
      "countyName": "Alexander"
    },
    {
      "countyFIP": "005",
      "countyName": "Alleghany"
    },
    {
      "countyFIP": "007",
      "countyName": "Anson"
    },
    {
      "countyFIP": "009",
      "countyName": "Ashe"
    },
    {
      "countyFIP": "011",
      "countyName": "Avery"
    },
    {
      "countyFIP": "013",
      "countyName": "Beaufort"
    },
    {
      "countyFIP": "015",
      "countyName": "Bertie"
    },
    {
      "countyFIP": "017",
      "countyName": "Bladen"
    },
    {
      "countyFIP": "019",
      "countyName": "Brunswick"
    },
    {
      "countyFIP": "021",
      "countyName": "Buncombe"
    },
    {
      "countyFIP": "023",
      "countyName": "Burke"
    },
    {
      "countyFIP": "025",
      "countyName": "Cabarrus"
    },
    {
      "countyFIP": "027",
      "countyName": "Caldwell"
    },
    {
      "countyFIP": "029",
      "countyName": "Camden"
    },
    {
      "countyFIP": "031",
      "countyName": "Carteret"
    },
    {
      "countyFIP": "033",
      "countyName": "Caswell"
    },
    {
      "countyFIP": "035",
      "countyName": "Catawba"
    },
    {
      "countyFIP": "037",
      "countyName": "Chatham"
    },
    {
      "countyFIP": "039",
      "countyName": "Cherokee"
    },
    {
      "countyFIP": "041",
      "countyName": "Chowan"
    },
    {
      "countyFIP": "043",
      "countyName": "Clay"
    },
    {
      "countyFIP": "045",
      "countyName": "Cleveland"
    },
    {
      "countyFIP": "047",
      "countyName": "Columbus"
    },
    {
      "countyFIP": "049",
      "countyName": "Craven"
    },
    {
      "countyFIP": "051",
      "countyName": "Cumberland"
    },
    {
      "countyFIP": "053",
      "countyName": "Currituck"
    },
    {
      "countyFIP": "055",
      "countyName": "Dare"
    },
    {
      "countyFIP": "057",
      "countyName": "Davidson"
    },
    {
      "countyFIP": "059",
      "countyName": "Davie"
    },
    {
      "countyFIP": "061",
      "countyName": "Duplin"
    },
    {
      "countyFIP": "063",
      "countyName": "Durham"
    },
    {
      "countyFIP": "065",
      "countyName": "Edgecombe"
    },
    {
      "countyFIP": "067",
      "countyName": "Forsyth"
    },
    {
      "countyFIP": "069",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "071",
      "countyName": "Gaston"
    },
    {
      "countyFIP": "073",
      "countyName": "Gates"
    },
    {
      "countyFIP": "075",
      "countyName": "Graham"
    },
    {
      "countyFIP": "077",
      "countyName": "Granville"
    },
    {
      "countyFIP": "079",
      "countyName": "Greene"
    },
    {
      "countyFIP": "081",
      "countyName": "Guilford"
    },
    {
      "countyFIP": "083",
      "countyName": "Halifax"
    },
    {
      "countyFIP": "085",
      "countyName": "Harnett"
    },
    {
      "countyFIP": "087",
      "countyName": "Haywood"
    },
    {
      "countyFIP": "089",
      "countyName": "Henderson"
    },
    {
      "countyFIP": "091",
      "countyName": "Hertford"
    },
    {
      "countyFIP": "093",
      "countyName": "Hoke"
    },
    {
      "countyFIP": "095",
      "countyName": "Hyde"
    },
    {
      "countyFIP": "097",
      "countyName": "Iredell"
    },
    {
      "countyFIP": "099",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "101",
      "countyName": "Johnston"
    },
    {
      "countyFIP": "103",
      "countyName": "Jones"
    },
    {
      "countyFIP": "105",
      "countyName": "Lee"
    },
    {
      "countyFIP": "107",
      "countyName": "Lenoir"
    },
    {
      "countyFIP": "109",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "111",
      "countyName": "McDowell"
    },
    {
      "countyFIP": "113",
      "countyName": "Macon"
    },
    {
      "countyFIP": "115",
      "countyName": "Madison"
    },
    {
      "countyFIP": "117",
      "countyName": "Martin"
    },
    {
      "countyFIP": "119",
      "countyName": "Mecklenburg"
    },
    {
      "countyFIP": "121",
      "countyName": "Mitchell"
    },
    {
      "countyFIP": "123",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "125",
      "countyName": "Moore"
    },
    {
      "countyFIP": "127",
      "countyName": "Nash"
    },
    {
      "countyFIP": "129",
      "countyName": "New Hanover"
    },
    {
      "countyFIP": "131",
      "countyName": "Northampton"
    },
    {
      "countyFIP": "133",
      "countyName": "Onslow"
    },
    {
      "countyFIP": "135",
      "countyName": "Orange"
    },
    {
      "countyFIP": "137",
      "countyName": "Pamlico"
    },
    {
      "countyFIP": "139",
      "countyName": "Pasquotank"
    },
    {
      "countyFIP": "141",
      "countyName": "Pender"
    },
    {
      "countyFIP": "143",
      "countyName": "Perquimans"
    },
    {
      "countyFIP": "145",
      "countyName": "Person"
    },
    {
      "countyFIP": "147",
      "countyName": "Pitt"
    },
    {
      "countyFIP": "149",
      "countyName": "Polk"
    },
    {
      "countyFIP": "151",
      "countyName": "Randolph"
    },
    {
      "countyFIP": "153",
      "countyName": "Richmond"
    },
    {
      "countyFIP": "155",
      "countyName": "Robeson"
    },
    {
      "countyFIP": "157",
      "countyName": "Rockingham"
    },
    {
      "countyFIP": "159",
      "countyName": "Rowan"
    },
    {
      "countyFIP": "161",
      "countyName": "Rutherford"
    },
    {
      "countyFIP": "163",
      "countyName": "Sampson"
    },
    {
      "countyFIP": "165",
      "countyName": "Scotland"
    },
    {
      "countyFIP": "167",
      "countyName": "Stanly"
    },
    {
      "countyFIP": "169",
      "countyName": "Stokes"
    },
    {
      "countyFIP": "171",
      "countyName": "Surry"
    },
    {
      "countyFIP": "173",
      "countyName": "Swain"
    },
    {
      "countyFIP": "175",
      "countyName": "Transylvania"
    },
    {
      "countyFIP": "177",
      "countyName": "Tyrrell"
    },
    {
      "countyFIP": "179",
      "countyName": "Union"
    },
    {
      "countyFIP": "181",
      "countyName": "Vance"
    },
    {
      "countyFIP": "183",
      "countyName": "Wake"
    },
    {
      "countyFIP": "185",
      "countyName": "Warren"
    },
    {
      "countyFIP": "187",
      "countyName": "Washington"
    },
    {
      "countyFIP": "189",
      "countyName": "Watauga"
    },
    {
      "countyFIP": "191",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "193",
      "countyName": "Wilkes"
    },
    {
      "countyFIP": "195",
      "countyName": "Wilson"
    },
    {
      "countyFIP": "197",
      "countyName": "Yadkin"
    },
    {
      "countyFIP": "199",
      "countyName": "Yancey"
    }
  ],
  "38": [
    {
      "countyFIP": "001",
      "countyName": "Adams"
    },
    {
      "countyFIP": "003",
      "countyName": "Barnes"
    },
    {
      "countyFIP": "005",
      "countyName": "Benson"
    },
    {
      "countyFIP": "007",
      "countyName": "Billings"
    },
    {
      "countyFIP": "009",
      "countyName": "Bottineau"
    },
    {
      "countyFIP": "011",
      "countyName": "Bowman"
    },
    {
      "countyFIP": "013",
      "countyName": "Burke"
    },
    {
      "countyFIP": "015",
      "countyName": "Burleigh"
    },
    {
      "countyFIP": "017",
      "countyName": "Cass"
    },
    {
      "countyFIP": "019",
      "countyName": "Cavalier"
    },
    {
      "countyFIP": "021",
      "countyName": "Dickey"
    },
    {
      "countyFIP": "023",
      "countyName": "Divide"
    },
    {
      "countyFIP": "025",
      "countyName": "Dunn"
    },
    {
      "countyFIP": "027",
      "countyName": "Eddy"
    },
    {
      "countyFIP": "029",
      "countyName": "Emmons"
    },
    {
      "countyFIP": "031",
      "countyName": "Foster"
    },
    {
      "countyFIP": "033",
      "countyName": "Golden Valley"
    },
    {
      "countyFIP": "035",
      "countyName": "Grand Forks"
    },
    {
      "countyFIP": "037",
      "countyName": "Grant"
    },
    {
      "countyFIP": "039",
      "countyName": "Griggs"
    },
    {
      "countyFIP": "041",
      "countyName": "Hettinger"
    },
    {
      "countyFIP": "043",
      "countyName": "Kidder"
    },
    {
      "countyFIP": "045",
      "countyName": "LaMoure"
    },
    {
      "countyFIP": "047",
      "countyName": "Logan"
    },
    {
      "countyFIP": "049",
      "countyName": "McHenry"
    },
    {
      "countyFIP": "051",
      "countyName": "McIntosh"
    },
    {
      "countyFIP": "053",
      "countyName": "McKenzie"
    },
    {
      "countyFIP": "055",
      "countyName": "McLean"
    },
    {
      "countyFIP": "057",
      "countyName": "Mercer"
    },
    {
      "countyFIP": "059",
      "countyName": "Morton"
    },
    {
      "countyFIP": "061",
      "countyName": "Mountrail"
    },
    {
      "countyFIP": "063",
      "countyName": "Nelson"
    },
    {
      "countyFIP": "065",
      "countyName": "Oliver"
    },
    {
      "countyFIP": "067",
      "countyName": "Pembina"
    },
    {
      "countyFIP": "069",
      "countyName": "Pierce"
    },
    {
      "countyFIP": "071",
      "countyName": "Ramsey"
    },
    {
      "countyFIP": "073",
      "countyName": "Ransom"
    },
    {
      "countyFIP": "075",
      "countyName": "Renville"
    },
    {
      "countyFIP": "077",
      "countyName": "Richland"
    },
    {
      "countyFIP": "079",
      "countyName": "Rolette"
    },
    {
      "countyFIP": "081",
      "countyName": "Sargent"
    },
    {
      "countyFIP": "083",
      "countyName": "Sheridan"
    },
    {
      "countyFIP": "085",
      "countyName": "Sioux"
    },
    {
      "countyFIP": "087",
      "countyName": "Slope"
    },
    {
      "countyFIP": "089",
      "countyName": "Stark"
    },
    {
      "countyFIP": "091",
      "countyName": "Steele"
    },
    {
      "countyFIP": "093",
      "countyName": "Stutsman"
    },
    {
      "countyFIP": "095",
      "countyName": "Towner"
    },
    {
      "countyFIP": "097",
      "countyName": "Traill"
    },
    {
      "countyFIP": "099",
      "countyName": "Walsh"
    },
    {
      "countyFIP": "101",
      "countyName": "Ward"
    },
    {
      "countyFIP": "103",
      "countyName": "Wells"
    },
    {
      "countyFIP": "105",
      "countyName": "Williams"
    }
  ],
  "39": [
    {
      "countyFIP": "001",
      "countyName": "Adams"
    },
    {
      "countyFIP": "003",
      "countyName": "Allen"
    },
    {
      "countyFIP": "005",
      "countyName": "Ashland"
    },
    {
      "countyFIP": "007",
      "countyName": "Ashtabula"
    },
    {
      "countyFIP": "009",
      "countyName": "Athens"
    },
    {
      "countyFIP": "011",
      "countyName": "Auglaize"
    },
    {
      "countyFIP": "013",
      "countyName": "Belmont"
    },
    {
      "countyFIP": "015",
      "countyName": "Brown"
    },
    {
      "countyFIP": "017",
      "countyName": "Butler"
    },
    {
      "countyFIP": "019",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "021",
      "countyName": "Champaign"
    },
    {
      "countyFIP": "023",
      "countyName": "Clark"
    },
    {
      "countyFIP": "025",
      "countyName": "Clermont"
    },
    {
      "countyFIP": "027",
      "countyName": "Clinton"
    },
    {
      "countyFIP": "029",
      "countyName": "Columbiana"
    },
    {
      "countyFIP": "031",
      "countyName": "Coshocton"
    },
    {
      "countyFIP": "033",
      "countyName": "Crawford"
    },
    {
      "countyFIP": "035",
      "countyName": "Cuyahoga"
    },
    {
      "countyFIP": "037",
      "countyName": "Darke"
    },
    {
      "countyFIP": "039",
      "countyName": "Defiance"
    },
    {
      "countyFIP": "041",
      "countyName": "Delaware"
    },
    {
      "countyFIP": "043",
      "countyName": "Erie"
    },
    {
      "countyFIP": "045",
      "countyName": "Fairfield"
    },
    {
      "countyFIP": "047",
      "countyName": "Fayette"
    },
    {
      "countyFIP": "049",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "051",
      "countyName": "Fulton"
    },
    {
      "countyFIP": "053",
      "countyName": "Gallia"
    },
    {
      "countyFIP": "055",
      "countyName": "Geauga"
    },
    {
      "countyFIP": "057",
      "countyName": "Greene"
    },
    {
      "countyFIP": "059",
      "countyName": "Guernsey"
    },
    {
      "countyFIP": "061",
      "countyName": "Hamilton"
    },
    {
      "countyFIP": "063",
      "countyName": "Hancock"
    },
    {
      "countyFIP": "065",
      "countyName": "Hardin"
    },
    {
      "countyFIP": "067",
      "countyName": "Harrison"
    },
    {
      "countyFIP": "069",
      "countyName": "Henry"
    },
    {
      "countyFIP": "071",
      "countyName": "Highland"
    },
    {
      "countyFIP": "073",
      "countyName": "Hocking"
    },
    {
      "countyFIP": "075",
      "countyName": "Holmes"
    },
    {
      "countyFIP": "077",
      "countyName": "Huron"
    },
    {
      "countyFIP": "079",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "081",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "083",
      "countyName": "Knox"
    },
    {
      "countyFIP": "085",
      "countyName": "Lake"
    },
    {
      "countyFIP": "087",
      "countyName": "Lawrence"
    },
    {
      "countyFIP": "089",
      "countyName": "Licking"
    },
    {
      "countyFIP": "091",
      "countyName": "Logan"
    },
    {
      "countyFIP": "093",
      "countyName": "Lorain"
    },
    {
      "countyFIP": "095",
      "countyName": "Lucas"
    },
    {
      "countyFIP": "097",
      "countyName": "Madison"
    },
    {
      "countyFIP": "099",
      "countyName": "Mahoning"
    },
    {
      "countyFIP": "101",
      "countyName": "Marion"
    },
    {
      "countyFIP": "103",
      "countyName": "Medina"
    },
    {
      "countyFIP": "105",
      "countyName": "Meigs"
    },
    {
      "countyFIP": "107",
      "countyName": "Mercer"
    },
    {
      "countyFIP": "109",
      "countyName": "Miami"
    },
    {
      "countyFIP": "111",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "113",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "115",
      "countyName": "Morgan"
    },
    {
      "countyFIP": "117",
      "countyName": "Morrow"
    },
    {
      "countyFIP": "119",
      "countyName": "Muskingum"
    },
    {
      "countyFIP": "121",
      "countyName": "Noble"
    },
    {
      "countyFIP": "123",
      "countyName": "Ottawa"
    },
    {
      "countyFIP": "125",
      "countyName": "Paulding"
    },
    {
      "countyFIP": "127",
      "countyName": "Perry"
    },
    {
      "countyFIP": "129",
      "countyName": "Pickaway"
    },
    {
      "countyFIP": "131",
      "countyName": "Pike"
    },
    {
      "countyFIP": "133",
      "countyName": "Portage"
    },
    {
      "countyFIP": "135",
      "countyName": "Preble"
    },
    {
      "countyFIP": "137",
      "countyName": "Putnam"
    },
    {
      "countyFIP": "139",
      "countyName": "Richland"
    },
    {
      "countyFIP": "141",
      "countyName": "Ross"
    },
    {
      "countyFIP": "143",
      "countyName": "Sandusky"
    },
    {
      "countyFIP": "145",
      "countyName": "Scioto"
    },
    {
      "countyFIP": "147",
      "countyName": "Seneca"
    },
    {
      "countyFIP": "149",
      "countyName": "Shelby"
    },
    {
      "countyFIP": "151",
      "countyName": "Stark"
    },
    {
      "countyFIP": "153",
      "countyName": "Summit"
    },
    {
      "countyFIP": "155",
      "countyName": "Trumbull"
    },
    {
      "countyFIP": "157",
      "countyName": "Tuscarawas"
    },
    {
      "countyFIP": "159",
      "countyName": "Union"
    },
    {
      "countyFIP": "161",
      "countyName": "Van Wert"
    },
    {
      "countyFIP": "163",
      "countyName": "Vinton"
    },
    {
      "countyFIP": "165",
      "countyName": "Warren"
    },
    {
      "countyFIP": "167",
      "countyName": "Washington"
    },
    {
      "countyFIP": "169",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "171",
      "countyName": "Williams"
    },
    {
      "countyFIP": "173",
      "countyName": "Wood"
    },
    {
      "countyFIP": "175",
      "countyName": "Wyandot"
    }
  ],
  "40": [
    {
      "countyFIP": "001",
      "countyName": "Adair"
    },
    {
      "countyFIP": "003",
      "countyName": "Alfalfa"
    },
    {
      "countyFIP": "005",
      "countyName": "Atoka"
    },
    {
      "countyFIP": "007",
      "countyName": "Beaver"
    },
    {
      "countyFIP": "009",
      "countyName": "Beckham"
    },
    {
      "countyFIP": "011",
      "countyName": "Blaine"
    },
    {
      "countyFIP": "013",
      "countyName": "Bryan"
    },
    {
      "countyFIP": "015",
      "countyName": "Caddo"
    },
    {
      "countyFIP": "017",
      "countyName": "Canadian"
    },
    {
      "countyFIP": "019",
      "countyName": "Carter"
    },
    {
      "countyFIP": "021",
      "countyName": "Cherokee"
    },
    {
      "countyFIP": "023",
      "countyName": "Choctaw"
    },
    {
      "countyFIP": "025",
      "countyName": "Cimarron"
    },
    {
      "countyFIP": "027",
      "countyName": "Cleveland"
    },
    {
      "countyFIP": "029",
      "countyName": "Coal"
    },
    {
      "countyFIP": "031",
      "countyName": "Comanche"
    },
    {
      "countyFIP": "033",
      "countyName": "Cotton"
    },
    {
      "countyFIP": "035",
      "countyName": "Craig"
    },
    {
      "countyFIP": "037",
      "countyName": "Creek"
    },
    {
      "countyFIP": "039",
      "countyName": "Custer"
    },
    {
      "countyFIP": "041",
      "countyName": "Delaware"
    },
    {
      "countyFIP": "043",
      "countyName": "Dewey"
    },
    {
      "countyFIP": "045",
      "countyName": "Ellis"
    },
    {
      "countyFIP": "047",
      "countyName": "Garfield"
    },
    {
      "countyFIP": "049",
      "countyName": "Garvin"
    },
    {
      "countyFIP": "051",
      "countyName": "Grady"
    },
    {
      "countyFIP": "053",
      "countyName": "Grant"
    },
    {
      "countyFIP": "055",
      "countyName": "Greer"
    },
    {
      "countyFIP": "057",
      "countyName": "Harmon"
    },
    {
      "countyFIP": "059",
      "countyName": "Harper"
    },
    {
      "countyFIP": "061",
      "countyName": "Haskell"
    },
    {
      "countyFIP": "063",
      "countyName": "Hughes"
    },
    {
      "countyFIP": "065",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "067",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "069",
      "countyName": "Johnston"
    },
    {
      "countyFIP": "071",
      "countyName": "Kay"
    },
    {
      "countyFIP": "073",
      "countyName": "Kingfisher"
    },
    {
      "countyFIP": "075",
      "countyName": "Kiowa"
    },
    {
      "countyFIP": "077",
      "countyName": "Latimer"
    },
    {
      "countyFIP": "079",
      "countyName": "Le Flore"
    },
    {
      "countyFIP": "081",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "083",
      "countyName": "Logan"
    },
    {
      "countyFIP": "085",
      "countyName": "Love"
    },
    {
      "countyFIP": "087",
      "countyName": "McClain"
    },
    {
      "countyFIP": "089",
      "countyName": "McCurtain"
    },
    {
      "countyFIP": "091",
      "countyName": "McIntosh"
    },
    {
      "countyFIP": "093",
      "countyName": "Major"
    },
    {
      "countyFIP": "095",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "097",
      "countyName": "Mayes"
    },
    {
      "countyFIP": "099",
      "countyName": "Murray"
    },
    {
      "countyFIP": "101",
      "countyName": "Muskogee"
    },
    {
      "countyFIP": "103",
      "countyName": "Noble"
    },
    {
      "countyFIP": "105",
      "countyName": "Nowata"
    },
    {
      "countyFIP": "107",
      "countyName": "Okfuskee"
    },
    {
      "countyFIP": "109",
      "countyName": "Oklahoma"
    },
    {
      "countyFIP": "111",
      "countyName": "Okmulgee"
    },
    {
      "countyFIP": "113",
      "countyName": "Osage"
    },
    {
      "countyFIP": "115",
      "countyName": "Ottawa"
    },
    {
      "countyFIP": "117",
      "countyName": "Pawnee"
    },
    {
      "countyFIP": "119",
      "countyName": "Payne"
    },
    {
      "countyFIP": "121",
      "countyName": "Pittsburg"
    },
    {
      "countyFIP": "123",
      "countyName": "Pontotoc"
    },
    {
      "countyFIP": "125",
      "countyName": "Pottawatomie"
    },
    {
      "countyFIP": "127",
      "countyName": "Pushmataha"
    },
    {
      "countyFIP": "129",
      "countyName": "Roger Mills"
    },
    {
      "countyFIP": "131",
      "countyName": "Rogers"
    },
    {
      "countyFIP": "133",
      "countyName": "Seminole"
    },
    {
      "countyFIP": "135",
      "countyName": "Sequoyah"
    },
    {
      "countyFIP": "137",
      "countyName": "Stephens"
    },
    {
      "countyFIP": "139",
      "countyName": "Texas"
    },
    {
      "countyFIP": "141",
      "countyName": "Tillman"
    },
    {
      "countyFIP": "143",
      "countyName": "Tulsa"
    },
    {
      "countyFIP": "145",
      "countyName": "Wagoner"
    },
    {
      "countyFIP": "147",
      "countyName": "Washington"
    },
    {
      "countyFIP": "149",
      "countyName": "Washita"
    },
    {
      "countyFIP": "151",
      "countyName": "Woods"
    },
    {
      "countyFIP": "153",
      "countyName": "Woodward"
    }
  ],
  "41": [
    {
      "countyFIP": "001",
      "countyName": "Baker"
    },
    {
      "countyFIP": "003",
      "countyName": "Benton"
    },
    {
      "countyFIP": "005",
      "countyName": "Clackamas"
    },
    {
      "countyFIP": "007",
      "countyName": "Clatsop"
    },
    {
      "countyFIP": "009",
      "countyName": "Columbia"
    },
    {
      "countyFIP": "011",
      "countyName": "Coos"
    },
    {
      "countyFIP": "013",
      "countyName": "Crook"
    },
    {
      "countyFIP": "015",
      "countyName": "Curry"
    },
    {
      "countyFIP": "017",
      "countyName": "Deschutes"
    },
    {
      "countyFIP": "019",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "021",
      "countyName": "Gilliam"
    },
    {
      "countyFIP": "023",
      "countyName": "Grant"
    },
    {
      "countyFIP": "025",
      "countyName": "Harney"
    },
    {
      "countyFIP": "027",
      "countyName": "Hood River"
    },
    {
      "countyFIP": "029",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "031",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "033",
      "countyName": "Josephine"
    },
    {
      "countyFIP": "035",
      "countyName": "Klamath"
    },
    {
      "countyFIP": "037",
      "countyName": "Lake"
    },
    {
      "countyFIP": "039",
      "countyName": "Lane"
    },
    {
      "countyFIP": "041",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "043",
      "countyName": "Linn"
    },
    {
      "countyFIP": "045",
      "countyName": "Malheur"
    },
    {
      "countyFIP": "047",
      "countyName": "Marion"
    },
    {
      "countyFIP": "049",
      "countyName": "Morrow"
    },
    {
      "countyFIP": "051",
      "countyName": "Multnomah"
    },
    {
      "countyFIP": "053",
      "countyName": "Polk"
    },
    {
      "countyFIP": "055",
      "countyName": "Sherman"
    },
    {
      "countyFIP": "057",
      "countyName": "Tillamook"
    },
    {
      "countyFIP": "059",
      "countyName": "Umatilla"
    },
    {
      "countyFIP": "061",
      "countyName": "Union"
    },
    {
      "countyFIP": "063",
      "countyName": "Wallowa"
    },
    {
      "countyFIP": "065",
      "countyName": "Wasco"
    },
    {
      "countyFIP": "067",
      "countyName": "Washington"
    },
    {
      "countyFIP": "069",
      "countyName": "Wheeler"
    },
    {
      "countyFIP": "071",
      "countyName": "Yamhill"
    }
  ],
  "42": [
    {
      "countyFIP": "001",
      "countyName": "Adams"
    },
    {
      "countyFIP": "003",
      "countyName": "Allegheny"
    },
    {
      "countyFIP": "005",
      "countyName": "Armstrong"
    },
    {
      "countyFIP": "007",
      "countyName": "Beaver"
    },
    {
      "countyFIP": "009",
      "countyName": "Bedford"
    },
    {
      "countyFIP": "011",
      "countyName": "Berks"
    },
    {
      "countyFIP": "013",
      "countyName": "Blair"
    },
    {
      "countyFIP": "015",
      "countyName": "Bradford"
    },
    {
      "countyFIP": "017",
      "countyName": "Bucks"
    },
    {
      "countyFIP": "019",
      "countyName": "Butler"
    },
    {
      "countyFIP": "021",
      "countyName": "Cambria"
    },
    {
      "countyFIP": "023",
      "countyName": "Cameron"
    },
    {
      "countyFIP": "025",
      "countyName": "Carbon"
    },
    {
      "countyFIP": "027",
      "countyName": "Centre"
    },
    {
      "countyFIP": "029",
      "countyName": "Chester"
    },
    {
      "countyFIP": "031",
      "countyName": "Clarion"
    },
    {
      "countyFIP": "033",
      "countyName": "Clearfield"
    },
    {
      "countyFIP": "035",
      "countyName": "Clinton"
    },
    {
      "countyFIP": "037",
      "countyName": "Columbia"
    },
    {
      "countyFIP": "039",
      "countyName": "Crawford"
    },
    {
      "countyFIP": "041",
      "countyName": "Cumberland"
    },
    {
      "countyFIP": "043",
      "countyName": "Dauphin"
    },
    {
      "countyFIP": "045",
      "countyName": "Delaware"
    },
    {
      "countyFIP": "047",
      "countyName": "Elk"
    },
    {
      "countyFIP": "049",
      "countyName": "Erie"
    },
    {
      "countyFIP": "051",
      "countyName": "Fayette"
    },
    {
      "countyFIP": "053",
      "countyName": "Forest"
    },
    {
      "countyFIP": "055",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "057",
      "countyName": "Fulton"
    },
    {
      "countyFIP": "059",
      "countyName": "Greene"
    },
    {
      "countyFIP": "061",
      "countyName": "Huntingdon"
    },
    {
      "countyFIP": "063",
      "countyName": "Indiana"
    },
    {
      "countyFIP": "065",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "067",
      "countyName": "Juniata"
    },
    {
      "countyFIP": "069",
      "countyName": "Lackawanna"
    },
    {
      "countyFIP": "071",
      "countyName": "Lancaster"
    },
    {
      "countyFIP": "073",
      "countyName": "Lawrence"
    },
    {
      "countyFIP": "075",
      "countyName": "Lebanon"
    },
    {
      "countyFIP": "077",
      "countyName": "Lehigh"
    },
    {
      "countyFIP": "079",
      "countyName": "Luzerne"
    },
    {
      "countyFIP": "081",
      "countyName": "Lycoming"
    },
    {
      "countyFIP": "083",
      "countyName": "McKean"
    },
    {
      "countyFIP": "085",
      "countyName": "Mercer"
    },
    {
      "countyFIP": "087",
      "countyName": "Mifflin"
    },
    {
      "countyFIP": "089",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "091",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "093",
      "countyName": "Montour"
    },
    {
      "countyFIP": "095",
      "countyName": "Northampton"
    },
    {
      "countyFIP": "097",
      "countyName": "Northumberland"
    },
    {
      "countyFIP": "099",
      "countyName": "Perry"
    },
    {
      "countyFIP": "101",
      "countyName": "Philadelphia"
    },
    {
      "countyFIP": "103",
      "countyName": "Pike"
    },
    {
      "countyFIP": "105",
      "countyName": "Potter"
    },
    {
      "countyFIP": "107",
      "countyName": "Schuylkill"
    },
    {
      "countyFIP": "109",
      "countyName": "Snyder"
    },
    {
      "countyFIP": "111",
      "countyName": "Somerset"
    },
    {
      "countyFIP": "113",
      "countyName": "Sullivan"
    },
    {
      "countyFIP": "115",
      "countyName": "Susquehanna"
    },
    {
      "countyFIP": "117",
      "countyName": "Tioga"
    },
    {
      "countyFIP": "119",
      "countyName": "Union"
    },
    {
      "countyFIP": "121",
      "countyName": "Venango"
    },
    {
      "countyFIP": "123",
      "countyName": "Warren"
    },
    {
      "countyFIP": "125",
      "countyName": "Washington"
    },
    {
      "countyFIP": "127",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "129",
      "countyName": "Westmoreland"
    },
    {
      "countyFIP": "131",
      "countyName": "Wyoming"
    },
    {
      "countyFIP": "133",
      "countyName": "York"
    }
  ],
  "44": [
    {
      "countyFIP": "001",
      "countyName": "Bristol"
    },
    {
      "countyFIP": "003",
      "countyName": "Kent"
    },
    {
      "countyFIP": "005",
      "countyName": "Newport"
    },
    {
      "countyFIP": "007",
      "countyName": "Providence"
    },
    {
      "countyFIP": "009",
      "countyName": "Washington"
    }
  ],
  "45": [
    {
      "countyFIP": "001",
      "countyName": "Abbeville"
    },
    {
      "countyFIP": "003",
      "countyName": "Aiken"
    },
    {
      "countyFIP": "005",
      "countyName": "Allendale"
    },
    {
      "countyFIP": "007",
      "countyName": "Anderson"
    },
    {
      "countyFIP": "009",
      "countyName": "Bamberg"
    },
    {
      "countyFIP": "011",
      "countyName": "Barnwell"
    },
    {
      "countyFIP": "013",
      "countyName": "Beaufort"
    },
    {
      "countyFIP": "015",
      "countyName": "Berkeley"
    },
    {
      "countyFIP": "017",
      "countyName": "Calhoun"
    },
    {
      "countyFIP": "019",
      "countyName": "Charleston"
    },
    {
      "countyFIP": "021",
      "countyName": "Cherokee"
    },
    {
      "countyFIP": "023",
      "countyName": "Chester"
    },
    {
      "countyFIP": "025",
      "countyName": "Chesterfield"
    },
    {
      "countyFIP": "027",
      "countyName": "Clarendon"
    },
    {
      "countyFIP": "029",
      "countyName": "Colleton"
    },
    {
      "countyFIP": "031",
      "countyName": "Darlington"
    },
    {
      "countyFIP": "033",
      "countyName": "Dillon"
    },
    {
      "countyFIP": "035",
      "countyName": "Dorchester"
    },
    {
      "countyFIP": "037",
      "countyName": "Edgefield"
    },
    {
      "countyFIP": "039",
      "countyName": "Fairfield"
    },
    {
      "countyFIP": "041",
      "countyName": "Florence"
    },
    {
      "countyFIP": "043",
      "countyName": "Georgetown"
    },
    {
      "countyFIP": "045",
      "countyName": "Greenville"
    },
    {
      "countyFIP": "047",
      "countyName": "Greenwood"
    },
    {
      "countyFIP": "049",
      "countyName": "Hampton"
    },
    {
      "countyFIP": "051",
      "countyName": "Horry"
    },
    {
      "countyFIP": "053",
      "countyName": "Jasper"
    },
    {
      "countyFIP": "055",
      "countyName": "Kershaw"
    },
    {
      "countyFIP": "057",
      "countyName": "Lancaster"
    },
    {
      "countyFIP": "059",
      "countyName": "Laurens"
    },
    {
      "countyFIP": "061",
      "countyName": "Lee"
    },
    {
      "countyFIP": "063",
      "countyName": "Lexington"
    },
    {
      "countyFIP": "065",
      "countyName": "McCormick"
    },
    {
      "countyFIP": "067",
      "countyName": "Marion"
    },
    {
      "countyFIP": "069",
      "countyName": "Marlboro"
    },
    {
      "countyFIP": "071",
      "countyName": "Newberry"
    },
    {
      "countyFIP": "073",
      "countyName": "Oconee"
    },
    {
      "countyFIP": "075",
      "countyName": "Orangeburg"
    },
    {
      "countyFIP": "077",
      "countyName": "Pickens"
    },
    {
      "countyFIP": "079",
      "countyName": "Richland"
    },
    {
      "countyFIP": "081",
      "countyName": "Saluda"
    },
    {
      "countyFIP": "083",
      "countyName": "Spartanburg"
    },
    {
      "countyFIP": "085",
      "countyName": "Sumter"
    },
    {
      "countyFIP": "087",
      "countyName": "Union"
    },
    {
      "countyFIP": "089",
      "countyName": "Williamsburg"
    },
    {
      "countyFIP": "091",
      "countyName": "York"
    }
  ],
  "46": [
    {
      "countyFIP": "003",
      "countyName": "Aurora"
    },
    {
      "countyFIP": "005",
      "countyName": "Beadle"
    },
    {
      "countyFIP": "007",
      "countyName": "Bennett"
    },
    {
      "countyFIP": "009",
      "countyName": "Bon Homme"
    },
    {
      "countyFIP": "011",
      "countyName": "Brookings"
    },
    {
      "countyFIP": "013",
      "countyName": "Brown"
    },
    {
      "countyFIP": "015",
      "countyName": "Brule"
    },
    {
      "countyFIP": "017",
      "countyName": "Buffalo"
    },
    {
      "countyFIP": "019",
      "countyName": "Butte"
    },
    {
      "countyFIP": "021",
      "countyName": "Campbell"
    },
    {
      "countyFIP": "023",
      "countyName": "Charles Mix"
    },
    {
      "countyFIP": "025",
      "countyName": "Clark"
    },
    {
      "countyFIP": "027",
      "countyName": "Clay"
    },
    {
      "countyFIP": "029",
      "countyName": "Codington"
    },
    {
      "countyFIP": "031",
      "countyName": "Corson"
    },
    {
      "countyFIP": "033",
      "countyName": "Custer"
    },
    {
      "countyFIP": "035",
      "countyName": "Davison"
    },
    {
      "countyFIP": "037",
      "countyName": "Day"
    },
    {
      "countyFIP": "039",
      "countyName": "Deuel"
    },
    {
      "countyFIP": "041",
      "countyName": "Dewey"
    },
    {
      "countyFIP": "043",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "045",
      "countyName": "Edmunds"
    },
    {
      "countyFIP": "047",
      "countyName": "Fall River"
    },
    {
      "countyFIP": "049",
      "countyName": "Faulk"
    },
    {
      "countyFIP": "051",
      "countyName": "Grant"
    },
    {
      "countyFIP": "053",
      "countyName": "Gregory"
    },
    {
      "countyFIP": "055",
      "countyName": "Haakon"
    },
    {
      "countyFIP": "057",
      "countyName": "Hamlin"
    },
    {
      "countyFIP": "059",
      "countyName": "Hand"
    },
    {
      "countyFIP": "061",
      "countyName": "Hanson"
    },
    {
      "countyFIP": "063",
      "countyName": "Harding"
    },
    {
      "countyFIP": "065",
      "countyName": "Hughes"
    },
    {
      "countyFIP": "067",
      "countyName": "Hutchinson"
    },
    {
      "countyFIP": "069",
      "countyName": "Hyde"
    },
    {
      "countyFIP": "071",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "073",
      "countyName": "Jerauld"
    },
    {
      "countyFIP": "075",
      "countyName": "Jones"
    },
    {
      "countyFIP": "077",
      "countyName": "Kingsbury"
    },
    {
      "countyFIP": "079",
      "countyName": "Lake"
    },
    {
      "countyFIP": "081",
      "countyName": "Lawrence"
    },
    {
      "countyFIP": "083",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "085",
      "countyName": "Lyman"
    },
    {
      "countyFIP": "087",
      "countyName": "McCook"
    },
    {
      "countyFIP": "089",
      "countyName": "McPherson"
    },
    {
      "countyFIP": "091",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "093",
      "countyName": "Meade"
    },
    {
      "countyFIP": "095",
      "countyName": "Mellette"
    },
    {
      "countyFIP": "097",
      "countyName": "Miner"
    },
    {
      "countyFIP": "099",
      "countyName": "Minnehaha"
    },
    {
      "countyFIP": "101",
      "countyName": "Moody"
    },
    {
      "countyFIP": "102",
      "countyName": "Oglala Lakota"
    },
    {
      "countyFIP": "103",
      "countyName": "Pennington"
    },
    {
      "countyFIP": "105",
      "countyName": "Perkins"
    },
    {
      "countyFIP": "107",
      "countyName": "Potter"
    },
    {
      "countyFIP": "109",
      "countyName": "Roberts"
    },
    {
      "countyFIP": "111",
      "countyName": "Sanborn"
    },
    {
      "countyFIP": "115",
      "countyName": "Spink"
    },
    {
      "countyFIP": "117",
      "countyName": "Stanley"
    },
    {
      "countyFIP": "119",
      "countyName": "Sully"
    },
    {
      "countyFIP": "121",
      "countyName": "Todd"
    },
    {
      "countyFIP": "123",
      "countyName": "Tripp"
    },
    {
      "countyFIP": "125",
      "countyName": "Turner"
    },
    {
      "countyFIP": "127",
      "countyName": "Union"
    },
    {
      "countyFIP": "129",
      "countyName": "Walworth"
    },
    {
      "countyFIP": "135",
      "countyName": "Yankton"
    },
    {
      "countyFIP": "137",
      "countyName": "Ziebach"
    }
  ],
  "47": [
    {
      "countyFIP": "001",
      "countyName": "Anderson"
    },
    {
      "countyFIP": "003",
      "countyName": "Bedford"
    },
    {
      "countyFIP": "005",
      "countyName": "Benton"
    },
    {
      "countyFIP": "007",
      "countyName": "Bledsoe"
    },
    {
      "countyFIP": "009",
      "countyName": "Blount"
    },
    {
      "countyFIP": "011",
      "countyName": "Bradley"
    },
    {
      "countyFIP": "013",
      "countyName": "Campbell"
    },
    {
      "countyFIP": "015",
      "countyName": "Cannon"
    },
    {
      "countyFIP": "017",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "019",
      "countyName": "Carter"
    },
    {
      "countyFIP": "021",
      "countyName": "Cheatham"
    },
    {
      "countyFIP": "023",
      "countyName": "Chester"
    },
    {
      "countyFIP": "025",
      "countyName": "Claiborne"
    },
    {
      "countyFIP": "027",
      "countyName": "Clay"
    },
    {
      "countyFIP": "029",
      "countyName": "Cocke"
    },
    {
      "countyFIP": "031",
      "countyName": "Coffee"
    },
    {
      "countyFIP": "033",
      "countyName": "Crockett"
    },
    {
      "countyFIP": "035",
      "countyName": "Cumberland"
    },
    {
      "countyFIP": "037",
      "countyName": "Davidson"
    },
    {
      "countyFIP": "039",
      "countyName": "Decatur"
    },
    {
      "countyFIP": "041",
      "countyName": "DeKalb"
    },
    {
      "countyFIP": "043",
      "countyName": "Dickson"
    },
    {
      "countyFIP": "045",
      "countyName": "Dyer"
    },
    {
      "countyFIP": "047",
      "countyName": "Fayette"
    },
    {
      "countyFIP": "049",
      "countyName": "Fentress"
    },
    {
      "countyFIP": "051",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "053",
      "countyName": "Gibson"
    },
    {
      "countyFIP": "055",
      "countyName": "Giles"
    },
    {
      "countyFIP": "057",
      "countyName": "Grainger"
    },
    {
      "countyFIP": "059",
      "countyName": "Greene"
    },
    {
      "countyFIP": "061",
      "countyName": "Grundy"
    },
    {
      "countyFIP": "063",
      "countyName": "Hamblen"
    },
    {
      "countyFIP": "065",
      "countyName": "Hamilton"
    },
    {
      "countyFIP": "067",
      "countyName": "Hancock"
    },
    {
      "countyFIP": "069",
      "countyName": "Hardeman"
    },
    {
      "countyFIP": "071",
      "countyName": "Hardin"
    },
    {
      "countyFIP": "073",
      "countyName": "Hawkins"
    },
    {
      "countyFIP": "075",
      "countyName": "Haywood"
    },
    {
      "countyFIP": "077",
      "countyName": "Henderson"
    },
    {
      "countyFIP": "079",
      "countyName": "Henry"
    },
    {
      "countyFIP": "081",
      "countyName": "Hickman"
    },
    {
      "countyFIP": "083",
      "countyName": "Houston"
    },
    {
      "countyFIP": "085",
      "countyName": "Humphreys"
    },
    {
      "countyFIP": "087",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "089",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "091",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "093",
      "countyName": "Knox"
    },
    {
      "countyFIP": "095",
      "countyName": "Lake"
    },
    {
      "countyFIP": "097",
      "countyName": "Lauderdale"
    },
    {
      "countyFIP": "099",
      "countyName": "Lawrence"
    },
    {
      "countyFIP": "101",
      "countyName": "Lewis"
    },
    {
      "countyFIP": "103",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "105",
      "countyName": "Loudon"
    },
    {
      "countyFIP": "107",
      "countyName": "McMinn"
    },
    {
      "countyFIP": "109",
      "countyName": "McNairy"
    },
    {
      "countyFIP": "111",
      "countyName": "Macon"
    },
    {
      "countyFIP": "113",
      "countyName": "Madison"
    },
    {
      "countyFIP": "115",
      "countyName": "Marion"
    },
    {
      "countyFIP": "117",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "119",
      "countyName": "Maury"
    },
    {
      "countyFIP": "121",
      "countyName": "Meigs"
    },
    {
      "countyFIP": "123",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "125",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "127",
      "countyName": "Moore"
    },
    {
      "countyFIP": "129",
      "countyName": "Morgan"
    },
    {
      "countyFIP": "131",
      "countyName": "Obion"
    },
    {
      "countyFIP": "133",
      "countyName": "Overton"
    },
    {
      "countyFIP": "135",
      "countyName": "Perry"
    },
    {
      "countyFIP": "137",
      "countyName": "Pickett"
    },
    {
      "countyFIP": "139",
      "countyName": "Polk"
    },
    {
      "countyFIP": "141",
      "countyName": "Putnam"
    },
    {
      "countyFIP": "143",
      "countyName": "Rhea"
    },
    {
      "countyFIP": "145",
      "countyName": "Roane"
    },
    {
      "countyFIP": "147",
      "countyName": "Robertson"
    },
    {
      "countyFIP": "149",
      "countyName": "Rutherford"
    },
    {
      "countyFIP": "151",
      "countyName": "Scott"
    },
    {
      "countyFIP": "153",
      "countyName": "Sequatchie"
    },
    {
      "countyFIP": "155",
      "countyName": "Sevier"
    },
    {
      "countyFIP": "157",
      "countyName": "Shelby"
    },
    {
      "countyFIP": "159",
      "countyName": "Smith"
    },
    {
      "countyFIP": "161",
      "countyName": "Stewart"
    },
    {
      "countyFIP": "163",
      "countyName": "Sullivan"
    },
    {
      "countyFIP": "165",
      "countyName": "Sumner"
    },
    {
      "countyFIP": "167",
      "countyName": "Tipton"
    },
    {
      "countyFIP": "169",
      "countyName": "Trousdale"
    },
    {
      "countyFIP": "171",
      "countyName": "Unicoi"
    },
    {
      "countyFIP": "173",
      "countyName": "Union"
    },
    {
      "countyFIP": "175",
      "countyName": "Van Buren"
    },
    {
      "countyFIP": "177",
      "countyName": "Warren"
    },
    {
      "countyFIP": "179",
      "countyName": "Washington"
    },
    {
      "countyFIP": "181",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "183",
      "countyName": "Weakley"
    },
    {
      "countyFIP": "185",
      "countyName": "White"
    },
    {
      "countyFIP": "187",
      "countyName": "Williamson"
    },
    {
      "countyFIP": "189",
      "countyName": "Wilson"
    }
  ],
  "48": [
    {
      "countyFIP": "001",
      "countyName": "Anderson"
    },
    {
      "countyFIP": "003",
      "countyName": "Andrews"
    },
    {
      "countyFIP": "005",
      "countyName": "Angelina"
    },
    {
      "countyFIP": "007",
      "countyName": "Aransas"
    },
    {
      "countyFIP": "009",
      "countyName": "Archer"
    },
    {
      "countyFIP": "011",
      "countyName": "Armstrong"
    },
    {
      "countyFIP": "013",
      "countyName": "Atascosa"
    },
    {
      "countyFIP": "015",
      "countyName": "Austin"
    },
    {
      "countyFIP": "017",
      "countyName": "Bailey"
    },
    {
      "countyFIP": "019",
      "countyName": "Bandera"
    },
    {
      "countyFIP": "021",
      "countyName": "Bastrop"
    },
    {
      "countyFIP": "023",
      "countyName": "Baylor"
    },
    {
      "countyFIP": "025",
      "countyName": "Bee"
    },
    {
      "countyFIP": "027",
      "countyName": "Bell"
    },
    {
      "countyFIP": "029",
      "countyName": "Bexar"
    },
    {
      "countyFIP": "031",
      "countyName": "Blanco"
    },
    {
      "countyFIP": "033",
      "countyName": "Borden"
    },
    {
      "countyFIP": "035",
      "countyName": "Bosque"
    },
    {
      "countyFIP": "037",
      "countyName": "Bowie"
    },
    {
      "countyFIP": "039",
      "countyName": "Brazoria"
    },
    {
      "countyFIP": "041",
      "countyName": "Brazos"
    },
    {
      "countyFIP": "043",
      "countyName": "Brewster"
    },
    {
      "countyFIP": "045",
      "countyName": "Briscoe"
    },
    {
      "countyFIP": "047",
      "countyName": "Brooks"
    },
    {
      "countyFIP": "049",
      "countyName": "Brown"
    },
    {
      "countyFIP": "051",
      "countyName": "Burleson"
    },
    {
      "countyFIP": "053",
      "countyName": "Burnet"
    },
    {
      "countyFIP": "055",
      "countyName": "Caldwell"
    },
    {
      "countyFIP": "057",
      "countyName": "Calhoun"
    },
    {
      "countyFIP": "059",
      "countyName": "Callahan"
    },
    {
      "countyFIP": "061",
      "countyName": "Cameron"
    },
    {
      "countyFIP": "063",
      "countyName": "Camp"
    },
    {
      "countyFIP": "065",
      "countyName": "Carson"
    },
    {
      "countyFIP": "067",
      "countyName": "Cass"
    },
    {
      "countyFIP": "069",
      "countyName": "Castro"
    },
    {
      "countyFIP": "071",
      "countyName": "Chambers"
    },
    {
      "countyFIP": "073",
      "countyName": "Cherokee"
    },
    {
      "countyFIP": "075",
      "countyName": "Childress"
    },
    {
      "countyFIP": "077",
      "countyName": "Clay"
    },
    {
      "countyFIP": "079",
      "countyName": "Cochran"
    },
    {
      "countyFIP": "081",
      "countyName": "Coke"
    },
    {
      "countyFIP": "083",
      "countyName": "Coleman"
    },
    {
      "countyFIP": "085",
      "countyName": "Collin"
    },
    {
      "countyFIP": "087",
      "countyName": "Collingsworth"
    },
    {
      "countyFIP": "089",
      "countyName": "Colorado"
    },
    {
      "countyFIP": "091",
      "countyName": "Comal"
    },
    {
      "countyFIP": "093",
      "countyName": "Comanche"
    },
    {
      "countyFIP": "095",
      "countyName": "Concho"
    },
    {
      "countyFIP": "097",
      "countyName": "Cooke"
    },
    {
      "countyFIP": "099",
      "countyName": "Coryell"
    },
    {
      "countyFIP": "101",
      "countyName": "Cottle"
    },
    {
      "countyFIP": "103",
      "countyName": "Crane"
    },
    {
      "countyFIP": "105",
      "countyName": "Crockett"
    },
    {
      "countyFIP": "107",
      "countyName": "Crosby"
    },
    {
      "countyFIP": "109",
      "countyName": "Culberson"
    },
    {
      "countyFIP": "111",
      "countyName": "Dallam"
    },
    {
      "countyFIP": "113",
      "countyName": "Dallas"
    },
    {
      "countyFIP": "115",
      "countyName": "Dawson"
    },
    {
      "countyFIP": "117",
      "countyName": "Deaf Smith"
    },
    {
      "countyFIP": "119",
      "countyName": "Delta"
    },
    {
      "countyFIP": "121",
      "countyName": "Denton"
    },
    {
      "countyFIP": "123",
      "countyName": "DeWitt"
    },
    {
      "countyFIP": "125",
      "countyName": "Dickens"
    },
    {
      "countyFIP": "127",
      "countyName": "Dimmit"
    },
    {
      "countyFIP": "129",
      "countyName": "Donley"
    },
    {
      "countyFIP": "131",
      "countyName": "Duval"
    },
    {
      "countyFIP": "133",
      "countyName": "Eastland"
    },
    {
      "countyFIP": "135",
      "countyName": "Ector"
    },
    {
      "countyFIP": "137",
      "countyName": "Edwards"
    },
    {
      "countyFIP": "139",
      "countyName": "Ellis"
    },
    {
      "countyFIP": "141",
      "countyName": "El Paso"
    },
    {
      "countyFIP": "143",
      "countyName": "Erath"
    },
    {
      "countyFIP": "145",
      "countyName": "Falls"
    },
    {
      "countyFIP": "147",
      "countyName": "Fannin"
    },
    {
      "countyFIP": "149",
      "countyName": "Fayette"
    },
    {
      "countyFIP": "151",
      "countyName": "Fisher"
    },
    {
      "countyFIP": "153",
      "countyName": "Floyd"
    },
    {
      "countyFIP": "155",
      "countyName": "Foard"
    },
    {
      "countyFIP": "157",
      "countyName": "Fort Bend"
    },
    {
      "countyFIP": "159",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "161",
      "countyName": "Freestone"
    },
    {
      "countyFIP": "163",
      "countyName": "Frio"
    },
    {
      "countyFIP": "165",
      "countyName": "Gaines"
    },
    {
      "countyFIP": "167",
      "countyName": "Galveston"
    },
    {
      "countyFIP": "169",
      "countyName": "Garza"
    },
    {
      "countyFIP": "171",
      "countyName": "Gillespie"
    },
    {
      "countyFIP": "173",
      "countyName": "Glasscock"
    },
    {
      "countyFIP": "175",
      "countyName": "Goliad"
    },
    {
      "countyFIP": "177",
      "countyName": "Gonzales"
    },
    {
      "countyFIP": "179",
      "countyName": "Gray"
    },
    {
      "countyFIP": "181",
      "countyName": "Grayson"
    },
    {
      "countyFIP": "183",
      "countyName": "Gregg"
    },
    {
      "countyFIP": "185",
      "countyName": "Grimes"
    },
    {
      "countyFIP": "187",
      "countyName": "Guadalupe"
    },
    {
      "countyFIP": "189",
      "countyName": "Hale"
    },
    {
      "countyFIP": "191",
      "countyName": "Hall"
    },
    {
      "countyFIP": "193",
      "countyName": "Hamilton"
    },
    {
      "countyFIP": "195",
      "countyName": "Hansford"
    },
    {
      "countyFIP": "197",
      "countyName": "Hardeman"
    },
    {
      "countyFIP": "199",
      "countyName": "Hardin"
    },
    {
      "countyFIP": "201",
      "countyName": "Harris"
    },
    {
      "countyFIP": "203",
      "countyName": "Harrison"
    },
    {
      "countyFIP": "205",
      "countyName": "Hartley"
    },
    {
      "countyFIP": "207",
      "countyName": "Haskell"
    },
    {
      "countyFIP": "209",
      "countyName": "Hays"
    },
    {
      "countyFIP": "211",
      "countyName": "Hemphill"
    },
    {
      "countyFIP": "213",
      "countyName": "Henderson"
    },
    {
      "countyFIP": "215",
      "countyName": "Hidalgo"
    },
    {
      "countyFIP": "217",
      "countyName": "Hill"
    },
    {
      "countyFIP": "219",
      "countyName": "Hockley"
    },
    {
      "countyFIP": "221",
      "countyName": "Hood"
    },
    {
      "countyFIP": "223",
      "countyName": "Hopkins"
    },
    {
      "countyFIP": "225",
      "countyName": "Houston"
    },
    {
      "countyFIP": "227",
      "countyName": "Howard"
    },
    {
      "countyFIP": "229",
      "countyName": "Hudspeth"
    },
    {
      "countyFIP": "231",
      "countyName": "Hunt"
    },
    {
      "countyFIP": "233",
      "countyName": "Hutchinson"
    },
    {
      "countyFIP": "235",
      "countyName": "Irion"
    },
    {
      "countyFIP": "237",
      "countyName": "Jack"
    },
    {
      "countyFIP": "239",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "241",
      "countyName": "Jasper"
    },
    {
      "countyFIP": "243",
      "countyName": "Jeff Davis"
    },
    {
      "countyFIP": "245",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "247",
      "countyName": "Jim Hogg"
    },
    {
      "countyFIP": "249",
      "countyName": "Jim Wells"
    },
    {
      "countyFIP": "251",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "253",
      "countyName": "Jones"
    },
    {
      "countyFIP": "255",
      "countyName": "Karnes"
    },
    {
      "countyFIP": "257",
      "countyName": "Kaufman"
    },
    {
      "countyFIP": "259",
      "countyName": "Kendall"
    },
    {
      "countyFIP": "261",
      "countyName": "Kenedy"
    },
    {
      "countyFIP": "263",
      "countyName": "Kent"
    },
    {
      "countyFIP": "265",
      "countyName": "Kerr"
    },
    {
      "countyFIP": "267",
      "countyName": "Kimble"
    },
    {
      "countyFIP": "269",
      "countyName": "King"
    },
    {
      "countyFIP": "271",
      "countyName": "Kinney"
    },
    {
      "countyFIP": "273",
      "countyName": "Kleberg"
    },
    {
      "countyFIP": "275",
      "countyName": "Knox"
    },
    {
      "countyFIP": "277",
      "countyName": "Lamar"
    },
    {
      "countyFIP": "279",
      "countyName": "Lamb"
    },
    {
      "countyFIP": "281",
      "countyName": "Lampasas"
    },
    {
      "countyFIP": "283",
      "countyName": "La Salle"
    },
    {
      "countyFIP": "285",
      "countyName": "Lavaca"
    },
    {
      "countyFIP": "287",
      "countyName": "Lee"
    },
    {
      "countyFIP": "289",
      "countyName": "Leon"
    },
    {
      "countyFIP": "291",
      "countyName": "Liberty"
    },
    {
      "countyFIP": "293",
      "countyName": "Limestone"
    },
    {
      "countyFIP": "295",
      "countyName": "Lipscomb"
    },
    {
      "countyFIP": "297",
      "countyName": "Live Oak"
    },
    {
      "countyFIP": "299",
      "countyName": "Llano"
    },
    {
      "countyFIP": "301",
      "countyName": "Loving"
    },
    {
      "countyFIP": "303",
      "countyName": "Lubbock"
    },
    {
      "countyFIP": "305",
      "countyName": "Lynn"
    },
    {
      "countyFIP": "307",
      "countyName": "McCulloch"
    },
    {
      "countyFIP": "309",
      "countyName": "McLennan"
    },
    {
      "countyFIP": "311",
      "countyName": "McMullen"
    },
    {
      "countyFIP": "313",
      "countyName": "Madison"
    },
    {
      "countyFIP": "315",
      "countyName": "Marion"
    },
    {
      "countyFIP": "317",
      "countyName": "Martin"
    },
    {
      "countyFIP": "319",
      "countyName": "Mason"
    },
    {
      "countyFIP": "321",
      "countyName": "Matagorda"
    },
    {
      "countyFIP": "323",
      "countyName": "Maverick"
    },
    {
      "countyFIP": "325",
      "countyName": "Medina"
    },
    {
      "countyFIP": "327",
      "countyName": "Menard"
    },
    {
      "countyFIP": "329",
      "countyName": "Midland"
    },
    {
      "countyFIP": "331",
      "countyName": "Milam"
    },
    {
      "countyFIP": "333",
      "countyName": "Mills"
    },
    {
      "countyFIP": "335",
      "countyName": "Mitchell"
    },
    {
      "countyFIP": "337",
      "countyName": "Montague"
    },
    {
      "countyFIP": "339",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "341",
      "countyName": "Moore"
    },
    {
      "countyFIP": "343",
      "countyName": "Morris"
    },
    {
      "countyFIP": "345",
      "countyName": "Motley"
    },
    {
      "countyFIP": "347",
      "countyName": "Nacogdoches"
    },
    {
      "countyFIP": "349",
      "countyName": "Navarro"
    },
    {
      "countyFIP": "351",
      "countyName": "Newton"
    },
    {
      "countyFIP": "353",
      "countyName": "Nolan"
    },
    {
      "countyFIP": "355",
      "countyName": "Nueces"
    },
    {
      "countyFIP": "357",
      "countyName": "Ochiltree"
    },
    {
      "countyFIP": "359",
      "countyName": "Oldham"
    },
    {
      "countyFIP": "361",
      "countyName": "Orange"
    },
    {
      "countyFIP": "363",
      "countyName": "Palo Pinto"
    },
    {
      "countyFIP": "365",
      "countyName": "Panola"
    },
    {
      "countyFIP": "367",
      "countyName": "Parker"
    },
    {
      "countyFIP": "369",
      "countyName": "Parmer"
    },
    {
      "countyFIP": "371",
      "countyName": "Pecos"
    },
    {
      "countyFIP": "373",
      "countyName": "Polk"
    },
    {
      "countyFIP": "375",
      "countyName": "Potter"
    },
    {
      "countyFIP": "377",
      "countyName": "Presidio"
    },
    {
      "countyFIP": "379",
      "countyName": "Rains"
    },
    {
      "countyFIP": "381",
      "countyName": "Randall"
    },
    {
      "countyFIP": "383",
      "countyName": "Reagan"
    },
    {
      "countyFIP": "385",
      "countyName": "Real"
    },
    {
      "countyFIP": "387",
      "countyName": "Red River"
    },
    {
      "countyFIP": "389",
      "countyName": "Reeves"
    },
    {
      "countyFIP": "391",
      "countyName": "Refugio"
    },
    {
      "countyFIP": "393",
      "countyName": "Roberts"
    },
    {
      "countyFIP": "395",
      "countyName": "Robertson"
    },
    {
      "countyFIP": "397",
      "countyName": "Rockwall"
    },
    {
      "countyFIP": "399",
      "countyName": "Runnels"
    },
    {
      "countyFIP": "401",
      "countyName": "Rusk"
    },
    {
      "countyFIP": "403",
      "countyName": "Sabine"
    },
    {
      "countyFIP": "405",
      "countyName": "San Augustine"
    },
    {
      "countyFIP": "407",
      "countyName": "San Jacinto"
    },
    {
      "countyFIP": "409",
      "countyName": "San Patricio"
    },
    {
      "countyFIP": "411",
      "countyName": "San Saba"
    },
    {
      "countyFIP": "413",
      "countyName": "Schleicher"
    },
    {
      "countyFIP": "415",
      "countyName": "Scurry"
    },
    {
      "countyFIP": "417",
      "countyName": "Shackelford"
    },
    {
      "countyFIP": "419",
      "countyName": "Shelby"
    },
    {
      "countyFIP": "421",
      "countyName": "Sherman"
    },
    {
      "countyFIP": "423",
      "countyName": "Smith"
    },
    {
      "countyFIP": "425",
      "countyName": "Somervell"
    },
    {
      "countyFIP": "427",
      "countyName": "Starr"
    },
    {
      "countyFIP": "429",
      "countyName": "Stephens"
    },
    {
      "countyFIP": "431",
      "countyName": "Sterling"
    },
    {
      "countyFIP": "433",
      "countyName": "Stonewall"
    },
    {
      "countyFIP": "435",
      "countyName": "Sutton"
    },
    {
      "countyFIP": "437",
      "countyName": "Swisher"
    },
    {
      "countyFIP": "439",
      "countyName": "Tarrant"
    },
    {
      "countyFIP": "441",
      "countyName": "Taylor"
    },
    {
      "countyFIP": "443",
      "countyName": "Terrell"
    },
    {
      "countyFIP": "445",
      "countyName": "Terry"
    },
    {
      "countyFIP": "447",
      "countyName": "Throckmorton"
    },
    {
      "countyFIP": "449",
      "countyName": "Titus"
    },
    {
      "countyFIP": "451",
      "countyName": "Tom Green"
    },
    {
      "countyFIP": "453",
      "countyName": "Travis"
    },
    {
      "countyFIP": "455",
      "countyName": "Trinity"
    },
    {
      "countyFIP": "457",
      "countyName": "Tyler"
    },
    {
      "countyFIP": "459",
      "countyName": "Upshur"
    },
    {
      "countyFIP": "461",
      "countyName": "Upton"
    },
    {
      "countyFIP": "463",
      "countyName": "Uvalde"
    },
    {
      "countyFIP": "465",
      "countyName": "Val Verde"
    },
    {
      "countyFIP": "467",
      "countyName": "Van Zandt"
    },
    {
      "countyFIP": "469",
      "countyName": "Victoria"
    },
    {
      "countyFIP": "471",
      "countyName": "Walker"
    },
    {
      "countyFIP": "473",
      "countyName": "Waller"
    },
    {
      "countyFIP": "475",
      "countyName": "Ward"
    },
    {
      "countyFIP": "477",
      "countyName": "Washington"
    },
    {
      "countyFIP": "479",
      "countyName": "Webb"
    },
    {
      "countyFIP": "481",
      "countyName": "Wharton"
    },
    {
      "countyFIP": "483",
      "countyName": "Wheeler"
    },
    {
      "countyFIP": "485",
      "countyName": "Wichita"
    },
    {
      "countyFIP": "487",
      "countyName": "Wilbarger"
    },
    {
      "countyFIP": "489",
      "countyName": "Willacy"
    },
    {
      "countyFIP": "491",
      "countyName": "Williamson"
    },
    {
      "countyFIP": "493",
      "countyName": "Wilson"
    },
    {
      "countyFIP": "495",
      "countyName": "Winkler"
    },
    {
      "countyFIP": "497",
      "countyName": "Wise"
    },
    {
      "countyFIP": "499",
      "countyName": "Wood"
    },
    {
      "countyFIP": "501",
      "countyName": "Yoakum"
    },
    {
      "countyFIP": "503",
      "countyName": "Young"
    },
    {
      "countyFIP": "505",
      "countyName": "Zapata"
    },
    {
      "countyFIP": "507",
      "countyName": "Zavala"
    }
  ],
  "49": [
    {
      "countyFIP": "001",
      "countyName": "Beaver"
    },
    {
      "countyFIP": "003",
      "countyName": "Box Elder"
    },
    {
      "countyFIP": "005",
      "countyName": "Cache"
    },
    {
      "countyFIP": "007",
      "countyName": "Carbon"
    },
    {
      "countyFIP": "009",
      "countyName": "Daggett"
    },
    {
      "countyFIP": "011",
      "countyName": "Davis"
    },
    {
      "countyFIP": "013",
      "countyName": "Duchesne"
    },
    {
      "countyFIP": "015",
      "countyName": "Emery"
    },
    {
      "countyFIP": "017",
      "countyName": "Garfield"
    },
    {
      "countyFIP": "019",
      "countyName": "Grand"
    },
    {
      "countyFIP": "021",
      "countyName": "Iron"
    },
    {
      "countyFIP": "023",
      "countyName": "Juab"
    },
    {
      "countyFIP": "025",
      "countyName": "Kane"
    },
    {
      "countyFIP": "027",
      "countyName": "Millard"
    },
    {
      "countyFIP": "029",
      "countyName": "Morgan"
    },
    {
      "countyFIP": "031",
      "countyName": "Piute"
    },
    {
      "countyFIP": "033",
      "countyName": "Rich"
    },
    {
      "countyFIP": "035",
      "countyName": "Salt Lake"
    },
    {
      "countyFIP": "037",
      "countyName": "San Juan"
    },
    {
      "countyFIP": "039",
      "countyName": "Sanpete"
    },
    {
      "countyFIP": "041",
      "countyName": "Sevier"
    },
    {
      "countyFIP": "043",
      "countyName": "Summit"
    },
    {
      "countyFIP": "045",
      "countyName": "Tooele"
    },
    {
      "countyFIP": "047",
      "countyName": "Uintah"
    },
    {
      "countyFIP": "049",
      "countyName": "Utah"
    },
    {
      "countyFIP": "051",
      "countyName": "Wasatch"
    },
    {
      "countyFIP": "053",
      "countyName": "Washington"
    },
    {
      "countyFIP": "055",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "057",
      "countyName": "Weber"
    }
  ],
  "50": [
    {
      "countyFIP": "001",
      "countyName": "Addison"
    },
    {
      "countyFIP": "003",
      "countyName": "Bennington"
    },
    {
      "countyFIP": "005",
      "countyName": "Caledonia"
    },
    {
      "countyFIP": "007",
      "countyName": "Chittenden"
    },
    {
      "countyFIP": "009",
      "countyName": "Essex"
    },
    {
      "countyFIP": "011",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "013",
      "countyName": "Grand Isle"
    },
    {
      "countyFIP": "015",
      "countyName": "Lamoille"
    },
    {
      "countyFIP": "017",
      "countyName": "Orange"
    },
    {
      "countyFIP": "019",
      "countyName": "Orleans"
    },
    {
      "countyFIP": "021",
      "countyName": "Rutland"
    },
    {
      "countyFIP": "023",
      "countyName": "Washington"
    },
    {
      "countyFIP": "025",
      "countyName": "Windham"
    },
    {
      "countyFIP": "027",
      "countyName": "Windsor"
    }
  ],
  "51": [
    {
      "countyFIP": "001",
      "countyName": "Accomack"
    },
    {
      "countyFIP": "003",
      "countyName": "Albemarle"
    },
    {
      "countyFIP": "005",
      "countyName": "Alleghany"
    },
    {
      "countyFIP": "007",
      "countyName": "Amelia"
    },
    {
      "countyFIP": "009",
      "countyName": "Amherst"
    },
    {
      "countyFIP": "011",
      "countyName": "Appomattox"
    },
    {
      "countyFIP": "013",
      "countyName": "Arlington"
    },
    {
      "countyFIP": "015",
      "countyName": "Augusta"
    },
    {
      "countyFIP": "017",
      "countyName": "Bath"
    },
    {
      "countyFIP": "019",
      "countyName": "Bedford"
    },
    {
      "countyFIP": "021",
      "countyName": "Bland"
    },
    {
      "countyFIP": "023",
      "countyName": "Botetourt"
    },
    {
      "countyFIP": "025",
      "countyName": "Brunswick"
    },
    {
      "countyFIP": "027",
      "countyName": "Buchanan"
    },
    {
      "countyFIP": "029",
      "countyName": "Buckingham"
    },
    {
      "countyFIP": "031",
      "countyName": "Campbell"
    },
    {
      "countyFIP": "033",
      "countyName": "Caroline"
    },
    {
      "countyFIP": "035",
      "countyName": "Carroll"
    },
    {
      "countyFIP": "036",
      "countyName": "Charles City"
    },
    {
      "countyFIP": "037",
      "countyName": "Charlotte"
    },
    {
      "countyFIP": "041",
      "countyName": "Chesterfield"
    },
    {
      "countyFIP": "043",
      "countyName": "Clarke"
    },
    {
      "countyFIP": "045",
      "countyName": "Craig"
    },
    {
      "countyFIP": "047",
      "countyName": "Culpeper"
    },
    {
      "countyFIP": "049",
      "countyName": "Cumberland"
    },
    {
      "countyFIP": "051",
      "countyName": "Dickenson"
    },
    {
      "countyFIP": "053",
      "countyName": "Dinwiddie"
    },
    {
      "countyFIP": "057",
      "countyName": "Essex"
    },
    {
      "countyFIP": "059",
      "countyName": "Fairfax"
    },
    {
      "countyFIP": "061",
      "countyName": "Fauquier"
    },
    {
      "countyFIP": "063",
      "countyName": "Floyd"
    },
    {
      "countyFIP": "065",
      "countyName": "Fluvanna"
    },
    {
      "countyFIP": "067",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "069",
      "countyName": "Frederick"
    },
    {
      "countyFIP": "071",
      "countyName": "Giles"
    },
    {
      "countyFIP": "073",
      "countyName": "Gloucester"
    },
    {
      "countyFIP": "075",
      "countyName": "Goochland"
    },
    {
      "countyFIP": "077",
      "countyName": "Grayson"
    },
    {
      "countyFIP": "079",
      "countyName": "Greene"
    },
    {
      "countyFIP": "081",
      "countyName": "Greensville"
    },
    {
      "countyFIP": "083",
      "countyName": "Halifax"
    },
    {
      "countyFIP": "085",
      "countyName": "Hanover"
    },
    {
      "countyFIP": "087",
      "countyName": "Henrico"
    },
    {
      "countyFIP": "089",
      "countyName": "Henry"
    },
    {
      "countyFIP": "091",
      "countyName": "Highland"
    },
    {
      "countyFIP": "093",
      "countyName": "Isle of Wight"
    },
    {
      "countyFIP": "095",
      "countyName": "James City"
    },
    {
      "countyFIP": "097",
      "countyName": "King and Queen"
    },
    {
      "countyFIP": "099",
      "countyName": "King George"
    },
    {
      "countyFIP": "101",
      "countyName": "King William"
    },
    {
      "countyFIP": "103",
      "countyName": "Lancaster"
    },
    {
      "countyFIP": "105",
      "countyName": "Lee"
    },
    {
      "countyFIP": "107",
      "countyName": "Loudoun"
    },
    {
      "countyFIP": "109",
      "countyName": "Louisa"
    },
    {
      "countyFIP": "111",
      "countyName": "Lunenburg"
    },
    {
      "countyFIP": "113",
      "countyName": "Madison"
    },
    {
      "countyFIP": "115",
      "countyName": "Mathews"
    },
    {
      "countyFIP": "117",
      "countyName": "Mecklenburg"
    },
    {
      "countyFIP": "119",
      "countyName": "Middlesex"
    },
    {
      "countyFIP": "121",
      "countyName": "Montgomery"
    },
    {
      "countyFIP": "125",
      "countyName": "Nelson"
    },
    {
      "countyFIP": "127",
      "countyName": "New Kent"
    },
    {
      "countyFIP": "131",
      "countyName": "Northampton"
    },
    {
      "countyFIP": "133",
      "countyName": "Northumberland"
    },
    {
      "countyFIP": "135",
      "countyName": "Nottoway"
    },
    {
      "countyFIP": "137",
      "countyName": "Orange"
    },
    {
      "countyFIP": "139",
      "countyName": "Page"
    },
    {
      "countyFIP": "141",
      "countyName": "Patrick"
    },
    {
      "countyFIP": "143",
      "countyName": "Pittsylvania"
    },
    {
      "countyFIP": "145",
      "countyName": "Powhatan"
    },
    {
      "countyFIP": "147",
      "countyName": "Prince Edward"
    },
    {
      "countyFIP": "149",
      "countyName": "Prince George"
    },
    {
      "countyFIP": "153",
      "countyName": "Prince William"
    },
    {
      "countyFIP": "155",
      "countyName": "Pulaski"
    },
    {
      "countyFIP": "157",
      "countyName": "Rappahannock"
    },
    {
      "countyFIP": "159",
      "countyName": "Richmond"
    },
    {
      "countyFIP": "161",
      "countyName": "Roanoke"
    },
    {
      "countyFIP": "163",
      "countyName": "Rockbridge"
    },
    {
      "countyFIP": "165",
      "countyName": "Rockingham"
    },
    {
      "countyFIP": "167",
      "countyName": "Russell"
    },
    {
      "countyFIP": "169",
      "countyName": "Scott"
    },
    {
      "countyFIP": "171",
      "countyName": "Shenandoah"
    },
    {
      "countyFIP": "173",
      "countyName": "Smyth"
    },
    {
      "countyFIP": "175",
      "countyName": "Southampton"
    },
    {
      "countyFIP": "177",
      "countyName": "Spotsylvania"
    },
    {
      "countyFIP": "179",
      "countyName": "Stafford"
    },
    {
      "countyFIP": "181",
      "countyName": "Surry"
    },
    {
      "countyFIP": "183",
      "countyName": "Sussex"
    },
    {
      "countyFIP": "185",
      "countyName": "Tazewell"
    },
    {
      "countyFIP": "187",
      "countyName": "Warren"
    },
    {
      "countyFIP": "191",
      "countyName": "Washington"
    },
    {
      "countyFIP": "193",
      "countyName": "Westmoreland"
    },
    {
      "countyFIP": "195",
      "countyName": "Wise"
    },
    {
      "countyFIP": "197",
      "countyName": "Wythe"
    },
    {
      "countyFIP": "199",
      "countyName": "York"
    },
    {
      "countyFIP": "510",
      "countyName": "Alexandria City"
    },
    {
      "countyFIP": "520",
      "countyName": "Bristol City"
    },
    {
      "countyFIP": "530",
      "countyName": "Buena Vista City"
    },
    {
      "countyFIP": "540",
      "countyName": "Charlottesville City"
    },
    {
      "countyFIP": "550",
      "countyName": "Chesapeake City"
    },
    {
      "countyFIP": "570",
      "countyName": "Colonial Heights City"
    },
    {
      "countyFIP": "580",
      "countyName": "Covington City"
    },
    {
      "countyFIP": "590",
      "countyName": "Danville City"
    },
    {
      "countyFIP": "595",
      "countyName": "Emporia City"
    },
    {
      "countyFIP": "600",
      "countyName": "Fairfax City"
    },
    {
      "countyFIP": "610",
      "countyName": "Falls Church City"
    },
    {
      "countyFIP": "620",
      "countyName": "Franklin City"
    },
    {
      "countyFIP": "630",
      "countyName": "Fredericksburg City"
    },
    {
      "countyFIP": "640",
      "countyName": "Galax City"
    },
    {
      "countyFIP": "650",
      "countyName": "Hampton City"
    },
    {
      "countyFIP": "660",
      "countyName": "Harrisonburg City"
    },
    {
      "countyFIP": "670",
      "countyName": "Hopewell City"
    },
    {
      "countyFIP": "678",
      "countyName": "Lexington City"
    },
    {
      "countyFIP": "680",
      "countyName": "Lynchburg City"
    },
    {
      "countyFIP": "683",
      "countyName": "Manassas City"
    },
    {
      "countyFIP": "685",
      "countyName": "Manassas Park City"
    },
    {
      "countyFIP": "690",
      "countyName": "Martinsville City"
    },
    {
      "countyFIP": "700",
      "countyName": "Newport News City"
    },
    {
      "countyFIP": "710",
      "countyName": "Norfolk City"
    },
    {
      "countyFIP": "720",
      "countyName": "Norton City"
    },
    {
      "countyFIP": "730",
      "countyName": "Petersburg City"
    },
    {
      "countyFIP": "735",
      "countyName": "Poquoson City"
    },
    {
      "countyFIP": "740",
      "countyName": "Portsmouth City"
    },
    {
      "countyFIP": "750",
      "countyName": "Radford City"
    },
    {
      "countyFIP": "760",
      "countyName": "Richmond City"
    },
    {
      "countyFIP": "770",
      "countyName": "Roanoke City"
    },
    {
      "countyFIP": "775",
      "countyName": "Salem City"
    },
    {
      "countyFIP": "790",
      "countyName": "Staunton City"
    },
    {
      "countyFIP": "800",
      "countyName": "Suffolk City"
    },
    {
      "countyFIP": "810",
      "countyName": "Virginia Beach City"
    },
    {
      "countyFIP": "820",
      "countyName": "Waynesboro City"
    },
    {
      "countyFIP": "830",
      "countyName": "Williamsburg City"
    },
    {
      "countyFIP": "840",
      "countyName": "Winchester City"
    }
  ],
  "53": [
    {
      "countyFIP": "001",
      "countyName": "Adams"
    },
    {
      "countyFIP": "003",
      "countyName": "Asotin"
    },
    {
      "countyFIP": "005",
      "countyName": "Benton"
    },
    {
      "countyFIP": "007",
      "countyName": "Chelan"
    },
    {
      "countyFIP": "009",
      "countyName": "Clallam"
    },
    {
      "countyFIP": "011",
      "countyName": "Clark"
    },
    {
      "countyFIP": "013",
      "countyName": "Columbia"
    },
    {
      "countyFIP": "015",
      "countyName": "Cowlitz"
    },
    {
      "countyFIP": "017",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "019",
      "countyName": "Ferry"
    },
    {
      "countyFIP": "021",
      "countyName": "Franklin"
    },
    {
      "countyFIP": "023",
      "countyName": "Garfield"
    },
    {
      "countyFIP": "025",
      "countyName": "Grant"
    },
    {
      "countyFIP": "027",
      "countyName": "Grays Harbor"
    },
    {
      "countyFIP": "029",
      "countyName": "Island"
    },
    {
      "countyFIP": "031",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "033",
      "countyName": "King"
    },
    {
      "countyFIP": "035",
      "countyName": "Kitsap"
    },
    {
      "countyFIP": "037",
      "countyName": "Kittitas"
    },
    {
      "countyFIP": "039",
      "countyName": "Klickitat"
    },
    {
      "countyFIP": "041",
      "countyName": "Lewis"
    },
    {
      "countyFIP": "043",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "045",
      "countyName": "Mason"
    },
    {
      "countyFIP": "047",
      "countyName": "Okanogan"
    },
    {
      "countyFIP": "049",
      "countyName": "Pacific"
    },
    {
      "countyFIP": "051",
      "countyName": "Pend Oreille"
    },
    {
      "countyFIP": "053",
      "countyName": "Pierce"
    },
    {
      "countyFIP": "055",
      "countyName": "San Juan"
    },
    {
      "countyFIP": "057",
      "countyName": "Skagit"
    },
    {
      "countyFIP": "059",
      "countyName": "Skamania"
    },
    {
      "countyFIP": "061",
      "countyName": "Snohomish"
    },
    {
      "countyFIP": "063",
      "countyName": "Spokane"
    },
    {
      "countyFIP": "065",
      "countyName": "Stevens"
    },
    {
      "countyFIP": "067",
      "countyName": "Thurston"
    },
    {
      "countyFIP": "069",
      "countyName": "Wahkiakum"
    },
    {
      "countyFIP": "071",
      "countyName": "Walla Walla"
    },
    {
      "countyFIP": "073",
      "countyName": "Whatcom"
    },
    {
      "countyFIP": "075",
      "countyName": "Whitman"
    },
    {
      "countyFIP": "077",
      "countyName": "Yakima"
    }
  ],
  "54": [
    {
      "countyFIP": "001",
      "countyName": "Barbour"
    },
    {
      "countyFIP": "003",
      "countyName": "Berkeley"
    },
    {
      "countyFIP": "005",
      "countyName": "Boone"
    },
    {
      "countyFIP": "007",
      "countyName": "Braxton"
    },
    {
      "countyFIP": "009",
      "countyName": "Brooke"
    },
    {
      "countyFIP": "011",
      "countyName": "Cabell"
    },
    {
      "countyFIP": "013",
      "countyName": "Calhoun"
    },
    {
      "countyFIP": "015",
      "countyName": "Clay"
    },
    {
      "countyFIP": "017",
      "countyName": "Doddridge"
    },
    {
      "countyFIP": "019",
      "countyName": "Fayette"
    },
    {
      "countyFIP": "021",
      "countyName": "Gilmer"
    },
    {
      "countyFIP": "023",
      "countyName": "Grant"
    },
    {
      "countyFIP": "025",
      "countyName": "Greenbrier"
    },
    {
      "countyFIP": "027",
      "countyName": "Hampshire"
    },
    {
      "countyFIP": "029",
      "countyName": "Hancock"
    },
    {
      "countyFIP": "031",
      "countyName": "Hardy"
    },
    {
      "countyFIP": "033",
      "countyName": "Harrison"
    },
    {
      "countyFIP": "035",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "037",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "039",
      "countyName": "Kanawha"
    },
    {
      "countyFIP": "041",
      "countyName": "Lewis"
    },
    {
      "countyFIP": "043",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "045",
      "countyName": "Logan"
    },
    {
      "countyFIP": "047",
      "countyName": "McDowell"
    },
    {
      "countyFIP": "049",
      "countyName": "Marion"
    },
    {
      "countyFIP": "051",
      "countyName": "Marshall"
    },
    {
      "countyFIP": "053",
      "countyName": "Mason"
    },
    {
      "countyFIP": "055",
      "countyName": "Mercer"
    },
    {
      "countyFIP": "057",
      "countyName": "Mineral"
    },
    {
      "countyFIP": "059",
      "countyName": "Mingo"
    },
    {
      "countyFIP": "061",
      "countyName": "Monongalia"
    },
    {
      "countyFIP": "063",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "065",
      "countyName": "Morgan"
    },
    {
      "countyFIP": "067",
      "countyName": "Nicholas"
    },
    {
      "countyFIP": "069",
      "countyName": "Ohio"
    },
    {
      "countyFIP": "071",
      "countyName": "Pendleton"
    },
    {
      "countyFIP": "073",
      "countyName": "Pleasants"
    },
    {
      "countyFIP": "075",
      "countyName": "Pocahontas"
    },
    {
      "countyFIP": "077",
      "countyName": "Preston"
    },
    {
      "countyFIP": "079",
      "countyName": "Putnam"
    },
    {
      "countyFIP": "081",
      "countyName": "Raleigh"
    },
    {
      "countyFIP": "083",
      "countyName": "Randolph"
    },
    {
      "countyFIP": "085",
      "countyName": "Ritchie"
    },
    {
      "countyFIP": "087",
      "countyName": "Roane"
    },
    {
      "countyFIP": "089",
      "countyName": "Summers"
    },
    {
      "countyFIP": "091",
      "countyName": "Taylor"
    },
    {
      "countyFIP": "093",
      "countyName": "Tucker"
    },
    {
      "countyFIP": "095",
      "countyName": "Tyler"
    },
    {
      "countyFIP": "097",
      "countyName": "Upshur"
    },
    {
      "countyFIP": "099",
      "countyName": "Wayne"
    },
    {
      "countyFIP": "101",
      "countyName": "Webster"
    },
    {
      "countyFIP": "103",
      "countyName": "Wetzel"
    },
    {
      "countyFIP": "105",
      "countyName": "Wirt"
    },
    {
      "countyFIP": "107",
      "countyName": "Wood"
    },
    {
      "countyFIP": "109",
      "countyName": "Wyoming"
    }
  ],
  "55": [
    {
      "countyFIP": "001",
      "countyName": "Adams"
    },
    {
      "countyFIP": "003",
      "countyName": "Ashland"
    },
    {
      "countyFIP": "005",
      "countyName": "Barron"
    },
    {
      "countyFIP": "007",
      "countyName": "Bayfield"
    },
    {
      "countyFIP": "009",
      "countyName": "Brown"
    },
    {
      "countyFIP": "011",
      "countyName": "Buffalo"
    },
    {
      "countyFIP": "013",
      "countyName": "Burnett"
    },
    {
      "countyFIP": "015",
      "countyName": "Calumet"
    },
    {
      "countyFIP": "017",
      "countyName": "Chippewa"
    },
    {
      "countyFIP": "019",
      "countyName": "Clark"
    },
    {
      "countyFIP": "021",
      "countyName": "Columbia"
    },
    {
      "countyFIP": "023",
      "countyName": "Crawford"
    },
    {
      "countyFIP": "025",
      "countyName": "Dane"
    },
    {
      "countyFIP": "027",
      "countyName": "Dodge"
    },
    {
      "countyFIP": "029",
      "countyName": "Door"
    },
    {
      "countyFIP": "031",
      "countyName": "Douglas"
    },
    {
      "countyFIP": "033",
      "countyName": "Dunn"
    },
    {
      "countyFIP": "035",
      "countyName": "Eau Claire"
    },
    {
      "countyFIP": "037",
      "countyName": "Florence"
    },
    {
      "countyFIP": "039",
      "countyName": "Fond du Lac"
    },
    {
      "countyFIP": "041",
      "countyName": "Forest"
    },
    {
      "countyFIP": "043",
      "countyName": "Grant"
    },
    {
      "countyFIP": "045",
      "countyName": "Green"
    },
    {
      "countyFIP": "047",
      "countyName": "Green Lake"
    },
    {
      "countyFIP": "049",
      "countyName": "Iowa"
    },
    {
      "countyFIP": "051",
      "countyName": "Iron"
    },
    {
      "countyFIP": "053",
      "countyName": "Jackson"
    },
    {
      "countyFIP": "055",
      "countyName": "Jefferson"
    },
    {
      "countyFIP": "057",
      "countyName": "Juneau"
    },
    {
      "countyFIP": "059",
      "countyName": "Kenosha"
    },
    {
      "countyFIP": "061",
      "countyName": "Kewaunee"
    },
    {
      "countyFIP": "063",
      "countyName": "La Crosse"
    },
    {
      "countyFIP": "065",
      "countyName": "Lafayette"
    },
    {
      "countyFIP": "067",
      "countyName": "Langlade"
    },
    {
      "countyFIP": "069",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "071",
      "countyName": "Manitowoc"
    },
    {
      "countyFIP": "073",
      "countyName": "Marathon"
    },
    {
      "countyFIP": "075",
      "countyName": "Marinette"
    },
    {
      "countyFIP": "077",
      "countyName": "Marquette"
    },
    {
      "countyFIP": "078",
      "countyName": "Menominee"
    },
    {
      "countyFIP": "079",
      "countyName": "Milwaukee"
    },
    {
      "countyFIP": "081",
      "countyName": "Monroe"
    },
    {
      "countyFIP": "083",
      "countyName": "Oconto"
    },
    {
      "countyFIP": "085",
      "countyName": "Oneida"
    },
    {
      "countyFIP": "087",
      "countyName": "Outagamie"
    },
    {
      "countyFIP": "089",
      "countyName": "Ozaukee"
    },
    {
      "countyFIP": "091",
      "countyName": "Pepin"
    },
    {
      "countyFIP": "093",
      "countyName": "Pierce"
    },
    {
      "countyFIP": "095",
      "countyName": "Polk"
    },
    {
      "countyFIP": "097",
      "countyName": "Portage"
    },
    {
      "countyFIP": "099",
      "countyName": "Price"
    },
    {
      "countyFIP": "101",
      "countyName": "Racine"
    },
    {
      "countyFIP": "103",
      "countyName": "Richland"
    },
    {
      "countyFIP": "105",
      "countyName": "Rock"
    },
    {
      "countyFIP": "107",
      "countyName": "Rusk"
    },
    {
      "countyFIP": "109",
      "countyName": "St Croix"
    },
    {
      "countyFIP": "111",
      "countyName": "Sauk"
    },
    {
      "countyFIP": "113",
      "countyName": "Sawyer"
    },
    {
      "countyFIP": "115",
      "countyName": "Shawano"
    },
    {
      "countyFIP": "117",
      "countyName": "Sheboygan"
    },
    {
      "countyFIP": "119",
      "countyName": "Taylor"
    },
    {
      "countyFIP": "121",
      "countyName": "Trempealeau"
    },
    {
      "countyFIP": "123",
      "countyName": "Vernon"
    },
    {
      "countyFIP": "125",
      "countyName": "Vilas"
    },
    {
      "countyFIP": "127",
      "countyName": "Walworth"
    },
    {
      "countyFIP": "129",
      "countyName": "Washburn"
    },
    {
      "countyFIP": "131",
      "countyName": "Washington"
    },
    {
      "countyFIP": "133",
      "countyName": "Waukesha"
    },
    {
      "countyFIP": "135",
      "countyName": "Waupaca"
    },
    {
      "countyFIP": "137",
      "countyName": "Waushara"
    },
    {
      "countyFIP": "139",
      "countyName": "Winnebago"
    },
    {
      "countyFIP": "141",
      "countyName": "Wood"
    }
  ],
  "56": [
    {
      "countyFIP": "001",
      "countyName": "Albany"
    },
    {
      "countyFIP": "003",
      "countyName": "Big Horn"
    },
    {
      "countyFIP": "005",
      "countyName": "Campbell"
    },
    {
      "countyFIP": "007",
      "countyName": "Carbon"
    },
    {
      "countyFIP": "009",
      "countyName": "Converse"
    },
    {
      "countyFIP": "011",
      "countyName": "Crook"
    },
    {
      "countyFIP": "013",
      "countyName": "Fremont"
    },
    {
      "countyFIP": "015",
      "countyName": "Goshen"
    },
    {
      "countyFIP": "017",
      "countyName": "Hot Springs"
    },
    {
      "countyFIP": "019",
      "countyName": "Johnson"
    },
    {
      "countyFIP": "021",
      "countyName": "Laramie"
    },
    {
      "countyFIP": "023",
      "countyName": "Lincoln"
    },
    {
      "countyFIP": "025",
      "countyName": "Natrona"
    },
    {
      "countyFIP": "027",
      "countyName": "Niobrara"
    },
    {
      "countyFIP": "029",
      "countyName": "Park"
    },
    {
      "countyFIP": "031",
      "countyName": "Platte"
    },
    {
      "countyFIP": "033",
      "countyName": "Sheridan"
    },
    {
      "countyFIP": "035",
      "countyName": "Sublette"
    },
    {
      "countyFIP": "037",
      "countyName": "Sweetwater"
    },
    {
      "countyFIP": "039",
      "countyName": "Teton"
    },
    {
      "countyFIP": "041",
      "countyName": "Uinta"
    },
    {
      "countyFIP": "043",
      "countyName": "Washakie"
    },
    {
      "countyFIP": "045",
      "countyName": "Weston"
    }
  ],
  "60": [
    {
      "countyFIP": "010",
      "countyName": "Eastern District"
    },
    {
      "countyFIP": "020",
      "countyName": "Manu'a"
    },
    {
      "countyFIP": "050",
      "countyName": "Western District"
    }
  ],
  "66": [
    {
      "countyFIP": "010",
      "countyName": "Guam"
    }
  ],
  "69": [
    {
      "countyFIP": "100",
      "countyName": "Rota"
    },
    {
      "countyFIP": "110",
      "countyName": "Saipan"
    },
    {
      "countyFIP": "120",
      "countyName": "Tinian"
    }
  ],
  "72": [
    {
      "countyFIP": "001",
      "countyName": "Adjuntas"
    },
    {
      "countyFIP": "003",
      "countyName": "Aguada"
    },
    {
      "countyFIP": "005",
      "countyName": "Aguadilla"
    },
    {
      "countyFIP": "007",
      "countyName": "Aguas Buenas"
    },
    {
      "countyFIP": "009",
      "countyName": "Aibonito"
    },
    {
      "countyFIP": "011",
      "countyName": "Añasco"
    },
    {
      "countyFIP": "013",
      "countyName": "Arecibo"
    },
    {
      "countyFIP": "015",
      "countyName": "Arroyo"
    },
    {
      "countyFIP": "017",
      "countyName": "Barceloneta"
    },
    {
      "countyFIP": "019",
      "countyName": "Barranquitas"
    },
    {
      "countyFIP": "021",
      "countyName": "Bayamón"
    },
    {
      "countyFIP": "023",
      "countyName": "Cabo Rojo"
    },
    {
      "countyFIP": "025",
      "countyName": "Caguas"
    },
    {
      "countyFIP": "027",
      "countyName": "Camuy"
    },
    {
      "countyFIP": "029",
      "countyName": "Canóvanas"
    },
    {
      "countyFIP": "031",
      "countyName": "Carolina"
    },
    {
      "countyFIP": "033",
      "countyName": "Cataño"
    },
    {
      "countyFIP": "035",
      "countyName": "Cayey"
    },
    {
      "countyFIP": "037",
      "countyName": "Ceiba"
    },
    {
      "countyFIP": "039",
      "countyName": "Ciales"
    },
    {
      "countyFIP": "041",
      "countyName": "Cidra"
    },
    {
      "countyFIP": "043",
      "countyName": "Coamo"
    },
    {
      "countyFIP": "045",
      "countyName": "Comerío"
    },
    {
      "countyFIP": "047",
      "countyName": "Corozal"
    },
    {
      "countyFIP": "049",
      "countyName": "Culebra"
    },
    {
      "countyFIP": "051",
      "countyName": "Dorado"
    },
    {
      "countyFIP": "053",
      "countyName": "Fajardo"
    },
    {
      "countyFIP": "054",
      "countyName": "Florida"
    },
    {
      "countyFIP": "055",
      "countyName": "Guánica"
    },
    {
      "countyFIP": "057",
      "countyName": "Guayama"
    },
    {
      "countyFIP": "059",
      "countyName": "Guayanilla"
    },
    {
      "countyFIP": "061",
      "countyName": "Guaynabo"
    },
    {
      "countyFIP": "063",
      "countyName": "Gurabo"
    },
    {
      "countyFIP": "065",
      "countyName": "Hatillo"
    },
    {
      "countyFIP": "067",
      "countyName": "Hormigueros"
    },
    {
      "countyFIP": "069",
      "countyName": "Humacao"
    },
    {
      "countyFIP": "071",
      "countyName": "Isabela"
    },
    {
      "countyFIP": "073",
      "countyName": "Jayuya"
    },
    {
      "countyFIP": "075",
      "countyName": "Juana Díaz"
    },
    {
      "countyFIP": "077",
      "countyName": "Juncos"
    },
    {
      "countyFIP": "079",
      "countyName": "Lajas"
    },
    {
      "countyFIP": "081",
      "countyName": "Lares"
    },
    {
      "countyFIP": "083",
      "countyName": "Las Marías"
    },
    {
      "countyFIP": "085",
      "countyName": "Las Piedras"
    },
    {
      "countyFIP": "087",
      "countyName": "Loíza"
    },
    {
      "countyFIP": "089",
      "countyName": "Luquillo"
    },
    {
      "countyFIP": "091",
      "countyName": "Manatí"
    },
    {
      "countyFIP": "093",
      "countyName": "Maricao"
    },
    {
      "countyFIP": "095",
      "countyName": "Maunabo"
    },
    {
      "countyFIP": "097",
      "countyName": "Mayagüez"
    },
    {
      "countyFIP": "099",
      "countyName": "Moca"
    },
    {
      "countyFIP": "101",
      "countyName": "Morovis"
    },
    {
      "countyFIP": "103",
      "countyName": "Naguabo"
    },
    {
      "countyFIP": "105",
      "countyName": "Naranjito"
    },
    {
      "countyFIP": "107",
      "countyName": "Orocovis"
    },
    {
      "countyFIP": "109",
      "countyName": "Patillas"
    },
    {
      "countyFIP": "111",
      "countyName": "Peñuelas"
    },
    {
      "countyFIP": "113",
      "countyName": "Ponce"
    },
    {
      "countyFIP": "115",
      "countyName": "Quebradillas"
    },
    {
      "countyFIP": "117",
      "countyName": "Rincón"
    },
    {
      "countyFIP": "119",
      "countyName": "Río Grande"
    },
    {
      "countyFIP": "121",
      "countyName": "Sabana Grande"
    },
    {
      "countyFIP": "123",
      "countyName": "Salinas"
    },
    {
      "countyFIP": "125",
      "countyName": "San Germán"
    },
    {
      "countyFIP": "127",
      "countyName": "San Juan"
    },
    {
      "countyFIP": "129",
      "countyName": "San Lorenzo"
    },
    {
      "countyFIP": "131",
      "countyName": "San Sebastián"
    },
    {
      "countyFIP": "133",
      "countyName": "Santa Isabel"
    },
    {
      "countyFIP": "135",
      "countyName": "Toa Alta"
    },
    {
      "countyFIP": "137",
      "countyName": "Toa Baja"
    },
    {
      "countyFIP": "139",
      "countyName": "Trujillo Alto"
    },
    {
      "countyFIP": "141",
      "countyName": "Utuado"
    },
    {
      "countyFIP": "143",
      "countyName": "Vega Alta"
    },
    {
      "countyFIP": "145",
      "countyName": "Vega Baja"
    },
    {
      "countyFIP": "147",
      "countyName": "Vieques"
    },
    {
      "countyFIP": "149",
      "countyName": "Villalba"
    },
    {
      "countyFIP": "151",
      "countyName": "Yabucoa"
    },
    {
      "countyFIP": "153",
      "countyName": "Yauco"
    }
  ],
  "78": [
    {
      "countyFIP": "010",
      "countyName": "St Croix"
    },
    {
      "countyFIP": "020",
      "countyName": "St John"
    },
    {
      "countyFIP": "030",
      "countyName": "St Thomas"
    }
  ]
}
