import { AnalyticsBrowser } from '@segment/analytics-next'

class Segment {
  constructor(NODE_ENV) {
    this.NODE_ENV = NODE_ENV
    this.analyticsBrowser = AnalyticsBrowser.load({ writeKey: SEGMENT_KEY })
  }

  // Get User
  userLogIn = (user) => {
    const userId = user.id
    const userInfo = {
      email: user.email,
      name: `${user.firstname} ${user.lastname}`.trim(),
      first_name: user.firstname,
      last_name: user.lastname,
      role: user.role.name,
      lender_name: user.lender.name,
      lender_id: user.lender.id,
    }
    this.analyticsBrowser?.identify(userId, userInfo)
    this.userId = userId
    this.userInfo = userInfo

    if (user.lender) {
      this.analyticsBrowser?.group(user.lender.id, {
        lender_name: user.lender.name,
      })
    }
  }

  userLogOut = () => {
    this.analyticsBrowser?.reset()
  }

  trackComponent = (identifier, data = {}) => {
    this.analyticsBrowser?.track(identifier, data)
  }
}

const analytics = new Segment(process.env.NODE_ENV)

export default analytics
