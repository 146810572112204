import { Steps, Alert, Spin, Button as AntdButton } from 'antd'
import React, { Fragment, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Tooltip } from 'reactstrap'
import { InfoCircleOutlined } from '@ant-design/icons'
import ModalTrigger from '../../common/modals/ModalTrigger'
import { checkPermission } from '../../common/utils/helpers'
import {
  REGGORA_PROGRESS_ALERT_TEXT,
  APPRAISER_CANCELLATION_REQUEST,
  NO_ACCEPTED_APPRAISERS_AND_NO_DENIED_COMPANIES,
  DISMISSIBLE_LENDER_ATTENTION_REASONS,
  DISMISSABLE_LAR_TOOPTIP,
} from '../../common/constants/alerts'
import { JOB_TYPES } from '../constants/jobTypes'
import ModalCreateOrderContainer from '../../pages/orders/ModalCreateOrderContainer'
import { history } from '../../init'
import Rephrase from '../utils/Rephrase'
import { selectLenderId } from '@app/store/reducers/user/userSelectors'
import { resolveLenderAttention } from '@app/store/reducers/order/orderSlice'
const Step = Steps.Step

const ReggoraProgress = (props) => {
  const {
    orderData,
    using_amc_lender,
    loanData,
    is_amc_lender,
    refreshOrder,
    loading,
  } = props

  const dispatch = useDispatch()
  const [showTooltip, setShowTooltip] = useState({})
  const [lenderAttentions, setLenderAttentions] = useState([])
  const lenderId = useSelector(selectLenderId)

  const availableStatuses = orderData.available_statuses
  const status = orderData.status
  const onHold = orderData.on_hold
  const onHoldReason = orderData.on_hold_reason
  const requestedCancellation = orderData.appraiser_requested_cancel
  const revisionStatus = is_amc_lender ? orderData.amc_revision_status : orderData.client_lender_revision_status
  const reassigned = orderData.has_been_reassigned
  const canceled = orderData.lender_canceled
  const job_type = orderData.job_type
  const fetchingAdditionalData = loading
  const { lender_attentions } = orderData
  const hasLenderAttention = lenderAttentions && lenderAttentions.length > 0

  useEffect(() => {
    if (lender_attentions) {
      setLenderAttentions(
        lender_attentions
          .filter(({ lender_id }) => lender_id === lenderId)
          .map(attention => ({ ...attention, resolved: false }))
      )
    }
  }, [lender_attentions, lenderId])

  // eslint-disable-next-line no-unused-vars
  const getAlertsByAttentionRequiredType = reason => {
    if (reason === APPRAISER_CANCELLATION_REQUEST) {
      return getAlertsForCancellationRequests(reason)
    } else {
      return getAlertsForOtherAttentionRequiredTypes(reason)
    }
  }

  const getAlertsForCancellationRequests = reason => {
    const alertMessage = getAlertMessageForCancellationRequest(reason)

    if (!canceled && requestedCancellation && checkPermission('order_edit') && (job_type !== JOB_TYPES.AMC || is_amc_lender)) {
      return <Fragment>
        <Alert
          color="primary"
          message={<Rephrase>{alertMessage}</Rephrase>}
          type="error"
          showIcon
        />
        <ModalTrigger
          selectedLoan={loanData}
          orderData={orderData}
          component={ ModalCreateOrderContainer }
          modalClassName='modal-primary modal-xl'
          backdrop='static'
          refreshOrder={refreshOrder}
          history={history}
        >
          <Button color="dark" className='mt-2 order-header-button'><Rephrase>Request New Appraiser</Rephrase></Button>
        </ModalTrigger>
      </Fragment>
    } else {
      return <Alert message={<Rephrase>{alertMessage}</Rephrase>} type="error" showIcon/>
    }
  }

  const getAlertMessageForCancellationRequest = (reason) => {
    const requiresAttentionReason = REGGORA_PROGRESS_ALERT_TEXT[reason]
    if (orderData.conversation &&
      orderData.conversation.messages &&
      orderData.conversation.messages.length &&
      orderData.conversation.messages.find(message => message.is_cancellation_request)
    ) {
      const cancellationReason = orderData.conversation.messages.filter(
        message => message.is_cancellation_request
      )[0].message
      return `Requires Attention: ${requiresAttentionReason} for the following reason - ${cancellationReason}`
    } else {
      return `Requires Attention: ${requiresAttentionReason}`
    }
  }

  const dismissLenderAttention = reason => {
    dispatch(resolveLenderAttention({ orderId: orderData.id, reason }))
      .then(() => setLenderAttentions(lenderAttentions.map(attention => ({
        ...attention,
        resolved: attention.reason === reason,
      }))))
      .catch(() => null)
  }

  const getAlertsForOtherAttentionRequiredTypes = reason => {
    const alertMessage = REGGORA_PROGRESS_ALERT_TEXT[reason]
    const lenderAttention = lenderAttentions.find(({ reason: attentionReason }) => attentionReason === reason)

    if (reason === NO_ACCEPTED_APPRAISERS_AND_NO_DENIED_COMPANIES) {
      return <Alert message={<Rephrase>{alertMessage}</Rephrase>} type="error" showIcon/> // this alert contains several lines and formatting
    } else {
      const extraProps = {}
      if (checkPermission('order_edit') && DISMISSIBLE_LENDER_ATTENTION_REASONS.includes(reason)) {
        const button = (
          <AntdButton type='default' size='small' onClick={() => dismissLenderAttention(reason)}>
            <Tooltip
              placement='top'
              isOpen={showTooltip[reason]}
              target={reason}
              toggle={() => setShowTooltip({ ...showTooltip, [reason]: !showTooltip[reason] })}
            >
              {DISMISSABLE_LAR_TOOPTIP}
            </Tooltip>
            <span className="dismiss-text">Dismiss</span>
            <span id={reason}><InfoCircleOutlined /></span>
          </AntdButton>
        )
        extraProps.action = button
      }
      return !lenderAttention.resolved && <Alert
        message={<Rephrase>{`Requires Attention: ${alertMessage}`}</Rephrase>}
        type="error"
        showIcon
        {...extraProps}
      />
    }
  }

  const showRevisionStatus = () => {
    // if the order is in amp_review only show revision status to the AMC lender
    if (!revisionStatus) {
      return false
    }
    if (status === 'AMP Review') {
      return is_amc_lender
    }
    return true
  }

  const showCancelationAlerts = !using_amc_lender && (requestedCancellation || canceled)

  // SHOW ORDER PROGRESS BAR / STEPS
  if (!onHold && !canceled && (
    !showCancelationAlerts && !hasLenderAttention && !showRevisionStatus())) {
    const orderProgress = availableStatuses.findIndex((statusObj) => {
      return statusObj.statusValue === status
    })

    const arrProgress = availableStatuses.map((progress, idx) => (
      <Step key={idx} title={progress.statusValue} />
    ))

    return (
      <Steps size="small" current={orderProgress} data-testid="steps">
        {arrProgress}
      </Steps>
    )
    // ELSE SHOW ALERT
  } else if (onHold && onHoldReason && !canceled) {
    return (
      <Alert message={`Hold: ${onHoldReason}`} type="info" showIcon/>
    )
  } else if (hasLenderAttention) {
    return <Fragment>
      {lenderAttentions.map(({ reason }) => {
        return <div key={reason} className="mb-1">{getAlertsByAttentionRequiredType(reason)}</div>
      })}
    </Fragment>
  } else if (revisionStatus) {
    if (revisionStatus === 'Revision Requested' || revisionStatus === 'Lender Revisions Requested') {
      return <Alert message="A revision has been requested." type="info" showIcon/>
    } else if (revisionStatus === 'Revision Submitted') {
      return <Alert message="A revision has been submitted." type="success" showIcon/>
    }
  } else if (reassigned) {
    return (
      <Alert message="This order has been reassigned." description={<span className='pointer' onClick={() => history.goBack()}> Return to Active Order</span>} type="warning" showIcon/>
    )
  } else if (canceled) {
    return (
      <Alert message="This order has been cancelled." type="error" showIcon/>
    )
  } else if (fetchingAdditionalData) {
    // place holder for when the data is still being fetched
    return (
      <Spin>
        <Alert message="Loading..." type="info" showIcon />
      </Spin>)
  } else {
    return (
      <Alert message={status} type="error" showIcon/>
    )
  }
}

export default ReggoraProgress
